import { Box, Divider, List, ListItem, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { COMBINEDBOUNDARIESURLS, getBoundaries } from '../../api';
import RIAccordion from '../../common-components/accordion/ri-accordion';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Boundary } from '../../models/boundary.model';
import {
  setBoundaries,
  setError,
  setLoading,
} from '../../state/boundaries/boundaries.slice';
import BoundariesCombinedLayerToggle from './boundaries-combined-layer-toggle';
import BoundariesPanelFilterBlock from './boundaries-panel-filter-block';
import './boundaries-panel.scss';
import BoundaryResultCard from './boundary-result-card';

function BoundariesPanel() {
  const dispatch = useAppDispatch();
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);

  const boundaries = useAppSelector((state) => state.boundaries.boundaries);
  const loading = useAppSelector((state) => state.boundaries.loading);
  const error = useAppSelector((state) => state.boundaries.error);
  const searchFilterValue = useAppSelector(
    (state) => state.boundariesPanel.searchFilterValue
  );

  // Check if the map is initialised and that the boundaries are not already
  // loaded. If both conditions are met, load the boundaries.
  useEffect(() => {
    if (!mapInitialised || boundaries?.length) {
      return;
    }

    // Set the loading state to true.
    dispatch(setLoading());
    getBoundaries()
      .then((boundariesData) => {
        dispatch(setBoundaries(boundariesData));
      })
      .catch(() => {
        dispatch(setError());
      });
  }, [mapInitialised, boundaries]);

  const filteredBoundaries = useMemo(
    () =>
      boundaries
        ? boundaries.filter((boundary: Boundary) =>
            !searchFilterValue.trim().length
              ? boundary
              : boundary.name
                  .trim()
                  .toLowerCase()
                  .includes(searchFilterValue.trim().toLowerCase()) ||
                boundary.description
                  .trim()
                  .toLowerCase()
                  .includes(searchFilterValue.trim().toLowerCase()) ||
                boundary.source
                  .trim()
                  .toLowerCase()
                  .includes(searchFilterValue.trim().toLowerCase())
          )
        : [],
    [boundaries, searchFilterValue]
  );

  const groupedBoundaries = useMemo(() => {
    const gBoundaries: { [key: string]: Boundary[] } = {};
    filteredBoundaries?.forEach((boundary) => {
      if (COMBINEDBOUNDARIESURLS.includes(boundary.url)) return;

      if (!gBoundaries[boundary.boundary_type]) {
        gBoundaries[boundary.boundary_type] = [];
      }
      gBoundaries[boundary.boundary_type].push(boundary);
    });
    return gBoundaries;
  }, [filteredBoundaries]);

  if (error) {
    return (
      <>
        <PanelTitle>Boundaries</PanelTitle>
        <ErrorPanel message="Unable to fetch boundaries. Please try again later..." />
      </>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <PanelTitle>Boundaries</PanelTitle>

      {loading ? (
        <LoadingPanel />
      ) : (
        <Box
          p={0.5}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            minHeight: 0,
          }}
        >
          <Divider sx={{ margin: '0.5rem 0' }} />
          <BoundariesCombinedLayerToggle />
          <Divider sx={{ margin: '0.5rem 0' }} />

          {!error && boundaries && (
            <Box
              data-testid="boundaries-panel-results-container"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflowY: 'auto',
                minHeight: 0,
              }}
            >
              <RIAccordion
                unmountOnExit={false}
                title="Advanced Boundaries Options"
                content={
                  <>
                    <Box sx={{ display: 'flex' }}>
                      <BoundariesPanelFilterBlock />
                    </Box>
                    <div>
                      <RIAccordion
                        unmountOnExit={false}
                        title="Territorial waters (TTW) Boundaries"
                        content={
                          <List>
                            {groupedBoundaries.TTW?.length > 0 ? (
                              groupedBoundaries.TTW.map((boundary) => (
                                <BoundaryResultCard
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...boundary}
                                />
                              ))
                            ) : (
                              <ListItem data-testid="boundaries-panel-warning-message">
                                <Typography>No TTW boundaries found</Typography>
                              </ListItem>
                            )}
                          </List>
                        }
                      />
                      <RIAccordion
                        unmountOnExit={false}
                        title="Exclusive Economic Zone (EEZ) Boundaries"
                        content={
                          <List>
                            {groupedBoundaries.EEZ?.length > 0 ? (
                              groupedBoundaries.EEZ.map((boundary) => (
                                <BoundaryResultCard
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...boundary}
                                />
                              ))
                            ) : (
                              <ListItem data-testid="boundaries-panel-warning-message">
                                <Typography>No EEZ boundaries found</Typography>
                              </ListItem>
                            )}
                          </List>
                        }
                      />
                      <RIAccordion
                        unmountOnExit={false}
                        title="Joint War Committee (JWC) Boundaries"
                        content={
                          <List>
                            {groupedBoundaries.JWC?.length > 0 ? (
                              groupedBoundaries.JWC.map((boundary) => (
                                <BoundaryResultCard
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...boundary}
                                />
                              ))
                            ) : (
                              <ListItem data-testid="boundaries-panel-warning-message">
                                <Typography>No JWC boundaries found</Typography>
                              </ListItem>
                            )}
                          </List>
                        }
                      />
                    </div>
                  </>
                }
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default BoundariesPanel;

/* eslint-disable no-async-promise-executor */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-promise-executor-return */
import {
  FeatureCollection,
  GeoJSON,
  GeoJsonProperties,
  Geometry,
  LineString,
  Polygon,
} from 'geojson';
import mapboxgl, { VectorSource } from 'mapbox-gl';
import { getCombinedEEZ, getCombinedJWC, getCombinedTTW } from '../../api';
import MapLayerManager from '../../map/map-layer-manager/map-layer-manager.utils';
import MapLayerPaint from '../../map/map-layer-manager/map-layer-paint';
import MapLayerVisibility from '../../map/map-layer-manager/map-layer-visibility.enum';
import { MapGroupLayer } from '../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../map/map.utils';
import { Boundary } from '../../models/boundary.model';
import store from '../../store';
import GeoHelper from '../../utils/geo-helpers.utils';

interface CreateOptions {
  createVisible?: boolean;
}

namespace BoundariesController {
  const handleGeoJsonFormat = (
    boundarySourceLayer: string,
    geojson: GeoJSON,
    boundaryPaint: any,
    boundaryColor?: string
  ): void => {
    const createFeatureCollection = (features: any[]) => ({
      type: 'FeatureCollection',
      features: features.map((feature) => ({
        type: 'Feature',
        properties: {},
        geometry: feature,
      })),
    });

    const createGeoJsonSourceAndLayer = (
      type: 'line' | 'fill',
      featureCollection: FeatureCollection<any, GeoJsonProperties>
    ): void => {
      let layerId = `${boundarySourceLayer}`;

      // need to be able to create different layer names for GeometryCollection geojson data
      if (geojson.type === 'GeometryCollection') {
        layerId = `${boundarySourceLayer}_${type === 'line' ? 'line' : 'fill'}`;
      }

      MapHelpers.addSource(layerId, {
        type: 'geojson',
        data: featureCollection,
      });

      MapLayerManager.AddLayerinGroups(
        {
          id: layerId,
          source: layerId,
          type,
          paint:
            type === 'line'
              ? {
                  'line-color':
                    boundaryColor ?? boundaryPaint['fill-outline-color'],
                  'line-width': 2,
                  'line-opacity': 0.7,
                }
              : { ...boundaryPaint },
        },
        [MapGroupLayer.BOUNDARIES_GROUP]
      );
    };

    if (geojson.type === 'GeometryCollection') {
      const { geometries } = geojson;
      const lines: LineString[] = geometries.filter(
        (feature: Geometry): feature is LineString =>
          feature.type === 'LineString'
      ) as LineString[];
      const polygons = geometries.filter(
        (feature: Geometry): feature is Polygon => feature.type === 'Polygon'
      ) as Polygon[];

      if (lines.length > 0) {
        createGeoJsonSourceAndLayer(
          'line',
          createFeatureCollection(lines) as FeatureCollection<
            any,
            GeoJsonProperties
          >
        );
      }

      if (polygons.length > 0) {
        createGeoJsonSourceAndLayer(
          'fill',
          createFeatureCollection(polygons) as FeatureCollection<
            any,
            GeoJsonProperties
          >
        );
      }
    }

    if (geojson.type === 'LineString') {
      createGeoJsonSourceAndLayer(
        'line',
        createFeatureCollection([geojson]) as FeatureCollection<
          any,
          GeoJsonProperties
        >
      );
    }

    if (geojson.type === 'Polygon' || geojson.type === 'MultiPolygon') {
      createGeoJsonSourceAndLayer(
        'fill',
        createFeatureCollection([geojson]) as FeatureCollection<
          any,
          GeoJsonProperties
        >
      );
    }
  };

  const handleVectorTileFormat = (
    boundarySourceLayer: string,
    url: string,
    boundaryPaint: any,
    createVisible: boolean
  ) => {
    MapHelpers.addSource(boundarySourceLayer, {
      type: 'vector',
      url,
    });

    MapLayerManager.AddLayerinGroups(
      {
        id: boundarySourceLayer,
        type: 'fill',
        source: boundarySourceLayer,
        'source-layer': boundarySourceLayer,
        paint: {
          ...boundaryPaint,
        },

        layout: {
          visibility: createVisible
            ? MapLayerVisibility.VISIBLE
            : MapLayerVisibility.NOT_VISIBLE,
        },
      },
      [MapGroupLayer.BOUNDARIES_GROUP]
    );
  };

  export const createBoundaryLayer = async (
    boundary: Boundary,
    options?: CreateOptions
  ) => {
    const { createVisible = false } = options || {};

    const {
      url,
      boundary_source_layer: boundarySourceLayer,
      boundary_type: boundaryType,
      geojson,
      format,
      boundary_color: boundaryColor,
    } = boundary;

    if (MapHelpers.getSource(boundarySourceLayer)) {
      // map layer already exists, don't recreate...
      if (createVisible) {
        MapHelpers.setLayerVisibility(boundarySourceLayer, true);
      }
      return;
    }
    const boundaryPaint =
      MapLayerPaint.BOUNDARY_SHAPE()?.[boundaryType] ||
      MapLayerPaint.BOUNDARY_SHAPE()?.default;
    if (boundaryColor) {
      boundaryPaint['fill-color'] = boundaryColor;
      boundaryPaint['fill-outline-color'] = boundaryColor;
      boundaryPaint['fill-opacity'] = 0.3;
    }

    if (format === 'geojson' && geojson) {
      handleGeoJsonFormat(
        boundarySourceLayer,
        geojson,
        boundaryPaint,
        boundaryColor
      );
    }

    if (format === 'vector_tile') {
      handleVectorTileFormat(
        boundarySourceLayer,
        url,
        boundaryPaint,
        createVisible
      );
    }
  };

  export const getBoundaryLayersByLocation = (lngLat: {
    long: number;
    lat: number;
  }) => {
    const { boundaries } = store.getState().boundaries;
    const boundaryTilesetIds = boundaries
      ? boundaries
          .map(
            (boundary) =>
              // Doesn't seem to have a type that would allow access to vectorLayers Info.
              (
                MapHelpers.getSource(
                  boundary.boundary_source_layer
                ) as VectorSource as any
              ).vectorLayers?.[0].source
          )
          .filter((id) => !!id)
      : [];

    return GeoHelper.getFeaturesInVectorTiles(boundaryTilesetIds, [
      lngLat.long,
      lngLat.lat,
    ]).then((features) =>
      features.map((feature) => feature.properties?.tilequery?.layer)
    );
  };

  export const addBoundariesToMap = async (
    boundaries: Boundary[],
    options: CreateOptions = {}
  ): Promise<mapboxgl.Map> => {
    const map = MapHelpers.getMapInstance();
    await Promise.all(
      boundaries.map((boundary) => createBoundaryLayer(boundary, options))
    );
    return map;
  };

  export const AddCombinedBoundariesLayers = async () => {
    const [eezResponse, jwcResponse, ttwResponse] = await Promise.all([
      getCombinedEEZ(),
      getCombinedJWC(),
      getCombinedTTW(),
    ]);

    await addBoundariesToMap([...eezResponse, ...jwcResponse, ...ttwResponse]);
  };
}

export default BoundariesController;

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface Token {
  sub: string;
  roles: string[] | null;
}

export interface UserFeatureFlags {
  ports_license: boolean;
  cruise_license: boolean;
  fleet_license: boolean;
  ais_history: boolean;
  voyager_intel_tool: boolean;
  sanctions: boolean;
}

interface UserState {
  idToken: Token | null;
  idTokenError: string | null;
  userSigningOut: boolean;
  featureFlags: UserFeatureFlags | null;
}

export const INITIAL_USER_STATE: UserState = {
  idToken: null,
  idTokenError: null,
  userSigningOut: false,
  featureFlags: null,
};

const UserSlice = createSlice({
  name: 'User',
  initialState: INITIAL_USER_STATE,
  reducers: {
    setUserIdToken: (state, action) => {
      const token: Token = {
        sub: action.payload.sub,
        roles: action.payload['cognito:roles'],
      };
      state.idToken = token;
    },
    setUserIdTokenError: (state, action) => {
      state.idTokenError = action.payload;
    },
    setUserFeatureFlags: (
      state,
      action: PayloadAction<UserState['featureFlags']>
    ) => {
      if (!state.idToken) return;
      state.featureFlags = action.payload;
    },
    setUserSigningOut: (state) => {
      state.userSigningOut = true;
    },
    clearUser: () => INITIAL_USER_STATE,
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserIdToken,
  setUserIdTokenError,
  setUserFeatureFlags,
  setUserSigningOut,
  clearUser,
} = UserSlice.actions;

export default UserSlice.reducer;

import { API } from '@aws-amplify/api';
import { Box, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import ReactPDF, { pdf, usePDF } from '@react-pdf/renderer';
import { indexOf } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { getIncident, getIncidentStats } from '../../../api';
import VirtualisedListWrapper from '../../../common-components/virtualised-list/virtualised-list';
import { useAppSelector } from '../../../hooks';
import { EMenuItems } from '../../../main-menu/menu.slice';
import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../../map/map.utils';
import { incidentExport } from '../../../reporting/incident-export';
import { incidentStatsExport } from '../../../reporting/incident-stats-export';
import DownloadHelpers from '../../../utils/download.utils';
import { areAnyFiltersSet } from '../../../utils/incidents-helpers';
import {
  ClearFiltersButton,
  IncidentFiltersBlock,
} from '../incident-filters/incident-filter-block';
import IncidentItem from '../incident-item/incident-item';
import { ExpandedIncident, Incident, IncidentFilters } from '../incident.model';
import './incident-list.scss';

const pdfReady = (
  pdfInstance: ReactPDF.UsePDFInstance
): pdfInstance is ReactPDF.UsePDFInstance & { url: string } => {
  const result =
    pdfInstance &&
    pdfInstance.url &&
    pdfInstance.url.length > 0 && // pdf url exists
    !pdfInstance.loading; // it isn't loading
  return !!result;
};

interface Props {
  incidentIds: Incident['id'][];
}

function IncidentsList({ incidentIds }: Props) {
  const [pdfInstance, setPdfInstance] = usePDF({});

  const [downloadMode, setDownloadMode] = useState(false);
  const [selectedIncsForDownload, setSelectedIncsForDownload] = useState<
    Incident[]
  >([]);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [completedDownloads, setCompletedDownloads] = useState(0);
  const [downloadedIncidents, setDownloadedIncidents] = useState<
    ExpandedIncident[] | null
  >(null);
  const [downloadError, setDownloadError] = useState(false);
  const [activeIncidentPromises, setActiveIncidentPromises] = useState<
    Promise<ExpandedIncident>[]
  >([]);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const selectedOption = useAppSelector((state) => state.menu.selectedOption);
  const incidentFilters = useAppSelector<IncidentFilters>(
    (state) => state.incidentsPanel.filters
  );
  const incidents = useAppSelector((state) => state.incidents.incidents);
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);

  const { selectedIncident } = useAppSelector((state) => state.incidents);
  const [selectedIncidentIndex, setSelectedIncidentIndex] = useState<
    null | number
  >(null);

  const toggleIncidents = useCallback(() => {
    if (mapInitialised) {
      const routeVisibility = selectedOption === EMenuItems.ROUTES;
      MapHelpers.setLayerVisibility(MapLayer.INCIDENTS, !routeVisibility);
      MapHelpers.setLayerVisibility(
        MapLayer.INCIDENT_CLUSTERS,
        !routeVisibility
      );
      MapHelpers.setLayerVisibility(
        MapLayer.INCIDENT_CLUSTER_COUNT,
        !routeVisibility
      );
      MapHelpers.setLayerVisibility(MapLayer.ROUTES_INCIDENTS, routeVisibility);
      MapHelpers.setLayerVisibility(
        MapLayer.ROUTES_INCIDENT_CLUSTERS,
        routeVisibility
      );
      MapHelpers.setLayerVisibility(
        MapLayer.ROUTES_INCIDENT_CLUSTER_COUNT,
        routeVisibility
      );
    }
  }, [selectedOption, mapInitialised]);

  useEffect(() => {
    toggleIncidents();
  }, [toggleIncidents]);

  const theme = useTheme();

  const onCancel = () => {
    activeIncidentPromises.forEach((promise: Promise<ExpandedIncident>) => {
      API.cancel(promise, 'Request cancelled');
    });
    setDownloadInProgress(false);
    setSelectedIncsForDownload([]);
    setCompletedDownloads(0);
    setActiveIncidentPromises([]);
    setDownloadedIncidents(null);
    setDownloadMode(false);
  };

  const modifyselectedIds = (incident: Incident) => {
    // if incident is in the selected incident list already then delete it
    if (
      selectedIncsForDownload.some(
        (selectedIndividualIncident: Incident) =>
          selectedIndividualIncident.id === incident.id
      )
    ) {
      setSelectedIncsForDownload((prevSelectedIncidents: Incident[]) =>
        prevSelectedIncidents.filter(
          (previouslySelectedIncident: Incident) =>
            previouslySelectedIncident.id !== incident.id
        )
      );
    } else {
      setSelectedIncsForDownload((oldArray: Incident[]) => [
        ...oldArray,
        incident,
      ]);
    }
  };
  const onIncidentSelected = (incident: Incident) => {
    modifyselectedIds(incident);
  };

  const selectAllIncidents = () => {
    if (incidents && selectedIncsForDownload.length < incidentIds.length) {
      setSelectedIncsForDownload(Object.values(incidents.byId));
    } else {
      setSelectedIncsForDownload([]);
    }
  };

  const incrementCompletedDownloads = () => {
    setCompletedDownloads(
      (prevCompletedDownloads: number) => prevCompletedDownloads + 1
    );
  };

  const fetchExpandedIncident = async (incident: Incident) =>
    getIncident(incident.id);

  const downloadStats = async () => {
    setDownloadInProgress(true);
    const response = await getIncidentStats(incidentFilters).catch(() => {
      setDownloadInProgress(false);
      setDownloadError(true);
    });

    if (!response) {
      return;
    }

    incidentStatsExport({
      stats: response,
      filters: incidentFilters,
    })
      .then(async (pdfContent) => {
        const pdfFile = pdf(pdfContent);
        const blob = await pdfFile.toBlob();
        const dUrl = URL.createObjectURL(blob);
        const docTitle = `incidents-stats-download-${new Date()
          .toISOString()
          .substring(0, 10)}.pdf`;
        DownloadHelpers.downloadURI(docTitle, dUrl);
        setDownloadInProgress(false);
      })
      .catch(() => {
        setDownloadInProgress(false);
        setDownloadError(true);
      });
  };

  const downloadIncidents = async () => {
    setDownloadInProgress(true);
    const tempIncidentPromises: Promise<ExpandedIncident>[] = [];
    selectedIncsForDownload.forEach((incident: Incident) => {
      const result = fetchExpandedIncident(incident).then(
        (data: ExpandedIncident) => {
          incrementCompletedDownloads();
          return data;
        }
      );
      tempIncidentPromises.push(result);
    });
    setActiveIncidentPromises(tempIncidentPromises);
    // use then instead of await as it makes the catch neater
    Promise.all(tempIncidentPromises)
      .then((data: ExpandedIncident[]) => {
        setDownloadedIncidents(data);
      })
      .catch(() => {});
  };

  const downloadButton = () => {
    let buttonText = '';
    if (downloadComplete) {
      buttonText = 'Downloaded';
      setTimeout(() => {
        setDownloadComplete(false);
        setCompletedDownloads(0);
      }, 2000);
    } else if (
      pdfInstance.loading ||
      (completedDownloads > 0 &&
        selectedIncsForDownload.length === completedDownloads)
    ) {
      buttonText = 'Generating document...';
    } else if (downloadInProgress) {
      buttonText = `Downloading ${completedDownloads} / ${selectedIncsForDownload.length}`;
    } else {
      buttonText = `Download ${selectedIncsForDownload.length} Incidents`;
    }

    return (
      <button
        className="download-view-on-buttons-download"
        type="button"
        disabled={selectedIncsForDownload.length === 0}
        onClick={() => downloadIncidents()}
      >
        {downloadInProgress || pdfInstance.loading ? (
          <>
            <ClipLoader size={15} color="#fff" />
            {buttonText}
          </>
        ) : (
          buttonText
        )}
      </button>
    );
  };

  useEffect(() => {
    if (
      downloadedIncidents &&
      downloadedIncidents.length > 0 &&
      pdfReady(pdfInstance)
    ) {
      const docTitle = `incidents-download-${new Date()
        .toISOString()
        .substring(0, 10)}.pdf`;
      DownloadHelpers.downloadURI(docTitle, pdfInstance.url, () => {
        setDownloadedIncidents(null);
        setDownloadComplete(true);
        setDownloadInProgress(false);
      });
    }
  }, [downloadedIncidents, pdfInstance]);

  useEffect(() => {
    if (downloadedIncidents && downloadedIncidents.length > 0) {
      const generatedDoc = incidentExport({ incidents: downloadedIncidents });
      setPdfInstance(generatedDoc);
    }
  }, [downloadedIncidents]);

  useEffect(() => {
    if (selectedIncident) {
      const selectedIncidentId = selectedIncident.id;
      setSelectedIncidentIndex(indexOf(incidentIds, selectedIncidentId));
    }
  }, [selectedIncident]);

  return (
    <Box data-testid="incident-list" sx={{ display: 'contents' }}>
      {incidentFilters.open && <IncidentFiltersBlock />}
      <Stack justifyContent="flex-end" className="incidentListTitleContainer">
        {areAnyFiltersSet(incidentFilters) && <ClearFiltersButton />}
      </Stack>
      <Stack
        className="download-incidents-button-container"
        sx={{
          padding: '1rem',
          margin: 0,
          borderTop: 1,
          borderBottom: 1,
          borderColor: theme.palette.primary.dark,
          '& Button': {
            borderRadius: '100px',
            borderColor: theme.palette.primary.dark,
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '38px',
          },
        }}
      >
        <Button
          className="download-incidents-button"
          type="button"
          onClick={() => downloadStats()}
        >
          {downloadError && 'Error downloading stats'}
          {!downloadError &&
            (downloadInProgress
              ? 'Downloading...'
              : 'Download Statistics with current filters')}
        </Button>
        {downloadMode ? (
          <Stack className="download-view-on-buttons">
            <Button
              className="download-view-on-buttons-cancel"
              type="button"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
            <Button
              className="download-view-on-buttons-select-all"
              type="button"
              onClick={() => selectAllIncidents()}
            >
              {selectedIncsForDownload.length === incidentIds.length
                ? 'Deselect all'
                : 'Select all'}
            </Button>
            {downloadButton()}
          </Stack>
        ) : (
          <Button
            className="download-incidents-button"
            type="button"
            onClick={() => setDownloadMode(true)}
          >
            Download Incidents
          </Button>
        )}
      </Stack>

      {incidents && (
        <VirtualisedListWrapper
          items={incidentIds}
          selectedItemIndex={selectedIncidentIndex ?? null}
          // eslint-disable-next-line react/no-unstable-nested-components
          listContent={(index) => {
            const incidentId = incidentIds[index];
            return incidents.byId[incidentId] ? (
              <IncidentItem
                downloadView={downloadMode}
                onCheckBoxSelected={onIncidentSelected}
                incident={incidents.byId[incidentId]}
                key={incidentId}
                selected={
                  selectedIncident?.id === incidentId ||
                  selectedIncsForDownload.includes(incidents.byId[incidentId])
                }
              />
            ) : null;
          }}
        />
      )}
    </Box>
  );
}

export default IncidentsList;

import { FilterAltOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Divider,
  IconButton,
  List,
  Typography,
} from '@mui/material';
import { Fragment, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReduxStatesToVerify, useEnsureReduxLoaded } from '../../api';
import Breadcrumb from '../../common-components/breadcrumb/breadcrumb';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { EDossiers, setSelectedDossier } from '../../main-menu/menu.slice';
import MapHelpers from '../../map/map.utils';
import { Country } from '../../models/risk_intelligence.model';
import {
  setCountriesLoading,
  setSelectedCountryId,
} from '../../state/countries/countries.slice';
import GeoHelper from '../../utils/geo-helpers.utils';
import { setFilters } from './countries-panel.slice';
import CountryListItem from './country-list-item';

export default function CountriesPanel() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    countries,
    countriesLoading,
    error,
    selectedCountry,
    selectedCountryId,
  } = useAppSelector((state) => state.countries);
  const { filters } = useAppSelector((state) => state.countriesPanel);

  const sortedCountries = useMemo(() => {
    if (!countries) return null;
    const allCountries = (countries || [])
      .filter((country) => country.title)
      .sort((a, b) => a.title.localeCompare(b.title));
    return allCountries;
  }, [countries]);

  const filteredCountries = useMemo(() => {
    if (!sortedCountries) return null;
    if (!filters.search) return sortedCountries;
    return sortedCountries.filter((country) =>
      country.title.toLowerCase().includes(filters.search.toLowerCase())
    );
  }, [filters.search, sortedCountries]);

  const updateSearchFilter = (value: string) => {
    dispatch(setFilters({ search: value }));
  };

  useEnsureReduxLoaded(
    [ReduxStatesToVerify.COUNTRIES, ReduxStatesToVerify.INCIDENTS],
    {
      before: () => {
        dispatch(setCountriesLoading(true));
      },
      after: () => {
        dispatch(setCountriesLoading(false));
      },
    }
  );

  const handleCountryClick = (country: Country) => {
    dispatch(setSelectedDossier(EDossiers.COUNTRY));
    dispatch(setSelectedCountryId(country.id));
    navigate(`/countries/${country.id}`);
    GeoHelper.getCountryBoundsFromShortcode(country.code).then((bbox) => {
      if (bbox) {
        MapHelpers.zoomToBBox(bbox);
      }
    });
  };

  return (
    <Box
      sx={{
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
      }}
    >
      <PanelTitle>Countries</PanelTitle>
      <Breadcrumb />
      {!filters.open && (
        <Box sx={{ display: 'flex', padding: '0px 20px' }}>
          <SimpleSearchBar
            handleChange={updateSearchFilter}
            filterValue={filters.search}
            placeholder="Search Countries"
            dataTestId="countries-search-bar"
          />
          <IconButton
            size="small"
            onClick={() => dispatch(setFilters({ open: true }))}
          >
            <FilterAltOutlined />
          </IconButton>
        </Box>
      )}
      {countriesLoading && <LoadingPanel />}
      {!countriesLoading && error && (
        <Alert data-testid="error-panel" severity="error">
          Error: Unable to fetch Countries
        </Alert>
      )}
      {!countriesLoading && !error && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {filteredCountries?.length ? (
            <List sx={{ overflowY: 'auto' }}>
              {filteredCountries.map((country, index) => (
                <Fragment key={country.id}>
                  <CountryListItem
                    country={country}
                    handleCountryClick={handleCountryClick}
                    selected={
                      country.id === selectedCountryId ||
                      country.title === selectedCountry?.title
                    }
                  />
                  {index !== filteredCountries.length - 1 && <Divider />}
                </Fragment>
              ))}
            </List>
          ) : (
            <Typography>No countries found</Typography>
          )}
        </>
      )}
    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuItem, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormikErrors, FormikTouched, useField } from 'formik';

interface SelectFieldControlledProps<
  T extends string | number | readonly string[] | undefined
> {
  label: string;
  name: string;
  placeholder?: string;
  options: { value: T; name: string }[];
  ariaLabel: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  classNames?: string;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  value: T;
  onChange: (e: SelectChangeEvent<T>) => void;
}

function MuiSelectFieldControlled<
  T extends string | number | readonly string[] | undefined
>(props: SelectFieldControlledProps<T>) {
  const {
    label,
    name,
    placeholder,
    options,
    ariaLabel,
    disabled,
    value,
    onChange,
  } = props;
  const [field, meta] = useField(name);

  const hasError = Boolean(meta.error && meta.touched);

  const menuProps = {
    MenuListProps: {
      sx: {
        backgroundColor: 'primary.main',
      },
    },
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="formik-select-fullwidth-label">{label}</InputLabel>
        <Select
          input={<OutlinedInput label={label} />}
          inputProps={{ 'data-testid': 'content-input' }}
          {...field}
          fullWidth
          labelId="formik-select-fullwidth-label"
          label={label}
          aria-label={ariaLabel}
          error={hasError}
          disabled={disabled}
          placeholder={placeholder}
          MenuProps={menuProps}
          value={value}
          onChange={onChange}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={`${option.value}`} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

MuiSelectFieldControlled.defaultProps = {
  placeholder: '',
  classNames: '',
  disabled: false,
};
export default MuiSelectFieldControlled;

import classNames from 'classnames';
import { useMobile } from './hooks';
import { nsTheme } from './theme';

function Logo() {
  const isMobile = useMobile();
  return (
    <img
      className={classNames('company-logo', {
        mobile: isMobile,
      })}
      src={nsTheme.image}
      alt="North Standard logo"
    />
  );
}

export default Logo;

import MapHelpers from '../../map.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addIncidentDatumSource from './add-incident-datum-source';

function addIncidentDatumLayer(layer: MapLayer | string) {
  // eslint-disable-next-line no-new
  return new Promise((resolve) => {
    addIncidentDatumSource(layer);
    MapHelpers.addLayer({
      id: layer,
      type: 'fill',
      source: layer,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'fill-color': [
          'match',
          ['get', 'type'],
          'average',
          '#FF8F9F',
          'max',
          '#FFACBB',
          /* other */ '#ccc',
        ],
        'fill-opacity': 0.3,
      },
    });
    resolve(MapHelpers);
  });
}

export default addIncidentDatumLayer;

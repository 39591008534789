import { FormikValues } from 'formik';

export interface NearbyVesselsFormValues extends FormikValues {
  radius: number;
  timeSinceLastAIS: number;
  centrePoint: {
    latitude: string;
    longitude: string;
  };
}

export const defaultValues: NearbyVesselsFormValues = {
  radius: 50,
  timeSinceLastAIS: 24,
  centrePoint: { latitude: '00° 00\' 00" N', longitude: '00° 00\' 00" E' },
};

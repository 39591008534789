import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import COPYRIGHT from '../assets/Copyright_symbol.png';

const footerStyles = StyleSheet.create({
  text: {
    textAlign: 'center',
    fontSize: 8,
  },
  container: {
    margin: '20px 10px -60px 10px', // https://github.com/diegomura/react-pdf/issues/1439#issuecomment-1321868253
    padding: '10px 30px 30px 30px',
    flexShrink: 0,
    alignItems: 'center',
  },
  copyrightImage: {
    height: 6,
    maxHeight: '100%',
    maxWidth: '100%',
    justifySelf: 'center',
    alignSelf: 'center',
    margin: '0 2px',
  },
});

export default function RIFooter() {
  return (
    <View fixed style={footerStyles.container}>
      <Text
        style={footerStyles.text}
        render={({ pageNumber, totalPages }) => {
          const now = new Date();
          const timeZone = 'UTC';
          const niceDate = now.toLocaleDateString(navigator.language, {
            timeZone,
            dateStyle: 'long',
          });
          const niceTime = now.toLocaleTimeString(navigator.language, {
            timeZone,
            hour12: false,
            timeStyle: 'short',
          });
          return (
            `Page ${pageNumber} of ${totalPages}. ` +
            `This information has been downloaded and is valid as of ${niceDate} ${niceTime} UTC.`
          );
        }}
      />
      <View style={{ flexDirection: 'row' }}>
        <Text style={footerStyles.text}>
          BUSINESS CONFIDENTIAL. Source: Risk Intelligence System. Copyright
        </Text>
        {/* https://github.com/diegomura/react-pdf/issues/2277 */}
        <Image src={COPYRIGHT} style={footerStyles.copyrightImage} />
        <Text style={footerStyles.text}>
          {`Risk Intelligence ${new Date().getFullYear()}.`}
        </Text>
      </View>
    </View>
  );
}

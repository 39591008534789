/* eslint-disable no-nested-ternary */
import { West } from '@mui/icons-material';
import AnimationIcon from '@mui/icons-material/Animation';
import { Box, Grid, IconButton, List, Typography } from '@mui/material';
import * as turf from '@turf/turf';
import { useCallback, useEffect, useRef } from 'react';
import {
  ReduxStatesToVerify,
  getIncidents,
  useEnsureReduxLoaded,
} from '../../../api';
import { getEntitiesInGeo } from '../../../api/risk-intelligence/search';
import RIAccordion from '../../../common-components/accordion/ri-accordion';
import { BackOneButton } from '../../../common-components/breadcrumb/breadcrumb';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useAccessControl from '../../../hooks/access-control/useAccessControl';
import {
  EDossiers,
  EMenuItems,
  setSelectedDossier,
  setSelectedOption,
} from '../../../main-menu/menu.slice';
import loadIncidentTypeMarkerImages from '../../../map/map-layer-manager/incident-utils/load-incident-type-marker-images';
import setIncidentFeatures from '../../../map/map-layer-manager/incident-utils/set-incident-features';
import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import setMaritimeAreasFeatures from '../../../map/map-layer-manager/maritime-areas-utils/set-maritime-areas-features';
import setPortFeatures from '../../../map/map-layer-manager/port-utils/set-port-features';
import MapHelpers from '../../../map/map.utils';
import {
  ExpandedId,
  MaritimeArea,
} from '../../../models/risk_intelligence.model';
import { Route } from '../../../models/routes.model';
import { addHistoryItem } from '../../../nav-history.slice';
import {
  setLoading,
  setSelectedRouteAreas,
  setSelectedRouteIncidentId,
  setSelectedRouteIncidents,
  setSelectedRoutePorts,
} from '../../../state/routes/routes.slice';
import useDownloadIncidents from '../../../utils/incidents-download';
import { convertKmToNauticalMiles } from '../../../utils/measurement-helpers';
import { neatDisplayNumber } from '../../../utils/text-formatting.utils';
import IncidentItem from '../../incidents-panel/incident-item/incident-item';
import {
  Incident,
  IncidentFilters,
  IncidentType,
} from '../../incidents-panel/incident.model';
import RIMaritimeAreaCard from '../../ri-maritime-areas-panel/ri-maritime-area-card/ri-maritime-area-card';
import { setSelectedArea } from '../../ri-maritime-areas-panel/ri-maritime-areas.slice';
import {
  generateRangeRingPoint,
  onRangeRingPointAdd,
} from '../../tools-panel/range-rings/range-ring-helpers';
import { RangeRingPoint } from '../../tools-panel/range-rings/range-ring.model';
import {
  ToolsPanelViewState,
  setRangeRingPoints,
  setToolsPanelViewState,
} from '../../tools-panel/tools-panel.slice';
import PortItem from '../../world-ports-panel/port-item/port-item';
import { Port } from '../../world-ports-panel/world-ports.model';
import RouteCalculations from './route-calculations/route-calculations';
import './route-details.scss';

function toggleRouteRelatedLayers(
  selectedOption: EMenuItems,
  mapInitialised: boolean
) {
  if (!mapInitialised) {
    return;
  }
  const routeVisibility = selectedOption === EMenuItems.ROUTES;

  // Incident layers
  MapHelpers.setLayerVisibility(MapLayer.INCIDENTS, !routeVisibility);
  MapHelpers.setLayerVisibility(MapLayer.INCIDENT_CLUSTERS, !routeVisibility);
  MapHelpers.setLayerVisibility(
    MapLayer.INCIDENT_CLUSTER_COUNT,
    !routeVisibility
  );

  // Maritime area layers
  MapHelpers.setLayerVisibility(MapLayer.RI_MARITIME_AREAS, !routeVisibility);

  // Port layers
  MapHelpers.setLayerVisibility(MapLayer.PORTS, !routeVisibility);
  MapHelpers.setLayerVisibility(MapLayer.PORT_CLUSTERS, !routeVisibility);
  MapHelpers.setLayerVisibility(MapLayer.PORT_CLUSTER_COUNT, !routeVisibility);

  // Route layers
  MapHelpers.setLayerVisibility(MapLayer.ROUTES_INCIDENTS, routeVisibility);
  MapHelpers.setLayerVisibility(
    MapLayer.ROUTES_INCIDENT_CLUSTERS,
    routeVisibility
  );
  MapHelpers.setLayerVisibility(
    MapLayer.ROUTES_INCIDENT_CLUSTER_COUNT,
    routeVisibility
  );

  MapHelpers.setLayerVisibility(MapLayer.ROUTES_PORTS, routeVisibility);
  MapHelpers.setLayerVisibility(MapLayer.ROUTES_PORT_CLUSTERS, routeVisibility);
  MapHelpers.setLayerVisibility(
    MapLayer.ROUTES_PORT_CLUSTER_COUNT,
    routeVisibility
  );

  MapHelpers.setLayerVisibility(
    MapLayer.ROUTES_MARITIME_AREAS,
    routeVisibility
  );
}

function RouteInfoTable({
  routeDistance,
  routeRadius,
}: {
  routeDistance: number;
  routeRadius: number;
}) {
  return (
    <Box sx={{ p: '0.2rem 1rem' }}>
      <Grid container rowGap={1}>
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="subtitle2" align="left">
              Route Length
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" textAlign="right">
              {neatDisplayNumber(routeDistance)} NM
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="subtitle2" align="left">
                Route Intelligence Radius
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" textAlign="right">
                {neatDisplayNumber(convertKmToNauticalMiles(routeRadius))} NM
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function setRouteIncidentFeatures(
  incidentTypes: IncidentType[],
  selectedRouteIncidents: Incident[]
) {
  loadIncidentTypeMarkerImages(incidentTypes).then(() => {
    setIncidentFeatures(MapLayer.ROUTES_INCIDENTS, selectedRouteIncidents);
  });
}

function setRoutePortFeatures(selectedRoutePorts: Port[]) {
  setPortFeatures(MapLayer.ROUTES_PORTS, selectedRoutePorts);
}

function setRouteAreaFeatures(selectedRouteAreas: MaritimeArea[]) {
  setMaritimeAreasFeatures(MapLayer.ROUTES_MARITIME_AREAS, selectedRouteAreas);
}

function makeRouteVisible(route: Route) {
  const geojson = route.route_data;
  MapHelpers.zoomToFeatureCollection(geojson);
  MapHelpers.setLayerVisibility(route.route_id, true);
  MapHelpers.setLayerVisibility(`${route.route_id}_radius`, true);
  MapHelpers.setLayerVisibility(`${route.route_id}_labels`, true);
}

interface FetchDataResponse {
  incidents: Incident[];
  ports: ExpandedId[];
  areas: ExpandedId[];
}

function SpecificRoute({ route }: { route: Route }) {
  const dispatch = useAppDispatch();

  const secondaryMenuOpen = useAppSelector(
    (state) => state.menu.secondaryMenuOpen
  );
  const selectedOption = useAppSelector((state) => state.menu.selectedOption);

  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);

  const loading = useAppSelector((state) => state.routes.loading);
  const error = useAppSelector((state) => state.routes.error);
  const selectedRouteIncidents = useAppSelector(
    (state) => state.routes.selectedRouteIncidents
  );
  const selectedRouteIncidentId = useAppSelector(
    (state) => state.routes.selectedRouteIncidentId
  );
  const selectedRoutePorts = useAppSelector(
    (state) => state.routes.selectedRoutePorts
  );
  const selectedRouteAreas = useAppSelector(
    (state) => state.routes.selectedRouteAreas
  );
  const incidentTypes = useAppSelector(
    (state) => state.incidents.incidentTypes
  );
  const incidentFilters = useAppSelector(
    (state) => state.incidentsPanel.filters
  );
  const reduxPorts = useAppSelector((state) => state.ports.ports);
  const reduxAreas = useAppSelector((state) => state.riMaritimeAreas.areas);
  const routeDistance = turf.length(
    turf.lineString(route.route_data.geometry.coordinates),
    { units: 'nauticalmiles' }
  );

  const ulRef = useRef<HTMLUListElement>(null);
  const { canAccessMapTools } = useAccessControl();

  const getRangeRingsForRoute = (): RangeRingPoint[] => {
    const rangeRings: RangeRingPoint[] =
      route.route_data.geometry.coordinates.map((point) => {
        const rangeRing: RangeRingPoint = generateRangeRingPoint(
          point[1],
          point[0]
        );
        onRangeRingPointAdd(rangeRing);
        return rangeRing;
      });

    dispatch(setRangeRingPoints(rangeRings));
    return rangeRings;
  };

  // /search/geo does not return port location, so will need to crossreference with /ports
  useEnsureReduxLoaded([
    ReduxStatesToVerify.COUNTRIES, // ensuring ports have the correct country subtitle requires countries
    ReduxStatesToVerify.PORTS,
    ReduxStatesToVerify.MARITIME_AREAS,
  ]);

  useEffect(() => {
    makeRouteVisible(route);
  }, [route, mapInitialised]);

  const radiusBuffer = turf.buffer(
    route.route_data,
    convertKmToNauticalMiles(route.radius),
    {
      units: 'nauticalmiles',
    }
  );

  const requestFilters: IncidentFilters = {
    ...incidentFilters,
    geo: radiusBuffer.geometry,
  };

  const fetchDataForRoute = useCallback((): Promise<FetchDataResponse> => {
    const incidentsPromise = getIncidents({ filters: requestFilters });

    const entitiesPromise = getEntitiesInGeo(radiusBuffer.geometry);

    return Promise.allSettled([incidentsPromise, entitiesPromise]).then(
      ([incidentResult, entitiesResult]) => {
        const response: Partial<FetchDataResponse> = {};
        if (incidentResult.status === 'fulfilled') {
          response.incidents = incidentResult.value;
        } else {
          response.incidents = [];
        }
        if (entitiesResult.status === 'fulfilled') {
          response.ports = entitiesResult.value.ports;
          response.areas = entitiesResult.value.areas;
        } else {
          response.ports = [];
          response.areas = [];
        }
        return response as FetchDataResponse;
      }
    );
  }, [route, incidentFilters]);

  useEffect(() => {
    if (selectedRouteIncidents) {
      if (ulRef.current) {
        const selectedLi = ulRef.current.children[
          selectedRouteIncidents.findIndex(
            (incident) => incident.id === selectedRouteIncidentId
          )
        ] as HTMLElement;

        if (selectedLi) {
          selectedLi.scrollIntoView({ behavior: 'smooth', block: 'center' });

          setTimeout(() => {
            dispatch(setSelectedRouteIncidentId(null));
          }, 5000);
        }
      }
    }

    dispatch(setLoading());
    fetchDataForRoute().then(({ incidents, ports, areas }) => {
      dispatch(setSelectedRouteIncidents(incidents));

      if (reduxPorts) {
        const selectedRoutePortIds = ports.map((port) => port.id);
        // /search/geo does not return port location, so will need to crossreference with /ports
        const foundPorts = reduxPorts.filter(
          (reduxPort) =>
            reduxPort.ri && selectedRoutePortIds.includes(reduxPort.ri.id)
        );

        dispatch(setSelectedRoutePorts(foundPorts));
        setRoutePortFeatures(foundPorts);
      }
      if (reduxAreas) {
        const selectedRouteAreaIds = areas.map((area) => area.id);
        // /search/geo does not return area location, so will need to crossreference with /areas
        const foundAreas = reduxAreas.filter((reduxArea) =>
          selectedRouteAreaIds.includes(reduxArea.id)
        );
        dispatch(setSelectedRouteAreas(foundAreas));
        setRouteAreaFeatures(foundAreas);
      }
    });
  }, [reduxPorts, reduxAreas, fetchDataForRoute]);

  useEffect(() => {
    if (selectedRouteIncidents && incidentTypes) {
      setRouteIncidentFeatures(incidentTypes!, selectedRouteIncidents);
    }
    if (selectedRoutePorts) {
      setRoutePortFeatures(selectedRoutePorts);
    }
    if (selectedRouteAreas) {
      setRouteAreaFeatures(selectedRouteAreas);
    }
  }, [
    selectedRouteIncidents,
    selectedRouteAreas,
    selectedRoutePorts,
    incidentTypes,
  ]);

  useEffect(() => {
    toggleRouteRelatedLayers(selectedOption, mapInitialised);
  }, [selectedOption, mapInitialised]);

  const nonDetachedStyling = {
    display: 'flex',
    marginTop: '1.25em',
    alignItems: 'center',
    marginBottom: '0.5em',
  };

  const detachedStyling = {
    px: '1rem',
    py: '0.5rem',
  };
  const selectedRouteIncidentsIds = selectedRouteIncidents?.map(
    (incidents) => incidents.id
  );

  const { downloadIncidentsButton } = useDownloadIncidents(
    selectedRouteIncidentsIds || [],
    requestFilters
  );

  return !error ? (
    <Box
      data-testid="specificRoutePanel"
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <Box sx={!secondaryMenuOpen ? nonDetachedStyling : detachedStyling}>
        {!secondaryMenuOpen && <BackOneButton />}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            fontWeight={500}
            data-testid="dossier-title"
            textAlign="left"
            sx={{ flex: '1' }}
          >
            Route Details - {route.route_name}
          </Typography>
          {canAccessMapTools ? (
            selectedOption === EMenuItems.TOOLS ? (
              <IconButton
                disableRipple
                title="Back to Routes"
                sx={{
                  marginRight: '1em',
                  '&:hover': {
                    bgcolor: 'primary.light',
                  },
                }}
                onClick={() => {
                  dispatch(setSelectedOption(EMenuItems.ROUTES));
                }}
              >
                <West />
              </IconButton>
            ) : (
              <IconButton
                disableRipple
                title="Range Rings"
                sx={{
                  marginRight: '1em',
                  '&:hover': {
                    bgcolor: 'primary.light',
                  },
                }}
                onClick={() => {
                  dispatch(setRangeRingPoints(getRangeRingsForRoute()));
                  dispatch(setSelectedOption(EMenuItems.TOOLS));
                  dispatch(
                    setToolsPanelViewState(ToolsPanelViewState.RANGE_RINGS)
                  );
                }}
              >
                <AnimationIcon />
              </IconButton>
            )
          ) : null}
        </Box>
      </Box>
      <Typography
        variant="subtitle2"
        textAlign="left"
        sx={{ p: '0.2rem 1rem' }}
      >
        {route.description}
      </Typography>

      <RouteInfoTable
        routeDistance={routeDistance} // already in nautical miles
        routeRadius={route.radius} // km (TODO: convert to nautical miles)
      />

      <Box sx={{ flexGrow: 1, minHeight: 0, overflowY: 'auto' }}>
        <RIAccordion
          title="Route Calculations"
          unmountOnExit={false}
          content={<RouteCalculations selectedRoute={route} />}
        />

        {loading ? (
          <LoadingPanel />
        ) : (
          <>
            <RIAccordion
              unmountOnExit={false}
              title={`Incidents (${selectedRouteIncidents?.length || 0})`}
              content={
                <>
                  <div>{downloadIncidentsButton()}</div>
                  <List disablePadding ref={ulRef}>
                    {selectedRouteIncidents?.map((incident) => (
                      <IncidentItem
                        incident={incident}
                        key={incident.id}
                        downloadView={false}
                        selected={false}
                        onCheckBoxSelected={() => {}}
                        route={route}
                        dense
                      />
                    ))}
                  </List>
                </>
              }
            />
            <RIAccordion
              title={`Ports (${selectedRoutePorts?.length || 0})`}
              content={
                <List disablePadding>
                  {selectedRoutePorts?.map((port: Port) => (
                    <PortItem
                      port={port}
                      route={route}
                      key={port.ri ? port.ri.id : port.WPI}
                      dense
                    />
                  ))}
                </List>
              }
            />
            <RIAccordion
              title={`Areas (${selectedRouteAreas?.length || 0})`}
              content={
                <List disablePadding>
                  {selectedRouteAreas?.map((area) => (
                    <RIMaritimeAreaCard
                      title={area.title}
                      onClick={() => {
                        MapHelpers.zoomToPolygon(area.geo);
                        dispatch(setSelectedArea(area));
                        dispatch(
                          addHistoryItem({ type: 'route', properties: route })
                        );
                        dispatch(
                          setSelectedDossier(EDossiers.RI_MARITIME_AREA)
                        );
                      }}
                      region=""
                      key={area.id}
                    />
                  ))}
                </List>
              }
            />
          </>
        )}
      </Box>
    </Box>
  ) : null;
}

export default SpecificRoute;

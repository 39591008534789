import { Alert, Button, Divider, List, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { deleteDocument, downloadDocument } from '../../api/documents';
import { useAppSelector } from '../../hooks';
import { DocumentWithMeta } from '../../models/document';
import SimpleDialog from '../dialog/SimpleDialog';
import LoadingButton from '../loading-button/loading-button';
import DocumentItem from './document-item';

interface DocumentsListProps {
  documents: DocumentWithMeta[];
  refreshDocuments: () => void;
  disableDivider?: boolean;
}

function DocumentsList(props: DocumentsListProps) {
  const { documents, refreshDocuments, disableDivider } = props;

  const [selectedDocument, setSelectedDocument] =
    useState<DocumentWithMeta | null>();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalSuccess, setDeleteModalSuccess] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteModalError, setDeleteModalError] = useState(false);

  const onDeleteModalClose = () => {
    if (deleteModalSuccess) {
      setSelectedDocument(null);
      refreshDocuments();
    }
    setDeleteModalVisible(false);
    setDeleteModalSuccess(false);
    setDeleteModalLoading(false);
  };

  const handleDownloadDocument = (doc: DocumentWithMeta) => {
    downloadDocument(doc);
  };

  const handleDeleteDocument = (doc: DocumentWithMeta) => {
    setDeleteModalLoading(true);
    setDeleteModalSuccess(false);
    deleteDocument(doc)
      .then(() => {
        setDeleteModalError(false);
        setDeleteModalSuccess(true);
      })
      .catch(() => {
        setDeleteModalSuccess(false);
        setDeleteModalError(true);
      })
      .finally(() => {
        setDeleteModalLoading(false);
      });
  };

  const canDelete = (doc: DocumentWithMeta) => {
    const cognitoId = useAppSelector((state) => state.user.idToken?.sub);
    return doc.metadata.cognito_id === cognitoId;
  };

  useEffect(() => {
    if (deleteModalSuccess) onDeleteModalClose();
  }, [deleteModalSuccess]);

  return documents.length ? (
    <>
      <List
        disablePadding
        data-testid="documents-list"
        sx={{ overflowY: 'auto' }}
      >
        {documents.map((document, index) => (
          <Fragment key={document.metadata.document_id}>
            <DocumentItem
              document={document}
              onDocumentDownload={handleDownloadDocument}
              onDocumentDelete={() => {
                setSelectedDocument(document);
                setDeleteModalVisible(true);
              }}
              canDelete={canDelete(document) ?? true}
            />
            {!disableDivider && index !== documents.length - 1 && <Divider />}
          </Fragment>
        ))}
      </List>
      <SimpleDialog
        open={deleteModalVisible}
        onClose={onDeleteModalClose}
        title="Delete Document"
        actions={
          <>
            <div>
              <LoadingButton
                data-testid="delete-document-submit-button"
                loading={deleteModalLoading}
                success={deleteModalSuccess}
                type="submit"
                variant="contained"
                onClick={() => {
                  handleDeleteDocument(selectedDocument!);
                }}
              >
                Delete
              </LoadingButton>
              <Button onClick={onDeleteModalClose}>Cancel</Button>
            </div>
            {deleteModalError && (
              <Alert severity="error">Document Deletion Failed.</Alert>
            )}
          </>
        }
      >
        <Typography>Are you sure you wish to delete this document?</Typography>
      </SimpleDialog>
    </>
  ) : (
    <Typography
      sx={{
        paddingLeft: '16px',
        fontSize: '14px',
      }}
    >
      No documents to show
    </Typography>
  );
}

DocumentsList.defaultProps = {
  disableDivider: false,
};

export default DocumentsList;

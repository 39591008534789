import MapLayerVisibility from '../map/map-layer-manager/map-layer-visibility.enum';

export const IMO_LENGTH = 7;
export const MMSI_LENGTH = 9;

export const VESSEL_COLOUR_ORANGE = '#FF7F27';
export const VESSEL_COLOUR_WHITE = '#FFFFFF';
export const VESSEL_COLOUR_GREEN = '#005B0A';

export const GENERIC_VESSEL_SIZE: mapboxgl.Expression = [
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#interpolate
  'interpolate',
  ['linear'],
  ['zoom'],
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#camera-expressions
  // map zoom level 2, circle radius is 0.25px
  2,
  0.25,
  // map zoom level 8, circle radius is 0.4px
  8,
  0.4,
  // map zoom level 16, circle radius is 0.5px
  16,
  0.5,
  // map zoom level 20, circle radius is 0.75px
  20,
  0.75,
];

export const GENERIC_VESSEL: mapboxgl.SymbolLayer = {
  id: '',
  type: 'symbol',
  source: '',
  metadata: {},
  layout: {
    visibility: MapLayerVisibility.VISIBLE,
    'text-field': '',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 12,
    'text-variable-anchor': ['top'],
    'text-justify': 'center',
    'text-optional': true,
    'text-offset': [0, 0.8],
    'icon-image': '',
    'icon-allow-overlap': true,
    'icon-rotation-alignment': 'map',
    'icon-rotate': ['number', ['get', 'course'], 360],
    'icon-size': GENERIC_VESSEL_SIZE,
  },
  paint: {
    'text-halo-color': '#ffffff',
    'text-halo-width': 1,
  },
};

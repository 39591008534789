import { Box } from '@mui/material';

interface TabPanelProps {
  index: number;
  value: number;
}

function TabPanel({
  index,
  value,
  children,
  ...delegated
}: React.PropsWithChildren<TabPanelProps>) {
  return (
    <Box
      // Allow the tab panel to grow to fill the available space.
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        display: 'flex',
        flexDirection: 'column',
        ...(value !== index && { maxHeight: '0' }),
      }}
      role="tabpanel"
      hidden={value !== index}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...delegated}
    >
      {value === index && children}
    </Box>
  );
}

export default TabPanel;

import { Box, Button } from '@mui/material';
import { uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  HistoricVesselData,
  getVesselLocationsDAAS,
  mapResponseToHistoricVesselPoints,
} from '../../api/vessels';
import ErrorSnackbar from '../../common-components/error-snackbar/error-snackbar';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import useAccessControl from '../../hooks/access-control/useAccessControl';
import {
  EDossiers,
  EMenuItems,
  setSelectedDossier,
  setSelectedOption,
} from '../../main-menu/menu.slice';
import { decimalToDMS } from '../../map/map-controls.utils';
import { HistoryFormValues } from '../../maritime-menu-options/history-panel/history-form/history-form-validators';
import {
  setError,
  setHistoryFormValues,
  setVesselHistoryData,
} from '../../maritime-menu-options/history-panel/history-panel.slice';
import VesselHistoryController from '../../maritime-menu-options/history-panel/vessel-history-controller.utils';
import {
  setNearbyVessels,
  setNearbyVesselsFormValues,
} from '../../maritime-menu-options/tools-panel/nearby-vessels/nearby-vessels.slice';
import {
  ToolsPanelViewState,
  setToolsPanelViewState,
} from '../../maritime-menu-options/tools-panel/tools-panel.slice';
import { Vessel, VesselSource } from '../../models/vessel.model';
import RiVesselInformation from './ri-vessel-information/ri-vessel-information';
import './vessel-dossier.scss';
import { setSelectedVessels } from './vessel-dossier.slice';
import VesselInformation from './vessel-information/vessel-information';

interface VesselDossierTabProps {
  selectedVessel: Vessel | null;
  secondaryMenuOpen: boolean;
  handleViewHistory: () => void;
  handleNearbyVessels: () => void;
}

function VesselDossierTab(props: VesselDossierTabProps) {
  const {
    selectedVessel,
    secondaryMenuOpen,
    handleViewHistory,
    handleNearbyVessels,
  } = props;

  const { canAccessNearbyVessels } = useAccessControl();

  return (
    <>
      <Box sx={{ display: 'flow', px: 1 }}>
        {selectedVessel?.source === VesselSource.AIS && (
          <VesselInformation
            isDocked={secondaryMenuOpen}
            selectedVessel={selectedVessel}
          />
        )}
        {selectedVessel?.source === VesselSource.RISK_INTELLIGENCE && (
          <RiVesselInformation
            isDocked={secondaryMenuOpen}
            selectedVessel={selectedVessel}
          />
        )}
      </Box>
      {selectedVessel && (
        <>
          <Box sx={{ display: 'flow', px: 1, margin: '0.5rem 0rem' }}>
            <Button onClick={handleViewHistory} variant="contained" fullWidth>
              View History
            </Button>
          </Box>
          {canAccessNearbyVessels && (
            <Box sx={{ display: 'flow', px: 1, margin: '0.5rem 0rem' }}>
              <Button
                onClick={handleNearbyVessels}
                variant="contained"
                fullWidth
              >
                Nearby Vessels
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
}

function VesselDossier() {
  const dispatch = useAppDispatch();

  const [entityError, setEntityError] = useState(false);
  const { selectedVessels } = useAppSelector((state) => state.vesselDossier);
  const { secondaryMenuOpen } = useAppSelector((state) => state.menu);
  const vesselHistoryData = useAppSelector(
    (state) => state.historyPanel.vesselHistoryData
  );

  const isMobile = useMobile();

  const alreadyShowingThisVessel = () => {
    if (!vesselHistoryData || vesselHistoryData.data.length === 0) {
      return false;
    }
    const vesselMmsisInHistory = uniq(
      vesselHistoryData.data.map((vessel) => vessel.vessel.mmsi?.toString())
    );
    const vesselMmsisInSelected = selectedVessels?.allIds.map(
      (vesselId) => selectedVessels.byId[vesselId].mmsi
    );
    if (
      !vesselMmsisInSelected ||
      !vesselMmsisInHistory ||
      vesselMmsisInSelected.length === 0 ||
      vesselMmsisInHistory.length === 0
    ) {
      return false;
    }
    // if there are selected vessels that are not in the history, we need to do a request.
    if (
      vesselMmsisInSelected.some(
        (mmsi) => !vesselMmsisInHistory.includes(mmsi?.toString())
      )
    ) {
      return false;
    }
    return true;
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEntityError(false);
    dispatch(setSelectedVessels(null));
    dispatch(setSelectedDossier(EDossiers.NOTHING));
  };

  const getDefaultDuration = 7;

  const getDefaultFrequency = '1m';

  const handleViewHistory = (vessels: Vessel[]) => {
    const identifiers: string[] = [];
    vessels.forEach((vessel) => {
      if (vessel?.mmsi) {
        identifiers.push(String(vessel.mmsi));
      } else if (vessel?.imo) {
        identifiers.push(String(vessel.imo));
      }
    });
    const updatedFormValues: Partial<HistoryFormValues> = {
      identifiers: identifiers.join(' '),
    };

    if (updatedFormValues.identifiers?.length) {
      dispatch(setHistoryFormValues(updatedFormValues));
      dispatch(setSelectedOption(EMenuItems.HISTORY));
    }
  };

  const handleNearbyVessels = (vessel: Vessel) => {
    dispatch(setSelectedOption(EMenuItems.TOOLS));
    dispatch(setToolsPanelViewState(ToolsPanelViewState.NEARBY_VESSELS));
    dispatch(
      setNearbyVesselsFormValues({
        centrePoint: {
          latitude: decimalToDMS(vessel.latitude || 0, 'latitude'),
          longitude: decimalToDMS(vessel.longitude || 0, 'longitude'),
        },
        timeSinceLastAIS: 12,
        radius: 10,
      })
    );
    dispatch(setNearbyVessels(null));
  };

  useEffect(() => {
    if (selectedVessels) {
      // check if we're already displaying this vessel's data
      if (alreadyShowingThisVessel()) {
        return;
      }
      VesselHistoryController.clearHistoricalLocationLayers();
      const historicVesselDataPromises = Object.values(
        selectedVessels.byId
      ).map((selectedVessel) => {
        if (
          selectedVessels &&
          selectedVessel.source === VesselSource.AIS &&
          selectedVessel.mmsi
        ) {
          return getVesselLocationsDAAS(
            String(selectedVessel.mmsi),
            selectedVessel.endTimestamp
              ? new Date(selectedVessel.endTimestamp)
              : new Date(),
            getDefaultDuration,
            getDefaultFrequency
          )
            .then((response) => {
              const points = mapResponseToHistoricVesselPoints(response);
              points.sort((a, b) => a.timestamp - b.timestamp);
              if (points.length > 0) {
                VesselHistoryController.createHistoricalLocationLayers(
                  points,
                  undefined
                );

                return {
                  vessel: {
                    name: selectedVessel.name,
                    mmsi: selectedVessel.mmsi,
                    imo: selectedVessel.imo,
                    latitude: selectedVessel.latitude,
                    longitude: selectedVessel.longitude,
                    course: selectedVessel.course,
                  },
                  messages: points,
                };
              }
              return null;
            })
            .catch((err) => {
              setError(err.message);
            });
        }
        return null;
      });
      Promise.all(historicVesselDataPromises).then((responses) => {
        const definedResponses = responses.filter(
          (response) => !!response
        ) as HistoricVesselData[];
        dispatch(
          setVesselHistoryData({
            data: definedResponses,
            formValues: {} as HistoryFormValues,
          })
        );
      });
    }
  }, [selectedVessels]);

  return (
    <Box
      data-testid="vessel-dossier-container"
      className={isMobile ? 'mobile' : ''}
      sx={{
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        '&.mobile': {
          width: '100vw',
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
    >
      {/* Displays loading spinner if directly loading page to open dossier */}
      {!selectedVessels?.allIds && <LoadingPanel />}

      {selectedVessels?.allIds.length === 1 && (
        <VesselDossierTab
          selectedVessel={selectedVessels.byId[selectedVessels.allIds[0]]}
          secondaryMenuOpen={secondaryMenuOpen}
          handleViewHistory={() =>
            handleViewHistory([selectedVessels.byId[selectedVessels.allIds[0]]])
          }
          handleNearbyVessels={() =>
            handleNearbyVessels(selectedVessels.byId[selectedVessels.allIds[0]])
          }
        />
      )}
      {(selectedVessels?.allIds.length || 0) > 1 && (
        <Tabs>
          <TabList>
            {Object.values(selectedVessels?.byId || {}).map((vessel) => (
              <Tab key={vessel.vessel_id}>{vessel.name}</Tab>
            ))}
          </TabList>
          {Object.values(selectedVessels?.byId || {}).map((vessel) => (
            <TabPanel key={vessel.vessel_id}>
              <VesselDossierTab
                selectedVessel={vessel}
                secondaryMenuOpen={secondaryMenuOpen}
                handleViewHistory={() =>
                  handleViewHistory(Object.values(selectedVessels?.byId || {}))
                }
                handleNearbyVessels={() => handleNearbyVessels(vessel)}
              />
            </TabPanel>
          ))}
        </Tabs>
      )}

      <ErrorSnackbar
        open={entityError}
        onClose={handleSnackbarClose}
        message="Unable to find vessel provided in URL"
      />
    </Box>
  );
}

export default VesselDossier;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAppSelector } from '../../hooks';

import MapHelpers from '../../map/map.utils';
import ItemToggle from '../item-toggle/item-toggle';
import ItemToggleVariants from '../item-toggle/item-toggle-variants';
import {
  useChildLayerVisibility,
  useIsFirstRender,
  useLayerVisibility,
} from './layer-toggle-hooks';

export interface LayerToggleProps {
  layers: string[];
  layerGroups: string[];
  label?: string;
  disabled?: boolean;
  sx?: any;
  onToggleOn?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isSideMenuToggle?: boolean;
  variant?: ItemToggleVariants;
}

function LayerToggle({
  label,
  layers: layerIds,
  layerGroups: groupLayerIds,
  disabled,
  sx,
  onMouseEnter,
  onMouseLeave,
  variant,
  isSideMenuToggle,
  onToggleOn,
}: LayerToggleProps) {
  const isFirstRender = useIsFirstRender();
  const { mapInitialised } = useAppSelector((state) => state.map);
  const {
    childLayerIds,
    childLayerVisibilityHistory,
    setChildLayerVisibilityHistory,
    hasVisibleChildLayer,
  } = useChildLayerVisibility(groupLayerIds ?? [], mapInitialised);

  const { hasVisibleLayer } = useLayerVisibility(
    layerIds ?? [],
    mapInitialised
  );

  const isDisabled =
    !mapInitialised ||
    disabled ||
    (isFirstRender && !hasVisibleChildLayer && !hasVisibleLayer);

  const hasNoEffect =
    !mapInitialised ||
    (isSideMenuToggle &&
      (layerIds === undefined || layerIds?.length === 0) &&
      (childLayerIds === undefined ||
        childLayerIds?.length === 0 ||
        Object.values(childLayerVisibilityHistory).some(
          (value) => value === true
        ) === false));

  if (hasNoEffect) {
    return null;
  }

  return (
    <ItemToggle
      value={hasVisibleChildLayer || hasVisibleLayer}
      onChange={(visible: boolean) => {
        (layerIds ?? []).forEach((source) => {
          if (MapHelpers.getLayer(source)) {
            MapHelpers.setLayerVisibility(source, visible);
          }
        });

        if (!visible) {
          const prevVisibility: Record<string, boolean> = {};

          childLayerIds.forEach((id) => {
            if (MapHelpers.getLayer(id)) {
              prevVisibility[id] = MapHelpers.isLayerVisible(id);
            }
          });

          childLayerIds.forEach((source) => {
            if (MapHelpers.getLayer(source)) {
              MapHelpers.setLayerVisibility(source, visible);
            }
          });
          setChildLayerVisibilityHistory(prevVisibility);
        } else {
          childLayerIds.forEach((source) => {
            if (MapHelpers.getLayer(source)) {
              MapHelpers.setLayerVisibility(
                source,
                childLayerVisibilityHistory[source]
              );
            }
          });
        }
        onToggleOn?.();
      }}
      toggledTooltip={label && `Show ${label}`}
      untoggledTooltip={label && `Hide ${label}`}
      disabled={isDisabled}
      sx={sx}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      variant={variant}
    />
  );
}

LayerToggle.defaultProps = {
  onToggleOn: () => {},
  label: '',
  disabled: false,
  sx: {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  isSideMenuToggle: false,
  variant: 'default',
};

export default LayerToggle;

import { Box, Tab, TabTypeMap, Tooltip } from '@mui/material';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

type Props = DefaultComponentProps<TabTypeMap>;

// https://github.com/mui/material-ui/issues/12597#issuecomment-455244379
function TabWithTooltip({ disabled, ...tabProps }: Props) {
  return (
    <Tooltip
      title={
        disabled
          ? 'You require a NorthStandard account to access these features.'
          : ''
      }
    >
      <Box
        sx={{
          backgroundColor: disabled ? 'grey' : 'inherit',
          display: 'flex',
          width: '100%',
        }}
      >
        <Tab
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tabProps}
          disabled={disabled}
        />
      </Box>
    </Tooltip>
  );
}

export default TabWithTooltip;

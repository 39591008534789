/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from 'classnames';

import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { Box, Button, FormHelperText, Grid, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useMobile } from '../../hooks';
import SimpleDialog from '../dialog/SimpleDialog';
import MuiSelectFieldControlled from '../form-fields/mui-select-field-controlled';
import { DateRangeMutators, DateRangePresets } from './date-range-presets';

dayjs.extend(utc);

interface DateFormikValues {
  dateFrom: Dayjs;
  dateTo: Dayjs;
  datePreset: string;
}

export enum EDateRangeModalVariants {
  FULL_RANGE = 'FULL_RANGE',
  THREE_MONTHS = 'THREE_MONTHS',
}

interface DateRangeModalProps {
  title: string;
  visible: boolean;

  onClose: () => void;
  onSelect: (values: DateFormikValues) => void; // todo sort typing?
  loading: boolean;
  success: boolean;
  error: boolean;
  initialStart?: string | null;
  initialEnd?: string | null;
  variant: EDateRangeModalVariants;
}

interface RenderDateRangeModalFormProps {
  onSelect: (values: DateFormikValues) => void;
  onClose: () => void;
  // eslint-disable-next-line react/require-default-props
  initialStart?: string | null;
  // eslint-disable-next-line react/require-default-props
  initialEnd?: string | null;
  variant: EDateRangeModalVariants;
}

function RenderDateRangeModalForm(props: RenderDateRangeModalFormProps) {
  const { onSelect, onClose, initialStart, initialEnd, variant } = props;
  const isMobile = useMobile();

  const validationSchema = yup.object().shape({
    dateFrom: yup
      .date()
      .required('Required')
      .max(new Date(), 'Start date cannot be in future'),
    dateTo: yup
      .date()
      .required('Required')
      .min(yup.ref('dateFrom'), 'End date cannot be before start date'),
  });

  const formik = useFormik<DateFormikValues>({
    initialValues: {
      dateFrom: dayjs.utc(initialStart, 'YYYY-MM-DD'),
      dateTo: dayjs.utc(initialEnd, 'YYYY-MM-DD'),
      datePreset: '',
    },
    onSubmit: (values) => {
      onSelect(values);
      formik.resetForm();
    },
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
  });

  const calculateMinDate = (range: EDateRangeModalVariants) => {
    let minDate: Date | null;
    minDate = new Date();
    if (range === EDateRangeModalVariants.THREE_MONTHS) {
      minDate.setMonth(minDate.getMonth() - 3);
    } else {
      minDate = null;
    }
    return minDate;
  };

  const generateDropDownOptions = () => {
    let options = [
      {
        name: 'Last 24 hours',
        value: DateRangePresets.ONE_DAY,
      },
      { name: 'Last week', value: DateRangePresets.ONE_WEEK },
      { name: 'Last 2 weeks', value: DateRangePresets.TWO_WEEKS },
      { name: 'Last month', value: DateRangePresets.ONE_MONTH },
      { name: 'Last 3 months', value: DateRangePresets.THREE_MONTHS },
    ];
    if (variant === EDateRangeModalVariants.FULL_RANGE) {
      options = [
        ...options,
        { name: 'Last 6 months', value: DateRangePresets.SIX_MONTHS },
        {
          name: 'Last 12 months',
          value: DateRangePresets.TWELVE_MONTHS,
        },
        { name: 'This year', value: DateRangePresets.THIS_YEAR },
        { name: '2019', value: DateRangePresets[2019] },
        { name: '2020', value: DateRangePresets[2020] },
        { name: '2021', value: DateRangePresets[2021] },
        { name: '2022', value: DateRangePresets[2022] },
      ];
    }
    return options;
  };

  return (
    <FormikProvider value={formik}>
      <Form
        className={classNames({
          mobile: isMobile,
        })}
        onSubmit={formik.handleSubmit}
      >
        <Box
          sx={{
            marginTop: '0.5em',
          }}
        >
          <Box>
            <Typography
              align="left"
              sx={{
                marginBottom: '0.5em',
              }}
            >
              Custom
            </Typography>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label="From"
                    value={formik.values.dateFrom}
                    onChange={(date) => {
                      formik.setFieldValue('datePreset', '');
                      formik.setFieldValue('dateFrom', date);
                    }}
                    format="YYYY-MM-DD"
                    disableFuture
                    minDate={dayjs(calculateMinDate(variant))}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="To"
                    value={formik.values.dateTo}
                    onChange={(date) => {
                      formik.setFieldValue('datePreset', '');
                      formik.setFieldValue('dateTo', date);
                    }}
                    format="YYYY-MM-DD"
                    minDate={formik.values.dateFrom}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Box>
          {formik.errors.dateFrom && (
            <FormHelperText error>{`${formik.errors.dateFrom}`}</FormHelperText>
          )}
          {formik.errors.dateTo && (
            <FormHelperText error>{`${formik.errors.dateTo}`}</FormHelperText>
          )}
          <Typography align="center">or</Typography>
          <Box>
            <MuiSelectFieldControlled
              name="datePreset"
              placeholder="Select preset range"
              options={generateDropDownOptions()}
              errors={formik.errors}
              touched={formik.touched}
              value={formik.values.datePreset}
              label="Preset"
              ariaLabel="date-range-preset-input"
              onChange={(e) => {
                formik.setFieldValue('datePreset', e.target.value);
                const startAndEnd: Dayjs[] =
                  DateRangeMutators[e.target.value]();
                formik.setFieldValue('dateFrom', startAndEnd[0]);
                formik.setFieldValue('dateTo', startAndEnd[1]);
                formik.validateForm();
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
              }}
              sx={{
                borderRadius: '1.5rem',
                marginTop: '1.0rem',
                marginRight: '0.5rem',
              }}
              data-testid="date-range-close-button"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              data-testid="submit-date-range-button"
              type="submit"
              disabled={!formik.isValid}
              sx={{
                borderRadius: '1.5rem',
                marginTop: '1.0rem',
                color: 'primary.main',
                borderColor: 'secondary.main',
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}

function DateRangeModal(props: DateRangeModalProps) {
  const {
    title,
    visible,
    onClose,
    onSelect,
    loading,
    success,
    error,
    initialStart,
    initialEnd,
    variant,
  } = props;
  return (
    <SimpleDialog
      onClose={onClose}
      title={title}
      open={visible}
      className="delete-modal"
    >
      <RenderDateRangeModalForm
        onSelect={onSelect}
        onClose={onClose}
        initialStart={initialStart}
        initialEnd={initialEnd}
        variant={variant}
      />
    </SimpleDialog>
  );
}

DateRangeModal.defaultProps = {
  initialStart: dayjs.utc().toISOString().split('T')[0],
  initialEnd: dayjs.utc().toISOString().split('T')[0],
};

export default DateRangeModal;

import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { FirstCallFeature } from '../../../models/first-call';
import { startNewHistory } from '../../../nav-history.slice';
import {
  setSelectedFirstCallPort,
  setSelectedFirstCallPortId,
} from '../../../state/ports/first-call.slice';
import store from '../../../store';
import { assertIsPointFeature } from '../../../utils/typescript-helpers';
import MapHelpers from '../../map.utils';

function onFirstCallClick(feature: MapboxGeoJSONFeature & FirstCallFeature) {
  const { firstCallPorts, selectedFirstCall } = store.getState().firstCallPorts;

  if (assertIsPointFeature(feature)) {
    MapHelpers.zoomToPoint(feature.geometry.coordinates, 8.5);
  }
  // feature.id in a Geojson feature is actually ignored when added to mapbox
  if (selectedFirstCall?.properties.OBJECTID === feature.properties.OBJECTID) {
    // port already selected
    return;
  }
  store.dispatch(startNewHistory({ type: 'map' }));
  store.dispatch(setSelectedFirstCallPortId(null));
  // not all properties of the FirstCallProperties model are in the GeoJson Feature
  const newSelectedPort = firstCallPorts?.find(
    (p) => p.properties.OBJECTID === feature.properties.OBJECTID
  );
  if (newSelectedPort) {
    window.history.pushState(
      {},
      '',
      `/first-call-ports/${newSelectedPort.properties.OBJECTID}`
    );
  }
  store.dispatch(setSelectedDossier(EDossiers.FIRST_CALL));
  store.dispatch(setSelectedFirstCallPort(newSelectedPort));
}

export default onFirstCallClick;

import { ClipLoader } from 'react-spinners';

interface Props {
  className: string;
}

function PlaceholderImage({ className }: Props) {
  return (
    <div
      className={className}
      style={{ backgroundColor: '#ccc', margin: '3px', display: 'flex' }}
    >
      <ClipLoader
        color="#ffffff"
        loading
        size={20}
        data-testid="description-loading-spinner"
        cssOverride={{ margin: 'auto' }}
      />
    </div>
  );
}

export default PlaceholderImage;

import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, IconButton, Link, Typography } from '@mui/material';
import Accordion from '../../../../common-components/accordion/ri-accordion';
import {
  ExpandedId,
  RIContacts,
  RISecurityEmergencyUnits,
} from '../../../../models/risk_intelligence.model';
import {
  isValidUrl,
  sanitiseBlock,
} from '../../../../utils/text-formatting.utils';
import { CONTACTS, Details, EMERGENCY } from '../../world-ports-panel.slice';
import './ri-details.scss';

interface PortRIDetails {
  setTab: (tab: typeof CONTACTS | typeof EMERGENCY) => void;
  details: Details;
  type: string;
}

function RIDetails({ details, setTab, type }: PortRIDetails) {
  return (
    <div className="ri-details">
      <div className="specific-port-header">
        <IconButton disableRipple sx={{ m: 'auto 0.5rem auto -0.5rem' }}>
          <ArrowBack onClick={() => setTab(details.title)} />
        </IconButton>
        <div className="specific-port-title">
          <Typography fontWeight={500} data-testid="dossier-title">
            {details.name}
          </Typography>
          <Typography variant="body2" data-testid="dossier-title">
            {details.title}
          </Typography>
        </div>
      </div>
      {(() => {
        switch (type) {
          case CONTACTS:
            return (
              details.items &&
              details.items.map((item, i) =>
                'types' in item
                  ? item.types.map((t: ExpandedId, j: number) => {
                      const lastType = j === item.types.length - 1;
                      const lastItem =
                        details.items &&
                        i === details.items.length - 1 &&
                        lastType;

                      const contactItem = item as RIContacts;
                      const content = (
                        <Box
                          sx={{
                            padding: '0.5rem 0.75rem',
                          }}
                        >
                          <Grid container rowGap={1}>
                            <Grid item xs>
                              <Typography variant="body1" align="left">
                                <b>{contactItem.company}</b>
                              </Typography>
                            </Grid>

                            {contactItem.email && (
                              <Grid container>
                                <Grid item xs>
                                  <Typography variant="subtitle2" align="left">
                                    Email
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body2" align="right">
                                    {contactItem.email}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}

                            {contactItem.website && (
                              <Grid container>
                                <Grid item xs>
                                  <Typography variant="subtitle2" align="left">
                                    Website
                                  </Typography>
                                </Grid>
                                <Grid item xs textAlign="right" wrap="nowrap">
                                  {isValidUrl(contactItem.website) && (
                                    <Link
                                      variant="body2"
                                      title={contactItem.website}
                                      target="_blank"
                                      href={contactItem.website}
                                    >
                                      {contactItem.website}
                                    </Link>
                                  )}
                                </Grid>
                              </Grid>
                            )}
                            {contactItem.mobile && (
                              <>
                                <Grid item xs>
                                  <Typography variant="subtitle2" align="left">
                                    Mobile
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body2" align="right">
                                    {contactItem.mobile}
                                  </Typography>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          <Grid container>
                            {contactItem.address.country && (
                              <>
                                <Grid container>
                                  <Grid item xs>
                                    <Typography
                                      variant="subtitle2"
                                      align="left"
                                    >
                                      Address
                                    </Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="body2" align="right">
                                      {contactItem.address.locality}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                {contactItem.address.premise && (
                                  <Grid container rowGap={-1}>
                                    <Grid item xs>
                                      <Typography variant="body2" align="right">
                                        {contactItem.address.premise}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                )}
                                <Grid container>
                                  <Grid item xs>
                                    <Typography variant="body2" align="right">
                                      {contactItem.address.postalCode}
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <Grid container>
                                  <Grid item xs>
                                    <Typography variant="body2" align="right">
                                      {contactItem.address.country}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Box>
                      );

                      return (
                        <Accordion
                          title={t.title}
                          border={!lastItem}
                          borderRadius={lastItem || false}
                          content={content}
                        />
                      );
                    })
                  : null
              )
            );

          case EMERGENCY:
            return (
              details.items &&
              details.items.map((item, i) =>
                'types' in item
                  ? item.types.map((t: ExpandedId, j: number) => {
                      const lastType = j === item.types.length - 1;
                      const lastItem =
                        details.items &&
                        i === details.items.length - 1 &&
                        lastType;

                      const emergencyItem = item as RISecurityEmergencyUnits;
                      const descriptionHTML = emergencyItem.description;

                      const content = (
                        <Grid container>
                          {emergencyItem.description && (
                            <Grid item xs>
                              <Typography
                                component="div"
                                dangerouslySetInnerHTML={{
                                  __html: sanitiseBlock(descriptionHTML, [
                                    'a',
                                    'b',
                                    'i',
                                    'p',
                                    'em',
                                    'strong',
                                    'br',
                                  ]),
                                }}
                                sx={{
                                  padding: '0.5rem 0.75rem',
                                }}
                                textAlign="left"
                              />
                            </Grid>
                          )}
                        </Grid>
                      );

                      return (
                        <Accordion
                          title={t.title}
                          border={!lastItem}
                          borderRadius={lastItem || false}
                          content={content}
                        />
                      );
                    })
                  : null
              )
            );

          default:
            return null;
        }
      })()}
    </div>
  );
}

export default RIDetails;

import { GeoJSONSource } from 'mapbox-gl';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

const clearWeatherMapLayers = () => {
  const map = MapHelpers.getMapInstance();
  [
    MapLayer.WEATHER_PIN,
    MapLayer.WEATHER_VISIBILITY_LABEL,
    MapLayer.WEATHER_VISIBILITY_RING,
  ].forEach((layerId) => {
    const source = map.getSource(layerId) as GeoJSONSource | undefined;
    if (source) {
      source.setData(GeoHelper.createGeoJSON([]));
    }
  });
};

export default clearWeatherMapLayers;

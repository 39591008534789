/* eslint-disable react/jsx-props-no-spreading */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { getIncidents } from '../../api/risk-intelligence/incidents';
import Breadcrumb from '../../common-components/breadcrumb/breadcrumb';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import ErrorSnackbar from '../../common-components/error-snackbar/error-snackbar';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  clearError,
  setError,
  setIncidents,
  setLoading,
} from '../../state/incidents/incidents.slice';
import { applyClientSideFilters } from '../../utils/incidents-helpers';
import { IncidentFiltersButton } from './incident-filters/incident-filter-block';
import { setIncidentFilters } from './incident-panel.slice';
import { Incident } from './incident.model';
import IncidentsLegend from './incidents-legend-modal';
import IncidentsList from './incidents-list/incident-list';
import './incidents-panel.scss';

function IncidentsPanel() {
  const dispatch = useAppDispatch();
  const { loading, error, incidents, selectedIncidentId } = useAppSelector(
    (state) => state.incidents
  );
  const { filters } = useAppSelector((state) => state.incidentsPanel);

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [filteredIncidentIds, setFilteredIncidentIds] = useState<
    Incident['id'][]
  >([]);

  useEffect(() => {
    if (!incidents) {
      dispatch(setLoading());

      // Has a side-effect of populating the incident types.
      getIncidents({ filters })
        .then((incidentsResponse) => {
          dispatch(setIncidents(incidentsResponse));
        })
        .catch(() => {
          dispatch(setError());
        });
    }
    // Only fetch incidents on initial load
    // re-fetches will occur in a parent component when filters change
  }, []);

  useEffect(() => {
    const clientFilteredIncidents = applyClientSideFilters(
      filters,
      Object.values(incidents?.byId ?? {})
    );

    setFilteredIncidentIds(
      clientFilteredIncidents.map((incident) => incident.id)
    );
  }, [incidents, filters]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    clearError();
  };

  const searchFilteredIncidents =
    incidents && searchInputValue
      ? filteredIncidentIds.filter((incidentId) => {
          const incident = incidents.byId[incidentId];
          if (incident) {
            return (
              incident.title
                .toLowerCase()
                .includes(searchInputValue.toLowerCase()) ||
              incident.summary
                .toLowerCase()
                .includes(searchInputValue.toLowerCase())
            );
          }
          return false;
        })
      : filteredIncidentIds;

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <PanelTitle>
        {filters.open ? (
          <>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(setIncidentFilters({ open: false }));
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            Filter
          </>
        ) : (
          'Incidents'
        )}
        <IncidentsLegend />
      </PanelTitle>
      <Breadcrumb currentPanel="incidentList" />
      <Box
        sx={{
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          height: '100%',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        {loading && <LoadingPanel />}
        {!loading && error && (
          <ErrorPanel message="Error: Unable to fetch incident data, please try again later..." />
        )}
        {!loading && !error && incidents && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: 0,
              textAlign: 'left',
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '0px 20px',
              }}
            >
              <SimpleSearchBar
                handleChange={setSearchInputValue}
                filterValue={searchInputValue}
                placeholder="Search Incidents"
                dataTestId="incidents-search-bar"
              />
              <IncidentFiltersButton />
            </Box>
            <IncidentsList
              incidentIds={searchFilteredIncidents || filteredIncidentIds}
            />
          </Box>
        )}
      </Box>
      <ErrorSnackbar
        open={!!selectedIncidentId && error}
        onClose={handleSnackbarClose}
        message="Unable to find incident id from URL"
      />
    </Box>
  );
}

export default IncidentsPanel;

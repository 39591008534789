import MapHelpers from '../../map.utils';

type SvgOptions = { w: number; h: number };

interface AddMapImageOptions {
  imageImport: string;
  imageName: string;
  svg?: SvgOptions;
  convertToSDF?: boolean;
}

function addMapImage({
  imageImport,
  imageName,
  svg,
  convertToSDF,
}: AddMapImageOptions) {
  // svgs must be rasterised for display
  if (svg) {
    // svgs can be rendered at any size, so a size must be specified
    const img = new Image(svg.w, svg.h);
    img.onload = () =>
      MapHelpers.addImage(imageName, img, { sdf: convertToSDF });
    img.src = imageImport;
    return;
  }
  MapHelpers.loadImage(imageImport, (error, image) => {
    if (error) {
      throw error;
    }
    MapHelpers.addImage(imageName, image as HTMLImageElement, {
      sdf: convertToSDF,
    });
  });
}

addMapImage.defaultProps = {
  convertToSDF: false,
};

export default addMapImage;

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { IncidentLegendIcon } from '../../../common-components/common-legend/common-legend';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { Route } from '../../../models/routes.model';
import { addHistoryItem, startNewHistory } from '../../../nav-history.slice';
import { setSelectedIncident } from '../../../state/incidents/incidents.slice';
import {
  getIncidentRegionTitleById,
  incidentDisplayDate,
} from '../../../utils/incidents-helpers';
import { Incident } from '../incident.model';
import './incident-item.scss';

interface IncidentItemProps {
  incident: Incident;
  downloadView: boolean;
  selected: boolean;
  onCheckBoxSelected: (incident: Incident) => void;
  route?: Route;
  dense?: boolean;
}

function IncidentItem(props: IncidentItemProps) {
  const navigate = useNavigate();

  const { incident, selected, downloadView, onCheckBoxSelected, route, dense } =
    props;
  const { id, type, title, region } = incident;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { selectedRouteIncidentId } = useAppSelector((state) => state.routes);
  const displayDate = incidentDisplayDate(incident);

  const onClick = (i: Incident) => {
    if (downloadView) {
      // if in download mode, clicking an incident selects it rather than opening it
      onCheckBoxSelected(i);
      return;
    }

    if (route) {
      dispatch(addHistoryItem({ type: 'route', properties: route }));
      dispatch(setSelectedIncident(i));
      dispatch(setSelectedDossier(EDossiers.INCIDENT));
    } else {
      dispatch(startNewHistory({ type: 'incidentList' }));
      dispatch(setSelectedIncident(i));
      navigate(`/incidents/${incident.id}`);
    }
  };
  return (
    // role is not redundant since list-style: none removes the 'semanticity' of a list
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ListItem
      className="incidentItemli"
      role="listitem"
      key={id.toString()}
      id={id.toString()}
      disableGutters
      disablePadding
      dense={dense}
    >
      <div
        className={`incident-item-checkbox ${
          downloadView ? 'visible' : 'hidden'
        }`}
      >
        <input
          onChange={() => onCheckBoxSelected(incident)}
          checked={selected}
          type="checkbox"
        />
      </div>
      <ListItemButton
        data-testid={`incident-${id.toString()}`}
        selected={selected}
        className={classNames('incidentItem', {
          selected: selectedRouteIncidentId === id,
        })}
        sx={{
          padding: '8px 24px 8px 16px',
          borderTop: 1,
          borderRadius: 0,
          borderColor: theme.palette.divider,
        }}
        onClick={() => onClick(incident)}
      >
        <ListItemAvatar className="incidentIconContainer">
          <IncidentLegendIcon id={type} type="round" />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            fontWeight: '400',
            variant: 'subtitle1',
          }}
          secondaryTypographyProps={{
            variant: 'caption',
          }}
          primary={title}
          secondary={`${getIncidentRegionTitleById(region)!} - ${displayDate}`}
        />
      </ListItemButton>
    </ListItem>
  );
}

IncidentItem.defaultProps = {
  dense: false,
  route: null,
};

export default IncidentItem;

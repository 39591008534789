import { eachMonthOfInterval } from 'date-fns';

namespace DateTimeHelpers {
  export const MINUTES_IN_HOUR = 60;
  export const MILLISECONDS_IN_MINUTE = 60000;
  export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * MINUTES_IN_HOUR;

  export const getHoursInMilliseconds = (milliseconds: number) =>
    Math.floor(milliseconds / MILLISECONDS_IN_HOUR);

  export const waitMs = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // converts date to format yyyy-mm-dd
  export const dateToIsoDate = (date: Date | string | null): string | null => {
    if (!date) {
      return null;
    }
    if (date instanceof Date) {
      return date.toISOString().split('T')[0];
    }

    const parsedDate = new Date(date);
    // return null if parsing failed
    if (Number.isNaN(parsedDate.valueOf())) {
      return null;
    }
    // recurse after casting to date object
    return dateToIsoDate(parsedDate);
  };

  /**
   * Return list of months
   * localeName   : name of local, f.e. en-GB, default es-MX
   * monthFormat : short, numeric, long (Default)
   * https://stackoverflow.com/a/66626916
   */
  export const monthsForLocale = (
    localeName: string,
    monthFormat: 'short' | 'numeric' | 'long' = 'long'
  ) => {
    const { format } = new Intl.DateTimeFormat(localeName, {
      month: monthFormat,
    });
    return [...Array(12).keys()].map((m) =>
      format(new Date(Date.UTC(2021, (m + 1) % 12)))
    );
  };

  /**
   * Uses the user's browser configured locale to return the name of the month number (0 indexed, ie. January is 0)
   * @param localeName "en-GB", "es-MX", etc.
   * @param monthNumber
   * @param monthFormat "short" returns "Jan", "numeric" returns "1", "long" returns "January". defaults to "long"
   * @returns
   */
  export const getMonthName = (
    localeName: string,
    monthNumber: number,
    monthFormat: 'short' | 'numeric' | 'long' = 'long'
  ) => {
    const { format } = new Intl.DateTimeFormat(localeName, {
      month: monthFormat,
    });
    return format(new Date(Date.UTC(2021, monthNumber)));
  };

  /**
   * Returns a list of [year, month] tuples for the given interval. The Months are 0-indexed to match RI, ie. January is 0.
   */
  export const getMonthsYearsInInterval = (start: Date, end: Date) => {
    const interval = { start, end };
    const dates = eachMonthOfInterval(interval);
    return dates.map(
      (date) => [date.getFullYear(), date.getMonth()] as [number, number]
    );
  };

  // removes milliseconds in iso date
  // https://stackoverflow.com/questions/34053715/how-to-output-date-in-javascript-in-iso-8601-without-milliseconds-and-with-z
  // 2023-07-07T15:22:00.102Z -> 2023-07-07T15:22:00Z
  export const dateToIso8601 = (date: Date | string) =>
    `${new Date(date).toISOString().split('.')[0]}Z`;

  export const subtractMonths = (date: Date, months: number) => {
    const oneMonthAgo = new Date(date);
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - months);
    return new Date(oneMonthAgo);
  };

  export const subtractHours = (
    date: Date | string | number,
    hours: number
  ) => {
    const hoursAgo = new Date(date);
    hoursAgo.setHours(hoursAgo.getHours() - hours);
    return new Date(hoursAgo);
  };

  export const dateToMonthDayYear = (date: Date | string) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.toLocaleString(undefined, { month: 'long' });
    const year = d.getFullYear();

    return `${month} ${day} ${year}`;
  };

  export const formatHoursAndMinutes = (hours: number) => {
    const minutes = (hours % 1) * 60.0;
    return `${Math.floor(hours)} hrs ${Math.round(minutes)} mins`;
  };

  export const compareTimeInMinutes = (fromDate: Date, toDate: Date) => {
    // Takes two dates and returns the difference between them in minutes
    const timeDifferenceInMilliseconds = toDate.getTime() - fromDate.getTime();
    const minutesDifference = timeDifferenceInMilliseconds / (1000 * 60);
    return minutesDifference;
  };
}

export default DateTimeHelpers;

export const dateToUtcTimeString = (
  date: Date | string | null
): string | null => {
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    try {
      const timeString = date.toISOString().split('T')[1].slice(0, 8);
      const dateString = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
      });
      return `${timeString}, ${dateString} (UTC)`;
    } catch (e) {
      return `00:00:00, Invalid Date (UTC)`;
    }
  }

  const parsedDate = new Date(date);
  // return null if parsing failed
  if (Number.isNaN(parsedDate.valueOf())) {
    return null;
  }
  // recurse after casting to date object
  return dateToUtcTimeString(parsedDate);
};

// returns just date, respecting the user's locale
export const dateWithLocale = (
  date: Date | string | null,
  tzOverride?: string
): string | null => {
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    return date.toLocaleDateString(navigator.language, {
      timeZone: tzOverride,
    });
  }

  const parsedDate = new Date(date);
  // return null if parsing failed
  if (Number.isNaN(parsedDate.valueOf())) {
    return null;
  }
  // recurse after casting to date object
  return dateWithLocale(parsedDate, tzOverride);
};

export function formatDateTime(eta: string) {
  const dateString = eta.replace(/-/g, '/');
  return dateString.replace('T', ' ');
}

/**
 * date must be in milliseconds NOT seconds
 *
 * @param date
 * @returns date in the string format of 'dd/mm/yyyy' e.g: 22/10/1996
 */
export const convertUnixToDDMMYY = (
  date: number | string | null
): string | null => {
  if (!date) {
    return null;
  }
  let newDate = null;

  if (typeof date === 'number') {
    newDate = new Date(date);
  } else if (typeof date === 'string') {
    newDate = new Date(Number(date));
  }

  if (newDate) {
    return `${newDate!.getDate()}/${newDate!.getMonth() + 1}/${newDate!
      .getFullYear()
      .toString()
      .slice(2)}`;
  }
  return newDate;
};

export const convertDDMMYYToUnix = (dateString: string): Number => {
  const dateParts = dateString.split('/');

  const day = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1;
  let year = parseInt(dateParts[2], 10);

  // Get the current year
  const currentYear = new Date().getFullYear();
  const currentYearLastTwo = currentYear % 100;

  // Assuming that if the year is above the current year, it's in the 1900s
  if (year > currentYearLastTwo) {
    year += 1900;
  } else {
    // Otherwise, it's in the 2000s
    year += 2000;
  }

  const date = new Date(year, month, day);
  const unixTimestamp = date.getTime();

  return unixTimestamp;
};

export const withinSevenDays = (
  startDate: number,
  endDate: number
): boolean => {
  const sevenDaysInSeconds = 7 * 24 * 60 * 60 * 1000; // 7 days in seconds

  const differenceInMilliseconds = Math.abs(endDate - startDate);

  return differenceInMilliseconds <= sevenDaysInSeconds;
};

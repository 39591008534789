import { Box, Button, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { getS3DocsWithMetadata } from '../../api';
import DocumentsList from '../../common-components/documents-list/documents-list';
import UploadEntityDocumentsModal from '../../common-components/upload-entity-documents-modal/upload-entity-documents-modal';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  setDocuments,
  setError,
  setLoading,
} from '../../maritime-menu-options/documents-panel/documents.slice';
import { EntityType } from '../../models/document';

interface DocumentsTabProps {
  entityId: string;
  entityName: string;
  entityType: EntityType;
}

function DocumentsTab({ entityId, entityName, entityType }: DocumentsTabProps) {
  const { myDocuments } = useAppSelector((state) => state.documents);
  const dispatch = useAppDispatch();

  const fetchDocumentsForEntity = useCallback(async () => {
    dispatch(setLoading());

    try {
      if (!entityId) {
        dispatch(
          setDocuments({
            myDocuments: null,
          })
        );
        return;
      }

      const { myDocuments: myDocs } = await getS3DocsWithMetadata(
        String(entityId)
      );

      dispatch(
        setDocuments({
          myDocuments: myDocs,
        })
      );
    } catch (error) {
      dispatch(setError());
    }
  }, [entityId]);

  useEffect(() => {
    fetchDocumentsForEntity();
  }, [fetchDocumentsForEntity]);

  const [uploadDocumentsModalVisible, setUploadDocumentsModalVisible] =
    useState<boolean>(false);

  const onUploadModalClose = () => {
    fetchDocumentsForEntity();
    setUploadDocumentsModalVisible(false);
  };

  const uploadEntityDocumentsModal = (
    <Box>
      <Box padding="10px">
        <Button
          className="MaritimeUploadButton"
          onClick={() => setUploadDocumentsModalVisible(true)}
          fullWidth
          sx={{
            borderRadius: '100px',
            borderColor: 'primary.dark',
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '38px',
            justifyContent: 'center !important',
            '&:hover': {
              color: 'background.button',
            },
          }}
        >
          Upload
        </Button>
      </Box>

      <UploadEntityDocumentsModal
        entityName={entityName || "Error: Couldn't find entity name"}
        entityId={entityId || 0}
        entityType={entityType}
        onClose={onUploadModalClose}
        visible={uploadDocumentsModalVisible}
      />
    </Box>
  );

  if (myDocuments && myDocuments.length > 0) {
    return (
      <Box className="documents-tab-content" sx={{ bgColor: 'primary.main' }}>
        <DocumentsList
          documents={myDocuments || []}
          refreshDocuments={fetchDocumentsForEntity}
        />
        {uploadEntityDocumentsModal}
      </Box>
    );
  }

  return (
    <Typography
      className="documents-tab-content"
      sx={{ bgColor: 'primary.main' }}
    >
      No saved Documents.
      {uploadEntityDocumentsModal}
    </Typography>
  );
}

export default DocumentsTab;

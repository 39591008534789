/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type CentreDate = {
  alreadyOffset: boolean;
  date: number;
};

interface TimelineState {
  centreDate: CentreDate;
  containerHeight: number;
}

const initialState: TimelineState = {
  centreDate: {
    alreadyOffset: false,
    date: new Date().getTime(),
  },
  containerHeight: 0,
};

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {
    setCentreDate: (state, action: PayloadAction<CentreDate>) => {
      state.centreDate = action.payload;
    },
    setContainerHeight: (state, action: PayloadAction<number>) => {
      state.containerHeight = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCentreDate, setContainerHeight } = timelineSlice.actions;

export default timelineSlice.reducer;

import { FirstCallFeature } from '../../../models/first-call';

function firstCallInfoPopUpContent(
  feature: mapboxgl.MapboxGeoJSONFeature
): { label: string; value: string }[] | null {
  if (feature) {
    const firstCallFeature = feature as unknown as FirstCallFeature;
    const { properties } = firstCallFeature;
    const { worldPortIndexNumber, portName } = properties;

    return [
      {
        label: 'First Call Port Name',
        value: portName,
      },
      {
        label: 'World Port Index No.',
        value: worldPortIndexNumber.toString(),
      },
    ];
  }
  return null;
}

export default firstCallInfoPopUpContent;

import MapHelpers from '../../map.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addBathymetrySource from './add-bathymetry-source';

function addBathymetryLayer(layer: MapLayer | string) {
  // eslint-disable-next-line no-new
  return new Promise((resolve) => {
    addBathymetrySource(layer);
    MapHelpers.addLayer({
      id: layer,
      type: 'fill',
      source: layer,
      'source-layer': layer,
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
      },
      paint: {
        'fill-outline-color': 'hsla(337, 82%, 62%, 0)',
        // cubic bezier is a four point curve for smooth and precise styling
        // adjust the points to change the rate and intensity of interpolation
        'fill-color': [
          'interpolate',
          ['cubic-bezier', 0, 0.5, 1, 0.5],
          ['get', 'DEPTH'],
          200,
          '#78bced',
          9000,
          '#15659f',
        ],
      },
    });
    resolve(MapHelpers);
  });
}

export default addBathymetryLayer;

import { GeoJSONSource } from 'mapbox-gl';
import { WeatherResponse } from '../../../maritime-menu-options/weather-panel/weather.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

const setMapPoints = (response: WeatherResponse) => {
  const pinLngLat = [response.weather.meta.lng, response.weather.meta.lat];
  const tideStationLngLat = [
    response.tidal_extremes.meta.station.lng,
    response.tidal_extremes.meta.station.lat,
  ];
  (
    MapHelpers.getMapInstance().getSource(MapLayer.WEATHER_PIN) as
      | GeoJSONSource
      | undefined
  )?.setData(
    GeoHelper.createGeoJSON([
      GeoHelper.createFeaturePoint(pinLngLat, {
        type: 'dropped-pin',
      }),
      GeoHelper.createFeaturePoint(tideStationLngLat, {
        type: 'tide-station',
        title: `${response.tidal_extremes.meta.station.name}`,
      }),
    ])
  );
};

export default setMapPoints;

import { Alert, Box, List } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReduxStatesToVerify, useEnsureReduxLoaded } from '../../api';
import Breadcrumb from '../../common-components/breadcrumb/breadcrumb';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { EDossiers, setSelectedDossier } from '../../main-menu/menu.slice';
import { MapGroupLayers } from '../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../map/map.utils';
import { Correspondent } from '../../models/correspondents.model';
import {
  setLoading,
  setSelectedCorrespondent,
} from '../../state/correspondents/correspondents.slice';
import CorrespondentListItem from './correspondent-list-item';
import { setSearch } from './correspondents-panel.slice';

function CorrespondentsPanel() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    correspondents,
    loading,
    error,
    selectedCorrespondent,
    selectedCorrespondentId,
  } = useAppSelector((state) => state.correspondents);
  const { search } = useAppSelector((state) => state.correspondentsPanel);

  const updateSearchFilter = (value: string) => {
    dispatch(setSearch(value));
  };

  const filteredCorrespondents = useMemo(() => {
    if (!correspondents) return null;
    if (!search) return correspondents;
    return correspondents.filter(
      (correspondent) =>
        correspondent.port_information.name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        correspondent.port_information.country
          .toLowerCase()
          .includes(search.toLowerCase())
    );
  }, [correspondents, search]);

  const handleCorrespondentClick = (correspondent: Correspondent) => {
    dispatch(setSelectedDossier(EDossiers.CORRESPONDENTS));
    dispatch(setSelectedCorrespondent(correspondent));
    navigate(`correspondents/${correspondent.uid}`);

    const portInfoLocation = correspondent.port_information.location;
    if (portInfoLocation.lat !== null && portInfoLocation.lng !== null) {
      MapHelpers.zoomToPoint([portInfoLocation.lng, portInfoLocation.lat], 8.5);
    }
  };

  useEnsureReduxLoaded([ReduxStatesToVerify.CORRESPONDENTS], {
    before: () => {
      dispatch(setLoading(true));
    },
    after: () => {
      dispatch(setLoading(false));
      MapGroupLayers.CORRESPONDENTS.forEach((layer) => {
        MapHelpers.setLayerVisibility(layer, true);
      });
    },
  });

  return (
    <Box
      sx={{
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
      }}
    >
      <PanelTitle>Correspondents</PanelTitle>
      <Breadcrumb />
      <Box sx={{ padding: 2 }}>
        <SimpleSearchBar
          handleChange={updateSearchFilter}
          filterValue={search}
          placeholder="Search Correspondents"
          dataTestId="correspondents-search-bar"
        />
      </Box>
      {loading && <LoadingPanel />}
      {error && (
        <Alert data-testid="error-panel" severity="error">
          Error: Unable to fetch Correspondents
        </Alert>
      )}
      {!loading && !error && filteredCorrespondents && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {filteredCorrespondents.length ? (
            <List sx={{ overflowY: 'auto' }}>
              {filteredCorrespondents.map((correspondent) => (
                <CorrespondentListItem
                  key={correspondent.uid}
                  correspondent={correspondent}
                  onClick={handleCorrespondentClick}
                  selected={
                    (correspondent.uid.toString() === selectedCorrespondentId ||
                      (selectedCorrespondent &&
                        correspondent.uid ===
                          selectedCorrespondent.uid)) as boolean
                  }
                  divider
                />
              ))}
            </List>
          ) : (
            <Alert data-testid="no-results" severity="info">
              No results
            </Alert>
          )}
        </>
      )}
    </Box>
  );
}

export default CorrespondentsPanel;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import update, { Spec } from 'immutability-helper';

import { Incident } from '../../maritime-menu-options/incidents-panel/incident.model';
import { Port } from '../../maritime-menu-options/world-ports-panel/world-ports.model';
import { MaritimeArea } from '../../models/risk_intelligence.model';
import { Route } from '../../models/routes.model';

interface RouteState {
  loading: boolean;
  error: boolean;
  routes: Route[] | null;
  selectedRoute: Route | null;
  selectedRouteId: string | null;
  selectedRouteIncidents: Incident[] | null;
  selectedRouteIncidentId: number | null;
  selectedRoutePorts: Port[] | null;
  selectedRouteAreas: MaritimeArea[] | null;
}

export const INITIAL_ROUTES_STATE: RouteState = {
  loading: false,
  error: false,
  routes: null,
  selectedRoute: null,
  selectedRouteId: null,
  selectedRouteIncidents: null,
  selectedRouteIncidentId: null,
  selectedRoutePorts: null,
  selectedRouteAreas: null,
};

const RoutesSlice = createSlice({
  name: 'routes',
  initialState: INITIAL_ROUTES_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setRoutes: (state, action: PayloadAction<Route[]>) => {
      state.loading = false;
      state.error = false;
      state.routes = action.payload;
    },
    setSelectedRouteIncidents: (
      state,
      action: PayloadAction<Incident[] | null>
    ) => {
      state.selectedRouteIncidents = action.payload;
      state.loading = false;
      state.error = false;
    },
    setSelectedRoutePorts: (state, action: PayloadAction<Port[] | null>) => {
      state.selectedRoutePorts = action.payload;
      state.loading = false;
      state.error = false;
    },
    setSelectedRouteAreas: (
      state,
      action: PayloadAction<MaritimeArea[] | null>
    ) => {
      state.selectedRouteAreas = action.payload;
      state.loading = false;
      state.error = false;
    },
    setSelectedRouteIncidentId: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.selectedRouteIncidentId = action.payload;
    },
    setSelectedRouteId: (state, action: PayloadAction<string | null>) => {
      state.selectedRouteId = action.payload;
    },
    setSelectedRoute: (
      state,
      action: PayloadAction<Route | undefined | null>
    ) => {
      // if a new route to select is provided, set it (with selected:true)
      if (action.payload) {
        state.selectedRoute = {
          ...action.payload,
          selected: true,
        };
      } else {
        // otherwise, set selected to null
        state.selectedRoute = null;
      }
      // if (somehow) routes are not yet populated, skip updating the routes array
      if (!state.routes) {
        return;
      }
      const prevIndex = state.routes.findIndex((route) => route.selected);
      const updateObject: Spec<typeof state.routes> = {};
      let index = -1;
      // if the payload is not null, set the new incident as selected
      if (action.payload) {
        index = state.routes.findIndex(
          (route) => route.route_id === (action.payload as Route).route_id
        );
        // update to set selected only if the index is valid and different from the previous index
        if (index > -1 && prevIndex !== index) {
          updateObject[index] = {
            selected: { $set: true },
          };
        }
      }
      // whether or not a new route was provided, we need to de-select the previous route (but only if it was different from the new route)
      if (prevIndex > -1 && prevIndex !== index) {
        updateObject[prevIndex] = {
          selected: { $set: false },
        };
      }
      // Only run updated if there is something to update
      if (Object.keys(updateObject).length > 0) {
        state.routes = update(state.routes, updateObject);
      }
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setLoading,
  setError,
  setRoutes,
  setSelectedRoute,
  setSelectedRouteId,
  setSelectedRouteIncidents,
  setSelectedRouteIncidentId,
  setSelectedRoutePorts,
  setSelectedRouteAreas,
} = RoutesSlice.actions;

export default RoutesSlice.reducer;

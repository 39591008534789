import useAccessControl from '../hooks/access-control/useAccessControl';
import { EMenuItems } from './menu.slice';

type AccessConfig = Record<EMenuItems, { hasAccess: boolean }>;

const useMainMenuAccessConfig = (): AccessConfig => {
  const {
    canAccessIncidents,
    canAccessPorts,
    canAccessRoutes,
    canAccessHistory,
    canAccessRiMaritimeAreas,
    canAccessBoundaries,
    canAccessDrawings,
    canAccessDocuments,
    canAccessUserPreferences,
    canAccessMaps,
    canAccessMapTools,
    canAccessVPS,
  } = useAccessControl();

  return {
    [EMenuItems.HISTORY]: {
      hasAccess: canAccessHistory,
    },
    [EMenuItems.MAPS]: {
      hasAccess: canAccessMaps,
    },
    [EMenuItems.INCIDENTS]: {
      hasAccess: canAccessIncidents,
    },
    [EMenuItems.BOUNDARIES]: {
      hasAccess: canAccessBoundaries,
    },
    [EMenuItems.PORTS]: {
      hasAccess: Object.values(canAccessPorts).some(
        (portTypeAccess) => portTypeAccess === true
      ),
    },
    [EMenuItems.DRAWINGS]: {
      hasAccess: canAccessDrawings,
    },
    [EMenuItems.ROUTES]: {
      hasAccess: canAccessRoutes,
    },
    [EMenuItems.RI_MARITIME_AREAS]: {
      hasAccess: canAccessRiMaritimeAreas,
    },
    [EMenuItems.DOCUMENTS]: { hasAccess: canAccessDocuments },
    [EMenuItems.SAVE_SETUP]: { hasAccess: canAccessUserPreferences },
    [EMenuItems.PRIVACY_POLICY]: { hasAccess: true },
    [EMenuItems.COUNTRIES]: { hasAccess: true },
    [EMenuItems.TOOLS]: { hasAccess: canAccessMapTools },
    [EMenuItems.INDUSTRY_NEWS]: {
      hasAccess: true,
    },
    [EMenuItems.VPS_PORTS]: {
      hasAccess: canAccessVPS,
    },
    [EMenuItems.NOTHING_SELECTED]: { hasAccess: true },
    [EMenuItems.FIRST_CALL_PORTS]: { hasAccess: true },
    [EMenuItems.CORRESPONDENTS]: { hasAccess: true },
  };
};

export default useMainMenuAccessConfig;

import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { differenceBy } from 'lodash';
import { Fragment } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  removeRangeRing,
  removeRangeRingPoint,
} from '../../../map/map-layer-manager/range-ring-utils/remove-range-ring-layers';
import { setRangeRingPoints } from '../tools-panel.slice';
import RangeRingForm from './range-ring-form';
import {
  generateRangeRingPoint,
  onRangeRingPointAdd,
  onRangeRingPointRemove,
  onRangeRingPointUpdate,
} from './range-ring-helpers';
import { RangeRingPoint } from './range-ring.model';

function RangeRingsView() {
  const { points } = useAppSelector((state) => state.toolsPanel.rangeRings);
  const dispatch = useAppDispatch();

  const updatePoints = (updatedPoints: RangeRingPoint[]) => {
    dispatch(setRangeRingPoints(updatedPoints));
  };

  const addPoint = () => {
    const newPoint = generateRangeRingPoint();

    onRangeRingPointAdd(newPoint);
    updatePoints([...points, newPoint]);
  };

  const clearAllPoints = () => {
    updatePoints([]);

    points.forEach((point) => {
      removeRangeRingPoint(point);
    });
  };

  const removePoint = (point: RangeRingPoint) => {
    const filteredPoints = [...points].filter((p) => p.id !== point.id);
    onRangeRingPointRemove(point);
    updatePoints(filteredPoints);

    removeRangeRingPoint(point);
  };

  const onUpdate = (point: RangeRingPoint) => {
    const { rings } = point;
    const updatedPoints = [...points];
    const selectedPointIndex = updatedPoints.findIndex(
      (p) => p.id === point.id
    );

    if (selectedPointIndex !== -1) {
      const deletedRings = differenceBy(
        points[selectedPointIndex].rings,
        rings,
        'id'
      );

      if (deletedRings.length) {
        deletedRings.forEach((ring) => removeRangeRing(ring));
      }

      updatedPoints[selectedPointIndex] = point;
      onRangeRingPointUpdate(point);
      updatePoints(updatedPoints);
    }
  };

  return (
    <Box
      data-testid="range-rings-view"
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <Box
        p={0.5}
        sx={{
          height: '85%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          scrollbarWidth: 'thin',
          marginBottom: '2rem',
        }}
      >
        {points.length === 0 && (
          <Typography>No Range Rings to display</Typography>
        )}
        <List
          sx={{
            textAlign: 'left',
          }}
        >
          {points.map((point, index) => (
            <Fragment key={point.id}>
              <ListItem>
                <RangeRingForm
                  title={`Range ${index + 1}`}
                  point={point}
                  onUpdate={onUpdate}
                  onRemove={removePoint}
                />
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </Box>
      <Box
        p={0.5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Button variant="outlined" onClick={addPoint}>
          Add New Point
        </Button>
        <Button
          variant="contained"
          onClick={clearAllPoints}
          sx={{
            marginTop: '5px',
          }}
        >
          Clear All Points
        </Button>
      </Box>
    </Box>
  );
}
export default RangeRingsView;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { DeleteOutline } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import mapboxgl from 'mapbox-gl';
import React, { useRef, useState } from 'react';
import LongLatInputFields from '../../../common-components/form-fields/long-lat-input-fields';
import { DMSToDecimal, decimalToDMS } from '../../../map/map-controls.utils';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { neatDisplayNumber } from '../../../utils/text-formatting.utils';
import measurementFormValidationSchema from './measurement-validators';
import { Measurement } from './measurement.model';

interface MeasurementFormProps {
  measurement: Measurement;
  onRemove: (measurement: Measurement) => void;
  onUpdate: (measurement: Measurement) => void;
}

function MeasurementForm(props: MeasurementFormProps) {
  const { measurement, onRemove, onUpdate } = props;
  const { title } = measurement;

  const titleEl = useRef<HTMLInputElement>(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const handleUpdate = (values: Measurement) => {
    onUpdate(values);
  };

  const onTitleEdit = () => {
    handleUpdate({
      ...measurement,
      title: titleEl?.current?.value || measurement.title,
    });
    setIsEditingTitle(false);
  };

  let point1: any = [];

  if (
    measurement.points[0].longitude !== '' &&
    measurement.points[0].latitude !== ''
  ) {
    point1 = [
      DMSToDecimal(measurement.points[0].longitude as string),
      DMSToDecimal(measurement.points[0].latitude as string),
    ] as [number, number];
  }

  let point2: any = [];

  if (
    measurement.points[1] &&
    measurement.points[1].longitude !== '' &&
    measurement.points[1].latitude !== ''
  ) {
    point2 = [
      DMSToDecimal(measurement.points[1].longitude as string),
      DMSToDecimal(measurement.points[1].latitude as string),
    ] as [number, number];
  }

  let miles: number = 0;
  let nauticalMiles: number = 0;
  let kilometres: number = 0;
  let meters: number = 0;

  if (measurement.points[1] && measurement.points[1].longitude !== '') {
    miles = GeoHelper.calculateDistanceBetweenPoints(point1, point2, 'miles');
    nauticalMiles = GeoHelper.calculateDistanceBetweenPoints(
      point1,
      point2,
      'nauticalmiles'
    );
    kilometres = GeoHelper.calculateDistanceBetweenPoints(
      point1,
      point2,
      'kilometers'
    );
    meters = GeoHelper.calculateDistanceBetweenPoints(point1, point2, 'meters');
  }

  const onLngLatSelect = (
    formik: FormikProps<Measurement>,
    pointIndex: number,
    lngLat: mapboxgl.LngLat
  ) => {
    const updatedValues = { ...formik.values };
    const updatedPoints = [...formik.values.points];
    updatedPoints[pointIndex] = {
      longitude: decimalToDMS(lngLat.lng, 'longitude'),
      latitude: decimalToDMS(lngLat.lat, 'latitude'),
    };

    if (updatedPoints[1].latitude === '') {
      updatedValues.points = [updatedPoints[0]];
    } else {
      updatedValues.points = updatedPoints;
    }

    formik.setValues(updatedValues);
    // set field value doesn't seem to trigger any change events - manually trigger update here
    handleUpdate(updatedValues);
  };

  return (
    <Formik<Measurement>
      initialValues={{ ...measurement }}
      onSubmit={() => {}}
      enableReinitialize
      validationSchema={measurementFormValidationSchema}
    >
      {(formik) => (
        <Paper
          data-testid="measurement-form"
          sx={{
            padding: '1rem',
            width: '100%',
          }}
        >
          <Form onKeyUp={() => handleUpdate(formik.values)}>
            <Box sx={{ marginBottom: '1rem', display: 'flex' }}>
              {isEditingTitle ? (
                <TextField
                  inputRef={titleEl}
                  variant="standard"
                  defaultValue={formik.values.title}
                  inputProps={{
                    'data-testid': 'measurement-title-input-field',
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={onTitleEdit}
                        data-testid="update-measurement-title"
                        disabled={titleEl?.current?.value.trim().length === 0}
                      >
                        <DoneIcon />
                      </IconButton>
                    ),
                  }}
                  fullWidth
                />
              ) : (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      width: '90%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    title={title}
                  >
                    {title}
                  </Typography>
                  <IconButton
                    onClick={() => setIsEditingTitle(true)}
                    data-testid="edit-measurement-title"
                  >
                    <EditIcon />
                  </IconButton>
                </>
              )}

              <IconButton
                sx={{
                  float: 'right',
                }}
                onClick={() => onRemove(measurement)}
                data-testid="delete-measurement"
              >
                <DeleteOutline />
              </IconButton>
            </Box>
            <Grid container spacing={0.5}>
              <LongLatInputFields
                longitudeInputName="points[0].longitude"
                latitudeInputName="points[0].latitude"
                onLngLatSelect={(lngLat) =>
                  onLngLatSelect(formik, 0, lngLat[0])
                }
              />
              <LongLatInputFields
                longitudeInputName="points[1].longitude"
                latitudeInputName="points[1].latitude"
                onLngLatSelect={(lngLat) =>
                  onLngLatSelect(formik, 1, lngLat[0])
                }
              />
            </Grid>

            <Grid
              container
              sx={{
                marginTop: '1rem',
                display: 'flex',
              }}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle2" align="left">
                  Distance between points:
                </Typography>
              </Grid>
              <Grid xs={12} direction="column">
                <Grid
                  item
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Typography variant="subtitle2" sx={{ marginRight: '10px' }}>
                    {neatDisplayNumber(nauticalMiles) || 0}
                  </Typography>
                  <Typography variant="subtitle2">Nautical Mile(s)</Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Typography variant="body2" sx={{ marginRight: '10px' }}>
                    {neatDisplayNumber(miles) || 0}
                  </Typography>
                  <Typography variant="body2">Mile(s)</Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Typography variant="body2" sx={{ marginRight: '10px' }}>
                    {neatDisplayNumber(kilometres) || 0}
                  </Typography>
                  <Typography variant="body2">Kilometre(s)</Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: 'flex',
                  }}
                >
                  <Typography variant="body2" sx={{ marginRight: '10px' }}>
                    {neatDisplayNumber(meters) || 0}
                  </Typography>
                  <Typography variant="body2">meter(s)</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      )}
    </Formik>
  );
}

export default React.memo(MeasurementForm);

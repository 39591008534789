import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import MUISlider from '../../common-components/slider/SliderMUI';
import { useAppSelector } from '../../hooks';
import useAccessControl from '../../hooks/access-control/useAccessControl';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../map/map.utils';
import { UserPreferencesBaseMap } from '../../utils/user.enum';
import './maps-panel.scss';

function MapsPanel() {
  const { canAccessBathymetry, canAccessDatumRings } = useAccessControl();

  const mapZoom = useAppSelector((state) => state.map.mapZoom);
  const bathymetryOpacity = useAppSelector(
    (state) => state.map.bathymetryOpacity
  );

  const [osmChecked, setOsmChecked] = useState(false);
  const [datumRingsChecked, setDatumRingsChecked] = useState(false);
  const [bathymetryChecked, setBathymetryChecked] = useState(false);
  const [baseMapSelected, setBaseMapSelected] =
    useState<UserPreferencesBaseMap>(UserPreferencesBaseMap.DEFAULT);

  const handleOSMChecked = () => {
    const newState = !osmChecked;
    setOsmChecked(newState);
    MapHelpers.setLayerVisibility(MapLayer.OSM_SEA, newState);
    localStorage.setItem('osmChecked', newState.toString());
  };

  const handleDatumRingsChecked = () => {
    const newState = !datumRingsChecked;
    setDatumRingsChecked(newState);
    MapHelpers.setLayerVisibility(MapLayer.DATUM_RINGS, newState);
    localStorage.setItem('datumRingsChecked', newState.toString());
  };

  const handleBathmetryOpacityChange = (newOpacity: number) => {
    MapHelpers.setFillLayerOpacity(MapLayer.BATHYMETRY, newOpacity);
  };

  const handleBathymetryChecked = () => {
    const newState = !bathymetryChecked;
    setBathymetryChecked(newState);
    MapHelpers.setLayerVisibility(MapLayer.BATHYMETRY, newState).then(() => {
      // bathymetry layer is going on top of location texts and blocking it
      // this fixes that
      MapHelpers.updateMapStyle();
    });
    localStorage.setItem('bathymetryChecked', newState.toString());
  };

  useEffect(() => {
    const savedBaseMapSelected = localStorage.getItem('baseMapSelected');
    if (savedBaseMapSelected && savedBaseMapSelected !== baseMapSelected) {
      setBaseMapSelected(savedBaseMapSelected as UserPreferencesBaseMap);
      MapHelpers.updateMapStyle();
    }
    const savedOsmChecked = localStorage.getItem('osmChecked');
    if (savedOsmChecked && savedOsmChecked !== osmChecked.toString()) {
      setOsmChecked(savedOsmChecked === 'true');
      MapHelpers.setLayerVisibility(
        MapLayer.OSM_SEA,
        savedOsmChecked === 'true'
      );
    }
    const savedDatumRingsChecked = localStorage.getItem('datumRingsChecked');
    if (
      savedDatumRingsChecked &&
      savedDatumRingsChecked !== datumRingsChecked.toString()
    ) {
      setDatumRingsChecked(savedDatumRingsChecked === 'true');
      MapHelpers.setLayerVisibility(
        MapLayer.DATUM_RINGS,
        savedDatumRingsChecked === 'true'
      );
    }
    const savedBathymetryChecked = localStorage.getItem('bathymetryChecked');
    if (
      savedBathymetryChecked &&
      savedBathymetryChecked !== bathymetryChecked.toString()
    ) {
      setBathymetryChecked(savedBathymetryChecked === 'true');
      MapHelpers.setLayerVisibility(
        MapLayer.BATHYMETRY,
        savedBathymetryChecked === 'true'
      ).then(() => {
        MapHelpers.updateMapStyle();
      });
    }
  }, [baseMapSelected, osmChecked, datumRingsChecked, bathymetryChecked]);

  return (
    <Box>
      <PanelTitle>Maps and Maritime Cartography</PanelTitle>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ display: 'flex', ml: '1rem' }} variant="subtitle2">
          Overlays:
        </Typography>
        <List sx={{ width: '100%' }}>
          {osmChecked && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                flexDirection: 'column',
                padding: '8px 16px',
              }}
            >
              <ErrorPanel
                level="warning"
                message="Open Sea Map charts are not for navigation"
              />
            </Box>
          )}
          <ListItem>
            <ListItemText primary="Open Sea Map" />
            <ListItemIcon>
              <Switch
                data-testid="open-sea-map-switch"
                checked={osmChecked}
                onChange={handleOSMChecked}
              />
            </ListItemIcon>
          </ListItem>

          {canAccessDatumRings && (
            <ListItem>
              <ListItemText primary="Toggle Datum Rings" />
              <ListItemIcon>
                <Switch
                  data-testid="toggle-datum-rings-switch"
                  checked={datumRingsChecked}
                  onChange={handleDatumRingsChecked}
                />
              </ListItemIcon>
            </ListItem>
          )}

          {canAccessBathymetry && (
            <ListItem>
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}
                >
                  <ListItemText primary="Bathymetry" />
                  <ListItemIcon>
                    <Switch
                      data-testid="toggle-bathymetry-switch"
                      checked={bathymetryChecked}
                      onChange={handleBathymetryChecked}
                    />
                  </ListItemIcon>
                </Box>
                <Box>
                  <Typography variant="caption">
                    Bathymetry Opacity: {bathymetryOpacity}%
                  </Typography>
                  <MUISlider
                    name="bathymetryOpacity"
                    id="bathymetryOpacity-slider"
                    value={bathymetryOpacity}
                    onChange={(_event, value) => {
                      handleBathmetryOpacityChange(value as number);
                    }}
                    disabled={!bathymetryChecked}
                    aria-label="Bathymetry Opacity"
                    valueLabelDisplay="auto"
                    min={1}
                    max={100}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                  }}
                >
                  <Typography variant="caption" display="block">
                    Note: Bathymetry is only available at zoom level 3 and above
                    <br />
                    You are at zoom level {mapZoom.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );
}

export default MapsPanel;

import { VesselHistoryResponse, getVesselHistory } from '../api';
import MapLayer from '../map/map-layer-manager/map-layer.enum';
import setVesselFeatures from '../map/map-layer-manager/vessel-utils/set-vessel-features';
import MapHelpers from '../map/map.utils';
import { HistoricVesselPoint } from '../maritime-menu-options/history-panel/historic-vessel-point.model';
import AISDataGapsController from '../maritime-menu-options/history-panel/history-ais-data-gaps/ais-data-gaps-controller.utils';
import AISMergedPointsController from '../maritime-menu-options/history-panel/history-ais-merged-points/history-ais-merged-points-controller.utils';
import { HistoryFormValues } from '../maritime-menu-options/history-panel/history-form/history-form-validators';
import {
  clearVesselHistoryData,
  prependHistoricVesselPoints,
  setError,
  setHistoricVesselPoints,
  setLoading,
  setMergedHistoricVesselPoints,
  setVesselHistoryData,
} from '../maritime-menu-options/history-panel/history-panel.slice';
import VesselHistoryController from '../maritime-menu-options/history-panel/vessel-history-controller.utils';
import {
  MaritimeAisApiLocationData,
  VesselData,
} from '../models/maritime-ais-api';
import { Vessel, VesselSource } from '../models/vessel.model';
import store from '../store';
import { UserPreferences } from '../user-settings/user-preferences/user-preferences.slice';
import { IMO_LENGTH, MMSI_LENGTH } from './vessel-constants.utils';

export const buildIdentifiers = (
  input: string[]
): { imos: string[]; mmsis: string[] } => {
  const seperated = input.reduce(
    (acc, str) => {
      if (str.length === IMO_LENGTH) {
        acc.imos.push(str);
      } else if (str.length === MMSI_LENGTH) {
        acc.mmsis.push(str);
      }
      return acc;
    },
    { imos: [], mmsis: [] } as { imos: string[]; mmsis: string[] }
  );
  return seperated;
};

export const maritimeAisResponseToVessels = (
  maritimeAis: MaritimeAisApiLocationData
) => {
  const vessels: Vessel[] = [];
  Object.values(maritimeAis.data).forEach((vesselData) => {
    if (vesselData.vessel) {
      vessels.push({
        vessel_id: vesselData.vessel.vesselId,
        name: vesselData.vessel.staticData.name,
        imo: vesselData.vessel.staticData.imo,
        mmsi: vesselData.vessel.staticData.mmsi,
        heading: vesselData.messages[0].heading,
        latitude: vesselData.messages[0].position?.coordinates[1] || 0,
        longitude: vesselData.messages[0].position?.coordinates[0] || 0,
        course: vesselData.messages[0].course,
        timestamp: vesselData.messages[0].timestamp,
        speed: vesselData.messages[0].speed,
        source: VesselSource.AIS,
      });
    }
  });
  return vessels;
};

export const renderNearbyVessels = (
  maritimeAisResponse: MaritimeAisApiLocationData | null
) => {
  if (!maritimeAisResponse) {
    setVesselFeatures(MapLayer.NEARBY_VESSELS, []);
  } else {
    const vessels = maritimeAisResponseToVessels(maritimeAisResponse);
    setVesselFeatures(MapLayer.NEARBY_VESSELS, vessels);
  }
  MapHelpers.setLayerVisibilityIfExists(MapLayer.NEARBY_VESSELS, true);
  if (MapHelpers.getLayer(MapLayer.SELECTED_AIS_POSITION)) {
    MapHelpers.moveLayer(
      MapLayer.NEARBY_VESSELS,
      MapLayer.SELECTED_AIS_POSITION
    );
  } else {
    MapHelpers.moveLayer(MapLayer.NEARBY_VESSELS);
  }
};

export function renderVesselHistory(
  rawVesselPoints: HistoricVesselPoint[],
  userPreferences: UserPreferences,
  { prepend, shouldDisplayOtherVessels } = {
    prepend: true,
    shouldDisplayOtherVessels: false,
  }
) {
  const mergedFeaturePoints =
    AISMergedPointsController.getMergedFeaturePoints(rawVesselPoints);
  const formattedVesselPoints =
    AISMergedPointsController.addMergedFeaturePointData(
      rawVesselPoints,
      mergedFeaturePoints
    );
  MapHelpers.setLayerVisibilityIfExists(
    MapLayer.VESSEL_FOCUS_RING,
    shouldDisplayOtherVessels
  );

  store.dispatch(setError(false));
  if (prepend) {
    store.dispatch(prependHistoricVesselPoints(formattedVesselPoints));
  } else {
    store.dispatch(setHistoricVesselPoints(formattedVesselPoints));
  }
  store.dispatch(setMergedHistoricVesselPoints(mergedFeaturePoints));

  VesselHistoryController.init(formattedVesselPoints);

  AISDataGapsController.resetToDefaults();
  AISMergedPointsController.resetToDefaults();
}

export const getRawVesselPoints = (data: any[]) =>
  data.flatMap(
    (vessel: { messages: HistoricVesselPoint[]; vessel: Vessel }) => {
      const rawVessels: HistoricVesselPoint[] = [];
      if (vessel.messages.length > 0 && vessel.vessel) {
        if (vessel.vessel) {
          vessel.messages.forEach((message) => {
            const newMessage: HistoricVesselPoint = {
              ...message,
              imo: vessel.vessel.imo,
              name: vessel.vessel.name,
            };
            rawVessels.push(newMessage);
          });
        }
      }
      return rawVessels;
    }
  );

export const getVesselHistoryData = (
  values: HistoryFormValues,
  userPreferences?: UserPreferences
) => {
  const identifiers = buildIdentifiers(values.identifiers.split(' '));

  const queryParams = {
    'start-date': values.fromDate,
    'end-date': values.toDate,
    'sample-rate': values.sampleRate,
    mmsis: identifiers.mmsis,
    imos: identifiers.imos,
  };
  return getVesselHistory(queryParams)
    .then((response: VesselHistoryResponse | VesselData[]) => {
      // response should only be null if we were in websocket mode, which we are not
      const { data } = response as VesselHistoryResponse;
      store.dispatch(
        setVesselHistoryData({
          data,
          formValues: values,
        })
      );

      const rawVesselPoints = getRawVesselPoints(data);
      renderVesselHistory(rawVesselPoints, userPreferences!);
      VesselHistoryController.onVesselHistoryDraw();
    })
    .catch(() => {
      store.dispatch(setError(true));
    })
    .finally(() => {
      store.dispatch(setLoading(false));
    });
};

export const clearVesselHistory = () => {
  store.dispatch(clearVesselHistoryData());
  VesselHistoryController.clearAllHistoryLayers();
};

export const clearNearbyVessels = () => {
  setVesselFeatures(MapLayer.NEARBY_VESSELS, []);
  MapHelpers.setLayerVisibilityIfExists(MapLayer.NEARBY_VESSELS, true);
};

export const hasMatchingMMSI = (
  vesselsArray: any,
  currentVessel: any
): boolean =>
  vesselsArray.some(
    (vessel: any) =>
      vessel.mmsi === currentVessel.mmsi &&
      vessel !== currentVessel &&
      vessel.mmsi
  );

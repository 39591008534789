import { Popup } from 'mapbox-gl';
import correspondentInfoPopUpContent from '../map/map-layer-manager/correspondents-utils/correspondents-info-popup';
import firstCallInfoPopUpContent from '../map/map-layer-manager/first-call-utils/first-call-info-popup';
import incidentInfoPopUpContent from '../map/map-layer-manager/incident-utils/incident-info-popup';
import industryNewsInfoPopUpContent from '../map/map-layer-manager/industry-news-utils/industry-news-info-popup';
import MapLayer from '../map/map-layer-manager/map-layer.enum';
import {
  hideMaritimeAreaInfoPopUp,
  maritimeAreaInfoPopUpContent,
} from '../map/map-layer-manager/maritime-areas-utils/maritime-area-info-popup';
import portInfoPopUpContent from '../map/map-layer-manager/port-utils/port-info-popup';
import { assertIsPointFeature } from '../utils/typescript-helpers';

namespace PopupHelpers {
  interface InfoCard {
    label: string;
    value: string | Number | undefined;
  }

  export const createInfoCard = (
    popup: mapboxgl.Popup,
    info: InfoCard[],
    header?: string
  ) => {
    let html = '';

    if (info.length > 1) {
      html += header
        ? `<div class="mapboxgl-popup-content-header">${header}</div>`
        : '';

      info.forEach((section) => {
        const { label, value } = section;
        if (label === 'DIVIDER') {
          html += '<hr>';
        } else if (label !== '') {
          html += `<div class="mapboxgl-popup-property">${
            label && `<b>${label} </b>`
          }${value || 'Unavailable'}</div>`;
        }
      });
    } else if (info.length === 1) {
      html += header
        ? `<div class="mapboxgl-popup-content-header">${header}</div>`
        : '';
      html += `<div class="mapboxgl-popup-name">${
        info[0].value || 'Unavailable'
      }</div>`;
    }

    popup.setHTML(html);
  };

  export const generateLayerPopupContent = (
    layer: MapLayer,
    e: mapboxgl.MapLayerMouseEvent,
    popupContent: { label: string; value: any }[],
    popup: Popup
  ) => {
    e.target.getCanvas().style.cursor = 'pointer';
    if (e.features?.length) {
      e.features.forEach((feature) => {
        if (popupContent.length > 0)
          popupContent.push({ label: 'DIVIDER', value: '' });
        switch (layer) {
          case MapLayer.INCIDENTS:
          case MapLayer.ROUTES_INCIDENTS:
            incidentInfoPopUpContent(feature)?.forEach((item) =>
              popupContent.push(item)
            );
            break;
          case MapLayer.RI_MARITIME_AREAS:
            popupContent.push(
              maritimeAreaInfoPopUpContent(e) as {
                label: string;
                value: string;
              }
            );
            break;
          case MapLayer.PORTS:
          case MapLayer.ROUTES_PORTS:
            portInfoPopUpContent(feature)?.forEach((item) =>
              popupContent.push(item)
            );
            break;
          case MapLayer.FIRST_CALL_PORTS:
            firstCallInfoPopUpContent(feature)?.forEach((item) =>
              popupContent.push(item)
            );
            break;
          case MapLayer.CORRESPONDENTS:
            popupContent.push(
              correspondentInfoPopUpContent(feature) as {
                label: string;
                value: string;
              }
            );
            break;
          case MapLayer.INDUSTRY_NEWS:
            popupContent.push(
              industryNewsInfoPopUpContent(feature) as {
                label: string;
                value: string;
              }
            );
            break;
          default:
            break;
        }
      });

      createInfoCard(popup, popupContent);

      if (layer === MapLayer.RI_MARITIME_AREAS) {
        popup.setLngLat(e.lngLat);
      }
      if (assertIsPointFeature(e.features[0])) {
        popup.setLngLat(e.features[0].geometry.coordinates);
      }
      popup.addTo(e.target);
    }
  };

  export const deleteLayerPopupContent = (
    layer: MapLayer,
    e: mapboxgl.MapLayerMouseEvent,
    popup: Popup
  ) => {
    e.target.getCanvas().style.cursor = '';
    popup.remove();

    if (layer === MapLayer.RI_MARITIME_AREAS) {
      hideMaritimeAreaInfoPopUp(e);
    }
  };
}

export default PopupHelpers;

/* eslint-disable react/require-default-props */
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import mapboxgl from 'mapbox-gl';
import { useState } from 'react';
import MapHelpers from '../../../map/map.utils';
import { Vessel } from '../../../models/vessel.model';
import VesselHistoryController from '../vessel-history-controller.utils';
import HistoryResultsLayerItem from './history-results-layer-item';

interface HistoryResultCardProps extends Partial<Pick<Vessel, 'imo' | 'mmsi'>> {
  name?: string | null;
  coordinates?: mapboxgl.LngLatLike | null;
  vesselId: string;
}

function HistoryResultCard(props: HistoryResultCardProps) {
  const { name, imo, mmsi, vesselId, coordinates } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleZoomToLocation = () => {
    if (coordinates) {
      MapHelpers.zoomToPoint(coordinates);
    }
  };

  const openMenu = (anchor: HTMLElement) => {
    setAnchorEl(anchor);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="vessel-history-more-options"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          closeMenu();
        }}
        sx={{ zIndex: 11 }} // This is needed to display the menu above the panel.
        slotProps={{
          paper: {
            sx: {
              bgcolor: 'primary.main',
            },
          },
        }}
      >
        <MenuItem
          sx={{ display: 'flex', gap: '8px' }}
          onClick={handleZoomToLocation}
        >
          <Typography variant="body2">Zoom to Vessel</Typography>
          <LocationOnOutlinedIcon />
        </MenuItem>
        <MenuItem disableGutters>
          <HistoryResultsLayerItem
            label="Vessel Journey"
            sources={[
              VesselHistoryController.layerList.JOURNEYS.getLayerKey(vesselId),
            ]}
          />
        </MenuItem>
        <MenuItem disableGutters>
          <HistoryResultsLayerItem
            label="AIS positions"
            sources={[
              VesselHistoryController.layerList.AIS_POSITIONS.getLayerKey(
                vesselId
              ),
            ]}
          />
        </MenuItem>
        <MenuItem disableGutters>
          <HistoryResultsLayerItem
            label="Heatmap"
            sources={[
              VesselHistoryController.layerList.AIS_HEATMAPS.getLayerKey(
                vesselId
              ),
            ]}
          />
        </MenuItem>
      </Menu>
      <ListItem
        dense
        disablePadding
        disableGutters
        secondaryAction={
          <IconButton
            size="small"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              openMenu(event.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        }
        sx={{
          borderBottom: '1px solid',
          borderColor: 'background.paper',
        }}
      >
        <ListItemButton dense disableRipple>
          <ListItemText
            primary={name || 'No Name'}
            secondary={
              <Typography component="span" variant="body2">
                <b>MMSI</b> {mmsi} <b>IMO</b> {imo}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
    </>
  );
}

export default HistoryResultCard;

import { View } from '@aws-amplify/ui-react';
import { StyleSheet, Text } from '@react-pdf/renderer';
import styles from './pdf-styles';

export function AssessmentTitle({
  title,
  heading,
}: {
  title: string;
  heading: string;
}) {
  return (
    <View style={styles.section}>
      <Text style={styles.title}>{title}</Text>
      <Text style={styles.titleSubTitle}>{heading}</Text>
    </View>
  );
}

export function AssessmentSummary({ summary }: { summary: string }) {
  return (
    <View style={styles.section}>
      <Text style={styles.subTitle}>Summary</Text>
      <Text style={styles.textBody}>{summary}</Text>
    </View>
  );
}

export const riDocumentStyles = StyleSheet.create({
  threatAttributes: {
    fontFamily: 'Helvetica-Bold',
    color: '#203058',
    fontSize: 10,
    marginHorizontal: 5,
  },
});

import { wrapRequest } from './base';

import { MAPBOX_ACCESS_TOKEN } from '../map/map.slice';
import { MapboxGeocodeResponse } from '../models/mapbox-geocoding.model';

const MAX_TILESETS_PER_REQUEST = 15;

export const getMapboxFeaturesInVectorTiles = async (
  tilesQueryString: string,
  coordinatesQueryString: string
): Promise<{ data: GeoJSON.FeatureCollection }> =>
  wrapRequest(
    'get',
    'mapbox',
    `/v4/?${tilesQueryString}/tilequery/${coordinatesQueryString}.json`,
    {
      queryStringParameters: {
        access_token: MAPBOX_ACCESS_TOKEN,
        limit: MAX_TILESETS_PER_REQUEST,
      },
    }
  );

export const getMapboxGeocode = async (
  lng: number,
  lat: number
): Promise<MapboxGeocodeResponse> =>
  wrapRequest(
    'get',
    'mapbox',
    `/geocoding/v5/mapbox.places/${lng},${lat}.json`,
    {
      queryStringParameters: {
        access_token: MAPBOX_ACCESS_TOKEN,
        types: 'country',
      },
    }
  );

export const getMapboxStyle = async (
  styleId: string,
  mapboxAccessToken = MAPBOX_ACCESS_TOKEN
) =>
  wrapRequest('get', 'mapbox', `/styles/v1/${styleId}`, {
    queryStringParameters: {
      access_token: mapboxAccessToken,
    },
  });

import { LngLatLike, MapLayerMouseEvent, Popup } from 'mapbox-gl';
import MapHelpers from '../../../map/map.utils';
import PopupHelpers from '../../../popup/popup.utils';
import DateTimeHelpers, {
  dateToUtcTimeString,
} from '../../../utils/date-time-helpers.utils';

const POPUP_CLASSNAME = 'ais-data-gaps-popup';

const fromPopup = new Popup({
  closeButton: true,
  closeOnMove: false,
  className: POPUP_CLASSNAME,
});

export const toPopup = new Popup({
  closeButton: true,
  closeOnMove: false,
  className: POPUP_CLASSNAME,
});

const linePopup = new Popup({
  closeButton: false,
  closeOnMove: true,
});

export function hideAISDataGapsPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = '';
  linePopup.remove();
}

export function showAISDataGapLinePopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  if (e.features?.length) {
    const feature = e.features[0];
    const { properties } = feature;

    const hoursOffline =
      Number(properties?.duration) / DateTimeHelpers.MILLISECONDS_IN_HOUR;

    PopupHelpers.createInfoCard(linePopup, [
      {
        label: 'Name',
        value: properties?.name,
      },
      {
        label: 'MMSI',
        value: properties?.mmsi,
      },
      {
        label: 'IMO',
        value: properties?.imo,
      },
      {
        label: 'Time Offline',
        value: DateTimeHelpers.formatHoursAndMinutes(hoursOffline),
      },
    ]);

    // @ts-ignore
    // can't figure out the typing needed for coordinates
    linePopup.setLngLat(e.lngLat);
    linePopup.addTo(e.target);
  }
}

export function hideAISDataGapPointPopups() {
  fromPopup.remove();
  toPopup.remove();
}

export function showAISDataGapPointPopups(
  feature: GeoJSON.Feature<GeoJSON.LineString>
) {
  const { properties, geometry } = feature;
  const map = MapHelpers.getMapInstance();
  PopupHelpers.createInfoCard(
    fromPopup,
    [
      {
        label: 'Last Broadcast',
        value: `<br>${dateToUtcTimeString(properties?.startTimestamp)}`,
      },
    ],
    'Start of Gap'
  );

  PopupHelpers.createInfoCard(
    toPopup,
    [
      {
        label: 'Next Broadcast',
        value: `<br>${dateToUtcTimeString(properties?.endTimestamp)}`,
      },
    ],
    'End of Gap'
  );

  const { coordinates } = geometry;

  fromPopup.setLngLat(coordinates[0] as LngLatLike);
  fromPopup.addTo(map);

  toPopup.setLngLat(coordinates[1] as LngLatLike);
  toPopup.addTo(map);
}

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { List } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import { HistoricVesselPoint } from '../historic-vessel-point.model';
import HistoryResultCard from './history-result-card';

function HistoryResultsList() {
  const { historicVesselPoints } = useAppSelector(
    (state) => state.historyPanel
  );

  const uniqueVesselIdentifiersArray = Array.from(
    new Set(historicVesselPoints.map((point) => point.vessel_id))
  );

  return (
    // <div className="history-panel-results" data-testid="history-panel-results">
    <List data-testid="history-panel-results" disablePadding>
      {historicVesselPoints.length ? (
        uniqueVesselIdentifiersArray.map((uvi: string) => {
          // Use the last point to get the latest location of the vessel
          // Would love to use `findLast` but we'd need to update our Javascript and Typescript versions
          // Feel it's not worth the risk for this one usecase
          const details = [...historicVesselPoints]
            .reverse()
            .find((point: HistoricVesselPoint) => point.vessel_id === uvi);

          // TODO: Replace collection_type with name once we have it in API Response
          // TODO: Show "no result found for vessel X" ?
          return details ? (
            <HistoryResultCard
              name={details.name}
              imo={details.imo}
              mmsi={details.mmsi}
              key={details.vessel_id}
              vesselId={details.vessel_id}
              coordinates={[details.longitude, details.latitude]}
            />
          ) : null;
        })
      ) : (
        <span>No Vessel results. Please try new query parameters.</span>
      )}
    </List>
  );
}

export default HistoryResultsList;

import { useEffect, useRef } from 'react';
import { Components, Virtuoso, VirtuosoHandle } from 'react-virtuoso';

interface VirtualisedListWrapperProps {
  items: any[];
  selectedItemIndex: number | null;
  listContent(index: number): JSX.Element | null;
  dataTestId?: string;
  styleOverrides?: React.CSSProperties;
  components?: Components;
  height?: string;
  scrollBehaviour?: 'auto' | 'smooth';
}

export default function VirtualisedListWrapper({
  items,
  selectedItemIndex,
  listContent,
  dataTestId,
  styleOverrides,
  components,
  height = '100%',
  scrollBehaviour = 'auto',
}: VirtualisedListWrapperProps) {
  const virtualisedListRef = useRef<VirtuosoHandle>(null);
  const visibleRange = useRef({ startIndex: 0, endIndex: 0 });

  // enables us to auto-scroll to the selected item within virtualised list
  useEffect(() => {
    if (selectedItemIndex && virtualisedListRef.current) {
      const { startIndex, endIndex } = visibleRange.current;

      // if selectedItemIndex is outside current visible range, scroll to it
      if (selectedItemIndex < startIndex || selectedItemIndex > endIndex) {
        virtualisedListRef.current.scrollToIndex({
          index: selectedItemIndex,
          align: 'start',
          behavior: scrollBehaviour,
        });
      }
    }
  }, [selectedItemIndex]);

  return (
    <Virtuoso
      data-testid={dataTestId}
      style={{ height, scrollbarWidth: 'thin', ...styleOverrides }}
      ref={virtualisedListRef}
      totalCount={items.length}
      rangeChanged={(e) => {
        visibleRange.current.startIndex = e.startIndex;
        visibleRange.current.endIndex = e.endIndex;
      }}
      itemContent={(index) => listContent(index)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(components ? { components } : {})} // Virtuoso responds incorrectly to components={undefined}, so only add it when they are defined
    />
  );
}

VirtualisedListWrapper.defaultProps = {
  dataTestId: undefined,
  styleOverrides: {},
  components: undefined,
  height: '100%',
  scrollBehaviour: 'auto',
};

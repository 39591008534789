import MapHelpers from '../../map.utils';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerPaint from '../map-layer-paint';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer, { MapGroupLayer } from '../map-layer.enum';
import MeasurementPointController from './measurement-point-controller.utils';

export const addMeasurementDrawLayers = () => {
  addSimpleGeoJsonSource(MapLayer.MEASUREMENT_LINES_TEMP);
  MapLayerManager.AddLayerinGroups(
    {
      id: MapLayer.MEASUREMENT_LINES_TEMP,
      type: 'line',
      source: MapLayer.MEASUREMENT_LINES_TEMP,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: MapLayerPaint.TEMP_DRAWING_LINE_PAINT,
    },
    [MapGroupLayer.MEASUREMENTS]
  );

  addSimpleGeoJsonSource(MapLayer.MEASUREMENT_POINTS_TEMP);
  MapLayerManager.AddLayerinGroups(
    {
      id: MapLayer.MEASUREMENT_POINTS_TEMP,
      type: 'circle',
      source: MapLayer.MEASUREMENT_POINTS_TEMP,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: MapLayerPaint.TEMP_DRAWING_POINT_PAINT,
    },
    [MapGroupLayer.MEASUREMENTS]
  );
};

export const addMeasurementLayers = () => {
  addSimpleGeoJsonSource(MapLayer.MEASUREMENT_LINES);
  MapLayerManager.AddLayerinGroups(
    {
      id: MapLayer.MEASUREMENT_LINES,
      type: 'line',
      source: MapLayer.MEASUREMENT_LINES,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: MapLayerPaint.MEASUREMENT_LINE_PAINT,
    },
    [MapGroupLayer.MEASUREMENTS]
  );

  addSimpleGeoJsonSource(MapLayer.MEASUREMENT_POINTS);
  MapLayerManager.AddLayerinGroups(
    {
      id: MapLayer.MEASUREMENT_POINTS,
      type: 'circle',
      source: MapLayer.MEASUREMENT_POINTS,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: MapLayerPaint.MEASUREMENT_POINT_PAINT,
    },
    [MapGroupLayer.MEASUREMENTS]
  );

  MapHelpers.addMapEventListener(
    'mouseenter',
    MapLayer.MEASUREMENT_POINTS,
    MeasurementPointController.layerEvents.onMouseEnter
  );
  MapHelpers.addMapEventListener(
    'mouseleave',
    MapLayer.MEASUREMENT_POINTS,
    MeasurementPointController.layerEvents.onMouseLeave
  );
  MapHelpers.addMapEventListener(
    'mousedown',
    MapLayer.MEASUREMENT_POINTS,
    MeasurementPointController.layerEvents.onMouseDown
  );
};

import { Incident } from '../../../maritime-menu-options/incidents-panel/incident.model';
import { onRouteIncidentClick } from '../../../maritime-menu-options/routes-panel/routes-panel.utils';
import { setSelectedRouteIncidentId } from '../../../state/routes/routes.slice';
import store from '../../../store';

function onRoutesIncidentsClick(incident: Incident) {
  const { selectedRoute } = store.getState().routes;
  if (!selectedRoute) return;

  store.dispatch(setSelectedRouteIncidentId(incident.id));
  onRouteIncidentClick(incident, selectedRoute);
}

export default onRoutesIncidentsClick;

import MapHelpers from '../../map.utils';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addNearbyVesselLayerToMap from './add-nearby-vessel-layer';
import addPlainVesselLayerToMap from './add-plain-vessel-layer';
import VesselsController from './vessel-controller.utils';

interface Metadata {
  groupLayers?: string[];
}

function addVesselsLayer(
  layer: MapLayer | string,
  visibility: MapLayerVisibility = MapLayerVisibility.VISIBLE,
  metadata?: Metadata
) {
  return new Promise((resolve) => {
    addSimpleGeoJsonSource(layer);

    if (layer === MapLayer.NEARBY_VESSELS) {
      addNearbyVesselLayerToMap(layer, visibility, metadata);
    } else {
      addPlainVesselLayerToMap(layer, visibility, metadata);
    }
    MapHelpers.addMapEventListener('mouseenter', layer, (e) =>
      VesselsController.layerEvents.onMouseEnter(e)
    );
    MapHelpers.addMapEventListener('mouseleave', layer, (e) =>
      VesselsController.layerEvents.onMouseLeave(e)
    );
    resolve(MapHelpers);
  });
}

export default addVesselsLayer;

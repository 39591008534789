import { Chip, Divider, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../hooks';
import {
  EDossiers,
  EMenuItems,
  setSelectedAreaDocument,
  setSelectedDossier,
  setSelectedOption,
} from '../main-menu/menu.slice';
import MapHelpers from '../map/map.utils';
import { setSearchFilterValue as setDrawingsSearchFilterValue } from '../maritime-menu-options/areas-panel/drawings.slice';
import BoundariesController from '../maritime-menu-options/boundaries-panel/boundaries-controller.utils';
import { setSearchFilterValue as setBoundariesSearchFilterValue } from '../maritime-menu-options/boundaries-panel/boundaries-panel.slice';
import { Incident } from '../maritime-menu-options/incidents-panel/incident.model';
import {
  setSelectedArea,
  setSelectedAreaId,
} from '../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import { Port } from '../maritime-menu-options/world-ports-panel/world-ports.model';
import { Boundary } from '../models/boundary.model';
import { Correspondent } from '../models/correspondents.model';
import { DocumentWithMeta } from '../models/document';
import { Drawing } from '../models/drawings.model';
import { FirstCallFeature } from '../models/first-call';
import { Country, MaritimeArea } from '../models/risk_intelligence.model';
import { SearchableDataKey, SearchableDataValue } from '../models/search';
import { addHistoryItem } from '../nav-history.slice';
import { setSelectedBoundary } from '../state/boundaries/boundaries.slice';
import { setSelectedCountryId } from '../state/countries/countries.slice';
import { setSelectedIncident } from '../state/incidents/incidents.slice';
import { setSelectedPort, setSelectedPortId } from '../state/ports/ports.slice';
import GeoHelper from '../utils/geo-helpers.utils';

interface SearchResultRowProps {
  title: string;
  onClick: (e: React.MouseEvent) => void;
  dataType: string;
  last: boolean;
}

function SearchResultRow({
  title,
  onClick,
  dataType,
  last,
}: SearchResultRowProps) {
  return (
    <>
      <ListItemButton className="search-result-item-card" onClick={onClick}>
        <div className="search-result-item-title">{title}</div>
        <Chip label={dataType} size="small" />
      </ListItemButton>
      {!last && <Divider component="li" sx={{ margin: '0 0.2rem' }} />}
    </>
  );
}

interface SearchResultItemProps {
  item: {
    dataType: SearchableDataKey;
    value: SearchableDataValue;
  };
  last: boolean;
}

export default function SearchResultItem(props: SearchResultItemProps) {
  const { item, last } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  switch (item.dataType) {
    case 'incidents': {
      const incident = item.value as Incident;
      const incidentOnClick = () => {
        dispatch(setSelectedDossier(EDossiers.INCIDENT));
        dispatch(setSelectedIncident(incident));
        dispatch(setSelectedOption(EMenuItems.INCIDENTS));
        dispatch(addHistoryItem({ type: 'search' }));
        navigate(`/incidents/${incident.id}`);
      };
      return (
        <SearchResultRow
          title={incident.title}
          onClick={incidentOnClick}
          dataType="Incident"
          last={last}
        />
      );
    }
    case 'countries': {
      const country = item.value as Country;
      const countryOnClick = () => {
        dispatch(setSelectedDossier(EDossiers.COUNTRY));
        dispatch(setSelectedOption(EMenuItems.COUNTRIES));
        dispatch(setSelectedCountryId(country.id));
        dispatch(addHistoryItem({ type: 'search' }));
        navigate(`/countries/${country.id}`);
        GeoHelper.getCountryBoundsFromShortcode(country.code).then((bbox) => {
          if (bbox) {
            MapHelpers.zoomToBBox(bbox);
          }
        });
      };

      return (
        <SearchResultRow
          title={country.title}
          onClick={countryOnClick}
          dataType="Country"
          last={last}
        />
      );
    }
    case 'ports': {
      const portItem = item.value as Port;
      const portOnClick = () => {
        dispatch(setSelectedDossier(EDossiers.PORT));
        dispatch(setSelectedPort(portItem));
        dispatch(setSelectedOption(EMenuItems.PORTS));
        dispatch(addHistoryItem({ type: 'search' }));
        navigate(`/ports/${portItem.UNLOCODE.replaceAll(' ', '')}`);

        if (portItem.LAT && portItem.LONG) {
          MapHelpers.zoomToPoint([
            parseFloat(portItem.LONG),
            parseFloat(portItem.LAT),
          ]);
        }
      };

      return (
        <SearchResultRow
          title={portItem.NAME}
          onClick={portOnClick}
          dataType="Port"
          last={last}
        />
      );
    }
    case 'boundaries': {
      const boundaryItem = item.value as Boundary;
      const boundariesOnClick = () => {
        dispatch(setSelectedOption(EMenuItems.BOUNDARIES));
        BoundariesController.createBoundaryLayer(boundaryItem, {
          createVisible: true,
        });
        dispatch(setSelectedBoundary(boundaryItem));
        dispatch(setBoundariesSearchFilterValue(boundaryItem.name));
        dispatch(addHistoryItem({ type: 'search' }));
        navigate(`/boundaries/${boundaryItem.boundary_id}`);
      };

      return (
        <SearchResultRow
          title={boundaryItem.name}
          onClick={boundariesOnClick}
          dataType="Boundary"
          last={last}
        />
      );
    }
    case 'drawings': {
      const drawingItem = item.value as Drawing;
      const drawingOnClick = () => {
        dispatch(setSelectedDossier(EDossiers.DRAWING));
        dispatch(setSelectedOption(EMenuItems.DRAWINGS));
        MapHelpers.setLayerVisibility(drawingItem.region_id, true);
        MapHelpers.zoomToFeatureCollection(drawingItem.region_data);
        dispatch(setDrawingsSearchFilterValue(drawingItem.region_name));
        dispatch(addHistoryItem({ type: 'search' }));
        navigate(`/drawings/${drawingItem.region_id}`);
      };

      return (
        <SearchResultRow
          title={drawingItem.region_name}
          onClick={drawingOnClick}
          dataType="Drawing"
          last={last}
        />
      );
    }

    case 'maritimeAreas': {
      const areaItem = item.value as MaritimeArea;
      const areaOnClick = () => {
        dispatch(setSelectedOption(EMenuItems.RI_MARITIME_AREAS));
        dispatch(setSelectedDossier(EDossiers.RI_MARITIME_AREA));
        dispatch(setSelectedArea(areaItem));
        MapHelpers.zoomToFeatureCollection(areaItem.geo);
        dispatch(addHistoryItem({ type: 'search' }));
        navigate(`/ri-maritime-areas/${areaItem.id}`);
      };

      return (
        <SearchResultRow
          key={areaItem.id}
          title={areaItem.title}
          onClick={areaOnClick}
          dataType="RI Maritime Area"
          last={last}
        />
      );
    }

    case 'documents': {
      const documentItem = item.value as DocumentWithMeta;

      const documentOnClick = () => {
        if (documentItem.metadata?.entity_type) {
          switch (documentItem.metadata?.entity_type) {
            case 'country': {
              dispatch(setSelectedDossier(EDossiers.COUNTRY));
              dispatch(setSelectedOption(EMenuItems.COUNTRIES));
              dispatch(
                setSelectedCountryId(Number(documentItem.metadata.entity_id))
              );
              navigate(`/countries/${documentItem.metadata.entity_id}`);
              break;
            }
            case 'port': {
              dispatch(setSelectedDossier(EDossiers.PORT));
              dispatch(setSelectedPortId(documentItem.metadata.entity_id));
              dispatch(setSelectedOption(EMenuItems.PORTS));
              navigate(`/ports/${documentItem.metadata.entity_id}`);
              break;
            }
            case 'area': {
              dispatch(setSelectedDossier(EDossiers.DRAWING));
              dispatch(setSelectedOption(EMenuItems.DRAWINGS));
              dispatch(
                setSelectedAreaDocument(documentItem.metadata.entity_id)
              );
              navigate(`/drawings/${documentItem.metadata.entity_id}`);
              break;
            }
            case 'ri-area': {
              dispatch(setSelectedDossier(EDossiers.RI_MARITIME_AREA));
              dispatch(
                setSelectedAreaId(Number(documentItem.metadata.entity_id))
              );
              dispatch(setSelectedOption(EMenuItems.RI_MARITIME_AREAS));
              navigate(`/ri-maritime-areas/${documentItem.metadata.entity_id}`);
              break;
            }
            default:
              break;
          }
        }
      };

      return (
        <SearchResultRow
          title={documentItem.metadata?.filename!}
          onClick={documentOnClick}
          dataType="Document"
          last={last}
        />
      );
    }

    case 'firstCallPorts': {
      const firstCallItem = item.value as FirstCallFeature;
      const firstCallOnClick = () => {
        dispatch(setSelectedDossier(EDossiers.FIRST_CALL));
        dispatch(setSelectedOption(EMenuItems.FIRST_CALL_PORTS));
        dispatch(addHistoryItem({ type: 'search' }));
        navigate(`/first-call-ports/${firstCallItem.id}`);
      };
      return (
        <SearchResultRow
          key={firstCallItem.id}
          title={firstCallItem.properties.portName}
          onClick={firstCallOnClick}
          dataType="First Call Port"
          last={last}
        />
      );
    }
    case 'correspondents': {
      const correspondent = item.value as Correspondent;
      const correspondentOnClick = () => {
        dispatch(setSelectedDossier(EDossiers.CORRESPONDENTS));
        dispatch(setSelectedOption(EMenuItems.CORRESPONDENTS));
        dispatch(addHistoryItem({ type: 'search' }));
        navigate(`/correspondents/${correspondent.uid}`);
      };
      return (
        <SearchResultRow
          title={correspondent.name}
          onClick={correspondentOnClick}
          dataType="Correspondent"
          last={last}
        />
      );
    }
    default: {
      return <div>{JSON.stringify(item)}</div>;
    }
  }
}

import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import { IconButton, ListItem, ListItemText } from '@mui/material';

import { FileDownloadOutlined } from '@mui/icons-material';
import { DocumentWithMeta } from '../../models/document';
import store from '../../store';

/**
 * This could be improved to search through the various ri entity types and find
 * the title of the entity it is attached to. however this requires us to load all the entities into the system to search through them (as well as logic for if-not-fetched)
 * Currently we do not load all-ri-countries into the system, but instead adhoc on demand, so we cannot search through them.
 * @param document
 * @returns
 */
const getAttachedToTitle = (document: DocumentWithMeta) => {
  switch (document.metadata.entity_type) {
    case 'port': {
      const portName = store
        .getState()
        .ports.ports?.find(
          (port) =>
            port.UNLOCODE === document.metadata.entity_id ||
            port.ri?.id === Number(document.metadata.entity_id)
        )?.NAME;
      if (portName) return `${portName} (port)`;
      return `${document.metadata.entity_type}/${document.metadata.entity_id}`;
    }
    case 'area': {
      const drawingName = store
        .getState()
        .drawings.drawings?.find(
          (drawing) => drawing.region_id === document.metadata.entity_id
        )?.region_name;
      if (drawingName) return `${drawingName} (drawing)`;
      return `${document.metadata.entity_type}/${document.metadata.entity_id}`;
    }
    case 'ri-area': {
      const areaName = store
        .getState()
        .riMaritimeAreas.areas?.find(
          (area) => area.id === Number(document.metadata.entity_id)
        )?.title;
      if (areaName) return `${areaName} (Maritime Area)`;
      return `${document.metadata.entity_type}/${document.metadata.entity_id}`;
    }
    case 'country': {
      const countryName = store
        .getState()
        .countries.countries?.find(
          (country) => country.id === Number(document.metadata.entity_id)
        )?.title;
      if (countryName) return `${countryName} (country)`;
      return `${document.metadata.entity_type}/${document.metadata.entity_id}`;
    }
    default: {
      return `${document.metadata.entity_type}/${document.metadata.entity_id}`;
    }
  }
};

export interface DocumentItemType {
  document: DocumentWithMeta;
  onDocumentDownload: (doc: DocumentWithMeta) => void;
  onDocumentDelete: (doc: DocumentWithMeta) => void;
  canDelete: boolean;
}

function DocumentItem(props: DocumentItemType) {
  const { document, onDocumentDownload, onDocumentDelete, canDelete } = props;

  const { filename: title, description } = document.metadata;

  const descriptionText = description?.trim().length
    ? description
    : 'No Description Available';

  const attachedTo = getAttachedToTitle(document);

  const secondaryText = `${attachedTo} - ${descriptionText}`;
  return (
    <ListItem
      data-testid="document-item"
      sx={{
        py: 2,
      }}
      secondaryAction={
        <>
          <IconButton
            title="Download item"
            data-testid="document-download-button"
            onClick={() => onDocumentDownload(document)}
          >
            <FileDownloadOutlined />
          </IconButton>
          <IconButton
            title="Delete item"
            data-testid="document-delete-button"
            onClick={() => onDocumentDelete(document)}
            disabled={!canDelete}
          >
            <DeleteForeverOutlined />
          </IconButton>
        </>
      }
    >
      <ListItemText
        data-testid="document-description"
        primary={title}
        primaryTypographyProps={{
          style: {
            marginRight: '3rem', // MUI List Items are not designed to have multiple secondary actions, so we need to make sure the text doesn't overlap
          },
          noWrap: true,
        }}
        secondary={secondaryText}
        secondaryTypographyProps={{
          style: {
            marginRight: '3rem',
          },
          noWrap: true,
        }}
      />
    </ListItem>
  );
}

export default DocumentItem;

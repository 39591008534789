import { DeleteOutline } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import ColourPicker from '../../../common-components/colour-picker/colour-picker';
import MuiFormikTextField from '../../../common-components/form-fields/mui-formik-text-field';
import MuiSelectFieldControlled from '../../../common-components/form-fields/mui-select-field-controlled';
import LayerToggle from '../../../common-components/layer-toggle/layer-toggle';
import { RangeRingLayers } from '../../../map/map-layer-manager/map-layer.enum';
import { rangeRingPropertiesValidationSchema } from './range-ring-form-validators';
import { RangeRingCalcOpt, RangeRingProperties } from './range-ring.model';

interface RangeRingPropertiesProps {
  title: string;
  ring: RangeRingProperties;
  onUpdate: (rangeRing: RangeRingProperties) => void;
  onRemove: (rangeRing: RangeRingProperties) => void;
}

function RangeRingCalcDistanceForm() {
  const DURATION_INPUT_NAME = 'duration';
  return (
    <>
      <Grid container>
        <Typography>Duration</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs>
          <MuiFormikTextField
            size="small"
            id="hours"
            name={`${DURATION_INPUT_NAME}.hours`}
            hiddenLabel
            variant="outlined"
            dataTestId="hours"
          />
        </Grid>
        <Grid item xs>
          <Typography>Hours</Typography>
        </Grid>
        <Grid item xs>
          <MuiFormikTextField
            size="small"
            id={`${DURATION_INPUT_NAME}.minutes`}
            name={`${DURATION_INPUT_NAME}.minutes`}
            hiddenLabel
            label="Minutes"
            variant="outlined"
            dataTestId="minutes"
          />
        </Grid>
        <Grid item xs>
          <Typography>Minutes</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography>Speed (knots)</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <MuiFormikTextField
            size="small"
            id="speed"
            name="speed"
            hiddenLabel
            variant="outlined"
            dataTestId="speed"
          />
        </Grid>
      </Grid>
    </>
  );
}

function RangeRingCalcDurationForm() {
  return (
    <>
      <Grid container>
        <Typography>Distance (Nautical Miles)</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs>
          <MuiFormikTextField
            size="small"
            id="distanceNauticalMiles"
            name="distanceNauticalMiles"
            hiddenLabel
            variant="outlined"
            dataTestId="distanceNauticalMiles"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography>Speed (knots)</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <MuiFormikTextField
            size="small"
            id="speed"
            name="speed"
            hiddenLabel
            variant="outlined"
            dataTestId="speed"
          />
        </Grid>
      </Grid>
    </>
  );
}

function RangeRingCalcSpeedForm() {
  const DURATION_INPUT_NAME = 'duration';
  return (
    <>
      <Grid container>
        <Typography>Distance (Nautical Miles)</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs>
          <MuiFormikTextField
            size="small"
            id="distanceNauticalMiles"
            name="distanceNauticalMiles"
            hiddenLabel
            variant="outlined"
            dataTestId="distanceNauticalMiles"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Typography>Duration</Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid item xs>
          <MuiFormikTextField
            size="small"
            id="hours"
            name={`${DURATION_INPUT_NAME}.hours`}
            hiddenLabel
            variant="outlined"
            dataTestId="hours"
          />
        </Grid>
        <Grid item xs>
          <Typography>Hours</Typography>
        </Grid>
        <Grid item xs>
          <MuiFormikTextField
            size="small"
            id={`${DURATION_INPUT_NAME}.minutes`}
            name={`${DURATION_INPUT_NAME}.minutes`}
            hiddenLabel
            label="Minutes"
            variant="outlined"
            dataTestId="minutes"
          />
        </Grid>
        <Grid item xs>
          <Typography>Minutes</Typography>
        </Grid>
      </Grid>
    </>
  );
}

function RangeRingPropertiesForm(props: RangeRingPropertiesProps) {
  const { title, ring, onUpdate, onRemove } = props;

  const COLOUR_INPUT_NAME = 'colour';

  const handleUpdate = (values: RangeRingProperties) => {
    onUpdate(values);
  };

  const [calculationTypeValue, setCalculationTypeValue] = useState(
    RangeRingCalcOpt.DISTANCE
  );

  return (
    <Formik<RangeRingProperties>
      initialValues={{ ...ring, distanceNauticalMiles: 0 }}
      onSubmit={() => {}}
      validationSchema={rangeRingPropertiesValidationSchema}
    >
      {(formik) => (
        <Form onKeyUp={() => handleUpdate(formik.values)}>
          <Paper
            data-testid="range-ring-properties-form"
            sx={{
              padding: '1rem',
              bgcolor: 'primary.main',
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography
                variant="subtitle1"
                fontWeight="bold"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {title}
              </Typography>
              <LayerToggle
                layerGroups={[]}
                layers={[ring.id, RangeRingLayers.LABEL(ring.id)]}
              />
              <ColourPicker
                type="hex"
                colour={ring.colour}
                onChange={(newColour) => {
                  formik.setFieldValue(COLOUR_INPUT_NAME, newColour);

                  // set field value doesn't seem to trigger any change events - manually trigger update here
                  handleUpdate({
                    ...formik.values,
                    colour: newColour,
                  });
                }}
              />
              <IconButton
                sx={{
                  marginLeft: 'auto !important',
                }}
                onClick={() => onRemove(ring)}
                data-testid="remove-range-ring"
              >
                <DeleteOutline />
              </IconButton>
            </Stack>
            <Box sx={{ margin: '1em 0' }}>
              <MuiSelectFieldControlled
                name="calculationOption"
                placeholder="Select Type"
                options={[
                  {
                    name: 'Calculate Distance',
                    value: RangeRingCalcOpt.DISTANCE,
                  },
                  {
                    name: 'Calculate Duration',
                    value: RangeRingCalcOpt.DURATION,
                  },
                  {
                    name: 'Calculate Speed',
                    value: RangeRingCalcOpt.SPEED,
                  },
                ]}
                label="Calculation Type"
                errors={formik.errors}
                touched={formik.touched}
                ariaLabel="calculation-type"
                onChange={(event) => {
                  const calculationOption = event.target
                    .value as RangeRingCalcOpt;
                  formik.setFieldValue('calculationOption', calculationOption);
                  setCalculationTypeValue(
                    event.target.value as RangeRingCalcOpt
                  );
                }}
                value={calculationTypeValue}
              />

              {calculationTypeValue === RangeRingCalcOpt.DISTANCE &&
                RangeRingCalcDistanceForm()}

              {calculationTypeValue === RangeRingCalcOpt.DURATION &&
                RangeRingCalcDurationForm()}

              {calculationTypeValue === RangeRingCalcOpt.SPEED &&
                RangeRingCalcSpeedForm()}
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
}

export default RangeRingPropertiesForm;

import onIncidentClusterClick from './incident-cluster-onClick';
import onIncidentClick from './incident-onClick';

namespace IncidentController {
  export const layerEvents = {
    onClick: onIncidentClick,
    onClusterClick: onIncidentClusterClick,
  };
}

export default IncidentController;

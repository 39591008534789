/* eslint-disable react/jsx-no-useless-fragment */
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { PropsWithChildren, useEffect } from 'react';
import { setFavicon } from '../theme';
import defaultTheme from './default-theme';

type AppThemeProviderProps = PropsWithChildren;

function AppThemeProvider({ children }: AppThemeProviderProps) {
  useEffect(() => {
    setFavicon();
  });

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  );
}

export default AppThemeProvider;

import {
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import MapHelpers from '../../../map/map.utils';
import { Route } from '../../../models/routes.model';
import { addHistoryItem } from '../../../nav-history.slice';
import store from '../../../store';
import { Port } from '../world-ports.model';

import { setSelectedPort } from '../../../state/ports/ports.slice';
import './port-item.scss';

interface Props {
  port: Port;
  route?: Route;
  dense?: boolean;
  selected?: boolean;
}

function PortItem({ port, route, dense, selected }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { NAME, COUNTRY } = port;

  const { selectedPort } = store.getState().ports;

  const [active, setActive] = useState(selectedPort?.ri?.id || null);

  const onClick = (clickedPort: Port) => {
    MapHelpers.zoomToPoint(
      [parseFloat(clickedPort.LONG), parseFloat(clickedPort.LAT)],
      8.5
    );
    if (route) {
      dispatch(addHistoryItem({ type: 'route', properties: route }));
      dispatch(setSelectedDossier(EDossiers.PORT));
    } else {
      dispatch(addHistoryItem({ type: 'portList' }));
      if (clickedPort.ri) {
        navigate(`/ports/${clickedPort.ri.id}`);
      } else {
        navigate(`/ports/${clickedPort.UNLOCODE.replace(/\s/, '')}`);
      }
    }
    dispatch(setSelectedPort(clickedPort));
    setActive(clickedPort?.ri ? clickedPort?.ri?.id : null);
  };

  useEffect(() => {
    setActive(selectedPort?.ri ? selectedPort?.ri?.id : null);
  }, [selectedPort]);

  return (
    // role is not redundant since list-style: none removes the 'semanticity' of a list
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ListItem
      disableGutters
      disablePadding
      sx={{
        bgcolor: active === port?.ri?.id ? 'rgba(0, 0, 0, 0.04)' : '',
        borderBottom: '1px solid',
        borderBottomColor: 'background.paper',
      }}
      dense={dense}
      data-testid="port-item"
    >
      <ListItemButton selected={selected} onClick={() => onClick(port)}>
        <ListItemText>
          <Typography className="portName" variant="subtitle1">
            {NAME}
          </Typography>
          <Typography variant="body2" component="span" className="portCountry">
            {COUNTRY || 'N/A'}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

PortItem.defaultProps = {
  route: undefined,
  dense: false,
  selected: false,
};

export default PortItem;

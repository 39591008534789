import { DMSToDecimal } from '../../map/map-controls.utils';
import { DrawingShape } from '../../models/drawings.model';
import GeoHelper from '../../utils/geo-helpers.utils';

export interface DrawingFormValues {
  name: string;
  description: string;
  shape: DrawingShape;
  topLeftLongitude?: string;
  topLeftLatitude?: string;
  bottomRightLongitude?: string;
  bottomRightLatitude?: string;
  centrePointInputLat?: string;
  centrePointInputLong?: string;
  radiusInNauticalMilesInput?: string;
  coordinates?: [string, string][];
  inputLong?: string;
  inputLat?: string;
  latitude1?: string;
  longitude1?: string;
  latitude2?: string;
  longitude2?: string;
}

// TODO revist this. Commented out incase RI want perfect squares and rectangles

// export const validateRectangle = (values: RegionFormValues) => {
//   const errors: FormikErrors<any> = {};
//   if (!values.topLeftLongitude) {
//     errors.topLeftLongitude = 'Required';
//   } else if (Number.isNaN(parseFloat(values.topLeftLongitude))) {
//     errors.topLeftLongitude = 'Invalid longitude';
//   } else if (
//     !GeoHelper.validateLongitude(parseFloat(values.topLeftLongitude))
//   ) {
//     errors.topLeftLongitude = 'Longitude outside standard range';
//   }

//   if (!values.topLeftLatitude) {
//     errors.topLeftLatitude = 'Required';
//   } else if (Number.isNaN(parseFloat(values.topLeftLatitude))) {
//     errors.topLeftLatitude = 'Invalid latitude';
//   } else if (!GeoHelper.validateLatitude(parseFloat(values.topLeftLatitude))) {
//     errors.topLeftLatitude = 'Latitude outside standard range';
//   }

//   if (!values.bottomRightLongitude) {
//     errors.bottomRightLongitude = 'Required';
//   } else if (Number.isNaN(parseFloat(values.bottomRightLongitude))) {
//     errors.bottomRightLongitude = 'Invalid longitude';
//   } else if (
//     !GeoHelper.validateLongitude(parseFloat(values.bottomRightLongitude))
//   ) {
//     errors.bottomRightLongitude = 'Longitude outside standard range';
//   }

//   if (!values.bottomRightLatitude) {
//     errors.bottomRightLatitude = 'Required';
//   } else if (Number.isNaN(parseFloat(values.bottomRightLatitude))) {
//     errors.bottomRightLatitude = 'Invalid latitude';
//   } else if (
//     !GeoHelper.validateLatitude(parseFloat(values.bottomRightLatitude))
//   ) {
//     errors.bottomRightLatitude = 'Latitude outside standard range';
//   }
//   return errors;
// };

export const validateCoordinatesList = (coordinates: [string, string][]) => {
  const errors: Record<string, string> = {};
  const INVALID_FORMAT = 'Invalid format';

  coordinates.forEach((coordinatePair, index) => {
    const [longitude, latitude] = coordinatePair;
    const isValidLongitude = GeoHelper.validateLongitude(
      DMSToDecimal(longitude)!
    );
    const isValidLatitude = GeoHelper.validateLatitude(DMSToDecimal(latitude)!);

    // maybe not the cleanest but DMSToDecimal can return 0 as a coord (valid)
    // this will be mistaken for 'false'
    if (!isValidLatitude || typeof DMSToDecimal(latitude) !== 'number') {
      errors[`coordinates[${index}][1]`] = INVALID_FORMAT;
    }

    if (!isValidLongitude || typeof DMSToDecimal(longitude) !== 'number') {
      errors[`coordinates[${index}][0]`] = INVALID_FORMAT;
    }
  });

  return errors;
};

export const validateForm = (values: DrawingFormValues) => {
  let errors: any = {};
  const REQUIRED = 'Required';
  const INVALID_FORMAT = 'Invalid Format';
  if (!values.name) errors.name = REQUIRED;
  if (!values.description) errors.description = REQUIRED;

  if (values.shape === DrawingShape.Circle) {
    if (!values.centrePointInputLat) errors.centrePointInputLat = REQUIRED;
    else if (
      !DMSToDecimal(values.centrePointInputLat) ||
      !GeoHelper.validateLatitude(
        Number(DMSToDecimal(values.centrePointInputLat))
      )
    ) {
      errors.centrePointInputLat = INVALID_FORMAT;
    }
    if (!values.centrePointInputLong) errors.centrePointInputLong = REQUIRED;
    else if (
      !DMSToDecimal(values.centrePointInputLong) ||
      !GeoHelper.validateLongitude(
        Number(DMSToDecimal(values.centrePointInputLong))
      )
    ) {
      errors.centrePointInputLong = INVALID_FORMAT;
    }
    if (!values.radiusInNauticalMilesInput)
      errors.radiusInNauticalMilesInput = REQUIRED;
    else if (Number.isNaN(Number(values.radiusInNauticalMilesInput))) {
      errors.radiusInNauticalMilesInput = INVALID_FORMAT;
    }
  }

  if (
    values.shape === DrawingShape.Line ||
    values.shape === DrawingShape.Polygon
  ) {
    const coordinates = values?.coordinates;
    const hasCoordinates = coordinates && coordinates?.length > 0;

    if (!hasCoordinates) {
      errors.coordinates = REQUIRED;
    } else {
      const coordinatesListErrors = validateCoordinatesList(coordinates);
      errors = { ...errors, ...coordinatesListErrors };
    }
  }

  if (values.shape === DrawingShape.Point) {
    const inputLong = values?.inputLong;
    const inputLat = values?.inputLat;

    if (!inputLat || !inputLong) {
      if (!inputLat) {
        errors.inputLat = REQUIRED;
      }

      if (!inputLong) {
        errors.inputLong = REQUIRED;
      }
    } else {
      const coordinate: [string, string][] = [[inputLong, inputLat]];
      const coordinatesListErrors = validateCoordinatesList(coordinate);
      errors = { ...errors, ...coordinatesListErrors };
    }
  }

  return errors;
};

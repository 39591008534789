import { Auth } from '@aws-amplify/auth';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import { NORTH_STANDARD_IDP_NAME, USE_HOSTED_UI } from '../../environment';
import { useAppDispatch } from '../../hooks';
import { RESET_ACTION } from '../../store';

function UserSessionButton({
  type,
  onClick,
}: {
  type: 'login' | 'logout';
  onClick: () => void;
}) {
  return (
    <MenuItem onClick={() => onClick()}>
      <ListItemIcon sx={{ color: 'secondary.main' }}>
        {type === 'login' ? <LoginOutlinedIcon /> : <LogoutOutlinedIcon />}
      </ListItemIcon>
      <ListItemText data-testid="login-logout" sx={{ textWrap: 'wrap' }}>
        {type === 'login'
          ? 'Login (Members, Brokers, and Correspondents)'
          : 'Logout'}
      </ListItemText>
    </MenuItem>
  );
}

export function LoginButton() {
  const signIn = async (
    options: { hostedUi: true } | { hostedUi: false; providerName: string }
  ) => {
    if (options.hostedUi) {
      Auth.federatedSignIn();
    } else {
      Auth.federatedSignIn({
        customProvider: options.providerName,
      });
    }
  };

  // eslint-disable-next-line no-nested-ternary
  return USE_HOSTED_UI ? (
    <UserSessionButton
      type="login"
      onClick={() => signIn({ hostedUi: true })}
    />
  ) : NORTH_STANDARD_IDP_NAME ? (
    <UserSessionButton
      type="login"
      onClick={() =>
        signIn({ hostedUi: false, providerName: NORTH_STANDARD_IDP_NAME! })
      }
    />
  ) : (
    <ErrorPanel message="SSO IDP is not set" />
  );
}

export function LogoutButton() {
  const dispatch = useAppDispatch();

  const signOut = async () => {
    await Auth.signOut();
    dispatch(RESET_ACTION);
  };

  return <UserSessionButton type="logout" onClick={() => signOut()} />;
}

import { Box } from '@mui/material';
import classNames from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonCloseButton from '../../common-components/common-close-button/common-close-button';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import { deselectAll } from '../../utils/risk-intelligence-helpers';
import {
  EMenuItems,
  setSelectedOption,
  toggleSecondaryMenu,
} from '../menu.slice';
import './secondary-pane.scss';

interface ISecondaryPaneProps {
  component: JSX.Element;
  // eslint-disable-next-line react/require-default-props
  name?: EMenuItems;
  // eslint-disable-next-line react/require-default-props
  isSpecific?: boolean;
}

const mountedStyle = {
  animation: 'inAnimation 270ms ease-out',
};
const unmountedStyle = {
  animation: 'outAnimation 270ms ease-out',
  animationFillMode: 'forwards',
};

function SecondaryPane(props: ISecondaryPaneProps) {
  const [isMounted, setIsMounted] = useState(true);
  const tickerOpen = useAppSelector(
    (state) => state.userPreferences.userPreferences.showRiNewsBanner
  );

  const { selectedRoute } = useAppSelector((state) => state.routes);
  const { selectedDossier, secondaryMenuOpen } = useAppSelector(
    (state) => state.menu
  );

  const isMobile = useMobile();
  const navigate = useNavigate();

  const { component, name, isSpecific } = props;
  const dispatch = useAppDispatch();

  return (
    <Box
      data-testid="secondary-pane-container"
      className={classNames('secondary-pane-container', {
        mobile: isMobile,
      })}
      sx={{
        bgcolor: 'primary.main',
        width: name === EMenuItems.PRIVACY_POLICY ? '45rem' : '25vh',
        display: 'flex',
        flexDirection: 'column',
        // eslint-disable-next-line no-nested-ternary
        height: isMobile
          ? tickerOpen
            ? 'calc(var(--bottomdraw-height) - var(--news-ticker-height))'
            : 'var(--bottomdraw-height)'
          : tickerOpen
          ? 'calc(100% - var(--news-ticker-height))'
          : '100%',
      }}
      style={isMounted ? mountedStyle : unmountedStyle}
      onAnimationEnd={() => {
        if (!isMounted) dispatch(toggleSecondaryMenu(false));
      }}
    >
      <Box className="secondary-pane-button-container">
        <CommonCloseButton
          onClick={() => {
            if (
              (secondaryMenuOpen && !selectedRoute) ||
              (!secondaryMenuOpen && selectedRoute)
            ) {
              dispatch(setSelectedOption(EMenuItems.NOTHING_SELECTED));
            }
            setIsMounted(false);
            if (isSpecific) {
              deselectAll(dispatch);
              navigate('');
              dispatch(toggleSecondaryMenu(false));
            } else if (!selectedDossier) {
              navigate('');
            }
          }}
          testId="close-secondary-pane-button"
        />
      </Box>
      <Box
        sx={{
          display: 'contents',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {component}
      </Box>
    </Box>
  );
}

export default SecondaryPane;

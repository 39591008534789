import {
  Box,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useAppDispatch } from '../../../hooks';
import {
  EMenuItems,
  setSelectedOption,
  setThreatAssessmentOpen,
} from '../../../main-menu/menu.slice';
import { setIncidentFilters } from '../../../maritime-menu-options/incidents-panel/incident-panel.slice';
import { ExpandedCountry } from '../../../models/risk_intelligence.model';
import { addHistoryItem } from '../../../nav-history.slice';
import { removeLineBreaksAndTrim } from '../../../utils/text-formatting.utils';
import { setSelectedEntityThreatAssessmentInfo } from '../../threat-assessments/threat-assessment.slice';

import RiFurtherInformationFooter from '../../../common-components/ri-further-information-footer/ri-further-infrormation-footer';
import './ri-country-tab.scss';

/**
 * Make a table.
 * @param country Risk intelligence Country data
 * @param dispatch
 * @returns <Table/>
 */
const makeRITable = (country: ExpandedCountry) => {
  const tableCellStyle = {
    display: 'flex',
    justifyContent: 'flex-end !important',
  };

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>UN/LOCODE:</TableCell>
          <TableCell sx={tableCellStyle}>{country.code}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Region:</TableCell>
          <TableCell
            sx={{
              display: 'flex',
              justifyContent: 'flex-end !important',
            }}
          >
            {country.region.title}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Disease information:</TableCell>
          <TableCell
            sx={{
              display: 'flex',
              justifyContent: 'flex-end !important',
            }}
          >
            <a
              href={country.deceasesVaccines}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'var(--primary-hover-colour)' }}
            >
              View Disease Info
            </a>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

interface CountryTabProps {
  country: ExpandedCountry | 'N/A';
}

export default function RICountryTab({ country }: CountryTabProps) {
  const dispatch = useAppDispatch();

  if (!country || country === 'N/A') {
    return (
      <div className="ri-country-panel">
        <p className="country-summary">No RI Information for this country.</p>
      </div>
    );
  }
  const table = makeRITable(country);
  const trimmedHeadline = removeLineBreaksAndTrim(country.headline);

  const openThreatAssessment = () => {
    dispatch(
      setSelectedEntityThreatAssessmentInfo({
        id: country.threatAssessment,
        title: country.title,
      })
    );
    dispatch(addHistoryItem({ type: 'country', properties: country }));
    dispatch(setThreatAssessmentOpen(true));
  };

  const buttonStyle = {
    borderRadius: '0',
    borderColor: 'secondary.main',
    borderBottom: '1px solid',
    borderBottomColor: 'primary.light',
    color: 'text.secondary',
    bgColor: 'primary.main',
    width: '100%',
    '&hover': {
      bgColor: 'primary.light',
    },
  };

  return (
    <Box sx={{ marginTop: '1em' }} className="ri-country-panel">
      {table}

      {trimmedHeadline && (
        <Typography
          align="left"
          variant="body2"
          sx={{
            padding: '1em 1.25em',
            fontWeight: '700',
            bgcolor: 'primary.light',
          }}
        >
          {trimmedHeadline}
        </Typography>
      )}

      {country.threatAssessment && (
        <ListItemButton onClick={openThreatAssessment} sx={buttonStyle}>
          View Threat Assessment
        </ListItemButton>
      )}

      <ListItemButton
        sx={{
          ...buttonStyle,
          borderBottom: 'none',
        }}
        onClick={() => {
          dispatch(addHistoryItem({ type: 'country', properties: country }));
          dispatch(setSelectedOption(EMenuItems.INCIDENTS));
          dispatch(
            setIncidentFilters({
              regions: [country.region.id],
            })
          );
        }}
        data-testid="region-incidents-button"
      >
        View Region Incidents
      </ListItemButton>
      <RiFurtherInformationFooter />
    </Box>
  );
}

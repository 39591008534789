/* eslint-disable import/prefer-default-export */
import { MOCK_CORRESPONDENTS_RESPONSE } from '../../mocks/stubs/correspondents.mock';
import { CorrespondentResponse } from '../../models/correspondents.model';
import { wrapRequest } from '../base';

export const fetchNSCorrespondents = async (): Promise<CorrespondentResponse> =>
  wrapRequest('get', 'north_standard', '/north-standard/correspondents');

export const fetchNSCorrespondentsStub =
  async (): Promise<CorrespondentResponse> => MOCK_CORRESPONDENTS_RESPONSE;

import { Box, Link, Typography, useTheme } from '@mui/material';
import { IndustryNews } from '../../../models/industry-news.model';

interface Props {
  news: IndustryNews;
}

function SpecificIndustryNewsItem({ news }: Props) {
  const theme = useTheme();
  const readableDateTime = new Date(news.date).toLocaleDateString('en-GB');

  return (
    <Box
      data-testid="specificIndustryNewsPanel"
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
        paddingTop: '1.5rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        paddingBottom: '1rem',
      }}
    >
      <Typography
        textAlign="left"
        sx={{ paddingRight: '3rem', paddingBottom: '1rem' }}
      >
        <Link
          href={news.url}
          target="_blank"
          rel="noopener noreferrer"
          underline="always"
          sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}
        >
          {news.title}
        </Link>
      </Typography>
      <Typography
        textAlign="left"
        sx={{ paddingRight: '3rem', paddingBottom: '1rem' }}
      >
        {readableDateTime}
      </Typography>
      <Typography
        data-testid="industry-news-article-link"
        variant="subtitle2"
        textAlign="left"
      >
        More information can be found on the article{' '}
        <Link
          href={news.url}
          target="_blank"
          rel="noopener noreferrer"
          underline="always"
          sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}
        >
          here
        </Link>
      </Typography>
      <Typography
        data-testid="industry-news-ns-link"
        variant="subtitle2"
        textAlign="left"
      >
        This layer only includes news articles published or updated in the last
        two years. All our news articles can be found{' '}
        <Link
          href="https://north-standard.com/insights-and-resources/resources/news/"
          target="_blank"
          rel="noopener noreferrer"
          underline="always"
          sx={{ fontWeight: 'bold', color: theme.palette.secondary.main }}
        >
          here
        </Link>
      </Typography>
    </Box>
  );
}

export default SpecificIndustryNewsItem;

import { set } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import MapLayerManager from '../../map/map-layer-manager/map-layer-manager.utils';
import MapHelpers from '../../map/map.utils';

function someLayersVisible(mapInitialised: boolean, layerIds: string[]) {
  if (!mapInitialised) {
    return false;
  }

  return layerIds.some(
    (source) => MapHelpers.getLayer(source) && MapHelpers.isLayerVisible(source)
  );
}

function generateVisibilityState(childLayerIds: string[]) {
  const initialVisibility: Record<string, boolean> = {};
  childLayerIds.forEach((id) => {
    if (MapHelpers.getLayer(id)) {
      initialVisibility[id] = MapHelpers.isLayerVisible(id)!;
    }
  });
  return initialVisibility;
}

export function useChildLayerVisibility(
  sourceGroups: string[],
  mapInitialised: boolean
) {
  // store the child layer ids in state for the source groups
  const [childLayerIds, setChildLayerIds] = useState<string[]>(() =>
    MapLayerManager.getLayerIdsFromSourceGroups(sourceGroups ?? [])
  );

  const map = MapHelpers.getMapInstance();

  const [hasVisibleChildLayer, setHasVisibleChildLayer] = useState<boolean>(
    () => someLayersVisible(mapInitialised, childLayerIds)
  );

  // store the child layer visibility history in state for the source groups
  const [childLayerVisibilityHistory, setChildLayerVisibilityHistory] =
    useState<Record<string, boolean>>(() =>
      generateVisibilityState(childLayerIds)
    );

  useEffect(() => {
    if (!map || !mapInitialised) {
      return () => {};
    }

    // update the child layer ids when the source groups change
    setChildLayerIds(MapLayerManager.getLayerIdsFromSourceGroups(sourceGroups));

    function onLayerGroupChange() {
      const newChildLayerIds =
        MapLayerManager.getLayerIdsFromSourceGroups(sourceGroups);

      setChildLayerVisibilityHistory(generateVisibilityState(newChildLayerIds));
      setChildLayerIds(newChildLayerIds);
      setHasVisibleChildLayer(
        someLayersVisible(mapInitialised, newChildLayerIds)
      );
    }

    // add listeners for source group layer changes
    sourceGroups?.forEach((group) => {
      MapLayerManager.onLayerGroupChange(group, onLayerGroupChange);
    });

    return () => {
      // add listeners for source group layer changes
      sourceGroups?.forEach((group) => {
        MapLayerManager.removeLayerGroupChange(group, onLayerGroupChange);
      });
    };
  }, [sourceGroups, mapInitialised]);

  useEffect(() => {
    if (!map || !mapInitialised) {
      return () => {};
    }

    function onLayerVisibilityChange() {
      const someChildLayersVisible = someLayersVisible(
        mapInitialised,
        childLayerIds
      );

      setChildLayerVisibilityHistory((prevVisibility) => {
        const newVisibility = {
          ...(someChildLayersVisible ? prevVisibility : {}),
        };
        childLayerIds.forEach((source) => {
          if (MapHelpers.getLayer(source)) {
            set(newVisibility, source, MapHelpers.isLayerVisible(source));
          }
        });
        return newVisibility;
      });

      setHasVisibleChildLayer(someChildLayersVisible);
    }

    childLayerIds.forEach((source) => {
      if (MapHelpers.getLayer(source)) {
        MapHelpers.onLayerVisibilityChange(source, onLayerVisibilityChange);
      }
    });

    return () => {
      childLayerIds.forEach((source) => {
        if (MapHelpers.getLayer(source)) {
          MapHelpers.removeLayerVisibilityEvent(
            source,
            onLayerVisibilityChange
          );
        }
      });
    };
  }, [childLayerIds]);

  return {
    childLayerIds,
    childLayerVisibilityHistory,
    setChildLayerVisibilityHistory,
    hasVisibleChildLayer,
  };
}

export function useLayerVisibility(
  layerIds: string[],
  mapInitialised: boolean
) {
  const [hasVisibleLayer, setHasVisibleLayer] = useState<boolean>(() =>
    someLayersVisible(mapInitialised, layerIds)
  );

  useEffect(() => {
    if (!MapHelpers.getMapInstance() || !mapInitialised) {
      return () => {};
    }

    layerIds.forEach((source) => {
      if (MapHelpers.getLayer(source)) {
        MapHelpers.onLayerVisibilityChange(source, () => {
          setHasVisibleLayer(someLayersVisible(mapInitialised, layerIds));
        });
      }
    });

    return () => {
      layerIds.forEach((source) => {
        if (MapHelpers.getLayer(source)) {
          MapHelpers.removeLayerVisibilityEvent(source, () => {});
        }
      });
    };
  }, [mapInitialised]);

  return {
    hasVisibleLayer,
  };
}

export function useIsFirstRender(): boolean {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

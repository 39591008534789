/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ListPicker from '../../../../common-components/list-picker/list-picker';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  calcDisplayedText,
  listFilterItems,
} from '../../../../utils/incidents-helpers';
import { setIncidentFilters } from '../../incident-panel.slice';
import { AlertDisplayFilter } from '../../incident.model';

function AlertsFilter() {
  const { filters } = useAppSelector((store) => store.incidentsPanel);
  const dispatch = useAppDispatch();

  const [selectedAlerts, setSelectedAlerts] = React.useState(
    () => filters.alerts
  );

  const onSubmit = () => {
    dispatch(
      setIncidentFilters({
        alerts: selectedAlerts,
      })
    );
  };

  return (
    <ListPicker
      onSubmit={onSubmit}
      items={listFilterItems(AlertDisplayFilter)}
      selectedItems={selectedAlerts}
      setSelectedItems={setSelectedAlerts}
      buttonText={calcDisplayedText(
        filters.alerts,
        'Alerts',
        AlertDisplayFilter
      )}
    />
  );
}
export default AlertsFilter;

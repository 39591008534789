import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

function UserSessionButton({ onClick }: { onClick: () => void }) {
  return (
    <MenuItem onClick={() => onClick()}>
      <ListItemIcon sx={{ color: 'secondary.main' }}>
        <ArrowBackIcon />
      </ListItemIcon>
      <ListItemText>NorthStandard</ListItemText>
    </MenuItem>
  );
}
function ReturnToNorthStandardButton() {
  const returnToNorthStandard = async () => {
    window.location.href = 'https://north-standard.com/';
  };

  return <UserSessionButton onClick={() => returnToNorthStandard()} />;
}

export default ReturnToNorthStandardButton;

import { Stack } from '@mui/material';
import { useAppSelector } from '../../../hooks';
import PortsThreatTypeFilter from './port-threat-type-filter';
import PortsTypeFilter from './port-type-filter';
import PortRiskAssessmentLevelFilter from './ports-risk-assessment-level-filter';

function PortsPanelFilterBlock() {
  const { open } = useAppSelector((state) => state.portsPanel.filters);

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      data-testid="filter-block"
      aria-expanded={open}
    >
      <PortRiskAssessmentLevelFilter />
      <PortsTypeFilter />
      <PortsThreatTypeFilter />
    </Stack>
  );
}

export default PortsPanelFilterBlock;

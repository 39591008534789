import { faImage, faRemove } from '@fortawesome/free-solid-svg-icons';
import './attachment-list-item.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface AttachmentListItemProps {
  fileName: string;
  removeAttachment: () => void;
}

function AttachmentListItem(props: AttachmentListItemProps) {
  const { fileName, removeAttachment } = props;

  return (
    <div className="attachment-list-item">
      <div className="attachment-list-item-icon">
        <FontAwesomeIcon className="fa-1x" icon={faImage} />
      </div>
      <div className="attachment-list-item-file-name">{fileName}</div>
      <div className="attachment-list-item-remove-item">
        <FontAwesomeIcon
          className="fa-1x"
          icon={faRemove}
          onClick={removeAttachment}
        />
      </div>
    </div>
  );
}

export default AttachmentListItem;

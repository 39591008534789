import setIncidentsLayer from '../incident-utils/incident-layer';
import MapLayer from '../map-layer.enum';

function addRoutesIncidentsLayer(map: mapboxgl.Map, metadata?: {}) {
  if (!map.getLayer(MapLayer.ROUTES_INCIDENTS)) {
    setIncidentsLayer(MapLayer.ROUTES_INCIDENTS, metadata);
  }
}

export default addRoutesIncidentsLayer;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React from 'react';
import LayerToggle from '../../common-components/layer-toggle/layer-toggle';
import { useAppDispatch } from '../../hooks';
import MapHelpers from '../../map/map.utils';
import { Boundary } from '../../models/boundary.model';
import { setSelectedBoundary } from '../../state/boundaries/boundaries.slice';
import BoundariesController from './boundaries-controller.utils';

function BoundaryResultCard(props: Boundary) {
  const { boundary_source_layer: layerId, name, source } = props;

  const [isInMap, setIsInMap] = React.useState(
    () => !!MapHelpers.getSource(layerId)
  );

  const dispatch = useAppDispatch();

  const handleBoundaryClick = () => {
    dispatch(setSelectedBoundary(props as Boundary));
  };

  return (
    <ListItem
      dense
      disableGutters
      secondaryAction={
        isInMap ? (
          <LayerToggle
            layers={[layerId, `${layerId}_line`, `${layerId}_fill`]}
            layerGroups={[]}
          />
        ) : (
          <IconButton
            onClick={() => {
              BoundariesController.createBoundaryLayer(props, {
                createVisible: true,
              });
              setIsInMap(true);
            }}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        )
      }
    >
      <ListItemButton onClick={() => handleBoundaryClick()} disabled={!isInMap}>
        <ListItemText primary={name} secondary={`Source: ${source}`} />
      </ListItemButton>
    </ListItem>
  );
}

export default React.memo(BoundaryResultCard);

import store from '../../../store';
import MapHelpers from '../../map.utils';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

function setMaritimeAreasLayer(layerId: MapLayer | string, metadata?: {}) {
  const baseMapType = store.getState().userPreferences.userPreferences.basemap;
  const fillColour = baseMapType === 'Default' ? '#7b91c7' : '#dbeaff';

  return new Promise((resolve) => {
    addSimpleGeoJsonSource(layerId);
    MapHelpers.addLayer({
      id: layerId,
      type: 'fill',
      source: layerId,
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
      },
      paint: {
        'fill-color': fillColour,
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.6,
          0.35,
        ],
      },
      metadata,
    });
    resolve(MapHelpers);
  });
}

export default setMaritimeAreasLayer;

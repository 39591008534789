/* eslint-disable import/prefer-default-export */
import { MOCK_FIRST_CALL_RESPONSE } from '../../mocks/stubs/first-call';
import { FirstCallResponse } from '../../models/first-call';
import { wrapRequest } from '../base';

export const fetchFirstCallPorts = async (): Promise<FirstCallResponse> =>
  wrapRequest('get', 'north_standard', '/first-call');

export const fetchFirstCallPortsStub = async (): Promise<FirstCallResponse> =>
  MOCK_FIRST_CALL_RESPONSE;

import {
  ExpandedId,
  MaybeExpandedId,
  Position,
  RIContacts,
  RIImage,
  RISecurityEmergencyUnits,
} from '../../models/risk_intelligence.model';

type BlankableBool = 'Y' | 'N' | 'U';

enum ReverseBlankable {
  Y = 'Yes',
  N = 'No',
  U = 'Unknown',
}

type Repairs = 'U' | 'N' | 'E' | 'L' | 'Mo' | 'Ma';

enum ReverseRepairs {
  U = 'Unknown',
  N = 'None',
  E = 'Emergency Only',
  L = 'Limited',
  Mo = 'Moderate',
  Ma = 'Major',
}

enum PortColumnNames {
  WPI = 'World Port Index Number',
  NAME = 'Main Port Name',
  ANAME = 'Alternate Port Name',
  UNLOCODE = 'UN/LOCODE',
  COUNTRY = 'Country',
  SNC = 'Standard Nautical Chart',
  DNC = 'Digital Nautical Chart',
  TR = 'Tidal Range (m)',
  EW = 'Entrance Width (m)',
  CD = 'Channel Depth (m)',
  AD = 'Anchorage Depth (m)',
  CPD = 'Cargo Pier Depth (m)',
  OTD = 'Oil Terminal Depth (m)',
  GTD = 'Liquid Gas Terminal Depth (m)',
  MVL = 'Maximum Vessel Length (m)',
  MVB = 'Maximum Vessel Beam (m)',
  MVD = 'Maximum Vessel Draft (m)',
  OMVL = 'Offshore Maximum Vessel Length (m)',
  OMVB = 'Offshore Maximum Vessel Beam (m)',
  OMVD = 'Offshore Maximum Vessel Draft (m)',
  HS = 'Harbor Size',
  HT = 'Harbor Type',
  HU = 'Harbor Use',
  SH = 'Shelter Afforded',
  ERT = 'Entry Restriction - Tide',
  ERH = 'Entry Restriction - Heavy Swell',
  ERI = 'Entry Restriction - Ice',
  ERO = 'Entry Restriction - Other',
  OHL = 'Overhead Limitations',
  UCMS = 'Underkeel Clearance Management System',
  GHG = 'Good Holding Ground',
  TA = 'Turning Area',
  PS = 'Port Security',
  ETA = 'Estimated Time of Arrival Message',
  QP = 'Quarantine - Pratique',
  QS = 'Quarantine - Sanitation',
  QO = 'Quarantine - Other',
  TSS = 'Traffic Separation Scheme',
  VTS = 'Vessel Traffic Service',
  FPE = 'First Port of Entry',
  USR = 'U.S. Represenative',
  PC = 'Pilotage - Compulsory',
  PAV = 'Pilotage - Available',
  PL = 'Pilotage - Local Assistance',
  PAD = 'Pilotage - Advisable',
  TS = 'Tugs - Salvage',
  TAS = 'Tugs - Assistance',
  CT = 'Communications - Telephone',
  CTX = 'Communications - Telefax',
  CR = 'Communications - Radio',
  CRT = 'Communications - Radio Telephone',
  CA = 'Communications - Airport',
  CRA = 'Communications - Rail',
  SAR = 'Search and Rescue',
  NAV = 'NAVAREA',
  FW = 'Facilities - Wharves',
  FA = 'Facilities - Anchorage',
  FD = 'Facilities - Dangerous Cargo Anchorage',
  FM = 'Facilities - Med Mooring',
  FB = 'Facilities - Beach Mooring',
  FI = 'Facilities - Ice Mooring',
  FRR = 'Facilities - Roll-on/Roll-off',
  FSB = 'Facilities - Solid Bulk',
  FLB = 'Facilities - Liquid Bulk',
  FC = 'Facilities - Container',
  FBB = 'Facilities - Break Bulk',
  FO = 'Facilities - Oil Terminal',
  FLNG = 'Facilities - Liquid Gas Terminal',
  FOT = 'Facilities - Other',
  MED = 'Medical Facilities',
  GARB = 'Garbage Disposal',
  CHEM = 'Chemical Holding Tank Disposal',
  DG = 'Degaussing',
  DB = 'Dirty Ballast Disposal',
  CFI = 'Cranes - Fixed',
  CM = 'Cranes - Mobile',
  CFL = 'Cranes - Floating',
  CC = 'Cranes - Container',
  C100 = 'Cranes - Lift 100+ Tons',
  C50 = 'Cranes - Lift 50-100 Tons',
  C25 = 'Cranes - Lift 25-50 Tons',
  C0 = 'Cranes - Lift 0-25 Tons',
  SL = 'Services - Longshoremen',
  SE = 'Services - Electrical',
  SS = 'Services - Steam',
  SN = 'Services - Navigation Equipment',
  SER = 'Services - Electrical Repair',
  SI = 'Services - Ice Breaking',
  SD = 'Services - Diving',
  SP = 'Services - Provisions',
  SW = 'Services - Potable Water',
  SFO = 'Services - Fuel Oil',
  SDG = 'Services - Diesel',
  SAF = 'Services - Aviation Fuel',
  SDS = 'Services - Deck Supplies',
  SENG = 'Services - Engine Supplies',
  R = 'Repairs',
  DD = 'Drydock',
  RW = 'Railway',
  LAT = 'Latitude',
  LONG = 'Longitude',
}

type HarbourType =
  | 'RN'
  | 'RB'
  | 'RT'
  | 'OR'
  | 'CN'
  | 'CB'
  | 'CT'
  | 'CL'
  | 'TH'
  | 'UN';

enum ReverseHarbourType {
  RN = 'River (Natural)',
  RB = 'River (Basins)',
  RT = 'River (Tide Gates)',
  OR = 'Open Roadstead',
  CN = 'Coastal (Natural)',
  CB = 'Coastal (Breakwater)',
  CT = 'Coastal (Tide Gates)',
  CL = 'Canal or Lake',
  TH = 'Typhoon Harbor',
  UN = 'Unknown',
}

type Size = 'V' | 'S' | 'M' | 'L' | 'U';
enum ReverseSize {
  V = 'Very Small',
  S = 'Small',
  M = 'Medium',
  L = 'Large',
  U = 'Unknown',
}

export interface MinimalPort {
  NAME: string;
  WPI: string;
  UNLOCODE: string;
  LAT: string;
  LONG: string;
  COUNTRY: string;
}

// Make an interface with keys equal to ColumnNames
export interface WPIPort extends MinimalPort {
  ANAME: string;
  SNC: string;
  DNC: string;
  NAV: string;
  TR: string;
  EW: string;
  CD: string;
  AD: string;
  CPD: string;
  OTD: string;
  GTD: string;
  MVL: string;
  MVB: string;
  MVD: string;
  OMVL: string;
  OMVB: string;
  OMVD: string;
  HS: Size;
  HT: HarbourType;
  HU: string;
  SH: string;
  ERT: BlankableBool;
  ERH: BlankableBool;
  ERI: BlankableBool;
  ERO: BlankableBool;
  OHL: BlankableBool;
  UCMS: BlankableBool;
  GHG: BlankableBool;
  TA: BlankableBool;
  PS: BlankableBool;
  ETA: BlankableBool;
  QP: BlankableBool;
  QS: BlankableBool;
  QO: BlankableBool;
  TSS: BlankableBool;
  VTS: BlankableBool;
  FPE: BlankableBool;
  USR: BlankableBool;
  PC: BlankableBool;
  PAV: BlankableBool;
  PL: BlankableBool;
  PAD: BlankableBool;
  TS: BlankableBool;
  TAS: BlankableBool;
  CT: BlankableBool;
  CTX: BlankableBool;
  CR: BlankableBool;
  CRT: BlankableBool;
  CA: BlankableBool;
  CRA: BlankableBool;
  SAR: BlankableBool;
  FW: BlankableBool;
  FA: BlankableBool;
  FD: BlankableBool;
  FM: BlankableBool;
  FB: BlankableBool;
  FI: BlankableBool;
  FRR: BlankableBool;
  FSB: BlankableBool;
  FLB: BlankableBool;
  FC: BlankableBool;
  FBB: BlankableBool;
  FO: BlankableBool;
  FLNG: BlankableBool;
  FOT: BlankableBool;
  MED: BlankableBool;
  GARB: BlankableBool;
  CHEM: BlankableBool;
  DG: BlankableBool;
  DB: BlankableBool;
  CFI: BlankableBool;
  CM: BlankableBool;
  CFL: BlankableBool;
  CC: BlankableBool;
  C100: BlankableBool;
  C50: BlankableBool;
  C25: BlankableBool;
  C0: BlankableBool;
  SL: BlankableBool;
  SE: BlankableBool;
  SS: BlankableBool;
  SN: BlankableBool;
  SER: BlankableBool;
  SI: BlankableBool;
  SD: BlankableBool;
  SP: BlankableBool;
  SW: BlankableBool;
  SFO: BlankableBool;
  SDG: BlankableBool;
  SAF: BlankableBool;
  SDS: BlankableBool;
  SENG: BlankableBool;
  R: Repairs;
  DD: Size;
  RW: Size;
}

// selected added only when port is interacted with in the UI
export interface Port extends MinimalPort {
  selected?: boolean;
  ri?: RIPort | ExpandedRIPort;
}

export interface RIPort extends ExpandedId {
  unlocode: string;
  region: MaybeExpandedId;
  country: MaybeExpandedId;
  position: Position;
  wpi: number;
  inland?: boolean;
  cruise?: boolean;
  pal?: string;
  contacts?: RIContacts[];
  securityEmergencyUnits?: RISecurityEmergencyUnits[];
}

export interface ExpandedRIPort extends RIPort {
  headline: string | null;
  region: ExpandedId;
  country: ExpandedId;
  pal: string;
  isps: string;
  images: RIImage[];
  website: string;
  timeZone: string;
  threatAssessment: number;
}

export const isExpandedPort = (port: RIPort): port is ExpandedRIPort =>
  !!(port as ExpandedRIPort).pal;

export const getColumnNameFromShortCode = (name: keyof WPIPort) =>
  PortColumnNames[name];
const getSizeString = (size: Size) => ReverseSize[size];
const getBoolString = (bool: BlankableBool) => ReverseBlankable[bool];
const getHarbourTypeString = (harbourType: HarbourType) =>
  ReverseHarbourType[harbourType];
const getRepairsString = (repairs: Repairs) => ReverseRepairs[repairs];

const plainStringKeys = [
  'WPI',
  'NAME',
  'ANAME',
  'UNLOCODE',
  'COUNTRY',
  'LAT',
  'LONG',
  'SNC',
  'DNC',
  'NAV',
  'TR',
  'EW',
  'CD',
  'AD',
  'CPD',
  'OTD',
  'GTD',
  'MVL',
  'MVB',
  'MVD',
  'OMVL',
  'OMVB',
  'OMVD',
  'HU',
  'SH',
  'SNC',
  'DNC',
];

export const getValueFromShortCode = (name: keyof WPIPort, value: string) => {
  if (name === 'HS' || name === 'DD' || name === 'RW') {
    return getSizeString(value as Size);
  }
  if (name === 'R') {
    return getRepairsString(value as Repairs);
  }
  if (name === 'HT') {
    return getHarbourTypeString(value as HarbourType);
  }
  if (plainStringKeys.includes(name)) {
    if (!value) {
      return 'Unknown';
    }
    return value;
  }
  // All that remains are boolean values
  return getBoolString(value as BlankableBool);
};

export const portKey = (port: Port) => {
  if (port.ri) {
    return `${port.WPI}-${port.ri.id}`;
  }
  return `${port.WPI}`;
};

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  RIContacts,
  RISecurityEmergencyUnits,
} from '../../models/risk_intelligence.model';

export const EMERGENCY = 'Emergency';
export const CONTACTS = 'Contacts';

export interface Details {
  title: typeof CONTACTS | typeof EMERGENCY;
  name: string;
  items: RIContacts[] | RISecurityEmergencyUnits[] | null;
}

export type PortTypeFilter = {
  cruise: boolean;
  inland: boolean;
  coastal: boolean;
  wpi: boolean;
};

export type ThreatType = {
  id: number;
  title: string;
  value: number;
};

export type PortsFilters = {
  portType: PortTypeFilter;
  search: string;
  open: boolean;
  riskLevel: { [key: string]: boolean };
  threatType: ThreatType[];
};

export const DEFAULT_PORT_FILTERS: PortsFilters = {
  portType: {
    cruise: false,
    inland: false,
    coastal: false,
    wpi: false,
  },
  search: '',
  open: false,
  riskLevel: { 'Level 1': false, 'Level 2': false, 'Level 3': false },
  threatType: [],
};

interface PortsPanelState {
  filters: PortsFilters;
}

export const INITIAL_PORTS_PANEL_STATE: PortsPanelState = {
  filters: DEFAULT_PORT_FILTERS,
};

const PortsPanelSlice = createSlice({
  name: 'portsPanel',
  initialState: INITIAL_PORTS_PANEL_STATE,
  reducers: {
    setPortsFilters: (
      state,
      action: PayloadAction<Partial<PortsFilters> | null>
    ) => {
      // Only updates filters explictly mentioned in action
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function

export const { setPortsFilters } = PortsPanelSlice.actions;

export default PortsPanelSlice.reducer;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { TextField } from '@mui/material';
import './number-input.scss';

interface NumberInputBasicProps {
  testId?: string;
  defaultValue: number | undefined;
  placeHolder: string;
  onChange: (
    event: any,
    metaData?: {
      [key: string]: any;
    }
  ) => void;
  metaData?: {
    [key: string]: any;
  };
}

export default function NumberInputBasic(props: NumberInputBasicProps) {
  const { testId, defaultValue, metaData, onChange, placeHolder } = props;
  return (
    <TextField
      data-testid={testId}
      aria-label="wait time input"
      placeholder={placeHolder}
      defaultValue={defaultValue}
      onKeyUp={(e) => onChange(e, metaData)}
      variant="standard"
      type="number"
      InputProps={{
        inputProps: {
          min: 0,
        },
      }}
      sx={{
        width: '100px',
      }}
    />
  );
}

NumberInputBasic.defaultProps = {
  metaData: {},
  testId: '',
};

enum MapLayer {
  // ################# Ship Layers #################

  /** Layer for displaying vessel box outline on the map */
  VESSEL_FOCUS_RING = 'vessel-focus-ring',

  /** Layer for displaying AIS Journey Tracks */
  VESSEL_JOURNEYS = 'vessel-journeys',

  /** Layer for displaying AIS position points */
  VESSEL_AIS_POSITIONS = 'vessel-ais-positions',

  /** Layer for displaying selected AIS Positions */
  SELECTED_AIS_POSITION = 'selected-ais-position',

  // ################### Map Reference Layers ###################
  /** Open Sea Map Layer */
  OSM_SEA = 'open-sea-map',

  /** Bathymetry layer */
  BATHYMETRY = '10m-bathymetry-81bsvj',

  // ################### Boundaries Layers ###################
  BOUNDARIES_EEZ = 'eez_combined',
  BOUNDARIES_JWC = 'jwc_combined',
  BOUNDARIES_TTW = 'ttw_combined',

  // ################### Incidents Layers ###################
  INCIDENTS = 'incidents',
  INCIDENT_CLUSTERS = 'incident-clusters',
  INCIDENT_CLUSTER_COUNT = 'incident-cluster-count',

  // ################### Incidents Datum Rings Layer ###################
  DATUM_RINGS = 'datum-rings',

  // ################### Ports Layers ###################
  PORTS = 'ports',
  PORT_CLUSTERS = 'port-clusters',
  PORT_CLUSTER_COUNT = 'port-cluster-count',
  FIRST_CALL_PORTS = 'first-call-ports',
  FIRST_CALL_CLUSTERS = 'first-call-clusters',
  FIRST_CALL_CLUSTER_COUNT = 'first-call-cluster-count',
  CORRESPONDENTS = 'correspondents',
  CORRESPONDENT_CLUSTERS = 'correspondent-clusters',
  CORRESPONDENT_CLUSTER_COUNT = 'correspondent-cluster-count',

  // ################### Industry News Layers ###################
  INDUSTRY_NEWS = 'industry-news',
  INDUSTRY_NEWS_CLUSTERS = 'industry-news-clusters',
  INDUSTRY_NEWS_CLUSTER_COUNT = 'industry-news-cluster-count',

  // ################### Regions Layers ###################
  RI_MARITIME_AREAS = 'ri-maritime-areas',

  // ################### Routes Layers ###################
  ROUTES_MARITIME_AREAS = 'routes-maritime-areas',

  ROUTES_INCIDENTS = 'routes-incidents',
  ROUTES_INCIDENT_CLUSTERS = 'routes-incident-clusters',
  ROUTES_INCIDENT_CLUSTER_COUNT = 'routes-incident-cluster-count',

  ROUTES_PORTS = 'routes-ports',
  ROUTES_PORT_CLUSTERS = 'routes-port-clusters',
  ROUTES_PORT_CLUSTER_COUNT = 'routes-port-cluster-count',

  // ################### Drawings Layers ###################
  DRAW_MEASUREMENT_TEXT = 'measurement-text',

  // ################### Tools Layers ###################
  NEARBY_VESSELS_AREA = 'nearby-vessels-area',
  NEARBY_VESSELS = 'nearby-vessels',

  // layer generated from mapbox style
  // layer used for displaying countries of the world
  LAND_STRUCTURE_POLYGON = 'land-structure-polygon',

  HISTORY = 'HISTORY',

  MEASUREMENT_LINES = 'measurement-lines',
  MEASUREMENT_LINES_TEMP = 'measurement-lines-temp',
  MEASUREMENT_POINTS = 'measurement-points',
  MEASUREMENT_POINTS_TEMP = 'measurement-points-temp',

  WEATHER_PIN = 'weather-pin',
  WEATHER_VISIBILITY_RING = 'weather-visibility-ring',
  WEATHER_VISIBILITY_LABEL = 'weather-visibility-label',

  SHIPPING_LANE_OUTLINE = 'shipping-lane-outline',

  // note: tile layers are created with this prefix, this is not a layer in and of itself
  SHIPPING_LANE_TILE = 'shipping-lane-tile',
}

export enum MapGroupLayer {
  BOUNDARIES_GROUP = 'boundaries_group_layer',
  DRAWINGS = 'drawings_group_layer',
  ROUTES = 'routes_group_layer',
  HISTORY = 'history_group_layer',
  VESSEL_HISTORY_AIS_POSITIONS = 'vessel-history-ais-positions',
  VESSEL_HISTORY_SELECTED_AIS_POSITION = 'vessel-history-selected-ais-position',
  VESSEL_HISTORY_JOURNEYS = 'vessel-history-journeys',
  VESSEL_HISTORY_HEATMAPS = 'vessel-history-heatmaps',
  VESSEL_HISTORY_VESSELS = 'vessel-history-vessels',
  VESSEL_HISTORY_AIS_DATA_GAPS = 'vessel-history-ais-data-gaps',
  VESSELS = 'vessels',
  RANGE_RINGS = 'range-rings',
  RANGE_RING_LABELS = 'range-ring-labels',
  RANGE_RING_POINTS = 'range-ring-points',
  MEASUREMENTS = 'measurements',
  WEATHER = 'weather',
  SHIPPING_LANES = 'shipping-lanes',
  FIRST_CALL_PORTS = 'first-call-ports',
  CORRESPONDENTS = 'correspondents',
  PORTS = 'ports',
}

export const MapGroupLayers = {
  INCIDENTS: [
    MapLayer.INCIDENTS,
    MapLayer.INCIDENT_CLUSTERS,
    MapLayer.INCIDENT_CLUSTER_COUNT,
  ],
  PORTS: [MapLayer.PORTS, MapLayer.PORT_CLUSTERS, MapLayer.PORT_CLUSTER_COUNT],
  CORRESPONDENTS: [
    MapLayer.CORRESPONDENTS,
    MapLayer.CORRESPONDENT_CLUSTERS,
    MapLayer.CORRESPONDENT_CLUSTER_COUNT,
  ],
};

export const RangeRingLayers = {
  LABEL: (layerId: string) => `${MapGroupLayer.RANGE_RING_LABELS}.${layerId}`,
};

export default MapLayer;

import { GeoJSONSource } from 'mapbox-gl';
import { Correspondent } from '../../../models/correspondents.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

function setCorrespondentsFeatures(
  layerId: MapLayer | string,
  correspondents: Correspondent[]
) {
  // don't draw correspondents with null coordinates as they'll end up at 0,0
  const filteredCorrespondents = correspondents.filter(
    (correspondent) =>
      correspondent.port_information.location.lat !== null &&
      correspondent.port_information.location.lng !== null
  );
  const features = GeoHelper.correspondentsToGeoJSONFeatures(
    filteredCorrespondents
  );
  const geojson = GeoHelper.createGeoJSON(features);
  (MapHelpers.getSource(layerId) as GeoJSONSource)?.setData(geojson);
}

export default setCorrespondentsFeatures;

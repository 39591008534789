import onFirstCallClusterClick from './first-call-cluster-onClick';
import onFirstCallClick from './first-call-onClick';

namespace FirstCallController {
  export const layerEvents = {
    onClick: onFirstCallClick,
    onClusterClick: onFirstCallClusterClick,
  };
}

export default FirstCallController;

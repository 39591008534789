import * as dateFns from 'date-fns';
import {
  DataGroup,
  DataItem,
  DataSet,
  IdType,
  Timeline,
  TimelineOptions,
} from 'vis-timeline/standalone';
import { VesselHistoryData } from '../../api';
import { useAppDispatch } from '../../hooks';
import setVesselFeatures from '../../map/map-layer-manager/vessel-utils/set-vessel-features';
import MapHelpers from '../../map/map.utils';
import { HistoricVesselPoint } from '../../maritime-menu-options/history-panel/historic-vessel-point.model';
import VesselHistoryController from '../../maritime-menu-options/history-panel/vessel-history-controller.utils';
import { Vessel } from '../../models/vessel.model';
import { setCentreDate } from './timeline.slice';
// eslint-disable-next-line import/no-self-import
import * as thisModule from './timeline.utils';

export const PLAYBACK_CONTROLS_WIDTH = 48 * 3 + 1; // Three buttons + 1px right border

export interface DateWithOffset {
  date: number;
  alreadyOffset: boolean;
}

export interface VesselDataItem extends DataItem {
  message: HistoricVesselPoint;
  vessel: Vessel;
}

type TimelineItem = VesselDataItem;

export interface RangeChangeEvent {
  start: Date;
  end: Date;
  byUser: boolean;
  event: WheelEvent | MouseEvent;
}
export interface SelectEvent {
  items: IdType[];
  event: MouseEvent;
}

export interface FindNearestItemOptions {
  groupId?: DataGroup['id'];
  before?: boolean;
  after?: boolean;
  excludeIds?: IdType[];
}

export const selectZoomLevel = (
  diffMs: number
): TimelineOptions['timeAxis'] => {
  if (diffMs < 1000 * 60 * 60 * 3) {
    // less than 3 hours, show 5 minutes
    return {
      scale: 'minute',
      step: 5,
    };
  }
  if (diffMs < 1000 * 60 * 60 * 8) {
    // less than 8 hours, show 15 minutes
    return {
      scale: 'minute',
      step: 15,
    };
  }
  if (diffMs < 1000 * 60 * 60 * 16) {
    // less than 16 hours, show 30 minutes
    return {
      scale: 'minute',
      step: 30,
    };
  }
  if (diffMs < 1000 * 60 * 60 * 24) {
    // less than 24 hours, show 1 hour
    return {
      scale: 'hour',
      step: 1,
    };
  }
  if (diffMs < 1000 * 60 * 60 * 24 * 2) {
    // less than 2 days, show 2 hour
    return {
      scale: 'hour',
      step: 2,
    };
  }
  if (diffMs < 1000 * 60 * 60 * 24 * 4) {
    // less than 4 days, show 6 hour
    return {
      scale: 'hour',
      step: 6,
    };
  }
  if (diffMs < 1000 * 60 * 60 * 24 * 7) {
    // less than 7 days, show 12 hour
    return {
      scale: 'hour',
      step: 12,
    };
  }
  // greater than 14 days, show 24 hour
  return {
    scale: 'day',
    step: 1,
  };
};

export const convertVesselToVisItems = (
  vesselHistoryData?: VesselHistoryData | null | undefined
): { items: DataSet<TimelineItem>; groups: DataSet<DataGroup> } => {
  if (!vesselHistoryData) {
    return { items: new DataSet(), groups: new DataSet() };
  }

  const groups: DataGroup[] = [];
  const items: TimelineItem[] = [];

  vesselHistoryData.data.forEach((dataItem, groupIndex) => {
    if (dataItem.messages.length === 0) {
      // sometimes vessels come back with no history items. there is no point putting them on the timeline
      return;
    }
    const group: DataGroup = {
      id: `${dataItem.vessel.mmsi!}-${groupIndex}`,
      content: dataItem.vessel.name,
    };

    const vesselItems: VesselDataItem[] = dataItem.messages.map(
      (message, messageIndex) => ({
        id: `${group.id}-${messageIndex}`,
        group: group.id,
        start: message.timestamp,
        content: '',
        message,
        vessel: dataItem.vessel,
      })
    );

    groups.push(group);
    items.push(...vesselItems);
  });

  return { items: new DataSet(items), groups: new DataSet(groups) };
};

/**
 * The 'Vessel' that comes back from a vessel history API call does not have the lat/long/course properties.
 * These are instead found in the 'messages' section of the response.
 * @param vesselDataItems
 * @returns
 */
export const vesselDataItemsToVessel = (
  vesselDataItems: VesselDataItem[]
): Vessel[] =>
  vesselDataItems.map((vesselDataItem) => {
    const { message, vessel } = vesselDataItem;
    return {
      ...vessel,
      latitude: message.latitude,
      longitude: message.longitude,
      course: message.course,
    };
  });

/**
 * Get the nearest item to the given date.
 * @param items a vis DataSet of items
 * @param date
 * @param options.groupId if provided, only items in this group will be considered
 * @param options.after if true, only items after the given date will be considered
 * @param options.excludeIds if provided, items with these ids will be excluded
 * @returns
 */
export const findNearestItem = <T extends VesselDataItem>(
  items: DataSet<T>,
  date: Date,
  options: FindNearestItemOptions = {}
): T | null => {
  let shortestDist: number | null = null;
  let closestItem: T | null = null;
  const { groupId, after, before } = options;
  const closestFunc = (item: T) => {
    if (options.excludeIds && options.excludeIds.includes(item.id!)) {
      return;
    }
    if (groupId && item.group !== groupId) {
      return;
    }
    const itemTime = new Date(item.start);
    const distance = itemTime.getTime() - date.getTime();

    if (after && distance <= 0) {
      return;
    }
    if (before && distance >= 0) {
      return;
    }
    // shortest dist can be 0 in the case of moving to a point, so we need to check for null instead of !shortestDist
    if (shortestDist === null || Math.abs(distance) < shortestDist) {
      closestItem = item;
      shortestDist = Math.abs(distance);
    }
  };
  if (groupId) {
    items
      .get({ filter: (item) => item.group === groupId })
      .forEach(closestFunc);
  } else {
    items.forEach(closestFunc);
  }
  return closestItem;
};

/**
 * Find the nearest item from each group
 */
export const findNearestItemInEachGroup = <T extends VesselDataItem>(
  items: DataSet<T>,
  groups: DataSet<DataGroup>,
  date: Date,
  options: FindNearestItemOptions = {}
): {
  nearestOverall: T | null;
  nearestPerGroup: T[];
} => {
  const nearestPerGroup: T[] = [];
  groups.forEach((group) => {
    const nearestForGroup = thisModule.findNearestItem(items, date, {
      ...options,
      groupId: group.id,
    });
    if (nearestForGroup) {
      nearestPerGroup.push(nearestForGroup);
    }
  });
  const nearestOverall = thisModule.findNearestItem(items, date, options);
  return {
    nearestOverall,
    nearestPerGroup,
  };
};

// Vis Timeline does not expose a func for getting the current centre date or window range.
export const getTimelineInfo = (
  timeline: Timeline
): {
  start: Date;
  centre: Date;
  end: Date;
  windowRangeMs: number;
} => {
  const { start, end } = timeline.getWindow();
  const currentCentreDateMs = (start.getTime() + end.getTime()) / 2;
  return {
    start,
    centre: new Date(currentCentreDateMs),
    end,
    windowRangeMs: end.getTime() - start.getTime(),
  };
};

/**
 * depending on the length of the vessel name, the timeline is squashed to the right.
 * As we want the date buttons, with the little indicator to remain central (to avoid even more maths),
 * we need to calculate what date is at the centre of the whole timeline component.
 *
 * --------------------   Z    ----------------------
 * |    X      |                Y                   |
 * |           |    A     |                         |
 *
 * X = labelset width
 * Y = timeline width
 * Z = timeline container width
 * A = timeline offset
 *
 * We need to find A as a percentage of Y, so we can find the date A% along Y.
 *
 * @param unshiftedCentreDate the date we wish to move to, without any offset calculations yet applied.
 * @param currentWindowRangeMs current window range, in ms
 * @param options.reverse if true, we are at the centre of Z, and want to find the date at the centre of Y.
 * @returns an offset centreDate such that the whole timeline component will be centred on the required date
 */
export const calculateCentreDate = (
  unshiftedCentreDate: Date,
  currentWindowRangeMs: number,
  options: { reverse?: boolean } = {}
): Date | null => {
  const overallTimelineContainer = document.querySelector('#vis-timeline');
  if (!overallTimelineContainer) {
    return null;
  }
  const { width: fullWidth } = overallTimelineContainer.getBoundingClientRect();
  const labelsContainer =
    overallTimelineContainer.querySelector('.vis-labelset');
  if (!labelsContainer) {
    return null;
  }
  const { width: labelWidth } = labelsContainer.getBoundingClientRect();
  const timelineWidth = fullWidth - labelWidth - PLAYBACK_CONTROLS_WIDTH;
  const timelineOffset =
    (timelineWidth - labelWidth - PLAYBACK_CONTROLS_WIDTH) /
    (2 * timelineWidth);
  if (Number.isNaN(timelineOffset)) {
    // timelineWidth is 0 so
    // timeline is not yet initialised (or we're in jest where it has 0 width)
    return null;
  }
  if (options.reverse) {
    const scaledDiffMs = currentWindowRangeMs * timelineOffset; // how many milliseconds along the window range is the adjusted centre date
    const unshiftedEndMs =
      unshiftedCentreDate.getTime() + currentWindowRangeMs / 2;
    return new Date(unshiftedEndMs - scaledDiffMs);
  }
  const scaledDiffMs = currentWindowRangeMs * timelineOffset; // how many milliseconds along the window range is the adjusted centre date
  const unshiftedStartMs =
    unshiftedCentreDate.getTime() - currentWindowRangeMs / 2;

  const newCentreMs = unshiftedStartMs + scaledDiffMs;
  return new Date(newCentreMs);
};

/**
 * Similar to the above, but this time a user wants to moveTo a specific date.
 * Instead they need to move to a slightly later date to account for the offset.
 *
 * --------------------   Z    ----------------------
 * |    X      |                Y                   |
 * |           |          |  A  |                   |
 *
 * X = labelset width
 * Y = timeline width
 * Z = timeline container width
 * A = timeline offset between middle of Y and middle of Z
 *
 * @param timeline vis.js timeline
 * @param moveToDate date to move to
 * @param callback optional callback to be called after the move
 * @returns centreDate the date that the timeline will be centred on
 */
export const moveToWithOffset = (
  timeline: Timeline,
  moveToDate: Date,
  callback?: (e: any) => void
) => {
  const { start, windowRangeMs } = thisModule.getTimelineInfo(timeline);
  const newCentreDate = thisModule.calculateCentreDate(
    moveToDate,
    windowRangeMs,
    {
      reverse: true,
    }
  );
  if (!newCentreDate) {
    return null;
  }

  const currentCentreDateMs = start.getTime() + windowRangeMs / 2;
  const currentCentreDate = new Date(currentCentreDateMs);

  // if we're about to try to move less than 1% of the current range, don't bother
  if (
    Math.abs(currentCentreDate.getTime() - newCentreDate.getTime()) >
    windowRangeMs / 100
  ) {
    timeline.moveTo(newCentreDate, { animation: true }, callback);
    return newCentreDate;
  }
  return currentCentreDate;
};

/**
 * returns true if at least one item in the given dataset is within the given day
 * used to determine if a date button should be disabled
 * @param date
 * @param items
 * @returns
 */
export const isWithinDay = <T extends VesselDataItem>(
  date: Date,
  items: DataSet<T> | null | undefined
) => {
  if (!items) {
    return false;
  }
  const itemsWithinDay = items.get({
    filter: (item) => {
      const itemDate = new Date(item.start);
      return dateFns.isSameDay(date, itemDate);
    },
  });
  return itemsWithinDay.length > 0;
};

export const onVesselPointChanged = (
  items: DataSet<VesselDataItem> | null | undefined,
  groups: DataSet<DataGroup> | null | undefined,
  centreDate: Date,
  options: { after?: boolean } = {}
) => {
  if (!items || !groups) {
    return [];
  }
  const nearestItems = thisModule.findNearestItemInEachGroup(
    items,
    groups,
    centreDate,
    options
  );
  // vis.js id
  const nearestItemIds = nearestItems.nearestPerGroup.map(
    (item: VesselDataItem) => item.id!
  );
  // vessel ids
  const vesselIds = nearestItems.nearestPerGroup.map((item: VesselDataItem) => {
    if ('message' in item) {
      return item.message.vessel_id;
    }
    return null;
  });
  MapHelpers.setVesselOpacity(
    vesselIds.filter((id) => id !== null) as string[],
    // if the layer name is dependent on the vessel id, we need to pass the function which returns the layer name
    VesselHistoryController.layerList.VESSELS.getLayerKey,
    0.5
  );

  nearestItems.nearestPerGroup.forEach((item) => {
    if ('message' in item) {
      setVesselFeatures(
        VesselHistoryController.layerList.SELECTED_POSITION.getLayerKey(
          item.message.vessel_id
        ),
        thisModule.vesselDataItemsToVessel([item])
      );
      setVesselFeatures(
        VesselHistoryController.layerListVessels.SELECTED_POSITION.getLayerKey(
          item.message.vessel_id
        ),
        thisModule.vesselDataItemsToVessel([item])
      );
    }
  });
  return nearestItemIds;
};

export const onRangeChange = (
  dispatch: ReturnType<typeof useAppDispatch>,
  e: RangeChangeEvent
): TimelineOptions['timeAxis'] => {
  const diffMs = e.end.getTime() - e.start.getTime();
  if (e.byUser) {
    const timelineCentreDate = new Date(
      (e.start.getTime() + e.end.getTime()) / 2
    );
    const offsetCentreDate = thisModule.calculateCentreDate(
      timelineCentreDate,
      diffMs
    );
    if (offsetCentreDate) {
      dispatch(
        setCentreDate({
          alreadyOffset: true,
          date: offsetCentreDate.getTime(),
        })
      );
    }
  }

  return selectZoomLevel(diffMs);
};

export const onSelectChange = (
  items: DataSet<TimelineItem> | null | undefined,
  e: SelectEvent,
  dispatch: ReturnType<typeof useAppDispatch>
) => {
  const newStartTime = items?.get(e.items[0])?.start;

  if (!newStartTime) {
    return;
  }
  dispatch(
    setCentreDate({
      alreadyOffset: false,
      date: new Date(newStartTime).getTime(),
    })
  );
};

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FirstCallPanelState {
  search: string;
}

export const INITIAL_FIRST_CALL_PANEL_STATE: FirstCallPanelState = {
  search: '',
};

const FirstCallPanelSlice = createSlice({
  name: 'firstCallPanel',
  initialState: INITIAL_FIRST_CALL_PANEL_STATE,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { setSearch } = FirstCallPanelSlice.actions;

export default FirstCallPanelSlice.reducer;

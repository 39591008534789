import { endOfDay, endOfTomorrow } from 'date-fns';
import { defaultIncidentFilters } from '../../maritime-menu-options/incidents-panel/incident-panel.slice';
import {
  ExpandedIncident,
  Incident,
  IncidentFilters,
  IncidentTargetType,
  IncidentType,
} from '../../maritime-menu-options/incidents-panel/incident.model';
import {
  ExtendedMaritimeArea,
  MaritimeArea,
  RIStats,
  Region,
} from '../../models/risk_intelligence.model';
import {
  setIncidentAreas,
  setIncidentRegions,
  setIncidentTargetTypes,
  setIncidentTypes,
} from '../../state/incidents/incidents.slice';
import store from '../../store';
import { wrapRequest } from '../base';

export const getIncidentTypes = async (): Promise<IncidentType[]> =>
  wrapRequest('get', 'geonius', '/risk-intelligence/incident-types');
export const getIncidentRegions = async (): Promise<Region[]> =>
  wrapRequest('get', 'geonius', '/risk-intelligence/regions');
export const getMaritimeAreas = async (): Promise<MaritimeArea[]> =>
  wrapRequest('get', 'geonius', '/risk-intelligence/areas');
export const getSpecificMaritimeArea = async (
  areaId: number
): Promise<ExtendedMaritimeArea> =>
  wrapRequest('get', 'geonius', `/risk-intelligence/areas/${areaId}`);

export const getIncidentTargetTypes = async (): Promise<IncidentTargetType[]> =>
  wrapRequest('get', 'geonius', '/risk-intelligence/target-types');

interface FiltersToQueryStringOptions {
  filters: IncidentFilters;
  ignoreDateRange?: boolean;
  ignoreAllFilters?: boolean;
}

export const convertFiltersToQueryStringParams = ({
  filters,
  ignoreDateRange = false,
  ignoreAllFilters = false,
}: FiltersToQueryStringOptions): Record<string, string> => {
  const {
    startDate,
    endDate,
    incidentTypes,
    timeOfDay,
    place,
    regions,
    areas,
    targets,
    geo,
  } = filters;
  const queryStringParameters: Record<string, string> = {};

  if (ignoreDateRange || ignoreAllFilters) {
    // RI data begins Jan '06
    queryStringParameters.from = '2006-01-01';
    // Go to tomorrow to avoid potential timezone issues
    queryStringParameters.to = endOfTomorrow().toISOString().slice(0, 10);
  } else {
    if (startDate) {
      queryStringParameters.from = startDate;
    }
    if (endDate) {
      // Round up to include the entire day
      queryStringParameters.to = endOfDay(new Date(endDate)).toISOString();
    }
  }

  if (ignoreAllFilters) {
    return queryStringParameters;
  }
  if (incidentTypes.length > 0) {
    queryStringParameters.types = incidentTypes.join(',');
  }
  if (timeOfDay.length > 0) {
    queryStringParameters['time-of-day'] = timeOfDay.join(',');
  }
  if (place.length > 0) {
    queryStringParameters.place = place.join(',');
  }
  if (areas.length > 0) {
    queryStringParameters.areas = areas.join(',');
  }
  if (regions.length > 0) {
    queryStringParameters.regions = regions.join(',');
  }
  if (targets.length > 0) {
    queryStringParameters['target-types'] = targets.join(',');
  }
  if (geo) {
    queryStringParameters.geo = JSON.stringify(geo);
  }

  return queryStringParameters;
};

interface GetIncidentsOptions {
  ignoreDateRange?: boolean;
  ignoreAllFilters?: boolean;
  filters?: IncidentFilters;
}

export const getIncidents = async ({
  filters = defaultIncidentFilters,
  ignoreDateRange = false,
  ignoreAllFilters = false,
}: GetIncidentsOptions): Promise<
  Incident[] & {
    error?: string;
    error_code?: string;
  }
> => {
  const dependentPromises = [];
  const { incidentAreas, incidentRegions, incidentTargetTypes, incidentTypes } =
    store.getState().incidents;
  // if any of these are null, we need to fetch them
  if (!incidentAreas) {
    const areaPromise = getMaritimeAreas().then((data) => {
      store.dispatch(setIncidentAreas(data));
      return data;
    });
    dependentPromises.push(areaPromise);
  }
  if (!incidentRegions) {
    const regionPromise = getIncidentRegions().then((data) => {
      store.dispatch(setIncidentRegions(data));
      return data;
    });
    dependentPromises.push(regionPromise);
  }
  if (!incidentTargetTypes) {
    const targetTypesPromise = getIncidentTargetTypes().then((data) => {
      store.dispatch(setIncidentTargetTypes(data));
      return data;
    });
    dependentPromises.push(targetTypesPromise);
  }
  if (!incidentTypes) {
    const typesPromise = getIncidentTypes().then((data) => {
      store.dispatch(setIncidentTypes(data));
      return data;
    });
    dependentPromises.push(typesPromise);
  }
  const queryStringParameters = convertFiltersToQueryStringParams({
    filters,
    ignoreDateRange,
    ignoreAllFilters,
  });

  await Promise.all(dependentPromises);

  return wrapRequest('get', 'geonius', '/risk-intelligence/incidents', {
    queryStringParameters,
  });
};

export const getIncident = async (
  incidentId: string | number
): Promise<ExpandedIncident> =>
  wrapRequest('get', 'geonius', `/risk-intelligence/incidents/${incidentId}`);

export const getIncidentStats = async (
  incidentFilters: IncidentFilters
): Promise<RIStats> => {
  const queryStringParameters = convertFiltersToQueryStringParams({
    filters: incidentFilters,
  });
  return wrapRequest('get', 'geonius', '/risk-intelligence/statistics', {
    queryStringParameters,
  });
};

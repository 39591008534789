import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { Port } from '../../../maritime-menu-options/world-ports-panel/world-ports.model';
import { startNewHistory } from '../../../nav-history.slice';
import {
  setSelectedPort,
  setSelectedPortId,
} from '../../../state/ports/ports.slice';
import store from '../../../store';
import { assertIsPointFeature } from '../../../utils/typescript-helpers';
import MapHelpers from '../../map.utils';

function onPortClick(feature: MapboxGeoJSONFeature, port: Port) {
  const { ports, selectedPort } = store.getState().ports;

  if (assertIsPointFeature(feature)) {
    MapHelpers.zoomToPoint(feature.geometry.coordinates, 8.5);
  }

  if (selectedPort?.WPI === port.WPI) {
    // port already selected
    return;
  }
  store.dispatch(startNewHistory({ type: 'map' }));
  store.dispatch(setSelectedPortId(null));
  // Port Properties in the GeoJson Feature are not all properties of the Port model
  const newSelectedPort = ports?.find((p) => p.WPI === port.WPI);
  if (newSelectedPort) {
    if (newSelectedPort?.ri) {
      window.history.pushState({}, '', `/ports/${newSelectedPort.ri.id}`);
    } else {
      window.history.pushState(
        {},
        '',
        `/ports/${newSelectedPort.UNLOCODE.replace(' ', '')}`
      );
    }
  }
  store.dispatch(setSelectedDossier(EDossiers.PORT));
  store.dispatch(setSelectedPort(newSelectedPort));
}

export default onPortClick;

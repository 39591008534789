import { CorrespondentFeature } from '../../../models/correspondents.model';

function correspondentInfoPopUpContent(
  feature: mapboxgl.MapboxGeoJSONFeature
): { label: string; value: string } | null {
  if (feature) {
    const correspondentFeature = feature as unknown as CorrespondentFeature;
    const correspondentName = correspondentFeature.properties.name;
    return {
      label: 'Correspondent Name',
      value: correspondentName,
    };
  }
  return null;
}

export default correspondentInfoPopUpContent;

import { NorthRounded } from '@mui/icons-material';
import { TableCell, TableRow, Typography } from '@mui/material';
import {
  WeatherHourlyData,
  WeatherResultName,
  WeatherSourceName,
  WeatherUnits,
} from './weather.model';

interface ResultRowProps {
  rowName: string;
  data: [key: string, value: number | string][];
  preferredData: [key: string, value: number | string];
  units: string;
  showAllSources: boolean;
  isDirection: boolean;
  noSource?: boolean;
}

export function ResultRow(props: ResultRowProps) {
  const {
    rowName,
    data,
    preferredData,
    showAllSources,
    units,
    isDirection,
    noSource,
  } = props;
  return (
    <TableRow>
      <TableCell>
        <Typography sx={{ fontWeight: 500 }}>{rowName}</Typography>
      </TableCell>
      <TableCell colSpan={noSource ? 2 : 1}>
        {(showAllSources &&
          data.map(([, value]) => (
            <Typography
              variant="body1"
              key={value}
              display="flex"
              alignItems="start"
            >
              {`${value}${units}`}
              {isDirection && (
                <NorthRounded sx={{ transform: `rotate(${value}deg)` }} />
              )}
            </Typography>
          ))) || (
          <Typography variant="body1" display="flex" alignItems="start">
            {`${preferredData[1]}${units}`}

            {isDirection && (
              <NorthRounded
                sx={{
                  transform: `rotate(${preferredData[1]}deg)`,
                  transition: 'transform 0.5s ease-in-out',
                }}
              />
            )}
          </Typography>
        )}
      </TableCell>
      {!noSource && (
        <TableCell align="center">
          {(showAllSources &&
            data.map(([key]) => (
              <Typography
                key={key}
                title={WeatherSourceName[key]}
                fontSize="0.7rem"
                lineHeight={2}
              >
                {key.toUpperCase()}
              </Typography>
            ))) || (
            <Typography
              fontSize="0.7rem"
              lineHeight={2}
              title={WeatherSourceName[preferredData[0]] || preferredData[0]}
            >
              {preferredData[0].toUpperCase()}
            </Typography>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}

ResultRow.defaultProps = {
  noSource: false,
};

interface WeatherResultProps {
  dataKey: keyof Omit<WeatherHourlyData, 'time'>;
  data: WeatherHourlyData;
  showAllSources: boolean;
  isDirection?: boolean;
}

export function WeatherResultRow(props: WeatherResultProps) {
  const { data, dataKey, showAllSources, isDirection = false } = props;
  const dataForKey = data[dataKey];
  if (!dataForKey) return null; // clicking on land obviously returns no marine data
  const unitsForKey = WeatherUnits[dataKey].value;

  // Stormglass collates all the sources it has access to, and uses the best one.
  // we can figure out which one it chose to use, and display that as the source
  const sgResult = dataForKey.sg;
  let bestSouce: keyof typeof WeatherSourceName = 'sg';
  Object.entries(dataForKey).forEach(([key, value]) => {
    if (key === 'sg') return;
    if (value === sgResult) {
      bestSouce = key as keyof typeof WeatherSourceName;
    }
  });

  const sourcesWithoutSg = Object.entries(dataForKey).filter(
    ([key]) => key !== 'sg'
  ) as [string, number][];
  return (
    <ResultRow
      rowName={WeatherResultName[dataKey]}
      data={sourcesWithoutSg}
      preferredData={[bestSouce, sgResult]}
      showAllSources={showAllSources}
      units={unitsForKey}
      isDirection={isDirection}
    />
  );
}

WeatherResultRow.defaultProps = {
  isDirection: false,
};

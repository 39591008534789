import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { getDidYouKnow, updateDidYouKnow } from './api/did-you-know';
import SimpleDialog from './common-components/dialog/SimpleDialog';

interface DykData {
  title: string;
  content: string;
}

function didYouKnowModal({ title, content }: DykData, handleClose: any) {
  const sanitizedContent = sanitizeHtml(content, {
    allowedTags: ['a', 'b', 'i', 'p', 'em', 'strong'],
    allowedAttributes: {
      a: ['href', 'target', 'class'],
    },
    transformTags: {
      a(tagName, attribs) {
        return {
          tagName: 'a',
          attribs: {
            ...attribs,
            target: '_blank',
            class: 'riAnchor',
          },
        };
      },
    },
  });

  const contentToDisplay = (
    <div
      // HTML has been cleaned
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
    />
  );

  return (
    <Box
      data-testid="dyk-content-container"
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Typography
        variant="subtitle1"
        sx={{ margin: '0.5em 0', fontWeight: '700', fontSize: '1.15rem' }}
      >
        {title}
      </Typography>
      {contentToDisplay}
      <Button
        data-testid="dyk-ok-button"
        onClick={handleClose}
        sx={{
          alignSelf: 'flex-end',
          maxWidth: '15%',
          bgcolor: 'secondary.main',
          color: 'white',
          border: '2px solid',
          borderColor: 'secondary.main',
        }}
      >
        Ok
      </Button>
    </Box>
  );
}

function DidYouKnow() {
  const [showDykModal, setShowDykModal] = useState(false);
  const [dykData, setDykData] = useState<undefined | DykData>(undefined);
  const [iDFromRequest, setIdFromRequest] = useState<string | undefined>(
    undefined
  );
  const [errorDyk, setErrorDyk] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    getDidYouKnow()
      .then((res) => {
        if (Object.values(res).every((val) => val)) {
          const { id, title, content } = res;
          setIdFromRequest(id as string);
          setDykData({ title, content });
          setShowDykModal(true);
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((e) => {
        setErrorMessage('Error getting Did You Know message');
        setErrorDyk(true);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setErrorDyk(false);
    }, 3000);
  }, [errorDyk]);

  function handleClose() {
    setShowDykModal(false);
    setDykData(undefined);
    if (iDFromRequest) {
      updateDidYouKnow(iDFromRequest)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((err) => {
          setErrorMessage('Error updated Did you Know');
          setErrorDyk(true);
        });
    }
  }

  if (showDykModal) {
    return (
      <SimpleDialog
        title="Did You Know"
        width={500}
        open={showDykModal}
        onClose={() => {}}
      >
        {dykData &&
          didYouKnowModal(
            {
              title: dykData.title,
              content: dykData.content,
            },
            handleClose
          )}
      </SimpleDialog>
    );
  }

  if (errorDyk) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorDyk}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    );
  }
  return null;
}

export default DidYouKnow;

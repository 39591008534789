import { Amplify } from '@aws-amplify/core';
import { Storage } from '@aws-amplify/storage';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import awsExports from './aws-exports';
import ErrorPage from './common-components/error-components/error-page/error-page';
import './index.scss';
import { StorageDirectory, StorageLevel } from './models/document';
import AppThemeProvider from './mui-themes/app-theme-provider';
import reportWebVitals from './reportWebVitals';
import store from './store';
import refreshToken from './utils/refresh-token';

// Buffer exists in browser context, but gets confused in 'npm start' transpilation
// eslint-disable-next-line global-require
window.Buffer = window.Buffer || require('buffer').Buffer;

Amplify.configure(awsExports);
// By default, Amplify will automatically refresh the tokens for Google and Facebook.
// However, for the federated provider our client is using we need to add this configuration ourselves.
Auth.configure({
  refreshHandlers: {
    [process.env.REACT_APP_AUTH_DOMAIN || '']: refreshToken,
  },
});
Storage.configure({
  customPrefix: {
    private: StorageDirectory[StorageLevel.PRIVATE],
    public: '',
  },
});

const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppThemeProvider>
        <RouterProvider router={router} />
      </AppThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

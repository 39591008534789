import {
  AccessTimeRounded,
  ArticleRounded,
  Download,
  Waves,
  WbSunny,
  WbTwilightRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  FormControlLabel,
  MenuItem,
  Select,
  Slider,
  Stack,
  Switch,
  Table,
  TableBody,
  Typography,
} from '@mui/material';
import { isSameDay } from 'date-fns';
import { GeoJSONSource } from 'mapbox-gl';
import { useEffect, useState } from 'react';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../map/map.utils';
import { TimeSeriesLineGraph } from '../../reporting/charts';
import DateTimeHelpers from '../../utils/date-time-helpers.utils';
import GeoHelper from '../../utils/geo-helpers.utils';
import { titleCase } from '../../utils/text-formatting.utils';
import { ResultRow, WeatherResultRow } from './weather-result-row';
import {
  AstronomyDailyData,
  TidalExtremesData,
  TideMeta,
  TideStation,
  WeatherHourlyData,
  WeatherResponse,
  WeatherResultName,
  WeatherUnits,
} from './weather.model';

const getNearestWeatherHour = (results: WeatherResponse, date: Date) => {
  const nearest = results.weather.hours.reduce((acc, curr) => {
    const currDate = new Date(curr.time);
    const accDate = new Date(acc.time);
    const currDiff = Math.abs(currDate.getTime() - date.getTime());
    const accDiff = Math.abs(accDate.getTime() - date.getTime());
    if (currDiff < accDiff) {
      return curr;
    }
    return acc;
  });
  return nearest;
};

const getNearestAstronomyData = (results: WeatherResponse, date: Date) =>
  results.astronomy.data.find((item) => {
    const currDate = new Date(item.time); // astronomy data should go to the start of the day

    if (isSameDay(currDate, date)) {
      return true;
    }
    return false;
  });

const getNearestTideData = (results: WeatherResponse, date: Date) =>
  results.tidal_extremes.data.reduce((acc, curr) => {
    const currDate = new Date(curr.time);
    const accDate = new Date(acc.time);
    const currDiff = currDate.getTime() - date.getTime();
    // only display closest tide data in the past, not the future
    if (currDiff < 0) return acc;
    const accDiff = accDate.getTime() - date.getTime();
    if (accDiff < 0) return curr;
    if (currDiff < accDiff) return curr;
    return acc;
  });

const donwloadCsv = (results: WeatherResponse) => {
  const csvHeader = [
    'timestamp',
    'windSpeed_metersPerSecond',
    'windDirection_degrees',
    'airTemperature_degreesCelcius',
    'airPressure_hectopascal',
    'cloudCover_percentage',
    'iceCover_percentageNormalised',
    'precipitation_kilogramsPerMeterSquared',
    'horizontalVisibility_kilometers',
    'waveHeight_meters',
    'waveDirection_degrees',
    'wavePeriod_seconds',
    'currentSpeed_metersPerSecond',
    'currentDirection_degrees',
    'tidalExtremeHeight_meters',
    'tideStationName',
    'tideStationLng',
    'tideStationLat',
    'elevationBathymetry_meters',
    'sunrise_timestamp',
    'sunset_timestamp',
  ];

  const csvData = results.weather.hours.map((hour) => {
    const nearestTide = getNearestTideData(results, new Date(hour.time));
    const nearestAstronomy = getNearestAstronomyData(
      results,
      new Date(hour.time)
    );
    return {
      timestamp: hour.time,
      windSpeed_metersPerSecond: hour.windSpeed.sg,
      windDirection_degrees: hour.windDirection.sg,
      airTemperature_degreesCelcius: hour.airTemperature.sg,
      airPressure_hectopascal: hour.pressure.sg,
      cloudCover_percentage: hour.cloudCover.sg,
      iceCover_percentageNormalised: hour.iceCover.sg,
      precipitation_kilogramsPerMeterSquared: hour.precipitation.sg,
      horizontalVisibility_kilometers: hour.visibility.sg,
      waveHeight_meters: hour.waveHeight.sg,
      waveDirection_degrees: hour.waveDirection.sg,
      wavePeriod_seconds: hour.wavePeriod.sg,
      currentSpeed_metersPerSecond: hour.currentSpeed.sg,
      currentDirection_degrees: hour.currentDirection.sg,
      tidalExtremeHeight_meters: nearestTide?.height?.toFixed(2) || '',
      tideStationName: titleCase(results.tidal_extremes.meta.station.name),
      tideStationLng: results.tidal_extremes.meta.station.lng,
      tideStationLat: results.tidal_extremes.meta.station.lat,
      elevationBathymetry_meters: results.elevation.data.elevation.toFixed(2),
      sunrise_timestamp: nearestAstronomy?.sunrise || '',
      sunset_timestamp: nearestAstronomy?.sunset || '',
    };
  });

  const csv = [
    csvHeader.join(','),
    ...csvData.map((row) => Object.values(row).join(',')),
  ].join('\n');
  const element = document.createElement('a');
  const file = new Blob([csv], { type: 'text/csv' });
  const trimmedLat = results.weather.meta.lat.toFixed(2);
  const trimmedLng = results.weather.meta.lng.toFixed(2);
  const { start, end } = results.weather.meta;
  const isoStart = DateTimeHelpers.dateToIso8601(start);
  const isoEnd = DateTimeHelpers.dateToIso8601(end);
  const fileName = `environmental_data_${trimmedLng}-${trimmedLat}_${isoStart}-${isoEnd}.csv`;
  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const setVisibilityRing = (radius: number, long: number, lat: number) => {
  // this feels a bit convoluted
  const circle = GeoHelper.createCircle(radius, long, lat);
  const circleCoords = GeoHelper.createCircumferenceCoordinates(circle);
  const circleFeature = GeoHelper.createFeatureCircle(circle, {});
  const circlePolygon = GeoHelper.convertFeatureCircleToPolygon(circleFeature);
  const circlePolygonCollection = GeoHelper.createGeoJSON([circlePolygon]);

  // place label on top of circle
  const labelCoordinates = [
    long,
    Math.max.apply(
      null,
      circleCoords.map((coordinates) => coordinates[1])
    ),
  ];

  const labelFeature = GeoHelper.createFeaturePoint(labelCoordinates, {
    label: `Horizontal Visibility:\n ${radius}km`,
  });

  const labelFeatureCollection = GeoHelper.createGeoJSON([labelFeature]);

  (
    MapHelpers.getSource(MapLayer.WEATHER_VISIBILITY_RING) as
      | GeoJSONSource
      | undefined
  )?.setData(circlePolygonCollection);
  (
    MapHelpers.getSource(MapLayer.WEATHER_VISIBILITY_LABEL) as
      | GeoJSONSource
      | undefined
  )?.setData(labelFeatureCollection);
};

const weatherGraphOptions: (keyof typeof WeatherResultName)[] = [
  'windSpeed',
  'windDirection',
  'airTemperature',
  'pressure',
  'cloudCover',
  'iceCover',
  'precipitation',
  'visibility',
];

const marineGraphOptions: (keyof typeof WeatherResultName)[] = [
  'waveHeight',
  'waveDirection',
  'wavePeriod',
  'currentSpeed',
  'currentDirection',
];

const formatTideData = (
  data: TidalExtremesData | null,
  meta: TideMeta
): [string, string][] => {
  if (!data) return [];
  return [
    [
      '',
      `${meta.datum} ${data.height >= 0 ? '+' : '-'} ${Math.abs(
        data.height
      ).toFixed(2)}m`,
    ],
    ['', `(@ ${new Date(data.time).toLocaleTimeString()}) (UTC)`],
  ];
};

const formatTideStation = (station: TideStation): [string, string][] => [
  ['', titleCase(station.name)],
  ['', `(@ ${station.lng.toFixed(2)}, ${station.lat.toFixed(2)})`],
];

interface WeatherResultsProps {
  results: WeatherResponse;
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
}

export default function WeatherResults(props: WeatherResultsProps) {
  const { results, selectedDate, setSelectedDate } = props;
  const [nearestWeatherHour, setNearestWeatherHour] =
    useState<WeatherHourlyData | null>(null);
  const [nearestAstronomyData, setNearestAstronomyData] =
    useState<AstronomyDailyData | null>(null);
  const [resultsFromAllSources, setResultsFromAllSources] =
    useState<boolean>(false);
  const [nearestTideData, setNearestTideData] =
    useState<TidalExtremesData | null>(null);
  const [dateOptions, setDateOptions] = useState<Date[]>([]);
  const [weatherTimeSeriesOpen, setWeatherTimeSeriesOpen] =
    useState<boolean>(false);
  const [marineTimeSeriesOpen, setMarineTimeSeriesOpen] =
    useState<boolean>(false);
  const [selectedWeatherGraphOption, setSelectedWeatherGraphOption] =
    useState<keyof typeof WeatherResultName>('windSpeed');
  const [selectedMarineGraphOption, setSelectedMarineGraphOption] =
    useState<keyof typeof WeatherResultName>('waveHeight');

  useEffect(() => {
    if (!selectedDate || !results) return;
    const nearestW = getNearestWeatherHour(results, selectedDate);
    // skip if the nearest weather hour is the same as the current one
    if (!nearestWeatherHour || nearestWeatherHour.time !== nearestW.time) {
      setVisibilityRing(
        nearestW.visibility.sg,
        results.weather.meta.lng,
        results.weather.meta.lat
      );
      setNearestWeatherHour(nearestW);
    }
    const nearestAstronomy = getNearestAstronomyData(results, selectedDate);
    if (
      nearestAstronomy &&
      (!nearestAstronomyData ||
        nearestAstronomyData.time !== nearestAstronomy.time)
    ) {
      setNearestAstronomyData(nearestAstronomy);
    }
    const nearestTide = getNearestTideData(results, selectedDate);
    if (
      nearestTide &&
      (!nearestTideData || nearestTideData.time !== nearestTide.time)
    ) {
      setNearestTideData(nearestTide);
    }
  }, [selectedDate, results]);

  useEffect(() => {
    if (!results) return;
    // weather is the highest resolution data, so we can use it to get the dates
    const dates = results.weather.hours.map((hour) => new Date(hour.time));
    setDateOptions(dates);
  }, [results]);

  return (
    <Box>
      <Stack spacing={1} sx={{ marginTop: 1 }}>
        {nearestWeatherHour && (
          <Card>
            <CardHeader
              titleTypographyProps={{
                align: 'left',
                fontWeight: 500,
                fontSize: '1.2rem',
              }}
              title="Weather"
              avatar={<WbSunny />}
            />

            <CardContent>
              <Table padding="none">
                <colgroup>
                  <col style={{ width: '60%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '10%' }} />
                </colgroup>
                <WeatherResultRow
                  dataKey="windSpeed"
                  data={nearestWeatherHour}
                  showAllSources={resultsFromAllSources}
                />
                <WeatherResultRow
                  dataKey="windDirection"
                  data={nearestWeatherHour}
                  showAllSources={resultsFromAllSources}
                  isDirection
                />
                <WeatherResultRow
                  dataKey="airTemperature"
                  data={nearestWeatherHour}
                  showAllSources={resultsFromAllSources}
                />
                <WeatherResultRow
                  dataKey="pressure"
                  data={nearestWeatherHour}
                  showAllSources={resultsFromAllSources}
                />
                <WeatherResultRow
                  dataKey="cloudCover"
                  data={nearestWeatherHour}
                  showAllSources={resultsFromAllSources}
                />
                <WeatherResultRow
                  dataKey="iceCover"
                  data={nearestWeatherHour}
                  showAllSources={resultsFromAllSources}
                />
                <WeatherResultRow
                  dataKey="precipitation"
                  data={nearestWeatherHour}
                  showAllSources={resultsFromAllSources}
                />
                <WeatherResultRow
                  dataKey="visibility"
                  data={nearestWeatherHour}
                  showAllSources={resultsFromAllSources}
                />
              </Table>
              <Collapse in={weatherTimeSeriesOpen}>
                <Box sx={{ padding: 1 }}>
                  <Select
                    value={selectedWeatherGraphOption}
                    onChange={(e) => {
                      setSelectedWeatherGraphOption(
                        e.target.value as keyof typeof WeatherResultName
                      );
                    }}
                    fullWidth
                  >
                    {weatherGraphOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {WeatherResultName[option]}
                      </MenuItem>
                    ))}
                  </Select>
                  <TimeSeriesLineGraph
                    title={
                      WeatherResultName[
                        selectedWeatherGraphOption
                      ] as keyof WeatherHourlyData
                    }
                    data={[results.weather.hours]}
                    xAxisKey="time"
                    yScale={WeatherUnits[selectedWeatherGraphOption]?.yScale}
                    yLabel={WeatherUnits[selectedWeatherGraphOption].yLabel}
                    // use the Stormglass source as it will always be present
                    yAxisKeys={[
                      {
                        key: `${selectedWeatherGraphOption}.sg`,
                        label: WeatherResultName[selectedWeatherGraphOption],
                      },
                    ]}
                    selectedDate={
                      selectedDate?.getTime() ||
                      new Date(
                        results.weather.hours[
                          results.weather.hours.length - 1
                        ].time
                      ).getTime()
                    }
                    onDateChange={(date: number) => {
                      setSelectedDate(new Date(date));
                    }}
                    hidePoints
                  />
                </Box>
              </Collapse>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => {
                  setWeatherTimeSeriesOpen(!weatherTimeSeriesOpen);
                }}
                title={
                  weatherTimeSeriesOpen
                    ? 'Hide Time Series'
                    : 'Show Time Series'
                }
                fullWidth
                variant="contained"
              >
                {weatherTimeSeriesOpen
                  ? 'Hide Time Series'
                  : 'Show Time Series'}
              </Button>
            </CardActions>
          </Card>
        )}
        {nearestWeatherHour && (
          <Card>
            <CardHeader
              titleTypographyProps={{
                align: 'left',
                fontWeight: 500,
                fontSize: '1.2rem',
              }}
              title="Marine"
              avatar={<Waves />}
            />
            <CardContent>
              <Table padding="none">
                <colgroup>
                  <col style={{ width: '60%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '10%' }} />
                </colgroup>
                <TableBody>
                  <WeatherResultRow
                    dataKey="waveHeight"
                    data={nearestWeatherHour}
                    showAllSources={resultsFromAllSources}
                  />
                  <WeatherResultRow
                    dataKey="waveDirection"
                    data={nearestWeatherHour}
                    showAllSources={resultsFromAllSources}
                    isDirection
                  />
                  <WeatherResultRow
                    dataKey="wavePeriod"
                    data={nearestWeatherHour}
                    showAllSources={resultsFromAllSources}
                  />
                  <WeatherResultRow
                    dataKey="currentSpeed"
                    data={nearestWeatherHour}
                    showAllSources={resultsFromAllSources}
                  />
                  <WeatherResultRow
                    dataKey="currentDirection"
                    data={nearestWeatherHour}
                    showAllSources={resultsFromAllSources}
                    isDirection
                  />
                  <ResultRow
                    rowName="Nearest Tide Station"
                    data={formatTideStation(
                      results.tidal_extremes.meta.station
                    )}
                    preferredData={['', '']}
                    showAllSources
                    units=""
                    isDirection={false}
                    noSource
                  />
                  <ResultRow
                    rowName="Tidal Extreme"
                    data={formatTideData(
                      nearestTideData,
                      results.tidal_extremes.meta
                    )}
                    preferredData={['', '']}
                    showAllSources
                    units=""
                    isDirection={false}
                    noSource
                  />
                </TableBody>
              </Table>
              <Collapse in={marineTimeSeriesOpen}>
                <Box sx={{ padding: 1 }}>
                  <Select
                    value={selectedMarineGraphOption}
                    onChange={(e) => {
                      setSelectedMarineGraphOption(
                        e.target.value as keyof typeof WeatherResultName
                      );
                    }}
                    fullWidth
                  >
                    {marineGraphOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {WeatherResultName[option]}
                      </MenuItem>
                    ))}
                  </Select>
                  <TimeSeriesLineGraph
                    title={
                      WeatherResultName[
                        selectedMarineGraphOption
                      ] as keyof WeatherHourlyData
                    }
                    data={[results.weather.hours]}
                    xAxisKey="time"
                    yScale={WeatherUnits[selectedMarineGraphOption]?.yScale}
                    yLabel={WeatherUnits[selectedMarineGraphOption].yLabel}
                    // use the Stormglass source as it will always be present
                    yAxisKeys={[
                      {
                        key: `${selectedMarineGraphOption}.sg`,
                        label: WeatherResultName[selectedMarineGraphOption],
                      },
                    ]}
                    selectedDate={
                      selectedDate?.getTime() ||
                      new Date(
                        results.weather.hours[
                          results.weather.hours.length - 1
                        ].time
                      ).getTime()
                    }
                    onDateChange={(date: number) => {
                      setSelectedDate(new Date(date));
                    }}
                    hidePoints
                  />
                </Box>
              </Collapse>
            </CardContent>
            <CardActions>
              <Button
                onClick={() => {
                  setMarineTimeSeriesOpen(!marineTimeSeriesOpen);
                }}
                title={
                  marineTimeSeriesOpen ? 'Hide Time Series' : 'Show Time Series'
                }
                fullWidth
                variant="contained"
              >
                {marineTimeSeriesOpen ? 'Hide Time Series' : 'Show Time Series'}
              </Button>
            </CardActions>
          </Card>
        )}
        {nearestAstronomyData && (
          <Card>
            <CardHeader
              titleTypographyProps={{
                align: 'left',
                fontSize: '1.2rem',
                fontWeight: 500,
              }}
              title="Other"
              avatar={<WbTwilightRounded />}
            />
            <CardContent>
              <Table padding="none">
                <colgroup>
                  <col style={{ width: '60%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '10%' }} />
                </colgroup>
                <TableBody>
                  <ResultRow
                    rowName="Sunrise"
                    data={[]}
                    preferredData={[
                      '',
                      new Date(
                        nearestAstronomyData.sunrise
                      ).toLocaleTimeString(),
                    ]}
                    showAllSources={false}
                    units=" (UTC)"
                    isDirection={false}
                  />
                  <ResultRow
                    rowName="Sunset"
                    data={[]}
                    preferredData={[
                      '',
                      new Date(
                        nearestAstronomyData.sunset
                      ).toLocaleTimeString(),
                    ]}
                    showAllSources={false}
                    units=" (UTC)"
                    isDirection={false}
                  />
                  <ResultRow
                    rowName="Bathymetry"
                    data={[]}
                    preferredData={[
                      'gebco',
                      results.elevation.data.elevation.toFixed(2),
                    ]}
                    showAllSources={false}
                    units=""
                    isDirection={false}
                    noSource
                  />
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardHeader
            title="Time Slider"
            titleTypographyProps={{
              align: 'left',
              fontWeight: 500,
              fontSize: '1.2rem',
            }}
            avatar={<AccessTimeRounded />}
          />
          <CardContent>
            <Typography variant="body1" align="left" padding={1}>
              Displayed Time: {selectedDate?.toLocaleString()}
            </Typography>
            <Slider
              value={selectedDate?.getTime() || 0}
              onChange={(e, value) => {
                if (!value || !dateOptions) return;
                const date = new Date(value as number);
                setSelectedDate(date);
              }}
              min={dateOptions[0]?.getTime() || 0}
              max={dateOptions[dateOptions.length - 1]?.getTime() || 0}
              step={1000 * 60 * 60}
              marks={dateOptions.map((date) => ({
                value: date.getTime(),
                label: null,
              }))}
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title="Misc"
            titleTypographyProps={{
              align: 'left',
              fontWeight: 500,
              fontSize: '1.2rem',
            }}
            avatar={<ArticleRounded />}
          />
          <CardContent>
            <FormControlLabel
              label="Display Results From All Sources"
              labelPlacement="start"
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: 0,
              }}
              control={
                <Switch
                  onChange={() =>
                    setResultsFromAllSources(!resultsFromAllSources)
                  }
                  value={resultsFromAllSources}
                />
              }
            />
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                donwloadCsv(results);
              }}
              startIcon={<Download />}
              title="Download CSV"
              fullWidth
              variant="contained"
            >
              Download CSV
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </Box>
  );
}

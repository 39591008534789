import { setSelectedVessels } from '../../../dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import store from '../../../store';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

export function clearFocusRing() {
  MapHelpers.deleteSource(MapLayer.VESSEL_FOCUS_RING);
}

const clearSelectedVessels = () => {
  const { selectedVessels } = store.getState().vesselDossier;

  clearFocusRing();

  if (selectedVessels) {
    store.dispatch(setSelectedVessels(null));
  }
};

export default clearSelectedVessels;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Button, Tooltip, Typography } from '@mui/material';
import LayerToggle, {
  LayerToggleProps,
} from '../../../common-components/layer-toggle/layer-toggle';
import { EMenuIcons, MenuIcons } from '../../menu.slice';

interface SideMenuItemProps {
  itemName: string;
  iconName: EMenuIcons;
  menuOpen: boolean;
  selected: boolean;
  onClick: (() => void) | undefined;
  layerToggleProps: LayerToggleProps;
  itemDisabled?: boolean;
}

function SideMenuItem(props: SideMenuItemProps) {
  const {
    itemName,
    iconName,
    menuOpen,
    onClick,
    selected,
    layerToggleProps,
    itemDisabled,
  } = props;

  const Icon = MenuIcons[iconName];

  const generateTooltip = () => {
    if (itemDisabled) {
      return 'You require a NorthStandard account to access these features.';
    }
    if (!menuOpen) {
      return itemName;
    }
    return '';
  };

  return (
    <Tooltip title={generateTooltip()} placement="right">
      <Box sx={{ position: 'relative', paddingInline: '8px' }}>
        <Button
          title={itemName}
          data-testid="side-menu-item"
          className="side-menu-item"
          sx={{
            padding: '0.8rem 0',
            width: '100%',
            minWidth: 0,
            justifyContent: menuOpen ? 'left' : 'center',
            color: 'secondary.main',
            '&:hover': {
              backgroundColor: 'secondary.dark',
            },
            backgroundColor: selected ? 'secondary.dark' : 'inherit',
          }}
          onClick={onClick}
          disabled={itemDisabled}
        >
          {typeof Icon === 'string' ? (
            <img
              src={Icon}
              alt="icon"
              style={{
                height: '1.5rem',
                width: '1.5rem',
                marginInlineStart: menuOpen ? '1rem' : '0rem',
                // Provided SVG Icons weren't playing nice with the theme
                // We set them to black and use a CSS filter to change the color
                // https://stackoverflow.com/questions/22252472/how-can-i-change-the-color-of-an-svg-element
                // https://codepen.io/sosuke/pen/Pjoqqp
                filter: itemDisabled
                  ? 'invert(81%) sepia(5%) saturate(756%) hue-rotate(181deg) brightness(90%) contrast(81%)'
                  : 'invert(83%) sepia(36%) saturate(787%) hue-rotate(78deg) brightness(100%) contrast(97%)',
              }}
            />
          ) : (
            <Icon
              sx={{ marginInlineStart: menuOpen ? '1rem' : '0rem' }}
              color="inherit"
            />
          )}
          {menuOpen && (
            <Typography className="menu-item" variant="body2">
              {itemName}
            </Typography>
          )}
        </Button>
        {menuOpen && layerToggleProps && layerToggleProps.layers && (
          <Box
            data-testid="side-menu-item-toggle"
            sx={{ position: 'absolute', right: '1.5rem', top: '0.9rem' }}
          >
            {!layerToggleProps.disabled && (
              <LayerToggle
                isSideMenuToggle
                label={itemName}
                {...layerToggleProps}
                sx={{ padding: '0' }}
              />
            )}
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}

SideMenuItem.defaultProps = {
  itemDisabled: false,
};

export default SideMenuItem;

import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

function addBathymetrySource(layer: MapLayer | string) {
  MapHelpers.addSource(layer, {
    type: 'vector',
    url: 'mapbox://mapbox.9tm8dx88',
  });
}

export default addBathymetrySource;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import update, { Spec } from 'immutability-helper';
import { Port } from '../../maritime-menu-options/world-ports-panel/world-ports.model';
import {
  RIContacts,
  RISecurityEmergencyUnits,
} from '../../models/risk_intelligence.model';

export const EMERGENCY = 'Emergency';
export const CONTACTS = 'Contacts';

export interface Details {
  title: typeof CONTACTS | typeof EMERGENCY;
  name: string;
  items: RIContacts[] | RISecurityEmergencyUnits[] | null;
}

interface PortsState {
  loading: boolean;
  error: boolean;
  ports: Port[] | null;
  selectedPort: Port | null;
  selectedPortId: number | string | null;
  timestamp: number;
}

export const INITIAL_PORTS_STATE: PortsState = {
  loading: false,
  error: false,
  ports: null,
  selectedPort: null,
  selectedPortId: null,
  timestamp: Date.now(),
};

const PortsSlice = createSlice({
  name: 'ports',
  initialState: INITIAL_PORTS_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean | null>) => {
      state.loading = action.payload ?? true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setPorts: (state, action: PayloadAction<Port[] | null>) => {
      state.ports = action.payload;
      state.loading = false;
      state.error = false;

      if (state.selectedPort) {
        // If a port is currently selected and the port data changes
        // We need to update the selected port with the new data, or else
        // deselect it if it no longer exists
        const comparePortByRiId = (port: Port) =>
          port.ri?.id === Number(state.selectedPort?.ri?.id);
        const comparePortByUnLocode = (port: Port) =>
          port.UNLOCODE?.replace(/\s/, '').toLowerCase() ===
          state.selectedPort!.UNLOCODE.replace(/\s/, '').toLowerCase();

        state.selectedPort =
          state.ports?.find(comparePortByRiId) ||
          state.ports?.find(comparePortByUnLocode) ||
          null;
      }
    },
    setSelectedPortId: (
      state,
      action: PayloadAction<number | string | null>
    ) => {
      // Sometimes we wish to inform the ports menu that a specific port is selected before the ports have been populated
      // For example if the port is accessed by Country>Port
      state.selectedPortId = action.payload;
    },
    setSelectedPort: (
      state,
      action: PayloadAction<Port | undefined | null>
    ) => {
      // if a new port to select is provided, set it (with selected:true)
      if (action.payload) {
        state.selectedPort = {
          ...action.payload,
          selected: true,
        };
      } else {
        // otherwise, set selected to null
        state.selectedPort = null;
      }
      // if (somehow) ports are not yet populated, skip updating the ports array
      if (!state.ports) {
        return;
      }
      const prevIndex = state.ports.findIndex((port) => port.selected);
      const updateObject: Spec<typeof state.ports> = {};
      let index = -1;
      // if the payload is not null, set the new incident as selected
      if (action.payload) {
        index = state.ports.findIndex(
          (port) => port.WPI === (action.payload as Port).WPI
        );
        // update to set selected only if the index is valid and different from the previous index
        if (index > -1 && prevIndex !== index) {
          updateObject[index] = {
            selected: { $set: true },
          };
        }
      }
      // whether or not a new port was provided, we need to de-select the previous port (but only if it was different from the new port)
      if (prevIndex > -1 && prevIndex !== index) {
        updateObject[prevIndex] = {
          selected: { $set: false },
        };
      }
      // Only run updated if there is something to update
      if (Object.keys(updateObject).length > 0) {
        state.ports = update(state.ports, updateObject);
      }
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setLoading,
  setError,
  setPorts,
  setSelectedPort,
  setSelectedPortId,
} = PortsSlice.actions;

export default PortsSlice.reducer;

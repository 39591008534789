/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TD, TR, Table } from '@ag-media/react-pdf-table';
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { parseISO, subYears } from 'date-fns';

import {
  IncidentFilters,
  IncidentType,
} from '../maritime-menu-options/incidents-panel/incident.model';
import { RIStats } from '../models/risk_intelligence.model';
import store from '../store';
import DateTimeHelpers from '../utils/date-time-helpers.utils';
import {
  getIncidentAreaTitleById,
  getIncidentRegionTitleById,
  getIncidentTargetTypeTitleById,
  getIncidentTypeTitleById,
  groupIncidentTypesByGroup,
} from '../utils/incidents-helpers';
import RIFooter from './RI-Footer';
import RIHeader from './RI-Header';
import { chartsCleanup, getBarChartImage, getDonutChartImage } from './charts';
import styles from './pdf-styles';

// Create styles
const statisticStyles = StyleSheet.create({
  image: {
    maxWidth: '50%',
    maxHeight: '200px',
    height: 'auto',
    width: 'auto',
  },
  tableStyle: {
    border: 'none',
    fontSize: 12,
  },
  bold: {
    fontWeight: 600,
  },
  centred: {
    justifyContent: 'center',
  },
});

interface TableRowData {
  yearMonth: [number, number];
  incidentTypeGroupTotals: number[];
  monthTotal: number;
}

export const getTotalsForIncidentTypeGroup = (
  incidentTypeGroups: Record<string, IncidentType[]>,
  statObjectForMonth: RIStats
) =>
  Object.entries(incidentTypeGroups).map(([_, incidentTypes]) => {
    const incidentTypeIds = incidentTypes.map((type) => type.id);
    const incidentTypesInThisGroup = statObjectForMonth.data?.filter(
      (item) => item.label?.id && incidentTypeIds.includes(item.label?.id)
    );
    if (!incidentTypesInThisGroup || incidentTypesInThisGroup.length === 0) {
      return 0;
    }
    const incidentTotalInGroup = incidentTypesInThisGroup.reduce(
      (acc, curr) => acc + curr.statistics.count,
      0
    );
    return incidentTotalInGroup;
  });

/**
 * Given a set of tuples of year/month buckets, an array of incident type groups, and an array of RIStats, build a matrix of the incident counts for each year/month/incident type group
 * The RI API only returns years and months that return incidents for the filters set by the user.
 * We need to fill in the gaps, since a table with :
 *  "June 2022"         x x x x x
 *  "Septempber 2022"   x x x x x
 * could be confusing to read.
 * @param yearMonths and array of [year,month] tuples, e.g. [[2020,1],[2020,2],[2020,3]]. This defines the height of the matrix
 * @param incidentTypesGroups the RI incident type groups. Ri groups similar incident types together, e.g. "Hijacking at sea" and "Hijacking at anchorage" are grouped together.
 * @param data RI Data. This defines the values of the matrix
 */
export const buildMatrix = (
  yearMonths: [number, number][],
  incidentTypeGroups: Record<string, IncidentType[]>,
  data: RIStats[]
): TableRowData[] => {
  const matrix = yearMonths.map((yearMonth) => {
    const [year, month] = yearMonth;
    const statObjectForYear = data.find((stat) => stat.label?.id === year);
    // if we can't find the requested year in the data, return an array of 0s
    if (!statObjectForYear || !statObjectForYear.data) {
      return {
        yearMonth,
        incidentTypeGroupTotals: Object.keys(incidentTypeGroups).map(() => 0),
        monthTotal: 0,
      };
    }
    const statObjectForMonth = statObjectForYear.data.find(
      (stat) => stat.label?.id === month
    );
    // if we can't find the requested month in the data, return an array of 0s
    if (!statObjectForMonth || !statObjectForMonth.data) {
      return {
        yearMonth,
        incidentTypeGroupTotals: Object.keys(incidentTypeGroups).map(() => 0),
        monthTotal: 0,
      };
    }
    return {
      yearMonth,
      incidentTypeGroupTotals: getTotalsForIncidentTypeGroup(
        incidentTypeGroups,
        statObjectForMonth
      ),
      monthTotal: statObjectForMonth.statistics.count,
    };
  });
  return matrix;
};

export const buildFilterTable = (filters: Omit<IncidentFilters, 'open'>) => {
  const start =
    filters.startDate || DateTimeHelpers.dateToIsoDate(subYears(new Date(), 1));
  const end = filters.endDate || DateTimeHelpers.dateToIsoDate(new Date());
  const areaNames = filters.areas
    .map((areaId) => getIncidentAreaTitleById(areaId))
    .join(', ');
  const incidentTypeNames = filters.incidentTypes
    .map((typeId) => getIncidentTypeTitleById(typeId))
    .join(', ');
  const regionsNames = filters.regions
    .map((regionId) => getIncidentRegionTitleById(regionId))
    .join(', ');
  const targetTypes = filters.targets
    .map((targetId) => getIncidentTargetTypeTitleById(targetId))
    .join(', ');

  const tableContent = [
    ['Dates', `${start} - ${end}`],
    ['Regions', regionsNames || '-'],
    ['Areas', areaNames || '-'],
    ['Types', incidentTypeNames || '-'],
    ['Target Types', targetTypes || '-'],
  ];
  return (
    <View style={{ margin: '0 40px', fontFamily: 'Helvetica', fontSize: '12' }}>
      <Text style={styles.title}>Incident Statistics</Text>
      <Table style={{ border: 'none' }} weightings={[0.25, 0.75]}>
        {/* react-pdf-table doesn't automatically do striped rows, so we need to do it manually */}
        {tableContent.map(([title, text], index) => (
          <TR
            key={title}
            style={{ backgroundColor: index % 2 ? '#f8f8f8' : '' }}
          >
            <TD>{title}</TD>
            <TD>{text}</TD>
          </TR>
        ))}
      </Table>
    </View>
  );
};

export const buildStatsTable = (
  incidentTypeGroups: Record<string, IncidentType[]>,
  dataMatrix: TableRowData[],
  totalsByIncidentTypeGroup: number[]
) => {
  // All incident types in a group have the same icons, so we can just use the first one
  const headerItems = Object.values(incidentTypeGroups).map(([first]) => (
    <TD key={first.id} style={statisticStyles.centred}>
      <Image
        style={{ height: '25px', width: '20px' }}
        src={`data:image/png;base64, ${first.icons.marker}`}
      />
    </TD>
  ));
  const header = (
    <TR style={{ marginBottom: '3px' }}>
      <TD />
      {headerItems}
      <TD style={{ justifyContent: 'center' }}>Total</TD>
    </TR>
  );
  let grandTotal = 0;
  const rows = dataMatrix.map((tableRowData) => {
    const rowLabel = `${DateTimeHelpers.getMonthName(
      navigator.language,
      tableRowData.yearMonth[1],
      'short'
    )} ${tableRowData.yearMonth[0]}`;
    grandTotal += tableRowData.monthTotal;
    return (
      <TR key={rowLabel} style={{ margin: '3px 0' }}>
        <TD>{rowLabel}</TD>
        {tableRowData.incidentTypeGroupTotals.map((total, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <TD key={`${rowLabel}-${index}`} style={statisticStyles.centred}>
            {total}
          </TD>
        ))}
        <TD style={{ ...statisticStyles.bold, ...statisticStyles.centred }}>
          {tableRowData.monthTotal}
        </TD>
      </TR>
    );
  });

  const footer = (
    <TR style={statisticStyles.bold}>
      <TD>Total</TD>
      {totalsByIncidentTypeGroup.map((total, index) => (
        <TD
          /* eslint-disable-next-line react/no-array-index-key */
          key={`total-${index}`}
          style={statisticStyles.centred}
        >
          {total}
        </TD>
      ))}
      <TD style={statisticStyles.centred}>{grandTotal}</TD>
    </TR>
  );

  const weightings = [
    1.5,
    ...Object.keys(incidentTypeGroups).map(() => 0.7),
    1,
  ];

  return (
    <View style={{ margin: '20px' }}>
      <Table
        style={{ ...statisticStyles.tableStyle, fontSize: 10 }}
        weightings={weightings}
      >
        {header}
        {rows}
        {footer}
      </Table>
    </View>
  );
};

export const dataMatrixToBarChartDataSet = (
  dataMatrix: TableRowData[],
  incidentTypeGroups: Record<string, IncidentType[]>
) => {
  const labels = dataMatrix.map((row) => {
    const [year, month] = row.yearMonth;
    return `${DateTimeHelpers.getMonthName(
      navigator.language,
      month,
      'short'
    )} ${year}`;
  });
  const datasets = [];
  const incidentTypeGroupNames = Object.keys(incidentTypeGroups);
  // Chart.js needs the data in a different format to react-pdf-table
  // Transpose the data matrix to group it by incident type group
  const justData = dataMatrix.map((row) => row.incidentTypeGroupTotals);
  const transposedData = justData[0].map((_, i) =>
    justData.map((row) => row[i])
  );
  for (let i = 0; i < transposedData.length; i += 1) {
    const incidentTypeGroup = incidentTypeGroupNames[i];
    datasets.push({
      label: incidentTypeGroup,
      data: transposedData[i],
      backgroundColor: incidentTypeGroups[incidentTypeGroup][0].group.colour,
    });
  }

  return {
    labels,
    datasets,
  };
};

export const dataMatrixToDonutChartDataSet = (
  totalsByIncidentTypeGroup: number[],
  incidentTypeGroups: Record<string, IncidentType[]>
) => {
  const labels = Object.keys(incidentTypeGroups);
  const totalDataPoints = totalsByIncidentTypeGroup.reduce(
    (acc, curr) => acc + curr,
    0
  );
  const labelsWithPercentage = labels.map((label, index) => {
    const percentage = (
      (totalsByIncidentTypeGroup[index] / totalDataPoints) *
      100
    ).toFixed(1);
    return `${label} (${percentage}%)`;
  });
  const datasets = [
    {
      label: 'Total over period',
      data: totalsByIncidentTypeGroup,
      backgroundColor: Object.values(incidentTypeGroups).map(
        ([first]) => first.group.colour
      ),
    },
  ];

  return {
    labels: labelsWithPercentage,
    datasets,
  };
};

interface IncidentStatsExportProps {
  stats: RIStats;
  filters: Omit<IncidentFilters, 'open'>;
}

export const incidentStatsExport = async (props: IncidentStatsExportProps) => {
  const { stats, filters } = props;
  const { incidentTypes } = store.getState().incidents;
  if (!incidentTypes) {
    throw Error('Failed to acquire incident types');
  }
  if (!stats.data || stats.data.length === 0) {
    throw Error('No data to export');
  }
  const incidentTypeGroups = groupIncidentTypesByGroup(incidentTypes);
  // The RI API returns only years and months that have incidents.
  // We need to know exactly how many months and years are in the interval to fill in the gaps.
  const start = filters.startDate
    ? parseISO(filters.startDate)
    : subYears(new Date(), 1);
  const end = filters.endDate ? parseISO(filters.endDate) : new Date();
  const yearsAndMonths = DateTimeHelpers.getMonthsYearsInInterval(start, end);
  const dataMatrix = buildMatrix(
    yearsAndMonths,
    incidentTypeGroups,
    stats.data
  );
  const totalsByIncidentTypeGroup = dataMatrix.reduce<number[]>((acc, curr) => {
    // if this is the first row, just return the incident totals
    if (!acc || acc.length === 0) {
      return curr.incidentTypeGroupTotals;
    }
    // all the dataMatrix rows should have the same length, so we can just add them up
    return acc.map(
      (total, index) => total + curr.incidentTypeGroupTotals[index]
    );
  }, []);

  const barChartData = dataMatrixToBarChartDataSet(
    dataMatrix,
    incidentTypeGroups
  );
  const donutChartData = dataMatrixToDonutChartDataSet(
    totalsByIncidentTypeGroup,
    incidentTypeGroups
  );

  const chartPromises = [
    getDonutChartImage(
      donutChartData,
      'Aggregate Incidents by Type over Time Period'
    ),
    getBarChartImage(barChartData),
  ];

  const [donutChartImage, barChartImage] = await Promise.all(chartPromises);

  // we've obtained the chart images so can remove the canvases
  chartsCleanup();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <RIHeader />
        <View
          style={{
            ...styles.section,
            flex: '1 1',
            flexDirection: 'column',
          }}
        >
          {buildFilterTable(filters)}
          {buildStatsTable(
            incidentTypeGroups,
            dataMatrix,
            totalsByIncidentTypeGroup
          )}
        </View>
        <View
          style={{
            ...styles.section,
            margin: '20 20px 20',
            flex: '1 1',
            flexDirection: 'column',
          }}
          wrap={false}
        >
          {/* chartImage already includes the "data:image/png;base64," prefix */}
          <Image style={{ margin: '20 auto 20' }} src={donutChartImage} />
          <Image style={{ margin: '20 auto 20' }} src={barChartImage} />
        </View>

        <RIFooter />
      </Page>
    </Document>
  );
};

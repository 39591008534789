import { useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useSignedUrl } from '../../hooks';

type BaseProps = {
  url: URL;
  signUrl: boolean;
  onConnectionOpened?: (event: Event) => void;
  onConnectionClosed?: (event: CloseEvent) => void;
  onConnectionError?: (event: Event) => void;
};

interface PropsJson extends BaseProps {
  onMessageReceivedJson: (message: unknown) => void;
  onMessageReceived?: never;
}

interface Props extends BaseProps {
  onMessageReceived: (message: MessageEvent<unknown>) => void;
  onMessageReceivedJson?: never;
}

export type WebsocketProps = Props | PropsJson;

// how many seconds we should wait before warning the user that a websocket is taking a long time to load
export const WEBSOCKET_SLOW_WARNING = 15 * 1000;
export const WEBSOCKET_SLOW_ERROR = 30 * 1000;

function WebSocket({
  url,
  signUrl = false,
  onMessageReceived,
  onMessageReceivedJson,
  onConnectionOpened,
  onConnectionClosed,
  onConnectionError,
}: Props | PropsJson) {
  // returns the normal url if signUrl is false
  const signedUrl = useSignedUrl(url.toString(), signUrl);

  const { lastMessage, lastJsonMessage } = useWebSocket(
    signedUrl && signedUrl.toString(),
    {
      onError: onConnectionError,
      onOpen: onConnectionOpened,
      onClose: onConnectionClosed,
    }
  );

  useEffect(() => {
    if (lastMessage) {
      onMessageReceived?.(lastMessage);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (lastJsonMessage) {
      onMessageReceivedJson?.(lastJsonMessage);
    }
  }, [lastJsonMessage]);

  return null;
}

export default WebSocket;

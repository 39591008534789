import { GeoJSONSource } from 'mapbox-gl';
import { Port } from '../../../maritime-menu-options/world-ports-panel/world-ports.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

function setPortFeatures(layerId: MapLayer | string, ports: Port[]) {
  const features = GeoHelper.portsToGeoJSONFeatures(ports);
  const geojson = GeoHelper.createGeoJSON(features);
  (MapHelpers.getSource(layerId) as GeoJSONSource)?.setData(geojson);
}

export default setPortFeatures;

import { ArrowBack, FilterAltOutlined } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  Paper,
  TextField,
} from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useMobile } from '../hooks';
import { EMenuItems, setSelectedOption } from '../main-menu/menu.slice';
import { setIncidentFilters } from '../maritime-menu-options/incidents-panel/incident-panel.slice';
import SearchPanel from './search-panel';
import { setPanelStatus } from './search-panel.slice';

import useAccessControl from '../hooks/access-control/useAccessControl';
import { DMSToDecimal } from '../map/map-controls.utils';
import MapHelpers from '../map/map.utils';
import { Port } from '../maritime-menu-options/world-ports-panel/world-ports.model';
import { DocumentWithMeta, EntityType } from '../models/document';
import { SearchableData } from '../models/search';
import './search-bar.scss';

function FiltersPanel(props: { toggleFiltersOpen: () => void }) {
  const { toggleFiltersOpen } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <List className="filterList">
      <ListItemButton
        onClick={() => {
          dispatch(setIncidentFilters({ open: true }));
          dispatch(setSelectedOption(EMenuItems.INCIDENTS));
          dispatch(setPanelStatus({ panelOpen: false }));
          toggleFiltersOpen();
          navigate('/incidents');
        }}
      >
        Incidents
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(setSelectedOption(EMenuItems.PORTS));
          dispatch(setPanelStatus({ panelOpen: false }));
          toggleFiltersOpen();
          navigate('/ports');
        }}
      >
        Ports
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(setSelectedOption(EMenuItems.COUNTRIES));
          dispatch(setPanelStatus({ panelOpen: false }));
          toggleFiltersOpen();
          navigate('/countries');
        }}
      >
        Countries
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(setSelectedOption(EMenuItems.BOUNDARIES));
          dispatch(setPanelStatus({ panelOpen: false }));
          toggleFiltersOpen();
          navigate('/boundaries');
        }}
      >
        Boundaries
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(setSelectedOption(EMenuItems.DRAWINGS));
          dispatch(setPanelStatus({ panelOpen: false }));
          toggleFiltersOpen();
          navigate('/drawings');
        }}
      >
        Drawings
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(setSelectedOption(EMenuItems.DOCUMENTS));
          dispatch(setPanelStatus({ panelOpen: false }));
          toggleFiltersOpen();
          navigate('/documents');
        }}
      >
        Documents
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(setSelectedOption(EMenuItems.FIRST_CALL_PORTS));
          dispatch(setPanelStatus({ panelOpen: false }));
          toggleFiltersOpen();
          navigate('/first-call-ports');
        }}
      >
        First Call Ports
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          dispatch(setSelectedOption(EMenuItems.CORRESPONDENTS));
          dispatch(setPanelStatus({ panelOpen: false }));
          toggleFiltersOpen();
          navigate('/correspondents');
        }}
      >
        Correspondents
      </ListItemButton>
    </List>
  );
}

function SearchBar({ inline = false }: { inline?: boolean }) {
  const panelStatus = useAppSelector((state) => state.searchPanel.panelStatus);
  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const searchIncidents = useAppSelector(
    (state) => state.incidents.searchIncidents
  );
  const ports = useAppSelector((state) => state.ports.ports);
  const myDocuments = useAppSelector((state) => state.documents.myDocuments);
  const countries = useAppSelector((state) => state.countries.countries);
  const boundaries = useAppSelector((state) => state.boundaries.boundaries);
  const drawings = useAppSelector((state) => state.drawings.drawings);
  const maritimeAreas = useAppSelector((state) => state.riMaritimeAreas.areas);
  const firstCallPorts = useAppSelector(
    (state) => state.firstCallPorts.firstCallPorts
  );
  const correspondents = useAppSelector(
    (state) => state.correspondents.correspondents
  );
  const privileges = useAccessControl();

  const portFilter = (port: Port) => {
    const riAccess = privileges.canAccessPorts.riskIntelligence;

    return riAccess && port.ri
      ? (port.ri.cruise && riAccess.cruise) ||
          (port.ri.inland && riAccess.land) ||
          (!port.ri.inland && !port.ri.cruise && riAccess.coastal)
      : privileges.canAccessPorts.wpi;
  };

  const searchablePorts = ports?.filter(portFilter);

  const portDocumentFilter = (document: DocumentWithMeta) => {
    if (document.metadata.entity_type !== EntityType.port) return false;

    const matchingPort = ports?.find(
      (port) =>
        port.ri?.id === Number(document.metadata.entity_id) ||
        port.UNLOCODE === document.metadata.entity_id
    );
    return matchingPort ? portFilter(matchingPort) : false;
  };

  const searchableDocuments = (myDocuments || []).filter((document) => {
    switch (document.metadata.entity_type) {
      case EntityType.port:
        return portDocumentFilter(document);
      case EntityType.riArea:
        return privileges.canAccessRiMaritimeAreas;
      default:
        return true;
    }
  });

  const searchableData: SearchableData = {
    ports: searchablePorts || null,
    documents: searchableDocuments,
    countries,
    boundaries: privileges.canAccessBoundaries ? boundaries : null,
    drawings,
    maritimeAreas: privileges.canAccessRiMaritimeAreas ? maritimeAreas : null,
    incidents:
      searchIncidents && privileges.canAccessIncidents
        ? Object.values(searchIncidents.byId)
        : null,
    firstCallPorts,
    correspondents,
  };

  const isMobile = useMobile();

  const paperRef = useRef<HTMLDivElement>(null);

  const [query, setQuery] = useState('');
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const clickSearchPanel = () => {
    dispatch(setPanelStatus({ panelOpen: true }));
    inputRef.current?.focus();
  };

  const toggleFiltersOpen = () => {
    setFiltersOpen(!filtersOpen);
    if (!panelStatus.panelOpen) {
      clickSearchPanel();
    }
  };

  // Debounce query, and don't query until 3 characters, to avoid typing lag
  useEffect(() => {
    if (query.length > 2) {
      const timeoutId = setTimeout(() => {
        setDebouncedQuery(query);
      }, 300);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    setDebouncedQuery('');

    return () => {};
  }, [query]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.trim();

    const dmsPattern = /(\d+° \d+' \d+" [NS]) (\d+° \d+' \d+" [EW])/;
    const latLongPattern =
      /^(-?([1-8]?\d(\.\d+)?|90(\.0+)?)),\s*(-?(1[0-7]\d(\.\d+)?|180(\.0+)?|\d{1,2}(\.\d+)?))$/;

    const matchesDms = inputValue.match(dmsPattern);
    const matchesLatLng = inputValue.match(latLongPattern);

    if (matchesDms && matchesDms.length === 3) {
      const [latitude, longitude] = [
        DMSToDecimal(matchesDms[1]),
        DMSToDecimal(matchesDms[2]),
      ];

      if (latitude && longitude) {
        if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
          MapHelpers.flyTo({
            center: [longitude, latitude],
            zoom: 7,
          });
        }
      }
    } else if (matchesLatLng) {
      const [latitude, longitude] = inputValue.split(',').map(parseFloat);

      if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
        MapHelpers.flyTo({
          center: [longitude, latitude],
          zoom: 7,
        });
      }
    }
    setQuery(e.target.value);
  };

  return (
    <Paper
      ref={paperRef}
      className={classNames('search-bar-container', {
        inline,
        open: panelStatus.panelOpen,
        closed: !panelStatus.panelOpen,
      })}
      onBlur={(e) => {
        // catch an onBlur event from the search bar, and close the search panel
        // but not if the onBlur event is from the one of the search panel's children
        if (!paperRef.current?.contains(e.relatedTarget as Node)) {
          dispatch(setPanelStatus({ panelOpen: false }));
          setFiltersOpen(false);
        }
      }}
      elevation={3}
      sx={{
        borderRadius: '1.75rem',
        transition: 'max-height 0.3s ease-in-out, width 0.3s ease-in-out',
        ...(isMobile && {
          borderRadius: 0,
        }),
      }}
    >
      <TextField
        ref={inputRef}
        label={query ? '' : 'Search'}
        onChange={handleOnChange}
        onClick={clickSearchPanel}
        data-testid="Search"
        variant="standard"
        margin="dense"
        autoComplete="off"
        InputLabelProps={{
          shrink: false,
          disableAnimation: true,
          sx: { top: '-0.75rem', left: '3.25rem' },
        }}
        disabled={filtersOpen}
        InputProps={{
          inputProps: {
            'data-testid': 'SearchInput',
          },
          disableUnderline: true,
          sx: {
            marginTop: '0 !important',
          },

          startAdornment: (
            <InputAdornment position="start">
              {filtersOpen ? (
                <IconButton onClick={() => toggleFiltersOpen()}>
                  <ArrowBack />
                </IconButton>
              ) : (
                <Box sx={{ width: '40px', height: '40px' }} />
              )}
            </InputAdornment>
          ),

          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ marginRight: '1rem', gap: '1rem', color: 'secondary.main' }}
            >
              <SearchIcon />
              <IconButton
                onClick={toggleFiltersOpen}
                sx={{
                  '&:hover': { bgcolor: 'secondary.dark' },
                  color: 'secondary.main',
                }}
              >
                <FilterAltOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          margin: '0.5rem',
          width: '100%',
          padding: '0.25rem',
        }}
      />
      {panelStatus.panelOpen &&
        (filtersOpen ? (
          <FiltersPanel toggleFiltersOpen={toggleFiltersOpen} />
        ) : (
          <SearchPanel searchableData={searchableData} query={debouncedQuery} />
        ))}
    </Paper>
  );
}

SearchBar.defaultProps = {
  inline: false,
};

export default SearchBar;

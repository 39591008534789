import {
  Box,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Modal,
  SwipeableDrawer,
  SwipeableDrawerProps,
  Typography,
  styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMobile } from '../../hooks';

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

interface SimpleDialogProps {
  title: string;
  open: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  width?: number;
  onClose: () => void;
  onOpen?: () => void;
  'data-testid'?: string;
}

export default function SimpleDialog(
  props: SimpleDialogProps & (DialogProps | SwipeableDrawerProps)
) {
  const isMobile = useMobile();

  const {
    onClose,
    onOpen,
    title,
    open,
    children,
    actions,
    width,
    'data-testid': testId,
  } = props;

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={open}
        onClose={onClose}
        onOpen={onOpen || (() => {})}
        anchor="bottom"
        disableSwipeToOpen
        disableDiscovery
      >
        <Puller />
        <DialogTitle sx={{ px: 2 }}>{title}</DialogTitle>
        <DialogContent sx={{ px: 2 }}>{children}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </SwipeableDrawer>
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      data-testid={testId || null}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          maxHeight: '80%',
          overflow: 'hidden',
          width: { width },
          bgcolor: 'primary.main',
          borderRadius: '20px',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column', // Ensure children container stretches vertically
          outline: 'none',
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          color="text.primary"
        >
          {title}
        </Typography>
        <Box
          sx={{
            flex: 1, // Allow the children container to expand and take remaining space
            overflowY: 'auto',
            scrollbarWidth: 'thin',
          }}
        >
          {children}
        </Box>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Box>
    </Modal>
  );
}

SimpleDialog.defaultProps = {
  actions: null,
  width: 400,
  onOpen: () => {},
  'data-testid': null,
};

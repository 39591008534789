import { Button, ListItem, Typography } from '@mui/material';
import { TravelAdvice } from '../travel-advice.model';

interface Props {
  travelAdvice: TravelAdvice;
}

const parseTravelAdviceDate = (date: string) => {
  let parsedDate;

  if (date.includes('/')) {
    const [day, month, year] = date.split('/');
    parsedDate = new Date(`${year}-${month}-${day}`);
  }

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  if (parsedDate) {
    return parsedDate.toLocaleDateString('en-GB', options);
  }
  return date;
};

function TravelAdviceItem({ travelAdvice }: Props) {
  const { link, title, updateDate } = travelAdvice;
  const openLinkClick = (_link: string) => {
    if (_link) {
      window.open(link);
    }
  };

  return (
    // role is not redundant since list-style: none removes the 'semanticity' of a list
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ListItem
      data-testid="travel-advice-item"
      sx={{ display: 'flex', width: '100%' }}
    >
      <div style={{ width: '100%' }}>
        <Typography sx={{ fontSize: '0.8rem' }}>
          {title}
          <br />
          <b>{parseTravelAdviceDate(updateDate)}</b>
        </Typography>

        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{
              backgroundColor: 'primary.main',
              color: 'secondary.light',
              textDecoration: 'underline',
            }}
            onClick={() => openLinkClick(link)}
          >
            Open Link
          </Button>
        </div>
      </div>
    </ListItem>
  );
}

export default TravelAdviceItem;

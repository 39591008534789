import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import { ExtendedMapEvent } from '../../map.utils';

/**
 * This function creates a click event handler for a Mapbox layer.
 *
 * @param onClick - A function that will be called when the layer is clicked.
 *                  This function should take a Mapbox MapMouseEvent or MapTouchEvent as its parameter.
 *
 * @returns A function that can be used as a click event handler for a Mapbox layer.
 *          This function takes a Mapbox MapMouseEvent or MapTouchEvent as its parameter.
 *          When the layer is clicked, it checks if the default action of the original event is prevented,
 *          or if the event is a drawing event (isDrawing property is true).
 *          If neither of these conditions is true, it calls the onClick function with the event as its parameter.
 */
export default function onLayerClick(
  onClick: (e: MapMouseEvent | MapTouchEvent) => void
) {
  return (event: MapMouseEvent | MapTouchEvent) => {
    if (
      event.originalEvent.defaultPrevented ||
      (event as ExtendedMapEvent).isDrawing === true ||
      (event as ExtendedMapEvent).layerClickHandled === true
    )
      return;

    onClick(event);
  };
}

export function stopLayerClickPropagation(
  event: MapMouseEvent | MapTouchEvent
) {
  // eslint-disable-next-line no-param-reassign
  (event as ExtendedMapEvent).layerClickHandled = true;
}

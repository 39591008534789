/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Box, Button } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import { useAppSelector, useMobile } from '../../hooks';
import { threatAssessmentExport } from '../../reporting/threat-assessment-export';
import { kebabify } from '../../utils/text-formatting.utils';
import ThreatAssessmentPanelContents from './threat-assessment-panel-contents/threat-assessment-panel-contents';
import './threat-assessment-panel.scss';
import useSelectedThreatAssessment from './use-selected-threat-assessment';

function ThreatAssessmentPanel() {
  const isMobile = useMobile();

  const { id } = useAppSelector(
    (store) => store.threatAssessment.selectedEntityThreatAssessmentInfo
  );

  const { loadingTA, error, threatAssessment } =
    useSelectedThreatAssessment(id);

  const canDownload = threatAssessment?.entity.type === 'area';

  return (
    <Box data-testid="threat-assessment-panel">
      {loadingTA && <LoadingPanel />}
      {!loadingTA && error && (
        <ErrorPanel message="Error loading threat assessment." />
      )}
      {!loadingTA && !error && threatAssessment && (
        <>
          <ThreatAssessmentPanelContents
            entity={threatAssessment.entity}
            country={threatAssessment.country}
            underRevision={threatAssessment.underRevision}
            summary={threatAssessment.summary}
            threats={threatAssessment.threats}
          />
          {canDownload && (
            <Box
              sx={{
                bottom: '2rem',
                left: '-15%',
                width: '100%',
                height: '3rem',
                ...(isMobile && {
                  left: 0,
                  bottom: 0,
                  position: 'absolute',
                }),
              }}
            >
              <Button
                className="pdf-download-button"
                variant="outlined"
                sx={{
                  borderRadius: '1.5rem',
                  m: '1rem 1.5rem',
                  borderColor: 'secondary.main',
                  width: '45%',
                  marginBottom: '1rem',
                  ...(isMobile && {
                    width: '100%',
                    margin: 0,
                  }),
                }}
              >
                <PDFDownloadLink
                  className="pdf-download-link"
                  style={{
                    textDecoration: 'none',
                  }}
                  document={threatAssessmentExport({ TA: threatAssessment })}
                  fileName={`${kebabify(
                    threatAssessment.entity.title.toString()
                  )}-threat-assessment.pdf`}
                >
                  {({ loading }) =>
                    loading
                      ? 'Generating document...'
                      : 'Download Threat Assessment'
                  }
                </PDFDownloadLink>
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

export default ThreatAssessmentPanel;

import { XMLParser } from 'fast-xml-parser';
import { GeoJsonProperties } from 'geojson';

interface Waypoint {
  '@_id': string;
  position: {
    '@_lat': string;
    '@_lon': string;
  };
  leg?: {
    '@_starboardXTD': string;
    '@_portXTD': string;
  };
}

interface MinimalRTZ {
  '?xml': {
    '@_version': string;
    '@_encoding': string;
  };
  route: {
    '@_version': string;
    '@_xmlns': string;
    waypoints: {
      waypoint: Waypoint[];
    };
    routeInfo: Record<string, string> & {
      '@_routeName': string;
    };
    schedules: {
      schedule: [];
    };
  };
}

/**
 * extract only the bare minimum to turn an RTZ exchange format XML file into a GeoJSON LineString object.
 * Discards all other information in the RTZ file except for the waypoints,
 * since we don't expect to use any other information in the RTZ file.
 */
export const rtzToGeoJson = (xmlString: string) => {
  const parser = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix: '@_', // this is the default, set explicitly for clarity
  });
  const result = parser.parse(xmlString) as Partial<MinimalRTZ>;
  // check for expected/required RTZ XML attributes
  if (
    !result.route ||
    !result.route['@_xmlns'] ||
    !result.route['@_xmlns'].startsWith('http://www.cirm.org/RTZ') ||
    !result.route['@_version']
  ) {
    throw new Error('XML does not have the expected RTZ headers');
  }
  const waypoints = result.route.waypoints?.waypoint;
  if (!waypoints) {
    throw new Error('No waypoints found in RTZ file');
  }
  const waypointPoints: [number, number][] = waypoints.map((waypoint) => [
    Number(waypoint.position['@_lon']),
    Number(waypoint.position['@_lat']),
  ]);
  // ensure we have gotten a lat/lon pair for each waypoint. filter any invalid points
  const validPoints = waypointPoints.filter(
    (point) => !Number.isNaN(point[0]) && !Number.isNaN(point[1])
  );
  if (validPoints.length === 0) {
    throw new Error('No valid waypoints found in RTZ file');
  }
  const geoJson: GeoJSON.Feature<GeoJSON.LineString, GeoJsonProperties> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: waypointPoints,
    },
  };
  if (result?.route?.routeInfo?.['@_routeName']) {
    geoJson.properties!.name = result.route.routeInfo['@_routeName'];
  }
  return geoJson;
};

export default rtzToGeoJson;

import { GeoSearchResponse } from '../../models/risk_intelligence.model';
import { wrapRequest } from '../base';

// eslint-disable-next-line import/prefer-default-export
export const getEntitiesInGeo = (geo: GeoJSON.Geometry) =>
  wrapRequest<GeoSearchResponse>(
    'get',
    'geonius',
    '/risk-intelligence/search/geo',
    {
      queryStringParameters: {
        query: JSON.stringify(geo),
      },
    }
  );

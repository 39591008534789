import { useEffect, useState } from 'react';
import ListPicker from '../../../common-components/list-picker/list-picker';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  DEFAULT_PORT_FILTERS,
  setPortsFilters,
} from '../world-ports-panel.slice';

function PortRiskAssessmentLevelFilter() {
  const dispatch = useAppDispatch();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const riskLevelFilter = useAppSelector(
    (state) => state.portsPanel.filters.riskLevel
  );

  const listItems = Object.keys(DEFAULT_PORT_FILTERS.riskLevel).map(
    (value, index) => ({
      id: index,
      title: value || 'Unknown',
      value,
    })
  );

  const handleSubmit = (items: number[]) => {
    const updatedFilters = listItems.map((item) => [
      item.value,
      items.includes(item.id),
    ]);

    dispatch(
      setPortsFilters({
        riskLevel: Object.fromEntries(updatedFilters),
      })
    );
  };

  useEffect(() => {
    const updatedFilters = listItems
      .filter((item) => riskLevelFilter?.[item.value] === true)
      .map((item) => item.id);

    setSelectedItems(updatedFilters);
  }, [riskLevelFilter]);

  return (
    <ListPicker
      onSubmit={handleSubmit}
      items={listItems}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      buttonText="PortRisk Assessment Level"
    />
  );
}

export default PortRiskAssessmentLevelFilter;

import { Alert } from '@mui/material';

interface FormSuccessMessageProps {
  message: string;
}

function FormSuccessMessage(props: FormSuccessMessageProps) {
  const { message } = props;

  return <Alert severity="success">{message}</Alert>;
}

export default FormSuccessMessage;

/* eslint-disable react/no-danger */
import { Box, Typography } from '@mui/material';

interface VesselInformationLabelProps {
  title: string;
  data: string | number | boolean;
  className?: string;
}

function VesselInformationLabel(props: VesselInformationLabelProps) {
  const { title, data, className } = props;

  const formatNumbers = (dataValue: string | number | boolean): string =>
    `${dataValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const addUnitsToData = (
    titleLabel: string,
    dataValue: string | number | boolean
  ): string | JSX.Element => {
    switch (titleLabel) {
      // ========================
      // Capacity
      // ========================
      case 'Dead Weight':
        return `${formatNumbers(dataValue)} kg`;
      case 'Gross Tonnage':
        return `${formatNumbers(dataValue)} T`;
      case 'Displacement':
        return (
          <>
            {formatNumbers(dataValue)} m<sup>3</sup>
          </>
        );
      case 'Grain Cubic Capacity':
        return (
          <>
            {formatNumbers(dataValue)} m<sup>3</sup>
          </>
        );
      case 'Liquid Cubic 98%':
        return (
          <>
            {formatNumbers(dataValue)} m<sup>3</sup>
          </>
        );
      case 'Net Tonnage':
        return `${formatNumbers(dataValue)} T`;
      case 'TEU':
        return `${formatNumbers(dataValue)} TEU`;
      case 'TPCMI':
        return `${formatNumbers(dataValue)} TPCMI`;
      case 'MCO Value':
        return `${formatNumbers(dataValue)}`;
      case 'MCO RPM':
        return `${formatNumbers(dataValue)} RPM`;
      case 'MCU Unit':
        return `${formatNumbers(dataValue)}`;
      case 'Hull Number':
        return `${formatNumbers(dataValue)}`;

      // ========================
      // Dimensions
      // ========================
      case 'GPS to Bow':
        return `${formatNumbers(dataValue)} m`;
      case 'GPS to Stern':
        return `${formatNumbers(dataValue)} m`;
      case 'GPS to Port':
        return `${formatNumbers(dataValue)} m`;
      case 'GPS to Starboard':
        return `${formatNumbers(dataValue)} m`;
      case 'Length':
        return `${formatNumbers(dataValue)} m`;
      case 'Width':
        return `${formatNumbers(dataValue)} m`;

      default:
        return `${dataValue}`;
    }
  };

  return (
    <Box
      data-testid="vessel-information-label"
      className={className}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4px',
      }}
    >
      <Typography variant="body1">
        <b>{title}</b>
      </Typography>
      <Typography variant="body2">
        {data ? addUnitsToData(title, data) : 'Unknown'}
      </Typography>
    </Box>
  );
}

VesselInformationLabel.defaultProps = {
  className: '',
};

export default VesselInformationLabel;

import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { IconButton } from '@mui/material';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { HexColorPicker, RgbColor, RgbColorPicker } from 'react-colorful';

type HexColourPickerProps = {
  type: 'hex';
  colour: string;
  onChange: (colour: string) => void;
};

type RgbColourPickerProps = {
  type: 'rgb';
  colour: RgbColor;
  onChange: (colour: RgbColor) => void;
};

type ColourPickerProps = HexColourPickerProps | RgbColourPickerProps;

function ColourPicker({ colour, onChange, type }: ColourPickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleClick} data-testid="colour-picker">
        <FormatColorFillIcon
          style={{
            color:
              type === 'hex'
                ? colour
                : `rgb(${colour.r}, ${colour.g}, ${colour.b})`,
          }}
        />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'hidden',
              padding: '20px 20px',
              borderRadius: 5,
            },
          },
        }}
      >
        {type === 'hex' ? (
          <HexColorPicker color={colour} onChange={onChange} />
        ) : (
          <RgbColorPicker color={colour} onChange={onChange} />
        )}
      </Popover>
    </>
  );
}

export default ColourPicker;

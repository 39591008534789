import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { IndustryNewsFeature } from '../../../models/industry-news.model';
import { startNewHistory } from '../../../nav-history.slice';
import {
  setSelectedIndustryNews,
  setSelectedIndustryNewsId,
} from '../../../state/industry-news/industry-news.slice';
import store from '../../../store';
import { assertIsPointFeature } from '../../../utils/typescript-helpers';
import MapHelpers from '../../map.utils';

function onIndustryNewsClick(
  feature: MapboxGeoJSONFeature & IndustryNewsFeature
) {
  const { selectedIndustryNewsId, industryNews } =
    store.getState().industryNews;

  if (assertIsPointFeature(feature)) {
    MapHelpers.zoomToPoint(feature.geometry.coordinates, 8.5);
  }

  if (selectedIndustryNewsId === feature.properties.id.toString()) {
    return;
  }

  store.dispatch(startNewHistory({ type: 'map' }));
  store.dispatch(setSelectedIndustryNewsId(feature.properties.id.toString()));
  const newSelectedIndustryNews = industryNews?.find(
    (news) => news.id === feature.properties.id
  );

  if (newSelectedIndustryNews) {
    window.history.pushState({}, '', `/industry-news/${feature.properties.id}`);
  }

  store.dispatch(setSelectedDossier(EDossiers.INDUSTRY_NEWS));
  store.dispatch(setSelectedIndustryNews(newSelectedIndustryNews));
}

export default onIndustryNewsClick;

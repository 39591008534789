import {
  EMenuIcons,
  EMenuItems,
  EMenuTitles,
} from '../../main-menu/menu.slice';
import { Privileges } from '../access-control/privileges';

export interface DataItem {
  selected: EMenuItems;
  icon: EMenuIcons;
}

interface MenuItem {
  title: string;
  items: DataItem[];
}

export const createMenuItems = (privileges: Privileges): MenuItem[] => [
  {
    title: EMenuTitles.INCIDENTS,
    items: [
      {
        selected: EMenuItems.INCIDENTS,
        icon: EMenuIcons.INCIDENTS,
      },
    ],
  },
  {
    title: privileges.canAccessHistory
      ? EMenuTitles.VESSELS_AND_PORTS
      : EMenuTitles.PORTS,
    items: [
      {
        selected: EMenuItems.PORTS,
        icon: EMenuIcons.PORTS,
      },
      {
        selected: EMenuItems.INDUSTRY_NEWS,
        icon: EMenuIcons.INDUSTRY_NEWS,
      },
      {
        selected: EMenuItems.FIRST_CALL_PORTS,
        icon: EMenuIcons.FIRST_CALL_PORTS,
      },
      {
        selected: EMenuItems.CORRESPONDENTS,
        icon: EMenuIcons.CORRESPONDENTS,
      },
      {
        selected: EMenuItems.VPS_PORTS,
        icon: EMenuIcons.VPS_PORTS,
      },
      {
        selected: EMenuItems.ROUTES,
        icon: EMenuIcons.ROUTES,
      },
      {
        selected: EMenuItems.HISTORY,
        icon: EMenuIcons.HISTORY,
      },
    ],
  },
  {
    title: EMenuTitles.AREAS,
    items: [
      {
        selected: EMenuItems.RI_MARITIME_AREAS,
        icon: EMenuIcons.RI_MARITIME_AREAS,
      },
      {
        selected: EMenuItems.BOUNDARIES,
        icon: EMenuIcons.BOUNDARIES,
      },
      {
        selected: EMenuItems.DRAWINGS,
        icon: EMenuIcons.DRAWINGS,
      },
    ],
  },
  {
    title: EMenuTitles.MAP_AND_TOOLS,
    items: [
      {
        selected: EMenuItems.MAPS,
        icon: EMenuIcons.MAPS,
      },
      {
        selected: EMenuItems.TOOLS,
        icon: EMenuIcons.TOOLS,
      },
    ],
  },
  {
    title: EMenuTitles.USER,
    items: [
      {
        selected: EMenuItems.DOCUMENTS,
        icon: EMenuIcons.DOCUMENTS,
      },
      {
        selected: EMenuItems.SAVE_SETUP,
        icon: EMenuIcons.SAVE_SETUP,
      },
      {
        selected: EMenuItems.PRIVACY_POLICY,
        icon: EMenuIcons.PRIVACY_POLICY,
      },
    ],
  },
];

/* eslint-disable import/prefer-default-export */
import { ThreatAssessment } from '../../dossiers_and_panels/threat-assessments/threat-assessment.model';
import { wrapRequest } from '../base';

export const getThreatAssessment = (
  threatAssessmentId: string | number
): Promise<ThreatAssessment> =>
  wrapRequest(
    'get',
    'north_standard',
    `/risk-intelligence/threat-assessments/${threatAssessmentId}`
  );

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchWeather } from '../../api/weather';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector } from '../../hooks';
import clearMapLayers from '../../map/map-layer-manager/weather-utils/clear-weather-layers';
import setMapPoints from '../../map/map-layer-manager/weather-utils/set-weather-points';
import WeatherForm from './weather-form';
import {
  setError,
  setLoading,
  setSuccess,
  setWeatherFormValues,
  setWeatherResults,
} from './weather-panel.slice';
import WeatherResults from './weather-results';
import { WeatherFormValues } from './weather.model';

export default function WeatherPanel() {
  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { error, formValues, loading, results, success } = useAppSelector(
    (state) => state.weather
  );

  const onSubmit = async (values: WeatherFormValues) => {
    dispatch(setWeatherFormValues(values));
    dispatch(setLoading(true));

    try {
      const response = await fetchWeather(values);
      dispatch(setWeatherResults(response));
      setMapPoints(response);
      dispatch(setSuccess(true));
    } catch (e) {
      dispatch(setError((e as Error).message));
    }
  };
  useEffect(() => {
    if (results) {
      setSelectedDate(new Date(results.weather.meta.end));
    }
  }, [results]);

  return (
    <Box sx={{ padding: 2, height: '100%', overflowY: 'auto' }}>
      <WeatherForm
        onSubmit={onSubmit}
        onClear={() => {
          dispatch(setWeatherResults(null));
          clearMapLayers();
        }}
        initialFormValues={formValues}
        loading={loading}
        success={success}
      />
      {loading && <LoadingPanel />}
      {error && <ErrorPanel message={error} />}
      {results && (
        <WeatherResults
          results={results}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      )}
    </Box>
  );
}

/* eslint-disable import/prefer-default-export */
import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import DrawCompletionOverlay from '../../common-components/drawing-overlay/drawing-overlay';
import ErrorSnackbar from '../../common-components/error-snackbar/error-snackbar';
import PanelTitle from '../../common-components/panel/panel-title';
import TabPanel from '../../common-components/tabs/TabPanel';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import { themedDraw } from '../../map/map-controls.utils';
import { setDrawType } from '../../map/map.slice';
import { Route } from '../../models/routes.model';
import {
  setSelectedRoute,
  setSelectedRouteId,
} from '../../state/routes/routes.slice';
import { RouteForm } from './route-form/route-form';
import './route-panel.scss';
import RoutesList from './routes-list/routes-list';

export function RoutesPanel() {
  const dispatch = useAppDispatch();
  const selectedRouteId = useAppSelector(
    (state) => state.routes.selectedRouteId
  );
  const routes = useAppSelector((state) => state.routes.routes);
  const drawType = useAppSelector((state) => state.map.drawType);

  const [tabIndex, setTabIndex] = useState(0);
  const [routeToEdit, setRouteToEdit] = useState<Route | null>(null);
  const [entityError, setEntityError] = useState<boolean>(false);

  const draw = themedDraw;

  const isMobile = useMobile();

  const onTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setRouteToEdit(null);
    }
    setTabIndex(newValue);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEntityError(false);
  };

  const onRouteEditClick = (route: Route) => {
    setTabIndex(1);
    setRouteToEdit(route);
  };

  useEffect(() => {
    if (selectedRouteId && routes && routes.length > 0) {
      const route = routes?.find((r) => r.route_id === selectedRouteId);
      if (route) {
        // once we have the route selected properly, we can unset the id
        dispatch(setSelectedRoute(route));
      } else {
        setEntityError(true);
      }
      dispatch(setSelectedRouteId(null));
    }
  }, [routes, selectedRouteId]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <PanelTitle>Routes</PanelTitle>

      {/* Complete Drawing Overlay/Scrim */}
      {drawType === 'route' && !isMobile && (
        <DrawCompletionOverlay
          handleClick={() => {
            if (draw) {
              draw.changeMode('simple_select');
              dispatch(setDrawType(null));
            }
          }}
          text="Click on the map to start drawing a route. Click this panel when done, or double click a point."
        />
      )}

      <Tabs value={tabIndex} onChange={onTabSelect} variant="fullWidth">
        <Tab
          label="Saved Routes"
          id="route-tab-savedroutes"
          aria-controls="route-tabpanel-savedroutes"
          value={0}
        />
        <Tab
          label={
            routeToEdit
              ? `Editing Route: ${routeToEdit.route_name}`
              : 'Plot a Route'
          }
          id="route-tab-editroutes"
          aria-controls="route-tabpanel-editroutes"
          value={1}
        />
      </Tabs>
      <TabPanel
        index={tabIndex}
        value={0}
        aria-labelledby="route-tab-savedroutes"
      >
        <RoutesList onRouteEditClick={onRouteEditClick} />
      </TabPanel>
      <TabPanel
        index={tabIndex}
        value={1}
        aria-labelledby="route-tab-editroutes"
      >
        <RouteForm
          routeToEdit={routeToEdit}
          clearEditRoute={() => {
            setRouteToEdit(null);
            setTabIndex(0);
          }}
        />
      </TabPanel>

      <ErrorSnackbar
        open={entityError}
        onClose={handleSnackbarClose}
        message="Unable to find route id provided in URL"
      />
    </Box>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Container, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import MuiFormikTextField from '../form-fields/mui-formik-text-field';
import UploadAttachments from '../upload-attachments/upload-attachments';
import {
  UploadEntityDocumentsFormValues,
  uploadEntityDocumentsAcceptedFileTypes,
} from './upload-entity-documents-validators';

type UploadEntityDocumentsFormProps = {
  entityName: string | undefined | null;
};

function UploadEntityDocumentsForm({
  entityName,
}: UploadEntityDocumentsFormProps) {
  const formik = useFormikContext<UploadEntityDocumentsFormValues>();

  const updateFileAttachments = (files: File[]) => {
    formik.setFieldValue('fileAttachments', files);
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    // Only allow name and description to be added one file upload until we have components ready to
    // edit title and description individually
    updateFileAttachments(files !== null ? Array.from(files) : []);
    // override default behaviour - capture all file selections, not just on change
    // e.currentTarget.value = '';
  };

  const handleRemoveAttachment = (index: number) => {
    const updatedFileAttachments = formik.values.fileAttachments.filter(
      (_, i) => i !== index
    );
    updateFileAttachments(updatedFileAttachments);
  };

  return (
    <Container
      className="upload-entity-documents-form"
      disableGutters
      sx={{ padding: 0 }}
    >
      <Typography
        variant="subtitle1"
        className="upload-entity-documents-form-label"
        sx={{ fontWeight: '500' }}
      >
        Upload document to: {entityName}
      </Typography>
      <Stack>
        <MuiFormikTextField label="Name" name="name" />
        <MuiFormikTextField label="Description" name="description" />
      </Stack>
      <Box sx={{ marginBlockEnd: '16px' }}>
        <UploadAttachments
          acceptedFileTypes={uploadEntityDocumentsAcceptedFileTypes}
          onChange={handleFileUpload}
          attachments={formik.values.fileAttachments}
          onClearAll={() => updateFileAttachments([])}
          removeAttachment={handleRemoveAttachment}
        />
        <span className="error-message">
          {formik.errors.fileAttachments?.toString()}
        </span>
      </Box>
    </Container>
  );
}
export default UploadEntityDocumentsForm;

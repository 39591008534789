/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box } from '@mui/material';
import ThreatAssessmentChip from '../threat-assessment-chip/threat-assessment-chip';

enum EThreatLevel {
  LOW = 1,
  MODERATE = 2,
  ELEVATED = 3,
  HIGH = 4,
  SEVERE = 5,
}

function RenderThreatDefinition(threatLevel: EThreatLevel) {
  const LOW_TEXT =
    'Negligable impact on operations in the forecast period due to the unlikely occurrence of threat-related activity.';
  const MODERATE_TEXT =
    'Some potential for minimal impact on operations in the forecast period due to occurrence of low-level threat related activity.';
  const ELEVATED_TEXT =
    'Moderate impact on operations in the forecast period is possible with some threat-related activity.';
  const HIGH_TEXT =
    'Potential for major impact on operations in the forecast period due to the occurrence of significant threat-related activity.';
  const SEVERE_TEXT =
    'Potential for severe impact on operations in the forecast period as significant threat-related activity is ongoing or expected to occur.';

  const getDefinitionText = (level: EThreatLevel) => {
    switch (level) {
      case EThreatLevel.LOW:
        return LOW_TEXT;
      case EThreatLevel.MODERATE:
        return MODERATE_TEXT;
      case EThreatLevel.ELEVATED:
        return ELEVATED_TEXT;
      case EThreatLevel.HIGH:
        return HIGH_TEXT;
      case EThreatLevel.SEVERE:
        return SEVERE_TEXT;
      default:
        return '';
    }
  };

  return (
    <Box>
      <Box sx={{ ml: '-0.4rem' }}>
        <ThreatAssessmentChip threatLevel={threatLevel} />
      </Box>
      <Box sx={{ ml: '1.3rem', fontSize: '14px' }}>
        {getDefinitionText(threatLevel)}
      </Box>
    </Box>
  );
}

function RenderThreatDefinitionsModalContents() {
  return (
    <Box
      sx={{
        width: '360px',
        padding: '1rem',
        bgcolor: 'primary.main',
        pointerEvents: 'auto',
      }}
      data-testid="threat-definitions-modal-contents"
    >
      <Box sx={{ fontSize: '14px' }}>
        The threat levels are assessed based on the likelihood and consequence
        of a particular threat type occurring. The levels are:
      </Box>
      <Box>
        {RenderThreatDefinition(EThreatLevel.LOW)}
        {RenderThreatDefinition(EThreatLevel.MODERATE)}
        {RenderThreatDefinition(EThreatLevel.ELEVATED)}
        {RenderThreatDefinition(EThreatLevel.HIGH)}
        {RenderThreatDefinition(EThreatLevel.SEVERE)}
      </Box>
    </Box>
  );
}

export default RenderThreatDefinitionsModalContents;

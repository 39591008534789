import { Box, List } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReduxStatesToVerify, useEnsureReduxLoaded } from '../../api';
import {
  getIncidentRegions,
  getMaritimeAreas,
} from '../../api/risk-intelligence/incidents';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import ErrorSnackbar from '../../common-components/error-snackbar/error-snackbar';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import setMaritimeAreasFeatures from '../../map/map-layer-manager/maritime-areas-utils/set-maritime-areas-features';
import MapHelpers from '../../map/map.utils';
import { MaritimeArea } from '../../models/risk_intelligence.model';
import { addHistoryItem } from '../../nav-history.slice';
import { setIncidentRegions } from '../../state/incidents/incidents.slice';
import store from '../../store';
import { getIncidentRegionTitleById } from '../../utils/incidents-helpers';
import RIMaritimeAreaCard from './ri-maritime-area-card/ri-maritime-area-card';
import {
  setAreas,
  setError,
  setLoading,
  setSelectedArea,
  setSelectedAreaId,
} from './ri-maritime-areas.slice';

function RIMaritimeAreasPanel() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);
  const selectedAreaId = useAppSelector(
    (state) => state.riMaritimeAreas.selectedAreaId
  );
  const areas = useAppSelector((state) => state.riMaritimeAreas.areas);
  const loading = useAppSelector((state) => state.riMaritimeAreas.loading);
  const error = useAppSelector((state) => state.riMaritimeAreas.error);

  const [entityError, setEntityError] = useState<boolean>(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [filteredAreas, setFilteredArea] = useState<MaritimeArea[] | null>(
    null
  );

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEntityError(false);
  };

  useEffect(() => {
    if (!selectedAreaId || !areas) {
      return;
    }
    const newArea = areas.find((el) => el.id === selectedAreaId);
    if (newArea) {
      dispatch(setSelectedArea(newArea));
      dispatch(setSelectedAreaId(null));
    } else {
      setEntityError(true);
    }
  }, [selectedAreaId, areas]);

  useEffect(() => {
    if (!areas) {
      dispatch(setLoading(true));
      dispatch(setError(false));
      getMaritimeAreas()
        .then((response) => {
          dispatch(setAreas(response));
          dispatch(
            setSelectedArea(
              response?.find((el) => el.id === selectedAreaId) || null
            )
          );
          dispatch(setSelectedAreaId(null));
        })
        .catch(() => {
          dispatch(setError(true));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    }

    // needed so that the areas' regions can be found
    getIncidentRegions().then((data) => {
      store.dispatch(setIncidentRegions(data));
    });
  }, []);

  useEffect(() => {
    if (!mapInitialised) {
      return;
    }
    if (areas) {
      setMaritimeAreasFeatures(MapLayer.RI_MARITIME_AREAS, areas);
    }
  }, [areas, mapInitialised]);

  const handleSearchChange = (e: string) => {
    const inputValue = e;
    setSearchInputValue(inputValue);
    if (inputValue === '') {
      setFilteredArea(areas!);
    } else {
      const filtered = areas!.filter(
        (area) =>
          area.title.toLowerCase().includes(inputValue.toLowerCase()) ||
          getIncidentRegionTitleById(area.region)!
            .toLowerCase()
            .includes(inputValue.toLowerCase())
      );
      setFilteredArea(filtered);
    }
  };

  useEnsureReduxLoaded([ReduxStatesToVerify.MARITIME_AREAS], {
    before: () => {
      dispatch(setLoading(true));
    },
    after: () => {
      dispatch(setLoading(false));
      MapHelpers.setLayerVisibility(MapLayer.RI_MARITIME_AREAS, true);
    },
  });

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <PanelTitle>Risk Intelligence Maritime Areas</PanelTitle>
      <Box
        sx={{
          overflowY: 'auto',
        }}
      >
        {loading && <LoadingPanel />}
        {!loading && error && (
          <ErrorPanel message="Error loading maritime areas..." />
        )}
        {!loading && !error && areas && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                padding: '0px 20px',
              }}
            >
              <SimpleSearchBar
                handleChange={handleSearchChange}
                filterValue={searchInputValue}
                placeholder="Search Maritime Areas"
                dataTestId="maritime-areas-search-bar"
              />
            </Box>
            <List
              className="maritime-areas-list"
              sx={{
                paddingTop: 0,
              }}
            >
              {(filteredAreas || areas).map((area) => (
                <RIMaritimeAreaCard
                  key={area.id}
                  title={area.title}
                  region={getIncidentRegionTitleById(area.region)}
                  onClick={() => {
                    MapHelpers.zoomToPolygon(area.geo);
                    dispatch(setSelectedArea(area));
                    dispatch(addHistoryItem({ type: 'RIareaslist' }));
                    navigate(`/ri-maritime-areas/${area.id}`);
                  }}
                />
              ))}
            </List>
          </Box>
        )}
        <ErrorSnackbar
          open={entityError}
          onClose={handleSnackbarClose}
          message="Unable to find area id provided in URL"
        />
      </Box>
    </Box>
  );
}

export default RIMaritimeAreasPanel;

import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../../map/map.utils';
import DateTimeHelpers from '../../../utils/date-time-helpers.utils';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { NormalisedIncidents } from '../incident-panel.slice';
import { Incident } from '../incident.model';

const generateCommonDatumRings = (circles: any[], type: string) =>
  circles.map((circle) =>
    GeoHelper.createFeatureCircle(
      GeoHelper.createCircle(
        circle.radius,
        circle.coordinates[1],
        circle.coordinates[0]
      ),
      { type }
    )
  );

const generateAvgDatumRing = (circles: any[]) =>
  generateCommonDatumRings(circles, 'average');

const generateMaxDatumRing = (circles: any[]) =>
  generateCommonDatumRings(circles, 'max');

const showDatumRings = (averageAndMaxRings: any[]) => {
  const polygonFeatures = averageAndMaxRings.map((feature) =>
    GeoHelper.convertFeatureCircleToPolygon(feature)
  );
  GeoHelper.setMapboxGeoJSONSourceData(
    MapLayer.DATUM_RINGS,
    GeoHelper.createGeoJSON(polygonFeatures)
  );

  MapHelpers.moveLayerIfExists(MapLayer.DATUM_RINGS, MapLayer.INCIDENTS);
  MapHelpers.moveLayerIfExists(
    MapLayer.DATUM_RINGS,
    MapLayer.INCIDENT_CLUSTERS
  );
};

const DatumRingTimer = (
  attackedIncidents: Incident[],
  intervalId: NodeJS.Timer | null
) => {
  const avgCircles: any[] = [];
  const maxCircles: any[] = [];

  attackedIncidents.forEach((attackedIncident) => {
    if (attackedIncident && attackedIncident.datum) {
      const timeGapInMinutes = DateTimeHelpers.compareTimeInMinutes(
        new Date(attackedIncident.date),
        new Date()
      );

      if (attackedIncident.datum < timeGapInMinutes / 60) {
        return;
      }

      // Divided by 60 to get knots per minute
      const avgDistance =
        (attackedIncident.attacker.avgSpeed! / 60) * timeGapInMinutes;
      const maxDistance =
        (attackedIncident.attacker.maxSpeed! / 60) * timeGapInMinutes;

      avgCircles.push({
        radius: avgDistance,
        coordinates: [
          attackedIncident.position!.latitude,
          attackedIncident.position!.longitude,
        ],
      });

      maxCircles.push({
        radius: maxDistance,
        coordinates: [
          attackedIncident.position!.latitude,
          attackedIncident.position!.longitude,
        ],
      });
    }
  });

  const max = generateMaxDatumRing(maxCircles);
  const avg = generateAvgDatumRing(avgCircles);

  const averageAndMaxRings = max.concat(avg);

  if (averageAndMaxRings.length === 0) {
    clearInterval(intervalId as NodeJS.Timer);
  }

  showDatumRings(averageAndMaxRings);
};

const generateDatumRings = (incidents: NormalisedIncidents) => {
  let intervalId: NodeJS.Timer | null = null;

  const attackedIncidents = Object.values(incidents.byId).filter(
    (incident) =>
      incident.attacker &&
      Object.values(incident.attacker).every(
        (value) => value !== null && value !== undefined
      )
  );
  if (attackedIncidents.length > 0) {
    // runs once before timer
    DatumRingTimer(attackedIncidents, intervalId);
    intervalId = setInterval(() => {
      DatumRingTimer(attackedIncidents, intervalId);
    }, 60000); // 1 minute
  }

  return intervalId;
};

export default generateDatumRings;

import { ClipLoader } from 'react-spinners';
import { LoaderSizeProps } from 'react-spinners/helpers/props';

function LoadingSpinner(props: LoaderSizeProps) {
  const { size } = props;

  return (
    <ClipLoader
      size={size}
      cssOverride={{
        backgroundColor: 'inherit',
        borderColor:
          // this will use the colors declared in themes.scss
          'var(--primary-text-colour) var(--primary-text-colour) transparent',
      }}
    />
  );
}

LoadingSpinner.defaultProps = {
  size: 15,
};

export default LoadingSpinner;

import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { FormikProps, FormikProvider } from 'formik';
import { LngLat } from 'mapbox-gl';
import { useEffect } from 'react';
import LongLatInputFields from '../../../../common-components/form-fields/long-lat-input-fields';
import MuiFormikTextField from '../../../../common-components/form-fields/mui-formik-text-field';
import { useAppDispatch } from '../../../../hooks';
import { decimalToDMS } from '../../../../map/map-controls.utils';
import { setNearbyVesselsFormValues } from '../nearby-vessels.slice';
import { NearbyVesselsFormValues } from './nearby-vessels-form-values';

interface NearbyVesselsFormProps {
  formik: FormikProps<NearbyVesselsFormValues>;
  handleReset: () => void;
}

function NearbyVesselsForm(props: NearbyVesselsFormProps) {
  const dispatch = useAppDispatch();

  const { formik, handleReset } = props;

  const onMapClick = (result: LngLat[]) => {
    const { lat, lng } = result[0];

    // Convert the longitude and latitude to degrees, minutes, and seconds
    const latitude = decimalToDMS(lat, 'latitude');
    const longitude = decimalToDMS(lng, 'longitude');
    formik.setFieldValue('centrePoint.latitude', latitude);
    formik.setFieldValue('centrePoint.longitude', longitude);
  };

  useEffect(() => {
    dispatch(setNearbyVesselsFormValues(formik.values));
  }, [formik.values]);

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ margin: '0 1rem' }}>
              <MuiFormikTextField
                name="radius"
                label="Radius (NM)"
                variant="outlined"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ margin: '0 1rem' }}>
              <MuiFormikTextField
                name="timeSinceLastAIS"
                label="Time Since Last AIS Ping (hours)"
                variant="outlined"
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={1} sx={{ padding: '1rem', margin: '0 1rem' }}>
              <Typography variant="subtitle2" align="left" gutterBottom>
                Centre Point
              </Typography>
              <LongLatInputFields
                longitudeInputName="centrePoint.longitude"
                latitudeInputName="centrePoint.latitude"
                onLngLatSelect={onMapClick}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                margin: '0 1rem',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handleReset}
                sx={{ width: '10rem' }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: '10rem', marginLeft: '1rem' }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </FormikProvider>
  );
}

export default NearbyVesselsForm;

/* eslint-disable jsx-a11y/label-has-associated-control */

import { Box, Button, Stack, Typography } from '@mui/material';
import SimpleDialog from '../dialog/SimpleDialog';
import FormLoadingMessage from '../form-messages/form-loading-message';
import './delete-confirmation-modal.scss';

interface DeleteConfirmationModalProps {
  visible: boolean;
  onClose: () => void;
  deleteFunction: () => void;
  itemsToDelete: string[];
  deleting: boolean;
  typeOfThingBeingDeleted: string;
}

interface RenderDeleteConfirmationModalProps {
  itemsToDelete: string[];
  deleting: boolean;
  typeOfThingBeingDeleted: string;
}

function RenderDeleteConfirmationModal(
  props: RenderDeleteConfirmationModalProps
) {
  const { itemsToDelete, deleting, typeOfThingBeingDeleted } = props;
  return (
    <Box sx={{ textAlign: 'left' }}>
      {deleting && (
        <div className="deleting-banner">
          <FormLoadingMessage message="Deleting..." />
        </div>
      )}

      <Typography>
        {`Are you sure you wish to delete the following ${typeOfThingBeingDeleted}${
          itemsToDelete.length > 1 ? 's' : ''
        }`}
        :
      </Typography>
      <ul>
        {itemsToDelete.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </Box>
  );
}

function DeleteConfirmationModal(props: DeleteConfirmationModalProps) {
  const {
    visible,
    onClose,
    itemsToDelete,
    deleteFunction,
    deleting,
    typeOfThingBeingDeleted,
  } = props;

  return (
    <SimpleDialog
      onClose={onClose}
      title="Delete Items"
      open={visible}
      actions={
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={() => {
              onClose();
            }}
            data-testid="confirm-delete-close-button"
          >
            Cancel
          </Button>
          <Button
            data-testid="confirm-delete-button"
            onClick={deleteFunction}
            disabled={deleting}
            variant="contained"
          >
            Confirm
          </Button>
        </Stack>
      }
    >
      <RenderDeleteConfirmationModal
        itemsToDelete={itemsToDelete}
        deleting={deleting}
        typeOfThingBeingDeleted={typeOfThingBeingDeleted}
      />
    </SimpleDialog>
  );
}

export default DeleteConfirmationModal;

import { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';

import { useNavigate } from 'react-router-dom';
import { getRiskIntelligencePort, getThreatAssessment } from '../api';
import {
  ENewsItemType,
  NewsItem,
  getRiNews,
} from '../api/risk-intelligence/news';
import LoadingPanel from '../common-components/loading-panel/loading-panel';
import { setSelectedEntityThreatAssessmentInfo } from '../dossiers_and_panels/threat-assessments/threat-assessment.slice';
import { useAppDispatch, useAppSelector, useMobile } from '../hooks';
import {
  EMenuItems,
  setSelectedOption,
  setThreatAssessmentOpen,
} from '../main-menu/menu.slice';
import MapHelpers from '../map/map.utils';
import { setSelectedIncidentId } from '../state/incidents/incidents.slice';
import './news-ticker.scss';

function NewsTicker() {
  const [news, setNews] = useState<NewsItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);

  const isMobile = useMobile();
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (mapInitialised && MapHelpers.loaded()) MapHelpers.resize();

    return () => {
      if (mapInitialised && MapHelpers.loaded()) MapHelpers.resize();
    };
  }, [mapInitialised]);

  useEffect(() => {
    setLoading(true);

    getRiNews()
      .then((response) => {
        setNews(response);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onTaClick = (id: string) => {
    getThreatAssessment(id)
      .then((response) => {
        dispatch(
          setSelectedEntityThreatAssessmentInfo({
            id: response.id,
            title: response.entity.title,
          })
        );
        dispatch(setThreatAssessmentOpen(true));
        if (response.entity.type === 'port') {
          getRiskIntelligencePort(response.entity.id)
            .then((port) => {
              if (port) {
                MapHelpers.zoomToPoint(
                  [port.position!.longitude, port.position!.latitude],
                  15
                );
              }
            })
            .catch(() => {})
            .finally(() => {});
        }
        // TODO what to do if it's a country type ?
      })
      .catch(() => {})
      .finally(() => {});
  };

  const onIncidentClick = (id: string) => {
    dispatch(setSelectedOption(EMenuItems.INCIDENTS));
    dispatch(setSelectedIncidentId(id));
    navigate(`/incidents/${id}`);
  };

  return (
    <>
      {loading && (
        <div className="incidentTicker">
          <LoadingPanel size={5} />
        </div>
      )}
      {!loading && error && (
        <div className="incidentTicker">
          <span>Error loading news items...</span>
        </div>
      )}
      {!loading && !error && (
        <Marquee
          pauseOnHover
          speed={50}
          className="incidentTicker"
          gradientColor={[0, 45, 92]} // risk intelligence secondary color
          gradientWidth={isMobile ? 10 : 100}
        >
          {news &&
            news.map((newsItem: NewsItem) => (
              <button
                key={newsItem.id}
                data-testid={`${newsItem.title.toString()}-${newsItem.id.toString()}`}
                type="button"
                className="incidentTickerItem"
                onClick={
                  newsItem.type === ENewsItemType.INCIDENT
                    ? () => {
                        onIncidentClick(newsItem.id);
                      }
                    : () => {
                        onTaClick(newsItem.id);
                      }
                }
              >
                {newsItem.type === ENewsItemType.INCIDENT
                  ? 'Recent incident ('
                  : 'Updated TA ('}
                {new Date(newsItem.date).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                })}
                {'): '}
                {newsItem.title}
              </button>
            ))}
        </Marquee>
      )}
    </>
  );
}

export default NewsTicker;

import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

function addRasterSource(layer: MapLayer | string, tileSources: string[]) {
  MapHelpers.addSource(layer, {
    type: 'raster',
    tiles: tileSources,
    tileSize: 256,
  });
}

export default addRasterSource;

import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import ReactPDF, { pdf, usePDF } from '@react-pdf/renderer';
import { useCallback, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import {
  getIncident,
  getIncidentStats,
} from '../api/risk-intelligence/incidents';
import {
  ExpandedIncident,
  IncidentFilters,
} from '../maritime-menu-options/incidents-panel/incident.model';
import { incidentExport } from '../reporting/incident-export';
import { incidentStatsExport } from '../reporting/incident-stats-export';
import DownloadHelpers from './download.utils';
import './incidents-download.scss';

const pdfReady = (
  pdfInstance: ReactPDF.UsePDFInstance
): pdfInstance is ReactPDF.UsePDFInstance & { url: string } => {
  const result =
    pdfInstance &&
    pdfInstance.url &&
    pdfInstance.url.length > 0 && // pdf url exists
    !pdfInstance.loading; // it isn't loading
  return !!result;
};

const useDownloadIncidents = (
  incidentIds: number[],
  incidentFilters: IncidentFilters
) => {
  const [downloadIncidentsInProgress, setDownloadIncidentsInProgress] =
    useState(false);
  const [downloadStatsInProgress, setDownloadStatsInProgress] = useState(false);
  const [completedDownloads, setCompletedDownloads] = useState(0);
  const [downloadedIncidents, setDownloadedIncidents] = useState<
    ExpandedIncident[] | null
  >(null);
  const [downloadComplete, setDownloadComplete] = useState(false);
  const [pdfInstance, setPdfInstance] = usePDF({});
  const [downloadStatsError, setDownloadStatsError] = useState(false);
  // const [downloadIncidentsError, setDownloadIncidentsError] = useState(false);

  const incrementCompletedDownloads = () => {
    setCompletedDownloads(
      (prevCompletedDownloads: number) => prevCompletedDownloads + 1
    );
  };

  const fetchExpandedIncident = async (id: number) => getIncident(id);

  const downloadIncidents = useCallback(async () => {
    setDownloadIncidentsInProgress(true);
    const tempIncidentPromises: Promise<ExpandedIncident>[] = [];
    incidentIds.forEach((incidentId: number) => {
      const result = fetchExpandedIncident(incidentId).then(
        (data: ExpandedIncident) => {
          incrementCompletedDownloads();
          return data;
        }
      );
      tempIncidentPromises.push(result);
    });
    // use then instead of await as it makes the catch neater
    Promise.all(tempIncidentPromises)
      .then((data: ExpandedIncident[]) => {
        setDownloadedIncidents(data);
      })
      .catch(() => {});
  }, [incidentIds]);

  useEffect(() => {
    if (
      downloadedIncidents &&
      downloadedIncidents.length > 0 &&
      pdfReady(pdfInstance)
    ) {
      const docTitle = `incidents-download-${new Date()
        .toISOString()
        .substring(0, 10)}.pdf`;
      DownloadHelpers.downloadURI(docTitle, pdfInstance.url, () => {
        setDownloadedIncidents(null);
        setDownloadComplete(true);
        setDownloadIncidentsInProgress(false);
      });
    }
  }, [downloadedIncidents, pdfInstance]);

  useEffect(() => {
    if (downloadedIncidents && downloadedIncidents.length > 0) {
      const generatedDoc = incidentExport({ incidents: downloadedIncidents });
      setPdfInstance(generatedDoc);
    }
  }, [downloadedIncidents]);

  const downloadIncidentsButton = () => {
    let buttonText = '';
    if (downloadComplete) {
      buttonText = 'Downloaded';
      setTimeout(() => {
        setDownloadComplete(false);
        setCompletedDownloads(0);
      }, 2000);
    } else if (
      (pdfInstance.loading && downloadIncidentsInProgress) ||
      (completedDownloads > 0 && incidentIds.length === completedDownloads)
    ) {
      buttonText = 'Generating document...';
    } else if (downloadIncidentsInProgress) {
      buttonText = `Downloading ${completedDownloads} / ${incidentIds.length}`;
    } else {
      buttonText = `Download ${incidentIds.length} Incidents`;
    }

    const downloadStats = async () => {
      setDownloadStatsInProgress(true);
      let response;
      try {
        response = await getIncidentStats(incidentFilters);
      } catch (error) {
        setDownloadStatsInProgress(false);
        setDownloadStatsError(true);
      }
      if (!response) {
        return;
      }

      incidentStatsExport({
        stats: response,
        filters: incidentFilters,
      })
        .then(async (pdfContent: any) => {
          const pdfFile = pdf(pdfContent);
          const blob = await pdfFile.toBlob();
          const dUrl = URL.createObjectURL(blob);
          const docTitle = `incidents-stats-download-${new Date()
            .toISOString()
            .substring(0, 10)}.pdf`;
          DownloadHelpers.downloadURI(docTitle, dUrl);
          setDownloadStatsInProgress(false);
        })
        .catch(() => {
          setDownloadStatsInProgress(false);
          setDownloadStatsError(true);
        });
    };

    return (
      <Stack
        className="download-incidents-button-container"
        sx={{
          padding: '1rem',
          margin: 0,
          borderTop: 1,
          borderBottom: 1,
          borderColor: 'primary.dark',
          '& Button': {
            borderRadius: '100px',
            borderColor: 'primary.dark',
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '38px',
          },
        }}
      >
        <Button
          className="download-view-on-buttons-download"
          type="button"
          disabled={incidentIds.length === 0}
          onClick={() => downloadIncidents()}
        >
          {downloadIncidentsInProgress ? (
            <>
              <ClipLoader size={15} color="#fff" />
              {buttonText}
            </>
          ) : (
            buttonText
          )}
        </Button>
        <Button
          className="download-incidents-button"
          type="button"
          disabled={incidentIds.length === 0}
          onClick={() => downloadStats()}
        >
          {downloadStatsError && 'Error downloading stats'}
          {!downloadStatsError &&
            (downloadStatsInProgress
              ? 'Downloading...'
              : 'Download Statistics with current filters')}
        </Button>
      </Stack>
    );
  };

  return { downloadIncidentsButton };
};

export default useDownloadIncidents;

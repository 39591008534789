import { MergedFeaturePoint } from './merged-feature-point.model';

export enum VesselSource {
  AIS = 'AIS',
  RISK_INTELLIGENCE = 'RISK_INTELLIGENCE',
}

export interface Vessel extends Partial<MergedFeaturePoint> {
  vessel_id: string;
  name: string;
  imo?: number | string;
  mmsi?: number | string;
  heading?: number;
  latitude: number;
  longitude: number;
  callsign?: string;
  shiptype?: string;
  // TODO: these should be numbers, but Alerts API returns string and History API returns number...
  course: number;
  speed?: number;
  source: VesselSource;
  timestamp?: string | number;
}

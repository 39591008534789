/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Drawing, DrawingData, OwnerType } from '../../models/drawings.model';

type DrawingsState = {
  loading: boolean;
  error: boolean;
  drawings: Drawing[] | null;
  data: DrawingData;
  createDrawingPanelVisible: boolean;
  timestamp: number;
  ownerTypes: OwnerType[];
  ownerType: OwnerType;
  selectedDrawing: null | Drawing;
  selectedDrawingId: null | number | string;
};

export const INITIAL_DRAWINGS_STATE: DrawingsState = {
  loading: false,
  error: false,
  drawings: null,
  data: {},
  createDrawingPanelVisible: false,
  timestamp: Date.now(),
  selectedDrawing: null,
  selectedDrawingId: null,
  ownerTypes: [
    { id: 0, title: 'Personal' },
    { id: 1, title: 'Organisation' },
  ],
  ownerType: {
    id: 0,
    title: '',
  },
};

const DrawingsSlice = createSlice({
  name: 'drawings',
  initialState: INITIAL_DRAWINGS_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setLoadingOff: (state) => {
      state.loading = false;
      state.error = false;
    },
    setError: (state) => {
      state.loading = false;
      state.error = true;
    },
    setDrawings: (state, action) => {
      state.drawings = action.payload;
      state.loading = false;
      state.error = false;
    },
    setCreateDrawingPanelVisible: (state, action) => {
      state.createDrawingPanelVisible = action.payload;
    },
    setSelectedDrawingId: (
      state,
      action: PayloadAction<DrawingsState['selectedDrawingId']>
    ) => {
      state.timestamp = Date.now();
      state.selectedDrawingId = action.payload;
    },
    setSelectedDrawing: (state, action: PayloadAction<Drawing | null>) => {
      state.timestamp = Date.now();
      state.selectedDrawing = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setDrawings,
  setLoadingOff,
  setCreateDrawingPanelVisible,
  setSelectedDrawing,
  setSelectedDrawingId,
} = DrawingsSlice.actions;

export default DrawingsSlice.reducer;

import {
  RangeRingPoint,
  RangeRingProperties,
} from '../../../maritime-menu-options/tools-panel/range-rings/range-ring.model';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerPaint from '../map-layer-paint';
import MapLayerVisibility from '../map-layer-visibility.enum';
import { MapGroupLayer, RangeRingLayers } from '../map-layer.enum';

interface RangeRingLabelOptions {
  rangeRing: RangeRingProperties;
  labelLayerId: string;
  pointLayerId: string;
}

export const addRangeRingPointLayer = (point: RangeRingPoint) => {
  addSimpleGeoJsonSource(point.id);

  MapLayerManager.AddLayerinGroups(
    {
      id: point.id,
      type: 'circle',
      source: point.id,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: MapLayerPaint.RANGE_RING_POINT_PAINT,
    },
    [MapGroupLayer.RANGE_RINGS, point.id]
  );
};

export const addRangeRingLabelLayer = (options: RangeRingLabelOptions) => {
  const { labelLayerId, pointLayerId, rangeRing } = options;

  addSimpleGeoJsonSource(labelLayerId);

  MapLayerManager.AddLayerinGroups(
    {
      id: labelLayerId,
      type: 'symbol',
      source: labelLayerId,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'text-field': ['get', 'label'],
        'text-anchor': 'top',
        'text-justify': 'auto',
        'text-size': 13,
        'text-max-width': 30,
      },
      metadata: MapLayerManager.groupLayerMetaData([
        pointLayerId,
        rangeRing.id,
      ]),
      paint: {
        'text-color': '#ffffff',
        'text-halo-width': 1,
        'text-halo-color': '#000000',
        'text-halo-blur': 0,
      },
    },
    [MapGroupLayer.RANGE_RINGS, pointLayerId, rangeRing.id]
  );
};

export const addRangeRingLayer = (
  rangeRing: RangeRingProperties,
  pointLayerId: string
) => {
  addSimpleGeoJsonSource(rangeRing.id);

  MapLayerManager.AddLayerinGroups(
    {
      id: rangeRing.id,
      type: 'line',
      source: rangeRing.id,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'line-color': rangeRing.colour,
        'line-width': 3,
      },
    },
    [MapGroupLayer.RANGE_RINGS, pointLayerId, rangeRing.id]
  );
};

export const addRangeRingLayers = (
  rangeRing: RangeRingProperties,
  pointLayerId: string
) => {
  addRangeRingLayer(rangeRing, pointLayerId);
  addRangeRingLabelLayer({
    rangeRing,
    pointLayerId,
    labelLayerId: RangeRingLayers.LABEL(rangeRing.id),
  });
};

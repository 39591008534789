import { Position } from 'geojson';
import { HistoricVesselPoint } from '../../../maritime-menu-options/history-panel/historic-vessel-point.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { getUniqueHistoricVessels } from './get-unique-historic-vessels';

function createVesselJourneyFeatures(points: HistoricVesselPoint[]) {
  const uniqueVessels = getUniqueHistoricVessels(points);
  const journeyFeatures = uniqueVessels.map((vessel) => {
    const vesselGroup = points.filter((point) => point.vessel_id === vessel);

    const vesselGroupCoordinates: Position[] = vesselGroup.map((v) => [
      v.longitude,
      v.latitude,
    ]);

    const linestring180limit = GeoHelper.cleanAISFeatureHistoryLineString(
      GeoHelper.createFeatureLineString(vesselGroupCoordinates, {
        vessel_id: vessel,
      })
    );

    // mapbox uses -360 - +360 for longitude in order to handle the anti-meridian
    const lineString360Limit = GeoHelper.handleAntiMeridian(linestring180limit);
    return lineString360Limit;
  });

  return journeyFeatures;
}

export default createVesselJourneyFeatures;

import { Auth } from 'aws-amplify';

async function refreshToken() {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = cognitoUser.signInUserSession;
  const data = {
    token: currentSession.accessToken.jwtToken,
    expires_at: currentSession.idToken.payload.exp,
  };
  return data;
}

export default refreshToken;

import { MenuList, Paper } from '@mui/material';
import { useAppSelector } from '../../hooks';
import { LoginButton, LogoutButton } from './login-logout-button';
import ReturnToNorthStandardButton from './return-to-north-standard-button';
import './user-menu.scss';

function UserMenu() {
  const idToken = useAppSelector((state) => state.user.idToken);

  return (
    <Paper
      sx={{
        width: 320,
        backgroundColor: 'primary.main',
        maxWidth: '100%',
        borderRadius: '20px',
        color: 'secondary.main',
      }}
    >
      <MenuList sx={{ textAlign: 'left' }}>
        {idToken ? <LogoutButton /> : <LoginButton />}
        <ReturnToNorthStandardButton />
      </MenuList>
    </Paper>
  );
}

export default UserMenu;

import onRoutesIncidentsClusterClick from './routes-incidents-cluster-onClick';
import onRoutesIncidentsClick from './routes-incidents-onClick';

namespace RoutesIncidentsController {
  export const layerEvents = {
    onClick: onRoutesIncidentsClick,
    onClusterClick: onRoutesIncidentsClusterClick,
  };
}

export default RoutesIncidentsController;

import { CheckCircle } from '@mui/icons-material';
import { Button, ButtonProps, CircularProgress, Fade } from '@mui/material';
import { useEffect, useState } from 'react';

interface LoadingButtonProps {
  loading: boolean;
  success: boolean;
}

export default function LoadingButton({
  loading,
  success,
  disabled,
  children,
  ...rest
}: LoadingButtonProps & ButtonProps) {
  const [tempSuccess, setTempSuccess] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (success) {
      setTempSuccess(true);
      setTempLoading(false);
      setTimeout(() => {
        setTempSuccess(false);
      }, 1500);
    } else {
      setTempSuccess(false);
    }
  }, [success]);

  useEffect(() => {
    // Delay showing the loading indicator
    if (loading) {
      setTimer(
        setTimeout(() => {
          setTempLoading(true);
        }, 800)
      );
    } else {
      setTempLoading(false);
      // If loading finishes before the timeout, clear the timeout
      if (timer) clearTimeout(timer);
      setTimer(null);
    }
    return () => {
      if (timer) clearTimeout(timer);
      setTimer(null);
    };
  }, [loading]);

  // the disabled prop passed to the parent overrides any fancy logic
  const isDisabled = disabled || loading || tempSuccess;

  return (
    <Button
      disabled={isDisabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <Fade in={tempLoading} timeout={300}>
        <CircularProgress
          size={20}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-10px',
            marginLeft: '-10px',
          }}
        />
      </Fade>
      <Fade in={tempSuccess} timeout={300}>
        <CheckCircle
          sx={{
            position: 'absolute',
            fontSize: '30px',
            top: '50%',
            left: '50%',
            marginTop: '-15px',
            marginLeft: '-15px',
            color: 'black',
          }}
        />
      </Fade>
      {children}
    </Button>
  );
}

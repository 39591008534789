import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, List, ListItem } from '@mui/material';
import { ReduxStatesToVerify } from '../api';
import { useAppDispatch, useMobile } from '../hooks';
import { Incident } from '../maritime-menu-options/incidents-panel/incident.model';
import {
  Port,
  portKey,
} from '../maritime-menu-options/world-ports-panel/world-ports.model';
import { Boundary } from '../models/boundary.model';
import { Correspondent } from '../models/correspondents.model';
import { IDocument } from '../models/document';
import { Drawing } from '../models/drawings.model';
import { FirstCallFeature } from '../models/first-call';
import { Country, MaritimeArea } from '../models/risk_intelligence.model';
import {
  SearchableData,
  SearchableDataKey,
  SearchableDataValue,
} from '../models/search';
import { matchQuality } from '../utils/text-formatting.utils';
import './search-panel.scss';
import { setPanelStatus } from './search-panel.slice';
import SearchResultItem from './search-result-item';

function CloseSearchPanel() {
  const dispatch = useAppDispatch();

  return (
    <Button
      className="close-search-tab-button"
      type="button"
      onClick={() => {
        dispatch(setPanelStatus({ panelOpen: false }));
      }}
    >
      <FontAwesomeIcon icon={faAngleDoubleUp} />
    </Button>
  );
}

interface SearchData {
  dataType: SearchableDataKey;
  matchQuality: number;
  value: SearchableDataValue;
  id: string;
}

const filterResults = (data: SearchableData, query: string) => {
  if (!query) {
    return null;
  }
  const filteredData: SearchData[] = [];
  (
    Object.entries(data) as [SearchableDataKey, SearchableDataValue[]][]
  ).forEach(([key, value]) => {
    if (!value) {
      return;
    }
    switch (key) {
      case 'incidents': {
        const hits = (value as Incident[])
          .map((item) => ({
            matchQuality: matchQuality(item.title, query),
            value: item,
            dataType: key,
            id: `${key}-${item.id}`,
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) => a.value.title.localeCompare(b.value.title));
        filteredData.push(...hits);
        break;
      }
      case 'countries': {
        const hits = (value as Country[])
          .map((item) => ({
            matchQuality: matchQuality(item.title, query),
            value: item,
            dataType: key,
            id: `${key}-${item.id}`,
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) => a.value.title.localeCompare(b.value.title));
        filteredData.push(...hits);
        break;
      }
      case 'documents': {
        const hits = (value as IDocument[])
          .map((item) => ({
            matchQuality: matchQuality(item.metadata?.filename, query),
            value: item,
            dataType: key,
            id: `${key}-${item.eTag}`,
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) =>
            a.value.metadata!.filename.localeCompare(b.value.metadata!.filename)
          );
        filteredData.push(...hits);

        break;
      }
      case 'ports': {
        const hits = (value as Port[])
          .map((item) => ({
            matchQuality: matchQuality(item.NAME, query),
            value: item,
            dataType: key,
            id: portKey(item),
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) => a.value.NAME.localeCompare(b.value.NAME));
        filteredData.push(...hits);

        break;
      }
      case 'boundaries': {
        const hits = (value as Boundary[])
          .map((item) => ({
            matchQuality: matchQuality(item.name, query),
            value: item,
            dataType: key,
            id: `${key}-${item.boundary_id}`,
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) => a.value.name.localeCompare(b.value.name));
        filteredData.push(...hits);

        break;
      }
      case 'drawings': {
        const hits = (value as Drawing[])
          .map((item) => ({
            matchQuality: matchQuality(item.region_name, query),
            value: item,
            dataType: key,
            id: `${key}-${item.region_id}`,
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) =>
            a.value.region_name.localeCompare(b.value.region_name)
          );
        filteredData.push(...hits);

        break;
      }
      case 'maritimeAreas': {
        const hits = (value as MaritimeArea[])
          .map((item) => ({
            matchQuality: matchQuality(item.title, query),
            value: item,
            dataType: key,
            id: `${key}-${item.id}`,
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) => a.value.title.localeCompare(b.value.title));
        filteredData.push(...hits);
        break;
      }
      case 'firstCallPorts': {
        const hits = (value as FirstCallFeature[])
          .map((item) => ({
            matchQuality: matchQuality(item.properties.portName, query),
            value: item,
            dataType: key,
            id: `${key}-${item.id}`,
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) =>
            a.value.properties.portName.localeCompare(
              b.value.properties.portName
            )
          );
        filteredData.push(...hits);
        break;
      }
      case 'correspondents': {
        const hits = (value as Correspondent[])
          .map((item) => ({
            matchQuality: matchQuality(item.port_information.country, query),
            value: item,
            dataType: key,
            id: `${key}-${item.uid}`,
          }))
          .filter((item) => item.matchQuality !== 0)
          .sort((a, b) =>
            a.value.port_information.country.localeCompare(
              b.value.port_information.country
            )
          );
        filteredData.push(...hits);
        break;
      }
      default: {
        break;
      }
    }
  });

  return filteredData.sort((a, b) => b.matchQuality - a.matchQuality);
};

interface SearchResultsProps {
  searchableData: SearchableData;
  query: string;
  reduxLoadingComplete: Record<Partial<ReduxStatesToVerify>, boolean>;
}

function SearchResults({
  searchableData,
  query,
  reduxLoadingComplete,
}: SearchResultsProps) {
  const isMobile = useMobile();

  const allLoadingFinished = Object.values(reduxLoadingComplete).every(
    (finished) => finished
  );

  const filteredResults = filterResults(searchableData, query);

  if (!query) {
    return null;
  }

  if (
    (!filteredResults || filteredResults.length === 0) &&
    allLoadingFinished
  ) {
    return (
      <List className="search-results-container" data-testid="search-results">
        <ListItem
          alignItems="center"
          sx={{ justifyContent: 'center' }}
          data-testid="no-results"
        >
          No results found
        </ListItem>
      </List>
    );
  }

  return (
    <>
      <List
        className="search-results-container"
        data-testid="search-results"
        sx={{ maxHeight: isMobile ? '50vh' : '80vh' }}
      >
        {filteredResults
          ? filteredResults.map((item, index) => (
              <SearchResultItem
                key={item.id}
                item={item}
                last={index === filteredResults.length - 1}
              />
            ))
          : null}

        {!allLoadingFinished && (
          <ListItem alignItems="center" sx={{ justifyContent: 'center' }}>
            <CircularProgress size="2rem" />
          </ListItem>
        )}
      </List>
      {((filteredResults && filteredResults.length > 0) ||
        !allLoadingFinished) && <CloseSearchPanel />}
    </>
  );
}

export default SearchResults;

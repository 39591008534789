/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { ArrowDropUp, ArrowRight } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import 'flag-icons/css/flag-icons.min.css';
import { useState } from 'react';

interface RIAccordionProps {
  title: React.ReactNode;
  content: React.ReactNode;
  border?: boolean;
  borderRadius?: boolean;
  icon?: string;
  unmountOnExit?: boolean;
}

function RIAccordion({
  title,
  content,
  border = false,
  borderRadius,
  icon,
  unmountOnExit,
}: RIAccordionProps) {
  const [open, setOpen] = useState(false);
  return (
    <Accordion
      expanded={open}
      onChange={() => setOpen(!open)}
      disableGutters
      TransitionProps={{
        unmountOnExit,
      }}
    >
      <AccordionSummary
        expandIcon={open ? <ArrowDropUp /> : <ArrowRight />}
        aria-controls="panel1a-content"
        sx={{
          borderRadius: open ? 0 : borderRadius ? '0 0 1rem 1rem' : 0,
          bgcolor: open ? 'primary.light' : 'primary.main',
          color: open ? 'text.primary' : 'text.secondary',
          borderBottom: open ? '1px solid' : 'none',
          borderColor: 'background.paper',
          justifyContent: 'space-between',
          width: '100%',
          margin: 0,
        }}
        data-testid="toggle-documents-panel"
      >
        <Typography fontWeight={400}>
          {icon && <Box className={icon} />}
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0',
          backgroundColor: 'primary.main',
        }}
      >
        {content}
      </AccordionDetails>
    </Accordion>
  );
}

RIAccordion.defaultProps = {
  border: false,
  borderRadius: false,
  icon: '',
  unmountOnExit: true,
};

export default RIAccordion;

import mapboxgl from 'mapbox-gl';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayer, { MapGroupLayer } from '../map-layer.enum';
import setMaritimeAreasLayer from '../maritime-areas-utils/maritime-areas-layer';

function addRouteMaritimeAreasLayer(map: mapboxgl.Map) {
  if (!map.getLayer(MapLayer.ROUTES_MARITIME_AREAS)) {
    setMaritimeAreasLayer(
      MapLayer.ROUTES_MARITIME_AREAS,
      MapLayerManager.groupLayerMetaData([MapGroupLayer.ROUTES])
    );
  }
}

export default addRouteMaritimeAreasLayer;

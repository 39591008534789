/* eslint-disable import/prefer-default-export */
import { MergedSanctions } from '../dossiers_and_panels/country-dossier/sanctions/sanction.model';
import { wrapRequest } from './base';

export const getSanctions = async (
  countryCodes: string[]
): Promise<MergedSanctions> =>
  wrapRequest('get', 'north_standard', '/sanctions', {
    queryStringParameters: {
      endpoint: 'sanctions',
      'target-datetime': new Date().toISOString(),
      'iso2-country-code': countryCodes
        .map((code) => code.toLowerCase())
        .join(','),
    },
  });

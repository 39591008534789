import * as yup from 'yup';
import {
  validateLatitudeDms,
  validateLongitudeDms,
} from '../../../utils/validation-helpers.utils';

const measurementFormValidationSchema = yup.object().shape({
  points: yup.array().of(
    yup.object().shape({
      longitude: validateLongitudeDms(),
      latitude: validateLatitudeDms(),
    })
  ),
});

export default measurementFormValidationSchema;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const INITIAL_PANEL_STATUS = {
  panelOpen: false,
};

const INITIAL_SEARCH_PANEL_STATE = {
  panelStatus: INITIAL_PANEL_STATUS,
};

const SearchPanelSlice = createSlice({
  name: 'searchPanel',
  initialState: INITIAL_SEARCH_PANEL_STATE,
  reducers: {
    setPanelStatus: (state, action) => {
      state.panelStatus = { ...state.panelStatus, ...action.payload };
    },
  },
});

export const { setPanelStatus } = SearchPanelSlice.actions;

export default SearchPanelSlice.reducer;

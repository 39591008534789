const MapLayerIcon = {
  PLAIN_VESSEL: {
    HIGHLIGHTED: 'plain-vessel-highlighted',
    NORMAL: 'plain-vessel',
    ALERT: 'alert',
    BORDER: 'border',
    NEARBY: 'nearby',
  },
  RI_VESSEL: {
    HIGHLIGHTED: 'ri-vessel-highlighted',
    NORMAL: 'ri-vessel',
  },
  INCIDENT: {
    DEFAULT: 'incident-default-marker',
    SELECTED: 'incident-default-marker-alert',
  },
  PORT: {
    DEFAULT: 'port-icon',
    DEFAULT_RI: 'port-icon-ri',
    SELECTED: 'port-icon-selected',
    SELECTED_RI: 'port-icon-selected-ri',
    INLAND_RI: 'port-inland-ri',
    CRUISE_RI: 'port-cruise-ri',
    SELECTED_CRUISE_RI: 'port-cruise-selected-ri',
    FIRST_CALL_PORT: 'first-call-port',
    FIRST_CALL_PORT_SELECTED: 'first-call-port-selected',
    CORRESPONDENT: 'correspondent',
    CORRESPONDENT_SELECTED: 'correspondent-selected',
  },
  INDUSTRY_NEWS: {
    DEFAULT: 'indsutry-news-icon',
    SELECTED: 'indsutry-news-icon-selected',
  },
  HISTORIC_POSITION: 'historic-position',
  RADIO_POSITION: 'radio-position',
  WEATHER: {
    PIN: 'weather-pin',
  },
  VESSEL_SEARCH_AREA: {
    NORMAL: 'vessel-search-area-normal',
    AMBER: 'vessel-search-area-amber',
    ALERT: 'vessel-search-area-alert',
  },
};

export default MapLayerIcon;

import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import * as dateFns from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import {
  DataGroup,
  DataSet,
  TimelineOptions,
  TimelineTimeAxisScaleType,
  Timeline as VisTimeline,
} from 'vis-timeline/standalone';
import { VesselHistoryData } from '../../api';
import { useAppDispatch, useAppSelector } from '../../hooks';
import zIndexes from '../../z-indexes.scss';
import { MinimiseButton, MinimisedTimeline } from './minimised-timeline';
import TimelineDateButton, {
  DATE_BUTTON_WIDTH_PX,
} from './timeline-date-button';
import TimelinePlaybackController from './timeline-playback-controls';
import { setCentreDate, setContainerHeight } from './timeline.slice';
import {
  DateWithOffset,
  PLAYBACK_CONTROLS_WIDTH,
  VesselDataItem,
  convertVesselToVisItems,
  findNearestItemInEachGroup,
  isWithinDay,
  moveToWithOffset,
  onRangeChange,
  onSelectChange,
  onVesselPointChanged,
} from './timeline.utils';

const MENU_CLOSED = '5rem';
const MENU_OPEN = '20rem';
const SECONDARY_OPEN = '25vw';
const MINIMISE_TIME = 0.2; // seconds

function DateButtons({
  centreDate,
  timelineContainer,
  items,
  groups,
}: {
  centreDate: DateWithOffset;
  timelineContainer?: React.RefObject<HTMLDivElement>;
  items?: DataSet<VesselDataItem> | null;
  groups?: DataSet<DataGroup> | null;
}) {
  const theme = useTheme();
  const [widthPx, setWidthPx] = useState<number>(0);
  const [dateIndex, setDateIndex] = useState<number>(0);
  const [dates, setDates] = useState<{ index: number; date: Date }[]>([
    {
      index: 0,
      date: new Date(),
    },
  ]);
  const dispatch = useAppDispatch();

  const [datesToRender, setDatesToRender] =
    useState<{ index: number; date: Date }[]>(dates);
  const onDateButtonClick = (index: number, date: Date) => {
    setDateIndex(index);
    if (items && groups) {
      const nearestItems = findNearestItemInEachGroup(items, groups, date, {
        after: true,
      });
      if (nearestItems.nearestOverall) {
        dispatch(
          setCentreDate({
            alreadyOffset: false,
            date: new Date(nearestItems.nearestOverall.start).getTime(),
          })
        );
      }
    }
  };

  // floor(/2)*2 - 1 ensures odd number of buttons (centre button and even on each side)
  const maxItems = 2 * Math.floor(widthPx / DATE_BUTTON_WIDTH_PX / 2) - 1;

  useResizeObserver(timelineContainer?.current!, (e) => {
    setWidthPx(e.contentRect.width + PLAYBACK_CONTROLS_WIDTH);
  });

  useEffect(() => {
    if (!dates) {
      return;
    }
    const newDateIndex = dateFns.closestIndexTo(
      dateFns.startOfDay(centreDate.date),
      dates.map((d) => d.date)
    );
    // index can be 0
    if (typeof newDateIndex !== 'undefined') {
      setDateIndex(newDateIndex);
    }
  }, [centreDate]);

  useEffect(() => {
    if (!items || !items.length) {
      return;
    }
    const itemDates = items.get().map((item) => new Date(item.start));

    const earliestDate = dateFns.min(itemDates);
    const latestDate = dateFns.max(itemDates);
    const newDates =
      earliestDate &&
      latestDate &&
      dateFns
        .eachDayOfInterval({
          start: earliestDate,
          end: latestDate,
        })
        .map((date, index) => ({
          date,
          index,
        }));

    if (!newDates) {
      return;
    }
    setDates(newDates);
    const sliceLower = Math.max(0, dateIndex - Math.floor(maxItems / 2));
    const sliceUpper = Math.min(
      dates.length,
      dateIndex + Math.ceil(maxItems / 2)
    );

    const newDatesToRender = dates.slice(sliceLower, sliceUpper);
    setDatesToRender(newDatesToRender);
  }, [items, widthPx, dateIndex]);

  /* widthPx/2 finds the midpoint, where we want to centre the selected button.
   * Subtract half the width of the button to find the left edge of the button.
   * then shrink the left buffer a number of times the width of the button to find the left edge of the first button.
   * however, if we would end up with a negative number, that means the date
   * range is being sliced and earlier dates are being hidden.
   * want to figure out what the left edge of the first button would be.
   * In this case
   * widthPx/2 finds the midpoint, where we want to centre the selected button.
   * Subtract half the width of the button to find the left edge of the button.
   * maxItems/2 is the number of buttons to the left of the selected button.
   */
  let leftBufferWidth = Math.max(
    widthPx / 2 -
      DATE_BUTTON_WIDTH_PX / 2 -
      Math.floor(maxItems / 2) * DATE_BUTTON_WIDTH_PX,
    widthPx / 2 - DATE_BUTTON_WIDTH_PX / 2 - dateIndex * DATE_BUTTON_WIDTH_PX
  );

  const leftItemsHidden =
    datesToRender.length > 0 && datesToRender[0].index !== 0;
  const rightItemsHidden =
    datesToRender.length > 0 &&
    datesToRender[datesToRender.length - 1].index !== dates.length - 1;

  // if there are un-rendered dates to the left of the first button, add a bit of space for the left-arrow
  if (leftItemsHidden) {
    leftBufferWidth -= 10;
  }

  const buttons = datesToRender.map((date) => (
    <TimelineDateButton
      key={date.date.toISOString()}
      dateAndIndex={date}
      selectedDateIndex={dateIndex}
      disabled={!isWithinDay(date.date, items)}
      onClick={onDateButtonClick}
    />
  ));

  return (
    <Box
      sx={{
        height: '3rem',
        // little down arrow like a popover
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: 'calc(50% - 10px)',
          width: '0',
          height: '0',
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: `10px solid ${theme.palette.secondary.main}`,
        },
        zIndex: zIndexes['$--map-overlay-layer'], // otherwise popover is hidden
        backgroundColor: 'primary.light',
      }}
      display="flex"
      flexDirection="row"
      position="relative"
    >
      <Box
        sx={{
          width: `${leftBufferWidth}px`,
          height: '3rem',
          transition: 'width 0.5s ease-in-out',
        }}
      />
      {leftItemsHidden && (
        <Box
          sx={{
            height: '3rem',
            width: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <NavigateBefore />
        </Box>
      )}
      {buttons}
      {rightItemsHidden && (
        <Box
          sx={{
            height: '3rem',
            width: '10px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <NavigateNext />
        </Box>
      )}
      {/* don't need a right buffer really */}
    </Box>
  );
}

DateButtons.defaultProps = {
  timelineContainer: null,
  items: null,
  groups: null,
};

interface TimelineProps {
  vesselHistoryData?: VesselHistoryData | null;
}

export default function Timeline({ vesselHistoryData }: TimelineProps) {
  const timelineContainer = useRef<HTMLDivElement>(null);
  const focusContainer = useRef<HTMLDivElement>(null);

  const [timeline, setTimeline] = useState<VisTimeline | null>(null);
  const [items, setItems] = useState<DataSet<VesselDataItem> | null>();
  const [groups, setGroups] = useState<DataSet<DataGroup> | null>();
  const [focused, setFocused] = useState(false);

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { centreDate } = useAppSelector((state) => state.timeline);
  const tickerOpen = useAppSelector(
    (state) => state.userPreferences.userPreferences.showRiNewsBanner
  );

  const reducedMotion = useMediaQuery('(prefers-reduced-motion)');

  const { secondaryMenuOpen, menuOpen, timelineMinimised, selectedDossier } =
    useAppSelector((state) => state.menu);

  const options: TimelineOptions = {
    timeAxis: {
      scale: 'hour' as TimelineTimeAxisScaleType,
      step: 1,
    },
    // zoomMax and zoomMin are in milliseconds
    zoomMax: 2 * 7 * 24 * 60 * 60 * 1000, // 2 weeks
    zoomMin: 60 * 60 * 1000, // 1 hour
    showMajorLabels: false,
    stack: false,
    stackSubgroups: true,
    type: 'point',
    margin: {
      item: { horizontal: 0, vertical: 25 },
    },
    maxHeight: '46vh', // set such that half an element is visible so it is clear vertical scrolling is possible
  };

  useEffect(() => {
    if (timeline && centreDate.date) {
      if (!centreDate.alreadyOffset) {
        const offsettedDate = moveToWithOffset(
          timeline,
          new Date(centreDate.date)
        );
        if (!offsettedDate) {
          // calculating offset failed, likely due to timeline not yet rendered
          return;
        }
      }
      const newIds = onVesselPointChanged(
        items,
        groups,
        new Date(centreDate.date)
      );
      timeline.setSelection(newIds);
    }
  }, [centreDate]);

  useEffect(() => {
    if (!timelineContainer.current) {
      return () => {};
    }

    const { items: newItems, groups: newGroups } =
      convertVesselToVisItems(vesselHistoryData);

    setItems(newItems);
    setGroups(newGroups);

    const newTimeline = new VisTimeline(
      timelineContainer.current,
      newItems,
      newGroups,
      {
        ...options,
        // default range of the timeline on load. set to 18h either side of now (tbd)
        // only set it on initialise otherwise you can't zoom or scroll
        start: new Date(centreDate.date - 1000 * 60 * 60 * 18 * 1),
        end: new Date(centreDate.date + 1000 * 60 * 60 * 18 * 1),
      }
    );

    newTimeline.on('rangechanged', (e) => {
      const newTimeAxis = onRangeChange(dispatch, e);
      newTimeline.setOptions({
        ...options,
        timeAxis: {
          ...options.timeAxis,
          ...newTimeAxis,
        },
      });
    });

    newTimeline.on('select', (e) => {
      onSelectChange(newItems, e, dispatch);
    });
    newTimeline.on('click', () => {
      // vis-timeline intercepts the click event, preventing the timeline container component from firing 'onFocus'
      // this stops the keyboard shortcuts from being mounted
      focusContainer.current?.focus();
    });

    dispatch(setContainerHeight(focusContainer.current?.clientHeight || 0));

    // kick the centre date to run the useEffect. wait for the width transition to complete
    setTimeout(() => {
      dispatch(
        setCentreDate({
          date: centreDate.date,
          alreadyOffset: false,
        })
      );
    }, MINIMISE_TIME * 1000);

    setTimeline(newTimeline);

    return () => {
      newTimeline.destroy();
      setTimeline(null);
    };
  }, [timelineContainer, vesselHistoryData, timelineMinimised]);

  let width = '100vw';
  let right = '0';
  if (menuOpen) {
    // when the secondary menu is closed, dossiers mount in the same place as the secondary menu
    // if a dossier is mounted we need to account for it in the width calcs.
    width =
      secondaryMenuOpen || selectedDossier !== ''
        ? `calc(100vw - ${MENU_OPEN} - ${SECONDARY_OPEN})`
        : `calc(100vw - ${MENU_OPEN})`;
  } else {
    width =
      secondaryMenuOpen || selectedDossier !== ''
        ? `calc(100vw - ${MENU_CLOSED} - ${SECONDARY_OPEN})`
        : `calc(100vw - ${MENU_CLOSED})`;
  }
  if (timelineMinimised) {
    // minimised size overrides calculated size
    // sadly width can't be auto or the transition does not work
    width = '300px';
    right = '20vw';
  }

  return (
    <Paper
      tabIndex={0}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      sx={
        process.env.NODE_ENV === 'test'
          ? {}
          : {
              width,
              right,

              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              ...(timelineMinimised
                ? {}
                : {
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    borderLeft: '1px solid',
                    borderColor: 'primary.light',
                  }),
              position: 'fixed !important',
              bottom: tickerOpen ? 'var(--news-ticker-height)' : 0,
              backgroundColor: 'background.default',
              color: 'text.primary',
              textAlign: 'initial',
              ...(reducedMotion
                ? {}
                : {
                    transition: `width ${MINIMISE_TIME}s ease-in-out, right ${MINIMISE_TIME}s ease-in-out;`,
                  }),
              '& .vis-timeline': {
                border: 'none',
                borderLeft: '1px solid',
                borderColor: 'primary.light',
                cursor: 'grab',
              },
              '& .vis-text': {
                color: 'text.primary',
              },
              '& .vis-label': {
                color: 'text.primary',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              '& .vis-item.vis-point.vis-selected': {
                textAlign: 'center',
                borderRadius: '10px',
                py: '2px',
                top: '-2px',
                left: '-7px',
                '& .vis-item.vis-dot': {
                  top: '3px',
                },
              },

              '& .vis-grid.vis-minor': {
                borderColor: theme.palette.background.paper,
              },
              '& .vis-panel': {
                borderColor: theme.palette.background.paper,
              },
              '& .vis-cluster': {
                minWidth: '25px',
                borderRadius: '50%',
                '& .vis-item-content': {
                  padding: 0,
                },
              },
            }
      }
      id="vis-timeline"
      ref={focusContainer}
    >
      {timelineMinimised ? (
        <MinimisedTimeline centreDate={new Date(centreDate.date)} />
      ) : (
        <>
          <DateButtons
            centreDate={centreDate}
            timelineContainer={timelineContainer}
            items={items}
            groups={groups}
          />
          <MinimiseButton />
          <Box display="flex" alignItems="end">
            <TimelinePlaybackController
              focused={focused}
              timeline={timeline}
              items={items as DataSet<VesselDataItem, 'id'>}
              groups={groups}
              centreDate={centreDate}
            />
            <Box
              ref={timelineContainer}
              sx={{ width: `calc(100% - ${PLAYBACK_CONTROLS_WIDTH}px)` }}
            />
          </Box>
        </>
      )}
    </Paper>
  );
}

Timeline.defaultProps = {
  vesselHistoryData: null,
} as TimelineProps;

import { Grid, Typography } from '@mui/material';
import { IncidentLegendIcon } from '../../common-components/common-legend/common-legend';
import CommonLegend from '../../common-components/legend/common-legend-modal';
import { useAppSelector } from '../../hooks';

function IncidentsLegend() {
  const incidentTypes = useAppSelector(
    (state) => state.incidents.incidentTypes
  );

  const iconList = incidentTypes?.map((incidentType) => (
    <Grid
      container
      columns={6}
      alignItems="center"
      justifyContent="center"
      key={incidentType.id}
    >
      <Grid item xs={1}>
        <IncidentLegendIcon id={incidentType} type="round" />
      </Grid>
      <Grid item xs={5}>
        <Typography>{incidentType.title}</Typography>
      </Grid>
    </Grid>
  ));

  return CommonLegend(iconList);
}

export default IncidentsLegend;

/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import ListPicker from '../../../../common-components/list-picker/list-picker';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  calcDisplayedText,
  incidentTypeCache,
} from '../../../../utils/incidents-helpers';
import { setIncidentFilters } from '../../incident-panel.slice';

function TypesFilter() {
  const { incidentTypes } = useAppSelector((store) => store.incidents);
  const { filters } = useAppSelector((store) => store.incidentsPanel);
  const dispatch = useAppDispatch();

  const [selectedIncidentTypes, setSelectedIncidentTypes] = useState<number[]>(
    []
  );

  useEffect(() => {
    setSelectedIncidentTypes(filters.incidentTypes);
  }, [filters.incidentTypes]);

  const onSubmit = () => {
    dispatch(
      setIncidentFilters({
        incidentTypes: selectedIncidentTypes,
      })
    );
  };

  return (
    <ListPicker
      onSubmit={onSubmit}
      items={
        incidentTypes
          ? [...incidentTypes].sort((a, b) => a.title.localeCompare(b.title))
          : []
      }
      selectedItems={selectedIncidentTypes}
      setSelectedItems={setSelectedIncidentTypes}
      buttonText={calcDisplayedText(
        filters.incidentTypes,
        'Incident Types',
        incidentTypeCache
      )}
    />
  );
}
export default TypesFilter;

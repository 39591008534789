/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from 'geojson';
import { MaritimeAisApiLocationData } from '../../../models/maritime-ais-api';
import {
  defaultValues,
  NearbyVesselsFormValues,
} from './nearby-vessels-form/nearby-vessels-form-values';

interface NearbyVesselsState {
  nearbyVessels: MaritimeAisApiLocationData | null;
  nearbyVesselsFormValues: NearbyVesselsFormValues;
  nearbyVesselsPoints: Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]; // TODO this might be wrong
  loading: boolean;
  error: boolean;
}

const initialState: NearbyVesselsState = {
  nearbyVessels: null,
  nearbyVesselsFormValues: defaultValues,
  nearbyVesselsPoints: [],
  loading: false,
  error: false,
};

const nearbyVesselsSlice = createSlice({
  name: 'nearbyVessels',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setNearbyVessels: (
      state,
      action: PayloadAction<MaritimeAisApiLocationData | null>
    ) => {
      state.nearbyVessels = action.payload;
      state.loading = false;
      state.error = false;
    },
    setNearbyVesselsFormValues: (
      state,
      action: PayloadAction<Partial<NearbyVesselsFormValues>>
    ) => {
      state.nearbyVesselsFormValues = {
        ...state.nearbyVesselsFormValues,
        ...action.payload,
      };
    },
    setNearbyVesselsPoints: (
      state,
      action: PayloadAction<Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[]>
    ) => {
      state.nearbyVesselsPoints = action.payload;
      state.loading = false;
      state.error = false;
    },
    appendNearbyVessels: (
      state,
      action: PayloadAction<MaritimeAisApiLocationData>
    ) => {
      const { data: newData } = action.payload;
      if (newData) {
        return {
          ...state,
          nearbyVessels: {
            ...(state.nearbyVessels || {}),
            data: {
              ...(state.nearbyVessels?.data || {}),
              ...newData,
            },
          },
        };
      }
      return state;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setLoading,
  setNearbyVessels,
  appendNearbyVessels,
  setNearbyVesselsFormValues,
  setNearbyVesselsPoints,
  setError,
} = nearbyVesselsSlice.actions;

export default nearbyVesselsSlice.reducer;

import fastDeepEqual from 'fast-deep-equal';
import { useEffect } from 'react';

import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined';
import { Box, IconButton, Typography } from '@mui/material';
import SimpleSearchBar from '../../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import setPortFeatures from '../../../map/map-layer-manager/port-utils/set-port-features';

import { applyPortFilter } from '../../../api/risk-intelligence/ports';
import PortsList from '../ports-list/ports-list';
import {
  DEFAULT_PORT_FILTERS,
  setPortsFilters,
} from '../world-ports-panel.slice';
import { Port } from '../world-ports.model';
import PortsPanelFilterBlock from './ports-panel-filter-block';

type Props = {
  ports: Port[];
};

function FilterablePorts({ ports }: Props) {
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state) => state.portsPanel.filters);

  const filteredPorts: Port[] = applyPortFilter(ports, filters);

  const defaultFiltersSelected = fastDeepEqual(filters, DEFAULT_PORT_FILTERS);

  useEffect(() => {
    if (mapInitialised && filteredPorts) {
      setPortFeatures(MapLayer.PORTS, filteredPorts);
    }
  }, [mapInitialised, filteredPorts]);

  const updatePortTextFilter = (value: string) => {
    dispatch(setPortsFilters({ search: value }));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
        textAlign: 'left',
        flexGrow: 1,
      }}
    >
      <Box sx={{ display: 'flex', padding: '0px 20px' }}>
        <SimpleSearchBar
          handleChange={updatePortTextFilter}
          placeholder="Filter Ports by Name or Country"
          filterValue={filters.search}
        />
        <IconButton
          size="small"
          onClick={() => dispatch(setPortsFilters({ open: !filters.open }))}
        >
          <FilterAltOutlined />
        </IconButton>
      </Box>
      {filters.open && <PortsPanelFilterBlock />}

      {defaultFiltersSelected && !filteredPorts.length && (
        <Typography>No ports found</Typography>
      )}

      {!defaultFiltersSelected && !filteredPorts.length && (
        <Typography>No ports found with selected filters</Typography>
      )}

      <PortsList ports={filteredPorts} />
    </Box>
  );
}

export default FilterablePorts;

import { Box, List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getTravelAdvice } from '../../../../api/north-standard/travel-advice';
import RIAccordion from '../../../../common-components/accordion/ri-accordion';
import ErrorPanel from '../../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../../common-components/loading-panel/loading-panel';
import { useAppSelector } from '../../../../hooks';
import { iso2ToIso3CountryCodes } from '../../../../utils/iso2-iso3-country-codes';
import TravelAdviceItem from '../travel-advice-item/travel-advice-item';
import { TravelAdvice } from '../travel-advice.model';

export default function TravelAdviceTab() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [travelAdviceInfo, setTravelAdiviceInfo] = useState<
    TravelAdvice[] | null
  >(null);
  const selectedCountry = useAppSelector(
    (state) => state.countries.selectedCountry
  );

  useEffect(() => {
    // prevent fetching before country is loaded, or if already loading
    // [] means the call is complete, there's just no data

    if (!selectedCountry || loading) {
      return;
    }

    const iso3CountryCode =
      selectedCountry.iso3 || iso2ToIso3CountryCodes[selectedCountry.unlocode];

    setLoading(true);
    setHasError(false);
    if (iso3CountryCode) {
      getTravelAdvice([iso3CountryCode])
        .then((response) => {
          setTravelAdiviceInfo(response);
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setHasError(true);
      setLoading(false);
    }
  }, [selectedCountry]);

  const filteredByUS = travelAdviceInfo?.filter(
    (entry) => entry.source === 'US'
  );
  const filteredByUK = travelAdviceInfo?.filter(
    (entry) => entry.source === 'UK'
  );

  return (
    <Box
      sx={{ marginTop: '1em' }}
      data-testid="travel-advice-list"
      className="ri-country-panel"
    >
      {loading && <LoadingPanel />}
      {!loading && hasError && (
        <ErrorPanel message="Unable to fetch travel advice information. Please try again later..." />
      )}
      {!loading && !hasError && travelAdviceInfo && (
        <List
          sx={{
            maxWidth: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
            overflowX: 'auto',
            scrollbarWidth: 'thin',
          }}
        >
          <RIAccordion
            key="uk-travel-advice"
            icon="fi fi-gb"
            title={` UK Travel Advice (${
              filteredByUK ? filteredByUK.length : 0
            })`}
            borderRadius={false}
            content={
              filteredByUK && filteredByUK.length > 0 ? (
                filteredByUK.map((travelAdvice) => (
                  <TravelAdviceItem
                    key={`uk-travel-advice-${travelAdvice.uniqueId}`}
                    travelAdvice={travelAdvice}
                  />
                ))
              ) : (
                <Typography key="uk-no-travel-advice-msg" fontWeight="bold">
                  No Travel Advice Data
                </Typography>
              )
            }
          />
          <RIAccordion
            key="us-travel-advice"
            icon="fi fi-us"
            title={` US Travel Advice (${
              filteredByUS ? filteredByUS.length : 0
            })`}
            borderRadius={false}
            content={
              filteredByUS && filteredByUS.length > 0 ? (
                filteredByUS.map((travelAdvice) => (
                  <TravelAdviceItem
                    key={`us-travel-advice-${travelAdvice.uniqueId}`}
                    travelAdvice={travelAdvice}
                  />
                ))
              ) : (
                <Typography key="us-no-travel-advice-msg" fontWeight="bold">
                  No Travel Advice Data
                </Typography>
              )
            }
          />
        </List>
      )}
    </Box>
  );
}

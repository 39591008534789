import { FormGroup, List, ListItem } from '@mui/material';
import useVesselHistoryLayerList from '../use-vessel-history-layer-list';
import HistoryResultsLayerItem from './history-results-layer-item';

function HistoryResultsVisualisationOptions() {
  const vesselHistoryLayerList = useVesselHistoryLayerList();
  const options = [
    {
      label: 'Vessels',
      sources: vesselHistoryLayerList.VESSELS,
    },
    {
      label: 'Vessel Journeys',
      sources: vesselHistoryLayerList.JOURNEYS,
    },
    {
      label: 'AIS Data Gaps',
      sources: vesselHistoryLayerList.AIS_DATA_GAPS,
    },
    {
      label: 'AIS Positions',
      sources: vesselHistoryLayerList.AIS_POSITIONS,
    },
    {
      label: 'AIS Heatmaps',
      sources: vesselHistoryLayerList.AIS_HEATMAPS,
    },
  ];

  return (
    <FormGroup>
      <List>
        {options.map((option) => (
          <ListItem
            disableGutters
            disablePadding
            key={option.label}
            sx={{
              borderBottom: '1px solid',
              borderColor: 'background.paper',
            }}
          >
            <HistoryResultsLayerItem
              label={option.label}
              sources={option.sources}
            />
          </ListItem>
        ))}
      </List>
    </FormGroup>
  );
}

export default HistoryResultsVisualisationOptions;

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Typography } from '@mui/material';
import MapLayerPaint from '../../map/map-layer-manager/map-layer-paint';

import './heatmap-key.scss';

interface HeatmapKeyProps {
  label: string;
}

// build gradient string from Heatmap Layer Style
const buildGradientString = () => {
  const heatmapGradient = MapLayerPaint.DEFAULT_HEATMAP_GRADIENT;
  const colourCount = heatmapGradient.length / 2;
  const gradientList = [];

  for (let i = 0; i < colourCount; i += 1) {
    const colour = heatmapGradient[i * 2 + 1];
    const percentage = Number(heatmapGradient[i * 2]);

    gradientList.push(`${colour} ${percentage * 100}%`);
  }

  return `linear-gradient( to right, ${gradientList.join(', ')})`;
};

function HeatmapKey(props: HeatmapKeyProps) {
  const { label } = props;
  const background = buildGradientString();

  return (
    <div className="heatmap-container">
      <Box
        sx={{
          background,
          height: '40px',
          width: '100%',
          borderRadius: '10px',
          borderColor: 'primary',
          border: 2,
        }}
      />
      <Typography
        variant="subtitle2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'center',
          margin: '10px 10px',
        }}
        gutterBottom
      >
        {label} &nbsp;
        <ArrowForwardIcon />
      </Typography>
    </div>
  );
}

export default HeatmapKey;

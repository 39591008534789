import { SxProps, Typography } from '@mui/material';
import { getThreatAssessmentRevisionLabel } from './threat-assessment-helpers';
import { ThreatAssessment } from './threat-assessment.model';

interface ThreatAssessmentRevisionLabelProps
  extends Pick<ThreatAssessment, 'underRevision'> {
  sx?: SxProps;
}

function ThreatAssessmentRevisionLabel(
  props: ThreatAssessmentRevisionLabelProps
) {
  const { underRevision, sx } = props;

  return (
    <Typography
      sx={{
        textAlign: 'left',
        ...sx,
      }}
      variant="subtitle2"
      color={underRevision ? 'red' : undefined}
    >
      {getThreatAssessmentRevisionLabel(underRevision)}
    </Typography>
  );
}

ThreatAssessmentRevisionLabel.defaultProps = {
  sx: {},
};

export default ThreatAssessmentRevisionLabel;

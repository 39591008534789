import { Box, Typography } from '@mui/material';
import DmsCoordinates from 'dms-conversion';

import { useState } from 'react';
import { BackOneButton } from '../../../common-components/breadcrumb/breadcrumb';
import { Vessel } from '../../../models/vessel.model';
import VesselInformationLabel from '../vessel-information/vessel-information-card/vessel-information-label/vessel-information-label';

function RenderInformation(
  riVessel: Vessel,
  open: boolean,
  handleClick: () => void,
  isDocked: boolean
) {
  const { latitude, longitude, name, imo, speed, course, endTimestamp } =
    riVessel;

  const dmsCoords = new DmsCoordinates(latitude, longitude);
  const { dmsArrays } = dmsCoords;

  const [dLong, mLong, sLong, nsewLong] = dmsArrays.longitude;
  const [dLat, mLat, sLat, nsewLat] = dmsArrays.latitude;

  const longitudeFormatted = `${dLong.toFixed(0)}° ${mLong.toFixed(
    0
  )} ${sLong.toFixed(0)}" ${nsewLong}`;
  const latitudeFormatted = `${dLat.toFixed(0)}° ${mLat.toFixed(
    0
  )} ${sLat.toFixed(0)}" ${nsewLat}`;

  return (
    <Box data-testid="vessel-information">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: `${isDocked ? '1.4625em' : '2.24em'}`,
        }}
      >
        {!isDocked && <BackOneButton />}
        <Typography
          sx={{
            fontSize: `${isDocked ? '18px' : '22px'}`,
            textAlign: 'left',
          }}
        >
          {name}
        </Typography>
      </Box>

      <Box className="vessel-information">
        {imo && <VesselInformationLabel title="IMO" data={imo} />}
        <VesselInformationLabel title="Latitude" data={latitudeFormatted} />
        <VesselInformationLabel title="Longitude" data={longitudeFormatted} />
        <VesselInformationLabel
          title="Speed"
          data={`${speed?.toFixed(2) || 0.0} knots`}
        />
        <VesselInformationLabel title="Course" data={`${course.toFixed(0)}°`} />
        <VesselInformationLabel title="Last reported" data={endTimestamp!} />
      </Box>
    </Box>
  );
}

function RiVesselInformation(props: {
  isDocked: boolean;
  selectedVessel: Vessel | null;
}) {
  const { isDocked, selectedVessel } = props;

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box>
      {selectedVessel !== null &&
        RenderInformation(selectedVessel, open, handleClick, isDocked)}
    </Box>
  );
}

export default RiVesselInformation;

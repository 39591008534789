/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import ListPicker from '../../../../common-components/list-picker/list-picker';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  calcDisplayedText,
  incidentAreaCache,
} from '../../../../utils/incidents-helpers';
import { setIncidentFilters } from '../../incident-panel.slice';

function AreasFilter() {
  const { incidentAreas } = useAppSelector((store) => store.incidents);
  const { filters } = useAppSelector((store) => store.incidentsPanel);
  const dispatch = useAppDispatch();

  const [selectedAreas, setSelectedAreas] = useState<number[]>([]);

  useEffect(() => {
    setSelectedAreas(filters.areas);
  }, [filters.areas]);

  const onSubmit = () => {
    dispatch(
      setIncidentFilters({
        areas: selectedAreas,
      })
    );
  };

  return (
    <ListPicker
      onSubmit={onSubmit}
      items={incidentAreas || []}
      selectedItems={selectedAreas}
      setSelectedItems={setSelectedAreas}
      buttonText={calcDisplayedText(filters.areas, 'Areas', incidentAreaCache)}
    />
  );
}
export default AreasFilter;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { IconButton, Stack, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  INITIAL_INCIDENTS_PANEL_STATE,
  setIncidentFilters,
} from '../incident-panel.slice';
import { IncidentFilters } from '../incident.model';
import AlertsFilter from './alerts-filter/alerts-filter';
import AreasFilter from './areas-filter/areas-filter';
import IncidentsDateFilter from './date-filter/date-filter';
import './incident-filter-block.scss';
import PlaceFilter from './place-filter/place-filter';
import RegionsFilter from './regions-filter/regions-filter';
import TargetsFilter from './targets-filter/targets-filter';
import TimeOfDayFilter from './time-of-day-filter/time-of-day-filter';
import TypesFilter from './types-filter/types-filter';

const incidentsPanelDateRangeSx: CSSProperties = {
  borderBottom: '1px solid',
  borderBottomColor: 'background.paper',
  textAlign: 'left',
  display: 'flex',
  justifyContent: 'space-between',
  minHeight: '40px',
  padding: '6px 24px 6px 16px',
  fontSize: '16px',
  fontWeight: '400',
  color: 'text.primary',
  borderRadius: '0',
  width: '100%',
};

export function ClearFiltersButton() {
  const dispatch = useAppDispatch();
  const handleClearFilters = () => {
    dispatch(setIncidentFilters(INITIAL_INCIDENTS_PANEL_STATE.filters));
  };
  return (
    <Stack direction="row" justifyContent="right">
      <IconButton onClick={handleClearFilters} sx={{ borderRadius: '28px' }}>
        <CloseIcon />
        <Typography>Clear Filters</Typography>
      </IconButton>
    </Stack>
  );
}

export function IncidentFiltersButton() {
  const incidentFilters = useAppSelector<IncidentFilters>(
    (state) => state.incidentsPanel.filters
  );
  const dispatch = useAppDispatch();
  const toggleOpen = () => {
    dispatch(setIncidentFilters({ open: !incidentFilters.open }));
  };
  return (
    <Stack direction="row" justifyContent="right">
      <IconButton className="filtersButton" onClick={() => toggleOpen()}>
        <FilterAltOutlinedIcon />
      </IconButton>
    </Stack>
  );
}

export function IncidentFiltersBlock() {
  const { open } = useAppSelector<IncidentFilters>(
    (state) => state.incidentsPanel.filters
  );

  return (
    <Stack
      data-testid="filter-block"
      direction="column"
      justifyContent="flex-start"
      aria-expanded={open}
    >
      <IncidentsDateFilter sx={incidentsPanelDateRangeSx} dateArrow />
      <RegionsFilter />
      <AreasFilter />
      <TimeOfDayFilter />
      <TypesFilter />
      <PlaceFilter />
      <TargetsFilter />
      <AlertsFilter />
    </Stack>
  );
}

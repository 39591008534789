/* eslint-disable import/prefer-default-export */
import { Boundary } from '../models/boundary.model';
import { wrapRequest } from './base';

export const getBoundaries = async (): Promise<Boundary[]> =>
  wrapRequest('get', 'geonius', '/boundaries', {
    queryStringParameters: {
      format: 'vector_tile',
    },
  });

export const getCombinedEEZ = async (): Promise<Boundary[]> =>
  wrapRequest('get', 'geonius', '/boundaries', {
    queryStringParameters: {
      url: 'mapbox://geollectryan.eez_combined',
    },
  });

export const getCombinedJWC = async (): Promise<Boundary[]> =>
  wrapRequest('get', 'geonius', '/boundaries', {
    queryStringParameters: {
      url: 'mapbox://geollectryan.jwc_combined',
    },
  });

export const getCombinedTTW = async (): Promise<Boundary[]> =>
  wrapRequest('get', 'geonius', '/boundaries', {
    queryStringParameters: {
      url: 'mapbox://geollectryan.ttw_combined',
    },
  });

export const COMBINEDBOUNDARIESURLS = [
  'mapbox://geollectryan.eez_combined',
  'mapbox://geollectryan.jwc_combined',
  'mapbox://geollectryan.ttw_combined',
];

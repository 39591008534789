import { AnyAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import timelineReducer from './common-components/timeline/timeline.slice';
import threatAssessmentReducer from './dossiers_and_panels/threat-assessments/threat-assessment.slice';
import vesselDossierReducer from './dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import menuReducer from './main-menu/menu.slice';
import mapReducer from './map/map.slice';
import drawingsPanelReducer from './maritime-menu-options/areas-panel/drawings.slice';
import boundariesPanelReducer from './maritime-menu-options/boundaries-panel/boundaries-panel.slice';
import correspondentsPanelReducer from './maritime-menu-options/correspondents-panel/correspondents-panel.slice';
import countriesPanelReducer from './maritime-menu-options/countries-panel/countries-panel.slice';
import DocumentsReducer from './maritime-menu-options/documents-panel/documents.slice';
import firstCallPanelReducer from './maritime-menu-options/first-call-panel/first-call-panel.slice';
import historyPanelReducer from './maritime-menu-options/history-panel/history-panel.slice';
import incidentsPanelReducer from './maritime-menu-options/incidents-panel/incident-panel.slice';
import riMaritimeAreasReducer from './maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import routesPanelReducer from './maritime-menu-options/routes-panel/routes-panel.slice';
import nearbyVesselsReducer from './maritime-menu-options/tools-panel/nearby-vessels/nearby-vessels.slice';
import toolsPanelReducer from './maritime-menu-options/tools-panel/tools-panel.slice';
import weatherReducer from './maritime-menu-options/weather-panel/weather-panel.slice';
import portsPanelReducer from './maritime-menu-options/world-ports-panel/world-ports-panel.slice';
import navHistoryReducer from './nav-history.slice';
import searchPanelReducer from './search-bar/search-panel.slice';
import boundariesReducer from './state/boundaries/boundaries.slice';
import correspondentsReducer from './state/correspondents/correspondents.slice';
import countriesReducer from './state/countries/countries.slice';
import drawingsReducer from './state/drawings/drawings.slice';
import incidentsReducer from './state/incidents/incidents.slice';
import industryNewsReducer from './state/industry-news/industry-news.slice';
import firstCallReducer from './state/ports/first-call.slice';
import portsReducer from './state/ports/ports.slice';
import routesReducer from './state/routes/routes.slice';
import applySetupBarSliceReducer from './user-settings/apply-setup-bar/apply-setup-bar.slice';
import userPreferencesReducer from './user-settings/user-preferences/user-preferences.slice';
import userReducer from './user/user.slice';

const appReducer = combineReducers({
  industryNews: industryNewsReducer,
  applySetupBar: applySetupBarSliceReducer,
  boundaries: boundariesReducer,
  boundariesPanel: boundariesPanelReducer,
  countries: countriesReducer,
  countriesPanel: countriesPanelReducer,
  correspondents: correspondentsReducer,
  correspondentsPanel: correspondentsPanelReducer,
  historyPanel: historyPanelReducer,
  incidents: incidentsReducer,
  incidentsPanel: incidentsPanelReducer,
  firstCallPorts: firstCallReducer,
  firstCallPortsPanel: firstCallPanelReducer,
  map: mapReducer,
  menu: menuReducer,
  navHistory: navHistoryReducer,
  ports: portsReducer,
  portsPanel: portsPanelReducer,
  drawings: drawingsReducer,
  drawingsPanel: drawingsPanelReducer,
  routes: routesReducer,
  routesPanel: routesPanelReducer,
  searchPanel: searchPanelReducer,
  threatAssessment: threatAssessmentReducer,
  user: userReducer,
  userPreferences: userPreferencesReducer,
  vesselDossier: vesselDossierReducer,
  riMaritimeAreas: riMaritimeAreasReducer,
  toolsPanel: toolsPanelReducer,
  documents: DocumentsReducer,
  nearbyVessels: nearbyVesselsReducer,
  timeline: timelineReducer,
  weather: weatherReducer,
});

export const RESET_ACTION = {
  type: 'RESET',
};

type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppState | undefined, action: AnyAction) => {
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
  // reset state of Redux Store
  if (action.type === RESET_ACTION.type) {
    localStorage.clear();
    return appReducer(undefined, { type: undefined });
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // map: make map instance accessible to entire app. Allows us to perform map actions and listen to map events
        // selectedVessel: mapbox geojson feature - will allow us to modify properties or perform action on selected feature
        ignoredActions: ['map/setMap', 'vessel-dossier/setSelectedVessel'],
        ignoredPaths: ['map.map', 'vesselDossier.selectedVessel'],
      },
    }),
  devTools: {
    stateSanitizer: (state) => {
      const appState = state as AppState;
      return {
        ...state,
        map: {
          ...appState.map,
          map: '<<MAP INSTANCE>>' as any,
        },
      } as any;
    },
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;

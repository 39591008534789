import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Feature, Position } from '@turf/helpers';
import {
  destination as turfDestination,
  length as turfLength,
} from '@turf/turf';
import classNames from 'classnames';
import {
  Form,
  FormikHelpers,
  FormikProvider,
  isString,
  useFormik,
} from 'formik';
import { LineString } from 'geojson';
import { useEffect, useRef, useState } from 'react';

import { postDrawing, postUpdateDrawing } from '../../api/drawings';
import MuiFormikTextField from '../../common-components/form-fields/mui-formik-text-field';
import MuiSelectField from '../../common-components/form-fields/mui-select-field';
import FormErrorMessage from '../../common-components/form-messages/form-error-message';
import FormLoadingMessage from '../../common-components/form-messages/form-loading-message';
import FormSuccessMessage from '../../common-components/form-messages/form-success-message';
import { useMobile } from '../../hooks';
import useAccessControl from '../../hooks/access-control/useAccessControl';
import { drawCircle } from '../../map/drawing/drawing.utils';
import {
  DMSToDecimal,
  addDrawTipsPopup,
  decimalToDMS,
  themedDraw,
} from '../../map/map-controls.utils';
import GeoHelper from '../../utils/geo-helpers.utils';
import {
  convertKmToNauticalMiles,
  convertNauticalMilesToKm,
} from '../../utils/measurement-helpers';

import MapHelpers from '../../map/map.utils';
import {
  CreateDrawingValues,
  Drawing,
  DrawingShape,
  DrawingSubmit,
  DrawingUpdate,
  PartialCreateDrawingValues,
} from '../../models/drawings.model';
import { nsTheme } from '../../theme';
import CircleDrawingForm from './create-circle-drawing-form';
import {
  DrawingFormValues,
  validateForm,
} from './create-drawing-form-validators';
import './create-drawing-panel.scss';
import MultiPointDrawingForm from './create-multipoint-drawing-form';
import SinglePointDrawingForm from './create-singlepoint-drawing-form';
import {
  updateMapboxDrawCircle,
  updateMapboxDrawLine,
  updateMapboxDrawPolygon,
} from './modify-map-layer-utils';

export function removeTemporaryCircleFeatures() {
  if (MapHelpers.getLayer('circle-edit-temp')) {
    MapHelpers.removeLayer('circle-edit-temp');
  }
  if (MapHelpers.getSource('circle-edit-temp')) {
    MapHelpers.removeSource('circle-edit-temp');
  }
}

const floatEqual = (a: number, b: number) => Math.abs(a - b) < 0.0000001;

// const setDrawButtonsDisabled = (disable: boolean) => {
//   const buttonSelectors = {
//     Circle: '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_circle',
//     Polygon: '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_polygon',
//     Line: '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_line',
//     Point: '.mapbox-gl-draw_ctrl-draw-btn.mapbox-gl-draw_point',
//   };

//   const buttons = {
//     Circle: document.querySelector(
//       buttonSelectors.Circle
//     ) as HTMLButtonElement | null,
//     Polygon: document.querySelector(
//       buttonSelectors.Polygon
//     ) as HTMLButtonElement | null,
//     Line: document.querySelector(
//       buttonSelectors.Line
//     ) as HTMLButtonElement | null,
//     Point: document.querySelector(
//       buttonSelectors.Point
//     ) as HTMLButtonElement | null,
//   };

//   Object.entries(buttons).forEach(([, button]) => {
//     if (button) {
//       // eslint-disable-next-line no-param-reassign
//       button.disabled = disable;
//     }
//   });
// };

const convertPolygonToCoordPairs = (
  geoJSON: GeoJSON.Feature<GeoJSON.Polygon>
) => {
  const coordPairs: [string, string][] = [];
  const coords = (geoJSON as GeoJSON.Feature<GeoJSON.Polygon>).geometry
    .coordinates[0];
  coords.forEach((coordinate, index) => {
    if (index !== coords.length - 1) {
      coordPairs.push([
        decimalToDMS(coordinate[0], 'longitude', true),
        decimalToDMS(coordinate[1], 'latitude', true),
      ]);
    }
  });
  return coordPairs;
};

export const convertLineToCoordPairs = (
  geoJSON: GeoJSON.Feature<GeoJSON.LineString>
) => {
  const coordPairs: [string, string][] = [];
  const coords = (geoJSON as GeoJSON.Feature<GeoJSON.LineString>).geometry
    .coordinates;
  coords.forEach((coordinate, index) => {
    if (index !== coords.length - 1) {
      coordPairs.push([
        decimalToDMS(coordinate[0], 'longitude', true),
        decimalToDMS(coordinate[1], 'latitude', true),
      ]);
    }
  });
  return coordPairs;
};

interface CreateDrawingFormProps {
  onCreate: (
    values: DrawingSubmit,
    regionId: string,
    resetForm: () => void
  ) => void;
  initialValues?: PartialCreateDrawingValues;
  drawingToEdit?: Drawing | null;
}

function CreateDrawingForm({
  onCreate,
  initialValues: iVals,
  drawingToEdit,
}: CreateDrawingFormProps) {
  const isMobile = useMobile();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  // shape shifting is when the user is editing a current drawing and selects
  // a different shape type on the map controls (bottom right of screen) and
  // draws a new shape with that
  const [shapeShifting, setShapeShifting] = useState(false);
  const tooltipsAddedRef = useRef(false);
  const { canDrawPoints } = useAccessControl();

  const [currentDrawing, setCurrentDrawing] = useState<GeoJSON.Feature | null>(
    null
  );

  const draw = themedDraw;

  // passed in initial values will override the default values
  const initialValues: CreateDrawingValues = {
    common: {
      name: shapeShifting ? '' : drawingToEdit?.region_name || '',
      description: shapeShifting ? '' : drawingToEdit?.description || '',
      shape: iVals?.common?.shape || DrawingShape.Circle,
      ...iVals?.common,
      edited: iVals?.common?.edited || false,
    },
    [DrawingShape.Circle]: {
      ...(iVals && iVals[DrawingShape.Circle]),
      radiusInNauticalMilesInput:
        convertKmToNauticalMiles(
          parseFloat(
            iVals![DrawingShape.Circle]?.radiusInNauticalMilesInput ?? '0'
          )
        ).toFixed(4) ?? '',
      centrePointInputLat: decimalToDMS(
        Number(iVals![DrawingShape.Circle]?.centrePointInputLat! || 0),
        'latitude',
        true
      ),
      centrePointInputLong: decimalToDMS(
        Number(iVals![DrawingShape.Circle]?.centrePointInputLong! || 0),
        'longitude',
        true
      ),
    },
    [DrawingShape.Polygon]: {
      coordinates: [],
      ...(iVals && iVals[DrawingShape.Polygon]),
    },
    [DrawingShape.Line]: {
      coordinates: [],
      ...(iVals && iVals[DrawingShape.Line]),
    },
    [DrawingShape.Point]: {
      ...(iVals && iVals[DrawingShape.Point]),
      inputLong: iVals![DrawingShape.Point]?.inputLong! || `0`,
      inputLat: iVals![DrawingShape.Point]?.inputLat! || `0`,
    },
  };

  /**
   * Ensure all values are converted to floats before being sent to the backend.
   * @param dataValues
   * @returns
   */
  const convertValuesToDrawingData = (dataValues: any): GeoJSON.Feature => {
    switch (dataValues.shape) {
      // TODO: Revisit this. Commented out incase RI want perfect squares and rectangles
      // case DrawingShape.Rectangle:
      //   return GeoHelper.createFeatureRectangle(
      //     GeoHelper.createRectangle(
      //       parseFloat(dataValues.topLeftLongitude),
      //       parseFloat(dataValues.topLeftLatitude),
      //       parseFloat(dataValues.bottomRightLongitude),
      //       parseFloat(dataValues.bottomRightLatitude)
      //     ),
      //     {}
      //   );
      case DrawingShape.Polygon: {
        const [firstLatLong] = dataValues.coordinates;
        const lastLatLong =
          dataValues.coordinates[dataValues.coordinates.length - 1];
        if (
          !floatEqual(firstLatLong[0], lastLatLong[0]) ||
          !floatEqual(firstLatLong[1], lastLatLong[1])
        ) {
          // eslint-disable-next-line no-param-reassign
          dataValues.coordinates = [
            ...dataValues.coordinates,
            dataValues.coordinates[0],
          ];
        }

        const floatCoordinates: number[][] = dataValues.coordinates.map(
          (pair: [string | number, string | number]) =>
            pair.map((coord) => {
              const coordAsString = isString(coord) ? coord : coord.toString();
              return DMSToDecimal(coordAsString);
            })
        );

        // GeoJSON Polygon requires a three-dimensional array see - https://en.wikipedia.org/wiki/GeoJSON
        const polygonCoordinates: Position[][] = [floatCoordinates];
        return GeoHelper.createFeaturePolygon(polygonCoordinates, {});
      }
      case DrawingShape.Circle:
        return GeoHelper.createFeatureCircle(
          GeoHelper.createCircle(
            convertNauticalMilesToKm(
              parseFloat(dataValues.radiusInNauticalMilesInput)
            ),
            DMSToDecimal(dataValues.centrePointInputLong)!,
            DMSToDecimal(dataValues.centrePointInputLat)!
          ),
          {}
        );
      case DrawingShape.Line: {
        const decimalCoordinates = dataValues.coordinates.map(
          (coordinate: string[]) => [
            DMSToDecimal(coordinate[0]),
            DMSToDecimal(coordinate[1]),
          ]
        );

        return GeoHelper.createFeatureLineString(decimalCoordinates, {});
      }
      case DrawingShape.Point: {
        const decimalCoordinates = [
          DMSToDecimal(dataValues.inputLong),
          DMSToDecimal(dataValues.inputLat),
        ] as Position;

        return GeoHelper.createFeaturePoint(decimalCoordinates, {});
      }
      default:
        throw new Error(
          `Form type shape ${dataValues.shape} is not an available shape.`
        );
    }
  };

  const onSubmit = (
    values: DrawingFormValues,
    { resetForm }: { resetForm: FormikHelpers<DrawingFormValues>['resetForm'] }
  ) => {
    const { name, description, ...drawingShapeValues } = values;
    const regionData = convertValuesToDrawingData(drawingShapeValues);

    const submitValues: DrawingSubmit = {
      region_name: name,
      description,
      region_data: regionData,
    };
    // adding region_id to submitValues if editing
    if (drawingToEdit && !shapeShifting) {
      submitValues.region_id = drawingToEdit.region_id;
    }

    setSuccess(false);
    setError(false);
    setLoading(true);

    const postOrUpdate =
      drawingToEdit && !shapeShifting ? postUpdateDrawing : postDrawing;

    postOrUpdate(submitValues as DrawingUpdate)
      .then((response) => {
        if (currentDrawing && !shapeShifting) {
          draw.delete(currentDrawing?.id as string);
        }
        const regionId =
          drawingToEdit && !shapeShifting
            ? drawingToEdit.region_id
            : response.region_id;

        onCreate(submitValues, regionId, resetForm);
        setError(false);
        setSuccess(true);
        setCurrentDrawing(null);
        removeTemporaryCircleFeatures();
        draw?.deleteAll();
        resetForm({
          values: {
            ...initialValues.common,
            ...initialValues[initialValues.common.shape],
          },
        });
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
      })
      .finally(() => {
        setLoading(false);
        setShapeShifting(false);
      });
  };

  const formikProps = useFormik({
    initialValues: {
      ...initialValues.common,
      ...initialValues[initialValues.common.shape],
    },
    validate: validateForm,
    onSubmit,
    enableReinitialize: true,
  });

  const handleResetForm = () => {
    const currentShape = formikProps.values.shape;

    formikProps.resetForm();

    formikProps.setFieldValue('shape', currentShape);

    if (currentShape === DrawingShape.Polygon) {
      formikProps.setFieldValue('coordinates', [
        [`00° 00' 00" E`, `00° 00' 00" N`],
        [`00° 00' 00" E`, `00° 00' 00" N`],
        [`00° 00' 00" E`, `00° 00' 00" N`],
      ]);
    } else if (currentShape === DrawingShape.Line) {
      formikProps.setFieldValue('coordinates', [
        [`00° 00' 00" E`, `00° 00' 00" N`],
        [`00° 00' 00" E`, `00° 00' 00" N`],
        [`00° 00' 00" E`, `00° 00' 00" N`],
      ]);
    } else if (currentShape === DrawingShape.Point) {
      formikProps.setFieldValue('inputLong', `00° 00' 00" E`);
      formikProps.setFieldValue('inputLat', `00° 00' 00" N`);
    } else if (currentShape === DrawingShape.Circle) {
      formikProps.setFieldValue('centrePointInputLong', `00° 00' 00" E`);
      formikProps.setFieldValue('centrePointInputLat', `00° 00' 00" N`);
      formikProps.setFieldValue('radiusInNauticalMilesInput', '0.0000');
    }

    setCurrentDrawing(null);
    removeTemporaryCircleFeatures();
    draw.deleteAll();
  };

  /**
   * When the Form is updated, we must also update the visual representation of the drawing on the map.
   * We can determine how to update the drawing based on the name of the input that was changed.
   * @param e
   * @returns void
   */
  const onChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target as { name: string; value: string };
    if (!currentDrawing) {
      // If user is manually creating a new drawing
      if (formikProps.values.shape === DrawingShape.Circle) {
        setCurrentDrawing({
          type: 'Feature',
          properties: {
            subType: 'Circle',
            radius: 0,
          },
          geometry: {
            type: 'LineString',
            coordinates: [
              [0, 0],
              [0, 0],
            ],
          },
        });
        drawCircle('circle-edit-temp', [0, 0], 0, {
          'fill-color': nsTheme.colourScheme.drawActive,
          'fill-opacity': 0.2,
        });
      } else if (formikProps.values.shape === DrawingShape.Polygon) {
        setCurrentDrawing({
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [0, 0],
                [0, 0],
                [0, 0],
                [0, 0],
              ],
            ],
          },
          properties: {},
        });
      } else if (formikProps.values.shape === DrawingShape.Line) {
        setCurrentDrawing({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [0, 0],
              [0, 0],
              [0, 0],
            ],
          },
          properties: {},
        });
      }
    }

    if (currentDrawing) {
      let newDrawing: any | null = null;
      // User is editing, adding, or removing, a point on a polygon
      if (name.startsWith('coordinates')) {
        newDrawing =
          currentDrawing.geometry.type === 'LineString'
            ? updateMapboxDrawLine(
                name,
                value,
                currentDrawing as GeoJSON.Feature<GeoJSON.LineString>,
                formikProps.setFieldValue
              )
            : updateMapboxDrawPolygon(
                name,
                value,
                currentDrawing as GeoJSON.Feature<GeoJSON.Polygon>,
                formikProps.setFieldValue
              );
      }

      // User is editing a circle
      if (currentDrawing.properties?.subType === 'Circle') {
        if (name.startsWith('radius')) {
          newDrawing = updateMapboxDrawCircle(
            name,
            value,
            currentDrawing as GeoJSON.Feature<
              GeoJSON.LineString,
              { radius: number; subType: 'Circle' }
            >
          );
        }
        if (name.startsWith('centre')) {
          newDrawing = updateMapboxDrawCircle(
            name,
            (DMSToDecimal(value) ?? 0).toFixed(4),
            currentDrawing as GeoJSON.Feature<
              GeoJSON.LineString,
              { radius: number; subType: 'Circle' }
            >
          );
        }
      }

      if (!newDrawing) {
        return;
      }
      // drawing has been updated, update the mapbox-draw layer
      draw?.set({
        type: 'FeatureCollection',
        features: [newDrawing],
      });
      setCurrentDrawing(newDrawing);
    }
  };

  /**
   * When the Drawing is updated, we must also update the form.
   * @param event
   * @param shape
   * @returns
   */
  const onDrawUpdateListener = (event: any) => {
    const drawnFeature = event.features[event.features.length - 1];
    const shape = iVals?.common?.shape;

    setCurrentDrawing(drawnFeature);

    if (drawnFeature.geometry.type === 'LineString' && shape === 'Circle') {
      const [long, lat] = drawnFeature.geometry.coordinates[0];
      const radius = turfLength(drawnFeature, { units: 'nauticalmiles' });
      formikProps.setFieldValue(
        'centrePointInputLong',
        decimalToDMS(long, 'longitude', true)
      );
      formikProps.setFieldValue(
        'centrePointInputLat',
        decimalToDMS(lat, 'latitude', true)
      );
      formikProps.setFieldValue(
        'radiusInNauticalMilesInput',
        radius.toFixed(4)
      );
      drawCircle('circle-edit-temp', [long, lat], radius, {});
    }
    if (drawnFeature.geometry.type === 'Polygon') {
      const coords = convertPolygonToCoordPairs(drawnFeature);
      formikProps.setFieldValue('coordinates', coords);
      return;
    }
    if (drawnFeature.geometry.type === 'LineString') {
      const coords = convertLineToCoordPairs(drawnFeature);
      formikProps.setFieldValue('coordinates', coords);
    }
    if (drawnFeature.geometry.type === 'Point') {
      const coords = convertLineToCoordPairs(drawnFeature);
      formikProps.setFieldValue('coordinates', coords);
    }
  };

  useEffect(() => {
    const drawingDataType = () => {
      // prioritise this over point as circles will show main type as being 'point'
      if (drawingToEdit?.region_data.properties?.subType === 'Circle') {
        return DrawingShape.Circle;
      }
      if (drawingToEdit?.region_data.geometry.type === 'Polygon') {
        return DrawingShape.Polygon;
      }
      if (drawingToEdit?.region_data.geometry.type === 'LineString') {
        return DrawingShape.Line;
      }
      if (drawingToEdit?.region_data.geometry.type === 'Point') {
        return DrawingShape.Point;
      }
      return null;
    };
    const objectDataType = Object.keys(iVals!)[1] as DrawingShape;
    if (
      !drawingDataType &&
      (drawingDataType as unknown as DrawingShape) !== objectDataType
    ) {
      // eslint-disable-next-line no-param-reassign
      drawingToEdit = null;
      setShapeShifting(true);
    } else {
      setShapeShifting(false);
    }

    if (!currentDrawing && !drawingToEdit) {
      // if a user draws with the draw tools, a drawing will already exist.
      // grab it
      const { features } = draw.getAll();
      if (features.length > 0) {
        const feature = features[0];
        if (feature.properties?.isCircle) {
          draw.deleteAll();
          const { center } = feature.properties;
          const radiusPoint = turfDestination(
            center,
            feature.properties.radiusInNm,
            90,
            {
              units: 'nauticalmiles',
            }
          ).geometry.coordinates;

          const currentCircleLineDrawing: Feature<
            LineString,
            { radius: number; subType: 'Circle' }
          > = {
            type: 'Feature',
            properties: {
              subType: 'Circle',
              radius: feature.properties.radiusInNm,
            },
            geometry: {
              type: 'LineString',
              coordinates: [center, radiusPoint],
            },
          };

          drawCircle(
            'circle-edit-temp',
            center,
            feature.properties.radiusInNm,
            {
              'fill-color': nsTheme.colourScheme.drawActive,
              'fill-opacity': 0.2,
            }
          );

          const [mapboxDrawFeatureId] = draw.add(currentCircleLineDrawing);
          setCurrentDrawing(currentCircleLineDrawing);

          draw.changeMode('direct_select', { featureId: mapboxDrawFeatureId });
        } else {
          setCurrentDrawing(draw.get(feature.id as string)!);
        }
      }
      MapHelpers.addMapEventListener('draw.update', null, onDrawUpdateListener);
      return () => {
        MapHelpers.removeMapEventListener('draw.update', onDrawUpdateListener);
      };
    }

    if (drawingToEdit && !currentDrawing) {
      // if there is a route to edit we need to push it into mapbox-draw
      draw.deleteAll();

      if (iVals?.common?.shape === DrawingShape.Point) {
        const [mapboxDrawFeatureId] = draw.add(drawingToEdit.region_data);
        setCurrentDrawing(
          draw.get(mapboxDrawFeatureId)! as GeoJSON.Feature<GeoJSON.Point>
        );
        draw.changeMode('simple_select');
        MapHelpers.addMapEventListener(
          'draw.update',
          null,
          onDrawUpdateListener
        );
        return () => {
          MapHelpers.removeMapEventListener(
            'draw.update',
            onDrawUpdateListener
          );
        };
      }

      if (iVals?.common?.shape !== DrawingShape.Circle) {
        const [mapboxDrawFeatureId] = draw.add(drawingToEdit.region_data);
        setCurrentDrawing(
          draw.get(mapboxDrawFeatureId)! as GeoJSON.Feature<GeoJSON.LineString>
        );
        draw.changeMode('direct_select', { featureId: mapboxDrawFeatureId });
        MapHelpers.addMapEventListener(
          'draw.update',
          null,
          onDrawUpdateListener
        );
        return () => {
          MapHelpers.removeMapEventListener(
            'draw.update',
            onDrawUpdateListener
          );
        };
      }

      // convert circle into 2 points, centre + one radius point
      if (iVals?.common?.shape === DrawingShape.Circle) {
        const circle = drawingToEdit.region_data as GeoJSON.Feature<
          GeoJSON.Point,
          { radius: number }
        >;
        const centrePoint = circle.geometry.coordinates;
        const radiusPoint = turfDestination(
          centrePoint,
          circle.properties.radius,
          90
        ).geometry.coordinates;

        const [mapboxDrawFeatureId] = draw.add({
          type: 'Feature',
          properties: {
            subType: 'Circle',
            radius: circle.properties.radius,
          },
          geometry: {
            type: 'LineString',
            coordinates: [centrePoint, radiusPoint],
          },
        });
        drawCircle('circle-edit-temp', centrePoint, circle.properties.radius, {
          'fill-color': nsTheme.colourScheme.drawActive,
          'fill-opacity': 0.2,
        });

        setCurrentDrawing(
          draw.get(mapboxDrawFeatureId) as GeoJSON.Feature<GeoJSON.LineString>
        );

        draw.changeMode('direct_select', { featureId: mapboxDrawFeatureId });
        MapHelpers.addMapEventListener(
          'draw.update',
          null,
          onDrawUpdateListener
        );
        return () => {
          MapHelpers.removeMapEventListener(
            'draw.update',
            onDrawUpdateListener
          );
        };
      }
    }
    return () => {};
  }, [drawingToEdit, draw]);

  useEffect(() => {
    // user is changing the shape of the drawing. any existing drawing will be useless.

    // new feature is not a circle, remove any temporary circle features
    if (draw.getAll()?.features?.[0]?.properties?.subType !== 'Circle') {
      removeTemporaryCircleFeatures();
    }

    if (currentDrawing) {
      draw.deleteAll();
      setCurrentDrawing(null);
      // no need to check, if it isn't a circle feature, this won't do anything
      removeTemporaryCircleFeatures();
    }

    if (
      formikProps.values.shape === DrawingShape.Polygon &&
      formikProps.values.coordinates
    ) {
      const storedDrawing = convertValuesToDrawingData(formikProps.values);
      setCurrentDrawing(storedDrawing);
      draw?.set({
        type: 'FeatureCollection',
        features: [storedDrawing],
      });
    }

    if (
      formikProps.values.shape === DrawingShape.Line &&
      formikProps.values.coordinates
    ) {
      const storedDrawing = convertValuesToDrawingData(formikProps.values);
      setCurrentDrawing(storedDrawing);
      draw?.set({
        type: 'FeatureCollection',
        features: [storedDrawing],
      });
    }

    if (
      formikProps.values.shape === DrawingShape.Circle &&
      formikProps.values.radiusInNauticalMilesInput &&
      formikProps.values.centrePointInputLat &&
      formikProps.values.centrePointInputLong
    ) {
      const storedDrawing: GeoJSON.Feature = {
        type: 'Feature',
        properties: {
          subType: 'Circle',
          radius: formikProps.values.radiusInNauticalMilesInput
            ? parseFloat(formikProps.values.radiusInNauticalMilesInput)
            : 0,
        },
        geometry: {
          type: 'LineString',
          coordinates: [
            [DMSToDecimal(formikProps.values.centrePointInputLat) ?? 0],
            [DMSToDecimal(formikProps.values.centrePointInputLong) ?? 0],
          ],
        },
      };
      setCurrentDrawing(storedDrawing);
      draw?.set({
        type: 'FeatureCollection',
        features: [storedDrawing],
      });

      drawCircle(
        'circle-edit-temp',
        [
          DMSToDecimal(formikProps.values.centrePointInputLong) ?? 0,
          DMSToDecimal(formikProps.values.centrePointInputLat) ?? 0,
        ],
        formikProps.values.radiusInNauticalMilesInput
          ? parseFloat(formikProps.values.radiusInNauticalMilesInput)
          : 0,
        {
          'fill-color': nsTheme.colourScheme.drawActive,
          'fill-opacity': 0.2,
        }
      );
    }
  }, [formikProps.values.shape]);

  const drawButtonClick = () => {
    const map = MapHelpers.getMapInstance();
    const { shape } = formikProps.values;

    let newMode = '';
    if (shape === DrawingShape.Circle) {
      newMode = 'draw_circle';
    } else if (shape === DrawingShape.Polygon) {
      newMode = 'draw_polygon';
    } else if (shape === DrawingShape.Line) {
      newMode = 'draw_line_string';
    } else if (shape === DrawingShape.Point) {
      newMode = 'draw_point';
    }

    draw.changeMode(newMode);
    // invoke a draw.modechange event to trigger the tooltips
    map.fire('draw.modechange', { mode: newMode });

    if (!tooltipsAddedRef.current) {
      addDrawTipsPopup(draw);
      tooltipsAddedRef.current = true;
    }
  };

  return (
    <Box>
      {loading && <FormLoadingMessage message="Creating drawing..." />}

      {!loading && error && (
        <FormErrorMessage message="Error creating drawing" />
      )}
      {!loading && !error && success && (
        <FormSuccessMessage message="Successfully created drawing" />
      )}
      <FormikProvider value={formikProps}>
        <Form
          className={classNames({
            mobile: isMobile,
          })}
          onChange={onChange}
        >
          <Box
            sx={{
              width: '90%',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                padding: '1.0em 0 0.5em 0',
                borderBottom: '1px solid',
                borderBottomColor: 'background.paper',
                marginBottom: '0.5em',
              }}
            >
              <Box sx={{ marginBottom: '0.6em' }}>
                <MuiSelectField
                  name="shape"
                  placeholder="Select drawing type"
                  disabled={!!drawingToEdit}
                  options={[
                    { name: DrawingShape.Circle, value: DrawingShape.Circle },
                    { name: DrawingShape.Polygon, value: DrawingShape.Polygon },
                    { name: DrawingShape.Line, value: DrawingShape.Line },
                    ...(canDrawPoints
                      ? [
                          {
                            name: DrawingShape.Point,
                            value: DrawingShape.Point,
                          },
                        ]
                      : []),
                  ]}
                  label="Drawing type"
                  ariaLabel="select-feedback-type"
                />
              </Box>

              <MuiFormikTextField
                label="Name of drawing"
                name="name"
                size="small"
              />

              <MuiFormikTextField
                label="Drawing description"
                name="description"
                size="small"
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography align="left" sx={{ marginBottom: '1em' }}>
                Positions
              </Typography>

              <IconButton
                data-testid="drawButton"
                title={`Draw a ${formikProps.values.shape}`}
                id="drawButton"
                sx={{ color: 'secondary.main' }}
                onClick={drawButtonClick}
              >
                <DrawOutlinedIcon />
              </IconButton>
            </Box>

            {formikProps.values.shape === DrawingShape.Circle && (
              <CircleDrawingForm />
            )}

            {formikProps.values.shape === DrawingShape.Polygon && (
              <MultiPointDrawingForm formik={formikProps} onChange={onChange} />
            )}

            {formikProps.values.shape === DrawingShape.Line && (
              <MultiPointDrawingForm formik={formikProps} onChange={onChange} />
            )}

            {formikProps.values.shape === DrawingShape.Point && (
              <SinglePointDrawingForm formik={formikProps} />
            )}
            {!formikProps.dirty && initialValues.common.edited && (
              <Button
                onClick={handleResetForm}
                variant="outlined"
                sx={{
                  borderRadius: '100px',
                  marginTop: '1em',
                }}
              >
                Reset Form
              </Button>
            )}
            <Button
              onClick={() => {
                formikProps.setFieldValue('edited', false);
              }}
              data-testid="create-drawing-submit-button"
              type="submit"
              disabled={
                !formikProps.isValid ||
                (!formikProps.dirty && !initialValues.common.edited) ||
                loading
              }
              variant="outlined"
              sx={{
                borderRadius: '100px',
                marginTop: '1em',
              }}
            >
              Submit
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}

CreateDrawingForm.defaultProps = {
  initialValues: {},
  drawingToEdit: null,
};

function createDrawingPanel(props: CreateDrawingFormProps) {
  const { onCreate, initialValues, drawingToEdit } = props;
  const key = JSON.stringify(initialValues);

  return (
    <Box
      sx={{ minHeight: 0, flexGrow: 1 }}
      data-testid="create-drawing-panel"
      key={key}
    >
      <CreateDrawingForm
        onCreate={onCreate}
        initialValues={initialValues}
        drawingToEdit={drawingToEdit}
      />
    </Box>
  );
}

createDrawingPanel.defaultProps = {
  initialValues: {},
};

export default createDrawingPanel;

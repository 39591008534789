import { Fragment, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonDossierContainer from '../../common-components/common-dossier-container/common-dossier-container';
import LargeTextSidePanel from '../../common-components/large-text-side-panel/large-text-side-panel';
import CorrespondentsDossier from '../../dossiers_and_panels/correspondents-dossier/correspondents-dossier';
import CountryDossier from '../../dossiers_and_panels/country-dossier/country-dossier';
import FirstCallDossier from '../../dossiers_and_panels/first-call-dossier/first-call-dossier';
import ThreatAssessmentPanel from '../../dossiers_and_panels/threat-assessments/threat-assessment-panel';
import VesselDossier from '../../dossiers_and_panels/vessel-dossier/vessel-dossier';
import { useAppDispatch, useAppSelector } from '../../hooks';
import clearSelectedVessel from '../../map/map-layer-manager/vessel-utils/clear-selected-vessel';
import clearWeatherMapLayers from '../../map/map-layer-manager/weather-utils/clear-weather-layers';
import SpecificDrawing from '../../maritime-menu-options/areas-panel/specific-drawing';
import { setVesselHistoryData } from '../../maritime-menu-options/history-panel/history-panel.slice';
import HistoryResultsViewPopup from '../../maritime-menu-options/history-panel/history-results/history-results-view-popup';
import VesselHistoryController from '../../maritime-menu-options/history-panel/vessel-history-controller.utils';
import SpecificIncident from '../../maritime-menu-options/incidents-panel/specific-incident/specific-incident';
import SpecificIndustryNewsItem from '../../maritime-menu-options/industry-news/specific-industry-news-item/specific-industry-news-item';
import { setSelectedArea as setSelectedMaritimeArea } from '../../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import SpecificRIMaritimeArea from '../../maritime-menu-options/ri-maritime-areas-panel/specific-ri-maritime-area/specific-ri-maritime-area';
import SpecificRoute from '../../maritime-menu-options/routes-panel/route-details/route-details';
import { exportLastRouteFromHistory } from '../../maritime-menu-options/routes-panel/routes-panel.utils';
import SpecificPort from '../../maritime-menu-options/world-ports-panel/specific-port/specific-port';
import {
  setSelectedCorrespondent,
  setSelectedCorrespondentId,
} from '../../state/correspondents/correspondents.slice';
import {
  setSelectedCountry,
  setSelectedCountryId,
} from '../../state/countries/countries.slice';
import { setSelectedDrawing } from '../../state/drawings/drawings.slice';
import { setSelectedIncident } from '../../state/incidents/incidents.slice';
import {
  setSelectedIndustryNews,
  setSelectedIndustryNewsId,
} from '../../state/industry-news/industry-news.slice';
import {
  setSelectedFirstCallPort,
  setSelectedFirstCallPortId,
} from '../../state/ports/first-call.slice';
import {
  setSelectedPort,
  setSelectedPortId,
} from '../../state/ports/ports.slice';
import { setSelectedRoute } from '../../state/routes/routes.slice';
import { clearVesselHistory } from '../../utils/vessels.utils';
import {
  EDossiers,
  EMenuItems,
  setAllDossierClosed,
  setSelectedDossier,
  setThreatAssessmentOpen,
} from '../menu.slice';

export default function ModalsContainer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedDossier, selectedOption, threatAssessmentOpen } =
    useAppSelector((state) => state.menu);

  const lastDossierItem = useAppSelector((state) => {
    const { dossierHistory } = state.menu;
    const lastSelectedItem =
      dossierHistory.length > 0
        ? dossierHistory[dossierHistory.length - 1]
        : null;
    return lastSelectedItem;
  });

  // navigation items history
  const { history } = useAppSelector((store) => store.navHistory);

  const { selectedIncident } = useAppSelector((state) => state.incidents);
  const { selectedPort } = useAppSelector((state) => state.ports);
  const { selectedDrawing } = useAppSelector((state) => state.drawings);
  const { selectedRoute } = useAppSelector((state) => state.routes);
  const { selectedIndustryNews } = useAppSelector(
    (state) => state.industryNews
  );

  const deselectMaritimeArea = () => {
    dispatch(setSelectedMaritimeArea(null));
    exportLastRouteFromHistory(history);
  };

  const deselectVessel = () => {
    clearSelectedVessel();
    if (selectedDossier !== EDossiers.HISTORY) {
      dispatch(setVesselHistoryData(null));
    }
    clearWeatherMapLayers();
    VesselHistoryController.clearHistoricalLocationLayers();
  };

  const deselectPort = () => {
    dispatch(setSelectedPortId(null));
    dispatch(setSelectedPort(null));
    exportLastRouteFromHistory(history);
  };

  const deselectIncident = () => {
    dispatch(setSelectedIncident(null));
    exportLastRouteFromHistory(history);
  };

  const deselectCountry = () => {
    dispatch(setSelectedCountryId(null));
    dispatch(setSelectedCountry(null));
  };

  const deselectDrawing = () => {
    dispatch(setSelectedDrawing(null));
    navigate('..', { relative: 'path' });
  };

  const deselectIndustryNews = () => {
    dispatch(setSelectedIndustryNews(null));
    dispatch(setSelectedIndustryNewsId(null));
    window.history.replaceState({}, '', '/industry-news');
  };

  const deselectFirstCall = () => {
    dispatch(setSelectedFirstCallPort(null));
    dispatch(setSelectedFirstCallPortId(null));
    window.history.replaceState({}, '', '/first-call-ports');
  };

  const deselectCorrespondent = () => {
    dispatch(setSelectedCorrespondentId(null));
    dispatch(setSelectedCorrespondent(null));
    window.history.replaceState({}, '', '/correspondents');
  };

  const closeRouteDossier = () => {
    dispatch(setSelectedRoute(null));
    dispatch(setAllDossierClosed());
    navigate('..', { relative: 'path' });
  };

  useEffect(() => {
    switch (lastDossierItem?.dossier) {
      case EDossiers.ROUTE: {
        if (
          lastDossierItem.option === EMenuItems.ROUTES &&
          selectedOption === EMenuItems.ROUTES &&
          selectedRoute
        ) {
          return;
        }
        closeRouteDossier();
        break;
      }
      case EDossiers.COUNTRY: {
        deselectCountry();
        break;
      }
      case EDossiers.INCIDENT: {
        deselectIncident();
        if (selectedRoute && selectedOption === EMenuItems.ROUTES) {
          dispatch(setSelectedDossier(EDossiers.ROUTE));
        }
        break;
      }
      case EDossiers.PORT: {
        if (selectedRoute && selectedOption === EMenuItems.ROUTES) {
          deselectPort();
          dispatch(setSelectedDossier(EDossiers.ROUTE));
        }
        break;
      }
      case EDossiers.DRAWING: {
        deselectDrawing();
        break;
      }
      case EDossiers.RI_MARITIME_AREA: {
        deselectMaritimeArea();

        if (selectedRoute && selectedOption === EMenuItems.ROUTES) {
          dispatch(setSelectedDossier(EDossiers.ROUTE));
        }

        break;
      }
      case EDossiers.VESSEL: {
        deselectVessel();
        break;
      }
      case EDossiers.HISTORY: {
        clearVesselHistory();
        break;
      }

      default:
    }
  }, [selectedDossier]);

  const renderDossier = useCallback(() => {
    switch (selectedDossier) {
      case EDossiers.COUNTRY:
        return (
          <CommonDossierContainer deselectDossier={deselectCountry}>
            <CountryDossier />
          </CommonDossierContainer>
        );
      case EDossiers.INCIDENT: {
        if (selectedIncident) {
          return (
            <CommonDossierContainer deselectDossier={deselectIncident}>
              <SpecificIncident incident={selectedIncident} />
            </CommonDossierContainer>
          );
        }
        return null;
      }
      case EDossiers.HISTORY:
        return (
          <CommonDossierContainer deselectDossier={clearVesselHistory}>
            <HistoryResultsViewPopup />
          </CommonDossierContainer>
        );
      case EDossiers.PORT: {
        if (selectedPort) {
          return (
            <CommonDossierContainer deselectDossier={deselectPort}>
              <SpecificPort port={selectedPort} />
            </CommonDossierContainer>
          );
        }
        return null;
      }
      case EDossiers.DRAWING: {
        if (selectedDrawing) {
          return (
            <CommonDossierContainer deselectDossier={deselectDrawing}>
              <SpecificDrawing drawing={selectedDrawing} />
            </CommonDossierContainer>
          );
        }
        return null;
      }
      case EDossiers.RI_MARITIME_AREA:
        return (
          <CommonDossierContainer deselectDossier={deselectMaritimeArea}>
            <SpecificRIMaritimeArea />
          </CommonDossierContainer>
        );
      case EDossiers.ROUTE: {
        if (selectedRoute) {
          return (
            <CommonDossierContainer deselectDossier={closeRouteDossier}>
              <SpecificRoute route={selectedRoute} />
            </CommonDossierContainer>
          );
        }
        return null;
      }
      case EDossiers.INDUSTRY_NEWS: {
        if (selectedIndustryNews) {
          return (
            <CommonDossierContainer deselectDossier={deselectIndustryNews}>
              <SpecificIndustryNewsItem news={selectedIndustryNews} />
            </CommonDossierContainer>
          );
        }
        return null;
      }
      case EDossiers.VESSEL:
        return (
          <CommonDossierContainer deselectDossier={deselectVessel}>
            <VesselDossier />
          </CommonDossierContainer>
        );
      case EDossiers.FIRST_CALL:
        return (
          <CommonDossierContainer deselectDossier={deselectFirstCall}>
            <FirstCallDossier />
          </CommonDossierContainer>
        );
      case EDossiers.CORRESPONDENTS:
        return (
          <CommonDossierContainer deselectDossier={deselectCorrespondent}>
            <CorrespondentsDossier />
          </CommonDossierContainer>
        );
      default:
        return null;
    }
  }, [
    selectedDossier,
    selectedRoute,
    selectedDrawing,
    selectedPort,
    selectedIncident,
    selectedIndustryNews,
  ]);

  // It is likely that in truth we only want one panel rendered at a time,
  // but lets keep the refactor simple for now.
  const panelsToRender = [];

  if (threatAssessmentOpen) {
    panelsToRender.push({
      key: 'threatAssessment',
      panel: (
        <LargeTextSidePanel
          title="Threat Assessment"
          onClose={() => dispatch(setThreatAssessmentOpen(false))}
        >
          <ThreatAssessmentPanel />
        </LargeTextSidePanel>
      ),
    });
  }

  return (
    <>
      {renderDossier()}
      {panelsToRender.map((panel) => (
        <Fragment key={panel.key}>{panel.panel}</Fragment>
      ))}
    </>
  );
}

import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

function PanelTitle(props: PropsWithChildren) {
  const { children } = props;

  return (
    <Typography
      variant="h5"
      sx={{
        m: '1.5rem 2rem 1.5rem',
        color: 'secondary.main',
        fontWeight: 400,
        textAlign: 'left',
      }}
    >
      {children}
    </Typography>
  );
}

export default PanelTitle;

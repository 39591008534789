/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Correspondent } from '../../models/correspondents.model';

interface CorrespondentsState {
  correspondents: Correspondent[] | null;
  loading: boolean;
  error: boolean;
  selectedCorrespondent: Correspondent | null;
  selectedCorrespondentId: string | null;
}

export const INITIAL_CORRESPONDENTS_STATE: CorrespondentsState = {
  correspondents: null,
  loading: false,
  error: false,
  selectedCorrespondent: null,
  selectedCorrespondentId: null,
};

const CorrespondentsSlice = createSlice({
  name: 'correspondents',
  initialState: INITIAL_CORRESPONDENTS_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean | null>) => {
      state.loading = action.payload ?? true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setCorrespondents: (
      state,
      action: PayloadAction<Correspondent[] | null>
    ) => {
      const correspondents = action.payload;
      if (correspondents) {
        state.correspondents = correspondents;
      } else {
        state.correspondents = null;
      }
      state.loading = false;
      state.error = false;
    },
    setSelectedCorrespondentId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.selectedCorrespondentId = action.payload;
    },
    setSelectedCorrespondent: (
      state,
      action: PayloadAction<Correspondent | undefined | null>
    ) => {
      const prevSelectedCorrespondentId = state.selectedCorrespondentId
        ? state.selectedCorrespondent?.uid
        : undefined;
      const newSelectedCorrespondentId = action.payload
        ? action.payload.uid
        : undefined;

      state.selectedCorrespondent = action.payload
        ? {
            ...action.payload,
            selected: true,
          }
        : null;

      // we deselect all correspondents if the selected correspondent is null
      // TODO we can refactor this whole block of logic when removing the 'selected id' piece of this state
      // atm we set selected id to null before setting selected correspondent as null so the final block of this isn't triggered so included this logic here.
      // this is for when we close the dossier panel and we want to deselect the correspondent.
      if (action.payload === null && state.correspondents) {
        state.correspondents = state.correspondents.map((correspondent) => ({
          ...correspondent,
          selected: false,
        }));
      }

      if (state.correspondents) {
        if (newSelectedCorrespondentId) {
          // set the selected property to true for the new selected correspondent
          state.correspondents = state.correspondents.map((correspondent) => {
            if (correspondent.uid === newSelectedCorrespondentId) {
              return {
                ...correspondent,
                selected: true,
              };
            }
            return correspondent;
          });
        }

        // if no new selected correspondent, deselect the previous correspondent
        if (
          prevSelectedCorrespondentId &&
          prevSelectedCorrespondentId !== newSelectedCorrespondentId
        ) {
          state.correspondents = state.correspondents.map((correspondent) => {
            if (correspondent.uid === prevSelectedCorrespondentId) {
              return {
                ...correspondent,
                selected: false,
              };
            }
            return correspondent;
          });
        }
      }
    },
  },
});

export const {
  setLoading,
  setError,
  setCorrespondents,
  setSelectedCorrespondentId,
  setSelectedCorrespondent,
} = CorrespondentsSlice.actions;

export default CorrespondentsSlice.reducer;

import { Box, Divider, Link, Typography } from '@mui/material';
import PanelTitle from '../../common-components/panel/panel-title';
import './vps-ports-panel.scss';

function VpsPortsPanel() {
  return (
    <Box className="vpsPortsPanel">
      <PanelTitle>VPS Ports</PanelTitle>
      <Divider sx={{ borderColor: 'primary.dark' }} />
      <Box
        data-testid="vps-ports-panel"
        sx={{ p: 2, overflowY: 'auto', scrollbarWidth: 'thin' }}
      >
        <Box>
          <Typography paragraph variant="body2" align="left">
            Fuel insights, showing VPS fuel analysis data for more than 500
            ports globally, can be accessed{' '}
            <Link
              href="https://north-standard.com/fuel-insights"
              sx={{ color: 'secondary.main' }}
            >
              here
            </Link>
            . Information includes off-spec cautions, alerts and GCMS failures
            in addition to fuel supplier benchmarks, quantities and quality
            scorecards, currently, for VLSFO, HSFO, biofuels and distillates.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default VpsPortsPanel;

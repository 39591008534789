import { MapLayerMouseEvent } from 'mapbox-gl';
import MapLayer from '../map-layer.enum';

let hoveredPolygonId: any = null;

export function maritimeAreaInfoPopUpContent(
  e: MapLayerMouseEvent
): { label: string; value: string } | null {
  if (e.features!.length > 0) {
    if (hoveredPolygonId !== null) {
      e.target.setFeatureState(
        { source: MapLayer.RI_MARITIME_AREAS, id: hoveredPolygonId },
        { hover: false }
      );
    }
    hoveredPolygonId = e.features![0].id;
    e.target.setFeatureState(
      { source: MapLayer.RI_MARITIME_AREAS, id: hoveredPolygonId },
      { hover: true }
    );

    return {
      label: 'RI Maritime Area',
      value: e.features![0].properties?.title,
    };
  }
  return null;
}
export function hideMaritimeAreaInfoPopUp(e: MapLayerMouseEvent) {
  if (hoveredPolygonId !== null) {
    e.target.setFeatureState(
      { source: MapLayer.RI_MARITIME_AREAS, id: hoveredPolygonId },
      { hover: false }
    );
  }
  hoveredPolygonId = null;
}

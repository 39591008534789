/* eslint-disable @typescript-eslint/no-unused-vars */
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Typography } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { getCountry } from '../../api';
import Breadcrumb from '../../common-components/breadcrumb/breadcrumb';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import TabWithTooltip from '../../common-components/tabs/TabWithTooltip';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import useAccessControl from '../../hooks/access-control/useAccessControl';
import { EntityType } from '../../models/document';
import { ExpandedCountry } from '../../models/risk_intelligence.model';
import {
  setCountryLoading,
  setError,
  setSelectedCountry,
  setSelectedCountryId,
} from '../../state/countries/countries.slice';
import { isExpandedCountry } from '../../utils/incidents-helpers';
import DocumentsTab from '../documents/documents-tab';
import ThreatAssessmentRevisionLabel from '../threat-assessments/threat-assessment-revision-label';
import useSelectedThreatAssessment from '../threat-assessments/use-selected-threat-assessment';
import './country-dossier.scss';
import RICountryTab from './ri-country-tab/ri-country-tab';
import SanctionList from './sanctions/sanction-list/sanction-list';
import TravelAdviceTab from './travel-advice-tab/travel-advice-list/travel-advice-list';

function CountryDossier() {
  const dispatch = useAppDispatch();

  const { selectedCountry, selectedCountryId, countryLoading, error } =
    useAppSelector((state) => state.countries);

  const {
    canAccessSanctions,
    canAccessDocuments,
    canAccessRiCountries,
    canAccessTravelAdvice,
  } = useAccessControl();
  const { threatAssessment, loadingTA } = useSelectedThreatAssessment(
    (selectedCountry?.ri as ExpandedCountry)?.threatAssessment
  );

  const fetchRiInfo = async (id: string | number) => {
    // Don't fetch unless selected country exists
    dispatch(setCountryLoading());
    try {
      const result: ExpandedCountry | {} = await getCountry(id);
      if (!isExpandedCountry(result)) {
        // call to RI returned no new info
        if (selectedCountry) {
          dispatch(
            setSelectedCountry({
              ...selectedCountry,
              ri: 'N/A',
            })
          );
          dispatch(setSelectedCountryId(null));
        } else {
          // requesting a country that RI does not have a dossier on, when we have no other country info, is unrecoverable
          dispatch(setError());
        }
        return;
      }
      dispatch(
        setSelectedCountry({
          title: result.title,
          unlocode: result.code,
          // Calling the endpoint with a country code that RI does not have a dossier on results in {}
          ri: result,
        })
      );
      dispatch(setSelectedCountryId(null));
    } catch (e) {
      dispatch(setError());
    }
  };

  const isMobile = useMobile();

  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!selectedCountry && selectedCountryId) {
      fetchRiInfo(selectedCountryId);
    }
    if (selectedCountry && selectedCountry.ri && selectedCountryId) {
      // if we get here, RI is populated but there is a request for a countryId
      if (
        selectedCountry.ri === 'N/A' ||
        selectedCountryId !== selectedCountry.ri.id
      ) {
        // if we get here, RI is populated with stale data
        fetchRiInfo(selectedCountryId);
        return;
      }
    }
    if (selectedCountry && !selectedCountry.ri) {
      fetchRiInfo(selectedCountry.unlocode.toLowerCase());
    }

    if (!canAccessRiCountries) {
      setValue('2');
    }
  }, [selectedCountry, selectedCountryId]);

  const secondaryMenuOpen = useAppSelector(
    (state) => state.menu.secondaryMenuOpen
  );

  return (
    <Box
      data-testid="country-dossier-container"
      className={classNames({ mobile: isMobile })}
      sx={{
        maxWidth: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        overflowX: 'auto',
        scrollbarWidth: 'thin',
      }}
    >
      <Box
        data-testid="country-title-container"
        sx={{
          marginLeft: '1em',
          marginTop: secondaryMenuOpen ? '1em' : '1.65em',
          padding: '0em',
          alignItems: 'center',
          textAlign: 'left',
          marginBottom: '0.5em',
        }}
      >
        {selectedCountry?.title && (
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: secondaryMenuOpen ? '1.125rem' : '1.1rem',
              marginLeft: !secondaryMenuOpen ? '0.2em' : '',
            }}
          >
            {selectedCountry.title}
          </Typography>
        )}
        {loadingTA && !threatAssessment && (
          <CircularProgress size={14} color="primary" />
        )}
        {threatAssessment && (
          <ThreatAssessmentRevisionLabel
            underRevision={threatAssessment.underRevision}
          />
        )}
      </Box>

      {!secondaryMenuOpen && <Breadcrumb />}

      <Box>
        <TabContext value={value}>
          <TabList onChange={handleChange}>
            <TabWithTooltip
              value="1"
              disabled={!canAccessRiCountries}
              label="Risk Intelligence"
              data-testid="risk-intelligence-tab"
              sx={{
                flex: '1',
              }}
            />
            <TabWithTooltip
              value="2"
              label="Sanctions"
              data-testid="sactions-tab"
              disabled={!canAccessSanctions}
              sx={{
                flex: '1',
              }}
            />
            <TabWithTooltip
              value="3"
              label="Travel Advice"
              disabled={!canAccessTravelAdvice}
              data-testid="travel-advice-tab"
              sx={{
                flex: '1',
              }}
            />
            <TabWithTooltip
              value="4"
              label="Documents"
              data-testid="documents-tab"
              disabled={!canAccessDocuments}
              sx={{
                flex: '1',
              }}
            />
          </TabList>

          <TabPanel sx={{ padding: 0 }} value="1">
            {error && (
              <ErrorPanel message="Error Loading RI country info, or that country id does not exist." />
            )}
            {!error && countryLoading && <LoadingPanel />}
            {!error && !countryLoading && selectedCountry && (
              <RICountryTab country={selectedCountry.ri || 'N/A'} />
            )}
          </TabPanel>

          {canAccessSanctions && (
            <TabPanel sx={{ padding: 0 }} value="2">
              <SanctionList />
            </TabPanel>
          )}

          <TabPanel sx={{ padding: 0 }} value="3">
            <TravelAdviceTab />
          </TabPanel>

          {canAccessDocuments && (
            <TabPanel value="4">
              <DocumentsTab
                entityId={String(selectedCountry?.unlocode)}
                entityType={EntityType.country}
                entityName={selectedCountry?.title || ''}
              />
            </TabPanel>
          )}
        </TabContext>
      </Box>
    </Box>
  );
}

export default CountryDossier;

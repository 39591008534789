/* eslint-disable @typescript-eslint/naming-convention */

import { Box, Grid, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { isEqual } from 'lodash';
import { useCallback, useEffect } from 'react';
import RIAccordion from '../../common-components/accordion/ri-accordion';
import { BackOneButton } from '../../common-components/breadcrumb/breadcrumb';
import DocumentsAccordianPanel from '../../dossiers_and_panels/documents/accordian-panel';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { EntityType } from '../../models/document';
import { Drawing, DrawingShape } from '../../models/drawings.model';
import { dateToUtcTimeString } from '../../utils/date-time-helpers.utils';
import GeoHelper from '../../utils/geo-helpers.utils';
import useDownloadIncidents from '../../utils/incidents-download';
import { setIncidentFilters } from '../incidents-panel/incident-panel.slice';
import AreaIncidents from './incidents-modal/area-incidents-modal';

interface SpecificDrawingProps {
  drawing: Drawing;
}

function SpecificDrawing(props: SpecificDrawingProps) {
  const { drawing } = props;

  const { region_name, description, date_created, region_id, region_data } =
    drawing;

  const dispatch = useAppDispatch();
  const {
    incidents,
    loading: incidentsLoading,
    error: incidentsError,
  } = useAppSelector((state) => state.incidents);
  const { filters: incidentFilters } = useAppSelector(
    (state) => state.incidentsPanel
  );
  const { secondaryMenuOpen } = useAppSelector((state) => state.menu);

  const updateIncidentFilters = useCallback(() => {
    const createClosedCircle = () => {
      // RI uses MongoDB which requires the circle to be 'closed', i.e. the
      // last coordinates matching the first coordinates.
      // Rounding and flaoting point math means that the last coordinates
      // don't always appear to match the first.
      // To prevent affecting anything else on the app (e.g. map) by changing the common function,
      // we'll handle this here specifically for the API for now.
      const circle = GeoHelper.convertFeatureCircleToPolygon(
        region_data as GeoJSON.Feature<GeoJSON.Point>
      );
      // Added comparison of first and last coordinates to prevent last two coordinates matching,
      // This ensures that capella sar search completes for circle drawing.
      const len = circle.geometry.coordinates[0].length;
      const firstCoordArray = JSON.parse(
        JSON.stringify(circle.geometry.coordinates[0][0])
      );
      const lastCoordArray = JSON.parse(
        JSON.stringify(circle.geometry.coordinates[0][len - 1])
      );
      const firstAndLastMatch =
        firstCoordArray[0] === lastCoordArray[0] &&
        firstCoordArray[1] === lastCoordArray[1];
      if (firstAndLastMatch) {
        return circle;
      }
      circle.geometry.coordinates[0].push(circle.geometry.coordinates[0][0]);
      return circle;
    };

    const { geometry } =
      region_data.properties?.subType === DrawingShape.Circle
        ? createClosedCircle()
        : region_data;

    if (!isEqual(incidentFilters.geo, geometry)) {
      dispatch(
        setIncidentFilters({
          ...incidentFilters,
          geo: geometry,
        })
      );
    }
  }, [region_data, incidentFilters]);

  useEffect(() => {
    updateIncidentFilters();
  }, [updateIncidentFilters]);

  useEffect(
    () => () => {
      // Reset the incident filters to remove the geometry filter when the component unmounts
      dispatch(
        setIncidentFilters({
          ...incidentFilters,
          geo: null,
        })
      );
    },
    []
  );

  const nonDetachedStyling = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '0.675em',
    alignItems: 'center',
    marginBottom: '0.5em',
  };

  const detachedStyling = {
    marginTop: '0.45em',
  };

  const { downloadIncidentsButton } = useDownloadIncidents(
    incidents?.allIds || [],
    incidentFilters // Already has the geo filter applied
  );

  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      data-testid="specific-area-container"
    >
      <Box
        maxWidth="90%"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '15px 15px 0px',
          alignItems: 'center',
        }}
      >
        <Stack sx={{ textAlign: 'left' }}>
          <Box sx={secondaryMenuOpen ? detachedStyling : nonDetachedStyling}>
            {!secondaryMenuOpen && <BackOneButton />}
            <Typography
              variant="body1"
              textAlign="left"
              sx={{ fontWeight: 500 }}
              gutterBottom
            >
              {region_name}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Box
        sx={{
          padding: '15px',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          textAlign="left"
          variant="body2"
          sx={{
            margin: '15px 0px 30px',
          }}
        >
          {description}
        </Typography>

        <Grid container rowSpacing={1}>
          <Grid xs={5}>
            <Typography variant="subtitle2" align="left">
              Date created
            </Typography>
          </Grid>
          <Grid xs={7}>
            <Typography variant="body2" align="right">
              {dateToUtcTimeString(date_created)}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle2" align="left">
              Owner Type
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2" align="right">
              User
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="subtitle2" align="left">
              Incidents in drawing
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body2" align="right">
              {incidentsError &&
                !incidentsLoading &&
                'Error fetching incidents'}
              {incidentsLoading && !incidentsError && (
                <CircularProgress size={20} />
              )}
              {!incidentsLoading && !incidentsError
                ? `${incidents?.allIds.length || 0}`
                : null}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ overflow: 'auto' }}>
        {!incidentsLoading && !incidentsError && (
          <RIAccordion
            title="View Incidents"
            content={
              <>
                <Box>{downloadIncidentsButton()}</Box>
                <AreaIncidents
                  incidents={incidents ? Object.values(incidents.byId) : []}
                />
              </>
            }
          />
        )}
        <DocumentsAccordianPanel
          entityId={String(region_id)}
          entityName={region_name}
          entityType={EntityType.drawing}
        />
      </Box>
    </Box>
  );
}

export default SpecificDrawing;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentWithMeta } from '../../models/document';

export interface DocumentFilters {
  open?: boolean;
  search: string;
}

export type DocumentsState = {
  loading: boolean;
  error: boolean;
  myDocuments: null | DocumentWithMeta[];
  filters: DocumentFilters;
};

export const INITIAL_DOCUMENTS_STATE: DocumentsState = {
  loading: false,
  error: false,
  filters: {
    open: false,
    search: '',
  },
  myDocuments: null,
};

export const DocumentsSlice = createSlice({
  name: 'documents',
  initialState: INITIAL_DOCUMENTS_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setFilters: (state, action: PayloadAction<Partial<DocumentFilters>>) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setDocuments: (
      state,
      action: PayloadAction<{
        myDocuments: DocumentWithMeta[] | null;
      }>
    ) => {
      state.myDocuments = action.payload.myDocuments;
      state.loading = false;
      state.error = false;
    },
  },
});

export const { setLoading, setError, setDocuments, setFilters } =
  DocumentsSlice.actions;

export default DocumentsSlice.reducer;

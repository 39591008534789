import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { Port } from '../../../maritime-menu-options/world-ports-panel/world-ports.model';
import { addHistoryItem } from '../../../nav-history.slice';
import { setSelectedPort } from '../../../state/ports/ports.slice';
import store from '../../../store';
import { assertIsPointFeature } from '../../../utils/typescript-helpers';
import MapHelpers from '../../map.utils';

function onRoutePortClick(feature: MapboxGeoJSONFeature, port: Port) {
  const { selectedRoute } = store.getState().routes;
  const { ports } = store.getState().ports;

  if (!selectedRoute) return;

  if (assertIsPointFeature(feature)) {
    MapHelpers.zoomToPoint(feature.geometry.coordinates, 8.5);
  }

  store.dispatch(addHistoryItem({ type: 'route', properties: selectedRoute }));

  // Port Properties in the GeoJson Feature are not all properties of the Port model
  const newSelectedPort = ports?.find((p) => p.WPI === port.WPI);
  store.dispatch(setSelectedPort(newSelectedPort));

  store.dispatch(setSelectedDossier(EDossiers.PORT));
}

export default onRoutePortClick;

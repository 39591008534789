import { MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';
import { assertIsPointFeature } from '../../../utils/typescript-helpers';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

function onVesselClick(
  e: MapLayerMouseEvent | MapLayerTouchEvent,
  layer: MapLayer | string
) {
  const map = e.target;
  const features = map.queryRenderedFeatures(e.point, {
    layers: [layer],
  });

  if (features?.length) {
    const feature = features[0];
    // TODO code commented to fix bug for NGG-587. Part of multiple vessel selection feature but requires more work.
    // store.dispatch(setSelectedDossier(EDossiers.VESSEL));
    if (assertIsPointFeature(feature)) {
      MapHelpers.zoomToPoint(feature.geometry.coordinates);
      // const vessel = {
      //   latitude: feature.geometry.coordinates[1],
      //   longitude: feature.geometry.coordinates[0],
      //   ...feature.properties,
      // };
      // store.dispatch(setSelectedVessels([vessel as Vessel]));
    }
    // TODO: Figure out what happens when a NorthStandard vessel is clicked
  }
}

export default onVesselClick;

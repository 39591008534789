/* eslint-disable react/jsx-props-no-spreading */
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { getIncident } from '../../../api';
import { IncidentLegendIcon } from '../../../common-components/common-legend/common-legend';
import ImageAlbum from '../../../common-components/image-album-with-lightbox/image-album-with-lightbox';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { incidentExport } from '../../../reporting/incident-export';
import { setExpandedIncident } from '../../../state/incidents/incidents.slice';
import {
  getIncidentRegionTitleById,
  getIncidentTypeTitleById,
  incidentDisplayDate,
  isExpandedIncident,
} from '../../../utils/incidents-helpers';
import { kebabify, sanitiseBlock } from '../../../utils/text-formatting.utils';
import { ExpandedIncident, Incident } from '../incident.model';

import { BackOneButton } from '../../../common-components/breadcrumb/breadcrumb';
import {
  EDossiers,
  EMenuItems,
  closeMenus,
  setSelectedDossier,
  setSelectedOption,
} from '../../../main-menu/menu.slice';
import { addHistoryItem } from '../../../nav-history.slice';
import {
  setSelectedCountry,
  setSelectedCountryId,
} from '../../../state/countries/countries.slice';
import {
  setSelectedPort,
  setSelectedPortId,
} from '../../../state/ports/ports.slice';
import {
  setSelectedArea,
  setSelectedAreaId,
} from '../../ri-maritime-areas-panel/ri-maritime-areas.slice';
import './specific-incident.scss';

interface Props {
  incident: Incident;
}

function SpecificIncident({ incident }: Props) {
  const { title, type, region, summary } = incident;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goToPort = (portId: number) => {
    dispatch(setSelectedPort(null));
    dispatch(setSelectedDossier(EDossiers.PORT));
    dispatch(setSelectedPortId(portId));
    dispatch(setSelectedOption(EMenuItems.PORTS)); // TODO have to keep this to load the real port I think
  };

  const goToCountry = (countryId: number) => {
    dispatch(closeMenus());
    dispatch(setSelectedCountry(null));
    dispatch(setSelectedDossier(EDossiers.COUNTRY));
    dispatch(setSelectedCountryId(countryId));
  };

  const goToArea = (areaId: number) => {
    dispatch(closeMenus());
    dispatch(setSelectedArea(null));
    dispatch(setSelectedDossier(EDossiers.RI_MARITIME_AREA));
    dispatch(setSelectedAreaId(areaId));
    dispatch(setSelectedOption(EMenuItems.RI_MARITIME_AREAS));
  };
  const { secondaryMenuOpen } = useAppSelector((state) => state.menu);

  const fetchExpandedIncident = (id: number) => {
    getIncident(id)
      .then((response: ExpandedIncident) => {
        dispatch(setExpandedIncident(response));
      })
      .catch((e) => {
        dispatch(
          setExpandedIncident({
            ...incident,
            expanded: true,
            description: `Error loading expanded incident: ${e.message}`,
            images: [],
            area: null,
            port: null,
            country: null,
            target: null,
            region: { id: -1, title: 'Error loading incident' },
            type: { id: -1, title: 'Error loading incident' },
          })
        );
      });
  };
  const theme = useTheme();

  useEffect(() => {
    if (!isExpandedIncident(incident)) {
      fetchExpandedIncident(incident.id);
    }
  }, [incident]);

  let description = <CircularProgress size={30} sx={{ my: '0.3rem' }} />;

  if (isExpandedIncident(incident)) {
    const santizedDescription = sanitizeHtml(incident.description, {
      allowedTags: ['a', 'b', 'i', 'p', 'em', 'strong', 'ul', 'li', 'br'],
      allowedAttributes: {
        a: [
          'href', // allow any hrefs
          'target',
          'class',
        ],
      },
      transformTags: {
        a(tagName, attribs) {
          return {
            tagName: 'a',
            attribs: {
              ...attribs,
              target: '_blank',
              class: 'riAnchor',
            },
          };
        },
      },
    });
    description = (
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: '0.25x',
        }}
        className="specificIncidentDescription"
        // HTML has been cleaned
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: santizedDescription }}
      />
    );
  }

  const area = isExpandedIncident(incident) && incident.area;

  const country = isExpandedIncident(incident) && incident.country;

  const port = isExpandedIncident(incident) && incident.port;

  const imo = isExpandedIncident(incident) && incident.target?.imo;

  const callSign = isExpandedIncident(incident) && incident.target?.callSign;

  const displayDate = incidentDisplayDate(incident);

  const HTMLsummary = (
    <Typography
      align="left"
      sx={{
        p: '10px 0',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25x',
      }}
      dangerouslySetInnerHTML={{
        __html: sanitiseBlock(summary, [
          'a',
          'b',
          'i',
          'p',
          'em',
          'strong',
          'br',
          'ul',
          'li',
        ]),
      }}
    />
  );

  return (
    <Box
      data-testid="incident-specific"
      sx={{
        width: 'fit-content',
        display: 'contents',
      }}
    >
      <Box
        maxWidth="90%"
        sx={{
          mt: secondaryMenuOpen ? '0.3rem' : '1rem',
          ml: '0.3rem',
          py: '0.25rem',
        }}
      >
        <Stack sx={{ textAlign: 'left' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '0.25em',
            }}
          >
            {!secondaryMenuOpen && <BackOneButton />}
            <IncidentLegendIcon id={type} type="round" />
            <Typography variant="subtitle2" textAlign="left">
              {title}
            </Typography>
          </Box>
          <Typography
            sx={{ marginLeft: '1em', marginRight: '-2em' }}
            variant="caption"
            textAlign="left"
          >
            {getIncidentRegionTitleById(region)}
            <span style={{ float: 'right' }}>
              <IconButton
                size="large"
                onClick={() =>
                  window.open(
                    `https://admin.riskintelligence.eu/node/${incident.id}/edit`,
                    `incident-${incident.id}`
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </span>
          </Typography>
        </Stack>
      </Box>

      <ImageAlbum
        images={isExpandedIncident(incident) ? incident.images : []}
        loading={!isExpandedIncident(incident)}
      />

      <Grid
        container
        rowSpacing={1}
        sx={{
          marginTop: '0.1rem',
          padding: '0 10px 10px 10px',
          overflowY: 'auto',
        }}
      >
        <Grid item xs={2}>
          <Typography sx={{ fontWeight: '500', fontSize: '14px' }} align="left">
            Type
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography sx={{ fontSize: '14px' }} align="right">
            {getIncidentTypeTitleById(type) || 'Incident'}
          </Typography>
        </Grid>

        {displayDate && (
          <>
            <Grid item xs={2}>
              <Typography
                sx={{ fontWeight: '500', fontSize: '14px' }}
                align="left"
              >
                Date
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: '14px' }} align="right">
                {displayDate}
              </Typography>
            </Grid>
          </>
        )}

        {region && (
          <>
            <Grid item xs={2}>
              <Typography
                sx={{ fontWeight: '500', fontSize: '14px' }}
                align="left"
              >
                Region
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: '14px' }} align="right">
                {getIncidentRegionTitleById(region)}
              </Typography>
            </Grid>
          </>
        )}

        {area && (
          <>
            <Grid item xs={2}>
              <Typography
                sx={{ fontWeight: '500', fontSize: '14px' }}
                align="left"
              >
                Area
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              container
              direction="row"
              justifyContent="flex-end"
            >
              <Link
                sx={{ fontSize: '0.875rem' }}
                component={RouterLink}
                to={`/ri-maritime-areas/${area.id}`}
                onClick={() => {
                  dispatch(
                    addHistoryItem({ type: 'incident', properties: incident })
                  );
                  goToArea(area.id);
                }}
              >
                {area.title}
              </Link>
            </Grid>
          </>
        )}

        {country && (
          <>
            <Grid item xs={2}>
              <Typography
                sx={{ fontWeight: '500', fontSize: '14px' }}
                align="left"
              >
                Country
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              container
              direction="row"
              justifyContent="flex-end"
            >
              <Link
                sx={{ fontSize: '0.875rem' }}
                component={RouterLink}
                to={`/countries/${country.id}`}
                onClick={() => {
                  dispatch(
                    addHistoryItem({ type: 'incident', properties: incident })
                  );
                  goToCountry(country.id);
                }}
              >
                {country.title}
              </Link>
            </Grid>
          </>
        )}
        {port && (
          <>
            <Grid item xs={2}>
              <Typography
                sx={{ fontWeight: '500', fontSize: '14px' }}
                align="left"
              >
                Port
              </Typography>
            </Grid>
            <Grid
              item
              xs={10}
              container
              direction="row"
              justifyContent="flex-end"
            >
              <Link
                sx={{ fontSize: '0.875rem' }}
                component={RouterLink}
                to={`/ports/${port.id}`}
                onClick={() => {
                  dispatch(
                    addHistoryItem({ type: 'incident', properties: incident })
                  );
                  goToPort(port.id);
                  navigate(`/ports/${port.id}`);
                }}
              >
                {port.title}
              </Link>
            </Grid>
          </>
        )}

        <Grid>{HTMLsummary}</Grid>
        <Container
          sx={{
            borderTop: 1,
            borderBottom: 1,
            borderColor: theme.palette.primary.dark,
            overflowY: 'auto',
            padding: '0 !important',
            scrollbarWidth: 'thin',
          }}
        >
          {description}
        </Container>
        {imo && (
          <Grid
            container
            columnSpacing={1}
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ marginTop: '0.5rem' }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                }}
              >
                IMO:
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: '14px' }}>{imo}</Typography>
            </Grid>
          </Grid>
        )}
        {callSign && (
          <Grid
            container
            columnSpacing={1}
            rowSpacing={imo ? 0 : undefined}
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ marginTop: imo ? '0' : '0.5rem' }}
          >
            <Grid item>
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                }}
              >
                Call Sign:
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ fontSize: '14px' }}>{callSign}</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      {isExpandedIncident(incident) && (
        <Button
          sx={{
            margin: '10px',
            borderRadius: '100px',
            borderColor: theme.palette.primary.dark,
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '38px',
          }}
          className="download-incidents-button"
        >
          <PDFDownloadLink
            document={incidentExport({ incidents: [incident] })}
            fileName={`${kebabify(incident.title)}.pdf`}
          >
            {({ loading }) =>
              loading ? 'Generating document...' : 'Download Incident'
            }
          </PDFDownloadLink>
        </Button>
      )}
    </Box>
  );
}

export default SpecificIncident;

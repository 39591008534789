/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CountryFilters {
  open: boolean;
  search: string;
}

interface CountriesPanel {
  filters: CountryFilters;
}

export const INITIAL_COUNTRIES_PANEL_STATE: CountriesPanel = {
  filters: {
    open: false,
    search: '',
  },
};

const CountriesPanelSlice = createSlice({
  name: 'countriesPanel',
  initialState: INITIAL_COUNTRIES_PANEL_STATE,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<CountryFilters>>) => {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
});

export const { setFilters } = CountriesPanelSlice.actions;

export default CountriesPanelSlice.reducer;

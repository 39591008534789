import {
  AlternateEmail,
  Anchor,
  Assessment,
  Business,
  Notes,
  Numbers,
  Phone,
} from '@mui/icons-material';
import { Link, List, Typography } from '@mui/material';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import {
  setSelectedFirstCallPort,
  setSelectedFirstCallPortId,
} from '../../state/ports/first-call.slice';
import DataRow from '../data-row';

function FirstCallDossier() {
  const dispatch = useAppDispatch();
  const { selectedFirstCall, selectedFirstCallId, firstCallPorts } =
    useAppSelector((state) => state.firstCallPorts);
  const isMobile = useMobile();
  useEffect(() => {
    if (
      selectedFirstCallId &&
      !selectedFirstCall &&
      firstCallPorts &&
      firstCallPorts.length > 0
    ) {
      const newSelectedFirstCall = firstCallPorts.find(
        (firstCall) => firstCall.id!.toString() === selectedFirstCallId
      );
      if (newSelectedFirstCall) {
        dispatch(setSelectedFirstCallPort(newSelectedFirstCall));
        dispatch(setSelectedFirstCallPortId(null));
      }
    }
  }, [selectedFirstCall, selectedFirstCallId, firstCallPorts]);
  if (!selectedFirstCall) {
    return null;
  }
  const { properties } = selectedFirstCall;
  return (
    <div
      data-test-id="first-call-dossier-container"
      className={classNames({ mobile: isMobile })}
    >
      <List>
        <DataRow
          label={properties.portName}
          value={properties.portCategory}
          icon={<Anchor />}
          primaryTypographyProps={{
            sx: { fontWeight: 'bold', fontSize: '1.25rem' },
          }}
        />

        <DataRow
          label="Telephone"
          value={
            <Link href={`tel:${properties.telephone}`}>
              <Typography variant="body1">{properties.telephone}</Typography>
            </Link>
          }
          icon={<Phone />}
          inverted
        />
        <DataRow
          label="Email"
          value={
            <Link href={`mailto:${properties.email}`}>
              <Typography variant="body1">{properties.email}</Typography>
            </Link>
          }
          icon={<AlternateEmail />}
          inverted
        />
        <DataRow
          label="Company"
          value={properties.company}
          icon={<Business />}
          inverted
        />
        <DataRow
          label="WPI / UNLOCODE"
          value={`${properties.worldPortIndexNumber} / ${properties.unLocode}`}
          icon={<Numbers />}
          inverted
        />
        <DataRow
          label="Port Contact Details"
          value={properties.portContactDetails}
          icon={<Notes />}
          inverted
        />
        <DataRow
          label="For more information:"
          value={
            <Link
              href="http://www.north-standard.com/first-call"
              target="_blank"
            >
              <Typography variant="body1">
                http://www.north-standard.com/first-call
              </Typography>
            </Link>
          }
          icon={<Assessment />}
          inverted
        />
      </List>
    </div>
  );
}

export default FirstCallDossier;

import { Alert, Snackbar } from '@mui/material';

interface ErrorSnackbarProps {
  open: boolean;
  onClose: (...args: any[]) => void;
  message: string;
}

export default function ErrorSnackbar({
  open,
  onClose,
  message,
}: ErrorSnackbarProps) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <Alert elevation={6} variant="filled" severity="error">
        {message}
      </Alert>
    </Snackbar>
  );
}

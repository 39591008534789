/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import { EventData } from 'mapbox-gl';
import addMapImage from './map-layer-manager/layer-utils/add-image-to-layer';

export const mapImageCache: Map<string, any> = new Map();

export const updateMapImageCache = (e: EventData) => {
  try {
    const images = e?.style?.imageManager?.images;
    Object.keys(images).forEach((imageId) => {
      const image = images[imageId];
      mapImageCache.set(imageId, image);
    });
  } catch (error) {
    return error;
  }
};

const imageDataToBlob = (imageData: ImageData) => {
  const canvas = document.createElement('canvas');
  canvas.width = imageData.width;
  canvas.height = imageData.height;
  const ctx = canvas.getContext('2d');

  ctx?.putImageData(imageData, 0, 0, 0, 0, imageData.width, imageData.height);

  return new Promise((resolve, reject) => {
    canvas.toBlob(resolve);
  });
};

export const loadCachedMapImage = async (imageId: string) => {
  try {
    const cachedImage = mapImageCache.get(imageId);

    if (!cachedImage) {
      return;
    }

    const rawImageArray = new Uint8ClampedArray(
      cachedImage?.data?.data,
      cachedImage?.width,
      cachedImage?.height
    );
    const imageData = new ImageData(
      rawImageArray,
      cachedImage?.data.width,
      cachedImage?.data.height
    );

    const imageURL = URL.createObjectURL(
      (await imageDataToBlob(imageData)) as Blob
    );

    addMapImage({
      imageImport: imageURL,
      imageName: imageId,
      convertToSDF: imageId === 'plain-vessel',
    });
  } catch (e) {
    return e;
  }
};

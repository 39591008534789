import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DEFAULT_PORT_FILTERS,
  PortTypeFilter,
  setPortsFilters,
} from '../world-ports-panel.slice';

import ListPicker from '../../../common-components/list-picker/list-picker';
import { useAppSelector } from '../../../hooks';
import useAccessControl from '../../../hooks/access-control/useAccessControl';
import './port-type-filter.scss';

const filterNames: Record<keyof PortTypeFilter, string> = {
  cruise: 'Cruise/Leisure Ports',
  inland: 'Inland Ports',
  coastal: 'Coastal Ports',
  wpi: 'WPI Ports',
};

const calcDisplayedText = (
  portTypes: PortTypeFilter | null,
  selected: number[]
) => {
  if (!portTypes || selected.length < 1) {
    return 'Type';
  }
  const firstPortTypeName = Object.values(filterNames)?.[selected[0]];
  if (selected.length === 1) {
    return `Type: ${firstPortTypeName}`;
  }
  if (selected.length === 2) {
    return `Type: ${firstPortTypeName} and 1 other`;
  }
  return `Type: ${firstPortTypeName} and ${selected.length - 1} others`;
};

function PortsTypeFilter() {
  const dispatch = useDispatch();
  const portTypeFilter = useAppSelector(
    (state) => state.portsPanel.filters.portType
  );
  const [selectedFilters, setSelectedFilters] = useState<number[]>([]);
  const displayedText = calcDisplayedText(portTypeFilter, selectedFilters);
  const { canAccessPorts } = useAccessControl();

  const listItems = Object.keys(DEFAULT_PORT_FILTERS.portType).map(
    (value, index) => ({
      id: index,
      title: filterNames?.[value as keyof PortTypeFilter] || 'Unknown',
      value,
    })
  );

  const filterListItems = () => {
    if (
      canAccessPorts.riskIntelligence &&
      !canAccessPorts.riskIntelligence.cruise &&
      listItems.some((item) => item.title === filterNames.cruise)
    ) {
      return listItems.filter((item) => item.title !== filterNames.cruise);
    }
    return listItems;
  };

  const filteredListItems = filterListItems();

  const handleSubmit = (selectedItems: number[]) => {
    const updatedFilters = filteredListItems.map((item) => [
      item.value,
      selectedItems.includes(item.id),
    ]);

    dispatch(
      setPortsFilters({
        portType: Object.fromEntries(updatedFilters),
      })
    );
  };

  useEffect(() => {
    const updatedFilters = filteredListItems
      .filter(
        (item) => portTypeFilter?.[item.value as keyof PortTypeFilter] === true
      )
      .map((item) => item.id);

    setSelectedFilters(updatedFilters);
  }, [portTypeFilter]);

  return (
    <ListPicker
      onSubmit={handleSubmit}
      items={filteredListItems}
      selectedItems={selectedFilters}
      setSelectedItems={setSelectedFilters}
      buttonText={displayedText}
    />
  );
}

export default PortsTypeFilter;

import { CallMadeRounded, CallReceivedRounded } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import * as dateFns from 'date-fns';
import { useAppDispatch } from '../../hooks';
import { setTimelineMinimised } from '../../main-menu/menu.slice';
import zIndexes from '../../z-indexes.scss';

interface MinimisedTimelineProps {
  centreDate: Date;
}

export function MinimisedTimeline(props: MinimisedTimelineProps) {
  const dispatch = useAppDispatch();
  const { centreDate } = props;
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography sx={{ fontWeight: 'bold', marginLeft: '0.5rem' }}>
        Timeline
      </Typography>

      <Typography align="center" sx={{ marginLeft: '0.5rem' }}>
        Displayed Date:{' '}
        {dateFns.roundToNearestMinutes(centreDate).toLocaleString()}
      </Typography>
      <Box>
        <IconButton
          onClick={() => dispatch(setTimelineMinimised(false))}
          data-testid="maximise-timeline-button"
          title="Maximise Timeline"
        >
          <CallMadeRounded />
        </IconButton>
      </Box>
    </Box>
  );
}

export function MinimiseButton() {
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '5px',
        right: '5px',
        zIndex: zIndexes['$--map-overlay-layer'],
      }}
    >
      <IconButton
        size="small"
        onClick={() => dispatch(setTimelineMinimised(true))}
        data-testid="minimise-timeline-button"
        title="Minimise Timeline"
      >
        <CallReceivedRounded />
      </IconButton>
    </Box>
  );
}

import { Box, Typography } from '@mui/material';
import {
  Capacity,
  Design,
  Dimensions,
  History,
  Propulsion,
  Registration,
  VesselAndTradingType,
} from '../../../../models/maritime-ais-api';

import VesselInformationLabel from './vessel-information-label/vessel-information-label';

interface VesselInformationCardProps {
  labels: string[];
  information:
    | Capacity
    | Design
    | Dimensions
    | History
    | Propulsion
    | Registration
    | VesselAndTradingType;
  // eslint-disable-next-line react/require-default-props
  additionalHTML?: any;
}

function VesselInformationCard(props: VesselInformationCardProps) {
  const { labels, information, additionalHTML } = props;

  return (
    <Box
      sx={{
        minWidth: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      {additionalHTML}
      {information ? (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        Object.entries(information).map(([_key, value], index) => (
          <VesselInformationLabel
            key={labels[index] + value}
            title={labels[index]}
            data={value}
          />
        ))
      ) : (
        <Typography variant="body1">
          No information currently available
        </Typography>
      )}
    </Box>
  );
}

export default VesselInformationCard;

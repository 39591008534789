/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useAppDispatch } from '../../../hooks';
import DateTimeHelpers, {
  dateToUtcTimeString,
} from '../../../utils/date-time-helpers.utils';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { setSelectedAISDataGapFeature } from '../history-panel.slice';
import AISDataGapsController from './ais-data-gaps-controller.utils';

interface HistoryAISDataGapResultCardProps {
  feature: GeoHelper.TimeGapFeature;
  selected: boolean;
}

function HistoryAISDataGapResultCard(props: HistoryAISDataGapResultCardProps) {
  const { feature, selected } = props;
  const { properties } = feature;
  const { mmsi, startTimestamp, duration, imo, name } = properties;

  const dispatch = useAppDispatch();

  const onAISDataGapResultClick = () => {
    AISDataGapsController.onFeatureSelect(feature);
    dispatch(setSelectedAISDataGapFeature(feature));
  };

  const hours = Number(duration) / DateTimeHelpers.MILLISECONDS_IN_HOUR;

  return (
    <ListItem
      disableGutters
      disablePadding
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'background.paper',
      }}
    >
      <ListItemButton
        onClick={onAISDataGapResultClick}
        selected={selected}
        autoFocus={selected}
      >
        <ListItemText
          primary={
            <>
              <Typography variant="body2">{name}</Typography>

              <Typography
                component="span"
                variant="body2"
                data-testid="ais-data-gap-vessel-details"
              >
                <b>MMSI</b> {mmsi} &nbsp;
                <b>IMO</b> {imo}
              </Typography>
            </>
          }
          secondary={
            <Typography variant="body2" data-testid="ais-data-gap-length">
              <b>Length</b> {DateTimeHelpers.formatHoursAndMinutes(hours)}
              &nbsp; {dateToUtcTimeString(new Date(startTimestamp))}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default HistoryAISDataGapResultCard;

import MapHelpers from '../../map.utils';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerIcon from '../map-layer-icon';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

// A property added when cluster: true on a GeoJSON Source.
const GLOBAL_ICON_SCALE_FACTOR = 1.2;
const POINT_COUNT = 'point_count';

function setCorrespondentsLayer(layerId: MapLayer | string, metadata?: {}) {
  const clusterLayerId = MapLayer.CORRESPONDENT_CLUSTERS;
  const clusterCountLayerId = MapLayer.CORRESPONDENT_CLUSTER_COUNT;

  return new Promise((resolve) => {
    addSimpleGeoJsonSource(layerId, true);

    MapHelpers.addLayer({
      id: clusterLayerId,
      type: 'circle',
      source: layerId,
      filter: ['has', POINT_COUNT],
      metadata,
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
      },
      paint: {
        'circle-color': '#6987FF',
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        'circle-radius': ['step', ['get', POINT_COUNT], 10, 5, 15, 10, 20],
      },
    });

    MapHelpers.addLayer({
      id: clusterCountLayerId,
      type: 'symbol',
      source: layerId,
      filter: ['has', POINT_COUNT],
      metadata,
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
        'text-field': ['get', 'point_count_abbreviated'],
        'text-allow-overlap': true,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
      paint: {
        'text-color': '#08182E',
      },
    });

    MapHelpers.addLayer({
      id: layerId,
      type: 'symbol',
      source: layerId,
      metadata,
      filter: ['!', ['has', POINT_COUNT]],
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
        'icon-image': [
          'case',
          ['==', ['get', 'selected'], true],
          MapLayerIcon.PORT.CORRESPONDENT_SELECTED,
          MapLayerIcon.PORT.CORRESPONDENT,
        ],
        'icon-allow-overlap': true,
        'icon-size': [
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#interpolate
          'interpolate',
          ['linear'],
          ['zoom'],
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#camera-expressions
          5,
          // scale based on zoom level but also the harbour size
          0.7 * GLOBAL_ICON_SCALE_FACTOR,
          8,
          0.8 * GLOBAL_ICON_SCALE_FACTOR,
          16,
          1.2 * GLOBAL_ICON_SCALE_FACTOR,
        ],
      },
    });

    resolve(MapHelpers);
  });
}

export default setCorrespondentsLayer;

import { Alert, Typography } from '@mui/material';
import { useState } from 'react';
import './error-panel.scss';

interface ErrorPanelProps {
  message: string;
  level?: 'warning' | 'error';
  dismissable?: boolean;
}

function ErrorPanel({ message, level, dismissable }: ErrorPanelProps) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {open && (
        <Alert
          data-testid="error-panel"
          severity={level}
          onClose={dismissable ? handleClose : undefined}
        >
          <Typography data-testid="error-panel-message">{message}</Typography>
        </Alert>
      )}
    </>
  );
}

ErrorPanel.defaultProps = {
  level: 'error',
  dismissable: false,
};

export default ErrorPanel;

/* eslint-disable react/destructuring-assignment */
import LanguageIcon from '@mui/icons-material/Language';
import { Box, Divider, Link, List, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { getSanctions } from '../../../../api/sanctions';
import ErrorPanel from '../../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../../common-components/loading-panel/loading-panel';
import { useAppSelector } from '../../../../hooks';
import SanctionItem from '../sanction-item/sanction-item';
import {
  GeollectSanction,
  MergedSanctions,
  NorthStandardSanction,
} from '../sanction.model';

enum SanctionType {
  NS = 'NS',
  GEOLLECT = 'GEOLLECT',
}

function RenderSanctions({
  sanctionInfo,
  sanctionType,
  isLoggedIn,
}: {
  sanctionInfo: NorthStandardSanction[] | GeollectSanction[];
  sanctionType: SanctionType;
  isLoggedIn: boolean;
}) {
  return sanctionInfo.length === 0 ? (
    <List>
      {sanctionType === SanctionType.NS && (
        <Typography
          data-testid="ns-no-sanctions-message"
          paragraph
          variant="body2"
          align="left"
        >
          There is no specific sanctions information available for this country.
          You can access all NorthStandard’s sanctions information in our
          dedicated area{' '}
          <Link
            href="https://north-standard.com/insights-and-resources/industry-insights/sanctions"
            sx={{ color: 'secondary.main' }}
          >
            here
          </Link>
          . Our dedicated sanctions advice group are available to answer any
          specific sanctions related questions and can be reached{' '}
          <Link
            href="mailto:sanctionsadvice@north-standard.com"
            sx={{ color: 'secondary.main' }}
          >
            here
          </Link>
          .
        </Typography>
      )}
      {sanctionType === SanctionType.GEOLLECT &&
        (isLoggedIn ? (
          <Typography paragraph variant="body2" align="left">
            There are no specific sanctions programs in place for this country.
          </Typography>
        ) : (
          <Typography paragraph variant="body2" align="left">
            You require a NorthStandard account to access this sanctions
            information.
          </Typography>
        ))}
    </List>
  ) : (
    <List
      sx={{
        maxWidth: '100%',
        maxHeight: '100%',
        overflowY: 'auto',
        overflowX: 'auto',
        scrollbarWidth: 'thin',
      }}
    >
      {sanctionInfo.map((sanction) => (
        <SanctionItem sanction={sanction} />
      ))}
    </List>
  );
}

function SanctionList() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [sanctionInfo, setSanctionInfo] = useState<MergedSanctions | null>(
    null
  );
  const selectedCountry = useAppSelector(
    (state) => state.countries.selectedCountry
  );
  const idToken = useAppSelector((state) => state.user.idToken);

  const previousCountryId = useRef<string>('');

  const ERROR_MESSAGE =
    'Unable to fetch sanction information. Please try again later...';

  let nsSanctionInfo: NorthStandardSanction[] = [];
  let otherSanctionInfo: GeollectSanction[] = [];

  useEffect(() => {
    // prevent fetching before country is loaded, or if already loading
    // [] means the call is complete, there's just no data
    if (!selectedCountry || loading) {
      return;
    }

    // prevent double fetching
    if (
      selectedCountry.unlocode.toLowerCase() ===
      previousCountryId.current.toLowerCase()
    ) {
      return;
    }
    // update cached country id
    previousCountryId.current = selectedCountry.unlocode;

    setLoading(true);

    getSanctions([selectedCountry.unlocode])
      .then((response) => {
        setSanctionInfo(response);
      })
      .catch(() => {
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCountry]);

  if (sanctionInfo) {
    nsSanctionInfo = Array.isArray(sanctionInfo.north_standard)
      ? sanctionInfo.north_standard
      : [];
    otherSanctionInfo = Array.isArray(sanctionInfo.geollect)
      ? sanctionInfo.geollect
      : [];
  }

  return (
    <Box sx={{ marginTop: '1em' }}>
      {loading && !hasError && <LoadingPanel />}
      {!loading && hasError && <ErrorPanel message={ERROR_MESSAGE} />}
      {!loading && !hasError && (
        <>
          <Box data-testid="ns-sanction-list">
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  color: 'secondary.main',
                  textAlign: 'left',
                  marginLeft: '4px',
                  fontSize: '0.9rem',
                  textDecoration: 'underline',
                }}
              >
                NorthStandard Sanctions
              </Typography>
              <Link
                href="https://north-standard.com/insights-and-resources/industry-insights/sanctions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LanguageIcon
                  sx={{ color: 'secondary.main', paddingLeft: '4px' }}
                />
              </Link>
            </Box>
            {loading && <ErrorPanel message={ERROR_MESSAGE} />}
            {!loading && !hasError && nsSanctionInfo && (
              <RenderSanctions
                sanctionInfo={nsSanctionInfo}
                sanctionType={SanctionType.NS}
                isLoggedIn={!!idToken}
              />
            )}
          </Box>
          <Divider
            sx={{
              borderBottom: '1.5px solid',
              color: 'secondary.main',
              marginBottom: '10px',
            }}
          />
          <Box data-testid="geollect-sanction-list">
            <Typography
              sx={{
                color: 'secondary.main',
                textAlign: 'left',
                textDecoration: 'underline',
                marginLeft: '4px',
                fontSize: '0.9rem',
              }}
            >
              Sanctions from other sources
            </Typography>
            {!loading && !hasError && otherSanctionInfo && (
              <RenderSanctions
                sanctionInfo={otherSanctionInfo}
                sanctionType={SanctionType.GEOLLECT}
                isLoggedIn={!!idToken}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default SanctionList;

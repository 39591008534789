import { Close, Menu } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import { setPanelStatus } from '../../search-bar/search-panel.slice';
import { toggleMainMenu } from '../menu.slice';
import './side-menu.scss';

function ExpandMenuButton() {
  const dispatch = useAppDispatch();
  const menuOpen = useAppSelector((state) => state.menu.menuOpen);

  const isMobile = useMobile();

  return (
    <IconButton
      title={menuOpen ? '' : 'Expand Menu'}
      disableRipple
      data-testid="menu-button"
      sx={{
        m: isMobile ? '1rem 8px 0.8rem' : '1.6rem 8px 0.8rem',

        display: 'flex',
        float: menuOpen ? 'right' : 'none',
        justifyContent: 'centre',
        color: 'secondary.main',
        '&:hover': {
          bgcolor: 'secondary.dark',
          borderRadius: isMobile ? '0' : '0.5rem',
        },
        borderRadius: isMobile ? '0' : '0.5rem',
      }}
      onClick={() => {
        dispatch(setPanelStatus({ panelOpen: false }));
        dispatch(toggleMainMenu());
      }}
    >
      {!menuOpen ? (
        <Menu data-testid="open-menu" className={cn({ mobile: isMobile })} />
      ) : (
        <Close data-testid="close-menu" />
      )}
    </IconButton>
  );
}

export default ExpandMenuButton;

import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import MapLayer from '../map-layer.enum';

function onCorrespondentsClusterClick(
  map: mapboxgl.Map,
  correspondentsCluster: GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
) {
  const clusterId = correspondentsCluster.properties?.cluster_id;
  (
    map.getSource(MapLayer.CORRESPONDENTS) as GeoJSONSource
  ).getClusterExpansionZoom(clusterId, (err, zoom) => {
    if (err) return;

    map.easeTo({
      center: correspondentsCluster.geometry.coordinates as [number, number],
      zoom: zoom + 0.5,
    });
  });
}

export default onCorrespondentsClusterClick;

import PortItem from '../port-item/port-item';
import { Port, portKey } from '../world-ports.model';

import VirtualisedListWrapper from '../../../common-components/virtualised-list/virtualised-list';
import store from '../../../store';
import './ports-list.scss';

interface Props {
  ports: Port[];
}

function PortsList({ ports }: Props) {
  const { selectedPort } = store.getState().ports;
  const selectedPortIndex = selectedPort
    ? ports.findIndex((port) => portKey(port) === portKey(selectedPort))
    : null;

  return (
    <VirtualisedListWrapper
      dataTestId="port-list"
      items={ports}
      selectedItemIndex={selectedPortIndex}
      // eslint-disable-next-line react/no-unstable-nested-components
      listContent={(index) => (
        <PortItem
          selected={selectedPortIndex === index}
          port={ports[index]}
          key={portKey(ports[index])}
        />
      )}
    />
  );
}

export default PortsList;

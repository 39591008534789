import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FirstCallFeature } from '../../models/first-call';

interface FirstCallListItemProps {
  firstCall: FirstCallFeature;
  selected: boolean;
  onClick: (firstCall: FirstCallFeature) => void;
  divider: boolean;
}

function FirstCallListItem({
  firstCall,
  selected,
  onClick,
  divider,
}: FirstCallListItemProps) {
  const { properties } = firstCall;
  return (
    <ListItem divider={divider} disablePadding>
      <ListItemButton
        selected={selected}
        autoFocus={selected}
        onClick={() => onClick(firstCall)}
      >
        <ListItemText
          primary={properties.portName}
          secondary={properties.portCategory}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default FirstCallListItem;

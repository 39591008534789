import * as dateFns from 'date-fns';
import { Position } from 'geojson';
import { HistoricVesselPoint } from '../maritime-menu-options/history-panel/historic-vessel-point.model';
import { HistoryFormValues } from '../maritime-menu-options/history-panel/history-form/history-form-validators';
import VesselHistoryController from '../maritime-menu-options/history-panel/vessel-history-controller.utils';
import {
  GetVesselResponse,
  MaritimeAisApiLocation,
  MaritimeAisApiLocationData,
  MaritimeAisApiLocationDefined,
  MaritimeAisApiLocationOnlyResponse,
  MessageData,
  MessageDataWithPosition,
  VesselData,
} from '../models/maritime-ais-api';
import { Vessel, VesselSource } from '../models/vessel.model';
import { wrapRequest } from './base';

export const WEBSOCKET_CHUNK_SIZE = 200;

export const intialiseVesselsByLocationWebsocket = async (
  geometry: Position[][],
  hours: number,
  clientId: string
): Promise<void> => {
  const endDatetime = new Date().toISOString();
  const startDatetime = dateFns.subHours(new Date(), hours).toISOString();
  const mostRecent = true; // This ensures we only get the most recent position update, not all updates for each vessel

  wrapRequest('post', 'north_standard', `/daas-proxy`, {
    body: {
      endpoint: 'maritime-ais/websocket/location-messages',
      targetArea: geometry,
      startDatetime,
      endDatetime,
      mostRecent,
      clientId,
    },
  });
};

// returns a dict of vessel messages, keyed by vesselId. Only vessels with messages are included
export const mergeMessagesOntoVessels = (
  vessels: Record<string, VesselData>,
  messages: MessageData[],
  reverseMessages: boolean = true
) => {
  const vesselsCopy: Record<string, MaritimeAisApiLocation> = {};
  messages.forEach((message) => {
    if (!message.vesselId) {
      return;
    }
    const vessel = vessels[message.vesselId];
    if (!vessel) {
      return;
    }
    if (!vesselsCopy[message.vesselId]) {
      vesselsCopy[message.vesselId] = {
        vessel,
        messages: [message],
      };
    } else {
      vesselsCopy[message.vesselId].messages.push(message);
    }
  });
  if (reverseMessages) {
    Object.values(vesselsCopy).forEach((vesselData) => {
      vesselData.messages.reverse();
    });
  }
  return vesselsCopy;
};

/**
 * The new API endpoint provides more+better info, but RI is not allowed to use it, so it's easier to convert the new data to the old shape (for now)
 * This function converts a single vessel+messages from the new API to the old shape
 * @param vesselData
 * @returns
 */
export const geollectDaasFormatToGeoniusColdFormat = ({
  messages,
  vessel,
}: {
  messages: MessageData[];
  vessel: VesselData;
}): HistoricVesselData => {
  const { vesselId } = vessel;

  const vesselMessagesArray = (
    messages.filter((message) => message.position) as MessageDataWithPosition[]
  ).map((message) => {
    const historicVesselPoint: HistoricVesselPoint = {
      id: message.messageId,
      vessel_id: vesselId,
      timestamp: new Date(message.timestamp).getTime(),
      created_at: new Date(message.createdAt).getTime(),
      speed: message.speed,
      rot: message.rot,
      collection_type: message.collectionType,
      maneuver: message.maneuver,
      name: vessel.staticData.name,
      imo: vessel.staticData.imo,
      mmsi: vessel.staticData.mmsi,
      heading: message.heading,
      latitude: message.position.coordinates[1],
      longitude: message.position.coordinates[0],
      course: message.course,
      source: VesselSource.AIS,
    };
    return historicVesselPoint;
  });

  const lastItem = vesselMessagesArray[vesselMessagesArray.length - 1];

  if (!lastItem) {
    return {
      messages: [],
      vessel: {} as Vessel,
    };
  }

  return {
    messages: vesselMessagesArray,
    vessel: {
      name: vessel.staticData.name,
      vessel_id: vessel.vesselId,
      latitude: vessel.lastPositionUpdate.position.coordinates[1],
      longitude: vessel.lastPositionUpdate.position.coordinates[0],
      course: lastItem.course,
      speed: lastItem.speed,
      timestamp: lastItem.timestamp,
      heading: lastItem.heading,
      imo: vessel.staticData.imo,
      mmsi: vessel.staticData.mmsi,
      source: VesselSource.AIS,
    },
  };
};

export const mapResponseToHistoricVesselPoints = (
  data: (MessageData & { position: Exclude<MessageData['position'], null> })[]
): HistoricVesselPoint[] => {
  if (!data) {
    throw new Error('The Vessel locations response is null or undefined.');
  }

  return data.map((value) => {
    const {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _id,
      timestamp,
      vesselId,
      mmsi,
      position,
      createdAt,
      speed,
      course,
      heading,
      collectionType,
      rot,
      maneuver,
    } = value;

    if (
      !_id ||
      !timestamp ||
      !vesselId ||
      !mmsi ||
      !position.coordinates[0] ||
      !position.coordinates[1] ||
      !createdAt
    ) {
      throw new Error(
        'Encountered null or undefined value in Vessel locations response data.'
      );
    }

    return {
      id: _id,
      name: 'n/a',
      timestamp: new Date(timestamp).getTime(),
      vessel_id: vesselId,
      mmsi,
      latitude: position.coordinates[1],
      longitude: position.coordinates[0],
      created_at: new Date(createdAt).getTime(),
      speed,
      course,
      heading,
      collection_type: collectionType,
      rot,
      maneuver,
      source: VesselSource.AIS,
    };
  });
};

export function responseToLocationArray(
  data: MaritimeAisApiLocationData,
  mergeToSingleArray: boolean = false
): HistoricVesselPoint[][] {
  // array of arrays to support the history of multiple vessels
  const resultsArray: HistoricVesselPoint[][] = [];
  if (mergeToSingleArray) {
    resultsArray.push([]);
  }

  (
    Object.values(data.data).filter(
      (value) => value.vessel
    ) as MaritimeAisApiLocationDefined[]
  ).forEach((vesselData) => {
    const definedVessel = vesselData as {
      vessel: VesselData;
      messages: MessageData[];
    };
    const converted = geollectDaasFormatToGeoniusColdFormat(definedVessel);
    if (mergeToSingleArray) {
      resultsArray[0].push(...converted.messages);
    } else {
      resultsArray.push(converted.messages);
    }
  });
  if (mergeToSingleArray) {
    // if merged to a single array, sort all results by timestamp
    resultsArray[0].sort((a, b) => a.timestamp - b.timestamp);
  }
  return resultsArray;
}

export const getVesselDAAS = async (
  mmsi: string
): Promise<VesselData | null> => {
  const result = await wrapRequest<GetVesselResponse>(
    'post',
    'north_standard',
    `/daas-proxy`,
    {
      body: {
        mmsis: [parseInt(mmsi, 10)],
        limit: 1,
        offset: 0,
        endpoint: 'maritime-ais/vessels',
      },
    }
  );
  if (result && result.data && result.data.length > 0) {
    return result.data[0];
  }
  return null;
};

interface GetVesselsDAASRequest {
  mmsis?: string[];
  imos?: string[];
  vesselIds?: string[];
  limit?: number;
  offset?: number;
}

export const getVesselsDAAS = async ({
  mmsis = [],
  imos = [],
  vesselIds = [],
  limit = 1000,
  offset = 0,
}: GetVesselsDAASRequest): Promise<VesselData[]> => {
  const vessels: VesselData[] = [];
  const response = await wrapRequest<GetVesselResponse>(
    'post',
    'north_standard',
    `/daas-proxy`,
    {
      body: {
        mmsis:
          mmsis && mmsis.length > 0
            ? mmsis.map((mmsi) => parseInt(mmsi, 10))
            : undefined,
        imos:
          imos && imos.length > 0
            ? imos.map((imo) => parseInt(imo, 10))
            : undefined,
        vesselIds:
          vesselIds && vesselIds.length > 0
            ? vesselIds.map((vesselId) => parseInt(vesselId, 10))
            : undefined,
        offset: 0,
        endpoint: 'maritime-ais/vessels',
      },
    }
  );
  vessels.push(...response.data);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { data_size } = response.metadata.pagination;
  if (data_size === limit) {
    // recurse until all vessels have been retrieved
    const nextVessels = await getVesselsDAAS({
      mmsis,
      imos,
      vesselIds,
      limit,
      offset: offset + limit,
    });
    vessels.push(...nextVessels);
  }
  return vessels;
};

/**
 * Given a set of parameters, initialise the DAAS System to begin a history request
 * The results will be returned via a websocket, with the provided clientId
 * @param startDatetime
 * @param endDatetime
 * @param mmsis
 * @param imos
 * @param vesselIds
 * @param clientId
 */
export const initialiseLocationDataViaWebsocket = async (
  startDatetime: Date,
  endDatetime: Date,
  clientId: string,
  mmsis?: string[],
  imos?: string[],
  vesselIds?: string[],
  downsampleRate: string = '1m', // 1m, 10m (only options currently)
  highTierSearchMode?: boolean
) =>
  // if high tier search maxExternalProviderWorkers: undefined, else limit maxExternalProviderWorkers to 1
  wrapRequest('post', 'north_standard', '/daas-proxy', {
    body: {
      maxExternalProviderWorkers:
        highTierSearchMode !== undefined && highTierSearchMode ? undefined : 1,
      // becomes undefined with empty arrays
      mmsis:
        mmsis && mmsis.length > 0
          ? mmsis!.map((mmsi) => parseInt(mmsi, 10))
          : undefined,
      imos:
        imos && imos.length > 0
          ? imos!.map((imo) => parseInt(imo, 10))
          : undefined,
      vesselIds:
        vesselIds && vesselIds.length > 0
          ? vesselIds!.map((vesselId) => parseInt(vesselId, 10))
          : undefined,
      // modify datetimes for database query
      startDatetime: dateFns
        .startOfDay(startDatetime)
        .toISOString()
        .split('.')[0],
      endDatetime: dateFns.endOfDay(endDatetime).toISOString().split('.')[0],
      maxChunkSize: WEBSOCKET_CHUNK_SIZE, // websocket messages have a relatively small max size. 350 works, so 250 gives us some overhead
      clientId,
      downsampleRate,
      endpoint: 'maritime-ais/websocket/location-messages',
    },
  });

export const getLocationDataDAAS = async (
  startDatetime: Date,
  endDatetime: Date,
  mmsis: string[],
  imos: string[],
  downsampleRate: string = '1m',
  limit: number = 10000,
  offset: number = 0,
  vesselHistoryPageProcessorCallback?: (
    data: MaritimeAisApiLocationData
  ) => void
): Promise<MaritimeAisApiLocationData> =>
  wrapRequest('post', 'north_standard', `/daas-proxy`, {
    body: {
      // becomes undefined with empty arrays
      mmsis:
        mmsis.length > 0 ? mmsis.map((mmsi) => parseInt(mmsi, 10)) : undefined,
      imos:
        imos!.length > 0 ? imos!.map((imo) => parseInt(imo, 10)) : undefined,
      mergeOnVessels: true,
      limit,
      offset,
      // modify datetimes for database query
      startDatetime: dateFns.startOfDay(startDatetime).toISOString(),
      endDatetime: dateFns.endOfDay(endDatetime).toISOString(),
      downsampleRate,
      endpoint: 'maritime-ais/location-messages',
    },
  }).then(async (response) => {
    if (vesselHistoryPageProcessorCallback) {
      vesselHistoryPageProcessorCallback(response);
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data_size } = response.metadata.pagination;
    if (data_size === limit) {
      const nextMessages = await getLocationDataDAAS(
        startDatetime,
        endDatetime,
        mmsis,
        imos,
        downsampleRate,
        limit,
        offset + limit,
        vesselHistoryPageProcessorCallback
      );
      // for every vessel in next messages check if it already exists in messages, if not add it
      // if it does then append the messages to the existing vessel
      Object.entries(response.data).forEach(([vesselId]) => {
        const nextVesselData = nextMessages.data[vesselId];
        if (nextVesselData) {
          response.data[vesselId].messages.push(...nextVesselData.messages);
        }
      });
    }
    return response;
  });

/**
 * If a websocketId is provided, then the websocket will be used to return the data
 * Otherwise, recursive http requests will be used
 * @param query
 * @returns
 */
export const getVesselHistory = async (
  query: VesselHistoryQuery,
  vesselHistoryPageProcessorCallback?: (
    data: MaritimeAisApiLocationData
  ) => void,
  websocketId?: string
): Promise<VesselHistoryResponse | VesselData[]> => {
  if (VesselHistoryController.uniqueHistoryVesselIds.length > 0)
    VesselHistoryController.uniqueHistoryVesselIds.length = 0;

  // websocket id provided, use websocket mode
  if (websocketId) {
    // in websocket mode, we only get location messages, not vessel data.
    // so we need to get the vessel data separately
    // we also need to request it first, so it is ready when the websocket messages start coming through.
    const vesselData = await getVesselsDAAS({
      mmsis: query.mmsis,
      imos: query.imos,
      vesselIds: [],
    });
    initialiseLocationDataViaWebsocket(
      new Date(query['start-date']),
      new Date(query['end-date']),
      websocketId,
      query.mmsis,
      query.imos,
      [], // could also provide vesselIds here
      query['sample-rate'],
      query.highTierSearchMode
    );
    return vesselData;
  }

  const response = await getLocationDataDAAS(
    new Date(query['start-date']),
    new Date(query['end-date']),
    query.mmsis,
    query.imos,
    query['sample-rate'],
    10000,
    0,
    vesselHistoryPageProcessorCallback
  );

  const data = (
    Object.values(response.data).filter(
      (value) => value.vessel
    ) as MaritimeAisApiLocationDefined[]
  ).map((vesselData) => geollectDaasFormatToGeoniusColdFormat(vesselData));

  return { data };
};

interface VesselHistoryQuery {
  'start-date': string;
  'end-date': string;
  'sample-rate': string;
  mmsis: string[];
  imos: string[];
  highTierSearchMode?: boolean;
}

export interface HistoricVesselData {
  messages: HistoricVesselPoint[];
  vessel: Vessel;
}

export interface VesselHistoryResponse {
  data: HistoricVesselData[];
}

export interface VesselHistoryData extends VesselHistoryResponse {
  formValues: HistoryFormValues;
}

export const getVesselLocationsDAAS = async (
  mmsi: string,
  endDate: Date, // this prevents points further than the shown location being added to the map
  days: number = 7, // number of days to go back
  downsampleRate: string = '1m', // 1m, 1h or 1d
  limit: number = 10000,
  offset: number = 0
): Promise<MessageDataWithPosition[]> => {
  const startDate = dateFns.subDays(endDate, days);

  // remove trailing values as per API docs
  const startDatetime = startDate.toISOString().slice(0, 19);
  const endDatetime = endDate.toISOString().slice(0, 19);

  const messages: MessageDataWithPosition[] = [];

  await wrapRequest('post', 'north_standard', `/daas-proxy`, {
    body: {
      mmsis: [parseInt(mmsi, 10)],
      limit,
      offset,
      downsampleRate,
      startDatetime,
      endDatetime,
      endpoint: 'maritime-ais/location-messages',
    },
  }).then(async (response: MaritimeAisApiLocationOnlyResponse) => {
    (
      Object.values(response.data)[0].filter(
        (message) => message.position
      ) as MessageDataWithPosition[]
    ).map((message) => messages.push(message));
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { data_size } = response.metadata.pagination;
    if (data_size === limit) {
      const nextMessages = await getVesselLocationsDAAS(
        mmsi,
        endDate,
        days,
        downsampleRate,
        limit,
        offset + limit
      );
      (
        nextMessages.filter(
          (message) => message.position
        ) as MessageDataWithPosition[]
      ).map((message) => messages.push(message));
    }
  });
  return messages;
};

import { hideVesselInfoPopUp, showVesselInfoPopUp } from './vessel-info-popup';
import onVesselClick from './vessel-onClick';

namespace VesselsController {
  export const layerEvents = {
    onMouseEnter: showVesselInfoPopUp,
    onMouseLeave: hideVesselInfoPopUp,
    onClick: onVesselClick,
  };
}

export default VesselsController;

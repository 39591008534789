import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Accordion from '../../../../common-components/accordion/ri-accordion';
import { useAppSelector } from '../../../../hooks';
import {
  Port,
  WPIPort,
  getColumnNameFromShortCode,
  getValueFromShortCode,
} from '../../world-ports.model';

interface SpecificPortProps {
  port: Port;
}

const partitionByUnknown = (port: Port) => {
  const [knowns, unknowns] = Object.entries(port).reduce<
    [[keyof Port, string][], [keyof Port, string][]]
  >(
    (acc, [key, value]) => {
      // Remove 'selected' from portData
      if (key === 'selected') {
        return acc;
      }
      if (value === 'U' || value === '') {
        acc[1].push([key as keyof Port, value]);
      } else {
        acc[0].push([key as keyof Port, value]);
      }
      return acc;
    },
    [[], []]
  );
  return { knowns, unknowns };
};

function WPITab({ port }: SpecificPortProps) {
  const tickerOpen = useAppSelector(
    (state) => state.userPreferences.userPreferences.showRiNewsBanner
  );
  const { knowns, unknowns } = partitionByUnknown(port);
  const knownRows = knowns.map(([key, value]) => (
    <TableRow key={key}>
      <TableCell sx={{ fontWeight: 500, borderBottom: 'none' }}>
        {getColumnNameFromShortCode(key as keyof WPIPort)}
      </TableCell>
      <TableCell sx={{ textAlign: 'right', borderBottom: 'none' }}>
        {getValueFromShortCode(key as keyof WPIPort, value)}
      </TableCell>
    </TableRow>
  ));
  const unknownRows = unknowns.map(([key, value]) => (
    <TableRow key={key}>
      <TableCell sx={{ fontWeight: 500, borderBottom: 'none' }}>
        {getColumnNameFromShortCode(key as keyof WPIPort)}
      </TableCell>
      <TableCell sx={{ textAlign: 'right', borderBottom: 'none' }}>
        <i>{getValueFromShortCode(key as keyof WPIPort, value)}</i>
      </TableCell>
    </TableRow>
  ));
  const knownTable = (
    <Table size="small">
      <TableBody>{knownRows}</TableBody>
    </Table>
  );
  const unknownTable = (
    <Table size="small">
      <TableBody>{unknownRows}</TableBody>
    </Table>
  );
  return (
    <Box
      sx={{
        maxHeight: tickerOpen ? 'calc(84vh - 2rem)' : '84vh',
        bgColor: 'primary.main',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        borderRadius: '0 0 1rem 1rem',
      }}
    >
      <Box sx={{ padding: '0 1rem' }}>{knownTable}</Box>
      <Accordion
        borderRadius
        title="Unknown Data"
        content={<Box sx={{ padding: '0 1rem' }}>{unknownTable}</Box>}
      />
    </Box>
  );
}

export default WPITab;

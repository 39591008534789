import {
  AUTH_DOMAIN,
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_USER_POOL_ID,
  AWS_USER_POOL_WEBCLIENT_ID,
  ENTITY_DOCUMENTS_BUCKET,
  GEONIUS_BACKEND_URL_ROOT,
  NS_BACKEND_URL,
  SIGN_IN_REDIRECT_URL,
  SIGN_OUT_REDIRECT_URL,
} from './environment';

const GEONIUS_API_NAME = 'geonius';
const MAPBOX_API_NAME = 'mapbox';
const NORTH_STANDARD_API_NAME = 'north_standard';
export type ApiName =
  | typeof GEONIUS_API_NAME
  | typeof MAPBOX_API_NAME
  | typeof NORTH_STANDARD_API_NAME;

const awsExports = {
  Auth: {
    region: AWS_REGION,
    userPoolId: AWS_USER_POOL_ID,
    userPoolWebClientId: AWS_USER_POOL_WEBCLIENT_ID,
    identityPoolId: AWS_IDENTITY_POOL_ID,
    oauth: {
      domain: AUTH_DOMAIN,
      scope: ['openid'],
      redirectSignIn: SIGN_IN_REDIRECT_URL,
      redirectSignOut: SIGN_OUT_REDIRECT_URL,
      clientId: AWS_USER_POOL_WEBCLIENT_ID,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: GEONIUS_API_NAME,
        endpoint: GEONIUS_BACKEND_URL_ROOT,
        service: 'execute-api',
        region: AWS_REGION,
      },
      {
        name: MAPBOX_API_NAME,
        endpoint: 'https://api.mapbox.com',
      },
      {
        name: NORTH_STANDARD_API_NAME,
        endpoint: NS_BACKEND_URL,
        service: 'execute-api',
        region: AWS_REGION,
      },
    ],
  },
  Storage: {
    AWSS3: {
      // Set the default bucket.
      // If we want to use multiple buckets in the future, the Storage API methods also allow the bucket to be
      // specified e.g. Storage.get('key', { bucket: 'bucket name' })
      bucket: ENTITY_DOCUMENTS_BUCKET,
      region: AWS_REGION,
    },
  },
};

export default awsExports;

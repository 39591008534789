import {
  CircleOutlined,
  LinearScale,
  PentagonOutlined,
  RoomOutlined,
} from '@mui/icons-material';
import { Box, List, Typography } from '@mui/material';
import ErrorPanel from '../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import { useAppSelector } from '../../../hooks';
import { Drawing, DrawingShape } from '../../../models/drawings.model';
import AreaInfoCard from '../area-info-card';

export const decideIconShape = (shape: DrawingShape) => {
  const commonIconStyle = { fontSize: '22px', color: 'secondary.main' };

  const iconComponents = {
    [DrawingShape.Circle]: <CircleOutlined sx={commonIconStyle} />,
    [DrawingShape.Polygon]: <PentagonOutlined sx={commonIconStyle} />,
    [DrawingShape.Line]: <LinearScale sx={commonIconStyle} />,
    [DrawingShape.Point]: <RoomOutlined sx={commonIconStyle} />,
  };

  return iconComponents[shape] || null;
};

interface Props {
  filteredDrawings: Drawing[];
  onInfoCardClick: (drawing: Drawing) => void;
  onEditClick: (drawing: Drawing) => void;
  deleteDrawing: (drawingId: string) => void;
}

function DrawingsList({
  filteredDrawings,
  onInfoCardClick,
  onEditClick,
  deleteDrawing,
}: Props) {
  const { loading, error, selectedDrawing } = useAppSelector(
    (state) => state.drawings
  );

  return (
    <>
      {loading && <LoadingPanel />}
      {!loading && error && (
        <ErrorPanel message="Error: Unable to fetch drawings incidents, please try again later... " />
      )}

      <List
        data-testid="drawings-list"
        sx={{
          overflowY: 'auto',
          scrollbarWidth: 'thin',
        }}
      >
        {filteredDrawings.length > 0 ? (
          filteredDrawings.map((drawing) => (
            <AreaInfoCard
              key={drawing.region_id}
              titleIcon={decideIconShape(
                drawing.region_data.properties?.subType
              )}
              title={drawing.region_name}
              entityId={drawing.region_id}
              subtitle={`${drawing.description}`}
              deleteDrawing={deleteDrawing}
              onClick={() => onInfoCardClick(drawing)}
              onEditClick={() => onEditClick(drawing)}
              drawingId={drawing.region_id}
              selected={selectedDrawing?.region_id === drawing.region_id}
            />
          ))
        ) : (
          <Box
            className="drawings-panel-warning-message"
            data-testid="drawings-panel-warning-message"
            sx={{ padding: '1em' }}
          >
            <Typography>No drawings found</Typography>
          </Box>
        )}
      </List>
    </>
  );
}

export default DrawingsList;

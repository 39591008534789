import {
  RangeRingPoint,
  RangeRingProperties,
} from '../../../maritime-menu-options/tools-panel/range-rings/range-ring.model';
import MapLayerManager from '../map-layer-manager.utils';

export const removeRangeRing = (rangeRing: RangeRingProperties) => {
  const pointLayers = MapLayerManager.findLayersByGroupLayerId(rangeRing.id);

  [...pointLayers].forEach((layer) => MapLayerManager.destroyLayer(layer.id));
};

export const removeRangeRingPoint = (point: RangeRingPoint) => {
  const rangeRingLayers = MapLayerManager.findLayersByGroupLayerId(point.id);

  [...rangeRingLayers].forEach((layer) =>
    MapLayerManager.destroyLayer(layer.id)
  );
};

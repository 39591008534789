import mapboxgl from 'mapbox-gl';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerIcon from '../map-layer-icon';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer, { MapGroupLayer } from '../map-layer.enum';

const GLOBAL_ICON_SCALE_FACTOR = 2;

function addWeatherPinLayer(
  layer: MapLayer | string,
  visibility: MapLayerVisibility = MapLayerVisibility.VISIBLE,
  metadata: object = {}
) {
  addSimpleGeoJsonSource(layer);
  MapLayerManager.AddLayerinGroups(
    {
      id: layer,
      type: 'symbol',
      source: layer,
      metadata,
      paint: {
        'icon-color': [
          'match',
          ['get', 'type'],
          'dropped-pin',
          '#4bfa91',
          'tide-station',
          '#266661',
          '#4bfa91',
        ],
        'icon-halo-blur': 4,
        'icon-halo-color': '#042222',
        'icon-halo-width': 3,
      },
      layout: {
        visibility,
        'icon-image': MapLayerIcon.WEATHER.PIN,
        'icon-allow-overlap': true,
        'icon-rotation-alignment': 'map',
        'icon-size': [
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#interpolate
          'interpolate',
          ['linear'],
          ['zoom'],
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#camera-expressions
          5,
          0.7 * GLOBAL_ICON_SCALE_FACTOR,
          8,
          1 * GLOBAL_ICON_SCALE_FACTOR,
          16,
          1.4 * GLOBAL_ICON_SCALE_FACTOR,
        ],
        'icon-anchor': 'bottom',
        'text-field': [
          'case',
          ['==', ['get', 'type'], 'tide-station'],
          ['coalesce', ['get', 'title'], ''],
          '',
        ],
        'text-size': 12,
        'text-anchor': 'top',
        'text-offset': [0, 1],
        'text-allow-overlap': true,
        'text-ignore-placement': true,
      },
    },
    [MapGroupLayer.WEATHER]
  );
}

export const addVisibilityLabelLayer = (labelLayerId: MapLayer | string) => {
  addSimpleGeoJsonSource(labelLayerId);

  MapLayerManager.AddLayerinGroups(
    {
      id: labelLayerId,
      type: 'symbol',
      source: labelLayerId,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'text-field': ['get', 'label'],
        'text-anchor': 'bottom',
        'text-justify': 'auto',
        'text-size': 13,
        'text-max-width': 30,
      },
      paint: {
        'text-color': '#ffffff',
        'text-halo-width': 1,
        'text-halo-color': '#000000',
        'text-halo-blur': 0,
      },
    },
    [MapGroupLayer.WEATHER]
  );
};

export const addRangeRingLayer = (labelLayerId: MapLayer | string) => {
  addSimpleGeoJsonSource(labelLayerId);

  MapLayerManager.AddLayerinGroups(
    {
      id: labelLayerId,
      type: 'line',
      source: labelLayerId,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'line-color': '#000',
        'line-width': 3,
      },
    },
    [MapGroupLayer.WEATHER]
  );
};

const addWeatherLayers = (map: mapboxgl.Map) => {
  if (!map.getLayer(MapLayer.WEATHER_VISIBILITY_RING)) {
    addRangeRingLayer(MapLayer.WEATHER_VISIBILITY_RING);
  }
  if (!map.getLayer(MapLayer.WEATHER_VISIBILITY_LABEL)) {
    addVisibilityLabelLayer(MapLayer.WEATHER_VISIBILITY_LABEL);
  }
  if (!map.getLayer(MapLayer.WEATHER_PIN)) {
    // add weather pin last so it goes over the visibility label, conveniently hiding it when the user is very zoomed out
    addWeatherPinLayer(MapLayer.WEATHER_PIN);
  }
};

export default addWeatherLayers;

import {
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useAppSelector } from '../../hooks';
import { Country, ExpandedId } from '../../models/risk_intelligence.model';
import { isExpandedId } from '../../utils/risk-intelligence-helpers';

interface CountryItemProps {
  country: Country;
  handleCountryClick: (country: Country) => void;
  selected: boolean;
}

export default function CountryItem(props: CountryItemProps) {
  const { country, handleCountryClick, selected } = props;
  const { incidentRegions, loading } = useAppSelector(
    (state) => state.incidents
  );
  const myRegion = incidentRegions?.find(
    (region) => isExpandedId(region) && region.id === country.region
  ) as ExpandedId | undefined;
  const regionName = myRegion?.title || country.region;
  return (
    <ListItem key={country.id} disablePadding>
      <ListItemButton
        onClick={() => handleCountryClick(country)}
        selected={selected}
        autoFocus={selected}
      >
        <ListItemText
          primary={country.title}
          secondary={
            loading ? <CircularProgress size={20} /> : `Region: ${regionName}`
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

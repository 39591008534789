import { IndustryNewsFeature } from '../../../models/industry-news.model';

function industryNewsInfoPopUpContent(
  feature: mapboxgl.MapboxGeoJSONFeature
): { label: string; value: string } | null {
  if (feature) {
    const industryNewsFeature = feature as unknown as IndustryNewsFeature;
    const industryNewsTitle = industryNewsFeature.properties.title;
    return {
      label: 'Industry News Title',
      value: industryNewsTitle,
    };
  }
  return null;
}

export default industryNewsInfoPopUpContent;

import { GeoJSONSource } from 'mapbox-gl';
import { FirstCallFeature } from '../../../models/first-call';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

function setFirstCallFeatures(firstCallFeatures: FirstCallFeature[]) {
  // first call features are already in the correct format
  const geojson = GeoHelper.createGeoJSON(firstCallFeatures);
  (MapHelpers.getSource(MapLayer.FIRST_CALL_PORTS) as GeoJSONSource)?.setData(
    geojson
  );
}

export default setFirstCallFeatures;

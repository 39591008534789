import { Text, View } from '@react-pdf/renderer';
import DateTimeHelpers from '../utils/date-time-helpers.utils';
import styles from './pdf-styles';

function DateStamp({ date }: { date: Date }) {
  return (
    <View style={styles.section}>
      <Text style={styles.titleSubTitle}>
        {DateTimeHelpers.dateToMonthDayYear(date)}
      </Text>
    </View>
  );
}

export default DateStamp;

import { Box, Paper, Typography } from '@mui/material';
import { useAppSelector } from '../../../../hooks';

function NearbyVesselsFormSummary() {
  const { nearbyVesselsFormValues } = useAppSelector(
    (state) => state.nearbyVessels
  );

  return (
    <Paper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0.5rem',
        }}
      >
        <Typography variant="body2">
          <b>Centre:</b> {nearbyVesselsFormValues.centrePoint.latitude}{' '}
          {nearbyVesselsFormValues.centrePoint.longitude}
        </Typography>
        <Typography variant="body2">
          <b>Radius:</b> {nearbyVesselsFormValues.radius} NM
        </Typography>
        <Typography variant="body2">
          <b>Time since last AIS ping:</b>{' '}
          {nearbyVesselsFormValues.timeSinceLastAIS} hours
        </Typography>
      </Box>
    </Paper>
  );
}

export default NearbyVesselsFormSummary;

import MapHelpers from '../../map.utils';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerIcon from '../map-layer-icon';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

// A property added when cluster: true on a GeoJSON Source.
const POINT_COUNT = 'point_count';

type IncidentLayerId = MapLayer.INCIDENTS | MapLayer.ROUTES_INCIDENTS;

const layerIdLookup: Record<
  IncidentLayerId,
  { clusterLayerId: string; clusterCountLayerId: string }
> = {
  [MapLayer.INCIDENTS]: {
    clusterLayerId: MapLayer.INCIDENT_CLUSTERS,
    clusterCountLayerId: MapLayer.INCIDENT_CLUSTER_COUNT,
  },
  [MapLayer.ROUTES_INCIDENTS]: {
    clusterLayerId: MapLayer.ROUTES_INCIDENT_CLUSTERS,
    clusterCountLayerId: MapLayer.ROUTES_INCIDENT_CLUSTER_COUNT,
  },
};

function setIncidentsLayer(layerId: IncidentLayerId, metadata?: {}) {
  const { clusterLayerId, clusterCountLayerId } = layerIdLookup[layerId];

  return new Promise((resolve) => {
    addSimpleGeoJsonSource(layerId, true);

    MapHelpers.addLayer({
      id: clusterLayerId,
      type: 'circle',
      source: layerId,
      filter: ['has', POINT_COUNT],
      metadata,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'circle-color': '#e14444',
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        'circle-radius': ['step', ['get', POINT_COUNT], 10, 5, 15, 10, 20],
      },
    });

    MapHelpers.addLayer({
      id: clusterCountLayerId,
      type: 'symbol',
      source: layerId,
      metadata,
      filter: ['has', POINT_COUNT],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'text-allow-overlap': true,
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
      },
    });

    MapHelpers.addLayer({
      id: layerId,
      type: 'symbol',
      source: layerId,
      metadata,
      filter: ['!', ['has', POINT_COUNT]],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'icon-image': [
          'case',
          [
            'all',
            ['==', ['get', 'selected'], true],
            ['==', ['get', 'alert'], true],
          ],
          [
            // Incident is selected and alerting
            'coalesce',
            ['image', ['get', 'selectedAlerting', ['get', 'icon']]], // try to use the image named in the properties
            ['image', MapLayerIcon.INCIDENT.SELECTED], // fallback image
          ],
          ['==', ['get', 'selected'], true],
          [
            // Incident is selected
            'coalesce',
            ['image', ['get', 'selected', ['get', 'icon']]], // try to use the image named in the properties
            ['image', MapLayerIcon.INCIDENT.SELECTED], // fallback image
          ],
          ['==', ['get', 'alert'], true],
          [
            // Incident is alerting
            'coalesce',
            ['image', ['get', 'alerting', ['get', 'icon']]], // try to use the image named in the properties
            ['image', MapLayerIcon.INCIDENT.DEFAULT], // fallback image
          ],
          [
            // Incident is neither selected or alerting
            'coalesce',
            ['image', ['get', 'base', ['get', 'icon']]], // try to use the image named in the properties
            ['image', MapLayerIcon.INCIDENT.DEFAULT], // fallback image
          ],
        ],
        // ensure selected incidents are always on top
        // coalesce makes it so selected:undefined or selected:null is treated as selected:false
        'symbol-sort-key': [
          'case',
          ['coalesce', ['get', 'selected'], false],
          1,
          0,
        ],
        'icon-allow-overlap': true,
        // RI Icons designed to be pins above the incident
        'icon-anchor': 'bottom',
        'icon-size': [
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#interpolate
          'interpolate',
          ['linear'],
          ['zoom'],
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#camera-expressions
          2,
          0.5,
          8,
          0.7,
          16,
          1,
          20,
          1.2,
        ],
      },
    });

    resolve(MapHelpers);
  });
}

export default setIncidentsLayer;

import { Pause, PlayArrow, SkipNext, SkipPrevious } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import * as dateFns from 'date-fns';
import { useEffect, useState } from 'react';
import { DataGroup, DataSet, Timeline } from 'vis-timeline/standalone';
import { useAppDispatch, useInterval } from '../../hooks';
import { setCentreDate } from './timeline.slice';
import {
  DateWithOffset,
  PLAYBACK_CONTROLS_WIDTH,
  VesselDataItem,
  findNearestItemInEachGroup,
} from './timeline.utils';

const PLAYBACK_SPEED = 1; // 1 once per second, 2 twice per second, etc

const sortEarliestToLatest = (a: VesselDataItem, b: VesselDataItem) =>
  a.start > b.start ? 1 : -1;
const sortLatestToEarliest = (a: VesselDataItem, b: VesselDataItem) =>
  a.start < b.start ? 1 : -1;

interface TImelinePlaybackControlsProps {
  isPlaying: boolean;
  onPlay: () => void;
  onPause: () => void;
  goToStart: () => void;
  goToEnd: () => void;
}

// View
export function TimelinePlayBackControls(props: TImelinePlaybackControlsProps) {
  const { isPlaying, onPlay, onPause, goToEnd, goToStart } = props;
  return (
    <Box
      sx={{
        width: PLAYBACK_CONTROLS_WIDTH,

        height: '3rem',
      }}
    >
      <IconButton aria-label="go-to-start" size="large" onClick={goToStart}>
        <SkipPrevious />
      </IconButton>
      {isPlaying ? (
        <IconButton aria-label="pause" size="large" onClick={onPause}>
          <Pause />
        </IconButton>
      ) : (
        <IconButton aria-label="play" size="large" onClick={onPlay}>
          <PlayArrow />
        </IconButton>
      )}
      <IconButton aria-label="go-to-end" size="large" onClick={goToEnd}>
        <SkipNext />
      </IconButton>
    </Box>
  );
}

interface TimelinePlaybackControllerProps {
  timeline: Timeline | null;
  items?: DataSet<VesselDataItem> | null;
  groups?: DataSet<DataGroup> | null;
  centreDate: DateWithOffset;
  focused: boolean;
}

// Controller
export default function TimelinePlaybackController(
  props: TimelinePlaybackControllerProps
) {
  const { timeline, items, groups, centreDate, focused } = props;
  const [isPlaying, setIsPlaying] = useState(false);

  const dispatch = useAppDispatch();
  const goToStart = () => {
    const earliest = items?.get({
      order: sortEarliestToLatest,
    })[0];
    if (earliest) {
      dispatch(
        setCentreDate({
          alreadyOffset: false,
          date: new Date(earliest.start).getTime(),
        })
      );
    }
  };
  const goToEnd = () => {
    const latest = items?.get({
      order: sortLatestToEarliest,
    })[0];
    if (latest) {
      dispatch(
        setCentreDate({
          alreadyOffset: false,
          date: new Date(latest.start).getTime(),
        })
      );
    }
  };

  const goToNextResult = () => {
    if (items && groups && timeline) {
      const currentlySelected = timeline.getSelection();
      const nearestItems = findNearestItemInEachGroup(
        items,
        groups,
        new Date(centreDate.date),
        {
          after: true,
          excludeIds: currentlySelected,
        }
      );
      if (nearestItems.nearestOverall) {
        dispatch(
          setCentreDate({
            alreadyOffset: false,
            date: new Date(nearestItems.nearestOverall.start).getTime(),
          })
        );
      } else {
        // no more items in the future, so stop playing.
        setIsPlaying(false);
      }
    }
  };

  const goToPreviousResult = () => {
    if (items && groups && timeline) {
      const currentlySelected = timeline.getSelection();
      const nearestItems = findNearestItemInEachGroup(
        items,
        groups,
        new Date(centreDate.date),
        {
          before: true,
          excludeIds: currentlySelected,
        }
      );
      if (nearestItems.nearestOverall) {
        dispatch(
          setCentreDate({
            alreadyOffset: false,
            date: new Date(nearestItems.nearestOverall.start).getTime(),
          })
        );
      }
    }
  };

  const goToNextDay = () => {
    if (items && groups && timeline) {
      const currentlySelected = timeline.getSelection();
      const nextDay = dateFns.add(new Date(centreDate.date), { days: 1 });
      const nearestItems = findNearestItemInEachGroup(items, groups, nextDay, {
        // exclude what is currently selected so that it finds the next nearest (in the future).
        after: true,
        excludeIds: currentlySelected,
      });
      if (nearestItems.nearestOverall) {
        dispatch(
          setCentreDate({
            alreadyOffset: false,
            date: new Date(nearestItems.nearestOverall.start).getTime(),
          })
        );
      } else {
        // no more items in the future, so stop playing.
        setIsPlaying(false);
        // if user tried to go to next day, but there are no more items in the future, then go to the last item.
        goToEnd();
      }
    }
  };

  const goToPreviousDay = () => {
    if (items && groups && timeline) {
      const currentlySelected = timeline.getSelection();
      const previousDay = dateFns.add(new Date(centreDate.date), { days: -1 });
      const nearestItems = findNearestItemInEachGroup(
        items,
        groups,
        previousDay,
        {
          before: true,
          excludeIds: currentlySelected,
        }
      );
      if (nearestItems.nearestOverall) {
        dispatch(
          setCentreDate({
            alreadyOffset: false,
            date: new Date(nearestItems.nearestOverall.start).getTime(),
          })
        );
      } else {
        // if user tried to go to previous day, but there are no more items in the past, then go to the first item.
        goToStart();
      }
    }
  };

  const keyboardListener = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowRight':
        setIsPlaying(false);
        goToNextResult();
        break;
      case 'ArrowLeft':
        setIsPlaying(false);
        goToPreviousResult();
        break;
      case 'ArrowDown':
      case 'PageDown':
        setIsPlaying(false);
        goToNextDay();
        break;
      case 'ArrowUp':
      case 'PageUp':
        setIsPlaying(false);
        goToPreviousDay();
        break;
      case 'Home':
        setIsPlaying(false);
        goToStart();
        break;
      case 'End':
        setIsPlaying(false);
        goToEnd();
        break;
      case ' ':
        // space is how a keyboard-only user 'clicks' buttons, so need to check if we're currently focusing a button
        // and if so, don't toggle play/pause
        if (e.target) {
          const target = e.target as HTMLElement;
          if (
            target.tagName === 'BUTTON' ||
            target.getAttribute('role') === 'button'
          ) {
            return;
          }
        }
        setIsPlaying((prev) => !prev);
        break;
      default:
        break;
    }
  };

  // Keyboard listeners
  useEffect(() => {
    if (!focused) {
      window.removeEventListener('keydown', keyboardListener);
      return () => {};
    }
    window.addEventListener('keydown', keyboardListener);
    return () => {
      window.removeEventListener('keydown', keyboardListener);
    };
  });

  // step through the timeline
  useInterval(
    () => {
      goToNextResult();
    },
    1000 / PLAYBACK_SPEED,
    isPlaying
  );

  const onPlay = () => {
    setIsPlaying(true);
  };
  const onPause = () => {
    setIsPlaying(false);
  };

  return (
    <TimelinePlayBackControls
      isPlaying={isPlaying}
      onPlay={onPlay}
      onPause={onPause}
      goToStart={goToStart}
      goToEnd={goToEnd}
    />
  );
}

TimelinePlaybackController.defaultProps = {
  items: null,
  groups: null,
};

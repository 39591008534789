/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Modal } from '@mui/material';
import {
  CategoryScale,
  Chart,
  ChartData,
  ChartDataset,
  Decimation,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  registerables,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import { get } from 'lodash';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import CommonCloseButton from '../common-components/common-close-button/common-close-button';
import LoadingPanel from '../common-components/loading-panel/loading-panel';
import { withinSevenDays } from '../utils/date-time-helpers.utils';
import zIndexes from '../z-indexes.scss';

const canvasCache: HTMLCanvasElement[] = [];

// webpack tree shaking doesn't work with chartjs, so we need to manually register the chart types we use

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  Decimation,
  ...registerables
);
Chart.defaults.font.size = 20;
Chart.defaults.font.family = 'Helvetica';

const initCanvas = (name: string, heightOverride?: number) => {
  // drop existing chart canvas if it exists
  document.getElementById(`canvas-${name}`)?.remove();
  const canvas = document.createElement('canvas');
  canvas.id = `canvas-${name}`;
  canvas.width = 800;
  canvas.height = heightOverride || 400;
  canvas.style.display = 'none';
  // chartjs requires the canvas be mounted in the DOM
  document.body.appendChild(canvas);
  canvasCache.push(canvas);
  return canvas;
};

export const chartsCleanup = () => {
  while (canvasCache.length > 0) {
    canvasCache.pop()?.remove();
  }
};

/**
 * Given a chart data object, return a base64 encoded image of a bar chart
 * The resolve is in the onComplete callback of the chart animation, because otherwise the
 * chart has not been finished rendering when the promise resolves, resulting in a blank image.
 * @param chartData
 * @returns
 */
export const getBarChartImage = (
  chartData: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string | string[];
    }[];
  },
  title?: string
) =>
  new Promise<string>((resolve, reject) => {
    try {
      // Chart.js combined with @react-pdf/renderer doesn't play well with devicePixelRatio and seems to try to take it into account 3 times
      // overriding the device pixel ratio within the chart options seems to have no effect
      const undoPixelRatio =
        1 /
        (window.devicePixelRatio *
          window.devicePixelRatio *
          window.devicePixelRatio);
      const canvas = initCanvas('bar', 500); // make it taller to fit the legend
      const chart = new Chart(canvas, {
        type: 'bar',
        data: chartData,
        options: {
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: !!title,
              text: title,
              padding: 10 * undoPixelRatio,
              font: {
                family: 'Helvetica',
                size: 44 * undoPixelRatio,
              },
              color: '#203058',
            },
          },
          scales: {
            x: {
              stacked: false,
              ticks: {
                font: { family: 'Helvetica', size: 30 * undoPixelRatio },
                color: '#3C3D3D',
              },
            },
            y: {
              stacked: true,
              ticks: {
                font: { family: 'Helvetica', size: 30 * undoPixelRatio },
                color: '#3C3D3D',
              },
            },
          },
          animation: {
            duration: 100, // duration can be very short but non-zero to prevent the chart from being blank
            onComplete() {
              resolve(canvas.toDataURL('image/png'));
            },
          },
        },
      });
    } catch (e) {
      reject(e);
    }
  });

/**
 * Given a chart data object, return a base64 encoded image of a donut chart
 * The resolve is in the onComplete callback of the chart animation, because otherwise the
 * chart has not been finished rendering when the promise resolves, resulting in a blank image.
 *
 * This uses the same chart data as the bar chart, but with a different chart type.
 * It changes the data to drop the date labels and group all the data by incident type group instead
 * @param chartData
 * @returns
 */
export const getDonutChartImage = (
  chartData: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string | string[];
    }[];
  },
  title?: string
) =>
  new Promise<string>((resolve, reject) => {
    try {
      // Chart.js combined with @react-pdf/renderer doesn't play well with devicePixelRatio and seems to try to take it into account 3 times
      const undoPixelRatio =
        1 /
        (window.devicePixelRatio *
          window.devicePixelRatio *
          window.devicePixelRatio);
      const canvas = initCanvas('donut', 600);
      const chart = new Chart(canvas, {
        type: 'doughnut',
        data: chartData,
        options: {
          aspectRatio: 1.5,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',

              labels: {
                font: { family: 'Helvetica', size: 30 * undoPixelRatio },
                color: '#3C3D3D',
                padding: 20 * undoPixelRatio,
              },
            },
            title: {
              display: !!title,
              text: title,
              padding: 10 * undoPixelRatio,
              font: {
                family: 'Helvetica',
                size: 44 * undoPixelRatio,
              },
              color: '#203058',
            },
          },
          animation: {
            duration: 100,
            onComplete() {
              resolve(canvas.toDataURL('image/png'));
            },
          },
        },
      });
    } catch (e) {
      reject(e);
    }
  });

const lineOption = {
  responsive: true,
  pointRadius: 1,
  pointHitRadius: 10,
  plugins: {
    decimation: {
      enabled: true,
      algorithm: 'lttb' as const,
      samples: 120,
      threshold: 1,
    },
    legend: {
      display: false,
    },
    title: {
      display: true,
      color: 'white',
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        minUnit: 'minute',
        displayFormats: {
          minute: 'MMM DD hh:mm',
          hour: 'MMM DD hh:mm',
          day: 'MMM DD',
          week: 'MMM DD',
          // month: 'MMM DD',
          // quarter: 'MMM DD',
          // year: 'MMM DD',
        },
      },
      title: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
    },
    y: {
      title: {
        display: false,
      },
      ticks: {
        color: 'white',
      },
    },
  },
};

type AxisKey = {
  key: string;
  label?: string;
};

interface LineGraphProps {
  title?: string;
  data: ChartData<'line'>;
  onDateChange?: (date: number) => void;
  hidePoints?: boolean;
  yLabel?: string;
  yAxisMin?: number;
  yAxisMax?: number;
  xAxisMin?: number;
  xAxisMax?: number;
  yScale?: {
    min: number;
    max: number;
  };
}

interface MegaLineGraphProps<T> extends LineGraphProps {
  selectedDate: number | undefined;
  yAxisKeys: AxisKey[];
  xAxisKey: string;
  labelKeys?: string[];
  yScale?: {
    min: number;
    max: number;
  };
  requestDetailedDataFunction?: (min: Date, max: Date) => Promise<T[][]>;
}

function MiniLineGraph({
  title,
  data,
  onDateChange,
  hidePoints,
  yLabel,
  yAxisMin,
  yAxisMax,
  xAxisMin,
  xAxisMax,
  yScale,
}: LineGraphProps) {
  const options = {
    ...lineOption,
    ...(hidePoints ? { pointRadius: 0 } : {}),
    onClick: (e: any, element: any) => {
      if (element.length > 0 && onDateChange) {
        onDateChange(element[0].element.$context.parsed.x);
      }
    },
    parsing: false as const,
    plugins: {
      decimation: {
        ...lineOption.plugins.decimation,
        samples: 60,
      },
      legend: { ...lineOption.plugins.legend },
      title: {
        display: true,
        text: title,
        color: 'white',
      },
    },
    scales: {
      ...lineOption.scales,
      x: {
        ...lineOption.scales.x,
        min: xAxisMin,
        max: xAxisMax,
      },
      y: {
        ...lineOption.scales.y,
        min: yAxisMin,
        max: yAxisMax,
        title: {
          display: !!yLabel,
          text: yLabel ?? '',
          color: 'white',
        },
        ...(yScale ?? {}),
      },
    },
  };

  // small line graph on the secondary pane
  // @ts-ignore - react-chartjs types are slightly wrong
  return <Line options={options} data={data} />;
}

MiniLineGraph.defaultProps = {
  title: '',
  onDateChange: undefined,
  hidePoints: false,
  yLabel: undefined,
  xAxisMin: undefined,
  xAxisMax: undefined,
  yAxisMin: undefined,
  yAxisMax: undefined,
  yScale: undefined,
};

function setSegmentColouring(d: any, selectedDate: number | undefined) {
  d.segment = {
    // maybe not the best way but the only way I can set the greying out for
    // past vessel selections is to let the graph line colours to be defined
    // and then set the greying section
    borderColor: (ctx: any) => {
      if (selectedDate && ctx.p0.parsed.x >= selectedDate) {
        return 'rgb(128, 128, 128)';
      }
      return undefined;
    },
  };
  return d.segment;
}

function updateMultiGraphData(chart: any, datasets: any) {
  // eslint-disable-next-line no-param-reassign
  chart.data.datasets = datasets;
  chart.update();
}

function MegaLineGraph<T>({
  title,
  data,
  yAxisKeys,
  xAxisKey,
  labelKeys,
  yLabel,
  yScale,
  requestDetailedDataFunction,
  selectedDate,
  onDateChange,
  hidePoints,
  yAxisMin,
  yAxisMax,
  xAxisMin,
  xAxisMax,
}: MegaLineGraphProps<T>) {
  // lightbox line graph shown when "expand" is clicked
  function fetchData({ chart }: any) {
    const { min, max } = chart.scales.x;
    if (withinSevenDays(min, max) && requestDetailedDataFunction) {
      requestDetailedDataFunction(new Date(min), new Date(max)).then(
        (response) => {
          const dataSets: ChartDataset<'line'>[] = [];
          yAxisKeys.forEach((yAxisKey, index) => {
            const axisLabel = yAxisKey?.label || yAxisKey.key;

            const refinedResult = response.map((d) => ({
              segment: setSegmentColouring(d, selectedDate),
              label: labelKeys?.[index] ?? axisLabel,
              data: d.map((point) => ({
                y: Number(get(point, yAxisKey.key)),
                x: Number(get(point, xAxisKey)),
              })),
            }));
            dataSets.push(...refinedResult);
          });
          updateMultiGraphData(chart, dataSets);
        }
      );
    } else {
      updateMultiGraphData(chart, data.datasets);
    }
  }

  const config = {
    type: 'line' as const,
    options: {
      onClick: (e: any, element: any) => {
        if (element.length > 0 && onDateChange) {
          onDateChange(element[0].element.$context.parsed.x);
        }
      },
      ...(hidePoints ? { pointRadius: 0 } : {}),
      pointHitRadius: 40,
      parsing: false as const,
      maintainAspectRatio: false,
      scales: {
        x: {
          min: xAxisMin,
          max: xAxisMax,
          position: 'bottom' as const,
          type: lineOption.scales.x.type,
          time: { ...lineOption.scales.x.time },
          ticks: {
            ...lineOption.scales.x.ticks,
            autoSkip: true,
            autoSkipPadding: 50,
            maxRotation: 0,
          },
          title: {
            display: true,
            text: 'Time',
            color: 'white',
          },
        },
        y: {
          min: yAxisMin,
          max: yAxisMax,
          type: 'linear' as const,
          position: 'left' as const,
          title: {
            display: true,
            text: yLabel,
            color: 'white',
          },
          ticks: { ...lineOption.scales.y.ticks },
          ...(yScale ?? {}),
        },
      },
      plugins: {
        decimation: {
          ...lineOption.plugins.decimation,
        },
        legend: { display: true },
        zoom: {
          limits: {
            x: {
              min: 'original' as const,
              max: 'original' as const,
              minRange: 1000 * 60 * 60,
            },
          },
          pan: {
            enabled: true,
            mode: 'x' as const,
            modifierKey: 'ctrl' as const,
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x' as const,
          },
        },
        title: {
          display: true,
          text: `Expanded Graph View - ${title}`,
          color: 'white',
        },
      },
      transitions: {
        zoom: {
          animation: {
            duration: 100,
          },
        },
      },
    },
  };
  // @ts-ignore - react-chartjs types are slightly wrong
  return <Line options={config.options} data={data} />;
}

MegaLineGraph.defaultProps = {
  title: '',
  onDateChange: undefined,
  hidePoints: false,
  labelKeys: undefined,
  requestDetailedDataFunction: undefined,
  yLabel: undefined,
  xAxisMin: undefined,
  xAxisMax: undefined,
  yAxisMin: undefined,
  yAxisMax: undefined,
  yScale: undefined,
};

interface TimeSeriesLineGraphProps<T> {
  // eslint-disable-next-line react/no-unused-prop-types
  title?: string;
  data?: T[][];
  yAxisKeys: AxisKey[];
  xAxisKey: string;
  labelKeys?: string[];
  yLabel: string;
  yScale?: {
    min: number;
    max: number;
  };
  requestDetailedDataFunction?: (min: Date, max: Date) => Promise<T[][]>;
  selectedDate?: number;
  onDateChange?: (time: number) => void;
  hidePoints?: boolean;
  colours?: string[];
  yAxisMin?: number;
  yAxisMax?: number;
  xAxisMin?: number;
  xAxisMax?: number;
}

/**
 * A wrapper around the line graph that allows for a mini graph to be shown
 * @argument title - the title of the graph
 * @argument data - the data to be shown on the graph. Array of Array of Objects. each top level array is a dataset, each inner array is a point on the graph.
 * @argument yAxisKey - the key to use for the y axis. supports lodash.get syntax
 * @argument xAxisKey - the key to use for the x axis. supports lodash.get syntax
 * @argument labelKey - Optional. the key to use for the label for each dataset. pulls from the first value of the dataset, ie, data[*][0]. supports lodash.get syntax
 * @argument requestDetailedDataFunction - Optional. a function that is called when user zooms in more than 7 days. should return a promise that resolves to the same format as data.
 * @argument selectedDate - Optional. the date that the user has selected. used to grey out the future data. If not provided, no data is greyed out.
 * @argument onDateChange - Optional. a function that is called when the user clicks on a point on the graph. passes the x value of the point.
 */
export function TimeSeriesLineGraph<T extends Record<any, any>>({
  title,
  data,
  yAxisKeys,
  xAxisKey,
  labelKeys,
  yLabel,
  yScale,
  requestDetailedDataFunction,
  selectedDate,
  onDateChange,
  hidePoints,
  colours,
  yAxisMin,
  yAxisMax,
  xAxisMin,
  xAxisMax,
}: TimeSeriesLineGraphProps<T>) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (data) {
    const dataSets: ChartDataset<'line'>[] = [];
    yAxisKeys.forEach((yAxisKey, index) => {
      const datasetsForKey = data.map((d, dataSetIndex) => {
        const axisLabel = yAxisKey.label || yAxisKey.key;

        let label: string;
        if (yAxisKeys.length === 1 && data.length > 1) {
          // if there's only one yAxisKey, but multiple datasets
          // we're likely graphing one value for multiple items.
          // eg, speed for multiple vessels
          // the label should come from the item
          label = labelKeys?.[dataSetIndex] ?? axisLabel;
        } else {
          label = labelKeys?.[index] ?? axisLabel;
        }
        return {
          data: d.map((point) => ({
            y: Number(get(point, yAxisKey.key)), // lodash.get means we can use keys like 'data.source'
            x: new Date(get(point, xAxisKey)).getTime(),
          })),
          label,
          spanGaps: true,
          pointHitRadius: 20,
        };
      });
      dataSets.push(...datasetsForKey);
    });
    const graphData: ChartData<'line'> = {
      datasets: dataSets,
    };

    // has to be set after the graphdata has been created to allow
    // for a default colouring per dataset
    graphData.datasets.forEach((d, index) => {
      d.segment = setSegmentColouring(d, selectedDate);
      // if colours are not provided, chart.js will use its default colours
      if (colours && colours[index]) {
        d.borderColor = colours[index];
      }
    });

    const lightboxStyle = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      height: '80%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      borderRadius: '1rem',
      boxShadow: 24,
      p: 4,
    };

    return (
      <>
        <MiniLineGraph
          title={title}
          data={graphData}
          onDateChange={onDateChange}
          hidePoints={hidePoints}
          yLabel={yLabel}
          xAxisMax={xAxisMax}
          xAxisMin={xAxisMin}
          yAxisMax={yAxisMax}
          yAxisMin={yAxisMin}
          yScale={yScale}
        />
        <Button onClick={handleOpen}>View Detailed {title} Profile</Button>
        <Modal
          sx={{
            zIndex: zIndexes['$--feature-panel-contents-layer'],
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={lightboxStyle}>
            <CommonCloseButton onClick={handleClose} />
            <MegaLineGraph
              title={title}
              data={graphData}
              yLabel={yLabel}
              yAxisKeys={yAxisKeys}
              xAxisKey={xAxisKey}
              yScale={yScale}
              labelKeys={labelKeys}
              requestDetailedDataFunction={requestDetailedDataFunction}
              onDateChange={onDateChange}
              selectedDate={selectedDate}
              hidePoints={hidePoints}
              xAxisMax={xAxisMax}
              xAxisMin={xAxisMin}
              yAxisMax={yAxisMax}
              yAxisMin={yAxisMin}
            />
          </Box>
        </Modal>
      </>
    );
  }
  return <LoadingPanel />;
}

TimeSeriesLineGraph.defaultProps = {
  title: '',
  data: undefined,
  onDateChange: undefined,
  hidePoints: false,
  labelKeys: undefined,
  requestDetailedDataFunction: undefined,
  selectedDate: undefined,
  colours: undefined,
  xAxisMin: undefined,
  xAxisMax: undefined,
  yAxisMin: undefined,
  yAxisMax: undefined,
  yScale: undefined,
};

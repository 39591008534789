import {
  GeoJSONSource,
  MapLayerMouseEvent,
  MapLayerTouchEvent,
} from 'mapbox-gl';
import { setMeasurements } from '../../../maritime-menu-options/tools-panel/tools-panel.slice';
import store from '../../../store';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { decimalToDMS } from '../../map-controls.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';
import setMeasurementLayerFeatures from './set-measurement-layer-features';

namespace MeasurementPointController {
  let onMouseMoveFunc: any = () => {};

  export const onMouseEnter = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    e.target.getCanvas().style.cursor = 'move';
  };

  export const onMouseLeave = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    e.target.getCanvas().style.cursor = '';
  };

  export const onMouseMove = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    feature?: GeoJSON.Feature
  ) => {
    const { measurements } = store.getState().toolsPanel.measuringTool;
    const selectedMeasurement = measurements.find(
      (measurement) => measurement.id === feature?.properties?.id
    );

    if (selectedMeasurement) {
      const updatedMeasurement = { ...selectedMeasurement };
      const updatedPoints = [...updatedMeasurement.points];
      updatedPoints[feature?.properties?.index] = {
        longitude: decimalToDMS(e.lngLat.lng, 'longitude'),
        latitude: decimalToDMS(e.lngLat.lat, 'latitude'),
      };

      updatedMeasurement.points = updatedPoints;

      setMeasurementLayerFeatures({
        measurements: [updatedMeasurement],
        lineLayerId: MapLayer.MEASUREMENT_LINES_TEMP,
        pointLayerId: MapLayer.MEASUREMENT_POINTS_TEMP,
      });
    }
  };

  export const onMouseUp = (
    e: MapLayerMouseEvent | MapLayerTouchEvent,
    feature?: GeoJSON.Feature
  ) => {
    const { lngLat } = e;
    const { measurements } = store.getState().toolsPanel.measuringTool;

    const selectedMeasurementIndex = measurements.findIndex(
      (measurement) => measurement.id === feature?.properties?.id
    );

    if (selectedMeasurementIndex > -1) {
      const selectedMeasurement = measurements[selectedMeasurementIndex];

      const updatedMeasurements = [...measurements];
      const updatedMeasurement = { ...selectedMeasurement };
      const updatedPoints = [...updatedMeasurement.points];

      updatedPoints[feature?.properties?.index] = {
        longitude: decimalToDMS(lngLat.lng, 'longitude'),
        latitude: decimalToDMS(lngLat.lat, 'latitude'),
      };

      updatedMeasurement.points = updatedPoints;
      updatedMeasurements[selectedMeasurementIndex] = updatedMeasurement;

      store.dispatch(setMeasurements(updatedMeasurements));
      setMeasurementLayerFeatures({
        measurements: updatedMeasurements,
        lineLayerId: MapLayer.MEASUREMENT_LINES,
        pointLayerId: MapLayer.MEASUREMENT_POINTS,
      });
    }

    (
      MapHelpers.getSource(MapLayer.MEASUREMENT_LINES_TEMP) as GeoJSONSource
    )?.setData(GeoHelper.createGeoJSON());
    (
      MapHelpers.getSource(MapLayer.MEASUREMENT_POINTS_TEMP) as GeoJSONSource
    )?.setData(GeoHelper.createGeoJSON());

    MapHelpers.removeMapEventListener('mousemove', onMouseMoveFunc);
    MapHelpers.removeMapEventListener('touchmove', onMouseMoveFunc);
  };

  export const onMouseDown = (e: MapLayerMouseEvent | MapLayerTouchEvent) => {
    // prevent default map drag behaviour
    e.preventDefault();

    const features = e.target.queryRenderedFeatures(e.point, {
      layers: [MapLayer.MEASUREMENT_POINTS],
    });

    if (features.length) {
      const feature = features[0];
      MapHelpers.getCanvas().style.cursor = 'grab';
      onMouseMoveFunc = (ev: MapLayerMouseEvent | MapLayerTouchEvent) =>
        onMouseMove(ev, feature);

      MapHelpers.addMapEventListener('mousemove', null, onMouseMoveFunc);
      MapHelpers.once(
        'mouseup',
        (ev: MapLayerMouseEvent | MapLayerTouchEvent) => onMouseUp(ev, feature)
      );
    }
  };

  export const layerEvents = {
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
  };
}

export default MeasurementPointController;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Box } from '@mui/material';
import {
  translateThreatLevelToColour,
  translateThreatLevelToText,
} from '../threat-assessment-helpers';
import './threat-assessment-chip.scss';

interface ThreatAssessmentChipProps {
  threatLevel: number;
}

function ThreatAssessmentChip({ threatLevel }: ThreatAssessmentChipProps) {
  return (
    <Box className="threat-assessment-chip">
      <div
        data-testid="threat-assessment-chip"
        className="threat-chip"
        style={{ backgroundColor: translateThreatLevelToColour(threatLevel) }}
      />
      {translateThreatLevelToText(threatLevel)}
    </Box>
  );
}

export default ThreatAssessmentChip;

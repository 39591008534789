import DownloadHelpers from '../../utils/download.utils';

export type UploadEntityDocumentsFormValues = {
  fileAttachments: File[];
  description: string;
  name: string;
};

export const uploadEntityDocumentsAcceptedFileTypes = [
  DownloadHelpers.FileMimeType.PNG,
  DownloadHelpers.FileMimeType.JPEG,
  DownloadHelpers.FileMimeType.PDF,
  DownloadHelpers.FileMimeType.JPG,
  DownloadHelpers.FileMimeType.CSV,
];

export const validateUploadEntityDocumentsForm = (
  values: UploadEntityDocumentsFormValues
) => {
  const invalidFileAttachments = values.fileAttachments.filter(
    (file) =>
      !uploadEntityDocumentsAcceptedFileTypes.includes(
        file.type as DownloadHelpers.FileMimeType
      )
  );

  return {
    ...(invalidFileAttachments.length && {
      fileAttachments: `Invalid Files attached: \n${invalidFileAttachments
        .map((file) => file.name)
        .join('\n')}`,
    }),
    ...(values.name.trim().length < 1 && {
      name: 'Required',
    }),
  };
};

export const DESIGN_LABELS = ['Is Coated'];

export const ENGINE_LABELS = [
  'Engine Designation',
  'Main Engine Designer',
  'Main Engine Count',
  'MCO Value',
  'MCO Unit',
  'MCO RPM',
  'Propulsion Type',
  'Propeller Count',
];

export const DIMENSION_LABELS = [
  'GPS to Bow',
  'GPS to Stern',
  'GPS to Port',
  'GPS to Starboard',
  'Length',
  'Width',
];

export const CAPACITY_LABELS = [
  'Dead Weight',
  'Gross Tonnage',
  'Displacement',
  'Grain Cubic Capacity',
  'Liquid Cubic 98%',
  'Net Tonnage',
  'TEU',
  'TPCMI',
];

export const REGISTRATION_LABELS = [
  'Ice Class',
  'Is Ice Classed',
  'Class 1 Code',
];

export const BUILD_HISTORY_LABELS = [
  'Build Year',
  'Commercial Owner',
  'Dead Year',
  'Hull Number',
  'Ship Builder',
  'Name Date',
];

import { DeleteOutline } from '@mui/icons-material';
import {
  Button,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import LongLatInputFields from '../../../common-components/form-fields/long-lat-input-fields';
import LayerToggle from '../../../common-components/layer-toggle/layer-toggle';
import { decimalToDMS } from '../../../map/map-controls.utils';
import { rangeRingFormCentreValidationSchema } from './range-ring-form-validators';
import { generateRangeRing } from './range-ring-helpers';
import RangeRingPropertiesForm from './range-ring-properties-form';
import { RangeRingPoint, RangeRingProperties } from './range-ring.model';

interface RangeRingFormProps {
  title: string;
  point: RangeRingPoint;
  onUpdate: (point: RangeRingPoint) => void;
  onRemove: (point: RangeRingPoint) => void;
}

function RangeRingForm(props: RangeRingFormProps) {
  const { title, point, onUpdate, onRemove } = props;

  const LONGITUDE_INPUT_NAME = 'centre.longitude';
  const LATITUDE_INPUT_NAME = 'centre.latitude';

  const handleUpdate = (values: Pick<RangeRingPoint, 'centre'>) => {
    onUpdate({
      ...point,
      ...values,
    });
  };

  const addRangeRing = () => {
    const newRangeRing = generateRangeRing(point.rings.length);
    const updatedRings = [...point.rings, newRangeRing];

    onUpdate({ ...point, rings: updatedRings });
  };

  const clearRangeRings = () => {
    onUpdate({ ...point, rings: [] });
  };

  const removeRangeRing = (rangeRing: RangeRingProperties) => {
    const updatedRangeRings = point.rings.filter(
      (ring) => ring.id !== rangeRing.id
    );
    const updatedPoint = { ...point, rings: updatedRangeRings };

    onUpdate(updatedPoint);
  };

  const onRangeRingUpdate = (rangeRing: RangeRingProperties) => {
    const selectedRingIndex = point.rings.findIndex(
      (ring) => ring.id === rangeRing.id
    );

    if (selectedRingIndex !== -1) {
      const updatedPoint = { ...point, rings: [...point.rings] };
      updatedPoint.rings[selectedRingIndex] = rangeRing;
      onUpdate(updatedPoint);
    }
  };

  return (
    <Formik<Pick<RangeRingPoint, 'centre'>>
      initialValues={{
        centre: {
          latitude: point.centre.latitude,
          longitude: point.centre.longitude,
        },
      }}
      onSubmit={() => {}}
      validationSchema={rangeRingFormCentreValidationSchema}
    >
      {(formik) => (
        <Paper
          data-testid="range-ring-form"
          sx={{
            padding: '1rem',
          }}
        >
          <Form onKeyUp={() => handleUpdate(formik.values)}>
            <Typography variant="h6">
              {title}
              <LayerToggle
                layerGroups={point.rings.map((ring) => ring.id)}
                layers={[point.id]}
              />
              <IconButton
                sx={{
                  float: 'right',
                }}
                onClick={() => {
                  onRemove(point);
                }}
                data-testid="delete-point"
              >
                <DeleteOutline />
              </IconButton>
            </Typography>

            <Typography variant="body1" align="left">
              Centre Point
            </Typography>

            <LongLatInputFields
              latitudeInputName={LATITUDE_INPUT_NAME}
              longitudeInputName={LONGITUDE_INPUT_NAME}
              onLngLatSelect={(lngLat) => {
                const values = {
                  centre: {
                    longitude: decimalToDMS(lngLat[0].lng, 'longitude'),
                    latitude: decimalToDMS(lngLat[0].lat, 'latitude'),
                  },
                };

                formik.setValues(values);
                // set field value doesn't seem to trigger any change events - manually trigger update here
                handleUpdate(values);
              }}
            />
          </Form>
          <List>
            {point.rings.map((ring, index) => (
              <ListItem key={ring.id}>
                <RangeRingPropertiesForm
                  title={`Ring ${index + 1}`}
                  ring={ring}
                  onUpdate={onRangeRingUpdate}
                  onRemove={(rangeRing: RangeRingProperties) =>
                    removeRangeRing(rangeRing)
                  }
                />
              </ListItem>
            ))}
            <ListItem
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Button variant="contained" onClick={clearRangeRings}>
                Clear All Range Rings
              </Button>
              <Button variant="outlined" onClick={addRangeRing}>
                Add Range Ring
              </Button>
            </ListItem>
          </List>
        </Paper>
      )}
    </Formik>
  );
}

export default React.memo(RangeRingForm);

import { Box, Button, Grid, List, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import DateTimeHelpers from '../../../utils/date-time-helpers.utils';
import {
  setMergedPointsEnabled,
  setMergedPointsMinimumCount,
  setMergedPointsMinimumGapLength,
} from '../history-panel.slice';
import HistoryAISMergedPointResult from './history-ais-merged-point-result';
import AISMergedPointsController from './history-ais-merged-points-controller.utils';
import { hideAISMergedPointsPopup } from './history-ais-merged-points-popup';

function HistoryAISMergedPoints() {
  const {
    mergedHistoricVesselPoints,
    mergedPointsMinimumCount,
    mergedPointsMinimumGapLength,
    selectedMergedPointIndex,
  } = useAppSelector((state) => state.historyPanel);

  const dispatch = useAppDispatch();

  const filteredPoints = mergedHistoricVesselPoints
    .slice()
    .sort((a, b) => a.startTimestamp - b.startTimestamp)
    .filter((point) => {
      const pointCount = point.features.length;
      const gapLength = DateTimeHelpers.getHoursInMilliseconds(
        point.endTimestamp - point.startTimestamp
      );

      return (
        mergedPointsMinimumCount &&
        mergedPointsMinimumGapLength &&
        pointCount >= mergedPointsMinimumCount &&
        gapLength >= mergedPointsMinimumGapLength
      );
    });

  const handleClearSelection = () => {
    AISMergedPointsController.clearSelectedFeature();
    hideAISMergedPointsPopup();
  };

  const handleMergedPointCountChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleClearSelection();
    dispatch(setMergedPointsMinimumCount(Number(e.target.value)));
  };

  const handleMergedPointGapLengthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleClearSelection();
    dispatch(setMergedPointsMinimumGapLength(Number(e.target.value)));
  };

  useEffect(() => {
    dispatch(setMergedPointsEnabled(true));

    return () => {
      dispatch(setMergedPointsEnabled(false));
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: '20px 20px 0px',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <TextField
          label="Minimum Gap length (hrs)"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: 1,
              step: 1,
            },
          }}
          placeholder="Adjust Gap length"
          value={mergedPointsMinimumGapLength}
          fullWidth
          onChange={handleMergedPointGapLengthChange}
        />
        <TextField
          label="Minimum Number of Points"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: 1,
              step: 1,
            },
          }}
          placeholder="Adjust Minimum Number of Points"
          value={mergedPointsMinimumCount}
          fullWidth
          onChange={handleMergedPointCountChange}
          sx={{
            margin: '20px 0px',
          }}
        />
        <Button
          sx={{
            borderRadius: '100px',
            borderColor: 'primary.dark',
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '38px',
            '& a': {
              color: 'secondary.main',
            },
          }}
          fullWidth
          onClick={handleClearSelection}
          disabled={selectedMergedPointIndex === null}
        >
          Clear selection
        </Button>
        <Box
          sx={{
            margin: '20px 0px',
          }}
        >
          <Grid container rowSpacing={1}>
            <Grid item xs={10}>
              <Typography variant="subtitle2" align="left">
                Merged/stationary point(s) group(s) found
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" align="right">
                {filteredPoints.length}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        {filteredPoints.length ? (
          <List disablePadding>
            {filteredPoints.map((point) => (
              <HistoryAISMergedPointResult
                point={point}
                selected={point.index === selectedMergedPointIndex}
                key={point.index}
              />
            ))}
          </List>
        ) : (
          <Typography>No Merged/Stationary Points Found</Typography>
        )}
      </Box>
    </>
  );
}

export default HistoryAISMergedPoints;

import { getRoutes } from '../../api/routes';
import { EDossiers, setSelectedDossier } from '../../main-menu/menu.slice';
import setIncidentFeatures from '../../map/map-layer-manager/incident-utils/set-incident-features';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import setPortFeatures from '../../map/map-layer-manager/port-utils/set-port-features';
import addRouteLayer from '../../map/map-layer-manager/route-utils/route-layer-handler';
import { GenericHistoryItem, isNoPropsItem } from '../../models/history';
import { Route } from '../../models/routes.model';
import { addHistoryItem, emptyHistory } from '../../nav-history.slice';
import { setSelectedIncident } from '../../state/incidents/incidents.slice';
import {
  setError,
  setRoutes,
  setSelectedRoute,
  setSelectedRouteAreas,
  setSelectedRouteIncidents,
  setSelectedRoutePorts,
} from '../../state/routes/routes.slice';
import store from '../../store';
import { Incident } from '../incidents-panel/incident.model';

const updateRoutes = async () => {
  try {
    const routes = await getRoutes();
    routes.forEach((route) => {
      addRouteLayer(route.route_id, route);
    });

    store.dispatch(setRoutes(routes));
    return routes;
  } catch (error) {
    store.dispatch(setError());
    return null;
  }
};

function resetRouteIncidents() {
  store.dispatch(setSelectedRouteIncidents(null));
  setIncidentFeatures(MapLayer.ROUTES_INCIDENTS, []);
}

function resetRoutePorts() {
  store.dispatch(setSelectedRoutePorts(null));
  setPortFeatures(MapLayer.ROUTES_PORTS, []);
}

export const deselectRoute = (options?: { resetEntities: boolean }) => {
  store.dispatch(setSelectedRoute(null));
  if (options?.resetEntities) {
    resetRouteIncidents();
    resetRoutePorts();
    store.dispatch(setSelectedRouteAreas(null));
  }
};

export const exportLastRouteFromHistory = (history: GenericHistoryItem[]) => {
  if (history.length > 0) {
    const lastItem = history[history.length - 1];
    if (!isNoPropsItem(lastItem) && lastItem.type === 'route') {
      const route = lastItem.properties as Route;
      store.dispatch(setSelectedRoute(route));
      store.dispatch(emptyHistory());
    }
  }
};

export const onRouteIncidentClick = (incident: Incident, route: Route) => {
  store.dispatch(setSelectedIncident(incident));
  store.dispatch(addHistoryItem({ type: 'route', properties: route }));
  store.dispatch(setSelectedDossier(EDossiers.INCIDENT));
};

export default updateRoutes;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getSpecificMaritimeArea } from '../../../api';
import { BackOneButton } from '../../../common-components/breadcrumb/breadcrumb';
import ErrorPanel from '../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import RiFurtherInformationFooter from '../../../common-components/ri-further-information-footer/ri-further-infrormation-footer';
import DocumentsAccordianPanel from '../../../dossiers_and_panels/documents/accordian-panel';
import { setSelectedEntityThreatAssessmentInfo } from '../../../dossiers_and_panels/threat-assessments/threat-assessment.slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setThreatAssessmentOpen } from '../../../main-menu/menu.slice';
import { EntityType } from '../../../models/document';
import {
  ExtendedMaritimeArea,
  MaritimeArea,
} from '../../../models/risk_intelligence.model';
import { addHistoryItem } from '../../../nav-history.slice';
import { setSelectedArea, setSelectedAreaId } from '../ri-maritime-areas.slice';
import './specific-ri-maritime-area.scss';

const isExpandedMaritimeArea = (
  area: MaritimeArea | ExtendedMaritimeArea
): area is ExtendedMaritimeArea =>
  (area as ExtendedMaritimeArea).threatAssessment !== undefined;

function SpecificRIMaritimeArea() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { selectedArea, selectedAreaId } = useAppSelector(
    (state) => state.riMaritimeAreas
  );

  const dispatch = useAppDispatch();

  const openThreatAssessment = () => {
    dispatch(
      setSelectedEntityThreatAssessmentInfo({
        id: (selectedArea as ExtendedMaritimeArea)!.threatAssessment,
        title: selectedArea!.title,
      })
    );
    dispatch(
      addHistoryItem({
        type: 'RiArea',
        properties: (selectedArea as ExtendedMaritimeArea)!,
      })
    );
    dispatch(setThreatAssessmentOpen(true));
  };

  useEffect(() => {
    if (!selectedArea || !isExpandedMaritimeArea(selectedArea)) {
      const idToGet = selectedAreaId || selectedArea?.id;
      if (idToGet) {
        setLoading(true);
        getSpecificMaritimeArea(idToGet)
          .then((response) => {
            dispatch(setSelectedArea(response));
            if (selectedAreaId) {
              dispatch(setSelectedAreaId(null));
            }
          })
          .catch(() => {
            setError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [selectedArea, selectedAreaId]);

  const nonDetachedStyling = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2.2em',
    alignItems: 'center',
  };

  const detachedStyling = {
    marginTop: '1.3em',
  };

  const { secondaryMenuOpen } = useAppSelector((state) => state.menu);

  return (
    <div className="specific-maritime-area-container">
      {loading && <LoadingPanel />}
      {!loading && error && (
        <ErrorPanel message="Error loading maritime area..." />
      )}
      {!loading && !error && selectedArea && (
        <Box className="specific-incident-info-container">
          <Box>
            <Box
              sx={{
                padding: '10px',
                height: '46px',
                maxWidth: '90%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Box
                sx={secondaryMenuOpen ? detachedStyling : nonDetachedStyling}
              >
                {!secondaryMenuOpen && <BackOneButton />}
                <Typography
                  variant="subtitle1"
                  fontWeight="500"
                  textAlign="left"
                  sx={{
                    fontSize: secondaryMenuOpen ? '1rem' : '1rem',
                    color: 'text.primary',
                  }}
                >
                  {selectedArea.title}
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              rowSpacing={1}
              sx={{
                padding: '0 10px 10px 10px',
                marginTop: secondaryMenuOpen ? '0.2em' : '0.5em',
              }}
            >
              <Grid item xs={3}>
                <Typography
                  sx={{ fontWeight: '500', fontSize: '14px' }}
                  align="left"
                >
                  Region
                </Typography>
              </Grid>
              {isExpandedMaritimeArea(selectedArea) && (
                <Grid item xs={9}>
                  <Typography sx={{ fontSize: '14px' }} align="right">
                    {selectedArea.region.title}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={3}>
                <Typography
                  sx={{ fontWeight: '500', fontSize: '14px' }}
                  align="left"
                >
                  Area Type
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: '14px' }} align="right">
                  {selectedArea.type}
                </Typography>
              </Grid>
            </Grid>
            {isExpandedMaritimeArea(selectedArea) && (
              <Container
                sx={{
                  overflowY: 'auto',
                  padding: '0 !important',
                  scrollbarWidth: 'thin',
                }}
              >
                <Typography
                  sx={{
                    bgcolor: 'primary.main',
                    padding: '10px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.25x',
                  }}
                >
                  {selectedArea.headline}
                </Typography>
              </Container>
            )}
          </Box>
          <Stack>
            {isExpandedMaritimeArea(selectedArea) && (
              <Box
                sx={{
                  padding: '10px',
                }}
              >
                <Button
                  onClick={openThreatAssessment}
                  sx={{
                    color: 'primary.dark',
                    width: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                    }}
                  >
                    Threat Assessment
                  </Typography>
                </Button>
              </Box>
            )}

            <DocumentsAccordianPanel
              entityId={String(selectedArea.id)}
              entityName={selectedArea.title}
              entityType={EntityType.riArea}
            />
          </Stack>

          <RiFurtherInformationFooter />
        </Box>
      )}
    </div>
  );
}

export default SpecificRIMaritimeArea;

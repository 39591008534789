/* eslint-disable react/destructuring-assignment */
import { Box, List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getIndustryNews } from '../../api/north-standard/industry-news';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { IndustryNews } from '../../models/industry-news.model';
import { setIndustryNews } from '../../state/industry-news/industry-news.slice';
import IndustryNewsItem from './industry-news-item/industry-news-item';

function IndustryNewsPanel() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const { industryNews } = useAppSelector((state) => state.industryNews);
  const [filteredIndustryNews, setFilteredIndustryNews] = useState<
    IndustryNews[]
  >(industryNews || []);

  useEffect(() => {
    if (!industryNews && !loading) {
      setLoading(true);
      getIndustryNews()
        .then((response) => {
          dispatch(setIndustryNews(response));
          setFilteredIndustryNews(response);
        })
        .catch(() => {
          setHasError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleSearchChange = (e: string) => {
    const inputValue = e;
    setSearchInputValue(inputValue);
    if (inputValue === '' && industryNews) {
      setFilteredIndustryNews(industryNews);
    } else {
      const filtered = industryNews
        ? industryNews.filter((newsInfo) =>
            newsInfo.title.toLowerCase().includes(inputValue.toLowerCase())
          )
        : [];
      setFilteredIndustryNews(filtered);
    }
  };

  return (
    <Box
      data-testid="industry-news-panel"
      sx={{
        minHeight: 0,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <PanelTitle>News</PanelTitle>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '0px 20px',
          }}
        >
          <SimpleSearchBar
            handleChange={handleSearchChange}
            filterValue={searchInputValue}
            placeholder="Search News"
            dataTestId="industry-news-search-bar"
          />
        </Box>

        {loading && <LoadingPanel />}
        {!loading && hasError && (
          <ErrorPanel message="Error: Unable to fetch News data, please try again later... " />
        )}
        {!loading && !hasError && industryNews && (
          <List
            sx={{
              overflowY: 'auto',
            }}
            data-testid="industry-news-list"
          >
            {filteredIndustryNews.length === 0 ? (
              <Typography>No News Data</Typography>
            ) : (
              filteredIndustryNews.map((news) => (
                <IndustryNewsItem key={news.id} news={news} />
              ))
            )}
          </List>
        )}
      </Box>
    </Box>
  );
}

export default IndustryNewsPanel;

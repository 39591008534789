import mapboxgl from 'mapbox-gl';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer, { MapGroupLayer } from '../map-layer.enum';

export interface ShippingLaneTile {
  type: 'image';
  url: string;
  coordinates: [number, number][];
}

export const createBitmapTile = (
  url: string,
  coordinates: [number, number][]
) => {
  const tile: ShippingLaneTile = {
    type: 'image',
    url,
    coordinates,
  };
  return tile;
};

const getShippingLaneOutlineId = (id: string) =>
  `${MapLayer.SHIPPING_LANE_OUTLINE}-${id}`;

export const addShippingLaneOutlineLayer = (id: string) => {
  const outlineId = getShippingLaneOutlineId(id);
  addSimpleGeoJsonSource(outlineId);

  MapLayerManager.AddLayerinGroups(
    {
      id: outlineId,
      type: 'line',
      source: outlineId,
      paint: {
        'line-color': '#dbdbd9',
        'line-width': 1,
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
        visibility: MapLayerVisibility.VISIBLE,
      },
    },
    [MapGroupLayer.SHIPPING_LANES]
  );
};

export const setShippingLaneOutline = (
  id: string,
  rect: [number, number][]
) => {
  const outline = MapHelpers.getSource(
    getShippingLaneOutlineId(id)
  ) as mapboxgl.GeoJSONSource;

  outline.setData(GeoHelper.createFeatureLineString([...rect, rect[0]], {}));
};

// clears the content of the shipping lane outline layer without deleting it
export const clearShippingLaneOutline = (id: string) => {
  const outline = MapHelpers.getSource(
    getShippingLaneOutlineId(id)
  ) as mapboxgl.GeoJSONSource;
  outline.setData(GeoHelper.createGeoJSON([]));
};

export const deleteAllShippingLaneOutlines = () => {
  const layers = MapHelpers.getStyle().layers || [];
  layers.forEach((layer) => {
    if (layer.id.includes(MapLayer.SHIPPING_LANE_OUTLINE)) {
      MapHelpers.removeLayer(layer.id);
      MapHelpers.removeSource(layer.id);
    }
  });
};

export const deleteShippingLaneOutlineById = (id: string) => {
  const layers = MapHelpers.getStyle().layers || [];
  const outlineId = getShippingLaneOutlineId(id);
  layers.forEach((layer) => {
    if (
      layer.id.includes(
        `${MapLayer.SHIPPING_LANE_OUTLINE}${id ? `-${id}` : ''}`
      )
    ) {
      MapHelpers.removeLayer(outlineId);
      MapHelpers.removeSource(outlineId);
    }
  });
};

export const deleteShippingLaneTileLayers = (id?: string) => {
  const layers = MapHelpers.getStyle().layers || [];
  layers.forEach((layer) => {
    if (
      layer.id.includes(`${MapLayer.SHIPPING_LANE_TILE}${id ? `-${id}` : ''}`)
    ) {
      MapHelpers.removeLayer(layer.id);
      MapHelpers.removeSource(layer.id);
    }
  });
};

export const addShippingTileLayers = (
  tiles: ShippingLaneTile[][],
  id: string
) => {
  const map = MapHelpers.getMapInstance();
  tiles.forEach((tileSet, index) => {
    tileSet.forEach((tile, index2) => {
      const tileId = `${MapLayer.SHIPPING_LANE_TILE}-${id}-${index}-tile-${index2}`;
      map.addSource(tileId, {
        type: 'image',
        url: tile.url,
        coordinates: tile.coordinates,
      });
      MapLayerManager.AddLayerinGroups(
        {
          id: tileId,
          source: tileId,
          type: 'raster',
          layout: {
            visibility: MapLayerVisibility.VISIBLE,
          },
          paint: {
            'raster-opacity': 1.0,
            'raster-resampling': 'nearest',
          },
        },
        [MapGroupLayer.SHIPPING_LANES]
      );
    });
  });
};

/* eslint-disable react/jsx-props-no-spreading */
import {
  arrow,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import update from 'immutability-helper';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useMobile } from '../../hooks';
import './list-picker.scss';

interface ListPickerItemProps<T> {
  item: T;
  isSelected: boolean;
  onClick: (id: number, checked: boolean) => void;
}

function ListPickerItem<T extends { id: number; title: string }>({
  item,
  isSelected,
  onClick,
}: ListPickerItemProps<T>) {
  const { id, title } = item;
  return (
    <ListItem
      disablePadding
      disableGutters
      onClick={() => onClick(id, !isSelected)}
    >
      <Checkbox
        checked={isSelected}
        onChange={(e) => onClick(id, e.target.checked)}
      />
      <span>{title}</span>
    </ListItem>
  );
}

export interface ListPickerProps<T> {
  items: T[];
  onSubmit: (items: number[]) => void;
  selectedItems: number[];
  setSelectedItems: (items: number[]) => void;
  buttonText: string | JSX.Element;
  openButtonClassName?: string;
  popoverClassName?: string;
  listClassName?: string;
}

function ListPicker<T extends { id: number; title: string }>({
  items,
  onSubmit,
  selectedItems,
  setSelectedItems,
  buttonText,
  listClassName,
  openButtonClassName,
  popoverClassName,
}: PropsWithChildren<ListPickerProps<T>>) {
  const [allIds, setAllIds] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [buttonSelection, setButtonSelection] = useState<string | null>(null);

  const isMobile = useMobile();
  const theme = useTheme();

  useEffect(() => {
    setAllIds(items.map((item) => item.id));
  }, [items]);
  const selectAll = () => {
    setSelectedItems(allIds);
  };
  const selectNone = () => {
    setSelectedItems([]);
  };

  // Popover setup
  const arrowRef = useRef(null);
  const { x, y, strategy, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom',
    middleware: [
      offset({ crossAxis: isMobile ? 0 : 90, mainAxis: 10 }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          // Do things with the data, e.g.
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 40}px`,
          });
        },
      }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const onClick = (id: number, checked: boolean) => {
    setButtonSelection(null);

    if (checked) {
      if (!selectedItems.includes(id)) {
        setSelectedItems(update(selectedItems, { $push: [id] }));
        return;
      }
      // item just selected was already selected?
      return;
    }

    // box is unchecked
    if (selectedItems.includes(id)) {
      const indexToRemove = selectedItems.findIndex((i) => i === id);
      setSelectedItems(
        update(selectedItems, { $splice: [[indexToRemove, 1]] })
      );
    }
  };

  const handleButtonSelection = (
    event: React.MouseEvent<HTMLElement>,
    newButtonSelection: string | null
  ) => {
    switch (newButtonSelection) {
      case 'none':
        setButtonSelection(newButtonSelection);
        selectNone();
        break;
      case 'all':
        setButtonSelection(newButtonSelection);
        selectAll();
        break;
      default:
        setButtonSelection(null);
        break;
    }
  };

  return (
    <>
      <IconButton
        className={classNames('filter-button', 'filterItem', {
          [`${openButtonClassName}`]: openButtonClassName,
          mobile: isMobile,
        })}
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'background.paper',
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '40px',
          padding: '6px 24px 6px 16px',
          fontSize: '16px',
          fontWeight: '400',
          color: 'text.primary',
          bgcolor: open ? 'primary.light' : '',
          borderRadius: '0',
          width: '100%',
        }}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {buttonText}
        {open ? <ArrowDropDown /> : <ArrowRight />}
      </IconButton>
      {open && (
        <Box
          className={`popoverContainer${
            popoverClassName ? ` ${popoverClassName}` : ''
          }`}
          bgcolor={theme.palette.primary.light}
          ref={refs.setFloating}
          sx={{
            overflowY: 'auto',
            scrollbarWidth: 'thin',
          }}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            borderRadius: '28px',
          }}
          {...getFloatingProps()}
        >
          <ToggleButtonGroup
            className="listPickerAllNoneButtons"
            value={buttonSelection}
            exclusive
            onChange={handleButtonSelection}
            aria-label="selection buttons"
            sx={{
              borderBottom: '1px solid',
              borderColor: 'background.paper',
              borderRadius: 0,
              '& .MuiToggleButtonGroup-grouped': {
                margin: '0 auto',
                border: 0,
                borderRadius: '4px',
                width: '100%',
                '&:not(:first-of-type)': {
                  margin: 0,
                },
                '&:first-of-type': {
                  borderTopLeftRadius: '28px',
                },
                '&:last-child': {
                  borderTopRightRadius: '28px',
                },
              },
            }}
          >
            <ToggleButton className="listPickerButton" value="all">
              Select All
            </ToggleButton>

            <ToggleButton className="listPickerButton" value="none">
              Select None
            </ToggleButton>
          </ToggleButtonGroup>
          <List
            sx={{ p: '0.5rem 1rem' }}
            className={`listPickerList${
              listClassName ? ` ${listClassName}` : ''
            }`}
          >
            {items.map((item) => (
              <ListPickerItem
                key={item.id}
                item={item}
                onClick={onClick}
                isSelected={selectedItems.includes(item.id)}
              />
            ))}
          </List>
          <Button
            className="listPickerButton listPickerSubmit"
            sx={{
              borderRadius: '0 0 28px 28px',
              borderTop: '1px solid',
              borderTopColor: 'background.paper',
            }}
            onClick={() => {
              onSubmit(selectedItems);
              setOpen(false);
            }}
          >
            Submit
          </Button>
        </Box>
      )}
    </>
  );
}

ListPicker.defaultProps = {
  openButtonClassName: '',
  popoverClassName: '',
  listClassName: '',
};

export default ListPicker;

import { FirstCallProperties } from '../../models/first-call';

/* eslint-disable import/prefer-default-export */
export const MOCK_FIRST_CALL_RESPONSE: GeoJSON.FeatureCollection<
  GeoJSON.Point,
  FirstCallProperties
> = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      id: 29,
      geometry: {
        type: 'Point',
        coordinates: [-122.41666699999996, 47.283333000000027],
      },
      properties: {
        OBJECTID: 29,
        portName: 'Tacoma',
        latitude: 47.283332999999999,
        longitude: -122.416667,
        worldPortIndexNumber: 17700,
        unLocode: 'US TIW',
        portCategory: 'East/West US Coast Ports',
        portContactDetails:
          'If you are disembarking crew for medical treatment at the following port, please contact First Call',
        dataSource: 'NorthStandard',
        dataSourceURL: 'https://www.nepia.com/first-call/',
        email: 'firstcall@hudsontactix.com',
        company: 'Hudson Tactix',
        telephone: '+1 856 342 7500',
      },
    },
    {
      type: 'Feature',
      id: 30,
      geometry: {
        type: 'Point',
        coordinates: [-75.549999999999955, 39.733333000000073],
      },
      properties: {
        OBJECTID: 30,
        portName: 'Wilmington, Delaware',
        latitude: 39.733333000000002,
        longitude: -75.549999999999997,
        worldPortIndexNumber: 8050,
        unLocode: 'US ILG',
        portCategory: 'East/West US Coast Ports',
        portContactDetails:
          'If you are disembarking crew for medical treatment at the following port, please contact First Call',
        dataSource: 'NorthStandard',
        dataSourceURL: 'https://www.nepia.com/first-call/',
        email: 'firstcall@hudsontactix.com',
        company: 'Hudson Tactix',
        telephone: '+1 856 342 7500',
      },
    },
    {
      type: 'Feature',
      id: 31,
      geometry: {
        type: 'Point',
        coordinates: [-77.949999999999989, 34.233333000000073],
      },
      properties: {
        OBJECTID: 31,
        portName: 'Wilmington, North Carolina',
        latitude: 34.233333000000002,
        longitude: -77.950000000000003,
        worldPortIndexNumber: 8470,
        unLocode: 'US ILM',
        portCategory: 'East/West US Coast Ports',
        portContactDetails:
          'If you are disembarking crew for medical treatment at the following port, please contact First Call',
        dataSource: 'NorthStandard',
        dataSourceURL: 'https://www.nepia.com/first-call/',
        email: 'firstcall@hudsontactix.com',
        company: 'Hudson Tactix',
        telephone: '+1 856 342 7500',
      },
    },
  ],
};

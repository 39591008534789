import { StyleSheet, Text, View } from '@react-pdf/renderer';
import {
  translateThreatLevelToText,
  translateThreatTrendToText,
} from '../dossiers_and_panels/threat-assessments/threat-assessment-helpers';
import { Threat } from '../dossiers_and_panels/threat-assessments/threat-assessment.model';
import TALevelChip from './threat-assessment-level-chip';

const styles = StyleSheet.create({
  table: {
    fontSize: 10,
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 30,
  },
  tableRowHeader: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Helvetica-Bold',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    fontWeight: 700,
    fontSize: 10,
    backgroundColor: '#203058',
    color: '#fff',
    height: '20',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    width: '100%',
    border: '1px solid #000',
    borderTop: 'none',
  },
  headerCell: {
    width: '33%',
  },
  cell: {
    width: '33%',
    borderRight: '1px solid #000',
    paddingLeft: 5,
    paddingBottom: 5,
    paddingTop: 5,
    color: '#3C3D3D',
  },
  rightCell: {
    width: '33%',
    paddingLeft: 5,
    paddingBottom: 5,
    paddingTop: 5,
    color: '#3C3D3D',
  },
  colourChip: {
    borderRadius: '50%',
    width: '10px',
    height: '10px',
  },
});

function TAThreatsTable({ threats }: { threats: Threat[] }) {
  return (
    <View style={styles.table}>
      <View style={styles.tableRowHeader}>
        <Text style={styles.headerCell}>Type</Text>
        <Text style={styles.headerCell}>Level</Text>
        <Text style={styles.headerCell}>Trend</Text>
      </View>
      {threats.map((threat) => (
        <View key={threat.type.title} style={styles.tableRow}>
          <Text style={styles.cell}>{threat.type.title}</Text>
          <View style={{ ...styles.cell, flexDirection: 'row' }}>
            <TALevelChip level={threat.level} />
            <Text>{translateThreatLevelToText(threat.level)}</Text>
          </View>

          <Text style={styles.rightCell}>
            {translateThreatTrendToText(threat.trend)}
          </Text>
        </View>
      ))}
    </View>
  );
}

export default TAThreatsTable;

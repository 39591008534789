import PanelTitle from '../../common-components/panel/panel-title';
import HistoryForm from './history-form/history-form';

function HistoryPanel() {
  return (
    <div className="history-panel-container">
      <PanelTitle>History</PanelTitle>
      <HistoryForm />
    </div>
  );
}

export default HistoryPanel;

import { ReduxStatesToVerify, useEnsureReduxLoaded } from '../api';
import useAccessControl from '../hooks/access-control/useAccessControl';
import { SearchableData } from '../models/search';
import './search-panel.scss';
import SearchResults from './search-results';

interface SearchPanelProps {
  searchableData: SearchableData;
  query: string;
}

function SearchPanel({ searchableData, query }: SearchPanelProps) {
  const privileges = useAccessControl();

  const reduxLoadingComplete = useEnsureReduxLoaded([
    ReduxStatesToVerify.PORTS,
    ...(privileges.canAccessRiCountries ? [ReduxStatesToVerify.COUNTRIES] : []),
    ...(privileges.canAccessBoundaries ? [ReduxStatesToVerify.BOUNDARIES] : []),
    ...(privileges.canAccessDrawings ? [ReduxStatesToVerify.DRAWINGS] : []),
    ...(privileges.canAccessIncidents ? [ReduxStatesToVerify.INCIDENTS] : []),
    ...(privileges.canAccessDocuments ? [ReduxStatesToVerify.DOCUMENTS] : []),
    ...(privileges.canAccessRiMaritimeAreas
      ? [ReduxStatesToVerify.MARITIME_AREAS]
      : []),
    ReduxStatesToVerify.FIRST_CALL_PORTS,
    ReduxStatesToVerify.CORRESPONDENTS,
  ]);
  return (
    <SearchResults
      searchableData={searchableData}
      query={query}
      reduxLoadingComplete={reduxLoadingComplete}
    />
  );
}

export default SearchPanel;

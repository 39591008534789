import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Units } from '@turf/helpers';
import { circle as turfCircle } from '@turf/turf';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { useAppDispatch, useAppSelector } from '../../../hooks';

import {
  getVesselsDAAS,
  intialiseVesselsByLocationWebsocket,
  mergeMessagesOntoVessels,
} from '../../../api/vessels';
import WebSocket from '../../../api/web-socket/WebSocket';
import ErrorPanel from '../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import SimpleSearchBar from '../../../common-components/simple-search-bar/simple-search-bar';
import {
  deleteDrawing,
  drawCircleLine,
} from '../../../map/drawing/drawing.utils';
import { DMSToDecimal } from '../../../map/map-controls.utils';
import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../../map/map.utils';
import {
  MaritimeAisApiLocation,
  MessageData,
  VesselData,
} from '../../../models/maritime-ais-api';
import {
  validateLatitudeDms,
  validateLongitudeDms,
} from '../../../utils/validation-helpers.utils';
import {
  clearNearbyVessels,
  renderNearbyVessels,
} from '../../../utils/vessels.utils';
import NearbyVesselsFormSummary from './nearby-vessels-form-summary/nearby-vessels-form-summary';
import NearbyVesselsForm from './nearby-vessels-form/nearby-vessels-form';
import {
  NearbyVesselsFormValues,
  defaultValues,
} from './nearby-vessels-form/nearby-vessels-form-values';
import NearbyVesselsResults from './nearby-vessels-results/nearby-vessels-results';
import {
  appendNearbyVessels,
  setError,
  setLoading,
  setNearbyVessels,
  setNearbyVesselsFormValues,
} from './nearby-vessels.slice';

const HISTORY_WEBSOCKET_BASE =
  process.env.REACT_APP_HISTORY_WEBSOCKET_BASE || '';

function NearbyVessels() {
  useAppSelector((state) => state.nearbyVessels);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.nearbyVessels.loading);
  const error = useAppSelector((state) => state.nearbyVessels.error);
  const nearbyVessels = useAppSelector(
    (state) => state.nearbyVessels.nearbyVessels
  );
  const nearbyVesselsFormValues = useAppSelector(
    (state) => state.nearbyVessels.nearbyVesselsFormValues
  );
  const [websocketId, setWebsocketId] = useState<string | undefined>(undefined);

  const [formExpanded, setFormExpanded] = useState(true);

  const [searchFilter, setSearchFilter] = useState('');

  const [longWaitDisplayMessage, setLongWaitDisplayMessage] = useState<
    string | null
  >(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (websocketId) {
      const timer = setTimeout(() => {
        setLongWaitDisplayMessage(
          'Response is taking longer than usual. Please wait...'
        );
      }, 30000); // display message after 30 seconds
      return () => {
        clearTimeout(timer);
      };
    }
    setLongWaitDisplayMessage(null);
  }, [websocketId]);

  const filteredVesselData = () => {
    if (searchFilter === '') {
      return Object.values(nearbyVessels?.data ?? []);
    }

    const vessels = Object.values(nearbyVessels?.data ?? []);
    const filteredVessels = vessels.filter(
      (vesselData) =>
        vesselData.vessel?.staticData?.name
          ?.toLowerCase()
          .includes(searchFilter.toLowerCase()) ||
        String(vesselData.vessel?.staticData?.mmsi).includes(
          searchFilter.toLowerCase()
        ) ||
        String(vesselData.vessel?.staticData.imo)
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
    );
    return filteredVessels;
  };

  const currentDecimalCoords = () => {
    const { centrePoint } = nearbyVesselsFormValues;
    if (centrePoint) {
      return [
        DMSToDecimal(centrePoint.longitude) || 0,
        DMSToDecimal(centrePoint.latitude) || 0,
      ];
    }
    return [0, 0];
  };

  const filteredVessels = filteredVesselData();

  useEffect(() => {
    const { centrePoint, radius } = nearbyVesselsFormValues;
    if (centrePoint && radius) {
      // This is a bit of a hack because DMSToDecimal doesn't throw an error with an invalid input
      const coords = currentDecimalCoords();
      if (coords[0] === 0 && coords[1] === 0) {
        // Don't show a circle at origin
        deleteDrawing(MapLayer.NEARBY_VESSELS_AREA);
      } else {
        drawCircleLine(MapLayer.NEARBY_VESSELS_AREA, coords, radius, {
          'line-color': '#dbdbd9',
          'line-opacity': 1,
        });
      }
    }
  }, [nearbyVesselsFormValues.centrePoint, nearbyVesselsFormValues.radius]);

  useEffect(() => {
    if (!loading && !error) {
      renderNearbyVessels(nearbyVessels);
    }

    if (!nearbyVessels) {
      setFormExpanded(true);
    }
  }, [nearbyVessels]);

  const handleToggleForm = () => {
    setFormExpanded(!formExpanded);
  };

  const validationSchema = Yup.object().shape({
    radius: Yup.number()
      .typeError('Radius must be a number')
      .required('Radius is required')
      .max(100, 'Radius must not exceed 100nm'),
    timeSinceLastAIS: Yup.number()
      .typeError('Time since last AIS ping must be a number')
      .required('Time since last AIS ping is required')
      .max(24, 'Time since last AIS ping must not exceed 24 hours'),
    centrePoint: Yup.object().shape({
      latitude: validateLatitudeDms(),
      longitude: validateLongitudeDms(),
    }),
  });

  const onSubmit = (values: NearbyVesselsFormValues) => {
    dispatch(setLoading(true));
    dispatch(setError(false));
    dispatch(setNearbyVesselsFormValues(values));
    const options = {
      steps: 16,
      units: 'nauticalmiles' as Units,
    };
    const circle = turfCircle(currentDecimalCoords(), values.radius, options);
    const wsId = `history-websocket-${uuid()}`;
    setWebsocketId(wsId);
    // initialise websocket to get nearby vessels
    intialiseVesselsByLocationWebsocket(
      circle.geometry.coordinates,
      values.timeSinceLastAIS,
      wsId
    );

    MapHelpers.zoomToPolygon(circle.geometry);
  };

  const formik = useFormik({
    initialValues: {
      ...nearbyVesselsFormValues,
    },
    onSubmit,
    validationSchema,
  });

  const handleResetForm = () => {
    formik.resetForm();
    formik.setValues(defaultValues);
    dispatch(setNearbyVessels(null));
    clearNearbyVessels();
  };

  const handleWebsocketReponse = async (result: MessageEvent<unknown>) => {
    try {
      const { metadata, data: messageDataResponse } = JSON.parse(
        result.data as string
      );

      if (!messageDataResponse) {
        dispatch(setError(true));
      }

      // if the end of data is reached, close the websocket by setting the websocketId to undefined
      // this unmounts websocket component and closes the connection via react-use-websocket
      if (metadata?.endOfData) {
        setWebsocketId(undefined);
        dispatch(setLoading(false));
        dispatch(setError(false));
        return;
      }

      // need to get the vessels seperately as the websocket response only contains messages
      const vesselMmsis = new Set<string>(
        messageDataResponse.map((message: MessageData) =>
          message.mmsi.toString()
        )
      );

      const getVesselsDAASResponse = await getVesselsDAAS({
        mmsis: Array.from(vesselMmsis),
      });

      // create a dict of  vessel IDs as keys and storing entire vessel data objects as values
      const vessels: Record<string, VesselData> = getVesselsDAASResponse.reduce(
        (acc: Record<string, VesselData>, vessel) => {
          acc[vessel.vesselId] = vessel;
          return acc;
        },
        {}
      );

      const resultFromMerge: Record<string, MaritimeAisApiLocation> =
        mergeMessagesOntoVessels(vessels, messageDataResponse, false);

      dispatch(appendNearbyVessels({ data: resultFromMerge }));
      setFormExpanded(false);
    } catch (e) {
      dispatch(setLoading(false));
      dispatch(setError(true));
    }
  };

  return (
    <Box
      data-testid="nearby-vessels"
      sx={{
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        height: '92vh',
      }}
    >
      {loading && <LoadingPanel />}
      {loading && longWaitDisplayMessage && (
        <Typography sx={{ textAlign: 'center' }}>
          {longWaitDisplayMessage}
        </Typography>
      )}
      {error && <ErrorPanel message="Failed to load nearby vessels" />}
      {!loading && (
        <Box
          sx={{
            height: formExpanded ? '50%' : '10%',
          }}
        >
          {nearbyVessels && (
            <IconButton onClick={handleToggleForm} sx={{ float: 'right' }}>
              {formExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
          {formExpanded ? (
            <NearbyVesselsForm formik={formik} handleReset={handleResetForm} />
          ) : (
            <NearbyVesselsFormSummary />
          )}
        </Box>
      )}

      {nearbyVessels && !loading && !error && (
        <Box
          sx={{
            overflowY: 'hidden',
            fontSize: 'small',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            height: formExpanded ? '50%' : '90%',
            paddingBottom: '10px',
          }}
        >
          <Divider style={{ margin: '1rem 0' }} />
          <Box sx={{ display: 'flex', padding: '0px 20px' }}>
            <SimpleSearchBar
              handleChange={(value: string) => {
                setSearchFilter(value);
              }}
              filterValue={searchFilter}
              placeholder="Search Nearby Vessels"
            />
          </Box>
          {filteredVessels && filteredVessels.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: '1rem',
                  height: formExpanded ? '63.5%' : '80%',
                }}
              >
                <NearbyVesselsResults results={filteredVessels} />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  height: '5vh',
                  marginTop: '10px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleResetForm}
                  sx={{
                    width: '30%',
                    height: '80%',
                    marginRight: '1rem',
                    flex: 'none', // Don't shrink the button
                  }}
                >
                  Reset
                </Button>
              </Grid>
            </Box>
          ) : (
            <Typography sx={{ textAlign: 'center' }}>
              No results found. Please try a different search term.
            </Typography>
          )}
        </Box>
      )}
      {websocketId && HISTORY_WEBSOCKET_BASE && (
        <WebSocket
          url={
            new URL(
              `${HISTORY_WEBSOCKET_BASE}?clientId=${websocketId}&dataSource=aisDatabase`
            )
          }
          signUrl
          onMessageReceived={(result) => {
            handleWebsocketReponse(result);
          }}
        />
      )}
    </Box>
  );
}

export default NearbyVessels;

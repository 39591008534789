import { FormControlLabel, Stack } from '@mui/material';
import LayerToggle from '../../common-components/layer-toggle/layer-toggle';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import './boundaries-panel.scss';

type CombinedBoundaryLayer =
  | MapLayer.BOUNDARIES_TTW
  | MapLayer.BOUNDARIES_EEZ
  | MapLayer.BOUNDARIES_JWC;

type BoundaryVisibilityState = Record<CombinedBoundaryLayer, boolean>;

const layerLabel = {
  [MapLayer.BOUNDARIES_TTW]: 'TTW',
  [MapLayer.BOUNDARIES_EEZ]: 'EEZ',
  [MapLayer.BOUNDARIES_JWC]: 'JWC',
};

function BoundariesCombinedLayerToggle() {
  return (
    <Stack px={2}>
      {Object.keys(layerLabel).map((type) => (
        <FormControlLabel
          key={type}
          control={
            <LayerToggle variant="switch" layers={[type]} layerGroups={[]} />
          }
          label={`Display Worldwide ${
            layerLabel[type as keyof BoundaryVisibilityState]
          } Boundaries`}
        />
      ))}
    </Stack>
  );
}

export default BoundariesCombinedLayerToggle;

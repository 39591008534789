import { useEffect, useState } from 'react';
import ListSlider from '../../../common-components/list-slider/list-slider';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { ThreatType, setPortsFilters } from '../world-ports-panel.slice';

function PortsThreatTypeFilter() {
  const dispatch = useAppDispatch();
  const { threatType } = useAppSelector((state) => state.portsPanel.filters);
  const [selectedItems, setSelectedItems] = useState<ThreatType[]>([]);

  useEffect(() => {
    setSelectedItems(threatType);
  }, [threatType]);

  const handleSubmit = () => {
    dispatch(
      setPortsFilters({
        threatType: selectedItems,
      })
    );
  };

  return (
    <ListSlider
      onSubmit={handleSubmit}
      items={selectedItems}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      buttonText="Threat Type"
    />
  );
}

export default PortsThreatTypeFilter;

import onIndustryNewsClusterClick from './industry-news-cluster-onClick';
import onIndustryNewsClick from './industry-news-onClick';

namespace IndustryNewsController {
  export const layerEvents = {
    onClick: onIndustryNewsClick,
    onClusterClick: onIndustryNewsClusterClick,
  };
}

export default IndustryNewsController;

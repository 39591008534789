import { RgbColor } from 'react-colorful';
import {
  ShippingLaneResolution,
  ShippingLaneResponseBody,
} from '../../api/shipping-lanes';
import {
  ShippingLaneTile,
  createBitmapTile,
} from '../../map/map-layer-manager/shipping-lane-utils/add-shipping-lane-layers';
import Bitmap from '../../utils/bitmap';
import GeoHelper from '../../utils/geo-helpers.utils';

export const createBitmaps = (
  data: ShippingLaneResponseBody,
  globalMax: number,
  colour: RgbColor
): ShippingLaneTile[] => {
  const { meta, lanes } = data;
  const { resolution, extent } = meta;
  const height = lanes.length;
  const width = lanes[0].length;

  let maxPixels = 100;
  if (resolution === ShippingLaneResolution.MEDIUM) {
    maxPixels = 10;
  } else if (resolution === ShippingLaneResolution.LOW) {
    maxPixels = 5;
  }
  const tilesHeight = Math.ceil(height / maxPixels);
  const bitmaps: ShippingLaneTile[] = [];

  // because one of the tiles may be smaller than the others (if height doesn't divide evenly), we need to keep track of the offset more carefully than tilesHeight * ty
  let offsetY = 0;

  for (let ty = 0; ty < tilesHeight; ty += 1) {
    const thisExtentHeight = maxPixels * resolution;

    // bitmaps are drawn from top to bottom, but the tiles are numbered from bottom to top
    const tyInverted = tilesHeight - ty - 1;

    const thisExtent = [...extent];
    thisExtent[2] = extent[2] + thisExtentHeight * tyInverted;
    thisExtent[3] = extent[2] + thisExtentHeight * (tyInverted + 1);
    if (thisExtent[3] > extent[3]) {
      // eslint-disable-next-line prefer-destructuring
      thisExtent[3] = extent[3];
    }
    const thisPixels = Math.round((thisExtent[3] - thisExtent[2]) / resolution);
    // tileHeight can round to 0
    if (thisPixels === 0) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const bmp = new Bitmap(width, thisPixels);
    for (let y = 0; y < bmp.height; y += 1) {
      const lane = data.lanes[y + offsetY];
      if (!lane) {
        break;
      }
      for (let x = 0; x < bmp.width; x += 1) {
        // log-normalise to prevent high peaks blowing out the colour
        const fullnessFactor = Math.max(
          Math.log(lane[x]) / Math.log(globalMax),
          0
        );
        // The RGB values of a bitmap pixel are defined between a range of
        // 0-1, so we need to divide the provided RGB values by 255 each
        bmp.pixel[x][y] = [
          colour.r / 255,
          colour.g / 255,
          colour.b / 255,
          fullnessFactor,
        ];
      }
    }
    offsetY += thisPixels;
    const url = bmp.dataURL();

    const flippedExtent = [...thisExtent] as [number, number, number, number];
    const temp = flippedExtent[2];
    // eslint-disable-next-line prefer-destructuring
    flippedExtent[2] = flippedExtent[3];
    flippedExtent[3] = temp;
    bitmaps.push(createBitmapTile(url, GeoHelper.extentToRect(flippedExtent)));
  }
  return bitmaps;
};

export const getGlobalMax = (results: ShippingLaneResponseBody[]) => {
  let max = 0;
  results.forEach((result) => {
    result.lanes.forEach((row) => {
      row.forEach((value) => {
        if (value > max) {
          max = value;
        }
      });
    });
  });
  return max;
};

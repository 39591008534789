/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import ListPicker from '../../../../common-components/list-picker/list-picker';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  calcDisplayedText,
  listFilterItems,
} from '../../../../utils/incidents-helpers';
import { setIncidentFilters } from '../../incident-panel.slice';
import { TimeOfDayDisplayFilter } from '../../incident.model';

function TimeOfDayFilter() {
  const { filters } = useAppSelector((store) => store.incidentsPanel);
  const dispatch = useAppDispatch();

  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState(
    () => filters.timeOfDay
  );

  useEffect(() => {
    setSelectedTimeOfDay(filters.timeOfDay);
  }, [filters.timeOfDay]);

  const onSubmit = () => {
    dispatch(
      setIncidentFilters({
        timeOfDay: selectedTimeOfDay,
      })
    );
  };

  return (
    <ListPicker
      onSubmit={onSubmit}
      items={listFilterItems(TimeOfDayDisplayFilter)}
      selectedItems={selectedTimeOfDay}
      setSelectedItems={setSelectedTimeOfDay}
      buttonText={calcDisplayedText(
        filters.timeOfDay,
        'Time of Day',
        TimeOfDayDisplayFilter
      )}
    />
  );
}
export default TimeOfDayFilter;

// Once we have pulled the incidentTypes from RI, we need to let mapbox know there is a big set of new icons for it to use.
import { promisify } from 'util';
import { IncidentType } from '../../../maritime-menu-options/incidents-panel/incident.model';
import { getIncidentMapBoxImageName } from '../../../utils/incidents-helpers';
import MapHelpers from '../../map.utils';

const loadIncidentTypeMarkerImages = async (incidentTypes: IncidentType[]) => {
  const map = MapHelpers.getMapInstance();
  const loadImagePromise = promisify(map.loadImage).bind(map);
  const mapImages = map.listImages();

  const promises = incidentTypes.flatMap((incidentType) =>
    Object.keys(incidentType.icons).reduce<
      ReturnType<typeof loadImagePromise>[]
    >((acc, key) => {
      const iconKey = key as keyof IncidentType['icons'];
      const imageName = getIncidentMapBoxImageName(incidentType, iconKey);

      if (!mapImages.includes(imageName)) {
        acc.push(
          loadImagePromise(
            `data:image/png;base64,${incidentType.icons[iconKey]}`
          ).then((img) => {
            if (img) {
              map.addImage(imageName, img);
            }
            return img;
          })
        );
      }
      return acc;
    }, [])
  );
  return Promise.all(promises);
};

export default loadIncidentTypeMarkerImages;

import { Box, Typography } from '@mui/material';
import MuiFormikTextField from '../../common-components/form-fields/mui-formik-text-field';
import './create-drawing-forms.scss';

function CircleDrawingForm() {
  return (
    <>
      <Box>
        <Typography sx={{ fontSize: '1rem', textAlign: 'left' }}>
          Radius (NM)
        </Typography>
        <MuiFormikTextField
          label="Radius (NM)"
          hiddenLabel
          name="radiusInNauticalMilesInput"
          ariaLabel="radius-in-nautical-miles-input"
          size="small"
          sx={{ maxHeight: '10%' }}
        />
      </Box>

      <Typography sx={{ fontSize: '1rem', textAlign: 'left' }}>
        Centre point (lat)
      </Typography>
      <MuiFormikTextField
        label="Centre Point (Lat)"
        hiddenLabel
        name="centrePointInputLat"
        ariaLabel="centre-point-input-lat"
        size="small"
      />

      <Typography sx={{ fontSize: '1rem', textAlign: 'left' }}>
        Centre point (long)
      </Typography>
      <MuiFormikTextField
        label="Centre Point (Long)"
        hiddenLabel
        name="centrePointInputLong"
        ariaLabel="centre-point-input-long"
        size="small"
      />
    </>
  );
}

export default CircleDrawingForm;

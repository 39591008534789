import {
  destination as turfDestination,
  lineString as turfLineString,
  point as turfPoint,
} from '@turf/turf';
import { Position } from 'geojson';
import { drawCircle } from '../../map/drawing/drawing.utils';
import { DMSToDecimal, convertCoordsToDMS } from '../../map/map-controls.utils';

export enum DrawingFormActions {
  NEW_COORDINATES = 'coordinates.new',
  DELETE_COORDINATES = 'coordinates.delete',
  CIRCLE_CENTRE_POINT_INPUT = 'centrePointInputLong',
  CIRCLE_RADIUS_INPUT = 'radiusInNauticalMilesInput',
}

/**
 * Adds a new point to a polygon, ensuring the polygon remains closed, ie, the first and last points are the same.
 * The new point will be a copy of the second-to-last point in the polygon, offset by 20nm eastwards
 * @param feature GeoJSON Feature
 * @returns new GeoJSON Feature
 */
const addPolygonPoint = (feature: GeoJSON.Feature<GeoJSON.Polygon>) => {
  // pop the last coord so we can add a new one before it
  const lastCoord = feature.geometry.coordinates[0].pop()!;
  const secondToLast = feature.geometry.coordinates[0].pop()!;
  const newCoord = turfDestination(secondToLast, 20, 90, {
    units: 'nauticalmiles',
  }).geometry.coordinates;
  newCoord[0] = Number(newCoord[0]);
  newCoord[1] = Number(newCoord[1]);
  const newCoordsWithLast = [
    ...feature.geometry.coordinates[0],
    secondToLast,
    newCoord,
    lastCoord as Position,
  ];

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [newCoordsWithLast],
    },
  };
};

/**
 * Removes the point at the given index from the polygon.
 * If the first or last point is removed, the polygon is reclosed, ie, the first and last points are updated to match.
 * @param feature GeoJSON Feature
 * @param index the index of the point to remove
 * @returns new GeoJSON Feature
 */
export const removePolygonPoint = (
  feature: GeoJSON.Feature<GeoJSON.Polygon>,
  index: number
) => {
  const newCoords = [...feature.geometry.coordinates[0]];

  // if first or last point of a polygon is removed we need to reclose it
  if (index === 0) {
    newCoords.shift();
    newCoords.pop();
    newCoords.push(newCoords[0]);
  } else if (index === newCoords.length - 1) {
    newCoords.shift();
    newCoords.pop();
    newCoords.unshift(newCoords[newCoords.length - 1]);
  } else {
    newCoords.splice(index, 1);
  }
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [newCoords],
    },
  };
};

/**
 * Adds a new point to a linestring offset by 20nm eastwards from the last point
 * @param feature GeoJSON Feature
 * @returns new GeoJSON Feature
 */
export const addLinestringPoint = (
  feature: GeoJSON.Feature<GeoJSON.LineString>
) => {
  const { coordinates } = feature.geometry;

  const lastCoord = coordinates[coordinates.length - 1];
  const newCoord = turfDestination(lastCoord, 20, 90, {
    units: 'nauticalmiles',
  }).geometry.coordinates;

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: [...coordinates, newCoord],
    },
  };
};

/**
 * Adds a new point to a linestring offset by 20nm eastwards from the last point
 * @param feature GeoJSON Feature
 * @returns new GeoJSON Feature
 */
export const addPoint = (feature: GeoJSON.Feature<GeoJSON.Point>) => {
  const { coordinates } = feature.geometry;

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates,
    },
  };
};

/**
 * Removes the point at the given index from the line string.
 * @param feature GeoJSON Feature
 * @param index the index of the point to remove
 * @returns new GeoJSON Feature
 */
export const removeLinestringPoint = (
  feature: GeoJSON.Feature<GeoJSON.LineString>,
  index: number
) => {
  const newCoords = [...feature.geometry.coordinates].filter(
    (_, i) => i !== index
  );

  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: newCoords,
    },
  };
};

/**
 * Generates a new LineString feature representing a circle, based on the current drawing and the form values
 * if the radius is changed, the radius point is moved while maintaining the bearing from the centre point
 * if the centre point is changed, the radius is maintained by also moving the radius point (maintaining the original bearing)
 * @param formName the fieldname that was changed in the form
 * @param formValue the value in the <input> field. This is a string.
 * @param currentDrawing GeoJSON Feature representing the current drawing
 * @param map mapboxGl map. needed to update the temp-circle layer
 * @returns
 */
export const updateMapboxDrawCircle = (
  formName: string,
  formValue: string,
  currentDrawing: GeoJSON.Feature<
    GeoJSON.LineString,
    { radius: number; subType: 'Circle' }
  > | null
) => {
  if (!currentDrawing || Number.isNaN(Number(formValue))) {
    return null;
  }
  const numberValue = Number(formValue);
  const coords = (currentDrawing as GeoJSON.Feature<GeoJSON.LineString>)
    .geometry.coordinates;
  const centrePoint: [number, number] =
    coords[0].length > 1
      ? [coords[0][0], coords[0][1]]
      : [coords[1][0], coords[0][0]];
  // User is editing the radius
  if (formName === DrawingFormActions.CIRCLE_RADIUS_INPUT) {
    // to correctly 'shorten' the circle we need the bearing from the centrepoint to the radius point
    const newRadiusPoint = turfDestination(
      turfPoint(centrePoint),
      numberValue,
      90,
      {
        units: 'nauticalmiles',
      }
    ).geometry.coordinates;
    newRadiusPoint[0] = Number(newRadiusPoint[0].toFixed(4));
    newRadiusPoint[1] = Number(newRadiusPoint[1].toFixed(4));
    const newDrawing = turfLineString(
      [centrePoint, newRadiusPoint],
      {
        subType: 'Circle',
        radius: numberValue,
      },
      { id: currentDrawing.id }
    );
    // also need to update the circle layer
    drawCircle('circle-edit-temp', centrePoint, numberValue, {});
    return newDrawing;
  }
  // User is editing the centre point

  const newCentrePoint =
    formName === DrawingFormActions.CIRCLE_CENTRE_POINT_INPUT
      ? [numberValue, centrePoint[1]]
      : [centrePoint[0], numberValue];
  // maintain existing radius
  const { radius } = currentDrawing.properties;
  const newRadiusPoint = turfDestination(newCentrePoint, radius, 90, {
    units: 'nauticalmiles',
  }).geometry.coordinates;
  newRadiusPoint[0] = Number(newRadiusPoint[0].toFixed(4));
  newRadiusPoint[1] = Number(newRadiusPoint[1].toFixed(4));
  newCentrePoint[0] = Number(newCentrePoint[0].toFixed(4));
  newCentrePoint[1] = Number(newCentrePoint[1].toFixed(4));

  const newDrawing = turfLineString(
    [newCentrePoint, newRadiusPoint],
    {
      subType: 'Circle',
      radius,
    },
    { id: currentDrawing.id }
  );

  // also need to update the circle layer
  drawCircle('circle-edit-temp', newCentrePoint, radius, {});
  return newDrawing;
};

/**
 * Updates a Mapbox Draw Polygon feature based on the formName and formValue
 * handles the special values of 'coordinates.new' and 'coordinates.delete',
 * which add and remove points from the polygon, whilst ensuring the polygon remains closed, ie, the first and last points are the same.
 * @param formName
 * @param formValue
 * @param currentDrawing
 * @returns
 */
export const updateMapboxDrawPolygon = (
  formName: string,
  formValue: string,
  currentDrawing: GeoJSON.Feature<GeoJSON.Polygon>,
  formikSetFieldValue: (
    field: string,
    value: string | string[][] | Position[]
  ) => void
) => {
  if (formName === DrawingFormActions.NEW_COORDINATES) {
    const newPolygon = addPolygonPoint(currentDrawing);
    const formPoints = [...newPolygon.geometry.coordinates[0]];
    // remove the last point, which is a duplicate of the first
    formPoints.pop();

    formikSetFieldValue('coordinates', convertCoordsToDMS(formPoints));

    return newPolygon;
  }

  if (formName === DrawingFormActions.DELETE_COORDINATES) {
    // in the case of 'delete', the value is the index of the point to delete
    const newPolygon = removePolygonPoint(
      currentDrawing,
      parseInt(formValue, 10)
    );
    const formPoints = [...newPolygon.geometry.coordinates[0]];
    // remove the last point, which is a duplicate of the first
    formPoints.pop();

    formikSetFieldValue('coordinates', convertCoordsToDMS(formPoints));
    return newPolygon;
  }

  const shapeCoords = currentDrawing.geometry.coordinates[0];
  const result = formName.match(/\[(.*?)\]\[(.*?)\]/);
  if (!result) {
    return null;
  }
  const [, index, longLat] = result;

  const value = Number(DMSToDecimal(formValue));
  if (index) {
    const waypointIndex = Number(index);

    shapeCoords[waypointIndex][Number(longLat)] = Number(value);
    // polygon coords must be closed, so editing the first or last points are equivalent
    if (waypointIndex === 0) {
      shapeCoords[shapeCoords.length - 1] = [...shapeCoords[0]];
    } else if (waypointIndex === shapeCoords.length - 1) {
      shapeCoords[0] = [...shapeCoords[shapeCoords.length - 1]];
    }

    return {
      ...currentDrawing,
      geometry: {
        ...currentDrawing.geometry,
        coordinates: [[...shapeCoords]],
      },
    } as GeoJSON.Feature<GeoJSON.Polygon>;
  }
  return null;
};

export const updateMapboxDrawLine = (
  formName: string,
  formValue: string,
  currentDrawing: GeoJSON.Feature<GeoJSON.LineString> | null,
  formikSetFieldValue: (
    field: string,
    value: string | string[][] | Position[]
  ) => void
) => {
  if (!currentDrawing) {
    return null;
  }
  if (formName === DrawingFormActions.NEW_COORDINATES) {
    const newLinestring = addLinestringPoint(currentDrawing);
    formikSetFieldValue(
      'coordinates',
      convertCoordsToDMS(newLinestring.geometry.coordinates)
    );
    return newLinestring;
  }

  if (formName === DrawingFormActions.DELETE_COORDINATES) {
    const newLinestring = removeLinestringPoint(
      currentDrawing,
      Number(formValue)
    );
    formikSetFieldValue(
      'coordinates',
      convertCoordsToDMS(newLinestring.geometry.coordinates)
    );
    return newLinestring;
  }

  const shapeCoords = currentDrawing.geometry.coordinates;

  const result = formName.match(/\[(.*?)\]\[(.*?)\]/);
  if (!result) {
    return null;
  }

  const [, index, longLat] = result;

  if (index) {
    const waypointIndex = Number(index);
    shapeCoords[waypointIndex][Number(longLat)] = Number(
      DMSToDecimal(formValue)
    );

    return {
      ...currentDrawing,
      geometry: {
        ...currentDrawing.geometry,
        coordinates: shapeCoords,
      },
    } as GeoJSON.Feature<GeoJSON.LineString>;
  }

  return {
    ...currentDrawing,
    geometry: {
      ...currentDrawing.geometry,
      coordinates: [...currentDrawing.geometry.coordinates],
    },
  } as GeoJSON.Feature<GeoJSON.LineString>;
};

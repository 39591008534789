/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns';
import type { VesselHistoryData } from '../../api/vessels';
import { VesselData } from '../../models/maritime-ais-api';
import { Vessel } from '../../models/vessel.model';
import { HistoricVesselPoint } from './historic-vessel-point.model';
import { HistoryFormValues } from './history-form/history-form-validators';
import HistoryPanelSampleRate from './history-panel-sample-rate';

export interface MergedHistoricVesselPoint
  extends Pick<Vessel, 'mmsi' | 'imo'> {
  unique_vessel_identifier: string;
  features: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties>[];
  startTimestamp: number;
  endTimestamp: number;
  index: number;
}

type IHistoryPanelState = {
  loading: boolean | string;
  error: boolean;
  disabled: boolean;
  selectedAISDataGapFeature: GeoJSON.Feature | null;
  selectedMergedPointIndex: number | null;
  aisDataGapLength: number;
  mergedPointsEnabled: boolean;
  mergedPointsMinimumGapLength: number;
  mergedPointsMinimumCount: number;
  mergedHistoricVesselPoints: MergedHistoricVesselPoint[];
  historicVesselPoints: HistoricVesselPoint[];
  resultsViewOpen: boolean;
  formValues: HistoryFormValues;
  vesselHistoryData: VesselHistoryData | null;
  vesselData: VesselData | null;
};

export const FORMATTED_TODAY = new Date().toISOString().split('T')[0];

export const FORMATTED_ONE_MONTH_AGO = subMonths(new Date(), 1)
  .toISOString()
  .split('T')[0];

export const INITIAL_HISTORY_PANEL_STATE: IHistoryPanelState = {
  loading: false,
  error: false,
  disabled: false,
  selectedAISDataGapFeature: null,
  selectedMergedPointIndex: null,
  aisDataGapLength: 24,
  mergedPointsEnabled: false,
  mergedPointsMinimumGapLength: 1,
  mergedPointsMinimumCount: 1,
  mergedHistoricVesselPoints: [],
  historicVesselPoints: [],
  resultsViewOpen: false,
  formValues: {
    identifiers: '',
    toDate: FORMATTED_TODAY,
    fromDate: FORMATTED_ONE_MONTH_AGO,
    sampleRate: HistoryPanelSampleRate.ONE_HOUR,
  },
  vesselHistoryData: null,
  vesselData: null,
};

const HistoryPanelSlice = createSlice({
  name: 'historyPanel',
  initialState: INITIAL_HISTORY_PANEL_STATE,
  reducers: {
    setSelectedAISDataGapFeature: (
      state,
      action: PayloadAction<GeoJSON.Feature | null>
    ) => {
      state.selectedAISDataGapFeature = action.payload;
    },
    setAISDataGapLength: (state, action: PayloadAction<number>) => {
      state.aisDataGapLength = action.payload;
    },
    setHistoricVesselPoints: (
      state,
      action: PayloadAction<HistoricVesselPoint[]>
    ) => {
      state.historicVesselPoints = action.payload;
    },
    prependHistoricVesselPoints: (
      state,
      action: PayloadAction<HistoricVesselPoint[]>
    ) => {
      state.historicVesselPoints = [
        ...action.payload,
        ...state.historicVesselPoints,
      ];
    },
    setMergedHistoricVesselPoints: (
      state,
      action: PayloadAction<MergedHistoricVesselPoint[]>
    ) => {
      state.mergedHistoricVesselPoints = action.payload;
    },
    setMergedPointsMinimumGapLength: (state, action: PayloadAction<number>) => {
      state.mergedPointsMinimumGapLength = action.payload;
    },
    setMergedPointsMinimumCount: (state, action: PayloadAction<number>) => {
      state.mergedPointsMinimumCount = action.payload;
    },
    setMergedPointsEnabled: (state, action: PayloadAction<boolean>) => {
      state.mergedPointsEnabled = action.payload;
    },
    setSelectedMergedPointIndex: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.selectedMergedPointIndex = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean | string>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
      // if error is set to true, loading should be set to false
      if (action.payload) {
        state.loading = false;
      }
    },
    setHistoryFormValues: (
      state,
      action: PayloadAction<Partial<HistoryFormValues> | null>
    ) => {
      // Only updates filters explictly mentioned in action
      state.formValues = {
        ...state.formValues,
        ...action.payload,
      };
    },
    setVesselHistoryData: (
      state,
      action: PayloadAction<VesselHistoryData | null>
    ) => {
      state.vesselHistoryData = action.payload;
    },
    // this is a bit of a pain due to the data segment being
    // an array of vessels, which then contain messages.
    // redux requires immutability so we can't just array.push
    prependVesselHistoryData: (
      state,
      action: PayloadAction<VesselHistoryData>
    ) => {
      if (!state.vesselHistoryData) {
        state.vesselHistoryData = action.payload;
        return;
      }
      const newState: VesselHistoryData = {
        ...state.vesselHistoryData,
        data: [],
      };
      state.vesselHistoryData.data.forEach((vesselData) => {
        const newVesselData = action.payload.data.find(
          (newData) => newData.vessel.vessel_id === vesselData.vessel.vessel_id
        );
        // the playload contains messages for this vessel
        if (newVesselData) {
          newState.data.push({
            ...vesselData,
            messages: [...newVesselData.messages, ...vesselData.messages],
          });
        } else {
          // otherwise leave it unchanged
          newState.data.push(vesselData);
        }
      });

      state.vesselHistoryData = newState;
    },
    clearVesselHistoryData: (state) => {
      state.selectedAISDataGapFeature = null;
      state.selectedMergedPointIndex = null;
      state.vesselHistoryData = null;
      state.mergedHistoricVesselPoints = [];
      state.historicVesselPoints = [];
    },
    setVesselData: (state, action: PayloadAction<VesselData | null>) => {
      state.vesselData = action.payload;
    },
  },
});

export const {
  setHistoricVesselPoints,
  prependHistoricVesselPoints,
  setMergedHistoricVesselPoints,
  setMergedPointsMinimumGapLength,
  setMergedPointsMinimumCount,
  setMergedPointsEnabled,
  setLoading,
  setError,
  setHistoryFormValues,
  setVesselHistoryData,
  prependVesselHistoryData,
  setAISDataGapLength,
  setSelectedAISDataGapFeature,
  setSelectedMergedPointIndex,
  clearVesselHistoryData,
  setVesselData,
} = HistoryPanelSlice.actions;

export default HistoryPanelSlice.reducer;

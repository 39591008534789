import { Position } from 'geojson';
import {
  Measurement,
  MeasurementPoint,
} from '../../../maritime-menu-options/tools-panel/measuring-tool/measurement.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { DMSToDecimal } from '../../map-controls.utils';
import MapHelpers from '../../map.utils';

const setMeasurementLayerFeatures = (options: {
  measurements: Measurement[];
  lineLayerId: string;
  pointLayerId: string;
}) => {
  const { measurements, lineLayerId, pointLayerId } = options;

  const pointToPosition = (point: MeasurementPoint) =>
    [
      DMSToDecimal(point.longitude as string),
      DMSToDecimal(point.latitude as string),
    ] as Position;

  const filteredMeasurements = measurements.map((measurement) => ({
    ...measurement,
    points: measurement.points.filter(
      (obj) => obj.latitude !== '' && obj.longitude !== ''
    ),
  }));

  const linestringFeatures = filteredMeasurements.map((measurement) =>
    GeoHelper.createFeatureLineString(measurement.points.map(pointToPosition), {
      title: measurement.title,
      id: measurement.id,
    })
  );

  const pointFeatures = filteredMeasurements
    .map((measurement) =>
      measurement.points.map((point, index) =>
        GeoHelper.createFeaturePoint(pointToPosition(point), {
          id: measurement.id,
          index,
        })
      )
    )
    .flat();
  const linestringGeoJSON = GeoHelper.createGeoJSON(linestringFeatures);
  const pointsGeoJSON = GeoHelper.createGeoJSON(pointFeatures);

  (MapHelpers.getSource(lineLayerId) as mapboxgl.GeoJSONSource)?.setData(
    linestringGeoJSON
  );

  (MapHelpers.getSource(pointLayerId) as mapboxgl.GeoJSONSource)?.setData(
    pointsGeoJSON
  );
};

export default setMeasurementLayerFeatures;

/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CorrespondentsPanelState {
  search: string;
}

export const INITIAL_CORRESPONDENTS_PANEL_STATE: CorrespondentsPanelState = {
  search: '',
};

const CorrespondentsPanelSlice = createSlice({
  name: 'correspondentsPanel',
  initialState: INITIAL_CORRESPONDENTS_PANEL_STATE,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
  },
});

export const { setSearch } = CorrespondentsPanelSlice.actions;

export default CorrespondentsPanelSlice.reducer;

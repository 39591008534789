import { Button, SelectChangeEvent, Stack } from '@mui/material';
import * as dateFns from 'date-fns';
import { FormikProvider, useFormik } from 'formik';
import mapboxgl from 'mapbox-gl';
import { useEffect, useMemo, useRef } from 'react';
import { RgbColor } from 'react-colorful';
import * as yup from 'yup';
import {
  ShippingLaneFlag,
  ShippingLaneRequestBody,
  ShippingLaneResolution,
  ShippingLaneShipType,
} from '../../api/shipping-lanes';
import ColourPicker from '../../common-components/colour-picker/colour-picker';
import MuiSelectFieldControlled from '../../common-components/form-fields/mui-select-field-controlled';
import LoadingButton from '../../common-components/loading-button/loading-button';
import MapLatLongPicker from '../../map/map-lat-long-picker';
import { setShippingLaneOutline } from '../../map/map-layer-manager/shipping-lane-utils/add-shipping-lane-layers';
import GeoHelper from '../../utils/geo-helpers.utils';
import { neatDisplayNumber } from '../../utils/text-formatting.utils';
import {
  validateLatitudeDms,
  validateLongitudeDms,
} from '../../utils/validation-helpers.utils';

export interface ShippingLaneFormValues {
  minLatitude: string | null;
  maxLatitude: string | null;
  minLongitude: string | null;
  maxLongitude: string | null;
  flag: ShippingLaneFlag;
  shipType: ShippingLaneShipType;
  resolution: ShippingLaneResolution;
}

interface ShippingLanesFormProps {
  formId: string;
  onColourChange: (colour: RgbColor) => void;
  onSubmit: (data: ShippingLaneRequestBody) => void;
  onClear: () => void;
  initialFormValues: ShippingLaneFormValues;
  laneColour: RgbColor;
  loading: boolean;
  success: boolean;
  error: string | null;
  percentComplete: number;
}

const validationSchema = yup.object().shape({
  minLatitude: validateLatitudeDms(),
  maxLatitude: validateLatitudeDms(),
  minLongitude: validateLongitudeDms(),
  maxLongitude: validateLongitudeDms(),
  flag: yup.string().required('Required'),
  shipType: yup.string().required('Required'),
  resolution: yup.number().required('Required'),
});

const noLowResFlags = [
  ShippingLaneFlag.SAINT_KITTS_AND_NEVIS,
  ShippingLaneFlag.PANAMA,
  ShippingLaneFlag.UKRAINE,
];

export default function ShippingLanesForm({
  formId,
  initialFormValues,
  onColourChange,
  onSubmit,
  onClear,
  laneColour,
  loading,
  success,
  error,
  percentComplete,
}: ShippingLanesFormProps) {
  const laneRef = useRef<ShippingLaneFormValues | null>(initialFormValues);

  const submit = (values: ShippingLaneFormValues) => {
    laneRef.current = values;

    const extent = [
      parseFloat(values.minLongitude!),
      parseFloat(values.maxLongitude!),
      parseFloat(values.minLatitude!),
      parseFloat(values.maxLatitude!),
    ] as ShippingLaneRequestBody['extent'];
    onSubmit({
      extent,
      filters: {
        flag: [values.flag],
        shiptype: [values.shipType], // shipping lanes filter ship types as `shiptype` instead of `shipType`
      },
      resolution: values.resolution,
      date: dateFns.addWeeks(new Date(), -2).toISOString(),
      ais_data_type: 'global',
    });
  };

  const formik = useFormik<ShippingLaneFormValues>({
    initialValues: initialFormValues,
    onSubmit: submit,
    validationSchema,
    validateOnMount: true,
  });

  useEffect(() => {
    if (!success) {
      // success is only false after the user has selected clear
      // we want the form reset when the user has selected clear
      formik.setValues(initialFormValues);
    }
  }, [success]);

  const onExtentSubmit = (lngLat: mapboxgl.LngLat[]) => {
    const [p1, p2] = lngLat;
    const minLongitude = Math.min(p1.lng, p2.lng);
    const maxLongitude = Math.max(p1.lng, p2.lng);
    const minLatitude = Math.min(p1.lat, p2.lat);
    const maxLatitude = Math.max(p1.lat, p2.lat);
    formik.setValues({
      ...formik.values,
      minLongitude: neatDisplayNumber(minLongitude),
      maxLongitude: neatDisplayNumber(maxLongitude),
      minLatitude: neatDisplayNumber(minLatitude),
      maxLatitude: neatDisplayNumber(maxLatitude),
    });
    setShippingLaneOutline(
      formId,
      GeoHelper.extentToRect([
        minLongitude,
        maxLongitude,
        minLatitude,
        maxLatitude,
      ])
    );
  };

  const onResolutionChange = (e: SelectChangeEvent<ShippingLaneResolution>) => {
    formik.setValues({
      ...formik.values,
      resolution: e.target.value as ShippingLaneResolution,
    });
  };

  const onShipTypeChange = (e: SelectChangeEvent<ShippingLaneShipType>) => {
    const value = e.target.value as ShippingLaneShipType;
    const isResolutionChangeNeeded =
      formik.values.resolution === ShippingLaneResolution.LOW &&
      value !== ShippingLaneShipType.ALL;

    formik.setValues({
      ...formik.values,
      ...(isResolutionChangeNeeded
        ? { resolution: ShippingLaneResolution.MEDIUM }
        : {}),
      shipType: value,
    });
  };

  const onFlagChange = (e: SelectChangeEvent<ShippingLaneFlag>) => {
    const value = e.target.value as ShippingLaneFlag;
    const isResolutionChangeNeeded =
      formik.values.resolution === ShippingLaneResolution.LOW &&
      noLowResFlags.includes(value);

    formik.setValues({
      ...formik.values,
      ...(isResolutionChangeNeeded
        ? { resolution: ShippingLaneResolution.MEDIUM }
        : {}),
      flag: value,
    });
  };

  const isLowResAvailable = useMemo(
    () =>
      formik.values.shipType === ShippingLaneShipType.ALL &&
      !noLowResFlags.includes(formik.values.flag),
    [formik.values.flag, formik.values.shipType]
  );

  const shipTypeOptions = useMemo(() => {
    const options = [
      {
        value: ShippingLaneShipType.ALL,
        name: 'All',
        flags: [],
      },
      {
        value: ShippingLaneShipType.CARGO,
        name: 'Cargo',
        flags: [ShippingLaneFlag.RUSSIA],
      },
      {
        value: ShippingLaneShipType.FISHING,
        name: 'Fishing',
        flags: [ShippingLaneFlag.UNITED_KINGDOM],
      },
      {
        value: ShippingLaneShipType.PASSENGER_SHIP,
        name: 'Passenger Ship',
        flags: [],
      },
      {
        value: ShippingLaneShipType.PLEASURE_CRAFT,
        name: 'Pleasure Craft',
        flags: [],
      },
      {
        value: ShippingLaneShipType.TANKER,
        name: 'Tanker',
        flags: [ShippingLaneFlag.RUSSIA],
      },
    ];

    return options.filter((option) => {
      const isAvailableForFlag = option.flags.includes(formik.values.flag);

      return (
        option.value === ShippingLaneShipType.ALL ||
        formik.values.flag === ShippingLaneFlag.ALL ||
        isAvailableForFlag
      );
    });
  }, [formik.values.flag]);

  const flagOptions = useMemo(() => {
    const options = [
      { value: ShippingLaneFlag.ALL, name: 'All', shipTypes: [] },
      { value: ShippingLaneFlag.CHINA, name: 'China', shipTypes: [] },
      { value: ShippingLaneFlag.PANAMA, name: 'Panama', shipTypes: [] },
      {
        value: ShippingLaneFlag.RUSSIA,
        name: 'Russia',
        shipTypes: [ShippingLaneShipType.CARGO, ShippingLaneShipType.TANKER],
      },
      {
        value: ShippingLaneFlag.SAINT_KITTS_AND_NEVIS,
        name: 'Saint Kitts and Nevis',
        shipTypes: [],
      },
      { value: ShippingLaneFlag.UKRAINE, name: 'Ukraine', shipTypes: [] },
      {
        value: ShippingLaneFlag.UNITED_KINGDOM,
        name: 'United Kingdom of Great Britain and Northern Ireland',
        shipTypes: [ShippingLaneShipType.FISHING],
      },
    ];

    return options.filter((option) => {
      const isAvailableForShipType = option.shipTypes.includes(
        formik.values.shipType
      );

      return (
        option.value === ShippingLaneFlag.ALL ||
        formik.values.shipType === ShippingLaneShipType.ALL ||
        isAvailableForShipType
      );
    });
  }, [formik.values.shipType]);

  return (
    <FormikProvider value={formik}>
      <Stack spacing={2} useFlexGap>
        <Stack direction="row" spacing={2}>
          <MapLatLongPicker
            onMapClick={onExtentSubmit}
            drawBox
            buttonText="Draw boundary"
          />
          <ColourPicker
            type="rgb"
            colour={laneColour}
            onChange={onColourChange}
          />
        </Stack>

        <MuiSelectFieldControlled
          options={[
            ...(isLowResAvailable
              ? [
                  {
                    value: ShippingLaneResolution.LOW,
                    name: 'Low',
                  },
                ]
              : []),
            { value: ShippingLaneResolution.MEDIUM, name: 'Medium' },
            { value: ShippingLaneResolution.HIGH, name: 'High' },
          ]}
          label="Resolution"
          name="resolution"
          ariaLabel="Resolution"
          errors={formik.errors}
          touched={formik.touched}
          value={formik.values.resolution}
          onChange={onResolutionChange}
        />

        <MuiSelectFieldControlled
          options={shipTypeOptions}
          label="Ship Type"
          name="shipType"
          ariaLabel="Ship Type"
          errors={formik.errors}
          touched={formik.touched}
          value={formik.values.shipType}
          onChange={onShipTypeChange}
        />

        <MuiSelectFieldControlled
          options={flagOptions}
          label="Flag"
          name="flag"
          ariaLabel="Flag"
          errors={formik.errors}
          touched={formik.touched}
          value={formik.values.flag}
          onChange={onFlagChange}
        />

        <LoadingButton
          type="submit"
          onClick={() => submit(formik.values)}
          variant="contained"
          loading={!!loading}
          success={!!success}
          // assumption is that if there's maxLongitude, there must also be the other 3 points of the square
          disabled={
            formik.values === laneRef.current ||
            formik.values.maxLongitude === null
          }
        >
          {loading
            ? `Loading Tiles: ${neatDisplayNumber(percentComplete)}%`
            : 'Submit'}
        </LoadingButton>

        <Button fullWidth onClick={onClear} disabled={!error && !success}>
          Clear query
        </Button>
      </Stack>
    </FormikProvider>
  );
}

import { Clear, Search } from '@mui/icons-material';
import { IconButton, Input } from '@mui/material';
import './simple-search-bar.scss';

interface SimpleSearchBarProps {
  handleChange: (value: string) => void;
  filterValue: string;
  placeholder?: string;
  classNames?: string;
  dataTestId?: string;
}

const defaultProps = {
  placeholder: 'Filter the table...',
  classNames: '',
  dataTestId: 'simple-search-bar',
};

function SimpleSearchBar(props: SimpleSearchBarProps) {
  const { handleChange, filterValue, placeholder, dataTestId, classNames } =
    props;

  const formattedFilterValue = filterValue.trim();

  return (
    <Input
      fullWidth
      disableUnderline
      inputProps={{
        'data-testid': dataTestId,
      }}
      sx={{
        marginLeft: '1rem',
      }}
      endAdornment={
        <IconButton
          title={filterValue.trim().length > 0 ? 'Remove filter text' : ''}
          onClick={() => handleChange('')}
          data-testid="delete-filter-text"
          sx={{
            pointerEvents: filterValue.trim().length > 0 ? 'unset' : 'none',
          }}
        >
          {formattedFilterValue.length > 0 ? <Clear /> : <Search />}
        </IconButton>
      }
      placeholder={placeholder}
      className={`${classNames}`}
      onChange={(event) => handleChange(event.target.value)}
      value={filterValue}
    />
  );
}

SimpleSearchBar.defaultProps = defaultProps;

export default SimpleSearchBar;

import {
  Incident,
  MapIncident,
} from '../maritime-menu-options/incidents-panel/incident.model';

// eslint-disable-next-line import/prefer-default-export
export const positionDefined = (incident: Incident): incident is MapIncident =>
  !!incident.position;

// Tell typescipt that:
// * If the type:Point then cast it as such
// * although coordinates: number[] and coordinates: [number,number] are technically different, there will only ever be 2 entries
export const assertIsPointFeature = (
  feature: GeoJSON.Feature
): feature is GeoJSON.Feature & {
  geometry: GeoJSON.Point & { coordinates: [number, number] };
} => feature.geometry.type === 'Point';

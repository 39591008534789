import { Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useEffect } from 'react';
import MuiFormikTextField from '../../common-components/form-fields/mui-formik-text-field';

interface FormHandlerProps {
  formik: FormikProps<any>;
}

function SinglePointRegionForm({ formik }: FormHandlerProps) {
  const { inputLong, inputLat } = formik.values;

  useEffect(() => {
    if (inputLong === undefined || inputLat === undefined) {
      formik.setFieldValue('inputLong', `00° 00' 00" E`);
      formik.setFieldValue('inputLat', `00° 00' 00" N`);
    }
  }, []);

  return (
    <Grid container columnSpacing={2} data-testid="singlepoint-drawing">
      <Grid item xs={6}>
        <Typography sx={{ fontSize: '1rem', textAlign: 'right' }}>
          Latitude
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography sx={{ fontSize: '1rem', textAlign: 'right' }}>
          Longitude
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <MuiFormikTextField
          label="Longitude"
          hiddenLabel
          name="inputLong"
          ariaLabel="longitude-input"
          size="small"
          sx={{ maxHeight: '10%' }}
          data-testid="longitude-field"
        />
      </Grid>

      <Grid item xs={6}>
        <MuiFormikTextField
          label="Latitude"
          hiddenLabel
          name="inputLat"
          ariaLabel="latitude-input"
          size="small"
          sx={{ maxHeight: '10%' }}
          data-testid="latitude-field"
        />
      </Grid>
    </Grid>
  );
}

export default SinglePointRegionForm;

import mapboxgl from 'mapbox-gl';
import { nsTheme } from '../../../theme';
import MapHelpers from '../../map.utils';
import MapLayerManager from '../map-layer-manager.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

function addLineLayer(
  layer: MapLayer | string,
  paint: mapboxgl.LinePaint = {},
  groupLayerid?: string
) {
  const { colourScheme } = nsTheme;
  // eslint-disable-next-line no-new
  new Promise((resolve) => {
    if (groupLayerid) {
      MapLayerManager.AddLayerinGroups(
        {
          id: layer,
          type: 'line',
          source: layer,
          layout: {
            visibility: MapLayerVisibility.NOT_VISIBLE,
          },
          paint: {
            ...paint,
            'line-color': colourScheme?.areaTint,
          },
        },
        [groupLayerid]
      );
    } else {
      MapHelpers.addLayer({
        id: layer,
        type: 'line',
        source: layer,
        layout: {
          visibility: MapLayerVisibility.NOT_VISIBLE,
        },
        paint: {
          ...paint,
          'line-color': colourScheme?.areaTint,
        },
      });
    }
    resolve(MapHelpers);
  });
}

export default addLineLayer;

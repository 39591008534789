/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useAppSelector, useMobile } from '../../hooks';
import './apply-setup-bar.scss';

interface ActivateFadeOutProps {
  setFadeOut: (arg0: boolean) => void;
  setFadeOutComplete: (arg0: boolean) => void;
}

const activateFadeOut = (props: ActivateFadeOutProps) => {
  const { setFadeOut, setFadeOutComplete } = props;
  setTimeout(() => {
    setFadeOut(true);
  }, 3000);
  setTimeout(() => {
    setFadeOutComplete(true);
  }, 3950); // 50 ms shorter than the CSS transition duration for a grace window
};

function ApplySetupBar() {
  const applySetupBar = useAppSelector((state) => state.applySetupBar);
  const { applySetupBarError, applySetupBarNumber } = applySetupBar;
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeOutComplete, setFadeOutComplete] = useState(false);
  const isMobile = useMobile();

  if (fadeOutComplete) {
    return null;
  }

  let iconElement = <>Empty</>;
  let message = '';
  if (applySetupBarNumber === 99) {
    iconElement = (
      <ClipLoader
        size={20}
        data-testid="apply-setup-bar-container-icon"
        cssOverride={{ margin: 'auto' }}
      />
    );
    message = 'Downloading saved setup...';
  } else if (applySetupBarNumber <= 0 && !applySetupBarError) {
    iconElement = (
      <FontAwesomeIcon
        className="apply-setup-bar-container-icon"
        icon={faCircleCheck}
        size="xl"
        data-testid="apply-setup-bar-container-icon"
      />
    );
    message = 'Saved setup applied successfully';
    activateFadeOut({ setFadeOut, setFadeOutComplete });
  } else if (applySetupBarError) {
    message = 'Error applying saved setup';
    iconElement = (
      <FontAwesomeIcon
        className="apply-setup-bar-container-icon"
        icon={faTriangleExclamation}
        size="xl"
        data-testid="apply-setup-bar-container-icon"
      />
    );
    activateFadeOut({ setFadeOut, setFadeOutComplete });
  } else if (applySetupBarNumber >= 1 && !applySetupBarError) {
    iconElement = (
      <ClipLoader
        size={20}
        data-testid="apply-setup-bar-container-icon"
        cssOverride={{ margin: 'auto' }}
      />
    );
    message = `Loading ${applySetupBarNumber} item${
      applySetupBarNumber > 1 ? 's' : ''
    } from saved setup...`;
  }

  return (
    <div
      data-testid="apply-setup-bar-container"
      className={classNames('apply-setup-bar-container ', {
        'fade-out': fadeOut,
        mobile: isMobile,
      })}
    >
      {iconElement}
      <span
        data-testid="apply-setup-bar-container-message"
        className="apply-setup-bar-container-message"
      >
        {message}
      </span>
    </div>
  );
}

export default ApplySetupBar;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  AccessTimeOutlined,
  LanguageOutlined,
  SvgIconComponent,
} from '@mui/icons-material';
import MedicalServicesOutlined from '@mui/icons-material/MedicalServicesOutlined';
import PhoneOutlined from '@mui/icons-material/PhoneOutlined';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import DmsCoordinates from 'dms-conversion';
import { getRiskIntelligencePort } from '../../../../api';
import ErrorPanel from '../../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../../common-components/loading-panel/loading-panel';
import RiFurtherInformationFooter from '../../../../common-components/ri-further-information-footer/ri-further-infrormation-footer';
import { setSelectedEntityThreatAssessmentInfo } from '../../../../dossiers_and_panels/threat-assessments/threat-assessment.slice';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import useAccessControl from '../../../../hooks/access-control/useAccessControl';
import {
  EMenuItems,
  setSelectedOption,
  setThreatAssessmentOpen,
} from '../../../../main-menu/menu.slice';
import { addHistoryItem } from '../../../../nav-history.slice';
import {
  CONTACTS,
  EMERGENCY,
  setSelectedPort,
} from '../../../../state/ports/ports.slice';
import { getCountryRegion } from '../../../../utils/incidents-helpers';
import { isExpandedId } from '../../../../utils/risk-intelligence-helpers';
import {
  isValidUrl,
  removeLineBreaksAndTrim,
} from '../../../../utils/text-formatting.utils';
import { setIncidentFilters } from '../../../incidents-panel/incident-panel.slice';
import { ExpandedRIPort, Port, isExpandedPort } from '../../world-ports.model';
import './ri-tab.scss';

interface RITabProps {
  port: Port;
  setTab: (tab: typeof CONTACTS | typeof EMERGENCY) => void;
}

const Buttons: PortButtons[] = [
  { title: CONTACTS, icon: PhoneOutlined },
  { title: EMERGENCY, icon: MedicalServicesOutlined },
];

interface PortButtons {
  title: typeof EMERGENCY | typeof CONTACTS;
  icon: SvgIconComponent;
}

const makeRITable = (port: Port & { ri: ExpandedRIPort }) => {
  // calculate position from in degrees, minutes and seconds
  const { latitude, longitude } = port.ri?.position || {};
  const dmsCoords = new DmsCoordinates(latitude, longitude);
  const { dmsArrays } = dmsCoords;

  const [dLong, mLong, sLong, nsewLong] = dmsArrays.longitude;
  const [dLat, mLat, sLat, nsewLat] = dmsArrays.latitude;

  const position = `
  ${Math.ceil(dLat)}° ${Math.ceil(mLat)} ${Math.ceil(sLat)}" ${nsewLat}
  ${Math.ceil(dLong)}° ${Math.ceil(mLong)} ${Math.ceil(sLong)}" ${nsewLong}
  `;

  return (
    <Grid container rowGap={1}>
      <Grid container>
        <Grid item xs>
          <Typography variant="subtitle2" align="left">
            Region
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2" align="right">
            {port.ri.region.title}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Typography variant="subtitle2" align="left">
            UN/LOCODE
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2" align="right">
            {port.ri.unlocode}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Typography variant="subtitle2" align="left" noWrap>
            PortRisk Assessment Level
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2" align="right">
            {port.ri.pal}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Typography variant="subtitle2" align="left">
            ISPS Security Level
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2" align="right">
            {port.ri.isps}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Typography variant="subtitle2" align="left">
            Position
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2" align="right" noWrap>
            {position}
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Typography variant="subtitle2" align="left">
            World Port Index Id
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="body2" align="right" noWrap>
            {port.ri.wpi}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

function RITab({ port, setTab }: RITabProps) {
  const { canAccessPortHeadline } = useAccessControl();
  const { selectedCountry } = useAppSelector((state) => state.countries);
  const dispatch = useAppDispatch();
  // Used to avoid repeated RI calls
  const [portId, setPortId] = useState<string | null>(null);
  const [error, setError] = useState<true | null>(null);

  useEffect(() => {
    // Cannot make RI call if no RI ID
    if (!port.ri?.id) {
      return;
    }
    // the ports/{id} call will mutate the port object, so need this catch to ensure we don't request twice
    if (portId === port.WPI) {
      return;
    }
    setPortId(port.WPI);
    getRiskIntelligencePort(port.ri.id)
      .then((data) => {
        dispatch(setSelectedPort({ ...port, ri: data }));
      })
      .catch((err) => {
        setError(err.message);
      });
  }, [port]);

  if (error) {
    return <ErrorPanel message="Error Loading Specific Port Info" />;
  }

  const openThreatAssessment = () => {
    if (port.ri && isExpandedPort(port.ri)) {
      dispatch(
        setSelectedEntityThreatAssessmentInfo({
          id: port.ri.threatAssessment,
          title: port.NAME,
        })
      );
      dispatch(addHistoryItem({ type: 'port', properties: port }));
      dispatch(setThreatAssessmentOpen(true));
    }
  };

  if (port.ri) {
    if (isExpandedPort(port.ri)) {
      return (
        <Box sx={{ bgColor: 'primary.main' }} className="ri-tab-content">
          <Box sx={{ p: ' 0.2rem 1rem' }}>
            {makeRITable(port as Port & { ri: ExpandedRIPort })}
          </Box>
          {canAccessPortHeadline && removeLineBreaksAndTrim(port.ri.headline) && (
            <Typography
              align="left"
              variant="body2"
              sx={{
                padding: '1em 1.25em',
                fontWeight: '700',
                bgcolor: 'primary.light',
              }}
            >
              {removeLineBreaksAndTrim(port.ri.headline)}
            </Typography>
          )}
          <Button
            sx={{
              bgColor: 'primary.main',
              borderRadius: '1.5rem',
              borderColor: 'primary.dark',
              color: 'background.button',
              m: '10px',
            }}
            variant="outlined"
            onClick={() => {
              if (!port.ri) {
                return;
              }
              const countryId = isExpandedId(port.ri.country)
                ? port.ri.country.id
                : port.ri.country;
              getCountryRegion(countryId, selectedCountry).then((regionId) => {
                if (!regionId) {
                  return;
                }
                dispatch(addHistoryItem({ type: 'port', properties: port }));
                dispatch(setSelectedOption(EMenuItems.INCIDENTS));
                dispatch(
                  setIncidentFilters({
                    regions: [regionId],
                  })
                );
              });
            }}
            data-testid="region-incidents-button"
          >
            View Region Incidents
          </Button>
          <ButtonGroup className="details-buttons">
            {Buttons.map((button) => {
              const Icon = button.icon;
              return (
                <Button
                  className="details-button"
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'secondary.main',
                  }}
                  variant="text"
                  onClick={() => setTab(button.title)}
                >
                  <Icon
                    className="details-button-icon"
                    sx={{
                      backgroundColor: 'primary.dark',
                    }}
                  />
                  {button.title}
                </Button>
              );
            })}
            {isValidUrl(port.ri.website) && (
              <Button
                title="Website"
                className="details-button"
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'secondary.main',
                }}
                variant="text"
                target="_blank"
                href={port.ri.website}
              >
                <LanguageOutlined
                  className="details-button-icon"
                  sx={{ backgroundColor: 'primary.dark' }}
                />
                Website
              </Button>
            )}
            <Box className="details-button" sx={{ color: 'secondary.main' }}>
              <AccessTimeOutlined
                className="details-button-icon"
                sx={{ backgroundColor: 'primary.dark' }}
              />
              {new Date().toLocaleTimeString(undefined, {
                timeZone: port.ri.timeZone,
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Box>
          </ButtonGroup>
          <Stack
            sx={{
              '& > Button': {
                fontSize: '14px',
                fontWeight: 400,
                bgColor: 'primary.main',
                padding: '0.25rem 1rem',
                borderRadius: 0,
                justifyContent: 'flex-start',

                '& hover, & active, & focus': {
                  backgroundColor: 'primary.dark',
                },
              },
            }}
          >
            {port.ri.threatAssessment && (
              <Button onClick={openThreatAssessment}>Threat Assessment</Button>
            )}

            <RiFurtherInformationFooter />
          </Stack>
        </Box>
      );
    }
    // Ri info exists but is not expanded yet
    return <LoadingPanel />;
  }

  return (
    <Box className="ri-tab-content" sx={{ bgColor: 'primary.main' }}>
      No RI Information available for this port.
    </Box>
  );
}

export default RITab;

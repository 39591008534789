import { useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/plugins/captions.css';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
import { RIImage } from '../../models/risk_intelligence.model';
import PlaceholderImage from '../image-placeholder/image-placeholder';
import './image-album-with-lightbox.scss';

export interface ImageAlbumProps {
  images: RIImage[];
  smallImageClassName?: string;
  imagesContainerClassName?: string;
  loading?: boolean;
}

export default function ImageAlbum({
  images,
  smallImageClassName,
  imagesContainerClassName,
  loading,
}: ImageAlbumProps) {
  const [albumOpen, setAlbumOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const smallImageClass = smallImageClassName
    ? `albumSmallImage ${smallImageClassName}`
    : `albumSmallImage`;
  const imagesContainerClass = imagesContainerClassName
    ? `albumImagesContainer ${imagesContainerClassName}`
    : `albumImagesContainer`;

  const slides = images.map((image) => ({
    src: image.url,
    alt: image.description,
    description: `${image.description}
      Source: ${image.source}`,
    width: window.innerWidth * 0.7,
    height: window.innerHeight * 0.7,
  }));
  const lightbox = (
    <Lightbox
      slides={slides}
      plugins={[Captions, Thumbnails]}
      open={albumOpen}
      close={() => setAlbumOpen(false)}
      index={initialSlide}
      controller={{
        closeOnBackdropClick: true,
      }}
      thumbnails={{
        vignette: false,
      }}
      captions={{
        descriptionTextAlign: 'center',
      }}
      styles={{
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        },
        container: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        thumbnailsContainer: {
          background: 'none',
        },
        captionsDescriptionContainer: {
          background: 'rgba(0, 0, 0, 0.4)',
        },
      }}
    />
  );

  if (loading) {
    return (
      <div className={imagesContainerClass}>
        <PlaceholderImage className={smallImageClass} />
      </div>
    );
  }

  if (images.length === 0) {
    return <div />;
  }
  // only one image, simply show it with image-with-zoom
  if (images.length === 1) {
    return (
      <>
        {lightbox}
        <div className={imagesContainerClass}>
          <button
            className="albumImageButton"
            type="button"
            onClick={() => setAlbumOpen(true)}
          >
            <img
              className={smallImageClass}
              src={images[0].url}
              alt={images[0].description}
            />
          </button>
        </div>
      </>
    );
  }
  if (images.length === 2) {
    return (
      <>
        {lightbox}
        <div className={imagesContainerClass}>
          {images.map((image, index) => (
            <button
              className="albumImageButton"
              type="button"
              onClick={() => {
                setAlbumOpen(true);
                setInitialSlide(index);
              }}
            >
              <img
                key={image.id}
                className={smallImageClass}
                src={image.url}
                alt={image.description}
              />
            </button>
          ))}
        </div>
      </>
    );
  }
  if (images.length > 2) {
    return (
      <>
        {lightbox}
        <div className={imagesContainerClass}>
          <button
            className="albumImageButton"
            type="button"
            onClick={() => setAlbumOpen(true)}
          >
            <img
              className={smallImageClass}
              src={images[0].url}
              alt={images[0].description}
            />
          </button>
          <button
            className="albumImageButton text"
            type="button"
            onClick={() => setAlbumOpen(true)}
          >
            <img
              className={smallImageClass}
              src={images[1].url}
              alt={images[1].description}
              style={{ filter: 'brightness(0.4)' }}
            />
            <span>{images.length - 1} more images...</span>
          </button>
        </div>
      </>
    );
  }
  // mathematically, you can't get here, but typescript doesn't know that
  return <div />;
}

ImageAlbum.defaultProps = {
  smallImageClassName: '',
  imagesContainerClassName: '',
  loading: false,
};

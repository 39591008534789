import { ListItemButton, ListItemText } from '@mui/material';
import * as dateFns from 'date-fns';

interface TimelineDateButtonProps {
  dateAndIndex: {
    date: Date;
    index: number;
  };
  disabled?: boolean;
  selectedDateIndex: number;
  onClick: (index: number, date: Date) => void;
}

export const DATE_BUTTON_WIDTH_PX = 116;

export default function TimelineDateButton(props: TimelineDateButtonProps) {
  const { dateAndIndex, selectedDateIndex, onClick, disabled } = props;
  const { date, index } = dateAndIndex;

  return (
    <ListItemButton
      data-testid="timeline-date-button"
      selected={index === selectedDateIndex}
      onClick={() => onClick(index, date)}
      disabled={disabled}
      sx={{
        width: `${DATE_BUTTON_WIDTH_PX}px`,
        flexGrow: 0, // attempts to fill the space, which we don't want
        '&.Mui-selected': {
          backgroundColor: 'secondary.main',
        },
      }}
    >
      <ListItemText
        primary={dateFns.isToday(date) ? 'Today' : ' '}
        secondary={dateFns.format(date, 'eee d MMM')}
        primaryTypographyProps={{
          align: 'center',
          variant: 'body2',
        }}
        secondaryTypographyProps={{
          align: 'center',
          color: 'text.primary',
        }}
      />
    </ListItemButton>
  );
}

TimelineDateButton.defaultProps = {
  disabled: false,
};

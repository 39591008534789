import { Stack } from '@mui/material';

import { useState } from 'react';
import { RgbColor } from 'react-colorful';
import {
  ShippingLaneFlag,
  ShippingLaneRequestBody,
  ShippingLaneResolution,
  ShippingLaneShipType,
  getShippingLanes,
} from '../../api/shipping-lanes';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import {
  addShippingTileLayers,
  clearShippingLaneOutline,
  deleteShippingLaneTileLayers,
} from '../../map/map-layer-manager/shipping-lane-utils/add-shipping-lane-layers';
import ShippingLanesForm, {
  ShippingLaneFormValues,
} from './shipping-lanes-form';
import { createBitmaps, getGlobalMax } from './shipping-lanes.utils';

const defaultFormValues: ShippingLaneFormValues = {
  minLatitude: null,
  maxLatitude: null,
  minLongitude: null,
  maxLongitude: null,
  flag: ShippingLaneFlag.ALL,
  shipType: ShippingLaneShipType.ALL,
  resolution: ShippingLaneResolution.HIGH,
};

interface ShippingLanesFormContainerProps {
  formId: string;
}

function ShippingLanesFormContainer({
  formId,
}: ShippingLanesFormContainerProps) {
  const [initialFormValues, setInitialFormValues] =
    useState<ShippingLaneFormValues>(defaultFormValues);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [percentComplete, setPercentComplete] = useState(0);
  const [laneColour, setLaneColour] = useState<RgbColor>({
    r: 255,
    g: 0,
    b: 0,
  });

  const clearMapLayers = () => {
    deleteShippingLaneTileLayers(formId);
    clearShippingLaneOutline(formId);
  };

  const onClear = () => {
    setInitialFormValues(defaultFormValues);
    setSuccess(false);
    setError(null);
    clearMapLayers();
  };

  const onColourChange = (colour: RgbColor) => {
    setLaneColour(colour);
  };

  const onSubmit = async (values: ShippingLaneRequestBody) => {
    deleteShippingLaneTileLayers(formId);
    setLoading(true);
    setError(null);
    setPercentComplete(0);
    try {
      // getShippingLanes takes a callback function that is called for each tile once the results are ready
      const finalResult = await getShippingLanes(
        values,
        (e, _result, tilesToProcess, tilesProcessed) => {
          if (e) {
            setError(e);
            return;
          }
          setPercentComplete((tilesProcessed / tilesToProcess) * 100);
        }
      );
      setSuccess(true);
      setLoading(false);

      const globalMax = getGlobalMax(finalResult.results);
      const allBitmaps = finalResult.results.map((result) =>
        createBitmaps(result, globalMax, laneColour)
      );
      addShippingTileLayers(allBitmaps, formId);
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
    }
  };

  return (
    <Stack
      data-testid="shipping-lanes-form-container"
      spacing={1}
      sx={{ padding: 2 }}
    >
      <ShippingLanesForm
        formId={formId}
        onColourChange={onColourChange}
        onSubmit={onSubmit}
        onClear={onClear}
        initialFormValues={initialFormValues}
        laneColour={laneColour}
        loading={loading}
        success={success}
        error={error}
        percentComplete={percentComplete}
      />
      {error && <ErrorPanel message={error} />}
    </Stack>
  );
}

export default ShippingLanesFormContainer;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Measurement } from './measuring-tool/measurement.model';
import { RangeRingPoint } from './range-rings/range-ring.model';

export enum ToolsPanelViewState {
  MENU = 'tools-menu',
  MEASURING_TOOL = 'measuring-tool',
  NEARBY_VESSELS = 'nearby-vessels',
  RANGE_RINGS = 'range-rings',
  WEATHER = 'weather',
  SHIPPING_LANES = 'shipping-lanes',
}

interface ToolsPanelState {
  viewState: ToolsPanelViewState;
  rangeRings: {
    points: RangeRingPoint[];
  };
  measuringTool: {
    measurements: Measurement[];
  };
}

export const INITIAL_TOOLS_PANEL_STATE: ToolsPanelState = {
  viewState: ToolsPanelViewState.MENU,
  rangeRings: {
    points: [],
  },
  measuringTool: {
    measurements: [],
  },
};

const ToolsPanelSlice = createSlice({
  name: 'tools',
  initialState: INITIAL_TOOLS_PANEL_STATE,
  reducers: {
    setToolsPanelViewState: (
      state,
      action: PayloadAction<ToolsPanelState['viewState']>
    ) => {
      state.viewState = action.payload;
    },
    setRangeRingPoints: (state, action: PayloadAction<RangeRingPoint[]>) => {
      state.rangeRings.points = action.payload;
    },
    setMeasurements: (state, action: PayloadAction<Measurement[]>) => {
      state.measuringTool.measurements = action.payload;
    },
  },
});

export const { setToolsPanelViewState, setRangeRingPoints, setMeasurements } =
  ToolsPanelSlice.actions;

export default ToolsPanelSlice.reducer;

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuItem, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useField } from 'formik';

interface SelectFieldProps {
  label: string;
  name: string;
  placeholder?: string;
  options: { value: string; name: string }[];
  ariaLabel: string;
  classNames?: string;
  disabled?: boolean;
}

function MuiSelectField(props: SelectFieldProps) {
  const { label, name, placeholder, options, ariaLabel, disabled } = props;
  const [field, meta] = useField(name);

  const hasError = Boolean(meta.error && meta.touched);

  const menuProps = {
    MenuListProps: {
      sx: {
        backgroundColor: 'primary.main',
      },
    },
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="formik-select-fullwidth-label">{label}</InputLabel>
        <Select
          input={<OutlinedInput label={label} />}
          inputProps={{ 'data-testid': 'content-input' }}
          {...field}
          fullWidth
          labelId="formik-select-fullwidth-label"
          label={label}
          aria-label={ariaLabel}
          error={hasError}
          disabled={disabled}
          placeholder={placeholder}
          MenuProps={menuProps}
        >
          {placeholder && (
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

MuiSelectField.defaultProps = {
  placeholder: '',
  classNames: '',
  disabled: false,
};

export default MuiSelectField;

import mapboxgl from 'mapbox-gl';

namespace MapLayerPaint {
  export const DEFAULT_HEATMAP_GRADIENT = [
    0,
    'rgba(13,8,135,0)',
    0.14,
    'rgb(84,2,163)',
    0.29,
    'rgb(139,10,165)',
    0.43,
    'rgb(185,50,137)',
    0.57,
    'rgb(219,92,104)',
    0.71,
    'rgb(244,136,73)',
    0.86,
    'rgb(254,188,43)',
    1,
    'rgb(240,249,33)',
  ];

  export const DEFAULT_HEATMAP: mapboxgl.HeatmapPaint = {
    'heatmap-intensity': 1,
    'heatmap-radius': {
      stops: [
        [1, 10],
        [22, 20],
      ],
    },
    // heatmap from purple to yellow
    // purple - least dense areas
    // yellow - most dense areas
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      ...DEFAULT_HEATMAP_GRADIENT,
    ],
    // decrease opacity to transition into the circle layer
    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      8,
      1, // at Zoom level 8, it's full opaque.
      9,
      0, // at Zoom level 9 it's complete transparent
    ],
  };

  // TODO match NS colours
  const colourPalette = ['#fbff00', '#ffa500', '#ff00ff', '#0000ff', '#ff0000'];

  export const VESSEL_HISTORY_JOURNEY = (colourId?: number) => ({
    'line-color': colourPalette[(colourId ?? 0) % colourPalette.length],
    'line-width': 2,
  });

  export const HIGHLIGHTED_LINES: mapboxgl.LinePaint = {
    'line-color': '#F88',
    'line-width': 4,
  };

  export const ROUTE_SHAPE_DEFAULT_PAINT = {
    'fill-color': '#fbb03b',
    'fill-opacity': 0.3,
  };

  export const SAR_HOVER_DEFAULT_PAINT = {
    'fill-opacity': 0.7,
  };

  const BOUNDARY_SHAPE_DEFAULT_PAINT = {
    'fill-color': 'transparent',
    'fill-opacity': 0.7,
    'fill-outline-color': '#79747e',
  };

  const TTW_BOUNDARY_DEFAULT_PAINT: mapboxgl.FillPaint = {
    'fill-color': '#B04D5E',
    'fill-opacity': 0.7,
    'fill-outline-color': 'transparent',
  };

  export const BOUNDARY_SHAPE = (): Record<
    'default' | string,
    mapboxgl.FillPaint
  > => ({
    default: {
      'fill-color': BOUNDARY_SHAPE_DEFAULT_PAINT['fill-color'],
      'fill-opacity': BOUNDARY_SHAPE_DEFAULT_PAINT['fill-opacity'],
      'fill-outline-color': BOUNDARY_SHAPE_DEFAULT_PAINT['fill-outline-color'],
    },
    EEZ: {
      'fill-color': BOUNDARY_SHAPE_DEFAULT_PAINT['fill-color'],
      'fill-opacity': BOUNDARY_SHAPE_DEFAULT_PAINT['fill-opacity'],
      'fill-outline-color': BOUNDARY_SHAPE_DEFAULT_PAINT['fill-outline-color'],
    },
    TTW: TTW_BOUNDARY_DEFAULT_PAINT,
    JWC: {
      'fill-color': '#4da866',
      'fill-opacity': 0.2,
      'fill-outline-color': 'transparent',
    },
  });

  export const DRAWING_SHAPE_DEFAULT_PAINT = {
    'fill-color': '#fbb03b',
    'fill-opacity': 0.3,
  };

  export const DRAWING_CIRCLE_DEFAULT_PAINT = {
    'circle-color': '#fbb03b',
    'circle-opacity': 0.3,
    'circle-radius': 10,
  };

  export const RI_MARITIME_AREAS_SHAPE_DEFAULT_PAINT = {
    'fill-color': '#203058',
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.8,
      0.3,
    ],
  };

  export const DRAWING_LINE_DEFAULT_PAINT = {
    'line-color': '#fbb03b',
    'line-width': 3,
  };

  export const RANGE_RING_POINT_PAINT = {
    'circle-radius': 6,
    'circle-color': '#ffffff',
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
  };

  export const MEASUREMENT_LINE_PAINT = {
    'line-color': '#ffffff',
    'line-width': 3,
  };

  export const MEASUREMENT_POINT_PAINT = {
    'circle-radius': 6,
    'circle-color': '#ffffff',
    'circle-stroke-width': 1,
    'circle-stroke-color': '#000000',
  };

  export const TEMP_DRAWING_POINT_PAINT = {
    ...MEASUREMENT_POINT_PAINT,
    'circle-opacity': 0.4,
  };

  export const TEMP_DRAWING_LINE_PAINT = {
    ...MEASUREMENT_LINE_PAINT,
    'line-opacity': 0.4,
    'line-width': 3,
    'line-dasharray': [0, 1.5],
  };
}

export default MapLayerPaint;

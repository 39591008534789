import { Incident } from '../../../maritime-menu-options/incidents-panel/incident.model';
import {
  getIncidentRegionTitleById,
  getIncidentTypeTitleById,
  incidentDisplayDate,
} from '../../../utils/incidents-helpers';

function incidentInfoPopUpContent(
  feature: mapboxgl.MapboxGeoJSONFeature
): { label: string; value: string }[] | null {
  if (feature) {
    const properties = feature.properties as Incident;
    const { title, type, region } = properties;
    const items = [
      {
        label: 'Incident Title',
        value: title,
      },
      {
        label: 'Type',
        value: getIncidentTypeTitleById(type) || 'Incident',
      },
      {
        label: 'Region',
        value: getIncidentRegionTitleById(region) || 'N/A',
      },
    ];

    const displayDate = incidentDisplayDate(properties);
    if (displayDate) {
      items.push({
        label: 'Date',
        value: displayDate,
      });
    }
    return items;
  }
  return null;
}

export default incidentInfoPopUpContent;

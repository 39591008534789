import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';

import {
  Capacity,
  Design,
  Dimensions,
  History,
  Propulsion,
  Registration,
  VesselAndTradingType,
} from '../../../../models/maritime-ais-api';
import VesselInformationCard from '../vessel-information-card/vessel-information-card';

interface VesselInformationListProps {
  label: string;
  labels: string[];
  information:
    | Capacity
    | Design
    | Dimensions
    | History
    | Propulsion
    | Registration
    | VesselAndTradingType;
  // eslint-disable-next-line react/require-default-props
  additionalHTML?: any;
}

function VesselInformationList(props: VesselInformationListProps) {
  const { label, labels, information, additionalHTML } = props;

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      <ListItemButton disableGutters onClick={handleClick}>
        <ListItemText primary={label} />
        {open ? <ArrowDropDown /> : <ArrowRight />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <VesselInformationCard
              labels={labels}
              information={information}
              additionalHTML={additionalHTML}
            />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}

export default VesselInformationList;

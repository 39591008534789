/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LocationFilter = {
  coordinates: {
    longitude: number;
    latitude: number;
  } | null;
  layers: string[];
};

interface RoutePanelState {
  locationFilter: LocationFilter;
  locationFilterLoading: boolean;
  locationFilterError: boolean;
  searchFilterValue: string;
}

export const INITIAL_ROUTES_PANEL_STATE: RoutePanelState = {
  locationFilter: {
    coordinates: null,
    layers: [],
  },
  locationFilterLoading: false,
  locationFilterError: false,
  searchFilterValue: '',
};

const RoutesPanelSlice = createSlice({
  name: 'routesPanel',
  initialState: INITIAL_ROUTES_PANEL_STATE,
  reducers: {
    setSearchFilterValue: (state, action: PayloadAction<string>) => {
      state.searchFilterValue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const { setSearchFilterValue } = RoutesPanelSlice.actions;

export default RoutesPanelSlice.reducer;

import { WbSunny } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  getLocationDataDAAS,
  getVesselDAAS,
  responseToLocationArray,
} from '../../../api/vessels';
import { fetchWeather } from '../../../api/weather';
import { BackOneButton } from '../../../common-components/breadcrumb/breadcrumb';
import ErrorPanel from '../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import { setCentreDate } from '../../../common-components/timeline/timeline.slice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useAccessControl from '../../../hooks/access-control/useAccessControl';
import { EMenuItems, setSelectedOption } from '../../../main-menu/menu.slice';
import { decimalToDMS } from '../../../map/map-controls.utils';
import clearWeatherMapLayers from '../../../map/map-layer-manager/weather-utils/clear-weather-layers';
import setMapPoints from '../../../map/map-layer-manager/weather-utils/set-weather-points';
import { HistoricVesselPoint } from '../../../maritime-menu-options/history-panel/historic-vessel-point.model';
import { setVesselData } from '../../../maritime-menu-options/history-panel/history-panel.slice';
import {
  ToolsPanelViewState,
  setToolsPanelViewState,
} from '../../../maritime-menu-options/tools-panel/tools-panel.slice';
import {
  setError,
  setSuccess,
  setWeatherFormValues,
  setLoading as setWeatherLoading,
  setWeatherResults,
} from '../../../maritime-menu-options/weather-panel/weather-panel.slice';
import { VesselData } from '../../../models/maritime-ais-api';
import { Vessel } from '../../../models/vessel.model';
import { TimeSeriesLineGraph } from '../../../reporting/charts';
import DateTimeHelpers, {
  formatDateTime,
} from '../../../utils/date-time-helpers.utils';
import VesselInformationLabel from './vessel-information-card/vessel-information-label/vessel-information-label';
import VesselInformationList from './vessel-information-list/vessel-information-list';
import {
  BUILD_HISTORY_LABELS,
  CAPACITY_LABELS,
  DESIGN_LABELS,
  DIMENSION_LABELS,
  ENGINE_LABELS,
  REGISTRATION_LABELS,
} from './vessel-information.labels';
import './vessel-information.scss';

type AxisKey = {
  key: string;
  label?: string;
};

interface RenderVessel {
  vesselInfo: VesselData | null;
}

interface RenderLineGraphProps extends RenderVessel {
  title: string;
  dataKeys: AxisKey[];
  yLabel: string;
  yAxisMin?: number;
  yAxisMax?: number;
  xAxisMin?: number;
  xAxisMax?: number;
}

interface RenderInformationProps extends RenderVessel {
  isDocked: boolean;
  loading: boolean;
}

function RenderVesselInformation({ vesselInfo }: RenderVessel) {
  if (vesselInfo === null) return <LoadingPanel />;

  const { mostRecentVoyage, staticData, embedded } = vesselInfo;
  const { design, propulsion, capacity, registration, history } =
    embedded.extended;

  const etaValue = mostRecentVoyage.eta;
  const formattedDateTime = etaValue ? formatDateTime(etaValue) : 'Unknown';

  return (
    <>
      <Box>
        <VesselInformationLabel
          title="Ship Name"
          className=""
          data={staticData.name}
        />
        <VesselInformationLabel title="Call Sign" data={staticData.callsign} />
        <VesselInformationLabel
          title="Ship Type"
          data={
            staticData.shipType
              ? staticData.shipType
                  .toLowerCase()
                  .replaceAll('_', ' ')
                  .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
              : 'Unknown'
          }
        />
        <VesselInformationLabel
          title="Flag"
          className=""
          data={staticData.flag}
        />
        <VesselInformationLabel title="IMO" data={staticData.imo} />
        <VesselInformationLabel title="MMSI" data={staticData.mmsi} />
        <VesselInformationLabel
          title="Destination"
          className=""
          data={mostRecentVoyage.destination}
        />
        <VesselInformationLabel title="ETA" data={formattedDateTime} />
        <VesselInformationLabel
          title="Draught"
          className=""
          data={`${Number(mostRecentVoyage.draught).toFixed(1).toString()}`}
        />
      </Box>
      <VesselInformationList
        label="Design"
        labels={DESIGN_LABELS}
        information={design}
      />
      <VesselInformationList
        label="Engine"
        labels={ENGINE_LABELS}
        information={propulsion}
      />
      <VesselInformationList
        label="Dimensions"
        labels={DIMENSION_LABELS}
        information={staticData.dimensions}
      />
      <VesselInformationList
        label="Capacity"
        labels={CAPACITY_LABELS}
        information={capacity}
      />
      <VesselInformationList
        label="Registration"
        labels={REGISTRATION_LABELS}
        information={registration}
      />
      <VesselInformationList
        label="Build History"
        labels={BUILD_HISTORY_LABELS}
        information={history}
      />
    </>
  );
}

function RenderLineGraph({
  vesselInfo,
  title,
  dataKeys,
  yLabel,
  yAxisMin,
  yAxisMax,
  xAxisMin,
  xAxisMax,
}: RenderLineGraphProps) {
  const dispatch = useAppDispatch();
  const [vesselPoints, setVesselPoints] = useState<HistoricVesselPoint[]>([]);
  const [searchError, setSearchError] = useState<boolean>(false);
  const currentDate = new Date();
  const lastMonthDate = new Date();
  // currently the furthest date back is about 2 weeks
  lastMonthDate.setDate(currentDate.getDate() - 30);

  const mmsi = vesselInfo?.staticData?.mmsi;

  useEffect(() => {
    if (!mmsi) return;
    getLocationDataDAAS(lastMonthDate, currentDate, [mmsi.toString()], [], '1m')
      .then((response) => {
        // flattened to single array
        const result = responseToLocationArray(response, true);
        setVesselPoints(result?.[0] || []);
      })
      .catch(() => {
        setSearchError(true);
      });
  }, [mmsi]);

  if (searchError) {
    return <ErrorPanel message={`Error loading ${title} graph...`} />;
  }

  const requestDetailedDataFunction = async (min: Date, max: Date) =>
    getLocationDataDAAS(min, max, [mmsi!.toString()], [], '1h').then(
      (response) => responseToLocationArray(response)
    );

  const onDateChange = (date: number) => {
    dispatch(
      setCentreDate({
        alreadyOffset: false,
        date,
      })
    );
  };
  return vesselPoints.length > 0 ? (
    <TimeSeriesLineGraph
      title={title}
      data={[vesselPoints]}
      yAxisKeys={dataKeys}
      xAxisKey="timestamp"
      yLabel={yLabel}
      xAxisMax={xAxisMax}
      xAxisMin={xAxisMin}
      yAxisMax={yAxisMax}
      yAxisMin={yAxisMin}
      requestDetailedDataFunction={requestDetailedDataFunction}
      onDateChange={onDateChange}
    />
  ) : (
    <CircularProgress />
  );
}

RenderLineGraph.defaultProps = {
  xAxisMin: undefined,
  xAxisMax: undefined,
  yAxisMin: undefined,
  yAxisMax: undefined,
};

function RenderSpeedInformation({ vesselInfo }: RenderVessel) {
  return (
    <RenderLineGraph
      vesselInfo={vesselInfo}
      title="Speed"
      dataKeys={[{ key: 'speed' }]}
      yLabel="Speed (Knots)"
    />
  );
}

function RenderCourseHeadingInformation({ vesselInfo }: RenderVessel) {
  return (
    <RenderLineGraph
      vesselInfo={vesselInfo}
      title="Course/Heading"
      dataKeys={[{ key: 'course' }, { key: 'heading' }]}
      yLabel="Degrees"
      yAxisMax={360}
    />
  );
}

function RenderInformation({
  vesselInfo,
  isDocked,
  loading,
}: RenderInformationProps) {
  const { canAccessWeather } = useAccessControl();
  const dispatch = useAppDispatch();

  const selectedVesselsById = useAppSelector(
    (state) => state.vesselDossier.selectedVessels!.byId // At this point we know there'll be an ID
  );
  const selectedVessels = useAppSelector(
    (state) => state.vesselDossier.selectedVessels!.allIds
  );

  const hasMultipleSelectedVessels = selectedVessels.length > 1;
  /**
   * Some MMSI numbers are linked to multiple vessels,
   * so this check just makes sure the selected vessel
   * ID is present in our Redux state.
   */
  const selectedVesselIdIsDefined =
    !!vesselInfo?.vesselId && vesselInfo.vesselId in selectedVesselsById;

  const handleWeatherButtonClick = async () => {
    const { latitude, longitude, timestamp } =
      selectedVesselsById[vesselInfo!.vesselId]; // We know an ID will exist at this point

    const endDate = timestamp ? new Date(timestamp) : new Date();
    const oneDayAgo = DateTimeHelpers.subtractHours(endDate, 24).toISOString();

    dispatch(setSelectedOption(EMenuItems.TOOLS));
    dispatch(setToolsPanelViewState(ToolsPanelViewState.WEATHER));

    const weatherRequestValues = {
      location: {
        latitude: decimalToDMS(latitude, 'latitude'),
        longitude: decimalToDMS(longitude, 'longitude'),
      },
      startDate: oneDayAgo,
      endDate: endDate.toISOString(),
    };

    dispatch(setWeatherFormValues(weatherRequestValues));
    dispatch(setWeatherResults(null));
    dispatch(setWeatherLoading(true));
    clearWeatherMapLayers();

    try {
      const response = await fetchWeather(weatherRequestValues);
      setMapPoints(response);
      dispatch(setWeatherResults(response));
      dispatch(setSuccess(true));
    } catch (e) {
      dispatch(setError((e as Error).message));
    }
  };

  return (
    <Box data-testid="vessel-information">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: `${isDocked ? '1.4625em' : '2.24em'}`,
          marginBottom: '0.5em',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {!isDocked && <BackOneButton />}
          <Typography
            sx={{
              fontSize: `${isDocked ? '18px' : '22px'}`,
              textAlign: 'left',
            }}
          >
            General Information
          </Typography>
        </Box>
        {canAccessWeather && selectedVesselIdIsDefined && (
          <IconButton
            data-testid="weather-button"
            sx={{
              color: 'secondary.main',
              marginRight: hasMultipleSelectedVessels ? '0' : '2.5rem', // so there's some spacing between the close button and this icon if they're aligned horizontally
            }}
            onClick={handleWeatherButtonClick}
          >
            <WbSunny />
          </IconButton>
        )}
      </Box>
      <Tabs>
        <TabList
          style={{
            marginBottom: '0.5rem',
            paddingBottom: '1px',
          }}
        >
          <Tab data-testid="info-tab-header">Info</Tab>
          <Tab data-testid="graphs-tab-header">Graphs</Tab>
        </TabList>

        {loading ? (
          <LoadingPanel />
        ) : (
          <>
            <TabPanel data-testid="info-tab-body">
              <RenderVesselInformation vesselInfo={vesselInfo} />
            </TabPanel>

            <TabPanel data-testid="graphs-tab-body">
              <RenderSpeedInformation vesselInfo={vesselInfo} />

              <RenderCourseHeadingInformation vesselInfo={vesselInfo} />
            </TabPanel>
          </>
        )}
      </Tabs>
    </Box>
  );
}

function VesselInformation(props: {
  isDocked: boolean;
  selectedVessel: Vessel | null;
}) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { vesselData } = useAppSelector((state) => state.historyPanel);
  const { isDocked, selectedVessel } = props;
  const ERROR_MESSAGE =
    'Unable to fetch vessel information. Please try again later...';

  // This is called twice due to strict mode when in dev
  useEffect(() => {
    // We set has error false here to reset the error state. If a vessel errors, we leave the dossier open, and then click on another vessel, we want to reset the error state.
    setHasError(false);

    if (selectedVessel?.mmsi) {
      // We already have the vessel data we need, so we don't need to fetch it again
      if (
        vesselData?.staticData.mmsi.toString() ===
        selectedVessel?.mmsi.toString()
      ) {
        return;
      }

      setLoading(true);

      getVesselDAAS(String(selectedVessel?.mmsi))
        .then((response) => {
          dispatch(setVesselData(response));
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((_error) => {
          setHasError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedVessel?.mmsi]);

  return (
    <Box sx={{ marginTop: '-0.75em' }}>
      {selectedVessel && (
        <>
          {hasError && <ErrorPanel message={ERROR_MESSAGE} />}
          {!hasError && (
            <RenderInformation
              vesselInfo={vesselData}
              isDocked={isDocked}
              loading={loading}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default VesselInformation;

/* eslint-disable @typescript-eslint/naming-convention */
import {
  Anchor,
  Business,
  Contacts,
  Home,
  Info,
  Notes,
  People,
  Phone,
  Work,
} from '@mui/icons-material';
import { Alert, Link, List, Typography } from '@mui/material';
import { Box } from '@mui/system';
import classNames from 'classnames';
import { useEffect } from 'react';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import {
  CorrespondentAddress,
  CorrespondentContact,
  CorrespondentContactInformationLabelled,
  CorrespondentContactInformationUnlabelled,
  CorrespondentPortInformation,
} from '../../models/correspondents.model';
import {
  setSelectedCorrespondent,
  setSelectedCorrespondentId,
} from '../../state/correspondents/correspondents.slice';
import DataRow from '../data-row';

function CorrespondentsDossier() {
  const dispatch = useAppDispatch();
  const {
    selectedCorrespondent,
    selectedCorrespondentId,
    correspondents,
    loading,
    error,
  } = useAppSelector((state) => state.correspondents);
  const isMobile = useMobile();
  useEffect(() => {
    if (
      selectedCorrespondentId &&
      !selectedCorrespondent &&
      correspondents &&
      correspondents.length > 0
    ) {
      const newSelectedCorrespondent = correspondents.find(
        (correspondent) =>
          correspondent.uid.toString()! === selectedCorrespondentId
      );
      if (newSelectedCorrespondent) {
        dispatch(setSelectedCorrespondent(newSelectedCorrespondent));
        dispatch(
          setSelectedCorrespondentId(newSelectedCorrespondent.uid.toString()!)
        );
      }
    }
  }, [selectedCorrespondent, selectedCorrespondentId, correspondents]);
  if (!selectedCorrespondent) {
    return null;
  }

  const parseAddress = (address: CorrespondentAddress) => {
    const { line1, line2, line3, city, subdivision, postcode, country } =
      address;

    if (
      [line1, line2, line3, city, subdivision, postcode, country].every(
        (field) => field === null
      )
    ) {
      return 'Unavailable';
    }

    const filledFields = [
      line1,
      line2,
      line3,
      city,
      subdivision,
      postcode,
      country,
    ].filter((field) => field !== null);
    return filledFields.join(',\n');
  };

  enum contactInformationType {
    EMAIL,
    PHONE,
    FAX,
  }

  const generateHref = (type: contactInformationType) => {
    switch (type) {
      case contactInformationType.EMAIL:
        return 'mailto:';
      case contactInformationType.PHONE:
        return 'tel:';
      case contactInformationType.FAX:
        return 'fax:';
      default:
        return '';
    }
  };

  const generateContactBlock = (
    title: string,
    value: string | undefined,
    type: contactInformationType
  ) => (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto auto',
      }}
    >
      <Typography fontSize="0.875rem">{title}</Typography>
      {value ? (
        <Link href={`${generateHref(type)}${value}`}>
          <Typography
            fontSize="0.875rem"
            sx={{
              display: 'flex',
              justifyContent: 'end',
            }}
            variant="body1"
          >
            {value}
          </Typography>
        </Link>
      ) : (
        <Typography
          fontSize="0.875rem"
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          Unknown
        </Typography>
      )}
    </Box>
  );

  const parseContactInformation = (
    contactInformation:
      | CorrespondentContactInformationLabelled
      | CorrespondentContactInformationUnlabelled
      | []
  ) => {
    if (Array.isArray(contactInformation) && contactInformation.length === 0) {
      return 'Unavailable';
    }

    // eslint-disable-next-line no-prototype-builtins
    if (contactInformation.hasOwnProperty('phone')) {
      // Data is unlabelled
      const { email, phone } =
        contactInformation as CorrespondentContactInformationUnlabelled;
      const { out_of_hours: outOfHours, personal } = phone;
      return (
        <>
          {generateContactBlock(
            'Email:',
            email[0],
            contactInformationType.EMAIL
          )}
          {generateContactBlock(
            'Primary Phone:',
            phone.default[0],
            contactInformationType.PHONE
          )}
          {generateContactBlock(
            'Personal Phone:',
            personal[0],
            contactInformationType.PHONE
          )}
          {generateContactBlock(
            'Out of Hours Phone:',
            outOfHours[0],
            contactInformationType.PHONE
          )}
        </>
      );
    }

    // Data is labelled

    const { fax, email, telephone, alternative } =
      contactInformation as CorrespondentContactInformationLabelled;

    return (
      <>
        {generateContactBlock(
          'Email:',
          email?.value,
          contactInformationType.EMAIL
        )}
        {generateContactBlock('Fax:', fax?.value, contactInformationType.FAX)}
        {generateContactBlock(
          'Telephone:',
          telephone?.value,
          contactInformationType.PHONE
        )}
        {generateContactBlock(
          'Alternative Phone:',
          alternative?.value,
          contactInformationType.PHONE
        )}
      </>
    );
  };

  const parsePortInformation = (
    portInformation: CorrespondentPortInformation
  ): string => {
    const { name, country } = portInformation;
    if (!name && !country) {
      return 'Unavailable';
    }
    return `Port Name - ${name}\nCountry - ${country}`;
  };

  const parseContacts = (contacts: CorrespondentContact[]) => {
    if (contacts.length === 0 || !contacts || !Array.isArray(contacts)) {
      return 'Unavailable';
    }

    return contacts.map((contact) => {
      const { title, first_name, last_name, job_title, contact_information } =
        contact;
      return (
        <Box sx={{ mb: '.75rem' }}>
          <Typography variant="body1">
            {title || ''} {first_name} {last_name}
          </Typography>
          <Typography variant="body2">
            {job_title ? `${job_title}` : ''}
          </Typography>
          {parseContactInformation(contact_information)}
        </Box>
      );
    });
  };

  const furtherInfoText = () => (
    <>
      <Typography variant="subtitle2">
        NorthStandard’s correspondents’ details are updated daily. The contact
        details in the ‘correspondents’ area on our website take precedent over
        the contact details provided in this platform. To create your own
        tailored list of correspondents PDF, or to download a list of all our
        Correspondents, please visit:
      </Typography>
      <Link
        href="https://north-standard.com/correspondents"
        target="_blank"
        rel="noopener"
        component="a"
      >
        <Typography variant="subtitle2">
          https://north-standard.com/correspondents
        </Typography>
      </Link>
    </>
  );

  const {
    port_information,
    address,
    name,
    is_lawyer,
    co_party,
    notes,
    contact_information,
    contacts,
  } = selectedCorrespondent;
  return (
    <Box
      data-test-id="correspondents-dossier-container"
      className={classNames({ mobile: isMobile })}
      sx={{ p: 2, overflowY: 'auto', scrollbarWidth: 'thin' }}
    >
      {loading && <LoadingPanel />}
      {error && (
        <Alert data-testid="error-panel" severity="error">
          Error: Unable to fetch Correspondents
        </Alert>
      )}
      {!loading && !error && (
        <List>
          <DataRow
            label="Name"
            value={name}
            icon={<Business />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
          <DataRow
            label="Port Information"
            value={parsePortInformation(port_information)}
            icon={<Anchor />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
          <DataRow
            label="Address"
            value={parseAddress(address)}
            icon={<Home />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
          <DataRow
            label="Lawyer"
            value={is_lawyer ? 'Yes' : 'No'}
            icon={<Work />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
          <DataRow
            label="Co Party"
            value={co_party || 'Unavailable'}
            icon={<People />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
          <DataRow
            label="Company Contact Information"
            value={parseContactInformation(contact_information)}
            icon={<Phone />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
          <DataRow
            label="Contacts"
            value={parseContacts(contacts)}
            icon={<Contacts />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
          <DataRow
            label="Notes"
            value={notes || 'Unavailable'}
            icon={<Notes />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
          <DataRow
            label="Further Information"
            value={furtherInfoText()}
            icon={<Info />}
            primaryTypographyProps={{
              sx: { fontWeight: 'bold', fontSize: '1.25rem' },
            }}
          />
        </List>
      )}
    </Box>
  );
}

export default CorrespondentsDossier;

import MapHelpers from '../../map.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addRasterSource from './add-raster-source';

function addRasterLayer(layer: MapLayer | string, tileSource: string[]) {
  // eslint-disable-next-line no-new
  return new Promise((resolve) => {
    addRasterSource(layer, tileSource);
    MapHelpers.addLayer({
      id: layer,
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
      },
      type: 'raster',
      source: layer,
      minzoom: 0,
      maxzoom: 22,
      paint: {
        'raster-opacity': 0.8,
      },
    });
    resolve(MapHelpers);
  });
}

export default addRasterLayer;

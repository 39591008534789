import { Incident } from '../../../maritime-menu-options/incidents-panel/incident.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapLayer from '../map-layer.enum';

function setIncidentFeatures(
  layerId: MapLayer | string,
  incidents: Incident[]
) {
  const features = GeoHelper.incidentsToGeoJSONFeatures(incidents);
  const geojson = GeoHelper.createGeoJSON(features);

  GeoHelper.setMapboxGeoJSONSourceData(layerId, geojson);
}

export default setIncidentFeatures;

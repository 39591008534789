/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import './ri-maritime-area-card.scss';

interface RIMaritimeAreaCardProps {
  title: string;
  region: string | null | undefined;
  onClick: () => void;
}

function RIMaritimeAreaCard(props: RIMaritimeAreaCardProps) {
  const { title, region, onClick } = props;

  return (
    <ListItem
      data-testid="maritime-area-info-card-container"
      className="maritime-area-info-card-container"
      role="listitem"
      disableGutters
      disablePadding
      sx={{
        width: 'auto',
        padding: 0,
        margin: 0,
        bgColor: 'transparent',
      }}
    >
      <ListItemButton
        data-testid="maritime-area-info-card-button"
        onClick={onClick}
        sx={{
          margin: 0,
          padding: '8px 24px 8px 16px',
          borderTop: 1,
          borderRadius: 0,
          borderColor: 'divider',
          display: 'flex',
          alignSelf: 'stretch',
          bgcolor: 'transparent',
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            fontWeight: '400',
            variant: 'subtitle1',
          }}
          secondaryTypographyProps={{
            variant: 'caption',
          }}
          primary={title}
          secondary={region}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default RIMaritimeAreaCard;

/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import ListPicker from '../../../../common-components/list-picker/list-picker';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  calcDisplayedText,
  listFilterItems,
} from '../../../../utils/incidents-helpers';
import { setIncidentFilters } from '../../incident-panel.slice';
import { PlaceDisplayFilter } from '../../incident.model';

function PlaceFilter() {
  const { filters } = useAppSelector((store) => store.incidentsPanel);
  const dispatch = useAppDispatch();

  const [selectedPlaces, setSelectedPlaces] = useState(() => filters.place);

  useEffect(() => {
    setSelectedPlaces(filters.place);
  }, [filters.place]);

  const onSubmit = () => {
    dispatch(
      setIncidentFilters({
        place: selectedPlaces,
      })
    );
  };

  return (
    <ListPicker
      onSubmit={onSubmit}
      items={listFilterItems(PlaceDisplayFilter)}
      selectedItems={selectedPlaces}
      setSelectedItems={setSelectedPlaces}
      buttonText={calcDisplayedText(
        filters.place,
        'Places',
        PlaceDisplayFilter
      )}
    />
  );
}
export default PlaceFilter;

/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Document, Page, Text, View } from '@react-pdf/renderer';
import {
  threatAssessmentTitle,
  translateThreatLevelToText,
  translateThreatTrendToText,
} from '../dossiers_and_panels/threat-assessments/threat-assessment-helpers';
import {
  Threat,
  ThreatAssessment,
} from '../dossiers_and_panels/threat-assessments/threat-assessment.model';
import { sanitiseBlock } from '../utils/text-formatting.utils';
import RIFooter from './RI-Footer';
import RIHeader from './RI-Header';
import DateStamp from './date-stamp';
import styles from './pdf-styles';
import {
  AssessmentSummary,
  AssessmentTitle,
  riDocumentStyles,
} from './ri-assessments-helpers';
import TALevelChip from './threat-assessment-level-chip';
import TAThreatsTable from './threat-assessment-threats-table';

interface ThreatAssessmentExportProps {
  TA: ThreatAssessment;
}

function TAThreatSummary({ threat }: { threat: Threat }) {
  return (
    <View style={styles.section}>
      <View
        wrap={
          false /* Don't allow threat section titles to wrap across pages */
        }
      >
        <Text style={styles.subTitle}>{threat.type.title}</Text>
        <View
          style={{ ...riDocumentStyles.threatAttributes, flexDirection: 'row' }}
        >
          <Text>Level: </Text>
          <TALevelChip level={threat.level} />
          <Text>{translateThreatLevelToText(threat.level)}</Text>
        </View>
        <Text
          style={riDocumentStyles.threatAttributes}
        >{`Trend: ${translateThreatTrendToText(threat.trend)}`}</Text>
      </View>
      <Text style={styles.textBody}>
        {`${sanitiseBlock(threat.description, [])}`}
      </Text>
    </View>
  );
}

function TAThreatSummaries({ threats }: { threats: Threat[] }) {
  return (
    <>
      {threats.map((threat) => (
        <TAThreatSummary key={threat.type.id} threat={threat} />
      ))}
    </>
  );
}

export const threatAssessmentExport = ({ TA }: ThreatAssessmentExportProps) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View>
        <RIHeader />
        <AssessmentTitle
          title={threatAssessmentTitle(TA.entity, TA.country)}
          heading="Threat Assessment"
        />
        <DateStamp date={new Date()} />
        <TAThreatsTable threats={TA.threats} />
      </View>
      <View
        wrap
        style={{
          flex: '1 1',
          flexDirection: 'column',
        }}
      >
        <AssessmentSummary summary={sanitiseBlock(TA.summary, [])} />
        <TAThreatSummaries threats={TA.threats} />
      </View>
      <RIFooter />
    </Page>
  </Document>
);

import { Box } from '@mui/material';
import MuiFormikTextField from '../../common-components/form-fields/mui-formik-text-field';

interface RenderLatLongInputsProps {
  index: number;
}

function LatLongInputs(props: RenderLatLongInputsProps) {
  const { index } = props;

  return (
    <Box className="row">
      <Box className="col-md-6">
        <MuiFormikTextField
          key={index}
          label={`latitude[${index}]`}
          name={`coordinates[${index}][1]`}
          dataTestId={`latitude-${index}-input`}
          ariaLabel={`coordinates[${index}][1]`}
          size="small"
          hiddenLabel
        />
      </Box>
      <Box className="col-md-6">
        <MuiFormikTextField
          key={index}
          label={`longitude[${index}]`}
          name={`coordinates[${index}][0]`}
          dataTestId={`longitude-${index}-input`}
          ariaLabel={`coordinates[${index}][0]`}
          size="small"
          hiddenLabel
        />
      </Box>
    </Box>
  );
}

export default LatLongInputs;

import { ArrowRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { CSSProperties, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DateRangeModal, {
  EDateRangeModalVariants,
} from '../../../../common-components/date-range-modal/date-range-modal';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import DateTimeHelpers from '../../../../utils/date-time-helpers.utils';
import { setIncidentFilters } from '../../incident-panel.slice';
import './date-filter.scss';

interface IncidentsDateFilterProps {
  sx?: CSSProperties;
  dateArrow?: boolean;
}

function IncidentsDateFilter({ sx, dateArrow }: IncidentsDateFilterProps) {
  const dispatch = useAppDispatch();
  const { startDate, endDate } = useAppSelector(
    (state) => state.incidentsPanel.filters
  );
  const onCalendarSubmit = (
    start: Date | null,
    end: Date | null,
    preset: string | null
  ) => {
    dispatch(
      setIncidentFilters({
        startDate: DateTimeHelpers.dateToIsoDate(start) || null,
        endDate: DateTimeHelpers.dateToIsoDate(end) || null,
        datePreset: preset,
      })
    );
  };

  const [showDateRangeModal, setShowDateRangeModal] = useState<boolean>(false);

  const [showDateRangeLoading, setShowDateRangeLoading] =
    useState<boolean>(false);
  const [showDateRangeError, setShowDateRangeError] = useState<boolean>(false);
  const [showDateRangeSuccess, setShowDateRangeSuccess] =
    useState<boolean>(false);

  const toggleShowDateRangeModal = () => {
    setShowDateRangeModal(!showDateRangeModal);
    if (!showDateRangeModal) {
      setShowDateRangeLoading(false);
      setShowDateRangeError(false);
      setShowDateRangeSuccess(false);
    }
  };
  return (
    <>
      <IconButton onClick={() => toggleShowDateRangeModal()} sx={sx || {}}>
        {startDate} - {endDate}
        {dateArrow ? <ArrowRight /> : null}
      </IconButton>
      <DateRangeModal
        title="Select a date range"
        loading={showDateRangeLoading}
        success={showDateRangeSuccess}
        error={showDateRangeError}
        visible={showDateRangeModal}
        onClose={() => {
          toggleShowDateRangeModal();
        }}
        onSelect={(modalResult) => {
          onCalendarSubmit(
            modalResult.dateFrom.toDate(),
            modalResult.dateTo.toDate(),
            modalResult.datePreset
          );
          toggleShowDateRangeModal();
        }}
        initialStart={startDate}
        initialEnd={endDate}
        variant={EDateRangeModalVariants.THREE_MONTHS}
      />
    </>
  );
}

IncidentsDateFilter.defaultProps = {
  sx: {},
  dateArrow: false,
};

export default IncidentsDateFilter;

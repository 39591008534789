import MapLayerVisibility from '../../map/map-layer-manager/map-layer-visibility.enum';
import { nsTheme } from '../../theme';

function getDrawStyle() {
  const { colourScheme } = nsTheme;
  return [
    {
      id: 'gl-draw-polygon-fill-inactive',
      type: 'fill',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
      ],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'fill-color': colourScheme.drawInactive,
        'fill-outline-color': colourScheme.drawInactive,
        'fill-opacity': 0.1,
      },
    },
    {
      id: 'gl-draw-polygon-fill-active',
      type: 'fill',
      filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'fill-color': colourScheme.drawActive,
        'fill-outline-color': colourScheme.drawActive,
        'fill-opacity': 0.1,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
      ],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': colourScheme.drawInactive,
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'circle-radius': 5,
        'circle-color': '#fff',
      },
    },
    {
      id: 'gl-draw-polygon-stroke-active',
      type: 'line',
      filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': colourScheme.drawActive,
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-polygon-fill-static',
      type: 'fill',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'fill-color': colourScheme.drawStatic,
        'fill-outline-color': colourScheme.drawStatic,
        'fill-opacity': 0.1,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-static',
      type: 'line',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': colourScheme.drawStatic,
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': colourScheme.drawStatic,
        'line-width': 20,
      },
    },
    {
      id: 'gl-draw-point-static',
      type: 'circle',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'circle-radius': 10,
        'circle-color': colourScheme.drawStatic,
      },
    },
    {
      id: 'gl-draw-line',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: {
        'line-color': colourScheme.drawActive,
        'line-dasharray': [0.2, 2],
        'line-width': 4,
      },
    },
  ];
}

export default getDrawStyle;

import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import DefaultIncidentIconMarkerAlert from '../../assets/icons/incident/default-incident-icon-marker-alert.png';
import DefaultIncidentIconMarker from '../../assets/icons/incident/default-incident-icon-marker.png';
import DefaultIncidentIconRound from '../../assets/icons/incident/default-incident-icon-round.png';
import { useAppSelector } from '../../hooks';
import { MaybeExpandedId } from '../../models/risk_intelligence.model';
import { getIncidentTypeById } from '../../utils/incidents-helpers';

interface IncidentProps {
  id: MaybeExpandedId;
  type: 'round' | 'marker' | 'markerAlert';
}

interface CommonProps {
  title: string;
  src: string;
  bgColor?: string;
  iconText?: string;
}

// The default Sources come from RI's 'Notice' Icon, which is plain enough to not be misleading while the correct icon loads.
const defaultImageSources = {
  round: DefaultIncidentIconRound,
  marker: DefaultIncidentIconMarker,
  markerAlert: DefaultIncidentIconMarkerAlert,
};

function CommonLegendIcon(props: CommonProps) {
  const { title, src, bgColor, iconText } = props;
  return (
    <Avatar
      alt={title}
      title={title}
      src={src}
      sx={{
        width: '30px',
        height: '30px',
        margin: '0.5rem',
        minWidth: 0,
        bgcolor: bgColor,
        fontSize: 'smaller',
      }}
    >
      {iconText}
    </Avatar>
  );
}

CommonLegendIcon.defaultProps = {
  bgColor: undefined,
  iconText: undefined,
};

export function IncidentLegendIcon({ id, type }: IncidentProps) {
  const { incidentTypes, selectedIncident } = useAppSelector(
    (state) => state.incidents
  );

  const [incidentIconProps, setIncidentIconProps] = useState({
    title: '',
    src: '',
  });

  useEffect(() => {
    const iType = getIncidentTypeById(id);
    // if we fail to find in the map, fallback to default
    if (!iType) {
      setIncidentIconProps({
        title: 'Incident Icon',
        src: defaultImageSources[type],
      });
    } else {
      setIncidentIconProps({
        title: iType.title,
        src: `data:image/png;base64, ${iType.icons[type]}`,
      });
    }
  }, [incidentTypes, selectedIncident]);

  const { title, src } = incidentIconProps;

  return CommonLegendIcon({ title, src });
}

export default CommonLegendIcon;

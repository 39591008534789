import { useNavigate } from 'react-router-dom';
import {
  EDossiers,
  EMenuItems,
  closeMenus,
  setAllAssessmentsClosed,
  setAllDossierClosed,
  setSelectedDossier,
  setSelectedOption,
} from '../../main-menu/menu.slice';
import { setSelectedArea } from '../../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import { Port } from '../../maritime-menu-options/world-ports-panel/world-ports.model';
import { IndividualHistoryItem } from '../../models/history';
import {
  ExpandedCountry,
  ExtendedMaritimeArea,
} from '../../models/risk_intelligence.model';
import { removeAfterIndex } from '../../nav-history.slice';
import { setPanelStatus } from '../../search-bar/search-panel.slice';
import {
  setSelectedCountry,
  setSelectedCountryId,
} from '../../state/countries/countries.slice';
import {
  setCreateDrawingPanelVisible,
  setSelectedDrawing,
  setSelectedDrawingId,
} from '../../state/drawings/drawings.slice';
import {
  setSelectedIncident,
  setSelectedIncidentId,
} from '../../state/incidents/incidents.slice';
import {
  setSelectedPort,
  setSelectedPortId,
} from '../../state/ports/ports.slice';
import { setSelectedRoute } from '../../state/routes/routes.slice';
import store from '../../store';
import { deselectAll } from '../../utils/risk-intelligence-helpers';

namespace BreadCrumbUtils {
  export const backToMap = (
    i: number,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(closeMenus());
    store.dispatch(setAllDossierClosed());
    store.dispatch(removeAfterIndex(i - 1));
    deselectAll(store.dispatch);
    navigate('/');
  };

  export const backToPorts = (
    i: number,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(setAllDossierClosed());
    store.dispatch(removeAfterIndex(i - 1));
    store.dispatch(setSelectedPort(null));
    store.dispatch(setSelectedPortId(null));
    store.dispatch(setSelectedOption(EMenuItems.PORTS));
    navigate('/ports');
  };

  export const backToIndustryNews = (
    i: number,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(setAllDossierClosed());
    store.dispatch(removeAfterIndex(i - 1));
    store.dispatch(setSelectedOption(EMenuItems.INDUSTRY_NEWS));
    navigate('/industry-news');
  };

  export const backToRiMaritimeAreas = (
    i: number,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(setAllDossierClosed());
    store.dispatch(setSelectedArea(null));
    store.dispatch(removeAfterIndex(i - 1));
    store.dispatch(setSelectedOption(EMenuItems.RI_MARITIME_AREAS));
    navigate('/ri-maritime-areas');
  };

  export const backToSearch = (
    i: number,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(setAllDossierClosed());
    deselectAll(store.dispatch);
    store.dispatch(removeAfterIndex(i - 1));
    store.dispatch(setPanelStatus({ panelOpen: true }));
    navigate('/');
  };

  export const backToDrawings = (
    i: number,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(setSelectedDrawing(null));
    store.dispatch(setSelectedDrawingId(null));
    store.dispatch(setCreateDrawingPanelVisible(false));
    store.dispatch(setAllDossierClosed());
    store.dispatch(removeAfterIndex(i - 1));
    store.dispatch(setSelectedOption(EMenuItems.DRAWINGS));
    navigate('/drawings');
  };

  export const backToRoutes = (
    i: number,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(setSelectedRoute(null));
    store.dispatch(setAllDossierClosed());
    store.dispatch(removeAfterIndex(i - 1));
    store.dispatch(setSelectedOption(EMenuItems.ROUTES));
    navigate('/routes');
  };

  export const backToIncidents = (
    i: number,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(setSelectedIncident(null));
    store.dispatch(setSelectedIncidentId(null));
    store.dispatch(setAllDossierClosed());
    store.dispatch(removeAfterIndex(i - 1));
    store.dispatch(setSelectedOption(EMenuItems.INCIDENTS));
    navigate('/incidents');
  };

  export const backToExpandedItem = (
    i: number,
    item: IndividualHistoryItem,
    navigate: ReturnType<typeof useNavigate>
  ) => {
    store.dispatch(removeAfterIndex(i - 1));
    store.dispatch(setAllAssessmentsClosed());
    switch (item.type) {
      case 'country': {
        const countryItem = item.properties;
        store.dispatch(setSelectedDossier(EDossiers.COUNTRY));
        store.dispatch(
          setSelectedCountry({ ...countryItem, unlocode: countryItem.code })
        );
        store.dispatch(
          setSelectedCountryId((item.properties as ExpandedCountry).id)
        );
        navigate(`/countries/${countryItem.id.toString()}`);
        break;
      }
      case 'port': {
        const portItem = item.properties as Port;
        store.dispatch(setSelectedDossier(EDossiers.PORT));
        store.dispatch(setSelectedPort(portItem));
        if (portItem.ri) {
          navigate(`/ports/${portItem.ri.id}`);
        } else {
          navigate(`/ports/${portItem.WPI}`);
        }
        break;
      }
      case 'incident': {
        const incidentItem = item.properties;
        store.dispatch(setSelectedDossier(EDossiers.INCIDENT));
        store.dispatch(setSelectedIncident(incidentItem));
        store.dispatch(setAllDossierClosed());
        navigate(`/incidents/${incidentItem.id}`);
        break;
      }
      case 'RiArea': {
        const riArea = item.properties as ExtendedMaritimeArea;
        store.dispatch(setSelectedDossier(EDossiers.RI_MARITIME_AREA));
        store.dispatch(setSelectedArea(riArea));
        navigate(`/ri-maritime-areas/${riArea.id}`);
      }
      // no default
    }
  };
}

export default BreadCrumbUtils;

import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { FieldArray, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { DrawingShape } from '../../models/drawings.model';
import './create-multipoint-drawing-form.scss';
import LatLongInputs from './lat-long-inputs';
import { DrawingFormActions } from './modify-map-layer-utils';

interface FormHandlerProps {
  formik: FormikProps<any>;
  onChange: (e: React.ChangeEvent) => void;
}

function MultiPointDrawingForm({ formik, onChange }: FormHandlerProps) {
  const { values, setFieldValue } = formik;

  const coords: number[][] | undefined = values.coordinates;
  const { shape } = values;

  useEffect(() => {
    if (!coords || coords.length === 0) {
      setFieldValue('coordinates', [
        [`00° 00' 00" E`, `00° 00' 00" N`],
        [`00° 00' 00" E`, `00° 00' 00" N`],
        [`00° 00' 00" E`, `00° 00' 00" N`],
      ]);
    }
  }, []);

  const canDeletePolygon =
    shape === DrawingShape.Polygon && coords && coords.length > 3;
  const canDeleteMultipointLine =
    shape === DrawingShape.Line && coords && coords.length > 2;

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '35%',
          marginLeft: '15%',
        }}
      >
        <Typography sx={{ fontSize: '1rem', textAlign: 'left' }}>
          Latitude
        </Typography>
        <Typography sx={{ fontSize: '1rem', textAlign: 'left' }}>
          Longitude
        </Typography>
      </Box>

      <Box className="polygon-points" data-testid="polygon-points">
        <FieldArray
          name="coordinates"
          render={() =>
            coords ? (
              <>
                {coords.map((coordinate, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <LatLongInputs index={index} />
                    {(canDeletePolygon || canDeleteMultipointLine) && (
                      <IconButton
                        sx={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          marginLeft: '0.25em',
                          marginRight: '0.25em',
                        }}
                        type="button"
                        data-testid={`delete-${index}-button`}
                        onClick={() => {
                          if (canDeletePolygon || canDeleteMultipointLine) {
                            // onChange modifies the formik values arrayHelpers not needed
                            onChange({
                              target: {
                                name: DrawingFormActions.DELETE_COORDINATES,
                                value: index,
                              },
                            } as unknown as React.ChangeEvent);
                          }
                        }}
                      >
                        <RemoveCircleOutlineOutlinedIcon />
                      </IconButton>
                    )}
                  </Box>
                ))}
              </>
            ) : undefined
          }
        />
      </Box>
      <Box>
        <Button
          type="button"
          data-testid="add-button"
          variant="outlined"
          onClick={() => {
            onChange({
              target: {
                name: DrawingFormActions.NEW_COORDINATES,
              },
            } as unknown as React.ChangeEvent);
          }}
          fullWidth
          sx={{
            width: '100%',
            padding: '10px, 16px, 10px, 16px',
            borderRadius: '100px',
            borderColor: 'secondary.main',
            marginTop: '1em',
          }}
        >
          Add Point
        </Button>
      </Box>
    </Box>
  );
}

export default MultiPointDrawingForm;

import { List, Typography } from '@mui/material';
import IncidentItem from '../../incidents-panel/incident-item/incident-item';
import { Incident } from '../../incidents-panel/incident.model';

interface AreaIncidentsProps {
  incidents: Incident[] | null;
}

function AreaIncidents(props: AreaIncidentsProps) {
  const { incidents } = props;

  return (
    <div className="area-incidents">
      {incidents && incidents.length > 0 ? (
        <List>
          {incidents.map((incident) => (
            <IncidentItem
              selected={false}
              key={incident.id}
              downloadView={false}
              onCheckBoxSelected={() => {}}
              incident={incident}
            />
          ))}
        </List>
      ) : (
        <Typography>No incidents to display</Typography>
      )}
    </div>
  );
}
export default AreaIncidents;

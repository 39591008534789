import { Close, Info } from '@mui/icons-material';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import RenderThreatDefinitionsModalContents from '../../dossiers_and_panels/threat-assessments/threat-assessments-definitions-modal/threat-definitions-modal';
import { useAppSelector, useMobile } from '../../hooks';
import zIndexes from '../../z-indexes.scss';
import { BackOneButton } from '../breadcrumb/breadcrumb';

interface LargeTextSidePanelProps {
  title: string;
  onClose: () => void;
  children: React.ReactNode;
}

function LargeTextSidePanel(props: LargeTextSidePanelProps) {
  const { userPreferences } = useAppSelector((state) => state.userPreferences);
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const { title, onClose, children } = props;
  const isMobile = useMobile();

  return (
    <Box
      sx={{
        width: 755,
        ...(isMobile && {
          position: 'absolute',
          zIndex: zIndexes['$--feature-panel-contents-layer'],
          height: '100vh',
          width: '100vw',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }),
        height: userPreferences.showRiNewsBanner
          ? 'calc(100vh - 2rem)'
          : '100vh',
        backgroundColor: 'primary.main',
      }}
    >
      <Box
        sx={{
          height: '5rem',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'left',
          justifyContent: 'center',
          marginTop: '.75rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BackOneButton />
          <Typography
            variant="h5"
            align="left"
            sx={{ fontWeight: 400, marginLeft: '1rem' }}
          >
            {title}
          </Typography>
          <IconButton
            disableRipple
            data-testid="menu-button"
            sx={{
              color: 'secondary.main',
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <Info data-testid="tooltip-button" />
          </IconButton>
        </Box>
        <IconButton
          disableRipple
          data-testid="menu-button"
          sx={{
            m: '-1rem 1rem -1rem auto',
            display: 'flex',
            justifyContent: 'end',
            color: 'secondary.main',
            '&:hover': {
              bgcolor: 'secondary.da',
              borderRadius: '1rem',
            },
          }}
          onClick={() => onClose()}
        >
          <Close data-testid="close-menu" />
        </IconButton>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
            borderRadius: '20px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: '1rem',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <RenderThreatDefinitionsModalContents />
        </Popover>
      </Box>
      {children}
    </Box>
  );
}

export default LargeTextSidePanel;

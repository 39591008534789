import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TypographyProps,
} from '@mui/material';
import React from 'react';

interface DataRowProps {
  label: string;
  value: string | React.ReactNode;
  icon: React.ReactNode;
  inverted?: boolean;
  primaryTypographyProps?: Partial<
    TypographyProps<'span', { component: string | undefined }>
  >;
  secondaryTypographyProps?: Partial<
    TypographyProps<'span', { component: string | undefined }>
  >;
}

function DataRow({
  label,
  value,
  icon,
  primaryTypographyProps,
  secondaryTypographyProps,
  inverted = false,
}: DataRowProps) {
  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth: '2.5rem' }}>{icon}</ListItemIcon>
      <ListItemText
        primary={label}
        secondary={
          <Typography
            component="span"
            variant="body2"
            sx={{
              whiteSpace: 'pre-line',
              ...(inverted
                ? { color: 'text.primary', ...secondaryTypographyProps }
                : { color: 'text.secondary', ...secondaryTypographyProps }),
            }}
          >
            {value}
          </Typography>
        }
        primaryTypographyProps={primaryTypographyProps}
        secondaryTypographyProps={secondaryTypographyProps}
      />
    </ListItem>
  );
}

DataRow.defaultProps = {
  inverted: false,
  primaryTypographyProps: {},
  secondaryTypographyProps: {},
};

export default DataRow;

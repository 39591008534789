/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ExtendedMaritimeArea,
  MaritimeArea,
} from '../../models/risk_intelligence.model';
import { populateAreasCache } from '../../utils/incidents-helpers';

interface MaritimeAreasState {
  loading: boolean;
  error: boolean;
  areas: MaritimeArea[] | null;
  showAllAreas: boolean;
  selectedArea: ExtendedMaritimeArea | MaritimeArea | null;
  selectedAreaId: number | null;
}

export const INITIAL_RI_MARITIME_AREAS_STATE: MaritimeAreasState = {
  loading: false,
  error: false,
  areas: null,
  showAllAreas: true,
  selectedArea: null,
  selectedAreaId: null,
};

const RIMaritimeAreasSlice = createSlice({
  name: 'maritimeAreas',
  initialState: INITIAL_RI_MARITIME_AREAS_STATE,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    toggleShowAllAreas: (state) => {
      const currentShowAllState = state.showAllAreas;
      state.showAllAreas = !currentShowAllState;
    },
    setAreas: (state, action: PayloadAction<MaritimeArea[] | null>) => {
      state.areas = action.payload;
      populateAreasCache(action.payload);
    },
    setSelectedArea: (
      state,
      action: PayloadAction<MaritimeArea | ExtendedMaritimeArea | null>
    ) => {
      state.selectedArea = action.payload;
    },
    setSelectedAreaId: (state, action: PayloadAction<number | null>) => {
      state.selectedAreaId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setLoading,
  setError,
  setAreas,
  toggleShowAllAreas,
  setSelectedArea,
  setSelectedAreaId,
} = RIMaritimeAreasSlice.actions;

export default RIMaritimeAreasSlice.reducer;

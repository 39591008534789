import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { Incident } from '../../../maritime-menu-options/incidents-panel/incident.model';
import { setSelectedIncident } from '../../../state/incidents/incidents.slice';
import store from '../../../store';

function onIncidentClick(incident: Incident) {
  store.dispatch(setSelectedDossier(EDossiers.INCIDENT));
  store.dispatch(setSelectedIncident(incident));
  window.history.pushState({}, '', `/incidents/${incident.id}`);
}

export default onIncidentClick;

interface CalculateDistance {
  duration: {
    hours: number;
    minutes: number;
  };
  speed: number;
}

interface CalculateDuration {
  distanceNauticalMiles: number;
  speed: number;
}

interface CalculateSpeed {
  distanceNauticalMiles: number;
  duration: {
    hours: number;
    minutes: number;
  };
}

export enum RangeRingCalcOpt {
  DISTANCE = 'distanceNauticalMiles',
  DURATION = 'duration',
  SPEED = 'speed',
}

export interface CommonRangeRingProperties {
  id: string;
  calculationOption: RangeRingCalcOpt;
  colour: string;
}

export interface RangeRingDistanceProperties
  extends CommonRangeRingProperties,
    CalculateDistance {}

export interface RangeRingDurationProperties
  extends CommonRangeRingProperties,
    CalculateDuration {}

export interface RangeRingSpeedProperties
  extends CommonRangeRingProperties,
    CalculateSpeed {}

export type RangeRingProperties =
  | RangeRingDistanceProperties
  | RangeRingDurationProperties
  | RangeRingSpeedProperties;

export interface RangeRingPoint {
  id: string;
  centre: {
    longitude: string;
    latitude: string;
  };
  rings: RangeRingProperties[];
}

import mapboxgl, { PopupOptions } from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import MapHelpers from '../../map/map.utils';

interface PopupBaseProps extends PopupOptions {
  children: React.ReactNode;
  lngLat: mapboxgl.LngLatLike;
}
/**
 * This Component allows us to use React (and therefore MUI) within a mapbox popup in an easy fashion.
 * Simply wrap your Reacty Content in a PopupBase and pass the lngLat prop.
 * You can also pass in any mapboxgl.PopupOptions as props to customise further.
 *
 *   {popupLngLat && <PopupBase lngLat={popupLngLat} anchor="left" closeButton={false}>{children}</PopupBase>}
 *
 * OnMount, the popup will be created and added to the map.
 * OnUnmount, (presumably when popupLngLat is set to null/falsy) the popup will be removed from the map.
 *
 * @link https://sparkgeo.com/blog/create-a-working-react-mapbox-popup/
 */
function PopupBase({ children, lngLat, ...popupOptions }: PopupBaseProps) {
  const popupRef = useRef<HTMLDivElement>(null);

  const mapInstance = MapHelpers.getMapInstance();

  if (!mapInstance) {
    return null;
  }

  useEffect(() => {
    if (!popupRef.current) {
      return () => {
        // noop
      };
    }
    const popup = new mapboxgl.Popup({
      ...popupOptions,
    })
      .setLngLat(lngLat)
      .setDOMContent(popupRef.current)
      .addTo(mapInstance);

    popupRef.current.focus();

    return () => {
      popup.remove();
    };
  }, [children, lngLat]);

  // This invisible div is returned so that we can snag the ref and use it in the useEffect above.
  return (
    <div style={{ display: 'none' }}>
      <div ref={popupRef} style={{ pointerEvents: 'auto' }}>
        {children}
      </div>
    </div>
  );
}

export default PopupBase;

/* eslint-disable consistent-return */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { BorderStyle, PushPin } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import mapboxgl, { LngLat } from 'mapbox-gl';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector, useMobile } from '../hooks';
import GeoHelper from '../utils/geo-helpers.utils';
import './map-lat-long-picker.scss';
import { setDrawType } from './map.slice';
import MapHelpers from './map.utils';

const BOX_DRAW_TEMP_LAYER_NAME = 'lat-long-box-draw';

interface MapLatLongPickerProps {
  onMapClick: (e: LngLat[]) => void;
  drawBox?: boolean;
  buttonText?: string;
  tool?: string;
  toolTip?: string;
  disableable?: boolean;
}

function MapLatLongPicker(props: MapLatLongPickerProps) {
  const { onMapClick, drawBox, buttonText, tool, toolTip, disableable } = props;
  const [active, setActive] = useState<boolean>(false);
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);
  const drawType = useAppSelector((state) => state.map.drawType);
  const dispatch = useAppDispatch();

  const isMobile = useMobile();

  // can't use state for these, event-callbacks don't quite correctly read state
  const clicks = useRef(0);
  const firstPoint = useRef({ lng: 0, lat: 0 });

  const onMouseMove = (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
    const secondPoint = e.lngLat;
    const polygon = GeoHelper.createFeaturePolygon(
      [
        GeoHelper.createRectangle(
          [firstPoint.current.lng, firstPoint.current.lat],
          [secondPoint.lng, secondPoint.lat]
        ),
      ],
      {}
    );
    (
      MapHelpers.getSource(BOX_DRAW_TEMP_LAYER_NAME) as mapboxgl.GeoJSONSource
    )?.setData(polygon);
  };

  const addBoxDrawLayers = () => {
    MapHelpers.addSource(BOX_DRAW_TEMP_LAYER_NAME, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [],
        },
        properties: {},
      },
    });
    MapHelpers.addLayer({
      id: BOX_DRAW_TEMP_LAYER_NAME,
      type: 'line',
      source: BOX_DRAW_TEMP_LAYER_NAME,
      paint: {
        'line-color': '#dbdbd9',
        'line-width': 1,
        'line-opacity': 1,
      },
    });
    MapHelpers.addMapEventListener('mousemove', null, onMouseMove);
  };

  const removeBoxDrawLayers = (map: mapboxgl.Map) => {
    MapHelpers.removeMapEventListener('mousemove', onMouseMove);
    if (map?.getLayer(BOX_DRAW_TEMP_LAYER_NAME)) {
      map?.removeLayer(BOX_DRAW_TEMP_LAYER_NAME);
    }
    if (map?.getSource(BOX_DRAW_TEMP_LAYER_NAME)) {
      map?.removeSource(BOX_DRAW_TEMP_LAYER_NAME);
    }
  };

  const onActiveStateChange = (
    e: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent
  ) => {
    if (drawBox) {
      const map = MapHelpers.getMapInstance();
      if (clicks.current === 0) {
        addBoxDrawLayers();
        firstPoint.current = e.lngLat;
      } else if (clicks.current === 1) {
        const secondPoint = e.lngLat;
        onMapClick([firstPoint.current as LngLat, secondPoint]);
        clicks.current = 0;
        setActive(false);
        dispatch(setDrawType(null));
        removeBoxDrawLayers(map);
        return;
      }
    } else {
      onMapClick([e.lngLat.wrap()]);
      setActive(false);
      dispatch(setDrawType(null));
    }
    clicks.current += 1;
  };

  useEffect(() => {
    if (!mapInitialised) {
      return;
    }

    // prevent touch-then-drag being interpreted as a click
    // if start point is significantly different from end point, or 250ms elapses, then it's a drag
    let isClick = false;
    let point: { x: number; y: number } | null = null;
    const touchStart = (e: mapboxgl.MapTouchEvent) => {
      isClick = true;
      point = e.point;
      setTimeout(() => {
        isClick = false;
        point = null;
      }, 250);
    };

    const touchEnd = function touchEnd(e: mapboxgl.MapTouchEvent) {
      if (isClick && GeoHelper.pointsNear(point, e.point)) {
        onActiveStateChange(e);
      }
    };

    if (active) {
      if (isMobile) {
        MapHelpers.addMapEventListener('touchstart', null, touchStart);
        MapHelpers.addMapEventListener('touchend', null, touchEnd);
      } else if (drawBox) {
        MapHelpers.addMapEventListener('click', null, onActiveStateChange);
      } else {
        MapHelpers.once('click', onActiveStateChange);
      }
    }

    return () => {
      // cleanup - prevent map listening to click events when component unmounts
      MapHelpers.removeMapEventListener('click', onActiveStateChange);
      MapHelpers.removeMapEventListener('touchend', touchEnd);
      MapHelpers.removeMapEventListener('touchstart', touchStart);
      removeBoxDrawLayers(MapHelpers.getMapInstance());
    };
  }, [mapInitialised, active]);

  useEffect(() => {
    return () => {
      // seperate useEffect for unmount only actions
      removeBoxDrawLayers(MapHelpers.getMapInstance());
      dispatch(setDrawType(null));
    };
  }, []);

  useEffect(() => {
    if (!drawType) {
      // seperate useEffect for when drawType is cleared
      setActive(false);
      removeBoxDrawLayers(MapHelpers.getMapInstance());
      dispatch(setDrawType(null));
      clicks.current = 0;
      firstPoint.current = { lng: 0, lat: 0 };
    }
  }, [drawType]);

  if (buttonText) {
    return (
      <Button
        data-testid="map-lat-long-picker"
        className={`map-lat-long-picker ${active ? 'active' : ''}`}
        sx={{
          cursor: 'crosshair',
          height: '100%',
          borderRadius: { xl: '4px' },
          display: 'flex',
          flexDirection: 'row',
        }}
        variant="outlined"
        fullWidth
        onClick={() => {
          setActive(true);
          dispatch(setDrawType(drawBox ? 'box' : 'boundary'));
        }}
        startIcon={
          drawBox ? (
            <BorderStyle
              color="action"
              sx={{ marginBottom: 0.1, marginRight: { xs: 1, xl: 0 } }}
            />
          ) : (
            <PushPin
              color="action"
              sx={{ marginBottom: 0.1, marginRight: { xs: 1, xl: 0 } }}
            />
          )
        }
      >
        {buttonText}
      </Button>
    );
  }
  return (
    <Tooltip title={toolTip}>
      <IconButton
        data-testid="map-lat-long-picker"
        className={`map-lat-long-picker ${active ? 'active' : ''}`}
        sx={{
          cursor: 'crosshair',
          '&.Mui-disabled': {
            background: '#383334',
            color: '#383334',
            opacity: 0.5,
          },
        }}
        onClick={() => {
          setActive(true);
          dispatch(setDrawType(drawBox ? 'box' : 'boundary'));
        }}
        disabled={disableable && drawType !== null}
      >
        {tool === 'mapMarker' ? (
          <PushPin color="action" />
        ) : (
          <BorderStyle color="action" />
        )}
      </IconButton>
    </Tooltip>
  );
}

MapLatLongPicker.defaultProps = {
  drawBox: false,
  buttonText: undefined,
  tool: undefined,
  toolTip: undefined,
  disableable: false,
};

export default MapLatLongPicker;

import mapboxgl, { MapboxGeoJSONFeature } from 'mapbox-gl';
import { EDossiers, setSelectedDossier } from '../main-menu/menu.slice';
import { Incident } from '../maritime-menu-options/incidents-panel/incident.model';
import { Port } from '../maritime-menu-options/world-ports-panel/world-ports.model';
import { CorrespondentFeature } from '../models/correspondents.model';
import { FirstCallFeature } from '../models/first-call';
import { IndustryNewsFeature } from '../models/industry-news.model';
import { MapboxGeoCodeFeature } from '../models/mapbox-geocoding.model';
import { MaritimeArea } from '../models/risk_intelligence.model';
import { startNewHistory } from '../nav-history.slice';
import {
  setSelectedCountry,
  setSelectedCountryId,
} from '../state/countries/countries.slice';
import store from '../store';
import { iso2ToIso3CountryCodes } from '../utils/iso2-iso3-country-codes';
import CorrespondentsController from './map-layer-manager/correspondents-utils/correspondents-controller';
import FirstCallController from './map-layer-manager/first-call-utils/first-call-controller';
import IncidentController from './map-layer-manager/incident-utils/incident-controller.utils';
import IndustryNewsController from './map-layer-manager/industry-news-utils/industry-news-controller';
import MapLayer from './map-layer-manager/map-layer.enum';
import MaritimeAreaController from './map-layer-manager/maritime-areas-utils/maritime-area-controller.utils';
import PortsController from './map-layer-manager/port-utils/port-controller.utils';
import RoutePortsController from './map-layer-manager/port-utils/route-port-controller.utils';
import RoutesIncidentsController from './map-layer-manager/route-utils/routes-incidents-controller.utils';
import MapHelpers from './map.utils';

const assertIsMapboxGeoJSONFeature = (
  feature: mapboxgl.MapboxGeoJSONFeature | MapboxGeoCodeFeature
): feature is mapboxgl.MapboxGeoJSONFeature =>
  (feature as mapboxgl.MapboxGeoJSONFeature).layer !== undefined;

export interface MapObjectInfo {
  id: string;
  name: string;
  action: () => void;
  type: string;
}

/**
 * This function is used to select the correct on-click function based on the feature clicked on the map. It also derives the 'name' of the feature that was clicked.
 * @param event The click event itself. is used by some on-click functions further down the stack.
 * @param feature The Mapbox feature that was clicked on.
 * @param dispatch redux dispatch function
 * @param finalAction an action to perform after the main action has been performed. Useful for closing popups etc.
 * @returns an array of the feature id, name and action to perform.
 */
const deriveMapObjectNameAndAction = (
  event: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent,
  feature: mapboxgl.MapboxGeoJSONFeature | MapboxGeoCodeFeature,
  dispatch: typeof store.dispatch,
  finalAction?: () => void
): MapObjectInfo => {
  let result: MapObjectInfo = {
    id: 'none',
    name: 'Feature',
    action: () => {
      finalAction?.();
    },
    type: 'unknown',
  };

  // country-click responses and other layer responses differ slightly
  if (assertIsMapboxGeoJSONFeature(feature)) {
    switch (feature.layer.id) {
      case MapLayer.INCIDENTS: {
        const incident = feature.properties as Incident;
        result = {
          id: `incident-${incident.id}`,
          name: incident.title,
          action: () => {
            IncidentController.layerEvents.onClick(incident);
          },
          type: 'incident',
        };
        break;
      }
      case MapLayer.INCIDENT_CLUSTERS: {
        const { cluster_id: clusterId, point_count: pointCount } =
          feature.properties as Record<string, string>;
        result = {
          id: `incident-cluster-${clusterId}`,
          name: `Incident Cluster (${pointCount})`,
          action: () => {
            IncidentController.layerEvents.onClusterClick(
              event.target,
              feature as GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
            );
          },
          type: 'incident',
        };
        break;
      }
      case MapLayer.ROUTES_INCIDENTS: {
        const incident = feature.properties as Incident;
        result = {
          id: `route-incident-${incident.id}`,
          name: incident.title,
          action: () => {
            MapHelpers.zoomToPoint(
              [incident.position?.longitude, incident.position?.latitude] as [
                number,
                number
              ],
              7
            );
            RoutesIncidentsController.layerEvents.onClick(incident);
          },
          type: 'route incident',
        };
        break;
      }
      case MapLayer.ROUTES_INCIDENT_CLUSTERS: {
        const { cluster_id: clusterId, point_count: pointCount } =
          feature.properties as Record<string, string>;
        result = {
          id: `route-incident-cluster-${clusterId}`,
          name: `Incident Cluster (${pointCount})`,
          action: () => {
            RoutesIncidentsController.layerEvents.onClusterClick(
              event.target,
              feature as GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
            );
          },
          type: 'route incident',
        };
        break;
      }
      case MapLayer.RI_MARITIME_AREAS: {
        const maritimeArea = feature.properties as MaritimeArea;
        result = {
          id: `maritime-area-${maritimeArea.id}`,
          name: maritimeArea.title,
          action: () => {
            MaritimeAreaController.layerEvents.onClick(maritimeArea);
          },
          type: 'maritime area',
        };
        break;
      }
      case MapLayer.PORTS: {
        const port = feature.properties as Port;
        result = {
          id: `port-${port.WPI}`,
          name: port.NAME,
          action: () => {
            PortsController.layerEvents.onClick(feature, port);
          },
          type: 'port',
        };
        break;
      }
      case MapLayer.PORT_CLUSTERS: {
        const { cluster_id: clusterId, point_count: pointCount } =
          feature.properties as Record<string, string>;
        result = {
          id: `port-cluster-${clusterId}`,
          name: `Port Cluster (${pointCount})`,
          action: () => {
            PortsController.layerEvents.onClusterClick(
              event.target,
              feature as GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
            );
          },
          type: 'port',
        };
        break;
      }
      case MapLayer.ROUTES_PORTS: {
        const port = feature.properties as Port;
        result = {
          id: `route-port-${port.WPI}`,
          name: port.NAME,
          action: () => {
            RoutePortsController.layerEvents.onClick(feature, port);
          },
          type: 'route port',
        };
        break;
      }
      case MapLayer.ROUTES_PORT_CLUSTERS: {
        const { cluster_id: clusterId, point_count: pointCount } =
          feature.properties as Record<string, string>;
        result = {
          id: `route-port-cluster-${clusterId}`,
          name: `Port Cluster (${pointCount})`,
          action: () => {
            RoutePortsController.layerEvents.onClusterClick(
              event.target,
              feature as GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
            );
          },
          type: 'route port',
        };
        break;
      }
      case MapLayer.FIRST_CALL_PORTS: {
        const firstCallPort = feature as MapboxGeoJSONFeature &
          FirstCallFeature;
        result = {
          id: `first-call-port-${firstCallPort.id}`,
          name: `${firstCallPort.properties?.portName} (First Call)`,
          action: () => {
            FirstCallController.layerEvents.onClick(firstCallPort);
          },
          type: 'first call port',
        };
        break;
      }
      case MapLayer.FIRST_CALL_CLUSTERS: {
        const { cluster_id: clusterId, point_count: pointCount } =
          feature.properties as Record<string, string>;
        result = {
          id: `first-call-port-cluster-${clusterId}`,
          name: `First Call Cluster (${pointCount})`,
          action: () => {
            FirstCallController.layerEvents.onClusterClick(
              event.target,
              feature as GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
            );
          },
          type: 'first call port',
        };
        break;
      }
      case MapLayer.CORRESPONDENTS: {
        const correspondent = feature as MapboxGeoJSONFeature &
          CorrespondentFeature;
        result = {
          id: `correspondent-${correspondent.properties.uid}`,
          name: `${correspondent.properties.name} (Correspondent)`,
          action: () => {
            CorrespondentsController.layerEvents.onClick(correspondent);
          },
          type: 'correspondent',
        };
        break;
      }
      case MapLayer.CORRESPONDENT_CLUSTERS: {
        const { cluster_id: clusterId, point_count: pointCount } =
          feature.properties as Record<string, string>;
        result = {
          id: `correspondent-cluster-${clusterId}`,
          name: `Correspondent Cluster (${pointCount})`,
          action: () => {
            CorrespondentsController.layerEvents.onClusterClick(
              event.target,
              feature as GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
            );
          },
          type: 'correspondent',
        };
        break;
      }
      case MapLayer.INDUSTRY_NEWS: {
        const news = feature as MapboxGeoJSONFeature & IndustryNewsFeature;
        result = {
          id: `indsutry-news-${news.id}`,
          name: `${news.properties.title} (Industry News)`,
          action: () => {
            IndustryNewsController.layerEvents.onClick(news);
          },
          type: 'industry-news',
        };
        break;
      }
      case MapLayer.INDUSTRY_NEWS_CLUSTERS: {
        const { cluster_id: clusterId, point_count: pointCount } =
          feature.properties as Record<string, string>;
        result = {
          id: `industry-news-cluster-${clusterId}`,
          name: `Industry News Cluster (${pointCount})`,
          action: () => {
            IndustryNewsController.layerEvents.onClusterClick(
              event.target,
              feature as GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
            );
          },
          type: 'indsutry-news',
        };
        break;
      }
      default: {
        return {
          id: 'none',
          name: 'Feature',
          action: () => {},
          type: 'unknown',
        };
      }
    }
  } else {
    // feature is a MapboxGeocodeFeature
    result = {
      id: `country-${feature.id}`,
      name: feature.place_name,
      action: () => {
        dispatch(startNewHistory({ type: 'map' }));
        dispatch(setSelectedDossier(EDossiers.COUNTRY));
        // extract important features from response
        dispatch(
          setSelectedCountry({
            unlocode: feature.properties.short_code,
            title: feature.place_name,
            iso3: iso2ToIso3CountryCodes[
              feature.properties.short_code.toUpperCase()
            ],
          })
        );
        window.history.pushState(
          {},
          '',
          `/countries/${feature.properties.short_code.toLowerCase()}`
        );
        dispatch(setSelectedCountryId(null));
      },
      type: 'country',
    };
  }

  return {
    id: result.id,
    name: result.name,
    action: () => {
      result.action();
      // perform finalAction if provided
      finalAction?.();
    },
    type: result.type,
  };
};

export default deriveMapObjectNameAndAction;

import { useEffect, useState } from 'react';
import { getThreatAssessment } from '../../api';
import { ThreatAssessment } from './threat-assessment.model';
import { ThreatAssessmentInfo } from './threat-assessment.slice';

const useSelectedThreatAssessment = (
  id: ThreatAssessmentInfo['id']
): {
  threatAssessment: ThreatAssessment | null;
  loadingTA: boolean;
  error: boolean;
} => {
  const [threatAssessment, setThreatAssessment] =
    useState<ThreatAssessment | null>(null);
  const [loadingTA, setLoadingTA] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (id) {
      setLoadingTA(true);
      getThreatAssessment(id)
        .then((response) => {
          setThreatAssessment(response);
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setLoadingTA(false);
        });
    } else {
      setThreatAssessment(null);
    }
  }, [id]);

  return {
    threatAssessment,
    loadingTA,
    error,
  };
};

export default useSelectedThreatAssessment;

import { Box, Grid, TextFieldProps } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import MapLatLongPicker from '../../map/map-lat-long-picker';
import MuiFormikTextField from './mui-formik-text-field';

interface LongLatInputFieldsProps {
  longitudeInputName: string;
  latitudeInputName: string;
  onLngLatSelect: (lngLat: mapboxgl.LngLat[]) => void;
}

export default function LongLatInputFields(
  props: LongLatInputFieldsProps & TextFieldProps
) {
  const { longitudeInputName, latitudeInputName, onLngLatSelect } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <MuiFormikTextField
          name={longitudeInputName}
          dataTestId={longitudeInputName}
          label="Longitude"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={5}>
        <MuiFormikTextField
          name={latitudeInputName}
          dataTestId={latitudeInputName}
          label="Latitude"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <Box
          sx={{
            marginTop: '1rem',
          }}
        >
          <MapLatLongPicker onMapClick={onLngLatSelect} tool="mapMarker" />
        </Box>
      </Grid>
    </Grid>
  );
}

import { useAppSelector } from '../hooks';
import IncidentsDateFilter from '../maritime-menu-options/incidents-panel/incident-filters/date-filter/date-filter';
import './incidents-date-range-indicator.scss';

const mapDateRangeSx = {
  backgroundColor: '#3131317e',
  left: '50%',
  transform: 'translate(-50%, 0)',
  borderRadius: '12px',
  color: 'white',
  fontSize: '16px',
  fontWeight: '400',
};
function IncidentsDateRangeIndicator() {
  const { incidents } = useAppSelector((state) => state.incidents);
  const {
    filters: { startDate, endDate },
  } = useAppSelector((state) => state.incidentsPanel);
  return incidents && startDate && endDate ? (
    <div id="incidents-date-range-indicator">
      <IncidentsDateFilter sx={mapDateRangeSx} />
    </div>
  ) : null;
}

export default IncidentsDateRangeIndicator;

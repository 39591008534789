import { Box, Button, Grid, List, TextField, Typography } from '@mui/material';
import { GeoJSONSource, Layer } from 'mapbox-gl';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import MapLayerManager from '../../../map/map-layer-manager/map-layer-manager.utils';
import { MapGroupLayer } from '../../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../../map/map.utils';
import DateTimeHelpers from '../../../utils/date-time-helpers.utils';
import { setAISDataGapLength } from '../history-panel.slice';
import AISDataGapsController from './ais-data-gaps-controller.utils';
import { hideAISDataGapPointPopups } from './ais-data-gaps-popup';
import HistoryAISDataGapResultCard from './history-ais-data-gap-result-card';

function HistoryAISDataGaps() {
  const aisDataGapLength = useAppSelector(
    (state) => state.historyPanel.aisDataGapLength
  );
  const selectedAISDataGapFeature = useAppSelector(
    (state) => state.historyPanel.selectedAISDataGapFeature
  );
  const historicVesselPoints = useAppSelector(
    (state) => state.historyPanel.historicVesselPoints
  );

  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);

  const dispatch = useAppDispatch();

  const GAP_INTERVAL = 0.5;
  const timeGapData = AISDataGapsController.getFilteredAISDataGapFeatures();

  const handleClearSelection = () => {
    AISDataGapsController.clearSelectedFeature();
    hideAISDataGapPointPopups();
  };

  const handleAISDataGapLengthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleClearSelection();
    dispatch(setAISDataGapLength(Number(e.target.value)));
  };

  const showAISDataGapLessThanOneHourWarning = () => {
    const { features } = timeGapData;

    if (!features.length) {
      return false;
    }

    const lastTimeGap = features?.[features.length - 1];
    const lastVessel = historicVesselPoints?.[historicVesselPoints.length - 1];

    const lastVesselBroadcast = new Date(lastVessel.timestamp).valueOf();
    const lastTimeGapTimestamp = Number(lastTimeGap?.properties?.endTimestamp);
    const timeDifference = lastVesselBroadcast - lastTimeGapTimestamp;

    return (
      DateTimeHelpers.MILLISECONDS_IN_HOUR > timeDifference &&
      timeDifference > 0
    );
  };

  useEffect(() => {
    if (mapInitialised) {
      // get all the layers within the AIS Data Gaps group
      // there should be one for each vessel
      const aisLayers = MapLayerManager.findLayersByGroupLayerId(
        MapGroupLayer.VESSEL_HISTORY_AIS_DATA_GAPS
      );
      // loop through each vessel layer and find the timegapdata related to that
      // vessel
      aisLayers.forEach((ais) => {
        const vesselId = (ais as Layer).metadata.groupLayers[0];
        const specificVesselData = timeGapData.features.filter(
          (data) => vesselId === data.properties.uniqueId
        );

        (MapHelpers.getSource(ais.id) as GeoJSONSource)?.setData({
          features: specificVesselData,
          type: 'FeatureCollection',
        });
      });
    }
  }, [mapInitialised, aisDataGapLength]);

  return (
    <>
      <Box
        sx={{
          padding: '20px 20px 0px',
          maxHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {showAISDataGapLessThanOneHourWarning() && (
          <Typography
            variant="subtitle2"
            align="left"
            data-testid="data-ais-gap-warning"
            sx={{
              marginBottom: '1rem',
            }}
          >
            Warning: The last AIS transmission of this vessel was less than an
            hour from the end of the query duration
          </Typography>
        )}

        <TextField
          label="Gap length (hrs)"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: 0.5,
              step: GAP_INTERVAL,
              pattern: '[0-9]*',
            },
          }}
          placeholder="Adjust Gap length"
          value={aisDataGapLength}
          fullWidth
          onChange={handleAISDataGapLengthChange}
        />
        <Button
          sx={{
            borderRadius: '100px',
            borderColor: 'primary.dark',
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '38px',
            '& a': {
              color: 'secondary.main',
            },
            marginTop: '20px',
          }}
          fullWidth
          onClick={handleClearSelection}
          disabled={selectedAISDataGapFeature === null}
        >
          Clear selection
        </Button>
        <Box
          sx={{
            margin: '20px 0px',
          }}
        >
          <Grid container rowSpacing={1}>
            <Grid item xs={10}>
              <Typography variant="subtitle2" align="left">
                Data gap(s) found
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                align="right"
                data-testid="ais-data-gaps-count"
              >
                {aisDataGapLength > 0 ? `${timeGapData.features.length}` : 0}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box>
        {timeGapData.features.length > 0 && aisDataGapLength > 0 ? (
          <List disablePadding>
            {timeGapData.features.map((feature) => (
              <HistoryAISDataGapResultCard
                key={feature.properties?.id}
                feature={feature}
                selected={
                  selectedAISDataGapFeature?.properties?.id ===
                  feature.properties?.id
                }
              />
            ))}
          </List>
        ) : (
          <Typography>No data gaps found</Typography>
        )}
      </Box>
    </>
  );
}

export default HistoryAISDataGaps;

import { uniqueId } from 'lodash';
import { decimalToDMS } from '../../../map/map-controls.utils';
import {
  addRangeRingLayers,
  addRangeRingPointLayer,
} from '../../../map/map-layer-manager/range-ring-utils/add-range-ring-layers';
import { removeRangeRingPoint } from '../../../map/map-layer-manager/range-ring-utils/remove-range-ring-layers';
import { setRangeRingPointFeatures } from '../../../map/map-layer-manager/range-ring-utils/set-range-ring-layer-features';
import MapHelpers from '../../../map/map.utils';
import {
  rangeRingFormCentreValidationSchema,
  rangeRingPropertiesValidationSchema,
} from './range-ring-form-validators';
import {
  RangeRingCalcOpt,
  RangeRingPoint,
  RangeRingProperties,
} from './range-ring.model';

export const RANGE_RING_COLOURS = [
  '#1baa36',
  '#ff7c1b',
  '#ff0000',
  '#156fff',
  '#000000',
];

export const generateRangeRingPoint = (
  lat?: number,
  lng?: number
): RangeRingPoint => ({
  id: uniqueId('range_point_'),
  centre: {
    longitude: decimalToDMS(lng || 0, 'longitude'),
    latitude: decimalToDMS(lat || 0, 'latitude'),
  },
  rings: [],
});

export const generateRangeRing = (index: number): RangeRingProperties => ({
  id: uniqueId('range_ring_'),
  calculationOption: RangeRingCalcOpt.DISTANCE,
  duration: {
    hours: 0,
    minutes: 0,
  },
  speed: 0,
  colour: RANGE_RING_COLOURS[index % RANGE_RING_COLOURS.length],
});

export const onRangeRingPointUpdate = async (point: RangeRingPoint) => {
  point.rings.forEach((ring) => {
    if (!MapHelpers.getSource(ring.id)) {
      addRangeRingLayers(ring, point.id);
    }
  });

  const isValidPoint = rangeRingFormCentreValidationSchema.isValid(point);
  const areValidRangeRings = point.rings.map((ring) =>
    rangeRingPropertiesValidationSchema.isValid(ring)
  );
  await Promise.all([isValidPoint, ...areValidRangeRings]).then((results) => {
    if (results.every((result) => result === true)) {
      setRangeRingPointFeatures(point);
    }
  });
};

export const onRangeRingPointAdd = (point: RangeRingPoint) => {
  addRangeRingPointLayer(point);
  onRangeRingPointUpdate(point);
};

export const onRangeRingPointRemove = (point: RangeRingPoint) => {
  removeRangeRingPoint(point);
};

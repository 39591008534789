import { Storage, StorageGetConfig } from '@aws-amplify/storage';
import { inflate } from 'pako';
import { jsonToCSV } from 'react-papaparse';

namespace DownloadHelpers {
  export const FileFormat = {
    JSON: 'json',
    CSV: 'csv',
  };

  export enum FileMimeType {
    JSON = 'application/json',
    CSV = 'text/csv',
    PNG = 'image/png',
    JPEG = 'image/jpeg',
    JPG = 'image/jpg',
    PDF = 'application/pdf',
  }

  export const createDownloadLink = (data: string, mimeType: string) => {
    const blob = new Blob([data], {
      type: mimeType,
    });

    return URL.createObjectURL(blob);
  };

  export const exportAs = {
    JSON: (data: {}) =>
      createDownloadLink(JSON.stringify(data), FileMimeType.JSON),
    CSV: (data: {}[]) => createDownloadLink(jsonToCSV(data), FileMimeType.CSV),
  };

  export const downloadURI = (
    filename: string,
    uri: string,
    callback?: <T extends null>(value?: T) => void
  ) => {
    const link = document.createElement('a');

    link.download = filename;
    link.href = uri;

    document.body.appendChild(link);
    link.click();
    if (callback) callback();
    try {
      document.body.removeChild(link);
    } catch (e) {
      // errors in tests
    }
  };

  /**
   * Takes a gzipped json, sent encoded in base64, and converts it back to JSON
   *
   * @param data b64 string
   * @param sortFn (optional) A function to compare two entries.
   *   If this is provided, the gzipped json is assumed to be an array of T's,
   *   rather than an object of type T (the default)
   * @returns {T}
   */
  export function processGzippedJson<T = {}>(data: string): T;
  export function processGzippedJson<T = {}>(
    data: string,
    sortFn?: (a: T, b: T) => number
  ): T[];
  export function processGzippedJson<T = {}>(
    data: string,
    sortFn?: (a: T, b: T) => number
  ): T | T[] {
    const td = new TextDecoder();
    const inflated = inflate(Buffer.from(data, 'base64'));
    const text = td.decode(inflated);
    const unsorted = JSON.parse(text);
    if (!sortFn) {
      return unsorted;
    }
    return (unsorted as T[]).sort(sortFn);
  }

  // https://docs.amplify.aws/lib/storage/download/q/platform/js/#get
  export const downloadS3File = (
    key: string,
    config: StorageGetConfig<Record<string, any>>
  ) => Storage.get(key, config);
}

export default DownloadHelpers;

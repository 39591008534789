import onRoutePortClusterClick from './route-port-cluster-onClick';
import onRoutePortClick from './route-port-onClick';

namespace RoutePortsController {
  export const layerEvents = {
    onClick: onRoutePortClick,
    onClusterClick: onRoutePortClusterClick,
  };
}

export default RoutePortsController;

import { Box, Button, List, ListItem, Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setMeasurements } from '../tools-panel.slice';
import MeasurementForm from './measurement-form';
import { Measurement } from './measurement.model';
import onMeasurementsUpdate from './measuring-tool-helpers';

function MeasuringTool() {
  const measurements = useAppSelector(
    (state) => state.toolsPanel.measuringTool.measurements
  );

  const { mapInitialised } = useAppSelector((state) => state.map);
  const dispatch = useAppDispatch();

  const updateMeasurements = (updatedMeasurements: Measurement[]) => {
    dispatch(setMeasurements(updatedMeasurements));

    if (mapInitialised) {
      onMeasurementsUpdate(updatedMeasurements);
    }
  };

  const addMeasurement = () => {
    const measurement: Measurement = {
      id: uniqueId('measurement_'),
      title: `Measurement ${measurements.length + 1}`,
      points: [
        {
          longitude: '',
          latitude: '',
        },
        {
          longitude: '',
          latitude: '',
        },
      ],
    };

    updateMeasurements([...measurements, measurement]);
  };

  const clearAllMeasurements = () => {
    updateMeasurements([]);
  };

  const removeMeasurement = (measurement: Measurement) => {
    const updatedMeasurements = measurements.filter(
      (m) => m.id !== measurement.id
    );
    updateMeasurements(updatedMeasurements);
  };

  const updateMeasurement = (measurement: Measurement) => {
    const updatedMeasurements = [...measurements];
    const selectedMeasurementIndex = measurements.findIndex(
      (m) => m.id === measurement.id
    );

    updatedMeasurements[selectedMeasurementIndex] = measurement;
    updateMeasurements(updatedMeasurements);
  };

  return (
    <Box
      data-testid="measuring-tool"
      sx={{
        flexGrow: 1,
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <Box
        p={0.5}
        sx={{
          height: '85%',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          scrollbarWidth: 'thin',
          marginBottom: '2rem',
        }}
      >
        {measurements.length === 0 && (
          <Typography>No measurements to display</Typography>
        )}
        <List
          sx={{
            textAlign: 'left',
          }}
        >
          {measurements.map((measurement) => (
            <ListItem key={measurement.id}>
              <MeasurementForm
                measurement={measurement}
                onRemove={(m: Measurement) => removeMeasurement(m)}
                onUpdate={(m: Measurement) => updateMeasurement(m)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box
        p={0.5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Button variant="outlined" onClick={addMeasurement}>
          Add New Measurement
        </Button>
        <Button
          variant="contained"
          onClick={clearAllMeasurements}
          sx={{
            marginTop: '5px',
          }}
        >
          Clear All Measurements
        </Button>
      </Box>
    </Box>
  );
}

export default MeasuringTool;

import { MaritimeArea } from '../../../models/risk_intelligence.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapLayer from '../map-layer.enum';

function setMaritimeAreasFeatures(
  layerId: MapLayer | string,
  areas: MaritimeArea[]
) {
  const featureCollection: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: areas.map((area) => ({
      type: 'Feature',
      geometry: area.geo,
      properties: {
        id: area.id,
        title: area.title,
      },
    })),
  };

  GeoHelper.setMapboxGeoJSONSourceData(layerId, featureCollection);
}

export default setMaritimeAreasFeatures;

/* eslint-disable react/jsx-props-no-spreading */
import { InputLabelProps, TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { useState } from 'react';

interface MuiFormikTextFieldProps {
  name: string;
  initialHelperText?: string;
  dataTestId?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  oneWayBinding?: boolean;
}

/**
 * A Formik-connected MUI TextField component
 *
 * @param name - the formik-name of the field in the form
 * @param label - the label for the TextField. Defaults to the name, but this can be not very human readable, recommend providing a label
 * @param required - whether the field is required. Changes the appearance of the field slightly
 * @param hiddenLabel - whether the label should be hidden. Defaults to false
 * @param multiline - whether the field should be a multiline textbox. Defaults to false
 * @param initialHelperText - the initial ghost-content for the field. Defaults to an empty string
 * @param sx - the sx styling prop for the TextField
 * @param dataTestId - the data-testid for the TextField for testing purposes
 * @param ariaLabel - the aria-label for the TextField for accessibility purposes
 * @param autoFocus - whether the TextField should be focused on mount
 * @param oneWayBinding - whether the TextField should be one-way bound.
 *    This means it takes the data from Formik but does not live-update
 *    back to formik with onChange/onBlur.
 *    This means updating back the formik value MUST be done by the parent component somewhere.
 *    This is sometimes necessary for large forms, but really should be avoided.
 *    Defaults to false
 * @returns
 */
export default function MuiFormikTextField({
  name,
  label = name,
  required = false,
  hiddenLabel = false,
  multiline = false,
  initialHelperText,
  sx,
  dataTestId,
  ariaLabel,
  autoFocus,
  oneWayBinding = false,
  ...rest
}: MuiFormikTextFieldProps & TextFieldProps) {
  const [field, meta] = useField(name);
  const [tempValue, setTempValue] = useState(field?.value);

  const labelProps: InputLabelProps = {
    sx: { visibility: hiddenLabel ? 'hidden' : 'initial' },
  };
  // if hiddenLabel is true, we want to set shrink to false so that the label doesn't overlap with the input
  // but otherwise we want it unset so that MUI controls it
  if (hiddenLabel) {
    labelProps.shrink = false;
  }

  const fieldPropsToAdd: Partial<typeof field> = { ...field };

  if (oneWayBinding) {
    fieldPropsToAdd.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setTempValue(e.target.value);
    };
    fieldPropsToAdd.value = tempValue;
    delete fieldPropsToAdd.onBlur;
  }

  return (
    <TextField
      {...rest}
      {...fieldPropsToAdd}
      // meta can be undefined if the field is not registered with Formik
      error={Boolean(meta?.touched && meta?.error)}
      helperText={(meta?.touched && meta?.error) || initialHelperText || ''}
      label={label || name}
      InputLabelProps={labelProps}
      InputProps={{
        inputProps: { 'aria-label': ariaLabel, 'data-testid': dataTestId },
      }}
      required={required}
      fullWidth
      multiline={multiline}
      rows={(multiline && 4) || (0 as number)}
      margin="dense"
      sx={sx}
      autoFocus={autoFocus}
    />
  );
}

MuiFormikTextField.defaultProps = {
  initialHelperText: '',
  dataTestId: '',
  ariaLabel: '',
  autoFocus: false,
  oneWayBinding: false,
};

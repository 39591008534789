/* eslint-disable import/prefer-default-export */
import { TravelAdvice } from '../../dossiers_and_panels/country-dossier/travel-advice-tab/travel-advice.model';
import { wrapRequest } from '../base';

export const getTravelAdvice = async (
  countryCodes: string[]
): Promise<TravelAdvice[]> =>
  wrapRequest('get', 'north_standard', '/daas-proxy', {
    queryStringParameters: {
      endpoint: 'travel-advice',
      'target-date': new Date().toISOString(),
      'iso-country-codes': countryCodes
        .map((code) => code.toUpperCase())
        .join(','),
    },
  });

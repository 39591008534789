import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

interface CommonCloseButtonInterface {
  onClick: () => void;
  testId?: string;
}

export default function CommonCloseButton({
  onClick,
  testId,
}: CommonCloseButtonInterface) {
  return (
    <IconButton
      sx={{
        position: 'absolute',
        justifyContent: 'right',
        top: '1vh',
        right: '1vh',
        zIndex: 1,
      }}
      onClick={onClick}
    >
      <CloseIcon data-testid={testId} />
    </IconButton>
  );
}

CommonCloseButton.defaultProps = {
  testId: 'CommonCloseIcon',
};

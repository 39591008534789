import { Expression } from 'mapbox-gl';
import { VesselSource } from '../models/vessel.model';
import {
  GENERIC_VESSEL_SIZE,
  VESSEL_COLOUR_GREEN,
  VESSEL_COLOUR_WHITE,
} from './vessel-constants.utils';

const prepareVesselColourData = () => {
  const iconColour: Expression = [
    'match',
    ['get', 'source'],
    VesselSource.AIS,
    VESSEL_COLOUR_WHITE,
    VesselSource.RISK_INTELLIGENCE,
    VESSEL_COLOUR_GREEN,
    VESSEL_COLOUR_WHITE,
  ];
  return iconColour;
};

const prepareVesselStylingData = () => {
  const sizeMultiplier = 1;

  const size: Expression = [...GENERIC_VESSEL_SIZE];
  size[6] *= sizeMultiplier;
  size[8] *= sizeMultiplier;
  size[10] *= sizeMultiplier;

  const colour = prepareVesselColourData();

  return {
    size,
    colour,
  };
};

export default prepareVesselStylingData;

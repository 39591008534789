import { S3ProviderListOutputItem, Storage } from '@aws-amplify/storage';
import getStorageFileDirectory from '../common-components/upload-entity-documents-modal/upload-entity-documents.utils';
import {
  DocumentWithMeta,
  EntityDocument,
  EntityType,
  FileOwner,
  FileOwnerToStorageLevel,
  IDocument,
  StorageLevel,
  serializeS3ProviderListOutputItem,
} from '../models/document';
import { UserFeatureFlags } from '../user/user.slice';
import DateTimeHelpers from '../utils/date-time-helpers.utils';
import DownloadHelpers from '../utils/download.utils';
import { getFilenameFromDirectory } from '../utils/text-formatting.utils';
import { wrapRequest } from './base';

interface PutDocumentMetaDataParams {
  file: File;
  entityId: string;
  entityType: string;
  description: string;
  name: string;
}

export const getStorageLevel = (fileOwner: FileOwner): StorageLevel =>
  FileOwnerToStorageLevel[fileOwner];

export const putDocumentMetadata = async ({
  file,
  entityId,
  entityType,
  name = '',
  description = '',
}: PutDocumentMetaDataParams): Promise<'Successfully updated document information'> =>
  wrapRequest('put', 'north_standard', `/documents`, {
    body: {
      // display name for document
      filename: name,
      date_uploaded: DateTimeHelpers.dateToIso8601(new Date()),
      file_date: DateTimeHelpers.dateToIso8601(new Date(file.lastModified)),
      description,
      s3_location: `${getStorageFileDirectory(
        StorageLevel.PRIVATE,
        entityId
      )}/${file.name}`,
      entity_id: entityId,
      entity_type: entityType,
    },
  });

export const getDocumentMetadata = async (
  entity_id?: string
): Promise<EntityDocument[]> => {
  const params = entity_id
    ? { queryStringParameters: { entity_id } }
    : undefined;
  return wrapRequest('get', 'north_standard', '/documents', params);
};

export const deleteDocumentMetadata = async (
  documentId: string
): Promise<void> =>
  wrapRequest('del', 'north_standard', '/documents', {
    body: {
      document_id: documentId,
    },
  });

// These functions are technically not API calls,
// but we never use the S3 objects without enriching them with metadata from the api, so they can live here

const addMetadataToS3Objects = async (
  s3Objects: IDocument[],
  metadataPromise: Promise<EntityDocument[]>
): Promise<DocumentWithMeta[]> => {
  const metadata = await metadataPromise;
  return s3Objects
    .map((s3Object) => {
      const metaDoc = metadata.find((m) => m.s3_location === s3Object.key);
      if (metaDoc) {
        return {
          ...s3Object,
          metadata: metaDoc,
        } as DocumentWithMeta;
      }
      // Can't handle documents without metadata
      return null;
    })
    .filter(Boolean) as DocumentWithMeta[];
};

const serializeResults = (results: S3ProviderListOutputItem[]): IDocument[] =>
  results.map((result) => serializeS3ProviderListOutputItem(result));

/**
 * Get S3 Docs from Amplify Storage, and add metadata from the API
 * Supports passing through filters to getDocumentMetadata
 * @param body
 * @returns
 */

function getDocsPromise(metadataPromise: Promise<any>, entityId?: string) {
  return Storage.list(getStorageFileDirectory(StorageLevel.PRIVATE, entityId), {
    level: StorageLevel.PRIVATE,
  }).then(({ results }) => {
    const serialized = serializeResults(results);
    const docsWithMetadata = addMetadataToS3Objects(
      serialized,
      metadataPromise
    );
    return docsWithMetadata;
  });
}

export const EntityTypeToFeatureFlag: Partial<
  Record<EntityType, keyof UserFeatureFlags>
> = {
  [EntityType.port]: 'ports_license' as keyof UserFeatureFlags,
};

export const filterDocumentsOnUsersLicense = (
  documents: DocumentWithMeta[],
  licenses: UserFeatureFlags | null
) =>
  documents.filter(({ metadata }) => {
    const license = EntityTypeToFeatureFlag[metadata.entity_type as EntityType];
    return license ? licenses?.[license] : true;
  });

export const getS3DocsWithMetadata = async (
  entityId?: string
): Promise<{
  myDocuments: DocumentWithMeta[];
}> => {
  const myDocumentsMetaPromise = getDocumentMetadata(entityId);

  const myDocuments = await getDocsPromise(myDocumentsMetaPromise, entityId);

  return {
    myDocuments,
  };
};

export const downloadDocument = async (document: IDocument) => {
  const { key, metadata } = document;

  if (!metadata) {
    return Promise.reject(Error('No metadata found for document'));
  }

  return DownloadHelpers.downloadS3File(key!, {
    level: StorageLevel.PRIVATE,
  })
    .then((uri) =>
      DownloadHelpers.downloadURI(getFilenameFromDirectory(key!) as string, uri)
    )
    .catch(() => {});
};

export const deleteDocument = async (document: DocumentWithMeta) => {
  const getDocumentDeletePromise = async () =>
    Storage.remove(document.key!, {
      level: StorageLevel.PRIVATE,
    });

  const getDocumentMetadataDeletePromises = () =>
    deleteDocumentMetadata(document.metadata.document_id!);

  return Promise.all([
    getDocumentDeletePromise(),
    getDocumentMetadataDeletePromises(),
  ]);
};

import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { updateUserPreferences } from '../../api/user-preferences';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { EMenuItems } from '../../main-menu/menu.slice';
import useMainMenuLayerConfig from '../../main-menu/use-main-menu-layer-config';
import MapLayerManager from '../../map/map-layer-manager/map-layer-manager.utils';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../map/map.utils';
import { IncidentFilters } from '../../maritime-menu-options/incidents-panel/incident.model';
import {
  UserPreferences,
  setUserPreferences,
} from '../../user-settings/user-preferences/user-preferences.slice';
import SimpleDialog from '../dialog/SimpleDialog';
import FormErrorMessage from '../form-messages/form-error-message';
import FormLoadingMessage from '../form-messages/form-loading-message';
import FormSuccessMessage from '../form-messages/form-success-message';
import './save-setup.scss';

interface RenderSaveSetupModalProps {
  loading: boolean;
  success: boolean;
  error: boolean;
}

function RenderSaveSetupModal({
  loading,
  error,
  success,
}: RenderSaveSetupModalProps) {
  return (
    <Box data-testid="save-setup-modal-container">
      {loading && <FormLoadingMessage message="Saving setup..." />}
      {!loading && error && <FormErrorMessage message="Error saving setup!" />}
      {!loading && !error && success && (
        <FormSuccessMessage message="Successfully saved setup!" />
      )}

      {!loading && !error && !success && (
        <Typography variant="body1" sx={{ mb: '4rem', mt: '2rem' }}>
          Are you sure you want to save this setup? This will overwrite any
          existing setup you may have saved.
        </Typography>
      )}
    </Box>
  );
}

interface SaveSetupModalProps {
  visible: boolean;
  onClose: () => void;
}

function SaveSetupModal({ onClose, visible }: SaveSetupModalProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const cognitoId = useAppSelector(
    (state) => state.user.idToken?.sub
  ) as string;
  const userPreferences = useAppSelector(
    (state) => state.userPreferences.userPreferences
  );

  const vesselHistoryFormPreferences = useAppSelector(
    (state) => state.historyPanel.formValues
  );

  const menu = useAppSelector((state) => state.menu);

  const layersByEMenu = useMainMenuLayerConfig();

  const filteredLayersByEMenu: {
    [key: string]: { layers: MapLayer[]; disabled: boolean };
  } = {};

  Object.keys(layersByEMenu).forEach((key) => {
    const { layers, layerGroups } =
      layersByEMenu[key as keyof typeof layersByEMenu];

    const visibleTopLevelLayers: string[] = layers.filter(
      (layer) => MapHelpers.isLayerVisible(layer) === true
    );

    const visibleChildLayers: string[] =
      MapLayerManager.getLayerIdsFromSourceGroups(layerGroups).filter(
        (layer) => MapHelpers.isLayerVisible(layer) === true
      );

    filteredLayersByEMenu[key] = {
      layers: [...visibleChildLayers, ...visibleTopLevelLayers] as MapLayer[],
      disabled:
        layersByEMenu[key as keyof typeof layersByEMenu].disabled ?? true,
    };
  });

  const incidentFilters = useAppSelector(
    (state) => state.incidentsPanel.filters as IncidentFilters
  );

  const { mapZoom } = useAppSelector((state) => state.map);

  const portsFilter = useAppSelector((state) => state.portsPanel.filters);

  const dispatch = useAppDispatch();

  const mergedUserPreferences: UserPreferences = {
    basemap: userPreferences.basemap,
    showRiNewsBanner: userPreferences.showRiNewsBanner,
    zoomToIncident: userPreferences.zoomToIncident,
    showDatumRings: userPreferences.showDatumRings,
    mapExtent: {
      center: [MapHelpers.getCenter().lng, MapHelpers.getCenter().lat],
      zoom: mapZoom,
    },
    menu,
    drawingsPanel: {
      layers: filteredLayersByEMenu[EMenuItems.DRAWINGS].layers as MapLayer[],
      disabled: layersByEMenu[EMenuItems.DRAWINGS].disabled ?? true,
    },
    routesPanel: {
      layers: filteredLayersByEMenu[EMenuItems.ROUTES].layers as MapLayer[],
      disabled: layersByEMenu[EMenuItems.ROUTES].disabled ?? true,
    },
    historyPanel: {
      layers: filteredLayersByEMenu[EMenuItems.HISTORY].layers as MapLayer[],
      disabled: layersByEMenu[EMenuItems.HISTORY].disabled ?? true,
      formValues: vesselHistoryFormPreferences,
    },
    RIMaritimeAreasPanel: {
      layers: filteredLayersByEMenu[EMenuItems.RI_MARITIME_AREAS]
        .layers as MapLayer[],
      disabled: layersByEMenu[EMenuItems.RI_MARITIME_AREAS].disabled ?? true,
    },
    openSeaMapsPanel: {
      layers: filteredLayersByEMenu[EMenuItems.MAPS].layers as MapLayer[],
      disabled: layersByEMenu[EMenuItems.MAPS].disabled ?? true,
    },
    incidentsPanel: {
      layers: filteredLayersByEMenu[EMenuItems.INCIDENTS].layers as MapLayer[],
      disabled: layersByEMenu[EMenuItems.INCIDENTS].disabled ?? true,
      filters: incidentFilters,
    },
    boundariesPanel: {
      layers: filteredLayersByEMenu[EMenuItems.BOUNDARIES].layers as MapLayer[],
      disabled: layersByEMenu[EMenuItems.BOUNDARIES].disabled ?? true,
    },
    worldPortsPanel: {
      layers: filteredLayersByEMenu[EMenuItems.PORTS].layers as MapLayer[],
      disabled: layersByEMenu[EMenuItems.PORTS].disabled ?? true,
      filters: portsFilter,
    },
  };

  const onUpdateUserPreferences = () => {
    setLoading(true);
    dispatch(setUserPreferences(mergedUserPreferences));
    updateUserPreferences(cognitoId, mergedUserPreferences)
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const actions = (
    <>
      <Button variant="outlined" onClick={onClose} disabled={loading}>
        {success || error ? 'Close' : 'Cancel'}
      </Button>
      {!success && !loading && !error && (
        <Button
          variant="contained"
          onClick={onUpdateUserPreferences}
          disabled={loading}
        >
          Save
        </Button>
      )}
    </>
  );

  return (
    <SimpleDialog
      open={visible}
      onClose={onClose}
      title="Save Setup"
      actions={actions}
    >
      <RenderSaveSetupModal error={error} loading={loading} success={success} />
    </SimpleDialog>
  );
}

export default SaveSetupModal;

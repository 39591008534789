import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  MaritimeAisApiLocation,
  MaritimeAisApiLocationDefined,
} from '../../../../models/maritime-ais-api';

interface NearbyVesselsResultsProps {
  results: MaritimeAisApiLocation[];
}

function NearbyVesselsResults(props: NearbyVesselsResultsProps) {
  const { results } = props;

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 860,
        maxHeight: '100%',
        overflow: 'auto',
      }}
    >
      {(
        Object.values(results).filter(
          (value) => value.vessel
        ) as MaritimeAisApiLocationDefined[]
      ).map((vesselData) => {
        const { staticData } = vesselData.vessel;
        return (
          /* eslint-disable no-underscore-dangle */
          <ListItem
            alignItems="flex-start"
            key={`${vesselData.vessel._id}}`}
            onClick={() => undefined}
            disableGutters
            disablePadding
            sx={{
              borderBottom: '1px solid',
              borderBottomColor: 'background.paper',
            }}
          >
            <ListItemButton>
              <ListItemText
                primary={staticData.name ? staticData.name : 'No Name'}
                secondary={
                  <Typography component="span" variant="body2">
                    {staticData.mmsi && (
                      <>
                        <b>MMSI</b> {staticData.mmsi}{' '}
                      </>
                    )}
                    {staticData.imo && (
                      <>
                        <b>IMO</b> {staticData.imo}
                      </>
                    )}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default NearbyVesselsResults;

import {
  RangeRingCalcOpt,
  RangeRingDistanceProperties,
  RangeRingDurationProperties,
  RangeRingPoint,
  RangeRingProperties,
  RangeRingSpeedProperties,
} from '../../../maritime-menu-options/tools-panel/range-rings/range-ring.model';
import store from '../../../store';
import DateTimeHelpers from '../../../utils/date-time-helpers.utils';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { convertNauticalMilesToKm } from '../../../utils/measurement-helpers';
import { DMSToDecimal } from '../../map-controls.utils';
import { setPaintProperty } from '../../map.slice';
import { RangeRingLayers } from '../map-layer.enum';

interface RangeRingOptions {
  layerId: string;
  radius: number;
  coordinates: [number, number];
  colour: string;
}

interface RangeRingLabelOptions {
  layerId: string;
  coordinates: [number, number];
  label: string;
}

export const calculateDuration = (
  distanceNeuticalMiles: number,
  speedNmPerHour: number
): { hours: number; minutes: number } => {
  const hours = distanceNeuticalMiles / speedNmPerHour;
  const minutes = (hours % 1) * 60;
  return {
    hours: Math.floor(hours),
    minutes: Math.round(minutes),
  };
};

export const setRangeRingLabelFeature = (options: RangeRingLabelOptions) => {
  const { layerId, coordinates, label } = options;

  const feature = GeoHelper.createFeaturePoint(coordinates, {
    label,
  });
  const geojson = GeoHelper.createGeoJSON([feature]);

  GeoHelper.setMapboxGeoJSONSourceData(layerId, geojson);
};

export const setRangeRingFeature = (options: RangeRingOptions) => {
  const { layerId, radius, coordinates, colour } = options;

  const rangeRingPointFeature = GeoHelper.createFeatureCircle(
    GeoHelper.createCircle(radius, coordinates[0], coordinates[1]),
    {}
  );

  const rangeRingPolygonFeature = GeoHelper.convertFeatureCircleToPolygon(
    rangeRingPointFeature
  );

  GeoHelper.setMapboxGeoJSONSourceData(
    layerId,
    GeoHelper.createGeoJSON([rangeRingPolygonFeature])
  );
  store.dispatch(
    setPaintProperty({
      layerId,
      name: 'line-color',
      value: colour,
    })
  );
};

export const setRangeRingPointFeatures = (point: RangeRingPoint) => {
  const longitude = DMSToDecimal(point.centre.longitude);
  const latitude = DMSToDecimal(point.centre.latitude);

  if (longitude === null || latitude === null) {
    return;
  }

  const feature = GeoHelper.createFeaturePoint([longitude, latitude], {});
  const geojson = GeoHelper.createGeoJSON([feature]);

  GeoHelper.setMapboxGeoJSONSourceData(point.id, geojson);

  const setRangeRingFeatures = (
    ring: RangeRingProperties,
    distanceNauticalMiles: number,
    speed: number,
    hours: number,
    minutes: number
  ) => {
    const radius = convertNauticalMilesToKm(distanceNauticalMiles);
    const circleCoords = GeoHelper.createCircumferenceCoordinates(
      GeoHelper.createCircle(radius, longitude, latitude)
    );

    // place label on top of circle
    const labelCoordinates = [
      longitude,
      Math.max.apply(
        null,
        circleCoords.map((coordinates) => coordinates[1])
      ),
    ];

    const label = radius
      ? `Range: ${parseFloat(`${distanceNauticalMiles}`).toFixed(
          2
        )} nautical miles \n(${hours}hrs ${minutes}mins @ ${speed ?? 0} knot${
          speed && speed > 1 ? 's' : ''
        })`
      : '';

    setRangeRingFeature({
      layerId: ring.id,
      radius,
      coordinates: [longitude, latitude],
      colour: ring.colour,
    });

    setRangeRingLabelFeature({
      label,
      layerId: RangeRingLayers.LABEL(ring.id),
      coordinates: labelCoordinates as [number, number],
    });
  };

  point.rings.forEach((ring) => {
    const { calculationOption } = ring;
    if (calculationOption === RangeRingCalcOpt.DISTANCE) {
      const { duration, speed } = ring as RangeRingDistanceProperties;
      const { hours = 0, minutes = 0 } = duration || {};
      const inputSpeed = parseFloat(String(speed));
      const inputHours =
        Number(hours) + Number(minutes) / DateTimeHelpers.MINUTES_IN_HOUR;
      const distanceNauticalMiles = inputSpeed * inputHours;
      setRangeRingFeatures(
        ring,
        distanceNauticalMiles,
        inputSpeed,
        hours,
        minutes
      );
    } else if (calculationOption === RangeRingCalcOpt.DURATION) {
      const { distanceNauticalMiles, speed } =
        ring as RangeRingDurationProperties;
      const { hours, minutes } = calculateDuration(
        distanceNauticalMiles,
        speed
      );
      setRangeRingFeatures(
        ring,
        distanceNauticalMiles,
        speed,
        Number(hours),
        Number(minutes)
      );
    } else if (calculationOption === RangeRingCalcOpt.SPEED) {
      const { distanceNauticalMiles, duration } =
        ring as RangeRingSpeedProperties;
      const { hours = 0, minutes = 0 } = duration || {};
      const minutesInHours = Number(minutes) / DateTimeHelpers.MINUTES_IN_HOUR;
      const totalTime = Number(hours) + minutesInHours;
      const speed: number = parseFloat(
        (distanceNauticalMiles / totalTime).toFixed(3)
      );
      setRangeRingFeatures(ring, distanceNauticalMiles, speed, hours, minutes);
    }
  });
};

import { FormControlLabel, Typography } from '@mui/material';
import LayerToggle from '../../../common-components/layer-toggle/layer-toggle';

interface HistoryResultsLayerItemProps {
  sources: string[];
  label: string;
}

function HistoryResultsLayerItem({
  sources,
  label,
}: HistoryResultsLayerItemProps) {
  return (
    <FormControlLabel
      labelPlacement="start"
      label={
        <Typography
          sx={{
            width: '100%',
            textAlign: 'left',
          }}
          variant="body2"
        >
          {label}
        </Typography>
      }
      control={
        <LayerToggle variant="checkbox" layers={sources} layerGroups={[]} />
      }
      sx={{
        width: '100%',
        paddingRight: '1rem',
      }}
    />
  );
}

export default HistoryResultsLayerItem;

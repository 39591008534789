/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IndustryNews } from '../../models/industry-news.model';

interface IndustryNewsState {
  industryNews: IndustryNews[] | null;
  selectedIndustryNews: IndustryNews | null;
  selectedIndustryNewsId: IndustryNews | string | null;
}

export const INITIAL_INDUSTRY_NEWS_STATE: IndustryNewsState = {
  industryNews: null,
  selectedIndustryNews: null,
  selectedIndustryNewsId: null,
};

const IndustryNewsSlice = createSlice({
  name: 'industryNews',
  initialState: INITIAL_INDUSTRY_NEWS_STATE,
  reducers: {
    setIndustryNews: (state, action: PayloadAction<IndustryNews[] | null>) => {
      state.industryNews = action.payload;
    },
    setSelectedIndustryNews: (
      state,
      action: PayloadAction<IndustryNews | undefined | null>
    ) => {
      const prevSelectedIndustryNewsId = state.selectedIndustryNewsId
        ? state.selectedIndustryNews?.id
        : undefined;
      const newSelectedIndustryNewsId = action.payload
        ? action.payload.id
        : undefined;

      state.selectedIndustryNews = action.payload
        ? {
            ...action.payload,
            selected: true,
          }
        : null;

      if (action.payload === null && state.industryNews) {
        state.industryNews = state.industryNews.map((news) => ({
          ...news,
          selected: false,
        }));
      }

      if (state.industryNews) {
        if (newSelectedIndustryNewsId) {
          // set the selected property to true for the new selected industry news
          state.industryNews = state.industryNews.map((news) => {
            if (news.id === newSelectedIndustryNewsId) {
              return {
                ...news,
                selected: true,
              };
            }
            return news;
          });
        }

        // if no new selected industry news, deselect the previous industry news
        if (
          prevSelectedIndustryNewsId &&
          prevSelectedIndustryNewsId !== newSelectedIndustryNewsId
        ) {
          state.industryNews = state.industryNews.map((news) => {
            if (news.id === prevSelectedIndustryNewsId) {
              return {
                ...news,
                selected: false,
              };
            }
            return news;
          });
        }
      }
    },
    setSelectedIndustryNewsId: (
      state,
      action: PayloadAction<IndustryNews | string | null>
    ) => {
      state.selectedIndustryNewsId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setIndustryNews,
  setSelectedIndustryNews,
  setSelectedIndustryNewsId,
} = IndustryNewsSlice.actions;

export default IndustryNewsSlice.reducer;

export interface WeatherFormValues {
  location: {
    latitude: string | null;
    longitude: string | null;
  };
  startDate: string | null;
  endDate: string | null;
}

export interface WeatherMeta {
  cost: number;
  dailyQuota: number;
  lat: number;
  lng: number;
  requestCount: number;
}

export interface WeatherHourlyDataValue {
  value: string;
  yLabel: string;
  yScale?: {
    min: number;
    max: number;
  };
}

export const WeatherSourceName: Record<string, string> = {
  sg: 'Stormglass',
  icon: 'ICON by Deutscher Wetterdienst',
  dwd: 'Deutscher Wetterdienst',
  noaa: 'US National Oceanic and Atmospheric Administration',
  meto: 'UK MetOffice',
  meteo: 'Météo-France',
  fcoo: 'Danish Defence Centre for Operational Oceanography',
  fmi: 'Finnish Meteorological Institute',
  yr: 'Norwegian Meteorological Institute and NRK',
  smhi: 'Swedish Meteorological and Hydrological Institute',
};

export const WeatherResultName: Omit<
  Record<keyof WeatherHourlyData, string>,
  'time'
> = {
  windSpeed: 'Wind Speed',
  windDirection: 'Wind Direction',
  airTemperature: 'Air Temperature',
  pressure: 'Pressure',
  cloudCover: 'Cloud Cover',
  iceCover: 'Ice Cover',
  precipitation: 'Precipitation',
  visibility: 'Visibility',
  waveHeight: 'Wave Height',
  waveDirection: 'Wave Direction',
  wavePeriod: 'Wave Period',
  currentSpeed: 'Current Speed',
  currentDirection: 'Current Direction',
};

export const WeatherUnits: Omit<
  Record<keyof WeatherHourlyData, WeatherHourlyDataValue>,
  'time'
> = {
  windSpeed: {
    value: ' m/s',
    yLabel: ' m/s',
  },
  windDirection: {
    value: '°',
    yLabel: 'Degrees',
    yScale: {
      min: 0,
      max: 360,
    },
  },
  airTemperature: {
    value: '°C',
    yLabel: '°C',
  },
  pressure: {
    value: ' hPa',
    yLabel: 'hPa',
  },
  cloudCover: {
    value: '%',
    yLabel: '%',
  },
  iceCover: {
    value: '%',
    yLabel: '%',
    yScale: {
      min: 0,
      max: 100,
    },
  },
  precipitation: {
    value: ' kg/m²',
    yLabel: 'kg/m²',
  },
  visibility: {
    value: ' km',
    yLabel: 'km',
  },
  waveHeight: {
    value: ' m',
    yLabel: 'Metres',
  },
  waveDirection: {
    value: '°',
    yLabel: 'Degrees',
    yScale: {
      min: 0,
      max: 360,
    },
  },
  wavePeriod: {
    value: ' s',
    yLabel: 'Seconds',
  },
  currentSpeed: {
    value: ' m/s',
    yLabel: 'm/s',
  },
  currentDirection: {
    value: '°',
    yLabel: 'Degrees',
    yScale: {
      min: 0,
      max: 360,
    },
  },
};

// Depending on where on the globe the request is made, different sources are available
export type WeatherHourlyDataBase = Partial<
  Record<keyof typeof WeatherSourceName, number>
> & { sg: number }; // sg (stormglass) is always available

export interface NoaaData extends WeatherHourlyDataBase {
  noaa: number;
}

// yes, theres meto _and_ meteo
export interface MetoData extends WeatherHourlyDataBase {
  meto: number;
}

export interface MeteoData extends WeatherHourlyDataBase {
  meteo: number;
}

export interface WeatherHourlyData {
  airTemperature: WeatherHourlyDataBase;
  cloudCover: WeatherHourlyDataBase;
  currentDirection: WeatherHourlyDataBase;
  currentSpeed: WeatherHourlyDataBase;
  iceCover: WeatherHourlyDataBase;
  precipitation: WeatherHourlyDataBase;
  pressure: WeatherHourlyDataBase;
  time: string;
  visibility: WeatherHourlyDataBase;
  waveDirection: WeatherHourlyDataBase;
  waveHeight: WeatherHourlyDataBase;
  wavePeriod: WeatherHourlyDataBase;
  windDirection: WeatherHourlyDataBase;
  windSpeed: WeatherHourlyDataBase;
}

export interface TidalExtremesData {
  height: number;
  time: string;
  type: 'high' | 'low';
}

export interface TideStation {
  distance: number;
  lat: number;
  lng: number;
  name: string;
  source: string;
}

export interface TideMeta extends WeatherMeta {
  start: string;
  end: string;
  datum: string;
  station: TideStation;
}

export interface AstronomyDailyData {
  astronomicalDawn: string;
  astronomicalDusk: string;
  civilDawn: string;
  civilDusk: string;
  moonFraction: number;
  moonPhase: {
    closest: {
      text: string;
      time: string;
      value: number;
    };
    current: {
      text: string;
      time: string;
      value: number;
    };
  };
  moonrise: string;
  moonset: string;
  nauticalDawn: string;
  nauticalDusk: string;
  sunrise: string;
  sunset: string;
  time: string;
}

export interface WeatherResponse {
  weather: {
    hours: WeatherHourlyData[];
    meta: WeatherMeta & {
      start: string;
      end: string;
      params: (keyof WeatherHourlyData)[];
    };
    errors?: {
      key: string;
    };
  };
  astronomy: {
    meta: WeatherMeta & {
      start: string;
    };
    data: AstronomyDailyData[];
    errors?: {
      key: string;
    };
  };
  elevation: {
    meta: WeatherMeta & {
      distance: number;
      elevation: {
        source: string;
        unit: string;
      };
    };
    data: {
      elevation: number;
    };
    errors?: {
      key: string;
    };
  };
  tidal_extremes: {
    meta: TideMeta;
    data: TidalExtremesData[];
    errors?: {
      key: string;
    };
  };
}

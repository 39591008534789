import dayjs from 'dayjs';

export const SetDateIntervals = {
  ONE_DAY: '1d',
  ONE_WEEK: '7d',
  TWO_WEEKS: '14d',
  ONE_MONTH: '1m',
  THREE_MONTHS: '3m',
  SIX_MONTHS: '6m',
  TWELVE_MONTHS: '12m',
  THIS_YEAR: 'this_year',
};

export const SetDateIntervalStrings = Object.values(SetDateIntervals);

export const DateRangePresets = {
  ...SetDateIntervals,
  2019: '2019',
  2020: '2020',
  2021: '2021',
  2022: '2022',
};

export const DateRangeMutators = {
  [DateRangePresets.ONE_DAY]: () => [dayjs().subtract(1, 'day'), dayjs()],
  [DateRangePresets.ONE_WEEK]: () => [dayjs().subtract(1, 'week'), dayjs()],
  [DateRangePresets.TWO_WEEKS]: () => [dayjs().subtract(2, 'weeks'), dayjs()],
  [DateRangePresets.ONE_MONTH]: () => [dayjs().subtract(1, 'month'), dayjs()],
  [DateRangePresets.THREE_MONTHS]: () => [
    dayjs().subtract(3, 'months'),
    dayjs(),
  ],
  [DateRangePresets.SIX_MONTHS]: () => [dayjs().subtract(6, 'months'), dayjs()],
  [DateRangePresets.TWELVE_MONTHS]: () => [
    dayjs().subtract(12, 'months'),
    dayjs(),
  ],
  [DateRangePresets.THIS_YEAR]: () => [
    dayjs(new Date(new Date().getFullYear(), 0, 1)),
    dayjs(),
  ],
  [DateRangePresets[2019]]: () => [
    dayjs(new Date(2019, 0, 1)),
    dayjs(new Date(2019, 11, 31)),
  ],
  [DateRangePresets[2020]]: () => [
    dayjs(new Date(2020, 0, 1)),
    dayjs(new Date(2020, 11, 31)),
  ],
  [DateRangePresets[2021]]: () => [
    dayjs(new Date(2021, 0, 1)),
    dayjs(new Date(2021, 11, 31)),
  ],
  [DateRangePresets[2022]]: () => [
    dayjs(new Date(2022, 0, 1)),
    dayjs(new Date(2022, 11, 31)),
  ],
};

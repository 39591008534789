import { Info } from '@mui/icons-material';
import { IconButton, Popover, Stack } from '@mui/material';
import { useRef, useState } from 'react';
import zIndexes from '../../z-indexes.scss';

function CommonLegend(iconList: any) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  return (
    <>
      <IconButton
        ref={buttonRef}
        disableRipple
        data-testid="legend-button"
        sx={{
          color: 'secondary.main',
        }}
        onMouseEnter={() => {
          let shouldClose = false;
          document.onmousemove = (moveEvent) => {
            // start a mouse move listener for the popover. Need to ensure if the user mouses somewhere else on the page, then close the popover
            // but not if they mouse back over the button or the popover within a certain time
            const button = document.querySelector(
              'button[data-testid="legend-button"]'
            );
            const popover = document.querySelector('#mouse-over-popover');
            const isPopoverChild = popover?.contains(moveEvent.target as Node);
            const isButtonChild = button?.contains(moveEvent.target as Node);
            // if the user moves outside the popover AND the button, then close after a delay
            if (!isPopoverChild && !isButtonChild) {
              shouldClose = true;
              setTimeout(() => {
                // if shouldClose still false, then close
                if (shouldClose) {
                  setOpen(false);
                }
              }, 250);
            } else {
              // if the user moves within the popover before the timeout, then don't close
              shouldClose = false;
            }
          };
          setOpen(true);
        }}
        onMouseLeave={() => {
          // remove the move listener after a delay
          setTimeout(() => {
            document.onmousemove = null;
          }, 260);
        }}
      >
        <Info data-testid="legend-button-icon" />
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          zIndex: zIndexes['$--feature-panel-contents-layer'],
          maxHeight: '80vh',
        }}
        open={!!open}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { onMouseLeave: () => setOpen(false) } }}
      >
        <Stack
          sx={{
            pointerEvents: 'auto',
            maxHeight: '70vh',
            overflowY: 'auto',
            width: '360px',
            padding: '1rem',
            bgcolor: 'primary.main',
            zIndex: zIndexes['$--feature-panel-contents-layer'],
          }}
        >
          {iconList}
        </Stack>
      </Popover>
    </>
  );
}

export default CommonLegend;

import { UserPreferences } from '../user-settings/user-preferences/user-preferences.slice';
import { UserFeatureFlags } from '../user/user.slice';
import { wrapRequest } from './base';

export interface RiRegionPreferences {
  id: number;
  title: string;
  incidents: string;
  other: string;
}

export interface RiUserPreferences {
  id: number;
  email: string;
  name: {
    first: string;
    last: string;
  };
  phone: string;
  company: number;
  notifications: {
    regions: RiRegionPreferences[];
  };
}

export const getUserProperties = async (
  cognitoId: string
): Promise<{ user_preferences: UserPreferences }> =>
  wrapRequest('get', 'north_standard', `/user-properties`, {
    queryStringParameters: {
      cognito_id: cognitoId,
    },
  });

export const updateUserFeatureFlags = async (
  user: string,
  feature_flags: UserFeatureFlags
) => {
  wrapRequest('patch', 'geonius', `/user/feature-flags`, {
    body: {
      username: user,
      feature_flags: {
        ...feature_flags,
      },
    },
  });
};

export const updateUserPreferences = async (
  cognitoId: string,
  preferences: UserPreferences
) => {
  wrapRequest('put', 'north_standard', `/user-properties`, {
    body: {
      cognito_id: cognitoId,
      user_config: {
        ...preferences,
      },
    },
  });
};

export const getRiUserPreferences = async (
  userId: string
): Promise<RiUserPreferences> =>
  wrapRequest('get', 'geonius', `/risk-intelligence/user-prefs/${userId}`);

export const updateRiUserPreferences = async (
  userId: string,
  preferences: RiUserPreferences
) => {
  wrapRequest('patch', 'geonius', `/risk-intelligence/user-prefs/${userId}`, {
    body: {
      ...preferences,
    },
  });
};

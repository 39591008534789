import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import MapLayer from '../map-layer.enum';

function onFirstCallClusterClick(
  map: mapboxgl.Map,
  firstCallCluster: GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
) {
  const clusterId = firstCallCluster.properties?.cluster_id;
  (
    map.getSource(MapLayer.FIRST_CALL_PORTS) as GeoJSONSource
  ).getClusterExpansionZoom(clusterId, (err, zoom) => {
    if (err) return;

    map.easeTo({
      center: firstCallCluster.geometry.coordinates as [number, number],
      zoom: zoom + 0.5,
    });
  });
}

export default onFirstCallClusterClick;

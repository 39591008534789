import { ListItem, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { IndustryNews } from '../../../models/industry-news.model';
import { addHistoryItem } from '../../../nav-history.slice';
import {
  setSelectedIndustryNews,
  setSelectedIndustryNewsId,
} from '../../../state/industry-news/industry-news.slice';

interface Props {
  news: IndustryNews;
}

function IndustryNewsItem({ news }: Props) {
  const { title, date, id } = news;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedIndustryNewsId } = useAppSelector(
    (state) => state.industryNews
  );

  const readableDateTime = new Date(date).toLocaleDateString('en-GB');

  const handleItemClick = (clickedIndustryNews: IndustryNews) => {
    dispatch(setSelectedDossier(EDossiers.INDUSTRY_NEWS));
    dispatch(addHistoryItem({ type: 'industryNewsList' }));
    dispatch(setSelectedIndustryNews(clickedIndustryNews));
    dispatch(setSelectedIndustryNewsId(clickedIndustryNews.id));
    navigate(`industry-news/${clickedIndustryNews.id}`);
  };

  return (
    // role is not redundant since list-style: none removes the 'semanticity' of a list
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ListItem
      data-testid="industry-news-item"
      sx={{
        display: 'flex',
        width: '100%',
        paddingBottom: '10px',
        borderBottom: '1px solid',
        borderColor: (theme) => theme.palette.background.paper,
        transition: 'background-color ease-in-out 0.1s',
        backgroundColor: id === selectedIndustryNewsId ? '#17273D' : 'inherit',
        '&:hover': {
          backgroundColor: '#17273D',
          cursor: 'pointer',
        },
      }}
      onClick={() => handleItemClick(news)}
    >
      <Typography
        data-testid="industry-news-title-and-date"
        variant="subtitle1"
        sx={{ fontSize: '16px' }}
      >
        {title}
        <br />
        {readableDateTime}
      </Typography>
    </ListItem>
  );
}

export default IndustryNewsItem;

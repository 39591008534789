import './drawing-overlay.scss';

function DrawCompletionOverlay({
  handleClick,
  text,
}: {
  handleClick: () => void;
  text: string;
}) {
  return (
    <button
      data-testid="drawing-panel-overlay"
      className="drawing-panel-overlay"
      type="button"
      onClick={handleClick}
    >
      <div className="drawing-panel-overlay__content">{text}</div>
    </button>
  );
}

export default DrawCompletionOverlay;

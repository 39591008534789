/* eslint-disable import/prefer-default-export */
import {
  IndustryNews,
  IndustryNewsResponse,
} from '../../models/industry-news.model';
import { wrapRequest } from '../base';

const getIndustryNewsPage = async (
  page: number
): Promise<IndustryNewsResponse> =>
  wrapRequest('get', 'north_standard', '/north-standard/industry-news', {
    queryStringParameters: {
      page,
    },
  });

export const getIndustryNews = async (
  page_number: number = 1
): Promise<IndustryNews[]> => {
  let results: IndustryNews[] = [];
  const response = await getIndustryNewsPage(page_number);
  results = response.data;
  if (response.last_page > page_number) {
    results = results.concat(await getIndustryNews(page_number + 1));
  }
  return results;
};

import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Correspondent } from '../../models/correspondents.model';

interface CorrespondentsListItemProps {
  correspondent: Correspondent;
  selected: boolean;
  onClick: (correspondent: Correspondent) => void;
  divider: boolean;
}

function CorrespondentListItem({
  correspondent,
  selected,
  onClick,
  divider,
}: CorrespondentsListItemProps) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { port_information } = correspondent;
  return (
    <ListItem
      divider={divider}
      disablePadding
      onClick={() => onClick(correspondent)}
    >
      <ListItemButton selected={selected} autoFocus={selected}>
        <ListItemText
          primary={port_information.name}
          secondary={port_information.country}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default CorrespondentListItem;

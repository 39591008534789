import { Incident } from '../maritime-menu-options/incidents-panel/incident.model';
import { Port } from '../maritime-menu-options/world-ports-panel/world-ports.model';
import { IndustryNews } from './industry-news.model';
import {
  ExpandedCountry,
  ExtendedMaritimeArea,
} from './risk_intelligence.model';
import { Route } from './routes.model';

interface HistoryItem {
  type:
    | 'port'
    | 'route'
    | 'country'
    | 'portList'
    | 'industryNews'
    | 'industryNewsList'
    | 'incidentList'
    | 'map'
    | 'search'
    | 'RIareaslist'
    | 'RiArea'
    | 'drawings'
    | 'incident'
    | 'routeList'
    | 'alert';
}

interface SkeletonPort {
  NAME: string;
  ri: {
    id: number;
  };
}

export type PortHistoryItem = HistoryItem & {
  type: 'port';
  properties: Port | SkeletonPort;
};

export type IndustryNewsHistoryItem = HistoryItem & {
  type: 'industryNews';
  properties: IndustryNews | SkeletonPort;
};

export type CountryHistoryItem = HistoryItem & {
  type: 'country';
  properties: ExpandedCountry;
};

export type RiAreaHistoryItem = HistoryItem & {
  type: 'RiArea';
  properties: ExtendedMaritimeArea;
};

export type RouteHistoryItem = HistoryItem & {
  type: 'route';
  properties: Route;
};

export type IncidentHistoryItem = HistoryItem & {
  type: 'incident';
  properties: Incident;
};

export type IndividualHistoryItem =
  | PortHistoryItem
  | CountryHistoryItem
  | RiAreaHistoryItem
  | RouteHistoryItem
  | IncidentHistoryItem;

interface NoPropsHistoryItem extends HistoryItem {
  type:
    | 'portList'
    | 'industryNewsList'
    | 'map'
    | 'search'
    | 'RIareaslist'
    | 'drawings'
    | 'routeList'
    | 'incidentList'
    | 'alert';
}

export type GenericHistoryItem = IndividualHistoryItem | NoPropsHistoryItem;

export const isNoPropsItem = (
  item: GenericHistoryItem
): item is NoPropsHistoryItem => {
  if (
    item.type === 'map' ||
    item.type === 'portList' ||
    item.type === 'industryNewsList' ||
    item.type === 'incidentList' ||
    item.type === 'search' ||
    item.type === 'RIareaslist' ||
    item.type === 'drawings' ||
    item.type === 'routeList'
  ) {
    return true;
  }
  return false;
};

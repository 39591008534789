/* eslint-disable jsx-a11y/aria-role */
import { Alert, Box, Button } from '@mui/material';
import { useState } from 'react';
import DocumentsList from '../../common-components/documents-list/documents-list';
import LoadingSpinner from '../../common-components/loading-spinner/loading-spinner';
import UploadEntityDocumentsModal from '../../common-components/upload-entity-documents-modal/upload-entity-documents-modal';
import { useAppSelector } from '../../hooks';
import './documents-dropdown-panel.scss';

interface DocumentsDropdownPanelProps {
  entityId: string;
  entityName: string;
  entityType: string;
  refreshDocuments: () => void;
}

function DocumentsDropdownPanel(props: DocumentsDropdownPanelProps) {
  const { myDocuments, loading, error } = useAppSelector(
    (state) => state.documents
  );

  const [uploadDocumentsModalVisible, setUploadDocumentsModalVisible] =
    useState<boolean>(false);

  const { entityName, entityId, entityType, refreshDocuments } = props;

  const onUploadModalClose = () => {
    refreshDocuments();
    setUploadDocumentsModalVisible(false);
  };

  return (
    <Box
      className="ri-documents-panel-container"
      data-testid="ri-documents-panel-container"
      bgcolor="transparent"
      sx={{
        padding: 0,
        '& > *': {
          borderTop: 1,
          borderRadius: 0,
          borderColor: 'divider',
        },
      }}
    >
      {loading && <LoadingSpinner />}
      {!loading && error && (
        <Alert severity="error">Error Loading Documents</Alert>
      )}
      {!loading && !error && myDocuments && (
        <DocumentsList
          documents={myDocuments || []}
          refreshDocuments={refreshDocuments}
        />
      )}

      <Box padding="10px">
        <Button
          className="MaritimeUploadButton"
          onClick={() => setUploadDocumentsModalVisible(true)}
          fullWidth
          sx={{
            borderRadius: '100px',
            borderColor: 'primary.dark',
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '38px',
            justifyContent: 'center !important',
          }}
        >
          Upload
        </Button>
      </Box>

      <UploadEntityDocumentsModal
        entityName={entityName || "Error: Couldn't find entity name"}
        entityId={entityId || 0}
        entityType={entityType}
        onClose={onUploadModalClose}
        visible={uploadDocumentsModalVisible}
      />
    </Box>
  );
}

export default DocumentsDropdownPanel;

import { Alert } from '@mui/material';

interface FormErrorMessageProps {
  message: string;
}

function FormErrorMessage(props: FormErrorMessageProps) {
  const { message } = props;

  return (
    <Alert severity="error" sx={{ whiteSpace: 'pre' }}>
      {message}
    </Alert>
  );
}

export default FormErrorMessage;

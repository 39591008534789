import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteRoute } from '../../../api/routes';
import DeleteConfirmationModal from '../../../common-components/delete-confirmation-modal/delete-confirmation-modal';
import ErrorPanel from '../../../common-components/error-components/error-panel/error-panel';
import LayerToggle from '../../../common-components/layer-toggle/layer-toggle';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import MapHelpers from '../../../map/map.utils';
import { Route } from '../../../models/routes.model';
import { startNewHistory } from '../../../nav-history.slice';
import {
  setError,
  setLoading,
  setSelectedRoute,
} from '../../../state/routes/routes.slice';
import zIndexes from '../../../z-indexes.scss';
import updateRoutes, { deselectRoute } from '../routes-panel.utils';
import RoutesPanelFilterBlock from './routes-panel-filter';

function RoutesList({
  onRouteEditClick,
}: {
  onRouteEditClick?: (route: Route) => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [contextMenuRoute, setContextMenuRoute] = useState<Route | null>(null);

  const openMenu = (anchor: HTMLElement, route: Route) => {
    setContextMenuRoute(route);
    setAnchorEl(anchor);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { routes, error, loading, selectedRouteId } = useAppSelector(
    (state) => state.routes
  );
  const { searchFilterValue } = useAppSelector((state) => state.routesPanel);

  useEffect(() => {
    if (!routes) {
      dispatch(setLoading());
      updateRoutes();
    }
  }, []);

  const filteredRoutes: Route[] =
    routes?.filter((route: Route) =>
      !searchFilterValue.trim().length
        ? route
        : route.route_name
            .trim()
            .toLowerCase()
            .includes(searchFilterValue.trim().toLowerCase()) ||
          (route.description || 'No description')
            .trim()
            .toLowerCase()
            .includes(searchFilterValue.trim().toLowerCase())
    ) ?? [];

  const deleteSelectedRoute = () => {
    if (!contextMenuRoute) return;

    setDeleting(true);
    deleteRoute([contextMenuRoute.route_id])
      .then(() => {
        updateRoutes();
        MapHelpers.deleteSources([contextMenuRoute.route_id]);
        setDeleteConfirmOpen(false);
      })
      .catch(() => {
        dispatch(setError());
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const editSelectedRoute = () => {
    if (contextMenuRoute) {
      const geojson = contextMenuRoute.route_data;
      MapHelpers.zoomToFeatureCollection(geojson);
      onRouteEditClick?.(contextMenuRoute);
    }
    closeMenu();
  };

  const onRouteItemClick = async (route: Route) => {
    if (route.route_id !== selectedRouteId) {
      deselectRoute({ resetEntities: true });
      dispatch(startNewHistory({ type: 'routeList' }));
      dispatch(setSelectedRoute(route));
      navigate(`/routes/${route.route_id}`);
    }
  };

  return (
    <div className="routes-panel">
      <div className="routes-panel-container">
        {/* No Routes found */}
        {error && (
          <ErrorPanel message="Unable to fetch routes. Please try again later..." />
        )}

        {/* Routes List */}
        <div
          className="routes-panel-results-container"
          data-testid="routes-panel-results-container"
        >
          <RoutesPanelFilterBlock />
          <Box
            sx={{
              overflowY: 'auto',
              scrollbarWidth: 'thin',
            }}
          >
            <List
              className="routes-panel-results-list"
              data-testid="routes-panel-results-list"
            >
              {routes &&
                filteredRoutes.map((route) => (
                  <ListItem
                    disablePadding
                    divider
                    key={route.route_id}
                    secondaryAction={
                      <Box sx={{ display: 'flex', gap: '8px' }}>
                        <IconButton
                          data-testid="context-menu-button"
                          id={`context-menu-button-${route.route_id}`}
                          edge="end"
                          aria-label="more"
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            openMenu(event.currentTarget, route);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <LayerToggle
                          layers={[
                            route.route_id,
                            `${route.route_id}_radius`,
                            `${route.route_id}_labels`,
                          ]}
                          layerGroups={[]}
                        />
                      </Box>
                    }
                  >
                    <ListItemButton onClick={() => onRouteItemClick(route)}>
                      <ListItemText
                        sx={{ paddingInlineEnd: '48px' }} // This is needed to make the text not overlap with the secondary action buttons.
                        primary={
                          <Typography noWrap>{route.route_name}</Typography>
                        }
                        secondary={
                          <Typography variant="body2">
                            {route.description || 'No Description'}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}

              {loading && !error ? (
                <LoadingPanel />
              ) : (
                filteredRoutes?.length === 0 && (
                  <div
                    className="routes-panel-warning-message"
                    data-testid="routes-panel-warning-message"
                  >
                    No Routes found
                  </div>
                )
              )}
            </List>
          </Box>

          <Menu
            id="route-edit-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              closeMenu();
            }}
            sx={{ zIndex: zIndexes['$--feature-panel-contents-layer'] }} // This is needed to display the menu above the panel.
            MenuListProps={{
              'aria-labelledby': `context-menu-button-${
                contextMenuRoute?.route_id ?? ''
              }`,
              role: 'listbox',
            }}
            slotProps={{
              paper: {
                sx: {
                  bgcolor: 'primary.main',
                },
              },
            }}
          >
            <MenuItem
              sx={{ display: 'flex', gap: '8px' }}
              onClick={() => {
                editSelectedRoute();
              }}
            >
              <ModeEditOutlineOutlinedIcon />
              Edit Route
            </MenuItem>
            <MenuItem
              sx={{ display: 'flex', gap: '8px' }}
              onClick={() => {
                setDeleteConfirmOpen(true);
                closeMenu();
              }}
            >
              <DeleteOutlineIcon />
              Delete
            </MenuItem>
          </Menu>
        </div>
      </div>
      <DeleteConfirmationModal
        itemsToDelete={[contextMenuRoute?.route_name ?? '']}
        visible={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        deleteFunction={() => deleteSelectedRoute()}
        deleting={deleting}
        typeOfThingBeingDeleted="route"
      />
    </div>
  );
}

RoutesList.defaultProps = {
  onRouteEditClick: null,
};

export default RoutesList;

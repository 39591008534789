/* eslint-disable jsx-a11y/aria-role */
import { useEffect } from 'react';
import { getS3DocsWithMetadata } from '../../api';
import RIAccordion from '../../common-components/accordion/ri-accordion';
import LoadingSpinner from '../../common-components/loading-spinner/loading-spinner';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  setDocuments,
  setError,
  setLoading,
} from '../../maritime-menu-options/documents-panel/documents.slice';
import DocumentsDropdownPanel from './documents-dropdown-panel';
import './documents-dropdown-panel.scss';

interface DocumentsAccordianPanelProps {
  entityId: string;
  entityName: string;
  entityType: string;
}

function DocumentsAccordianPanel({
  entityId,
  entityName,
  entityType,
}: DocumentsAccordianPanelProps) {
  const { myDocuments, loading } = useAppSelector((state) => state.documents);

  const dispatch = useAppDispatch();

  const fetchAllDocuments = async () => {
    dispatch(setLoading());

    try {
      if (!entityId) {
        dispatch(
          setDocuments({
            myDocuments: null,
          })
        );
        return;
      }

      const { myDocuments: myDocs } = await getS3DocsWithMetadata(
        String(entityId)
      );

      dispatch(
        setDocuments({
          myDocuments: myDocs,
        })
      );
    } catch (error) {
      dispatch(setError());
    }
  };

  useEffect(() => {
    fetchAllDocuments();
  }, [entityId]);

  const totalNumberOfDocuments = myDocuments?.length || 0;

  return (
    <RIAccordion
      title={
        <>Documents ({loading ? <LoadingSpinner /> : totalNumberOfDocuments})</>
      }
      content={
        <DocumentsDropdownPanel
          entityId={entityId}
          entityName={entityName}
          entityType={entityType}
          refreshDocuments={fetchAllDocuments}
        />
      }
    />
  );
}

export default DocumentsAccordianPanel;

import { Alert, Box, List } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReduxStatesToVerify, useEnsureReduxLoaded } from '../../api';
import Breadcrumb from '../../common-components/breadcrumb/breadcrumb';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { EDossiers, setSelectedDossier } from '../../main-menu/menu.slice';
import MapLayer, {
  MapGroupLayer,
} from '../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../map/map.utils';
import { FirstCallFeature } from '../../models/first-call';
import {
  setLoading,
  setSelectedFirstCallPort,
} from '../../state/ports/first-call.slice';
import { assertIsPointFeature } from '../../utils/typescript-helpers';
import FirstCallListItem from './first-call-list-item';
import { setSearch } from './first-call-panel.slice';

function FirstCallPanel() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    firstCallPorts,
    loading,
    error,
    selectedFirstCall,
    selectedFirstCallId,
  } = useAppSelector((state) => state.firstCallPorts);
  const { search } = useAppSelector((state) => state.firstCallPortsPanel);

  const updateSearchFilter = (value: string) => {
    dispatch(setSearch(value));
  };

  const filteredFirstCallPorts = useMemo(() => {
    if (!firstCallPorts) return null;
    if (!search) return firstCallPorts;
    return firstCallPorts.filter((firstCall) =>
      firstCall.properties.portName.toLowerCase().includes(search.toLowerCase())
    );
  }, [firstCallPorts, search]);

  const handleFirstCallClick = (firstCall: FirstCallFeature) => {
    dispatch(setSelectedDossier(EDossiers.FIRST_CALL));
    dispatch(setSelectedFirstCallPort(firstCall));
    navigate(`/first-call-ports/${firstCall.id}`);
    if (assertIsPointFeature(firstCall)) {
      MapHelpers.zoomToPoint(firstCall.geometry.coordinates, 8.5);
    }
  };

  useEnsureReduxLoaded([ReduxStatesToVerify.FIRST_CALL_PORTS], {
    before: () => {
      dispatch(setLoading(true));
    },
    after: () => {
      dispatch(setLoading(false));
      MapHelpers.setLayerVisibility(MapGroupLayer.FIRST_CALL_PORTS, true);
      MapHelpers.setLayerVisibility(MapLayer.FIRST_CALL_CLUSTERS, true);
      MapHelpers.setLayerVisibility(MapLayer.FIRST_CALL_CLUSTER_COUNT, true);
    },
  });

  return (
    <Box
      data-testId="FirstCallPortsPanel"
      sx={{
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
      }}
    >
      <PanelTitle>First Call Ports</PanelTitle>
      <Breadcrumb />
      <Box sx={{ padding: 2 }}>
        <SimpleSearchBar
          handleChange={updateSearchFilter}
          filterValue={search}
          placeholder="Search First Call Ports"
          dataTestId="first-call-port-search-bar"
        />
      </Box>
      {loading && <LoadingPanel />}
      {error && (
        <Alert data-testid="error-panel" severity="error">
          Error: Unable to fetch First Call Ports
        </Alert>
      )}
      {!loading && !error && filteredFirstCallPorts && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {filteredFirstCallPorts.length ? (
            <List sx={{ overflowY: 'auto' }}>
              {filteredFirstCallPorts.map((firstCall) => (
                <FirstCallListItem
                  key={firstCall.id}
                  firstCall={firstCall}
                  onClick={handleFirstCallClick}
                  selected={
                    firstCall.id === selectedFirstCallId ||
                    firstCall.id === selectedFirstCall?.id
                  }
                  divider
                />
              ))}
            </List>
          ) : (
            <Alert data-testid="no-results" severity="info">
              No results
            </Alert>
          )}
        </>
      )}
    </Box>
  );
}

export default FirstCallPanel;

import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { setSelectedArea } from '../../../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import { MaritimeArea } from '../../../models/risk_intelligence.model';
import store from '../../../store';

function onMaritimeAreaClick(area: MaritimeArea) {
  store.dispatch(setSelectedArea(area));
  store.dispatch(setSelectedDossier(EDossiers.RI_MARITIME_AREA));
  window.history.pushState({}, '', `/ri-maritime-areas/${area.id}`);
}

export default onMaritimeAreaClick;

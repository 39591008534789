import {
  Correspondent,
  CorrespondentFeature,
  CorrespondentResponse,
} from '../../models/correspondents.model';

export const MOCK_CORRESPONDENTS: Correspondent[] = [
  {
    uid: 52641,
    name: '(Santa Marta - See Bogota)',
    address: {
      line1: null,
      line2: null,
      line3: null,
      city: 'Santa Marta',
      subdivision: null,
      postcode: null,
      country: 'Colombia',
    },
    is_lawyer: false,
    co_party: null,
    notes: null,
    contact_information: [],
    port_information: {
      name: 'Santa Marta',
      country: 'Colombia',
      location: {
        lat: 1,
        lng: 2,
      },
      updated_at: 1712589099,
    },
    contacts: [],
    updated_at: 1712659734,
  },
  {
    uid: 43403,
    name: 'AIV Private Limited',
    address: {
      line1: 'House No C-118  ',
      line2: 'Block - 2',
      line3: 'Clifton  ',
      city: 'Karachi',
      subdivision: 'Sindh',
      postcode: null,
      country: 'Pakistan',
    },
    is_lawyer: false,
    co_party: null,
    notes: null,
    contact_information: {
      fax: {
        label: 'Fax',
        value: '(+92) 44 5566778',
      },
      email: {
        label: 'Email',
        value: 'pni@aivpak.com',
      },
      telephone: {
        label: 'Telephone',
        value: '(+92) 33 23123456',
      },
      alternative: {
        label: 'Alternative',
        value: '(+92) 23 15162634',
      },
    },
    port_information: {
      name: 'Karachi',
      country: 'Pakistan',
      location: {
        lat: 24.893379,
        lng: 67.028061,
      },
      updated_at: 1712659741,
    },
    contacts: [
      {
        uid: 224769,
        title: 'Mr',
        first_name: 'Ding',
        last_name: 'Thing',
        job_title: null,
        contact_information: {
          email: ['ding.thing@email.com'],
          phone: {
            default: [],
            personal: ['(+92) 111 2223334'],
            out_of_hours: [],
          },
        },
        updated_at: 1712659737,
      },
      {
        uid: 1102153,
        title: 'Miss',
        first_name: 'Pink',
        last_name: 'Azalea',
        job_title: null,
        contact_information: {
          email: ['abc@def.com'],
          phone: {
            default: [],
            personal: ['(++92) 123 4567890'],
            out_of_hours: [],
          },
        },
        updated_at: 1712659737,
      },
    ],
    updated_at: 1712659736,
  },
  {
    uid: 1103030,
    name: 'Akaya Co. Ltd.',
    address: {
      line1: 'S. Mehdiyev 93',
      line2: 'Azeraqrartikinti Binasi',
      line3: '9 cu Mertebe',
      city: 'Baku',
      subdivision: null,
      postcode: '1141',
      country: 'Azerbaijan',
    },
    is_lawyer: false,
    co_party: null,
    notes:
      'In case of difficulty contact liaison office in Istanbul: +12 345 6789',
    contact_information: {
      email: {
        label: 'Email',
        value: 'name@email.com',
      },
    },
    port_information: {
      name: 'Baku',
      country: 'Azerbaijan',
      location: {
        lat: 40.43495,
        lng: 49.867623,
      },
      updated_at: 1712659738,
    },
    contacts: [
      {
        uid: 1103031,
        title: 'Mr',
        first_name: 'Big',
        last_name: 'Bird',
        job_title: null,
        contact_information: {
          email: [],
          phone: {
            default: [],
            personal: ['(++90) 123 456 7890'],
            out_of_hours: [],
          },
        },
        updated_at: 1712659737,
      },
      {
        uid: 1103033,
        title: 'Mr',
        first_name: 'Pink',
        last_name: 'Blob',
        job_title: null,
        contact_information: {
          email: [],
          phone: {
            default: [],
            personal: ['(++99) 123 4567 888'],
            out_of_hours: [],
          },
        },
        updated_at: 1712659737,
      },
    ],
    updated_at: 1712659736,
  },
];

export const MOCK_CORRESPONDENTS_FEATURES: CorrespondentFeature[] =
  MOCK_CORRESPONDENTS.map((correspondent) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [
        correspondent.port_information.location.lat!,
        correspondent.port_information.location.lng!,
      ],
    },
    properties: correspondent,
  }));

export const MOCK_CORRESPONDENTS_RESPONSE: CorrespondentResponse = {
  data: MOCK_CORRESPONDENTS,
};

import { Box, Link, Typography } from '@mui/material';

function RiFurtherInformationFooter() {
  return (
    <Box
      sx={{
        padding: '10px',
        textAlign: 'left',
      }}
    >
      <Typography
        paragraph
        variant="subtitle1"
        fontSize="14px"
        align="left"
        display="inline"
      >
        Users who require more detailed information can contact
        NorthStandard&apos;s Loss Prevention team{' '}
      </Typography>
      <Link href="mailto:nslossprevention@standardclub.com">
        <Typography
          paragraph
          variant="subtitle1"
          fontSize="14px"
          align="left"
          display="inline"
        >
          here
        </Typography>
      </Link>
      <Typography
        paragraph
        variant="subtitle1"
        fontSize="14px"
        align="left"
        display="inline"
      >
        . For bespoke voyage risk assessments or port security threat
        assessments users can contact Risk Intelligence{' '}
      </Typography>
      <Link
        href="https://www.riskintelligence.eu/contact"
        target="_blank"
        rel="noopener"
        component="a"
      >
        <Typography
          paragraph
          variant="subtitle1"
          fontSize="14px"
          align="left"
          display="inline"
        >
          here
        </Typography>
      </Link>
      <Typography
        paragraph
        variant="subtitle1"
        fontSize="14px"
        align="left"
        display="inline"
      >
        . NorthStandard members are eligible for a 50% discount on bespoke
        advisory services.
      </Typography>
    </Box>
  );
}

export default RiFurtherInformationFooter;

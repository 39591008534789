import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import './App.scss';
import Logo from './Logo';
import { getIncident, getIncidents } from './api/risk-intelligence/incidents';
import DidYouKnow from './did-you-know';
import handleEntityRoute from './handleEntityRoute';
import { useAppDispatch, useAppSelector, useMobile } from './hooks';
import useAccessControl from './hooks/access-control/useAccessControl';
import IncidentsDateRangeIndicator from './incidents-date-range-indicator/incidents-date-range-indicator';
import MainMenu from './main-menu/main-menu/main-menu';
import MapDetailsIndicator from './map-details-indicator/map-details-indicator';
import Map from './map/map';
import { themedDraw } from './map/map-controls.utils';
import NewsTicker from './news-ticker/news-ticker';
import SearchBar from './search-bar/search-bar';
import {
  setIncidents,
  setError as setIncidentsFetchError,
  setLoading as setIncidentsLoading,
  setSelectedIncident,
} from './state/incidents/incidents.slice';
import UserButton from './user-settings/user-button';
import UserPreferences from './user-settings/user-preferences/user-preferences';

function App() {
  const dispatch = useAppDispatch();
  const tickerOpen = useAppSelector(
    (state) => state.userPreferences.userPreferences.showRiNewsBanner
  );
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);
  const drawType = useAppSelector((state) => state.map.drawType);

  const privileges = useAccessControl();

  const isMobile = useMobile();

  const { '*': routeSplat } = useParams();
  const [searchParams] = useSearchParams();

  const draw = themedDraw;

  const isDrawing =
    drawType || (mapInitialised && draw?.getMode() !== 'simple_select');

  const incidents = useAppSelector((state) => state.incidents.incidents);
  const incidentFilters = useAppSelector(
    (state) => state.incidentsPanel.filters
  );
  const selectedIncidentId = useAppSelector(
    (state) => state.incidents.selectedIncidentId
  );

  useEffect(() => {
    if (routeSplat) {
      handleEntityRoute(privileges, routeSplat, searchParams);
    }
  }, [routeSplat, searchParams]);

  // The incidents date range filter can change from the incidents panel
  // or the map date range indicator.
  // This is the common parent of both components where we can fetch the incidents
  // on updates to the filters in a single place
  const fetchIncidents = useCallback(() => {
    // Only re-fetch if we've already fetched incidents before
    // e.g. after the incidents panel has been opened or loaded via loadIncidentsSetup
    if (incidents) {
      dispatch(setIncidentsLoading());

      // Has a side-effect of populating the incident types.
      getIncidents({ filters: incidentFilters })
        .then((incidentsResponse) => {
          dispatch(setIncidents(incidentsResponse));
        })
        .catch(() => {
          dispatch(setIncidentsFetchError());
        });
    }
    // Don't use incidents as a dependency unless you want to end up with an infinte request loop
  }, [incidentFilters]);

  useEffect(() => {
    fetchIncidents();
  }, [fetchIncidents]);

  const fetchSelectedIncident = useCallback(() => {
    if (selectedIncidentId) {
      // we may have loaded an incident outside the range of the current filters, possibly via search.
      getIncident(selectedIncidentId)
        .then((incident) => {
          dispatch(setSelectedIncident(incident));
        })
        .catch(() => {
          setIncidentsFetchError();
        });
    }
  }, [selectedIncidentId]);

  useEffect(() => {
    fetchSelectedIncident();
  }, [fetchSelectedIncident]);

  return (
    <div className="App" id="appRoot">
      <Logo />
      <UserPreferences>
        <div
          className={classNames('menu-container', {
            hidden: isMobile && isDrawing,
            mobile: isMobile,
          })}
        >
          <MainMenu />
        </div>
        <div className="app-container">
          {privileges.canAccessDidYouKnow && <DidYouKnow />}
          <Map tickerOpen={tickerOpen} />
          <MapDetailsIndicator />
          {!isMobile && <SearchBar />}
          <UserButton />
          {privileges.canAccessIncidentsDateRangeIndicator && (
            <IncidentsDateRangeIndicator />
          )}
          {tickerOpen && <NewsTicker />}
        </div>
      </UserPreferences>
    </div>
  );
}

export default App;

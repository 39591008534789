import { Dayjs } from 'dayjs';
import {
  applyPortFilter,
  extractPortThreatTypesFromFilter,
  getBoundaries,
  getIncidents,
  getMaritimeAreas,
  getPorts,
} from '../api';
import {
  DateRangeMutators,
  SetDateIntervalStrings,
} from '../common-components/date-range-modal/date-range-presets';
import MapLayer from '../map/map-layer-manager/map-layer.enum';
import setMaritimeAreasFeatures from '../map/map-layer-manager/maritime-areas-utils/set-maritime-areas-features';
import setPortFeatures from '../map/map-layer-manager/port-utils/set-port-features';
import { MapExtent } from '../map/map.slice';
import MapHelpers from '../map/map.utils';
import { updateDrawings } from '../maritime-menu-options/areas-panel/drawings-panel.utils';
import BoundariesController from '../maritime-menu-options/boundaries-panel/boundaries-controller.utils';
import { HistoryFormValues } from '../maritime-menu-options/history-panel/history-form/history-form-validators';
import { setLoading as setVesselHistoryLoading } from '../maritime-menu-options/history-panel/history-panel.slice';
import {
  defaultIncidentFilters,
  setIncidentFilters,
} from '../maritime-menu-options/incidents-panel/incident-panel.slice';
import { IncidentFilters } from '../maritime-menu-options/incidents-panel/incident.model';
import { setAreas } from '../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import updateRoutes from '../maritime-menu-options/routes-panel/routes-panel.utils';
import {
  DEFAULT_PORT_FILTERS,
  PortsFilters,
  setPortsFilters,
} from '../maritime-menu-options/world-ports-panel/world-ports-panel.slice';
import { setBoundaries } from '../state/boundaries/boundaries.slice';
import { setIncidents } from '../state/incidents/incidents.slice';
import store from '../store';
import {
  setApplySetupBarError,
  setApplySetupBarNumber,
} from '../user-settings/apply-setup-bar/apply-setup-bar.slice';
import { UserPreferences } from '../user-settings/user-preferences/user-preferences.slice';
import { getVesselHistoryData } from './vessels.utils';

export const loadBoundariesSetup = async (visibleLayers: string[] = []) => {
  if (!visibleLayers.length) {
    return;
  }

  // 1. Fetch and populate boundaries metadata:
  const boundariesMetaData =
    store.getState().boundaries.boundaries ?? (await getBoundaries());

  // 2. filter boundaries metadata to isolate those that are visible
  const visibleBoundariesMetaData = boundariesMetaData.filter((boundary) =>
    visibleLayers.includes(boundary.boundary_source_layer)
  );

  // 3. add only the visible boundaries to the map.
  await BoundariesController.addBoundariesToMap(visibleBoundariesMetaData, {
    createVisible: true,
  });

  store.dispatch(setBoundaries(boundariesMetaData));
};

export const loadHistoryPanelSetup = async (
  formValues: HistoryFormValues,
  disabled: boolean,
  userPreferences: UserPreferences
): Promise<void> => {
  if (disabled || formValues.identifiers.length === 0) {
    // don't load if no layers to display
    return;
  }

  store.dispatch(setVesselHistoryLoading(true));

  try {
    await getVesselHistoryData(formValues, userPreferences);
  } finally {
    store.dispatch(setVesselHistoryLoading(false));
  }
};

export const loadAlertsPanelSetup = async (
  disabled: boolean = true,
  visibleLayers: string[] = []
): Promise<void> => {
  if (disabled || visibleLayers.length === 0) {
    // eslint-disable-next-line no-useless-return
    return;
  }
  // TODO implement rest of logic for alerts panel
};

export const loadRIMaritimeAreasPanelSetup = async (
  disabled: boolean = true,
  visibleLayers: string[] = []
): Promise<void> => {
  if (disabled || visibleLayers.length === 0) {
    return;
  }

  const areas =
    store.getState().riMaritimeAreas.areas ?? (await getMaritimeAreas());
  store.dispatch(setAreas(areas));
  if (areas) {
    setMaritimeAreasFeatures(MapLayer.RI_MARITIME_AREAS, areas);
  }
  visibleLayers.forEach((layer) => MapHelpers.setLayerVisibility(layer, true));
};

export const loadDrawingsSetup = async (visibleLayers: string[] = []) => {
  if (visibleLayers.length === 0) {
    return;
  }

  await updateDrawings();
  visibleLayers.forEach((layer) => MapHelpers.setLayerVisibility(layer, true));
};

export const loadRoutesSetup = async (
  visibleLayers: string[] = []
): Promise<void> => {
  if (!visibleLayers.length) {
    return;
  }

  await updateRoutes();
  visibleLayers.forEach((layer) => MapHelpers.setLayerVisibility(layer, true));
};

export function incidentFilterToApply(filters: IncidentFilters | undefined) {
  const updatedFilters: IncidentFilters = {
    ...defaultIncidentFilters,
    ...filters,
  };
  if (filters) {
    const { datePreset } = filters;
    if (
      datePreset &&
      datePreset.length > 0 &&
      SetDateIntervalStrings.includes(datePreset)
    ) {
      // Any preset dates should be from the current day not the date the preference was set
      const startAndEndDays: Dayjs[] = DateRangeMutators[datePreset]();
      updatedFilters.startDate = startAndEndDays[0].toISOString().slice(0, 10);
      updatedFilters.endDate = startAndEndDays[1].toISOString().slice(0, 10);
    }
    if (!updatedFilters.startDate) {
      updatedFilters.startDate = defaultIncidentFilters.startDate;
    }
    if (!updatedFilters.endDate) {
      updatedFilters.endDate = defaultIncidentFilters.endDate;
    }

    return updatedFilters;
  }
  return defaultIncidentFilters;
}

export const loadIncidentsSetup = async (
  filters?: IncidentFilters,
  disabled: boolean = true,
  visibleLayers: string[] = []
) => {
  if (disabled || visibleLayers.length === 0) {
    return;
  }

  const filterToApply = incidentFilterToApply(filters);

  store.dispatch(setIncidentFilters(filterToApply));

  const incidentsResponse = await getIncidents({ filters: filterToApply });
  store.dispatch(setIncidents(incidentsResponse));

  if (visibleLayers.length) {
    visibleLayers.forEach((layer) =>
      MapHelpers.setLayerVisibility(layer, true)
    );
  }
};

export const loadWorldPortsPanelSetup = async (
  filters: PortsFilters = DEFAULT_PORT_FILTERS,
  disabled: boolean = true,
  visibleLayers: string[] = []
): Promise<void> => {
  if (disabled || visibleLayers.length === 0) {
    return;
  }

  const userToken = store.getState().user.idToken;

  const filterToApply = filters ?? DEFAULT_PORT_FILTERS;
  store.dispatch(setPortsFilters(filterToApply));

  if (visibleLayers.length) {
    visibleLayers.forEach((layer) =>
      MapHelpers.setLayerVisibility(layer, true)
    );
  }

  const ports = await getPorts(
    userToken,
    extractPortThreatTypesFromFilter(filterToApply)
  );

  setPortFeatures(MapLayer.PORTS, applyPortFilter(ports, filterToApply));
};

const applySavedSetupActions: Partial<
  Record<keyof UserPreferences, Promise<void> | any>
> = {
  boundariesPanel: (preferences: UserPreferences) =>
    loadBoundariesSetup(preferences.boundariesPanel?.layers),
  drawingsPanel: (preferences: UserPreferences) =>
    loadDrawingsSetup(preferences.drawingsPanel?.layers),
  routesPanel: (preferences: UserPreferences) =>
    loadRoutesSetup(preferences.routesPanel?.layers),
  incidentsPanel: (preferences: UserPreferences) =>
    loadIncidentsSetup(
      preferences.incidentsPanel?.filters,
      preferences.incidentsPanel?.disabled,
      preferences.incidentsPanel?.layers
    ),
  worldPortsPanel: (preferences: UserPreferences) =>
    loadWorldPortsPanelSetup(
      preferences.worldPortsPanel?.filters,
      preferences.worldPortsPanel?.disabled,
      preferences.worldPortsPanel?.layers
    ),
  historyPanel: (preferences: UserPreferences) =>
    loadHistoryPanelSetup(
      preferences.historyPanel?.formValues as HistoryFormValues,
      preferences.historyPanel?.disabled as boolean,
      preferences
    ),
  RIMaritimeAreasPanel: (preferences: UserPreferences) =>
    loadRIMaritimeAreasPanelSetup(
      preferences.RIMaritimeAreasPanel?.disabled,
      preferences.RIMaritimeAreasPanel?.layers
    ),
  mapExtent: (preferences: UserPreferences) =>
    MapHelpers.updateMapExtent(preferences.mapExtent as MapExtent),
};

export const applyUserPreferences = async (preferences: UserPreferences) => {
  const actionKeys = Object.keys(applySavedSetupActions);

  await Promise.all(
    actionKeys.map((action) => {
      store.dispatch(
        setApplySetupBarNumber(actionKeys.length - actionKeys.indexOf(action))
      );

      const preference = preferences[action as keyof UserPreferences];

      if (preference) {
        try {
          // eslint-disable-next-line no-await-in-loop
          return applySavedSetupActions[action as keyof UserPreferences](
            preferences
          );
        } catch (_error) {
          store.dispatch(setApplySetupBarError(true));
          return Promise.reject(_error);
        }
      }
      return Promise.resolve();
    })
  );
  store.dispatch(setApplySetupBarNumber(0));
};

import * as Yup from 'yup';

export const validateLatitudeDms = () =>
  Yup.string()
    .matches(
      /^([0-8]?[0-9]|90)°\s([0-5]?[0-9])'\s([0-5]?[0-9])"\s[N|S]$/,
      `Latitude must be in the format of Degrees, Minutes, and Seconds\n e.g: 9° 57' 45" N`
    )
    .required('Latitude is required');

export const validateLongitudeDms = () =>
  Yup.string()
    .matches(
      /^([0-9]?[0-9]|1[0-7][0-9]|180)°\s([0-5]?[0-9])'\s([0-5]?[0-9])"\s[E|W]$/,
      `Longitude must be in the format of Degrees, Minutes, and Seconds\n e.g: 9° 57' 45" W`
    )
    .required('Longitude is required');

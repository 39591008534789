/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { ExpandedIncident } from '../maritime-menu-options/incidents-panel/incident.model';
import { getIncidentRegionTitleById } from '../utils/incidents-helpers';
import { sanitiseBlock } from '../utils/text-formatting.utils';
import RIFooter from './RI-Footer';
import RIHeader from './RI-Header';
import DateStamp from './date-stamp';
import styles from './pdf-styles';

// Create styles
const incidentStyles = StyleSheet.create({
  image: {
    maxWidth: '50%',
    maxHeight: '200px',
    height: 'auto',
    width: 'auto',
    margin: '20 5 20',
  },
});

interface IncidentExportProps {
  incidents: ExpandedIncident[];
}

function IncidentTitle({ title }: { title: string }) {
  return (
    <View style={styles.section}>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
}

function IncidentSummary({ incident }: { incident: ExpandedIncident }) {
  return (
    <View style={styles.section}>
      {incident.images && incident.images.length >= 1 && (
        <Image src={incident.images[0].url} style={incidentStyles.image} />
      )}

      <Text style={styles.subTitle}>
        Region: {getIncidentRegionTitleById(incident.region) || 'N/A'}
      </Text>
      <Text style={styles.textBody}>
        {sanitiseBlock(incident.description, [])}
      </Text>
    </View>
  );
}

export const incidentExport = (props: IncidentExportProps) => {
  const { incidents } = props;
  const pages = incidents.map((incident) => (
    <Page size="A4" key={incident.id} style={styles.page}>
      <View>
        <RIHeader />
        <IncidentTitle title={incident.title} />
        <DateStamp date={new Date(incident.date)} />
      </View>
      <View
        style={{
          flex: '1 1',
          flexDirection: 'column',
        }}
      >
        <IncidentSummary incident={incident} />
      </View>

      <RIFooter />
    </Page>
  ));
  return <Document>{pages}</Document>;
};

import { isEmpty } from '@aws-amplify/core';
import { getCountry } from '../api/risk-intelligence/countries';
import MapLayerIcon from '../map/map-layer-manager/map-layer-icon';
import { defaultIncidentFilters } from '../maritime-menu-options/incidents-panel/incident-panel.slice';
import {
  AlertDisplayFilter,
  ExpandedIncident,
  Incident,
  IncidentFilters,
  IncidentTargetType,
  IncidentType,
} from '../maritime-menu-options/incidents-panel/incident.model';
import {
  Area,
  ExpandedCountry,
  ExpandedId,
  MapCountry,
  MaybeExpandedId,
  Region,
} from '../models/risk_intelligence.model';
import DateTimeHelpers, { dateWithLocale } from './date-time-helpers.utils';
import { kebabify } from './text-formatting.utils';

// Fill Map in order to avoid doing a .find every time which is expensive
// can't fill the Map until incidentTypes has been populated
export const incidentTypeCache: Map<number, IncidentType> = new Map();
export const incidentRegionCache: Map<number, string> = new Map();
export const incidentTimeOfDayCache: Map<number, string> = new Map();
export const incidentAreaCache: Map<number, Area> = new Map();
export const incidentTargetTypesCache: Map<number, string> = new Map();

export const isExpandedCountry = (
  item: ExpandedCountry | {}
): item is ExpandedCountry => !isEmpty(item);

export const isExpandedIncident = (
  incident: Incident
): incident is ExpandedIncident => !!(incident as ExpandedIncident).expanded;

const isExpandedId = (id: MaybeExpandedId): id is ExpandedId =>
  typeof id === 'object';

export const populateIncidentTypeCache = (
  incidentTypes: IncidentType[] | null
) => {
  if (!incidentTypes) {
    return;
  }
  incidentTypes.forEach((incidentType) =>
    incidentTypeCache.set(incidentType.id, incidentType)
  );
};

export const populateRegionCache = (incidentRegions: Region[] | null) => {
  if (!incidentRegions) {
    return;
  }
  incidentRegions.forEach(
    (region) =>
      isExpandedId(region) && incidentRegionCache.set(region.id, region.title)
  );
};

export const populateAreasCache = (incidentAreas: Area[] | null) => {
  if (!incidentAreas) {
    return;
  }
  incidentAreas.forEach((area) => incidentAreaCache.set(area.id, area));
};

export const populateTargetTypesCache = (
  targetTypes: IncidentTargetType[] | null
) => {
  if (!targetTypes) {
    return;
  }
  targetTypes.forEach((targetType) =>
    incidentTargetTypesCache.set(targetType.id, targetType.title)
  );
};

export const getTitleById = (
  id: MaybeExpandedId | null,
  data: Map<number, string | Area | IncidentType>
) => {
  if (!id) {
    return null;
  }

  if (isExpandedId(id)) {
    return id.title;
  }
  const selectedValue = data.get(id);

  if (typeof selectedValue === 'string') {
    return selectedValue;
  }

  if (typeof selectedValue === 'object') {
    return selectedValue.title;
  }

  return undefined;
};

export const getIncidentTypeById = (id: MaybeExpandedId) =>
  isExpandedId(id) ? incidentTypeCache.get(id.id) : incidentTypeCache.get(id);

export const getIncidentTypeTitleById = (id: MaybeExpandedId) =>
  getTitleById(id, incidentTypeCache);

export const getIncidentAreaTitleById = (id: MaybeExpandedId | null) =>
  getTitleById(id, incidentAreaCache);

export const getIncidentRegionTitleById = (id: MaybeExpandedId | null) =>
  getTitleById(id, incidentRegionCache);

export const getIncidentTargetTypeTitleById = (id: MaybeExpandedId) =>
  getTitleById(id, incidentTargetTypesCache);

export const getIncidentMapBoxImageName = (
  id: MaybeExpandedId,
  iconType: keyof IncidentType['icons']
) => {
  const incidentTitle = isExpandedId(id)
    ? id.title
    : getIncidentTypeTitleById(id);

  if (!incidentTitle) {
    return iconType === 'marker'
      ? MapLayerIcon.INCIDENT.DEFAULT
      : MapLayerIcon.INCIDENT.SELECTED;
  }

  const kebabName = kebabify(incidentTitle);
  switch (iconType) {
    case 'marker':
      return `${kebabName}-marker`;
    case 'markerAlert':
      return `${kebabName}-marker-alert`;
    case 'markerAlertAura':
      return `${kebabName}-marker-alert-aura`;
    case 'markerAura':
      return `${kebabName}-marker-aura`;
    case 'round':
      return `${kebabName}-round`;
    default:
      return MapLayerIcon.INCIDENT.DEFAULT;
  }
};

export const groupIncidentTypesByGroup = (incidentTypes: IncidentType[]) =>
  incidentTypes.reduce<Record<string, IncidentType[]>>(
    (accumulator, current) => {
      if (!accumulator[current.group.title]) {
        accumulator[current.group.title] = [current];
      } else {
        accumulator[current.group.title].push(current);
      }
      return accumulator;
    },
    {}
  );

export const areAnyFiltersSet = (filters: IncidentFilters) => {
  if (!filters) {
    return false;
  }
  const {
    incidentTypes,
    regions,
    timeOfDay,
    place,
    areas,
    targets,
    startDate,
    endDate,
    alerts,
  } = filters;
  if (
    incidentTypes.length > 0 ||
    regions.length > 0 ||
    timeOfDay.length > 0 ||
    place.length > 0 ||
    areas.length > 0 ||
    targets.length > 0 ||
    alerts.length > 0 ||
    (startDate &&
      startDate !==
        DateTimeHelpers.dateToIsoDate(defaultIncidentFilters.startDate)) ||
    (endDate &&
      endDate !== DateTimeHelpers.dateToIsoDate(defaultIncidentFilters.endDate))
  ) {
    return true;
  }
  return false;
};

const sortIncidentsByDate = (incidents: Incident[]) =>
  incidents.sort((incident1, incident2) => {
    const date1 = new Date(incident1.date);
    const date2 = new Date(incident2.date);
    return date2.getTime() - date1.getTime();
  });

export const incidentDisplayDate = (incident: Incident) =>
  // The date we recieve from the API has already been converted to the user's local time on the backend/RI side
  // so we should display it as-is with no timezone conversion.
  // We only want to display it to the date level however without the timestamp, so treat it as a 'UTC' date and
  // let existing handling deal with the rest.
  dateWithLocale(incident.date, 'UTC') || 'Unknown Date';

export function applyClientSideFilters(
  filters: IncidentFilters,
  incidents: Incident[]
) {
  const sortedIncidents = sortIncidentsByDate(incidents);

  if (filters.alerts.length === 0) {
    return sortedIncidents;
  }

  return sortedIncidents.filter((incident) => {
    if (
      filters.alerts.includes(AlertDisplayFilter.ShowAlerts.id) &&
      incident.alert === true
    ) {
      return true;
    }
    if (
      filters.alerts.includes(AlertDisplayFilter.ShowNonAlerts.id) &&
      incident.alert === false
    ) {
      return true;
    }
    return false;
  });
}

export const getCountryRegion = async (
  countryId: number,
  selectedCountry: MapCountry | null
) => {
  if (!selectedCountry) {
    const country = await getCountry(countryId);
    return country.region.id;
  }
  if (selectedCountry.ri && selectedCountry.ri !== 'N/A') {
    return selectedCountry.ri.region.id;
  }
  return null;
};

export const listFilterItems = (filter: Record<string, ExpandedId>) =>
  Object.values(filter).map((key) => ({
    id: key.id,
    title: key.title,
  }));

export const calcDisplayedText = (
  selected: number[],
  name: string,
  data: Record<string, ExpandedId> | Map<number, string | Area | IncidentType>
) => {
  if (selected.length < 1) {
    return `Select ${name}`;
  }
  let firstName: string | null | undefined | Area = '';

  if (data instanceof Map) {
    const id = selected[0];
    firstName = getTitleById(id, data) || 'Unknown';
  } else if (typeof data === 'object') {
    firstName =
      Object.values(data).find((item) => item.id === selected[0])!.title ||
      null;
  }

  if (selected.length === 1) {
    return `${name}: ${firstName}`;
  }
  return `${name}: ${firstName} and ${
    selected.length === 2 ? `1 other` : `${selected.length - 1} others`
  }`;
};

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { IconButton } from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { clearUser, setUserIdToken } from '../user/user.slice';
import './user-button.scss';
import UserMenu from './user-menu/user-menu';

function UserButton() {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const mainMenuOpen = useAppSelector((state) => state.menu.menuOpen);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const clearUserDetails = () => {
      dispatch(clearUser());
    };

    const storeUserDetails = (user: CognitoUser) => {
      const session = user.getSignInUserSession();
      if (session) {
        const token = session.getIdToken().decodePayload();
        dispatch(setUserIdToken(token));
      }
    };

    const checkUserLoggedIn = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        storeUserDetails(user);
      } catch (error) {
        clearUserDetails();
      }
    };

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          storeUserDetails(data);
          break;
        case 'signOut':
          clearUserDetails();
          break;
        default:
          break;
      }
    });

    checkUserLoggedIn();

    return unsubscribe;
  }, []);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [mainMenuOpen]);

  return (
    <div className="user-menu-container">
      <IconButton
        data-testid="user-menu-button"
        sx={{
          backgroundColor: 'primary.main',
          '&:hover': {
            backgroundColor: 'primary.light',
          },
        }}
        onClick={handleClick}
      >
        <PersonOutlinedIcon
          sx={{
            color: 'secondary.main',
          }}
        />
      </IconButton>
      {menuOpen && <UserMenu />}
    </div>
  );
}

export default UserButton;

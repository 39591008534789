/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import AddIcon from '@mui/icons-material/Add';
import { Box, ListItemButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  CreateDrawingValues,
  Drawing,
  DrawingDelete,
  DrawingShape,
  PartialCreateDrawingValues,
} from '../../models/drawings.model';
import {
  setCreateDrawingPanelVisible,
  setError,
  setLoading,
  setSelectedDrawing,
} from '../../state/drawings/drawings.slice';
import { setInitialFormValues } from './drawings.slice';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { deleteDrawings } from '../../api';
import DeleteConfirmationModal from '../../common-components/delete-confirmation-modal/delete-confirmation-modal';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { addHistoryItem, startNewHistory } from '../../nav-history.slice';
import DrawingsList from './drawings-list/drawings-list';

import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import MapHelpers from '../../map/map.utils';
import { goToDrawing, updateDrawings } from './drawings-panel.utils';

import ErrorSnackbar from '../../common-components/error-snackbar/error-snackbar';
import { EDossiers, setSelectedDossier } from '../../main-menu/menu.slice';
import { decimalToDMS } from '../../map/map-controls.utils';
import './drawings-panel.scss';

const initialValuesForDrawing = (
  drawing: Drawing
): PartialCreateDrawingValues => {
  const drawingData = drawing.region_data;
  const common: Partial<CreateDrawingValues['common']> = {
    drawingId: drawing.region_id,
    name: drawing.region_name,
    description: drawing.description,
    shape: drawingData.properties?.subType,
  };
  const drawingValues: PartialCreateDrawingValues = {
    common,
  };

  switch (drawingData.properties?.subType) {
    case DrawingShape.Circle: {
      const circleDrawingData = drawingData as GeoJSON.Feature<
        GeoJSON.Point,
        { radius: number }
      >;
      drawingValues[DrawingShape.Circle] = {
        centrePointInputLong:
          circleDrawingData.geometry.coordinates[0].toString(),
        centrePointInputLat:
          circleDrawingData.geometry.coordinates[1].toString(),
        radiusInNauticalMilesInput:
          circleDrawingData.properties.radius.toString(),
      };
      break;
    }

    case DrawingShape.Polygon: {
      // first and last points in a polygon are the same, but that would make for a confusing firn
      const polygonDrawingData =
        drawingData as GeoJSON.Feature<GeoJSON.Polygon>;
      const formPoints = polygonDrawingData.geometry.coordinates[0].map(
        (position) => [
          decimalToDMS(position[0], 'longitude', true),
          decimalToDMS(position[1], 'latitude', true),
        ]
      );

      drawingValues[DrawingShape.Polygon] = {
        coordinates: formPoints as [string, string][],
      };
      break;
    }

    case DrawingShape.Line: {
      const lineDrawingData =
        drawingData as GeoJSON.Feature<GeoJSON.LineString>;

      const formPoints = lineDrawingData.geometry.coordinates.map(
        (position) => [
          decimalToDMS(position[0], 'longitude', true),
          decimalToDMS(position[1], 'latitude', true),
        ]
      );

      drawingValues[DrawingShape.Line] = {
        coordinates: formPoints as [string, string][],
      };
      break;
    }

    case DrawingShape.Point: {
      const pointDrawingData = drawingData as GeoJSON.Feature<GeoJSON.Point>;
      drawingValues[DrawingShape.Point] = {
        inputLong: decimalToDMS(
          pointDrawingData.geometry.coordinates[0],
          'longitude',
          true
        ),
        inputLat: decimalToDMS(
          pointDrawingData.geometry.coordinates[1],
          'latitude',
          true
        ),
      };

      break;
    }
    default: {
      throw new Error(
        `The drawing shape ${drawing.region_data.properties?.subType} is not supported`
      );
    }
  }
  return drawingValues;
};

function DrawingsPanel() {
  const dispatch = useAppDispatch();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [drawingToDeleteName, setDrawingToDeleteName] = useState<string | null>(
    null
  );
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const [filteredDrawings, setFilteredDrawings] = useState<Drawing[]>([]);
  const drawings = useAppSelector((state) => state.drawings.drawings);
  const loading = useAppSelector((state) => state.drawings.loading);
  const error = useAppSelector((state) => state.drawings.error);
  const selectedDrawingId = useAppSelector(
    (state) => state.drawings.selectedDrawingId
  );
  const [entityError, setEntityError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredDrawings(drawings || []);
  }, [drawings]);

  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);

  useEffect(() => {
    if (!mapInitialised) {
      return;
    }
    dispatch(setLoading());

    updateDrawings();
  }, [mapInitialised]);

  const onInfoCardClick = (drawing: Drawing) => {
    goToDrawing(drawing);
    dispatch(startNewHistory({ type: 'drawings' }));
    dispatch(setSelectedDrawing(drawing));
    dispatch(setSelectedDossier(EDossiers.DRAWING));
    navigate(`/drawings/${drawing.region_id}`);
  };

  const onEditClick = (drawing: Drawing) => {
    goToDrawing(drawing);
    const initVals = initialValuesForDrawing(drawing);
    dispatch(setInitialFormValues(initVals));
    dispatch(addHistoryItem({ type: 'drawings' }));
    dispatch(setCreateDrawingPanelVisible(true));
  };

  const transformToDrawingDelete = (drawingInfo: Drawing): DrawingDelete => {
    /* eslint-disable @typescript-eslint/naming-convention */
    const { region_id } = drawingInfo;
    return { region_ids: [region_id] };
  };

  const removeDrawing = () => {
    if (drawingToDeleteName === null) {
      return;
    }

    const drawingToRemove = drawings?.find(
      (drawing) => drawing.region_name === drawingToDeleteName
    );

    if (!drawingToRemove) {
      return;
    }

    setDeleting(true);
    // multi-deletion of drawings is not supported yet from the FE
    const drawingToDeleteId = drawingToRemove.region_id;
    deleteDrawings(transformToDrawingDelete(drawingToRemove))
      .then(() => {
        updateDrawings();
        MapHelpers.deleteSource(drawingToDeleteId);
        setDeleteConfirmOpen(false);
        dispatch(setSelectedDrawing(null));
      })
      .catch(() => {
        dispatch(setError());
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const getDrawingNameFromId = (drawingId: string) => {
    const foundDrawing = drawings?.find(
      (drawing) => drawing.region_id === drawingId
    );
    return foundDrawing ? foundDrawing.region_name : null;
  };

  const handleSearchChange = (e: string) => {
    const inputValue = e;
    setSearchInputValue(inputValue);
    if (inputValue === '' && drawings) {
      setFilteredDrawings(drawings);
    } else {
      const filtered = drawings
        ? drawings.filter(
            (drawing) =>
              drawing.region_name
                .toLowerCase()
                .includes(inputValue.toLowerCase()) ||
              drawing.description
                .toLowerCase()
                .includes(inputValue.toLowerCase())
          )
        : [];
      setFilteredDrawings(filtered);
    }
  };

  useEffect(() => {
    if (selectedDrawingId && drawings?.length) {
      const newDrawing = drawings.find(
        (drawing) => drawing.region_id === selectedDrawingId
      );
      if (newDrawing) {
        dispatch(setSelectedDrawing(newDrawing));
        onInfoCardClick(newDrawing);
      } else {
        setEntityError(true);
      }
    }
  }, [selectedDrawingId, drawings]);

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setEntityError(false);
    navigate('/drawings');
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: '0px 20px',
          }}
        >
          <SimpleSearchBar
            handleChange={handleSearchChange}
            filterValue={searchInputValue}
            placeholder="Search Drawings"
            dataTestId="drawings-search-bar"
          />
        </Box>

        {!loading && !error && (
          <ListItemButton
            data-testid="create-area-button"
            disableGutters
            sx={{
              display: 'flex',
              maxHeight: '100px',
              alignItems: 'center',
              padding: '1rem 0',
              borderBottom: '1px solid',
              borderBottomColor: 'background.paper',
            }}
            // use method in drawings slice to set modal visibility
            onClick={() => {
              const initialState = {
                common: {},
                [DrawingShape.Circle]: {},
                [DrawingShape.Polygon]: {},
                [DrawingShape.Line]: {},
                [DrawingShape.Point]: {},
              };
              dispatch(setInitialFormValues(initialState));
              dispatch(startNewHistory({ type: 'drawings' }));
              dispatch(setCreateDrawingPanelVisible(true));
            }}
          >
            <Box
              sx={{
                display: 'flex',
                marginLeft: '1.75rem',
              }}
            >
              <AddIcon sx={{ fontSize: '22px', color: 'secondary.main' }} />
              <Typography> Add area with points </Typography>
            </Box>
          </ListItemButton>
        )}

        {loading && <LoadingPanel />}

        {!loading && error && (
          <ErrorPanel message="Error: Unable to fetch drawings data, please try again later... " />
        )}

        {!loading && !error && (
          <DrawingsList
            filteredDrawings={filteredDrawings}
            onInfoCardClick={onInfoCardClick}
            onEditClick={onEditClick}
            deleteDrawing={(drawingId) => {
              setDrawingToDeleteName(getDrawingNameFromId(drawingId));
              setDeleteConfirmOpen(true);
            }}
          />
        )}
      </Box>

      <DeleteConfirmationModal
        data-testid="confirm-delete-drawing-modal"
        itemsToDelete={[drawingToDeleteName as string]}
        visible={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        deleteFunction={() => removeDrawing()}
        deleting={deleting}
        typeOfThingBeingDeleted="drawing"
      />

      <ErrorSnackbar
        open={entityError}
        onClose={handleSnackbarClose}
        message="Unable to find drawing provided in URL"
      />
    </>
  );
}
export default DrawingsPanel;

import { MapMouseEvent, MapTouchEvent } from 'mapbox-gl';

import {
  EDossiers,
  EMenuItems,
  setSelectedDossier,
  setSelectedOption,
} from '../../../main-menu/menu.slice';
import { Drawing, DrawingShape } from '../../../models/drawings.model';
import { setSelectedDrawing } from '../../../state/drawings/drawings.slice';
import store from '../../../store';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import addLineLayer from '../layer-utils/add-line-layer';
import addPointLayer from '../layer-utils/add-point-layer';
import addPolygonLayer from '../layer-utils/add-polygon-layer';
import addSimpleGeoJsonSource from '../layer-utils/add-simple-geojson-source';
import onLayerClick from '../layer-utils/layer-click-handler';
import MapLayerPaint from '../map-layer-paint';
import { MapGroupLayer } from '../map-layer.enum';

const addDrawingLayer = (drawingId: string, data: GeoJSON.Feature) => {
  const onClick = (e: MapMouseEvent | MapTouchEvent) => {
    const { drawings } = store.getState().drawings;
    const features = MapHelpers.queryRenderedFeatures(e.point, {
      layers: [drawingId],
    });
    const areaClicked = drawings?.find(
      (drawing) => drawing.region_id === features[0].properties!.region_id
    );
    store.dispatch(setSelectedOption(EMenuItems.DRAWINGS));
    store.dispatch(setSelectedDrawing(areaClicked as Drawing));
    store.dispatch(setSelectedDossier(EDossiers.DRAWING));

    window.history.pushState({}, '', `/drawings/${areaClicked?.region_id}`);
  };

  const shape: DrawingShape = data.properties?.subType || DrawingShape.Polygon;

  // eslint-disable-next-line no-new
  new Promise((resolve) => {
    const geojson =
      shape === DrawingShape.Circle
        ? GeoHelper.convertFeatureCircleToPolygon(
            <GeoJSON.Feature<GeoJSON.Point>>data
          )
        : data;

    const geojsonWithDrawingId = {
      ...geojson,
      properties: {
        ...geojson.properties,
        region_id: drawingId,
      },
    };

    if (MapHelpers.getSource(drawingId)) {
      GeoHelper.setMapboxGeoJSONSourceData(drawingId, geojsonWithDrawingId);
      return;
    }

    addSimpleGeoJsonSource(drawingId);

    if (shape === DrawingShape.Line) {
      addLineLayer(
        drawingId,
        MapLayerPaint.DRAWING_LINE_DEFAULT_PAINT,
        MapGroupLayer.DRAWINGS
      );
    } else if (shape === DrawingShape.Point) {
      addPointLayer(
        drawingId,
        MapLayerPaint.DRAWING_CIRCLE_DEFAULT_PAINT,
        MapGroupLayer.DRAWINGS
      );
    } else {
      addPolygonLayer(
        drawingId,
        MapLayerPaint.DRAWING_SHAPE_DEFAULT_PAINT,
        MapGroupLayer.DRAWINGS
      );
    }
    GeoHelper.setMapboxGeoJSONSourceData(drawingId, geojsonWithDrawingId);

    MapHelpers.addMapEventListener('click', drawingId, onLayerClick(onClick));

    resolve(MapHelpers);
  });
};

export default addDrawingLayer;

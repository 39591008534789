/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Button, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useAppSelector } from '../../../../hooks';
import './history-results-export-modal.scss';

import SimpleDialog from '../../../../common-components/dialog/SimpleDialog';
import MuiFormikTextField from '../../../../common-components/form-fields/mui-formik-text-field';
import MuiSelectField from '../../../../common-components/form-fields/mui-select-field';
import DownloadHelpers from '../../../../utils/download.utils';
import GeoHelper from '../../../../utils/geo-helpers.utils';
import {
  HistoryResultsExportFormValues,
  validateHistoryResultsExportForm,
} from './history-results-export-validators';

function RenderHistoryResultsExportForm(props: { closeModal: () => void }) {
  const { closeModal } = props;
  const { historicVesselPoints } = useAppSelector(
    (state) => state.historyPanel
  );

  const handleExport = (values: HistoryResultsExportFormValues) => {
    const { fileFormat, fileName } = values;
    const getFileContent = {
      [DownloadHelpers.FileFormat.JSON]: () =>
        DownloadHelpers.exportAs.JSON(
          GeoHelper.createGeoJSON(
            GeoHelper.vesselsToGeoJSONFeatures(historicVesselPoints)
          )
        ),
      [DownloadHelpers.FileFormat.CSV]: () =>
        DownloadHelpers.exportAs.CSV(historicVesselPoints),
    };

    DownloadHelpers.downloadURI(
      `${fileName}.${fileFormat}`,
      getFileContent[fileFormat]()
    );
  };

  const formik = useFormik({
    initialValues: {
      fileFormat: DownloadHelpers.FileFormat.JSON,
      fileName: '',
    },
    onSubmit: handleExport,
    validate: (values) => validateHistoryResultsExportForm(values),
    enableReinitialize: true,
  });

  return (
    <Box
      // className="history-results-export-form"
      data-testid="history-results-export-form"
    >
      <Typography sx={{ margin: '20px 0px' }}>
        Please Provide a File Name and Select a Format for your exported file.
      </Typography>

      <FormikProvider value={formik}>
        <Form>
          <Box sx={{ margin: '20px 0px' }}>
            <MuiFormikTextField
              label="Choose file name"
              placeholder="Enter filename"
              name="fileName"
              dataTestId="history-results-export-file-name"
              id="history-results-export-file-name"
              size="small"
              sx={{ marginBottom: '20px' }}
            />
            <MuiSelectField
              name="fileFormat"
              placeholder="Select Format"
              options={[
                { name: 'JSON', value: DownloadHelpers.FileFormat.JSON },
                { name: 'CSV', value: DownloadHelpers.FileFormat.CSV },
              ]}
              label="File Format"
              ariaLabel="history-results-export-file-format"
            />
          </Box>

          <Box>
            <Button
              variant="contained"
              color="primary"
              data-testid="download-history-button"
              type="submit"
              aria-label="download-history-button"
              sx={{
                borderRadius: '1.5rem',
                mx: '1rem 1.5rem',
                borderColor: 'secondary.main',
                '&:hover': {
                  bgcolor: 'secondary.main',
                  color: 'white',
                },
                float: 'right',
              }}
              disabled={Object.keys(formik.errors).length > 0}
            >
              Download
            </Button>
            <Button
              variant="outlined"
              color="primary"
              data-testid="cancel-history-cancel-button"
              sx={{
                borderRadius: '1.5rem',
                mx: '1rem 1.5rem',
                marginRight: '1rem',
                borderColor: 'secondary.main',
                '&:hover': {
                  bgcolor: 'secondary.main',
                  color: 'white',
                },
                float: 'right',
              }}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </Box>
  );
}

interface HistoryResultsExportModalProps {
  showModal: boolean;
  closeModal: () => void;
}

function HistoryResultsExportModal(props: HistoryResultsExportModalProps) {
  const { showModal, closeModal } = props;

  return (
    <SimpleDialog
      title="Download Vessel History"
      onClose={closeModal}
      open={showModal}
    >
      <RenderHistoryResultsExportForm closeModal={closeModal} />
    </SimpleDialog>
  );
}

export default HistoryResultsExportModal;

/* eslint-disable @typescript-eslint/naming-convention */
import { MapLayerMouseEvent, Popup } from 'mapbox-gl';
import { HistoricVesselPoint } from '../../../maritime-menu-options/history-panel/historic-vessel-point.model';
import PopupHelpers from '../../../popup/popup.utils';
import store from '../../../store';
import DateTimeHelpers from '../../../utils/date-time-helpers.utils';

const popup = new Popup({
  closeButton: false,
  closeOnMove: true,
});

export function hideAISPositionInfoPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = '';
  popup.remove();
}

export function showAISPositionInfoPopup(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  const {
    mergedPointsEnabled,
    mergedPointsMinimumCount,
    mergedPointsMinimumGapLength,
  } = store.getState().historyPanel;

  const { selectedVessels } = store.getState().vesselDossier;

  if (e.features?.length) {
    const feature = e.features[0];
    const properties = feature.properties as HistoricVesselPoint;
    const {
      mmsi,
      imo,
      name,
      speed,
      timestamp,
      startTimestamp,
      endTimestamp,
      pointCount,
      vessel_id,
    } = properties;

    const selectedImo =
      imo && imo !== 'n/a' ? imo : selectedVessels?.byId[vessel_id]?.imo;
    const selectedName =
      name && name !== 'n/a' ? name : selectedVessels?.byId[vessel_id]?.name;

    const duration =
      endTimestamp &&
      startTimestamp &&
      DateTimeHelpers.getHoursInMilliseconds(endTimestamp - startTimestamp);

    const isWithinPointsCount =
      pointCount && pointCount >= mergedPointsMinimumCount;
    const isWithinDuration =
      duration && duration >= mergedPointsMinimumGapLength;

    const shouldShowMergedPointsInfo =
      mergedPointsEnabled && isWithinPointsCount && isWithinDuration;

    const header = shouldShowMergedPointsInfo
      ? `${pointCount} Points in current position`
      : '';
    const mergedPointsPopupInfo = [
      ...(startTimestamp && endTimestamp
        ? [
            {
              label: 'From',
              // TODO: move to date helpers
              value: new Date(startTimestamp)
                .toISOString()
                .replace('T', ' ')
                .substring(0, 19),
            },
            {
              label: 'To',
              // TODO: move to date helpers
              value: new Date(endTimestamp)
                .toISOString()
                .replace('T', ' ')
                .substring(0, 19),
            },
            {
              label: 'Hours',
              value: duration,
            },
            {
              label: '',
              value: '&nbsp;',
            },
          ]
        : []),
    ];

    PopupHelpers.createInfoCard(
      popup,
      [
        ...(shouldShowMergedPointsInfo ? mergedPointsPopupInfo : []),
        {
          label: 'Name',
          value: selectedName,
        },
        {
          label: 'IMO',
          value: selectedImo || 'Unknown',
        },
        {
          label: 'MMSI',
          value: mmsi,
        },
        {
          label: 'Speed',
          value: `${speed.toFixed(1)} knots`,
        },
        {
          label: 'Timestamp',
          value: new Date(timestamp).toISOString().substring(0, 19),
        },
        ...(pointCount
          ? []
          : [
              {
                label: '',
                // TODO: move to date helpers
                value: new Date(timestamp)
                  .toISOString()
                  .replace('T', ' ')
                  .substring(0, 19),
              },
            ]),
      ],
      header
    );

    // @ts-ignore
    // can't figure out the typing needed for coordinates
    popup.setLngLat(feature.geometry.coordinates);
    popup.addTo(e.target);
  }
}

import { StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    margin: 10,
    padding: '20px 20px 70px 0px', // https://github.com/diegomura/react-pdf/issues/1439#issuecomment-1321868253
  },
  section: {
    padding: '0px 20px 0px 20px',
    fontSize: 14,
  },
  title: {
    fontSize: 24,
    color: '#203058',
    fontFamily: 'Helvetica-Bold',
  },
  titleSubTitle: {
    color: '#203058',
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
  },
  subTitle: {
    fontFamily: 'Helvetica-Bold',
    color: '#203058',
    fontSize: 10,
    margin: '5px 5px 0px 5px',
  },
  textBody: {
    color: '#3C3D3D',
    margin: '5px 5px 20px 5px',
    fontSize: 10,
  },
});

export default styles;

import { Auth } from '@aws-amplify/auth';
import { setSelectedVesselIds } from './dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import { NORTH_STANDARD_IDP_NAME } from './environment';
import { Privileges } from './hooks/access-control/privileges';
import {
  EDossiers,
  EMenuItems,
  setSelectedDossier,
  setSelectedOption,
} from './main-menu/menu.slice';
import { setSelectedAreaId } from './maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import { setSelectedCorrespondentId } from './state/correspondents/correspondents.slice';
import { setSelectedCountryId } from './state/countries/countries.slice';
import { setSelectedDrawingId } from './state/drawings/drawings.slice';
import { setSelectedIncidentId } from './state/incidents/incidents.slice';
import { setSelectedFirstCallPortId } from './state/ports/first-call.slice';
import { setSelectedPortId } from './state/ports/ports.slice';
import { setSelectedRouteId } from './state/routes/routes.slice';
import store from './store';

type RoutableEntityType =
  | 'vessel'
  | 'incident'
  | 'port'
  | 'country'
  | 'route'
  | 'boundary'
  | 'drawing'
  | 'alert'
  | 'ri-maritime-area'
  | 'first-call-ports'
  | 'correspondents'
  | 'login';

type RoutableMenu =
  | 'vessels'
  | 'incidents'
  | 'ports'
  | 'countries'
  | 'routes'
  | 'boundaries'
  | 'drawings'
  | 'alerts'
  | 'history'
  | 'documents'
  | 'ri-maritime-areas'
  | 'correspondents';

type RoutableEntity = RoutableEntityType | RoutableMenu;

const handleEntityRoute = (
  privileges: Privileges,
  routeSplat: string,
  searchParams?: URLSearchParams
) => {
  const { dispatch } = store;
  const splitPath = routeSplat.split('/');
  const entityType = splitPath[0] as RoutableEntity;
  const entityId = splitPath[1];

  switch (entityType) {
    case 'incident':
    case 'incidents':
      if (entityId) {
        dispatch(setSelectedDossier(EDossiers.INCIDENT));
        dispatch(setSelectedIncidentId(entityId));
      } else {
        dispatch(setSelectedOption(EMenuItems.INCIDENTS));
      }
      break;
    case 'vessel':
    case 'vessels':
      if (searchParams?.get('ids')) {
        dispatch(setSelectedDossier(EDossiers.VESSEL));
        if (privileges.canMultiSelect) {
          dispatch(
            setSelectedVesselIds(searchParams.get('ids')?.split(',') || null)
          );
        } else {
          dispatch(
            setSelectedVesselIds(
              searchParams.get('ids')?.split(',')?.slice(0, 1) || null
            )
          );
        }
      }
      break;
    case 'port':
    case 'ports':
      if (entityId) {
        // Could be UNLOCODE or RI port id
        const trimmed = entityId.replace(/[^a-zA-Z0-9]/g, '');

        dispatch(setSelectedDossier(EDossiers.PORT));
        dispatch(setSelectedPortId(trimmed));
      }
      dispatch(setSelectedOption(EMenuItems.PORTS));

      break;
    case 'route':
    case 'routes':
      if (entityId) {
        dispatch(setSelectedDossier(EDossiers.ROUTE));
        dispatch(setSelectedRouteId(entityId));
      } else {
        dispatch(setSelectedOption(EMenuItems.ROUTES));
      }
      break;
    case 'history':
      dispatch(setSelectedOption(EMenuItems.HISTORY));
      break;
    case 'ri-maritime-areas':
    case 'ri-maritime-area':
      if (entityId) {
        dispatch(setSelectedDossier(EDossiers.RI_MARITIME_AREA));
        dispatch(setSelectedAreaId(Number(entityId)));
      } else {
        dispatch(setSelectedOption(EMenuItems.RI_MARITIME_AREAS));
      }
      break;
    case 'boundary':
    case 'boundaries':
      // currently no specific boundary panel
      dispatch(setSelectedOption(EMenuItems.BOUNDARIES));
      dispatch(setSelectedDrawingId(entityId));
      break;
    case 'drawing':
    case 'drawings':
      // currently no specific drawing panel
      dispatch(setSelectedOption(EMenuItems.DRAWINGS));
      if (entityId) {
        dispatch(setSelectedDossier(EDossiers.DRAWING));
      }
      break;
    case 'country':
    case 'countries':
      if (entityId) {
        dispatch(setSelectedDossier(EDossiers.COUNTRY));
        dispatch(setSelectedCountryId(entityId));
      } else {
        dispatch(setSelectedOption(EMenuItems.COUNTRIES));
      }
      break;
    case 'documents':
      dispatch(setSelectedOption(EMenuItems.DOCUMENTS));
      break;
    case 'first-call-ports': {
      dispatch(setSelectedOption(EMenuItems.FIRST_CALL_PORTS));
      if (entityId) {
        dispatch(setSelectedDossier(EDossiers.FIRST_CALL));
        dispatch(setSelectedFirstCallPortId(entityId));
      }
      break;
    }
    case 'correspondents': {
      dispatch(setSelectedOption(EMenuItems.CORRESPONDENTS));
      if (entityId) {
        dispatch(setSelectedDossier(EDossiers.CORRESPONDENTS));
        dispatch(setSelectedCorrespondentId(entityId));
      }
      break;
    }
    case 'login': {
      Auth.federatedSignIn({
        customProvider: NORTH_STANDARD_IDP_NAME as string,
      });
      break;
    }
    default:
  }
};

export default handleEntityRoute;

import { Button, Container, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadHelpers from '../../utils/download.utils';

import UploadFileButton from '../upload-file/upload-file';
import AttachmentListItem from './attachment-list-item';
import './upload-attachments.scss';

interface UploadAttachmentsProps {
  onChange: (files: any) => void;
  onClearAll: () => void;
  attachments: File[];
  acceptedFileTypes: DownloadHelpers.FileMimeType[];
  removeAttachment: (index: number) => void;
}

function UploadAttachments(props: UploadAttachmentsProps) {
  const {
    onChange,
    onClearAll,
    attachments,
    acceptedFileTypes,
    removeAttachment,
  } = props;

  // get object keys by value
  // https://stackoverflow.com/questions/62215454/how-to-get-enum-key-by-value-in-typescript
  const supportedFileFormats = acceptedFileTypes.map(
    (fileType) =>
      Object.keys(DownloadHelpers.FileMimeType)[
        Object.values(DownloadHelpers.FileMimeType).indexOf(fileType)
      ]
  );

  return (
    <Container
      className="upload-attachments-form"
      disableGutters
      sx={{ padding: 0 }}
    >
      <div className="attachments-list">
        {attachments.map((attachment, index) => (
          <AttachmentListItem
            key={`${index.toString()}_${attachment.name}`}
            fileName={attachment.name}
            removeAttachment={() => removeAttachment(index)}
          />
        ))}
      </div>

      <Stack
        className="upload-attachments-form-supported-files"
        data-testid="supported-file-formats"
      >
        <Typography>Supported File Types:</Typography>
        <Typography className="files-list">
          {supportedFileFormats.join(', ')}
        </Typography>
      </Stack>

      <Stack
        direction="row"
        className="upload-attachments-controls"
        sx={{
          '& > *': {
            width: '45%',
            marginTop: '1rem',
          },
        }}
      >
        <UploadFileButton
          label="Upload Attachments"
          acceptedFileTypes={acceptedFileTypes.join(',')}
          onChange={onChange}
        />
        <Button
          data-testid="clear-all-attachments"
          startIcon={<DeleteIcon />}
          onClick={onClearAll}
          variant="contained"
          disabled={attachments.length === 0}
        >
          <Typography>Clear All Attachments</Typography>
        </Button>
      </Stack>
    </Container>
  );
}

export default UploadAttachments;

import { bboxify } from '@mapbox/geojson-extent';
import { bboxPolygon, lineString, bbox as turfBbox } from '@turf/turf';
import mapboxgl, {
  Expression,
  Layer,
  LngLatBoundsLike,
  LngLatLike,
  MapLayerEventType,
  MapMouseEvent,
  MapTouchEvent,
} from 'mapbox-gl';
import { getMapboxGeocode, getMapboxStyle } from '../api/mapbox';
import { Vessel } from '../models/vessel.model';
import { setTickerOpen } from '../state/incidents/incidents.slice';
import store from '../store';
import { nsTheme } from '../theme';
import { nearlyEquals } from '../utils/measurement-helpers';
import { UserPreferencesBaseMap } from '../utils/user.enum';
import CustomMapEvents from './custom-map-events.enum';
import MapLayerVisibility from './map-layer-manager/map-layer-visibility.enum';
import MapLayer from './map-layer-manager/map-layer.enum';
import MapStyle from './map-style';
import {
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM,
  MapExtent,
  setBathymetryOpacity,
  setCentre,
  setFitBounds,
  setFlyTo,
  setMapExtent,
  setMapStyle,
  setMapZoom,
  setPaintProperty,
  setStyle,
} from './map.slice';

export interface ExtendedMapEvent extends MapMouseEvent {
  isDrawing?: boolean;
  layerClickHandled?: boolean;
}

export const userPreferencesBaseMapStyle = {
  [UserPreferencesBaseMap.DEFAULT]: MapStyle.DEFAULT,
};

export const buildMapboxCaseStatement = (
  vesselIds: string[],
  opacity: number
) => {
  // get the vessel_id or unique_vessel_identifier, whichever is not null
  const coalesce = [
    'coalesce',
    ['get', 'vessel_id'],
    ['get', 'unique_vessel_identifier'],
  ];
  // ['case', boolean, ifTrue, ifFalse]
  return [
    'case',
    // if vessel_id or unique_vessel_identifier is in vesselIds array
    ['in', coalesce, ['literal', vesselIds]],
    opacity,
    1,
  ];
};

namespace MapHelpers {
  const { mapZoom } = store.getState().map;

  // Helps us access map outside of components
  export const getMapInstance = () => store.getState().map.map;
  export const getLayer = (sourceId: string) => {
    const map = getMapInstance();
    try {
      if (map && map.getLayer(sourceId)) {
        return map.getLayer(sourceId);
      }
    } catch {
      // sometimes there will be a map but getLayer doesn't exist
    }
    return null;
  };

  export const setFillLayerOpacity = async (
    layerId: MapLayer,
    opacity: number
  ) => {
    const layer = getLayer(layerId);
    if (layer) {
      store.dispatch(
        setPaintProperty({
          layerId,
          name: 'fill-opacity',
          value: opacity / 100,
        })
      );

      if (layerId === MapLayer.BATHYMETRY) {
        store.dispatch(setBathymetryOpacity(opacity));
      }
    }
  };

  export const setVesselOpacity = (
    vesselIds: string[],
    // if layerId is a function, it will be called with the vesselId as an argument
    layerId: MapLayer | string | ((vesselId: string) => string),
    opacity: number
  ) => {
    if (layerId instanceof Function) {
      vesselIds.forEach((vesselId) => {
        const layer = getLayer(layerId(vesselId));
        if (layer) {
          store.dispatch(
            setPaintProperty({
              layerId: layerId(vesselId),
              name: 'icon-opacity',
              value: buildMapboxCaseStatement([vesselId], opacity),
            })
          );
        }
      });
    } else {
      if (!getLayer(layerId)) {
        return;
      }
      store.dispatch(
        setPaintProperty({
          layerId,
          name: 'icon-opacity',
          value: buildMapboxCaseStatement(vesselIds, opacity),
        })
      );
    }
  };

  export const setLayoutProperty = (
    layer: string,
    name: string,
    value: any
  ) => {
    const map = getMapInstance();
    if (map.getLayer(layer)) {
      map.setLayoutProperty(layer, name, value);
    }
  };

  export const getLayoutProperty = (layer: string, name: string) => {
    const map = getMapInstance();
    return map.getLayoutProperty(layer, name);
  };

  export const setVesselIcon = (
    vessel: Vessel,
    layerId: MapLayer,
    icon: string
  ) => {
    // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#match

    // Use mmsi if no vessel id available
    MapHelpers.setLayoutProperty(layerId, 'icon-image', [
      'match',
      ['get', 'mmsi'],
      vessel.mmsi,
      icon,
      // fallback icon. use default one here
      MapHelpers.getLayoutProperty(layerId, 'icon-image'),
    ]);
  };

  export const isLayerVisible = (layerSource: string) => {
    const layer = getLayer(layerSource)!;
    return (
      layer &&
      getLayoutProperty(layerSource, 'visibility') ===
        MapLayerVisibility.VISIBLE
    );
  };

  export const LayerVisibilityChangeType = (layerId: string) =>
    `${layerId}_${CustomMapEvents.LAYER_VISIBILITY_CHANGE}`;

  export const fire = (type: string, properties?: {}) => {
    const map = getMapInstance();
    map.fire(type, properties);
  };

  export const getSource = (sourceId: string) => {
    const map = getMapInstance();
    if (map && map.getSource(sourceId)) {
      return map.getSource(sourceId);
    }
    return null;
  };

  export const triggerLayerVisibilityChange = (layerId: string) => {
    fire(LayerVisibilityChangeType(layerId), getSource(layerId)!);
  };

  export const removeMapEventListener = (
    type: string,
    callback:
      | ((e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void)
      | ((e: mapboxgl.MapTouchEvent & mapboxgl.EventData) => void)
  ) => {
    const map = getMapInstance();
    try {
      map.off(type, callback);
    } catch {
      // no map event listener to remove
    }
  };

  export const removeLayer = (layerId: string) => {
    const map = getMapInstance();
    map.removeLayer(layerId);
  };

  export const removeLayerVisibilityEvent = (
    layerId: string,
    callback: (ev: any) => void
  ) => {
    removeMapEventListener(LayerVisibilityChangeType(layerId), callback);
  };

  export const setLayerVisibility = async (
    layerId: string,
    visible: boolean
  ) => {
    if (layerId === MapLayer.INCIDENTS) {
      store.dispatch(setTickerOpen(visible));
    }
    setLayoutProperty(
      layerId,
      'visibility',
      visible ? MapLayerVisibility.VISIBLE : MapLayerVisibility.NOT_VISIBLE
    );
    triggerLayerVisibilityChange(layerId);
  };

  export const setLayerVisibilityIfExists = async (
    layerSource: string,
    visible: boolean
  ) => {
    if (getLayer(layerSource)) {
      setLayerVisibility(layerSource, visible);
    }
  };

  // currently used in Alerts but will either set the vessels to be coloured
  // the same as the alert colour or set them to the standard alert vessel
  // colour
  export const swapVesselColouring = (layerSource: string) => {
    const colouredLayer = `${layerSource}-coloured`;
    if (isLayerVisible(layerSource)) {
      setLayerVisibilityIfExists(layerSource, false);
      setLayerVisibilityIfExists(colouredLayer, true);
    } else {
      setLayerVisibilityIfExists(layerSource, true);
      setLayerVisibilityIfExists(colouredLayer, false);
    }
  };

  export const setVesselMultiColour = (layerId: MapLayer) => {
    store.dispatch(
      setPaintProperty({
        layerId,
        name: 'icon-color',
        value: '#FFFFFF',
      })
    );
  };

  export const calculateLeftCameraPadding = (
    secondaryMenuOpen: boolean,
    entitySelected: boolean
  ) => {
    let leftPadding = 0;
    const app = document
      .querySelector('.App')
      ?.querySelector('.menu-container');
    // If secondary pane is open, but no popover dosser is open
    if (secondaryMenuOpen || (entitySelected && !secondaryMenuOpen)) {
      const secondaryMenuEl = app?.querySelector('.secondary-pane-container');
      if (secondaryMenuEl) {
        const boundingClientRect = secondaryMenuEl.getBoundingClientRect();
        leftPadding += boundingClientRect.width;
      }
    }
    // If entity is selected and secondary pane is open
    if (entitySelected && secondaryMenuOpen) {
      const popoverContainerEl = app?.querySelector('.popoverContainer');
      if (popoverContainerEl) {
        const boundingClientRect = popoverContainerEl.getBoundingClientRect();
        leftPadding += boundingClientRect.width;
      }
    }

    return leftPadding;
  };

  const getMainMenuRect = () => {
    const app = document.querySelector('.App');
    const mainMenuEl = app?.querySelector('.menu-container')?.children[0];

    return mainMenuEl ? mainMenuEl.getBoundingClientRect() : undefined;
  };

  export const createZoomPadding = (padding = window.innerWidth * 0.1) => {
    // Zoom to Feature and put in center of map viewport
    // We cannot pad more than the width or height of the user's device, otherwise mapbox throws an error.
    // TODO: Take in to account other menus / elements in front of map element.
    const mainMenuRect = getMainMenuRect();
    const left = Math.min(padding, padding + (mainMenuRect?.width ?? 0));

    return {
      padding: {
        top: padding,
        left,
        bottom: padding,
        right: padding,
      },
    };
  };

  export const flyTo = (options: {}) => {
    store.dispatch(setFlyTo(options));
  };

  export const zoomToPoint = (coordinates: mapboxgl.LngLatLike, zoom = 7) => {
    flyTo({
      zoom: Math.max(mapZoom, zoom),
      center: coordinates,
      curve: 2,
      speed: 0.5,
      animate: true,
    });
  };

  export const zoomToPointFast = (
    coords: mapboxgl.LngLatLike,
    options: Partial<mapboxgl.FlyToOptions> = { zoom: 10 }
  ) => {
    const defaultOptions: mapboxgl.FlyToOptions = {
      ...createZoomPadding(),
      center: coords,
      curve: 1.4,
      speed: 1.8,
      zoom: Math.max(mapZoom, options.zoom ?? 10),
      ...options,
    };
    if (options.duration) {
      delete defaultOptions.speed;
    }
    flyTo(defaultOptions);
  };

  // return to a centered globe view. Good for when the map is likely to shortly go somewhere else but we're not yet sure where.
  export const returnToGlobeView = () => {
    zoomToPoint(DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM);
  };

  // Useful to check if the map is currently in a globe view.
  // If the user has moved the map from this 'default' position,
  // we should not interrupt them with another jarring move
  export const isInGlobeView = () => {
    const map = getMapInstance();
    const center = map.getCenter();
    const zoom = map.getZoom();
    return (
      // floating point errors mean the numbers may not exactly match
      nearlyEquals(center.lng, DEFAULT_MAP_CENTER[0]) &&
      nearlyEquals(center.lat, DEFAULT_MAP_CENTER[1]) &&
      zoom === DEFAULT_MAP_ZOOM
    );
  };

  export const once = (
    type: string,
    listener: (ev: mapboxgl.MapMouseEvent & mapboxgl.EventData) => void
  ) => {
    const map = getMapInstance();
    map.once(type, listener);
  };

  export const queryRenderedFeatures = (
    point: mapboxgl.Point | undefined,
    options?: {}
  ) => {
    const map = getMapInstance();
    return map.queryRenderedFeatures(point, options);
  };

  export const addMapEventListener = (
    eventName: string,
    layer: string | string[] | null,
    callback: (e: any) => void
  ) => {
    const map = getMapInstance();
    if (layer) {
      map.on(eventName as keyof MapLayerEventType, layer!, callback);
    } else {
      map.on(eventName, callback);
    }
  };

  export const zoomToBBox = (bbox: LngLatBoundsLike) => {
    /*
     * Bug in mapbox, using fitBounds after a flyTo adds their paddings together
     * which can cause there to be less screen than padding, which causes fitbounds to fail.
     * See https://github.com/mapbox/mapbox-gl-js/issues/11831
     * Workaround: setPadding back to 0 manually.
     * Padding now set in fitBounds useEffect hook in maps.tsx
     */
    store.dispatch(
      setFitBounds({
        bounds: bbox,
        options: {
          ...createZoomPadding(),
        },
      })
    );
  };

  export const zoomToFeatureCollection = (
    featureCollection: GeoJSON.GeoJSON,
    options?: mapboxgl.FitBoundsOptions
  ) => {
    try {
      // If feature already possesses a bbox, use it
      const bounds = featureCollection.bbox || bboxify(featureCollection).bbox!;
      /*
       * Bug in mapbox, using fitBounds after a flyTo adds their paddings together
       * which can cause there to be less screen than padding, which causes fitbounds to fail.
       * See https://github.com/mapbox/mapbox-gl-js/issues/11831
       * Workaround: setPadding back to 0 manually.
       * Padding now set in fitBounds useEffect hook in maps.tsx
       */
      store.dispatch(
        setFitBounds({
          bounds: bounds as LngLatBoundsLike,
          options: {
            ...createZoomPadding(),
            ...options,
          },
        })
      );
    } catch (error) {
      // Handle the error
    }
  };

  export const zoomToLine = (point1: LngLatLike, point2: LngLatLike) => {
    const line = lineString([
      point1 as [number, number],
      point2 as [number, number],
    ]);
    const boundingBox = turfBbox(line);
    const boundingBoxPolygon = bboxPolygon(boundingBox);

    // map.fitBounds(bounds, createZoomPadding(200));
    zoomToFeatureCollection(boundingBoxPolygon, { maxZoom: 21 });
  };

  export const zoomToFeature = (feature: GeoJSON.Feature) => {
    // Get the bounding box of the feature
    const boundingBox = turfBbox(feature);

    // Create a polygon from the bounding box
    const boundingBoxPolygon = bboxPolygon(boundingBox);

    // Zoom to the polygon
    zoomToFeatureCollection(boundingBoxPolygon, { maxZoom: 21 });
  };

  export const zoomToPolygon = (polygon: GeoJSON.Polygon) => {
    // Get the bounding box of the polygon
    const boundingBox = turfBbox(polygon);

    // Create a polygon from the bounding box
    const boundingBoxPolygon = bboxPolygon(boundingBox);

    // Zoom to the polygon
    zoomToFeatureCollection(boundingBoxPolygon, { maxZoom: 21 });
  };

  export const countryUnderMouse = async (
    event: MapMouseEvent | MapTouchEvent
  ) => {
    // if the clicked point is not on the globe, we want to abort,
    // as event.lngLat will be mapped to the nearest point on the globe which is barely visible
    if (!getMapInstance().isPointOnSurface(event.point)) {
      return undefined;
    }

    const result = await getMapboxGeocode(event.lngLat.lng, event.lngLat.lat);
    // The api has a default 'limit' of 1, so features length should be 1 or 0.
    if (result.features.length > 0) {
      const feature = result.features[0];
      return feature;
    }
    return undefined;
  };

  export const removeSource = (sourceId: string) => {
    const map = getMapInstance();
    map.removeSource(sourceId);
  };

  export const deleteSource = (sourceId: string) => {
    // Remove labels layer if it exists
    if (getLayer(`${sourceId}_labels`)) {
      removeLayer(`${sourceId}_labels`);
    }

    // Remove radius aura layers if they exist
    if (getLayer(`${sourceId}_radius`)) {
      removeLayer(`${sourceId}_radius`);
    }

    if (getSource(sourceId)) {
      removeLayer(sourceId);
      removeSource(sourceId);
    }

    if (getSource(`${sourceId}_labels`)) {
      removeSource(`${sourceId}_labels`);
    }

    if (getSource(`${sourceId}_radius`)) {
      removeSource(`${sourceId}_radius`);
    }
  };

  export const deleteSources = (sourceIds: string[]) => {
    sourceIds.forEach((sourceId) => {
      deleteSource(sourceId);
    });
  };

  export const addSource = (
    sourceId: string,
    options: mapboxgl.AnySourceData
  ) => {
    const map = getMapInstance();
    map.addSource(sourceId, options);
  };

  export const addLayer = (layer: mapboxgl.AnyLayer) => {
    const map = getMapInstance();
    map.addLayer(layer);
  };

  export const moveLayer = (layerId: string, beforeId?: string) => {
    const map = getMapInstance();
    map.moveLayer(layerId, beforeId);
  };

  export const getStyle = () => {
    const map = getMapInstance();
    return map.getStyle();
  };

  export const getStyleValid = () => {
    const map = getMapInstance();
    try {
      return map?.getStyle?.();
    } catch (e) {
      if ((e as Error).message.includes("(reading 'version')")) {
        // mapbox bug, ignore
        return false;
      }
      throw e;
    }
  };

  export const setVesselSingleColour = (
    layerId: MapLayer,
    value: string | Expression = '#FF0000'
  ) => {
    const map = getMapInstance();
    if (getStyleValid()) {
      map.setPaintProperty(layerId, 'icon-color', value);
    }
  };

  export const getAllLayers = () => {
    let layers: mapboxgl.AnyLayer[] = [];
    // Try Catch added because sometimes map.getStyle() temporarily throws error on
    // resizing the map

    try {
      layers = getStyle().layers;
      return layers.map((layer) => getLayer(layer.id)!);
    } catch (e) {
      return layers;
    }
  };

  // https://docs.mapbox.com/mapbox-gl-js/api/map/#map#movelayer
  export const moveLayerIfExists = (
    layerId: string,
    beforeLayerId?: string
  ) => {
    if (!beforeLayerId) {
      if (getLayer(layerId)) {
        moveLayer(layerId);
      }
      return;
    }
    if (getLayer(layerId) && getLayer(beforeLayerId)) {
      moveLayer(layerId, beforeLayerId);
    }
  };

  export const checkMapExtentValidity = (extent: MapExtent) => {
    if (
      !extent ||
      !extent.center ||
      !extent.zoom ||
      extent.center[0] < -180 ||
      extent.center[0] > 180 ||
      extent.center[1] < -90 ||
      extent.center[1] > 90 ||
      extent.zoom < 0 ||
      extent.zoom > 22
    ) {
      return false;
    }
    return true;
  };

  export const updateMapExtent = (extent: MapExtent) => {
    if (checkMapExtentValidity(extent)) {
      store.dispatch(setMapExtent(extent));
      store.dispatch(setCentre(extent.center));
      store.dispatch(setMapZoom(extent.zoom));
    }
  };

  export const updateMapStyle = async () => {
    const map = getMapInstance();
    let currentStyle: mapboxgl.Style | undefined;
    try {
      currentStyle = map?.getStyle?.();
    } catch (e) {
      if ((e as Error).message.includes("(reading 'version')")) {
        // mapbox bug, ignore
        return;
      }
      throw e;
    }

    const mapStyle = nsTheme.mapboxStyle;

    const loadFallbackStyle = () => {
      store.dispatch(
        setMapStyle({ style: mapStyle, options: { diff: false } })
      );
    };

    store.dispatch(setStyle(mapStyle));

    try {
      if (!currentStyle) {
        loadFallbackStyle();
        return;
      }

      // https://github.com/mapbox/mapbox-gl-js/issues/4006#issuecomment-772462907
      // Merge current layers with layers from the new style

      const mapStyleResponse = await getMapboxStyle(
        mapStyle.replaceAll('mapbox://styles/', '')
      );
      const newStyle = mapStyleResponse;
      // ensure any sources from the current style are copied across to the new style
      newStyle.sources = { ...currentStyle.sources, ...newStyle.sources };

      let labelIndex = newStyle.layers.findIndex(
        (el: Layer) => el.id === 'waterway-label'
      );

      // default to on top
      if (labelIndex === -1) {
        labelIndex = newStyle.layers.length;
      }
      const appLayers = (currentStyle.layers as Layer[]).filter(
        (el) =>
          // app layers are the layers to retain, and these are any layers which have a different source set
          el.source && el.source !== 'mapbox' && el.source !== 'composite'
      );

      newStyle.layers = [
        ...newStyle.layers.slice(0, labelIndex),
        ...appLayers,
        ...newStyle.layers.slice(labelIndex, -1),
      ];
      store.dispatch(setMapStyle({ style: newStyle }));
    } catch (_error) {
      loadFallbackStyle();
    }
  };

  export const onLayerVisibilityChange = (
    layerId: string,
    callback: (ev: any) => void
  ) => {
    addMapEventListener(LayerVisibilityChangeType(layerId), null, callback);
  };

  export const addImage = (
    imageName: string,
    img: HTMLImageElement,
    options?: {}
  ) => {
    const map = getMapInstance();
    map.addImage(imageName, img, options);
  };

  export const loadImage = (
    imageName: string,
    callback: (
      error?: Error | undefined,
      result?: HTMLImageElement | ImageBitmap | undefined
    ) => void
  ) => {
    const map = getMapInstance();
    return map.loadImage(imageName, callback);
  };

  export const easeTo = (options: mapboxgl.EaseToOptions) => {
    const map = getMapInstance();
    map.easeTo(options);
  };

  export const querySourceFeatures = (source: string) => {
    const map = getMapInstance();
    return map.querySourceFeatures(source);
  };

  export const setFilter = (layer: string, options?: any[]) => {
    const map = getMapInstance();
    map.setFilter(layer, options);
  };

  export const addControl = (
    control: mapboxgl.Control | mapboxgl.IControl,
    position?:
      | 'top-right'
      | 'top-left'
      | 'bottom-right'
      | 'bottom-left'
      | undefined
  ) => {
    const map = getMapInstance();
    map.addControl(control, position);
  };

  export const getCanvas = () => {
    const map = getMapInstance();
    return map.getCanvas();
  };

  export const getCenter = () => {
    const map = getMapInstance();
    return map.getCenter();
  };

  export const resize = () => {
    const map = getMapInstance();
    map.resize();
  };

  export const loaded = () => {
    const map = getMapInstance();
    if (map && Object.keys(map).length > 0) {
      return map.loaded();
    }
    return null;
  };

  export const bufferPoint = (
    point: mapboxgl.Point,
    options: { percent: number } | { pixels: number }
  ): [mapboxgl.PointLike, mapboxgl.PointLike] => {
    const map = getMapInstance();
    if ('percent' in options) {
      const { width } = map.getCanvas();
      // if we want 5% of the width, we want 2.5% of the width on each side of the point
      // also need to take into account the device pixel ratio
      // only use width so the buffer is square rather than rectangular
      const adjustedWidth =
        (width / window.devicePixelRatio / 2) * (options.percent / 100);
      return [
        [point.x - adjustedWidth, point.y - adjustedWidth],
        [point.x + adjustedWidth, point.y + adjustedWidth],
      ];
    }
    return [
      [point.x - options.pixels, point.y - options.pixels],
      [point.x + options.pixels, point.y + options.pixels],
    ];
  };
}

export default MapHelpers;

import { ArrowBack, FilterAltOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { getS3DocsWithMetadata } from '../../api/documents';
import DocumentsList from '../../common-components/documents-list/documents-list';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import PanelTitle from '../../common-components/panel/panel-title';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { DocumentWithMeta } from '../../models/document';
import {
  setDocuments,
  setError,
  setFilters,
  setLoading,
} from './documents.slice';

export default function DocumentsPanel() {
  const dispatch = useAppDispatch();
  const { loading, error, filters, myDocuments } = useAppSelector(
    (state) => state.documents
  );

  const sortedDocuments = useMemo(() => {
    if (!myDocuments) return null;
    const allDocs = (myDocuments || [])
      .filter((doc) => doc.metadata.filename)
      .sort((a, b) => a.metadata.filename.localeCompare(b.metadata.filename));
    return allDocs as DocumentWithMeta[];
  }, [myDocuments]);

  const filteredDocuments = useMemo(() => {
    if (!sortedDocuments) return null;
    if (!filters.search) return sortedDocuments;
    return sortedDocuments.filter((doc) =>
      doc.metadata.filename
        .toLowerCase()
        .includes(filters.search!.toLowerCase())
    );
  }, [filters.search, sortedDocuments]);

  const fetchAllDocuments = async () => {
    dispatch(setLoading());

    try {
      const { myDocuments: myDocs } = await getS3DocsWithMetadata();

      dispatch(
        setDocuments({
          myDocuments: myDocs,
        })
      );
    } catch (e) {
      dispatch(setError());
    }
  };

  const updateSearchFilter = (value: string) => {
    dispatch(setFilters({ search: value }));
  };

  useEffect(() => {
    fetchAllDocuments();
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
      }}
    >
      <PanelTitle>
        {filters.open ? (
          <>
            <IconButton
              size="small"
              onClick={() => {
                dispatch(setFilters({ open: false }));
              }}
            >
              <ArrowBack />
            </IconButton>
            Filter
          </>
        ) : (
          'Documents'
        )}
      </PanelTitle>
      {loading && <LoadingPanel />}
      {!loading && error && (
        <ErrorPanel message="Error: Unable to fetch Documents, please try again..." />
      )}
      {!loading && !error && sortedDocuments && (
        <Box>
          {/* To Be Implemented */}
          {/* <DocumentFiltersBlock /> */}
          {!filters?.open && (
            <Box sx={{ display: 'flex', padding: '0px 20px' }}>
              <SimpleSearchBar
                handleChange={updateSearchFilter}
                filterValue={filters.search}
                placeholder="Search Documents"
              />
              <IconButton
                size="small"
                onClick={() => dispatch(setFilters({ open: true }))}
              >
                <FilterAltOutlined />
              </IconButton>
            </Box>
          )}
          <DocumentsList
            documents={filteredDocuments || []}
            refreshDocuments={fetchAllDocuments}
          />
        </Box>
      )}
    </Box>
  );
}

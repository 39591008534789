/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Collapse,
  Divider,
  FormHelperText,
  IconButton,
  Stack,
  TextareaAutosize,
  Typography,
} from '@mui/material';

import { ArrowRight } from '@mui/icons-material';
import classNames from 'classnames';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';

import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';
import {
  WEBSOCKET_CHUNK_SIZE,
  geollectDaasFormatToGeoniusColdFormat,
  getVesselHistory,
  mergeMessagesOntoVessels,
} from '../../../api';
import WebSocket, {
  WEBSOCKET_SLOW_ERROR,
  WEBSOCKET_SLOW_WARNING,
} from '../../../api/web-socket/WebSocket';
import DateRangeModal, {
  EDateRangeModalVariants,
} from '../../../common-components/date-range-modal/date-range-modal';
import ErrorPanel from '../../../common-components/error-components/error-panel/error-panel';
import MuiSelectFieldControlled from '../../../common-components/form-fields/mui-select-field-controlled';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector, useMobile } from '../../../hooks';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import MapHelpers from '../../../map/map.utils';
import {
  MaritimeAisApiLocation,
  MaritimeAisApiLocationData,
  MaritimeAisApiLocationDefined,
  MessageData,
  VesselData,
} from '../../../models/maritime-ais-api';
import store from '../../../store';
import {
  buildIdentifiers,
  getRawVesselPoints,
  renderVesselHistory,
} from '../../../utils/vessels.utils';
import HistoryPanelSampleRate from '../history-panel-sample-rate';
import {
  clearVesselHistoryData,
  prependVesselHistoryData,
  setError,
  setHistoryFormValues,
  setLoading,
  setVesselHistoryData,
} from '../history-panel.slice';
import VesselHistoryController from '../vessel-history-controller.utils';
import { HistoryFormValues } from './history-form-validators';
import './history-form.scss';

const HISTORY_WEBSOCKET_BASE =
  process.env.REACT_APP_HISTORY_WEBSOCKET_BASE || '';

export const ERROR_FETCH =
  "Sorry, we couldn't retrieve the Vessel History at the moment. Please try again later.";

const ERROR_NO_RESULTS =
  'No Vessel History found for the provided identifiers. Please double-check your input and try again.';

const identifierSchema = Yup.string()
  .required('')
  .test(
    'is-valid-identifiers',
    'Invalid identifiers format. Please enter 7 or 9 digit numbers separated by spaces.',
    (value) => {
      const identifiers = value.split(/\s+/);

      // Check for duplicates
      const hasDuplicates = identifiers.some(
        (id, index) => identifiers.indexOf(id) !== index
      );

      // Check for valid format
      const isValidFormat = identifiers.every((id) =>
        id.match(/^\d{7}$|^\d{9}$/)
      );

      return !hasDuplicates && isValidFormat;
    }
  );

export interface WebsocketRepsonse {
  data?: MessageData[];
  metadata?: { endOfData: boolean };
}

const historyValidationSchema = Yup.object().shape({
  fromDate: Yup.date().required('Required'),
  toDate: Yup.date().required('Required'),
  sampleRate: Yup.string().required('Required'),
  identifiers: identifierSchema,
});

function HistoryPanelForm() {
  const dispatch = useAppDispatch();
  const { loading, error, formValues } = useAppSelector(
    (state) => state.historyPanel
  );
  const userPreferences = useAppSelector(
    (state) => state.userPreferences.userPreferences
  );
  const hasZoomedToPointsRef = useRef(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState<boolean>(false);
  const [showDateRangeLoading, setShowDateRangeLoading] =
    useState<boolean>(false);

  const vesselData = useRef<{
    [vesselId: string]: VesselData;
  }>({});

  // rendering too frequently is slow, so we'll cache up a bunch of messages before rendering
  // the number of messages we cache between renders will increase as we step through more pages of data
  const messagesCache = useRef<MessageData[]>([]);
  const messagesReceived = useRef<number>(0);

  // keep hold of a time reference so we can indicate to a user that a websocket seems to be taking a long time
  const warnTimeout = useRef<NodeJS.Timeout | null>(null);
  const errorTimeout = useRef<NodeJS.Timeout | null>(null);

  // on unmount set error to false
  // In the future this should be changed to use a querying
  // framework like react-query / SWR
  useEffect(
    () => () => {
      dispatch(setError(false));
    },
    []
  );

  const [showDateRangeError, setShowDateRangeError] = useState<boolean>(false);
  const [showDateRangeSuccess, setShowDateRangeSuccess] =
    useState<boolean>(false);
  const [noResultsError, setNoResultsError] = useState<boolean>(false);
  const [websocketId, setWebsocketId] = useState<string | undefined>();
  const [websocketLoadingSlowly, setWebsocketLoadingSlowly] =
    useState<boolean>(false);

  const restartCountdowns = () => {
    if (warnTimeout.current) {
      clearTimeout(warnTimeout.current);
    }
    if (errorTimeout.current) {
      clearTimeout(errorTimeout.current);
    }
    warnTimeout.current = setTimeout(() => {
      setWebsocketLoadingSlowly(true);
    }, WEBSOCKET_SLOW_WARNING);
    errorTimeout.current = setTimeout(() => {
      setWebsocketLoadingSlowly(false);
      dispatch(setError(true));
      setWebsocketId(undefined); // websocket seems to have hung, close it
    }, WEBSOCKET_SLOW_ERROR);
  };

  const clearCountdowns = () => {
    if (warnTimeout.current) {
      clearTimeout(warnTimeout.current);
    }
    if (errorTimeout.current) {
      clearTimeout(errorTimeout.current);
    }
    warnTimeout.current = null;
    errorTimeout.current = null;
    setWebsocketLoadingSlowly(false);
  };

  const isMobile = useMobile();

  const toggleShowDateRangeModal = () => {
    setShowDateRangeModal(!showDateRangeModal);
    if (!showDateRangeModal) {
      setShowDateRangeLoading(false);
      setShowDateRangeError(false);
      setShowDateRangeSuccess(false);
    }
  };

  const vesselHistoryWebsocketCallback = (
    responseMessage: WebsocketRepsonse
  ) => {
    if (responseMessage.metadata?.endOfData || !responseMessage.data) {
      // websocket finished, disconnect
      setWebsocketId(undefined);
      // finished, no timeout for long-running needed
      clearCountdowns();
      messagesReceived.current = 0;

      if (MapHelpers.isInGlobeView()) {
        // user hasn't panned or zoomed the map since we started loading points, so we'll zoom to the points now
        VesselHistoryController.onVesselHistoryDraw();
        hasZoomedToPointsRef.current = true;
      }

      dispatch(setLoading(false));
      store.dispatch(setSelectedDossier(EDossiers.HISTORY));
      return;
    }
    // just recieved websocket data, reset timeout
    restartCountdowns();

    // mesages come through WEBSOCKET_CHUNK_SIZE at a time, but this can be too few to to be worth the effort of rendering
    // so we'll cache them up until we have enough to make it worth rendering.
    messagesCache.current.push(...responseMessage.data);
    messagesReceived.current += 1;

    // as we step through more pages of data, we should group them into larger chunks to save rendering overhead
    // this keeps the time-to-render low for the initial pages of data, but reduces the number of times we need to render later on, which is much slower because there is more data.
    const chunkSize = Math.min(
      4000, // anymore than 4000 messages chunked and we start seeing memory issues
      WEBSOCKET_CHUNK_SIZE * Math.ceil(messagesReceived.current / 10)
    );

    // if not divisble by WEBSOCKET_CHUNK_SIZE, its probably the last page of data
    if (
      messagesCache.current.length % WEBSOCKET_CHUNK_SIZE === 0 &&
      messagesCache.current.length < chunkSize
    ) {
      return;
    }

    const messagesSortedOntoVessels = mergeMessagesOntoVessels(
      vesselData.current,
      messagesCache.current
    );

    messagesCache.current = [];

    const data = Object.values(messagesSortedOntoVessels).map((vals) =>
      geollectDaasFormatToGeoniusColdFormat(
        vals as MaritimeAisApiLocationDefined
      )
    );
    dispatch(
      prependVesselHistoryData({
        data,
        formValues,
      })
    );
    const rawVesselPoints = getRawVesselPoints(data);
    renderVesselHistory(rawVesselPoints, userPreferences, {
      prepend: true,
      shouldDisplayOtherVessels: false,
    });
  };

  const onWebsocketEstablished = () => {
    dispatch(setLoading('Loading points...'));
  };

  const onWebsocketClosed = () => {
    dispatch(setLoading(false));
    setWebsocketId(undefined);
    // finished, no timeout for long-running needed
    clearCountdowns();
    messagesReceived.current = 0;
  };

  // websocket error events are very opaque, not much point in printing anything
  const onWebsocketError = () => {
    dispatch(setLoading(false));
    setWebsocketId(undefined);
    dispatch(setError(true));
    // finished, no timeout for long-running needed
    clearCountdowns();
    messagesReceived.current = 0;
  };

  // this callback is called for each page of data we receive as we recursively paginate through the API
  const vesselHistoryPageProcessorCallback = (
    responsePage: MaritimeAisApiLocationData
  ) => {
    const data = (
      Object.values(responsePage.data).filter(
        (value) => (value as MaritimeAisApiLocation).vessel
      ) as MaritimeAisApiLocationDefined[]
    ).map((vesselWithLocationMessages) =>
      geollectDaasFormatToGeoniusColdFormat(vesselWithLocationMessages)
    );

    const rawVesselPoints = getRawVesselPoints(data);
    renderVesselHistory(rawVesselPoints, userPreferences);

    if (!hasZoomedToPointsRef.current) {
      VesselHistoryController.onVesselHistoryDraw();
      hasZoomedToPointsRef.current = true;
    }
  };

  const onSubmit = async (values: HistoryFormValues) => {
    if (!HISTORY_WEBSOCKET_BASE) {
      setError(true);
      setNoResultsError(false);
      return;
    }

    setWebsocketLoadingSlowly(false);
    const wsId = `history-websocket-${uuid()}`;
    setWebsocketId(wsId);
    dispatch(setLoading('Initialising history request...'));
    // need to watch for if the websocket takes a long time to load points
    restartCountdowns();
    // move to a globe view so that the user can see the points being loaded
    MapHelpers.returnToGlobeView();

    dispatch(clearVesselHistoryData());
    VesselHistoryController.clearAllHistoryLayers();
    hasZoomedToPointsRef.current = false;

    const whiteSpaceRegex = /\s|\r|\n/g;
    const formattedIdentifiers = buildIdentifiers(
      values.identifiers.split(whiteSpaceRegex).filter((str) => str !== '')
    );

    const queryParams = {
      'start-date': values.fromDate,
      'end-date': values.toDate,
      'sample-rate': values.sampleRate,
      mmsis: formattedIdentifiers.mmsis,
      imos: formattedIdentifiers.imos,
      highTierSearchMode: true,
    };

    try {
      const vesselHistoryPromise = getVesselHistory(
        queryParams,
        vesselHistoryPageProcessorCallback,
        wsId
      );

      const result = await vesselHistoryPromise;

      if (Array.isArray(result)) {
        // running in websocket mode
        // store the vessel data in the shape the rest of the app expects
        vesselData.current = result.reduce((acc, curr) => {
          acc[curr.vesselId] = curr;

          return acc;
        }, {} as { [vesselId: string]: VesselData });
        return;
      }

      const { data } = result;

      dispatch(
        setVesselHistoryData({
          data,
          formValues: values,
        })
      );

      if (!data.some((vessel) => vessel.messages.length > 0)) {
        setNoResultsError(true);
        setError(false);
        return;
      }
      setNoResultsError(false);

      store.dispatch(setSelectedDossier(EDossiers.HISTORY));
    } catch (e) {
      dispatch(setError(true));
      if (wsId) {
        // start-websocket failed, close the websocket
        setWebsocketId(undefined);
        clearCountdowns();
      }
    } finally {
      if (!wsId) {
        // loading doesn't finish in websocket mode until the websocket is closed
        dispatch(setLoading(false));
      }
    }
  };

  const onCalendarSubmit = (
    start: Date | null,
    end: Date | null,
    formik: ReturnType<typeof useFormik<HistoryFormValues>>
  ) => {
    dispatch(
      setHistoryFormValues({
        fromDate: start?.toISOString().split('T')[0],
        toDate: end?.toISOString().split('T')[0],
      })
    );
    formik.setFieldValue('fromDate', start?.toISOString().split('T')[0]);
    formik.setFieldValue('toDate', end?.toISOString().split('T')[0]);
  };

  const formik = useFormik({
    initialValues: {
      fromDate: formValues.fromDate,
      toDate: formValues.toDate,
      sampleRate: formValues.sampleRate,
      identifiers: formValues.identifiers,
    },
    validationSchema: historyValidationSchema,
    onSubmit,
    enableReinitialize: true,
    validateOnMount: true,
    validateOnChange: true,
  });

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          className={classNames({
            mobile: isMobile,
          })}
          onSubmit={formik.handleSubmit}
        >
          <Stack
            sx={{
              width: '90%',
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
            }}
            spacing={2}
            direction="column"
          >
            <Box>
              <Typography align="left" sx={{ marginLeft: '0.5rem' }}>
                Filters
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => toggleShowDateRangeModal()}
                sx={{
                  borderBottom: '1px solid',
                  borderBottomColor: 'background.paper',
                  textAlign: 'left',
                  display: 'flex',
                  justifyContent: 'space-between',
                  minHeight: '40px',
                  padding: '6px 24px 6px 16px',
                  fontSize: '16px',
                  fontWeight: '400',
                  color: 'text.primary',
                  borderRadius: '0',
                  width: '100%',
                  marginBottom: '1.0rem',
                  marginTop: '0.5rem',
                }}
              >
                {formik.values.fromDate} - {formik.values.toDate}
                <ArrowRight />
              </IconButton>
              <MuiSelectFieldControlled
                name="sampleRate"
                placeholder="Select sample rate"
                options={[
                  {
                    name: 'Every 10 minutes',
                    value: HistoryPanelSampleRate.TEN_MINUTES,
                  },
                  { name: 'Hourly', value: HistoryPanelSampleRate.ONE_HOUR },
                  { name: 'Daily', value: HistoryPanelSampleRate.ONE_DAY },
                ]}
                onChange={(e) => {
                  dispatch(
                    setHistoryFormValues({
                      sampleRate: e.target.value,
                    })
                  );
                }}
                errors={formik.errors}
                touched={formik.touched}
                label="Sample Rate"
                ariaLabel="sample-rate-input"
                value={formik.values.sampleRate}
              />
            </Box>
            <Divider />
            <Stack direction="column" spacing={2}>
              <Stack direction="column" spacing={1} sx={{ maxHeight: '35rem' }}>
                <Typography align="left">Vessel Identifiers</Typography>
                <TextareaAutosize
                  aria-label="Vessel Identifiers"
                  minRows={5}
                  placeholder="Enter white-space separated IMOs & MMSIs here. IMO is the preferred search criteria."
                  id="identifiers"
                  name="identifiers"
                  style={{ maxWidth: '100%', minWidth: '100%' }}
                  onChange={(e) => {
                    dispatch(
                      setHistoryFormValues({
                        identifiers: e.target.value,
                      })
                    );
                  }}
                  defaultValue={formik.values.identifiers}
                />
                {formik.errors.identifiers && (
                  <FormHelperText error>
                    {formik.errors.identifiers}
                  </FormHelperText>
                )}

                {loading && (
                  <LoadingPanel
                    loadingMessage={
                      typeof loading === 'string' ? loading : undefined
                    }
                  />
                )}
                {websocketLoadingSlowly && (
                  <ErrorPanel
                    level="warning"
                    message="Websocket connection is taking a long time to load points. Please try again later."
                  />
                )}
                {error && !loading && <ErrorPanel message={ERROR_FETCH} />}
                {noResultsError && !loading && (
                  <ErrorPanel message={ERROR_NO_RESULTS} />
                )}
              </Stack>

              <Button
                data-testid="history-panel-get-button"
                type="submit"
                disabled={!formik.isValid || !!loading}
                variant="contained"
              >
                Get History
              </Button>
              {/* This button is only visible when a websocket style connection is active */}
              <Collapse in={!!websocketId}>
                <Button
                  data-testid="history-panel-cancel-button"
                  type="button"
                  variant="contained"
                  onClick={() => {
                    // setting the websocketId to undefined will cause <Websocket /> to unmount, which will close the websocket
                    setWebsocketId(undefined);
                    dispatch(setLoading(false));
                  }}
                >
                  Stop Loading points
                </Button>
              </Collapse>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
      <DateRangeModal
        title="Select a date range"
        loading={showDateRangeLoading}
        success={showDateRangeSuccess}
        error={showDateRangeError}
        visible={showDateRangeModal}
        onClose={() => {
          toggleShowDateRangeModal();
        }}
        onSelect={(modalResult) => {
          onCalendarSubmit(
            modalResult.dateFrom.toDate(),
            modalResult.dateTo.toDate(),
            formik
          );
          toggleShowDateRangeModal();
        }}
        initialStart={formValues.fromDate}
        initialEnd={formValues.toDate}
        variant={EDateRangeModalVariants.FULL_RANGE}
      />
      {websocketId && HISTORY_WEBSOCKET_BASE && (
        <WebSocket
          url={
            new URL(
              `${HISTORY_WEBSOCKET_BASE}?clientId=${websocketId}&dataSource=aisDatabase&serviceTier=high`
            )
          }
          signUrl
          onMessageReceivedJson={(result) =>
            vesselHistoryWebsocketCallback(result as WebsocketRepsonse)
          }
          onConnectionOpened={onWebsocketEstablished}
          onConnectionClosed={onWebsocketClosed}
          onConnectionError={onWebsocketError}
        />
      )}
    </>
  );
}

export default HistoryPanelForm;

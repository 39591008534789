import EastIcon from '@mui/icons-material/East';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { sanitiseBlock } from '../../../utils/text-formatting.utils';
import ThreatAssessmentChip from '../threat-assessment-chip/threat-assessment-chip';
import { threatAssessmentTitle } from '../threat-assessment-helpers';
import ThreatAssessmentRevisionLabel from '../threat-assessment-revision-label';
import { Threat, ThreatAssessment } from '../threat-assessment.model';

interface ThreatAssessmentPanelContentsProps
  extends Pick<
    ThreatAssessment,
    'entity' | 'country' | 'summary' | 'threats' | 'underRevision'
  > {}

function ThreatAssessmentPanelContents(
  props: ThreatAssessmentPanelContentsProps
) {
  const { entity, country, summary, threats, underRevision } = props;

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const canExpand = entity.type === 'area';

  return (
    <Box
      sx={{
        maxHeight: '73vh',
        overflow: 'auto',
        scrollbarWidth: 'thin',
      }}
    >
      <Box>
        <Typography
          sx={{
            padding: '0px 16px',
            textAlign: 'left',
          }}
          variant="subtitle2"
        >
          {threatAssessmentTitle(entity, country)}
        </Typography>
        <ThreatAssessmentRevisionLabel
          underRevision={underRevision}
          sx={{
            padding: '0px 16px',
          }}
        />
        <Accordion
          defaultExpanded
          sx={{
            backgroundColor: 'primary.main',
            boxShadow: 'none',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              display: 'grid',
              gridTemplateColumns: '5rem auto',
              width: '8rem',
              justifyContent: 'left',
              '&.Mui-expanded': {
                minHeight: 0,
              },
              '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: '12px 0',
              },
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
          >
            <Typography
              noWrap
              variant="subtitle2"
              align="left"
              sx={{
                fontWeight: 700,
                mb: '0.5rem',
                marginBottom: '0',
              }}
            >
              Summary
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxHeight: '25vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              mr: '.5rem',
            }}
          >
            <Typography component="span" variant="body2" align="left">
              <div
                // HTML has been cleaned
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sanitiseBlock(summary, [
                    'a',
                    'b',
                    'i',
                    'p',
                    'em',
                    'strong',
                  ]),
                }}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box>
        {threats.map((threat: Threat) => (
          <Accordion
            key={threat.type.id}
            sx={{
              backgroundColor: 'primary.main',
              boxShadow: 'none',
            }}
            expanded={canExpand && expanded === threat.type.title}
            onChange={canExpand ? handleChange(threat.type.title) : undefined}
          >
            <AccordionSummary
              expandIcon={canExpand ? <ExpandMoreIcon /> : null}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                '&.Mui-expanded': {
                  backgroundColor: 'primary.light',
                },
                '&:hover': {
                  backgroundColor: canExpand ? 'primary.light' : undefined,
                  cursor: canExpand ? 'pointer' : 'default !important',
                },
                maxHeight: '10px',
                marginTop: '0',
                marginBottom: '0',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    align="left"
                    sx={{ fontWeight: 700, mb: '0.5rem', marginBottom: '0' }}
                  >
                    {threat.type.title}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <ThreatAssessmentChip threatLevel={threat.level} />
                </Grid>
                <Grid item xs={2}>
                  <div>
                    {threat.trend === 1 && <NorthEastIcon />}
                    {threat.trend === -1 && <SouthEastIcon />}
                    {threat.trend === 0 && <EastIcon />}
                  </div>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                overflowY: 'auto',
                scrollbarWidth: 'thin',
              }}
            >
              <Typography
                paragraph
                variant="subtitle2"
                align="left"
                sx={{ fontWeight: 700 }}
              >
                Summary
              </Typography>
              <Typography component="span" variant="body2" align="left">
                <div
                  // HTML has been cleaned
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: sanitiseBlock(threat.description, [
                      'a',
                      'b',
                      'i',
                      'p',
                      'em',
                      'strong',
                    ]),
                  }}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}

export default ThreatAssessmentPanelContents;

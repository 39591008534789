/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import ListPicker from '../../../../common-components/list-picker/list-picker';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  calcDisplayedText,
  incidentTargetTypesCache,
} from '../../../../utils/incidents-helpers';
import { setIncidentFilters } from '../../incident-panel.slice';

function TargetsFilter() {
  const { incidentTargetTypes } = useAppSelector((store) => store.incidents);
  const { filters } = useAppSelector((store) => store.incidentsPanel);
  const dispatch = useAppDispatch();

  const [selectedTargets, setSelectedTargets] = useState<number[]>([]);

  useEffect(() => {
    setSelectedTargets(filters.targets);
  }, [filters.targets]);

  const onSubmit = () => {
    dispatch(
      setIncidentFilters({
        targets: selectedTargets,
      })
    );
  };

  return (
    <ListPicker
      onSubmit={onSubmit}
      items={incidentTargetTypes || []}
      selectedItems={selectedTargets}
      setSelectedItems={setSelectedTargets}
      buttonText={calcDisplayedText(
        filters.targets,
        'Target Types',
        incidentTargetTypesCache
      )}
    />
  );
}
export default TargetsFilter;

import dayjs from 'dayjs';
import { MapLayerMouseEvent, Popup } from 'mapbox-gl';
import PopupHelpers from '../../../popup/popup.utils';
import { Vessel } from '../../../models/vessel.model';

const popup = new Popup({
  closeButton: false,
  closeOnMove: true,
});

export function hideVesselInfoPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = '';
  popup.remove();
}

export function showVesselInfoPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  if (e.features?.length) {
    const feature = e.features[0];
    const properties = feature.properties as Vessel;

    const { name, callsign, shiptype, imo, mmsi, speed, timestamp } =
      properties;

    const popupInfo = [
      {
        label: 'Name',
        value: name,
      },
      {
        label: 'IMO',
        value: imo,
      },
      {
        label: 'MMSI',
        value: mmsi,
      },
      {
        label: 'Speed',
        value: `${speed} knots`,
      },
      {
        label: 'Call Sign',
        value: callsign,
      },
      {
        label: 'Ship Type',
        value: shiptype
          ? shiptype
              .toLowerCase()
              .replaceAll('_', ' ')
              .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
          : 'Unknown',
      },
      {
        label: 'Timestamp',
        value: dayjs(timestamp).format('YYYY-MM-DDTHH:mm:ss'),
      },
    ];

    PopupHelpers.createInfoCard(popup, popupInfo);

    // @ts-ignore
    // can't figure out the typing needed for coordinates
    popup.setLngLat(feature.geometry.coordinates);
    popup.addTo(e.target);
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as turf from '@turf/turf';
import { useState } from 'react';
import { Route } from '../../../../models/routes.model';
import GeoHelper from '../../../../utils/geo-helpers.utils';
import { neatDisplayNumber } from '../../../../utils/text-formatting.utils';
import NumberInputBasic from './number-input';

export interface RouteCalculationsProps {
  selectedRoute: Route;
}

function RouteCalculations(props: RouteCalculationsProps) {
  const { selectedRoute } = props;
  const [averageSpeed, setAverageSpeed] = useState<number | undefined>(
    undefined
  );
  const [routeDuration, setRouteDuration] = useState<number | undefined>(
    undefined
  );
  const [waitTimesMap, setWaitTimesMap] = useState(new Map<string, number>());

  const getTotalWaitTimes = () => {
    let totalWaitTime = 0;
    if (waitTimesMap.size === 0) {
      return totalWaitTime;
    }
    waitTimesMap.forEach((value) => {
      totalWaitTime += value;
    });
    return totalWaitTime;
  };
  const routeDistance = turf.length(
    turf.lineString(selectedRoute.route_data.geometry.coordinates),
    { units: 'nauticalmiles' }
  );

  const handleWaitTimeChange = (
    e: any,
    metaData?: {
      [key: string]: any;
    }
  ) => {
    const waitTime = e.target.value;
    setWaitTimesMap((prev) => {
      const newMap = new Map(prev).set(
        `point-${metaData!.mapKey}`,
        Number(waitTime)
      );
      const totalWaitTimes = Array.from(newMap.values()).reduce(
        (a, b) => a + b,
        0
      );

      const duration = Number(
        routeDistance / Number(averageSpeed) + totalWaitTimes
      );

      setRouteDuration(Number(duration));
      return newMap;
    });
  };

  const handleAvgSpeedChange = (e: any) => {
    const speed = e.target.value;
    setAverageSpeed(speed);
    const duration = Number(
      routeDistance / Number(speed) + getTotalWaitTimes()
    );
    setRouteDuration(duration);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
          padding: '1rem',
        }}
      >
        <Typography variant="subtitle2">Average speed (knots):</Typography>
        <NumberInputBasic
          testId="average-speed-input"
          defaultValue={averageSpeed}
          placeHolder="Avg speed"
          onChange={handleAvgSpeedChange}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1rem',
        }}
      >
        <Typography variant="subtitle2">Route duration:</Typography>

        <Typography variant="subtitle2">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography data-testid="duration-hours">
              {neatDisplayNumber(routeDuration)} hours
            </Typography>
            <Typography data-testid="duration-days">
              {neatDisplayNumber(routeDuration! / 24)} days
            </Typography>
          </Box>
        </Typography>
      </Box>
      <Divider sx={{ color: 'white' }} />
      <Grid
        container
        columns={1}
        sx={{
          padding: '1rem',
          display: 'flex',
        }}
      >
        <Grid
          item
          xs={1}
          sx={{
            marginBottom: '1rem',
          }}
        >
          <Grid container columns={5}>
            <Grid item xs={1}>
              <Typography>Point</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Distance to next point</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Wait time (hours)</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid key="points-wrapper" item xs={1}>
          {selectedRoute.route_data.geometry.coordinates.map((item, index) => (
            <Grid
              key={`point-${index}`}
              container
              columns={5}
              sx={{ height: '4rem' }}
            >
              <Grid
                item
                key={`point-${index}-label`}
                xs={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="subtitle2">{index + 1}</Typography>
              </Grid>
              <Grid
                item
                key={`point-${index}-distance`}
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="subtitle2">
                  {index !==
                  selectedRoute.route_data.geometry.coordinates.length - 1
                    ? `${neatDisplayNumber(
                        GeoHelper.calculateDistanceBetweenPoints(
                          [item[1], item[0]],
                          [
                            selectedRoute.route_data.geometry.coordinates[
                              index + 1
                            ][1],
                            selectedRoute.route_data.geometry.coordinates[
                              index + 1
                            ][0],
                          ]
                        )
                      )} NM`
                    : '-'}
                </Typography>
              </Grid>
              <Grid
                item
                key={`point-${index}-waitTime`}
                xs={2}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <NumberInputBasic
                  testId={`point-${index}-waitTime`}
                  defaultValue={waitTimesMap.get(`point-${index}`)}
                  placeHolder="hours..."
                  metaData={{ mapKey: `point-${index}` }}
                  onChange={handleWaitTimeChange}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default RouteCalculations;

/* eslint-disable import/prefer-default-export */
import { wrapRequest } from '../base';

export enum ENewsItemType {
  INCIDENT = 'incident',
  THREAT_ASSESSMENT = 'threatAssessment',
}

export interface NewsItem {
  id: string;
  title: string;
  date: string;
  type: ENewsItemType;
}

export const getRiNews = (): Promise<NewsItem[]> =>
  wrapRequest('get', 'geonius', `/risk-intelligence/news`);

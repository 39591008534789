/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Vessel } from '../../models/vessel.model';

type NormalisedSelectedVessels = {
  byId: Record<string, Vessel>;
  allIds: Vessel['vessel_id'][];
};

type VesselDossierStateType = {
  selectedVessels: null | NormalisedSelectedVessels;
  selectedVesselIds: null | string[];
};

export const INITAL_VESSEL_DOSSIER_STATE: VesselDossierStateType = {
  selectedVessels: null,
  selectedVesselIds: null,
};

const VesselDossierSlice = createSlice({
  name: 'vessel-dossier',
  initialState: INITAL_VESSEL_DOSSIER_STATE,
  reducers: {
    setSelectedVesselIds: (state, action: PayloadAction<string[] | null>) => {
      state.selectedVessels = null;
      state.selectedVesselIds = action.payload;
    },
    toggleSelectedVesselId: (state, action: PayloadAction<string>) => {
      if (state.selectedVesselIds) {
        const isNewId = !state.selectedVesselIds.includes(action.payload);
        if (!isNewId) {
          state.selectedVesselIds = state.selectedVesselIds.filter(
            (id) => id !== action.payload
          );
        } else {
          state.selectedVesselIds.push(action.payload);
        }
      } else {
        state.selectedVesselIds = [action.payload];
      }
    },
    setSelectedVessels: (state, action: PayloadAction<Vessel[] | null>) => {
      state.selectedVesselIds = null;
      if (action.payload) {
        state.selectedVessels = {
          byId: action.payload.reduce((acc, vessel) => {
            acc[vessel.vessel_id] = vessel;
            return acc;
          }, {} as Record<string, Vessel>),
          allIds: action.payload.map((vessel) => vessel.vessel_id),
        };
      } else {
        state.selectedVessels = {
          byId: {},
          allIds: [],
        };
      }
    },
    toggleSelectedVessel: (state, action: PayloadAction<Vessel>) => {
      if (state.selectedVessels) {
        const isNewVessel = !state.selectedVessels.allIds.includes(
          action.payload.vessel_id
        );
        if (!isNewVessel) {
          state.selectedVessels.allIds = state.selectedVessels.allIds.filter(
            (id) => id !== action.payload.vessel_id
          );
          delete state.selectedVessels.byId[action.payload.vessel_id];
        } else {
          state.selectedVessels.allIds.push(action.payload.vessel_id);
          state.selectedVessels.byId[action.payload.vessel_id] = action.payload;
        }
      } else {
        state.selectedVessels = {
          byId: {
            [action.payload.vessel_id]: action.payload,
          },
          allIds: [action.payload.vessel_id],
        };
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedVessels,
  setSelectedVesselIds,
  toggleSelectedVessel,
  toggleSelectedVesselId,
} = VesselDossierSlice.actions;

export default VesselDossierSlice.reducer;

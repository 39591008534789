/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Storage } from '@aws-amplify/storage';
import { Box, Container, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { putDocumentMetadata } from '../../api';
import { StorageLevel } from '../../models/document';
import SimpleDialog from '../dialog/SimpleDialog';
import ErrorPanel from '../error-components/error-panel/error-panel';
import LoadingButton from '../loading-button/loading-button';
import UploadEntityDocumentsForm from './upload-entity-documents-form';
import './upload-entity-documents-modal.scss';
import {
  UploadEntityDocumentsFormValues,
  validateUploadEntityDocumentsForm,
} from './upload-entity-documents-validators';
import getStorageFileDirectory from './upload-entity-documents.utils';

type UploadEntityDocumentsFormProps = {
  entityName: string | undefined | null;
  entityId: number | string | undefined | null;
  entityType: string | null;
  onClose: () => void;
};

type ModalProps = {
  visible: boolean;
  onClose: () => void;
} & UploadEntityDocumentsFormProps;

function RenderUploadEntityDocumentsModal({
  entityName,
  entityId,
  entityType,
  onClose,
}: UploadEntityDocumentsFormProps) {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (values: UploadEntityDocumentsFormValues) => {
    try {
      setSuccess(false);
      setError(false);
      setSending(true);
      const storageLocation = getStorageFileDirectory(
        StorageLevel.PRIVATE,
        String(entityId)
      );
      const getFileStorageUploadPromises = () =>
        values.fileAttachments.map((file) =>
          // Note: For private level access, Amplify automatically
          // prepends the user identity id to the key
          Storage.put(`${storageLocation}/${file.name}`, file, {
            level: StorageLevel.PRIVATE,
          })
        );
      const getFileMetadataUploadPromises = () =>
        values.fileAttachments.map((file) =>
          putDocumentMetadata({
            file,
            entityId: String(entityId),
            entityType: String(entityType),
            description: values.description,
            name: values.name,
          })
        );

      await Promise.all(getFileStorageUploadPromises());
      await Promise.all(getFileMetadataUploadPromises());

      setSuccess(true);
      onClose();
    } catch (_error) {
      setSuccess(false);
      setError(true);
    } finally {
      setSending(false);
    }
  };

  const defaultFeedbackFormValues: UploadEntityDocumentsFormValues = {
    fileAttachments: [],
    name: '',
    description: '',
  };

  return (
    <Box data-testid="upload-entity-documents-modal-container">
      {!sending && !success && error && (
        <ErrorPanel message="Error Uploading Documents. Please Try Again" />
      )}

      <Formik
        initialValues={{
          ...defaultFeedbackFormValues,
        }}
        onSubmit={onSubmit}
        validate={validateUploadEntityDocumentsForm}
        enableReinitialize
      >
        {({ values, errors }) => (
          <Form>
            <Container disableGutters>
              <UploadEntityDocumentsForm entityName={entityName} />

              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <LoadingButton
                  data-testid="upload-entity-documents-submit-button"
                  type="submit"
                  loading={sending}
                  success={success}
                  variant="contained"
                  disabled={
                    values.fileAttachments.length < 1 ||
                    Object.values(errors).length > 0 ||
                    sending
                  }
                >
                  Upload
                </LoadingButton>
              </Stack>
            </Container>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function UploadEntityDocumentsModal(props: ModalProps) {
  const { visible, onClose, entityName, entityId, entityType } = props;

  return (
    <SimpleDialog onClose={onClose} title="Upload Document" open={visible}>
      <RenderUploadEntityDocumentsModal
        entityName={entityName}
        entityId={entityId}
        entityType={entityType}
        onClose={onClose}
      />
    </SimpleDialog>
  );
}

export default UploadEntityDocumentsModal;

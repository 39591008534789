/* eslint-disable jsx-a11y/anchor-is-valid */
import WestIcon from '@mui/icons-material/West';
import { Breadcrumbs, IconButton, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Port } from '../../maritime-menu-options/world-ports-panel/world-ports.model';
import {
  GenericHistoryItem,
  IndividualHistoryItem,
  isNoPropsItem,
} from '../../models/history';
import { ExpandedId } from '../../models/risk_intelligence.model';
import { addHistoryItem } from '../../nav-history.slice';
import BreadCrumbUtils from './breadcrumb.utils';

import { Route } from '../../models/routes.model';
import './breadcrumb.scss';

interface BackButtonProps {
  index: number;
  historyItem: GenericHistoryItem;
}

function BackButton({ index, historyItem }: BackButtonProps) {
  const navigate = useNavigate();
  if (isNoPropsItem(historyItem)) {
    if (historyItem.type === 'map') {
      return (
        <Link
          data-testid="map-breadcrumb"
          underline="hover"
          color="inherit"
          onClick={() => BreadCrumbUtils.backToMap(index, navigate)}
          sx={{ cursor: 'pointer' }}
          fontSize="0.9rem"
        >
          Map
        </Link>
      );
    }
    if (historyItem.type === 'portList') {
      return (
        <Link
          underline="hover"
          color="inherit"
          onClick={() => BreadCrumbUtils.backToPorts(index, navigate)}
          sx={{ cursor: 'pointer' }}
          fontSize="0.9rem"
        >
          All Ports
        </Link>
      );
    }

    if (historyItem.type === 'industryNewsList') {
      return (
        <Link
          underline="hover"
          color="inherit"
          onClick={() => BreadCrumbUtils.backToIndustryNews(index, navigate)}
          sx={{ cursor: 'pointer' }}
          fontSize="0.9rem"
        >
          All News
        </Link>
      );
    }

    if (historyItem.type === 'RIareaslist') {
      return (
        <Link
          underline="hover"
          color="inherit"
          onClick={() => BreadCrumbUtils.backToRiMaritimeAreas(index, navigate)}
          sx={{ cursor: 'pointer' }}
          fontSize="0.9rem"
        >
          All Risk Intelligence Maritime Areas
        </Link>
      );
    }
    if (historyItem.type === 'search') {
      return (
        <Link
          underline="hover"
          color="inherit"
          onClick={() => BreadCrumbUtils.backToSearch(index, navigate)}
          sx={{ cursor: 'pointer' }}
          fontSize="0.9rem"
        >
          Search
        </Link>
      );
    }
    if (historyItem.type === 'incidentList') {
      return (
        <Link
          underline="hover"
          color="inherit"
          onClick={() => BreadCrumbUtils.backToIncidents(index, navigate)}
          sx={{ cursor: 'pointer' }}
          fontSize="0.9rem"
        >
          All Incidents
        </Link>
      );
    }
    if (historyItem.type === 'routeList') {
      return (
        <Link
          underline="hover"
          color="inherit"
          onClick={() => BreadCrumbUtils.backToRoutes(index, navigate)}
          sx={{ cursor: 'pointer' }}
          fontSize="0.9rem"
        >
          All Routes
        </Link>
      );
    }
  }

  let itemName;
  switch (historyItem.type) {
    case 'port':
      itemName = (historyItem.properties as Port).NAME;
      break;
    case 'route':
      itemName = (historyItem.properties as Route).route_name;
      break;
    case 'country':
    case 'RiArea':
      itemName = (historyItem.properties as ExpandedId).title;
      break;
    case 'incident':
      itemName = `${(historyItem.properties as ExpandedId).title.slice(
        0,
        16
      )}...`;
      break;
    default:
  }

  return itemName ? (
    <Link
      sx={{ cursor: 'pointer' }}
      underline="hover"
      color="inherit"
      fontSize="0.9rem"
      onClick={() =>
        BreadCrumbUtils.backToExpandedItem(
          index,
          historyItem as IndividualHistoryItem,
          navigate
        )
      }
    >
      {itemName}
    </Link>
  ) : null;
}

interface BreadcrumbProps {
  defaultHistory?: GenericHistoryItem;
  currentPanel?: string | undefined;
}

export default function Breadcrumb({
  defaultHistory,
  currentPanel,
}: BreadcrumbProps) {
  const { history } = useAppSelector((store) => store.navHistory);
  const dispatch = useAppDispatch();

  if (history.length < 1) {
    // if the history is empty, add the default history item (if it exists)
    if (defaultHistory) {
      dispatch(addHistoryItem(defaultHistory));
    }
    return <Breadcrumbs />;
  }

  // filter history array to remove duplicates and remove currentPanel from list
  const filtered = history.filter(
    (item, index, self) =>
      index === self.findIndex((i) => i.type === item.type) &&
      item.type !== currentPanel
  );

  const items = filtered.map(
    (historyItem: GenericHistoryItem, index: number) => (
      <BackButton
        // eslint-disable-next-line react/no-array-index-key
        key={`${historyItem.type}-${index}`}
        index={index}
        historyItem={historyItem}
      />
    )
  );

  return (
    <Breadcrumbs
      maxItems={2}
      separator="›"
      itemsBeforeCollapse={0}
      itemsAfterCollapse={2}
      aria-label="breadcrumb"
      sx={{ marginLeft: items.length < 3 ? '1.255em' : '1.0425em' }}
    >
      {items}
    </Breadcrumbs>
  );
}

interface BackOneButtonProps {
  onClick?: () => void;
}

export function BackOneButton({
  onClick: additionalAction,
}: BackOneButtonProps) {
  const navigate = useNavigate();
  const { history } = useAppSelector((store) => store.navHistory);
  const lastItem = history[history.length - 1];

  const backOneFunctionToUse = (i: number, item: IndividualHistoryItem) => {
    if (isNoPropsItem(lastItem)) {
      switch (lastItem.type) {
        case 'portList': {
          return BreadCrumbUtils.backToPorts(i, navigate);
        }
        case 'industryNewsList': {
          return BreadCrumbUtils.backToIndustryNews(i, navigate);
        }
        case 'incidentList': {
          return BreadCrumbUtils.backToIncidents(i, navigate);
        }
        case 'RIareaslist': {
          return BreadCrumbUtils.backToRiMaritimeAreas(i, navigate);
        }
        case 'map': {
          return BreadCrumbUtils.backToMap(i, navigate);
        }
        case 'drawings': {
          return BreadCrumbUtils.backToDrawings(i, navigate);
        }
        case 'routeList': {
          return BreadCrumbUtils.backToRoutes(i, navigate);
        }
        default: {
          return BreadCrumbUtils.backToMap(i, navigate);
        }
      }
    } else {
      return BreadCrumbUtils.backToExpandedItem(i, item, navigate);
    }
  };

  return (
    lastItem && (
      <IconButton
        aria-label="back"
        disableRipple
        data-testid="menu-button"
        sx={{
          m: '-1rem 0 -1rem 0.25rem',

          display: 'flex',
          justifyContent: 'end',
          color: 'secondary.main',
          '&:hover': {
            bgcolor: 'secondary.dark',
            borderRadius: '1rem',
          },
        }}
        onClick={() => {
          additionalAction?.();
          return backOneFunctionToUse(
            history.length - 1,
            lastItem as IndividualHistoryItem
          );
        }}
      >
        <WestIcon />
      </IconButton>
    )
  );
}

BackOneButton.defaultProps = {
  onClick: undefined,
};

Breadcrumb.defaultProps = {
  defaultHistory: undefined,
  currentPanel: undefined,
};

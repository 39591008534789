/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DrawingFilters,
  PartialCreateDrawingValues,
} from '../../models/drawings.model';

type DrawingsPanelState = {
  initialFormValues: PartialCreateDrawingValues;
  searchFilterValue: string;
  filters: DrawingFilters;
};

const INITIAL_DRAWINGS_PANEL_STATE: DrawingsPanelState = {
  initialFormValues: {} as PartialCreateDrawingValues,
  searchFilterValue: '',
  filters: {
    startDate: null,
    endDate: null,
    ownerTypes: [],
    open: false,
  },
};

const DrawingsPanelSlice = createSlice({
  name: 'drawingsPanel',
  initialState: INITIAL_DRAWINGS_PANEL_STATE,
  reducers: {
    setInitialFormValues: (
      state,
      action: PayloadAction<PartialCreateDrawingValues>
    ) => {
      state.initialFormValues = action.payload;
    },
    setSearchFilterValue: (state, action: PayloadAction<string>) => {
      state.searchFilterValue = action.payload;
    },
    setDrawingFilters: (
      state,
      action: PayloadAction<Partial<DrawingFilters> | null>
    ) => {
      // Only updates filters explictly mentioned in action
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
});

export const { setInitialFormValues, setDrawingFilters, setSearchFilterValue } =
  DrawingsPanelSlice.actions;

export default DrawingsPanelSlice.reducer;

/* eslint-disable import/prefer-default-export */
import {
  setSelectedVesselIds,
  setSelectedVessels,
} from '../dossiers_and_panels/vessel-dossier/vessel-dossier.slice';
import { useAppDispatch } from '../hooks';
import { EDossiers, setSelectedDossier } from '../main-menu/menu.slice';
import {
  setSelectedArea,
  setSelectedAreaId,
} from '../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import { ExpandedId, MaybeExpandedId } from '../models/risk_intelligence.model';
import {
  setSelectedCorrespondent,
  setSelectedCorrespondentId,
} from '../state/correspondents/correspondents.slice';
import {
  setSelectedCountry,
  setSelectedCountryId,
} from '../state/countries/countries.slice';
import {
  setSelectedDrawing,
  setSelectedDrawingId,
} from '../state/drawings/drawings.slice';
import { setSelectedIncident } from '../state/incidents/incidents.slice';
import {
  setSelectedIndustryNews,
  setSelectedIndustryNewsId,
} from '../state/industry-news/industry-news.slice';
import {
  setSelectedFirstCallPort,
  setSelectedFirstCallPortId,
} from '../state/ports/first-call.slice';
import { setSelectedPort, setSelectedPortId } from '../state/ports/ports.slice';
import store from '../store';

export const isExpandedId = (id: MaybeExpandedId): id is ExpandedId =>
  typeof id === 'object';

export const getRICountryTitle = (countryMaybeId: MaybeExpandedId) => {
  if (isExpandedId(countryMaybeId)) {
    return (countryMaybeId as ExpandedId).title;
  }
  const foundCountry = store
    .getState()
    .countries.countries?.find((country) => countryMaybeId === country.id);
  if (foundCountry) {
    return foundCountry.title;
  }
  return 'Not Found';
};

export const deselectAll = (dispatch: ReturnType<typeof useAppDispatch>) => {
  dispatch(setSelectedPort(null));
  dispatch(setSelectedCountry(null));
  dispatch(setSelectedPortId(null));
  dispatch(setSelectedCountryId(null));
  dispatch(setSelectedIncident(null));
  dispatch(setSelectedVessels(null));
  dispatch(setSelectedVesselIds(null));
  dispatch(setSelectedArea(null));
  dispatch(setSelectedAreaId(null));
  dispatch(setSelectedIndustryNews(null));
  dispatch(setSelectedIndustryNewsId(null));
  dispatch(setSelectedCorrespondent(null));
  dispatch(setSelectedCorrespondentId(null));
  dispatch(setSelectedDrawing(null));
  dispatch(setSelectedDrawingId(null));
  dispatch(setSelectedFirstCallPort(null));
  dispatch(setSelectedFirstCallPortId(null));
  dispatch(setSelectedDossier(EDossiers.NOTHING));
};

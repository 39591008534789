import {
  Attacker,
  ExpandedId,
  MaybeExpandedId,
  Position,
  RIImage,
} from '../../models/risk_intelligence.model';

export interface Incident extends ExpandedId {
  position?: Position;
  date: string;
  alert: boolean;
  timeOfDay: MaybeExpandedId;
  place: MaybeExpandedId;
  summary: string;
  type: MaybeExpandedId;
  region: MaybeExpandedId | null;
  selected?: boolean;
  attacker: Attacker;
  datum: number;
}

export interface ExpandedIncident extends Incident {
  type: ExpandedId;
  region: ExpandedId;
  area: ExpandedId | null;
  description: string;
  target: IncidentTarget | null;
  images: RIImage[];
  expanded?: true;
  country: ExpandedId | null;
  port: ExpandedId | null;
}

export interface MapIncident extends Incident {
  icon: {
    base: string;
    selected: string;
    alerting: string;
    selectedAlerting: string;
  };
  // position not optional
  position: Position;
}

export interface IncidentType extends ExpandedId {
  group: ExpandedId & { colour: string };
  icons: {
    round: string;
    marker: string;
    markerAlert: string;
    markerAlertAura: string;
    markerAura: string;
  };
}

export interface IncidentFilters {
  startDate: string | null;
  endDate: string | null;
  datePreset: string | null;
  regions: number[];
  timeOfDay: number[];
  place: number[];
  areas: number[];
  incidentTypes: number[];
  targets: number[];
  open: boolean;
  geo: GeoJSON.Geometry | null; // is a GeoJSON object bbox - see https://datatracker.ietf.org/doc/html/rfc7946#section-3
  alerts: number[];
}

export const AlertDisplayFilter: Record<string, ExpandedId> = {
  ShowAlerts: { id: 0, title: 'Alerts' },
  ShowNonAlerts: { id: 1, title: 'Non-Alerts' },
};

export const TimeOfDayDisplayFilter: Record<string, ExpandedId> = {
  ShowUnknown: { id: 0, title: 'Unknown' },
  ShowDay: { id: 1, title: 'Day' },
  ShowNight: { id: 2, title: 'Night' },
};

export const PlaceDisplayFilter: Record<string, ExpandedId> = {
  ShowUnknown: { id: 0, title: 'Unknown' },
  ShowPort: { id: 1, title: 'Port' },
  ShowAnchorage: { id: 2, title: 'Anchorage' },
  ShowOpenSea: { id: 3, title: 'Open Sea' },
  ShowInshore: { id: 4, title: 'Inshore' },
};

interface IncidentTarget {
  type: IncidentTargetType;
  flagState: string;
  imo: string;
  callSign: string;
}

export type IncidentTargetType = ExpandedId;

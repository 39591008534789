import { useMemo } from 'react';
import { useAppSelector } from '../../hooks';
import { Privileges, calculatePrivileges } from './privileges';

const useAccessControl = (): Privileges => {
  const userToken = useAppSelector((state) => state.user.idToken);
  const privileges = useMemo(() => calculatePrivileges(userToken), [userToken]);

  return privileges;
};

export default useAccessControl;

import { West } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import PanelTitle from '../../common-components/panel/panel-title';
import { useAppDispatch, useAppSelector } from '../../hooks';
import ShippingLanesPanel from '../shipping-lanes-panel/shipping-lanes-panel';
import WeatherPanel from '../weather-panel/weather-panel';
import MeasuringTool from './measuring-tool/measuring-tool';
import NearbyVessels from './nearby-vessels/nearby-vessels';
import RangeRingsView from './range-rings/range-rings-view';
import ToolsMenu from './tools-menu';
import {
  ToolsPanelViewState,
  setToolsPanelViewState,
} from './tools-panel.slice';

function ToolsPanelSelectedView() {
  const { viewState } = useAppSelector((state) => state.toolsPanel);

  switch (viewState) {
    case ToolsPanelViewState.RANGE_RINGS:
      return <RangeRingsView />;
    case ToolsPanelViewState.NEARBY_VESSELS:
      return <NearbyVessels />;
    case ToolsPanelViewState.MEASURING_TOOL:
      return <MeasuringTool />;
    case ToolsPanelViewState.MENU:
      return <ToolsMenu />;
    case ToolsPanelViewState.WEATHER:
      return <WeatherPanel />;
    case ToolsPanelViewState.SHIPPING_LANES:
      return <ShippingLanesPanel />;
    default:
      return null;
  }
}

function ToolsPanel() {
  const { viewState } = useAppSelector((state) => state.toolsPanel);
  const dispatch = useAppDispatch();

  const ToolPanelSelectedViewTitle = {
    [ToolsPanelViewState.MEASURING_TOOL]: 'Measuring Tool',
    [ToolsPanelViewState.RANGE_RINGS]: 'Range Rings',
    [ToolsPanelViewState.NEARBY_VESSELS]: 'Nearby Vessels',
    [ToolsPanelViewState.MENU]: 'Tools',
    [ToolsPanelViewState.WEATHER]: 'Weather',
    [ToolsPanelViewState.SHIPPING_LANES]: 'Shipping Lanes',
  };

  return (
    <Stack
      data-testid="tools-panel"
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          maxHeight: '10vh',
        }}
      >
        {viewState !== ToolsPanelViewState.MENU && (
          <IconButton
            data-testid="tools-panel-back"
            onClick={() =>
              dispatch(setToolsPanelViewState(ToolsPanelViewState.MENU))
            }
          >
            <West />
          </IconButton>
        )}
        <PanelTitle>
          {ToolPanelSelectedViewTitle?.[viewState as ToolsPanelViewState]}
        </PanelTitle>
      </Box>
      <ToolsPanelSelectedView />
    </Stack>
  );
}

export default ToolsPanel;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Checkbox, IconButton, Switch } from '@mui/material';
import { EMenuItems } from '../../main-menu/menu.slice';
import { toggleShowAllAreas } from '../../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas.slice';
import store from '../../store';
import ItemToggleVariants from './item-toggle-variants';
import './item-toggle.scss';

export interface ItemToggleProps {
  onChange?: Function;
  value: boolean;
  disabled?: boolean;
  toggledTooltip?: string;
  untoggledTooltip?: string;
  sx?: any;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  variant?: ItemToggleVariants;
}

function ItemToggle(props: ItemToggleProps) {
  const {
    value,
    onChange,
    disabled,
    toggledTooltip,
    untoggledTooltip,
    sx,
    onMouseEnter,
    onMouseLeave,
    variant,
  } = props;

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    if (onChange) {
      onChange(!value);
    }

    if (toggledTooltip?.includes(EMenuItems.RI_MARITIME_AREAS)) {
      store.dispatch(toggleShowAllAreas());
    }
  };

  const getItemToolTip = () => {
    if (disabled) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return value ? untoggledTooltip : toggledTooltip;
  };

  const iconToDisplay = value ? (
    <VisibilityOutlinedIcon
      sx={{ fontSize: '22px', color: 'secondary.main' }}
    />
  ) : (
    <VisibilityOffOutlinedIcon
      sx={{ fontSize: '22px', color: 'secondary.main' }}
    />
  );

  switch (variant) {
    case 'checkbox':
      return (
        <Checkbox
          data-testid="item-toggle"
          onClick={onClick}
          title={getItemToolTip()}
          checked={value}
          disabled={disabled}
          sx={{
            ...sx,
            color: 'secondary.main',
          }}
        />
      );
    case 'switch': {
      return (
        <Switch
          data-testid="item-toggle"
          onClick={onClick}
          title={getItemToolTip()}
          checked={value}
          disabled={disabled}
          sx={{
            ...sx,
            color: 'secondary.main',
          }}
        />
      );
    }
    default:
      return (
        <IconButton
          className={`${value ? 'toggled' : `untoggled`}`}
          disabled={disabled}
          data-testid="item-toggle"
          onClick={onClick}
          title={getItemToolTip()}
          sx={sx}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {iconToDisplay}
        </IconButton>
      );
  }
}

ItemToggle.defaultProps = {
  disabled: false,
  onChange: () => {},
  toggledTooltip: '',
  untoggledTooltip: '',
  sx: {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  variant: 'default',
};

export default ItemToggle;

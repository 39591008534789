import mapboxgl, { Layer } from 'mapbox-gl';
import MapLayer from './map-layer.enum';

import MuiPlaceIcon from '../../assets/icons/generic/mui-place.svg';
import HighlightIncidentMarker from '../../assets/icons/incident/default-incident-icon-marker-alert.png';
import DefaultIncidentMarker from '../../assets/icons/incident/default-incident-icon-marker.png';
import IndustryNewsIcon from '../../assets/icons/industry-news/industry-news-icon-default.svg';
import IndustryNewsSelectedIcon from '../../assets/icons/industry-news/industry-news-icon-selected.svg';
import CorrespondentsSelectedIcon from '../../assets/icons/ports/correspondents-selected.svg';
import CorrespondentsIcon from '../../assets/icons/ports/correspondents.svg';
import FirstCallPortSelectedIcon from '../../assets/icons/ports/first-call-port-selected.svg';
import FirstCallPortIcon from '../../assets/icons/ports/first-call-port.svg';
import PortRICruiseSelectedIcon from '../../assets/icons/ports/ri-port-cruise-selected.svg';
import PortRICruise from '../../assets/icons/ports/ri-port-cruise.svg';
import PortRIInland from '../../assets/icons/ports/ri-port-inland.svg';
import PortRISelectedIcon from '../../assets/icons/ports/ri-port-selected.svg';
import PortRIIcon from '../../assets/icons/ports/ri-port.svg';
import PortSelectedIcon from '../../assets/icons/ports/wpi-port-selected.svg';
import PortIcon from '../../assets/icons/ports/wpi-port.svg';
import Outline from '../../assets/icons/vessels/outline.png';
import PathIsoscelesTriangleRed from '../../assets/icons/vessels/path-triangle-isosceles-red.png';
import PathIsoscelesTriangle from '../../assets/icons/vessels/path-triangle-isosceles.png';
import CurrentVesselWithBorder from '../../assets/icons/vessels/vessel-border.svg';
import vesselSearchAreaAmber from '../../assets/icons/vessels/vessel-navigation-icon-orange.png';
import vesselSearchAreaRed from '../../assets/icons/vessels/vessel-navigation-icon-red.png';
import vesselSearchArea from '../../assets/icons/vessels/vessel-navigation-icon.png';
import CurrentVessel from '../../assets/icons/vessels/vessel-standard.svg';
import CustomMapEvents from '../custom-map-events.enum';
import MapHelpers from '../map.utils';
import addMapImage from './layer-utils/add-image-to-layer';
import MapLayerIcon from './map-layer-icon';

namespace MapLayerManager {
  interface IGroupLayerProps {
    groupLayers?: string[];
  }

  export function getLayerIdsFromSourceGroups(sourceGroups: string[]) {
    return (
      sourceGroups?.flatMap((group) =>
        MapLayerManager.findLayersByGroupLayerId(group).map(
          (layer: { id: any }) => layer.id
        )
      ) ?? []
    );
  }

  // remove layer data and layer view from map
  export const destroyLayer = (layer: string) => {
    MapHelpers.removeLayer(layer);
    MapHelpers.removeSource(layer);
  };

  export const groupLayerMetaData = (
    groupLayers: string[]
    // eslint-disable-next-line object-shorthand
  ): IGroupLayerProps => ({ groupLayers: groupLayers });

  export const findLayersByGroupLayerId = (groupLayer: string) => {
    const allLayers = MapHelpers.getAllLayers();

    const filteredLayers = allLayers.filter((layer: mapboxgl.Layer) => {
      const { metadata } = layer;
      return (
        metadata &&
        metadata.groupLayers &&
        metadata.groupLayers.includes(groupLayer)
      );
    });

    return filteredLayers;
  };

  export const AddLayerinGroups = (
    layer: mapboxgl.AnyLayer,
    groupLayers: string[]
  ) => {
    // eslint-disable-next-line no-param-reassign
    (layer as Layer).metadata = {
      ...(layer as Layer).metadata,
      ...MapLayerManager.groupLayerMetaData(
        [(layer as Layer).metadata?.groupLayers || [], ...groupLayers].flat()
      ),
    };

    MapHelpers.addLayer(layer);

    groupLayers.forEach((groupLayer) => {
      MapLayerManager.triggerLayerGroupChange(groupLayer);
    });
  };

  const LayerGroupChangeType = (layerId: string) =>
    `${layerId}_${CustomMapEvents.GROUP_LAYER_CHANGE}`;

  export const triggerLayerGroupChange = (groupLayerId: string) =>
    MapHelpers.fire(LayerGroupChangeType(groupLayerId));

  export const onLayerGroupChange = (
    groupLayerId: string,
    callback: (ev: any) => void
  ) => {
    MapHelpers.addMapEventListener(
      LayerGroupChangeType(groupLayerId),
      null,
      callback
    );
  };

  export const removeLayerGroupChange = (
    groupLayerId: string,
    callback: (ev: any) => void
  ) => {
    MapHelpers.removeMapEventListener(
      LayerGroupChangeType(groupLayerId),
      callback
    );
  };

  export const loadMapImages = () => {
    addMapImage({
      imageImport: CurrentVessel,
      imageName: MapLayerIcon.PLAIN_VESSEL.NORMAL,
      svg: { h: 60, w: 60 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: CurrentVessel,
      imageName: MapLayerIcon.PLAIN_VESSEL.NEARBY,
      svg: { h: 60, w: 60 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: CurrentVesselWithBorder,
      imageName: MapLayerIcon.PLAIN_VESSEL.BORDER,
      svg: { h: 60, w: 60 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: PathIsoscelesTriangle,
      imageName: MapLayerIcon.HISTORIC_POSITION,
    });
    addMapImage({
      imageImport: PathIsoscelesTriangleRed,
      imageName: MapLayerIcon.RADIO_POSITION,
    });
    addMapImage({
      imageImport: DefaultIncidentMarker,
      imageName: MapLayerIcon.INCIDENT.DEFAULT,
    });
    addMapImage({
      imageImport: HighlightIncidentMarker,
      imageName: MapLayerIcon.INCIDENT.SELECTED,
    });
    addMapImage({
      imageImport: Outline,
      imageName: MapLayer.VESSEL_FOCUS_RING,
    });
    addMapImage({
      imageImport: PortRIIcon,
      imageName: MapLayerIcon.PORT.DEFAULT_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortRICruise,
      imageName: MapLayerIcon.PORT.CRUISE_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortRIInland,
      imageName: MapLayerIcon.PORT.INLAND_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortRISelectedIcon,
      imageName: MapLayerIcon.PORT.SELECTED_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortRICruiseSelectedIcon,
      imageName: MapLayerIcon.PORT.SELECTED_CRUISE_RI,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortIcon,
      imageName: MapLayerIcon.PORT.DEFAULT,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: PortSelectedIcon,
      imageName: MapLayerIcon.PORT.SELECTED,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: IndustryNewsIcon,
      imageName: MapLayerIcon.INDUSTRY_NEWS.DEFAULT,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: IndustryNewsSelectedIcon,
      imageName: MapLayerIcon.INDUSTRY_NEWS.SELECTED,
      svg: { h: 36, w: 28 },
    });
    addMapImage({
      imageImport: MuiPlaceIcon,
      imageName: MapLayerIcon.WEATHER.PIN,
      svg: { h: 20, w: 14 },
      convertToSDF: true,
    });
    addMapImage({
      imageImport: vesselSearchArea,
      imageName: MapLayerIcon.VESSEL_SEARCH_AREA.NORMAL,
    });
    addMapImage({
      imageImport: vesselSearchAreaAmber,
      imageName: MapLayerIcon.VESSEL_SEARCH_AREA.AMBER,
    });
    addMapImage({
      imageImport: vesselSearchAreaRed,
      imageName: MapLayerIcon.VESSEL_SEARCH_AREA.ALERT,
    });
    addMapImage({
      imageImport: FirstCallPortIcon,
      imageName: MapLayerIcon.PORT.FIRST_CALL_PORT,
      svg: { h: 45, w: 38 },
    });
    addMapImage({
      imageImport: FirstCallPortSelectedIcon,
      imageName: MapLayerIcon.PORT.FIRST_CALL_PORT_SELECTED,
      svg: { h: 45, w: 38 },
    });
    addMapImage({
      imageImport: CorrespondentsIcon,
      imageName: MapLayerIcon.PORT.CORRESPONDENT,
      svg: { h: 45, w: 38 },
    });
    addMapImage({
      imageImport: CorrespondentsSelectedIcon,
      imageName: MapLayerIcon.PORT.CORRESPONDENT_SELECTED,
      svg: { h: 60, w: 48 },
    });
  };
}

export default MapLayerManager;

import DateTimeHelpers from '../../utils/date-time-helpers.utils';
import { ThreatAssessment } from './threat-assessment.model';

export const translateThreatLevelToText = (threatLevel: number) => {
  switch (threatLevel) {
    case 1:
      return 'Low';
    case 2:
      return 'Moderate';
    case 3:
      return 'Elevated';
    case 4:
      return 'High';
    case 5:
      return 'Severe';
    default:
      return 'Unknown';
  }
};

export const translateThreatLevelToColour = (threatLevel: number) => {
  switch (threatLevel) {
    case 1:
    case 2:
      return '#3c9d38';
    case 3:
      return '#fccb32';
    case 4:
    case 5:
      return '#d0101e';
    default:
      return 'gray';
  }
};

export const translateThreatTrendToText = (threatTrend: number) => {
  switch (threatTrend) {
    case 0:
      return 'Unchanged';
    case 1:
      return 'Up';
    case -1:
      return 'Down';
    default:
      return 'Unknown';
  }
};

export const getThreatAssessmentRevisionLabel = (underRevision: boolean) =>
  underRevision
    ? 'Under Revision'
    : `Valid as of ${DateTimeHelpers.dateToMonthDayYear(
        new Date().toISOString()
      )}`;

export const threatAssessmentTitle = (
  entity: ThreatAssessment['entity'],
  country: ThreatAssessment['country']
) =>
  `${entity.title}${
    entity.type !== 'country' && country ? ` - ${country.title}` : ''
  }`;

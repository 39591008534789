/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LocationFilter = {
  coordinates: {
    longitude: number;
    latitude: number;
  } | null;
  layers: string[];
};

type IBoundariesPanelState = {
  locationFilter: LocationFilter;
  locationFilterLoading: boolean;
  locationFilterError: boolean;
  searchFilterValue: string;
};

export const INITIAL_BOUNDARIES_PANEL_STATE: IBoundariesPanelState = {
  locationFilter: {
    coordinates: null,
    layers: [],
  },
  locationFilterLoading: false,
  locationFilterError: false,
  searchFilterValue: '',
};

const BoundariesPanelSlice = createSlice({
  name: 'boundariesPanel',
  initialState: INITIAL_BOUNDARIES_PANEL_STATE,
  reducers: {
    setLocationFilter: (state, action: PayloadAction<LocationFilter>) => {
      state.locationFilter = action.payload;
    },
    setSearchFilterValue: (state, action: PayloadAction<string>) => {
      state.searchFilterValue = action.payload;
    },
  },
});

export const { setLocationFilter, setSearchFilterValue } =
  BoundariesPanelSlice.actions;

export default BoundariesPanelSlice.reducer;

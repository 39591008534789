import { wrapRequest } from './base';

export enum ShippingLaneFlag {
  ALL = 'ALL_FLAGS',
  CHINA = 'CN',
  UNITED_KINGDOM = 'GB',
  SAINT_KITTS_AND_NEVIS = 'KN',
  PANAMA = 'PA',
  RUSSIA = 'RU',
  UKRAINE = 'UA',
}

export enum ShippingLaneShipType {
  ALL = 'ALL_SHIPTYPES',
  CARGO = 'Cargo',
  FISHING = 'Fishing',
  PASSENGER_SHIP = 'Passenger Ship',
  PLEASURE_CRAFT = 'Pleasure Craft',
  TANKER = 'Tanker',
}

export enum ShippingLaneResolution {
  LOW = 0.5,
  MEDIUM = 0.1,
  HIGH = 0.01,
}

export interface ShippingLaneRequestBody {
  extent: [number, number, number, number]; // longMin, longMax, latMin, latMax
  date: string;
  resolution: ShippingLaneResolution;
  ais_data_type: string;
  filters: {
    flag: string[];
    shiptype: string[];
  };
}

export interface ShippingLaneResponseBody {
  errorType?: string;
  lanes: number[][]; // grid of intensity values. outer array is columns, inner array is rows
  meta: {
    extent: [number, number, number, number]; // lonStart, lonEnd, latStart, latEnd
    resolution: ShippingLaneResolution;
  };
}

export const getShippingLaneTile = async (
  postBody: ShippingLaneRequestBody
) => {
  const resultSansMeta = await wrapRequest<ShippingLaneResponseBody>(
    'post',
    'geonius',
    '/shipping-lanes',
    {
      body: {
        extent: postBody.extent,
        date: postBody.date.split('Z')[0], // python doesn't like {time}Z
        resolution: postBody.resolution,
        ais_data_type: postBody.ais_data_type,
        filters: postBody.filters,
      },
    }
  );
  return {
    ...resultSansMeta,
    meta: {
      extent: postBody.extent,
      resolution: postBody.resolution,
    },
  };
};

export const getShippingLanes = async (
  postBody: ShippingLaneRequestBody,
  eachTileCallback: (
    errorMessage: string | null,
    tile: ShippingLaneResponseBody | null,
    tilesToProcess: number,
    tilesProcessed: number
  ) => void
) => {
  const [longMin, longMax, latMin, latMax] = postBody.extent;
  const horizontalTileWidth = Math.min(40, postBody.resolution * 1000);
  const verticalTileHeight = Math.min(10, postBody.resolution * 500);
  const totalTiles =
    Math.ceil((longMax - longMin) / horizontalTileWidth) *
    Math.ceil((latMax - latMin) / verticalTileHeight);
  let processedTiles = 0;
  const tilePromises: Promise<ShippingLaneResponseBody>[] = [];

  for (let tx = longMin; tx < longMax; tx += horizontalTileWidth) {
    for (let ty = latMin; ty < latMax; ty += verticalTileHeight) {
      const tnx = Math.min(tx + horizontalTileWidth, longMax);
      const tny = Math.min(ty + verticalTileHeight, latMax);
      const extent = [tx, tnx, ty, tny] as [number, number, number, number];
      const tileBody = { ...postBody, extent };
      const tilePromise = getShippingLaneTile(tileBody)
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        .then((response) => {
          // response can be 200 with errorType in data
          if (response.errorType) {
            throw Error(response.errorType);
          }
          processedTiles += 1;
          eachTileCallback(null, response, totalTiles, processedTiles);
          return response;
        })
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        .catch((errorMessage) => {
          processedTiles += 1;
          eachTileCallback(errorMessage, null, totalTiles, processedTiles);
          return {
            errorType: errorMessage,
            lanes: [],
            meta: {
              extent: [tx, tnx, ty, tny],
              resolution: postBody.resolution,
            },
          } as ShippingLaneResponseBody;
        });
      tilePromises.push(tilePromise);
    }
  }
  return {
    results: await Promise.all(tilePromises),
    tilesToProcess: totalTiles,
    tilesProcessed: processedTiles,
  };
};

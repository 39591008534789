import onPortClusterClick from './port-cluster-onClick';
import onPortClick from './port-onClick';

namespace PortsController {
  export const layerEvents = {
    onClick: onPortClick,
    onClusterClick: onPortClusterClick,
  };
}

export default PortsController;

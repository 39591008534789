import { Box } from '@mui/material';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks';

import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import RegionsPanel from '../../maritime-menu-options/areas-panel/drawings-panel';
import BoundariesPanel from '../../maritime-menu-options/boundaries-panel/boundaries-panel';
import CountriesPanel from '../../maritime-menu-options/countries-panel/countries-panel';
import DocumentsPanel from '../../maritime-menu-options/documents-panel/documents-panel';
import HistoryPanel from '../../maritime-menu-options/history-panel/history-panel';
import IncidentsPanel from '../../maritime-menu-options/incidents-panel/incidents-panel';
import MapsPanel from '../../maritime-menu-options/maps-panel/maps-panel';
import RIMaritimeAreasPanel from '../../maritime-menu-options/ri-maritime-areas-panel/ri-maritime-areas-panel';
import { RoutesPanel } from '../../maritime-menu-options/routes-panel/routes-panel';
import ToolsPanel from '../../maritime-menu-options/tools-panel/tools-panel';
import WorldPortsPanel from '../../maritime-menu-options/world-ports-panel/world-ports-panel';

import Timeline from '../../common-components/timeline/timeline';
import { themedDraw } from '../../map/map-controls.utils';
import { setCreateDrawingPanelVisible } from '../../state/drawings/drawings.slice';
import { closeMenus, EMenuItems } from '../menu.slice';
import SecondaryPane from '../secondary-pane/secondary-pane';
import SideMenu from '../side-menu/side-menu';

import useAccessControl from '../../hooks/access-control/useAccessControl';
import useMainMenuAccessConfig from '../use-main-menu-access-config';

import MapHelpers from '../../map/map.utils';

import { removeTemporaryCircleFeatures } from '../../maritime-menu-options/areas-panel/create-drawing-panel';
import CorrespondentsPanel from '../../maritime-menu-options/correspondents-panel/correspondents-panel';
import FirstCallPanel from '../../maritime-menu-options/first-call-panel/first-call-panel';
import IndustryNewsPanel from '../../maritime-menu-options/industry-news/industry-news-panel';
import VpsPortsPanel from '../../maritime-menu-options/vps-ports-panel/vps-ports-panel';
import ModalsContainer from './modals-container';

function DeriveComponent(componentToDisplay: EMenuItems) {
  const mainMenuAccessConfig = useMainMenuAccessConfig();

  if (!mainMenuAccessConfig[componentToDisplay].hasAccess) {
    return <ErrorPanel message="You do not have access to this feature." />;
  }

  switch (componentToDisplay) {
    case EMenuItems.HISTORY: {
      return <HistoryPanel />;
    }
    case EMenuItems.MAPS: {
      return <MapsPanel />;
    }
    case EMenuItems.DRAWINGS: {
      return <RegionsPanel />;
    }
    case EMenuItems.INCIDENTS: {
      return <IncidentsPanel />;
    }
    case EMenuItems.BOUNDARIES: {
      return <BoundariesPanel />;
    }
    case EMenuItems.PORTS: {
      return <WorldPortsPanel />;
    }
    case EMenuItems.RI_MARITIME_AREAS: {
      return <RIMaritimeAreasPanel />;
    }
    case EMenuItems.ROUTES: {
      return <RoutesPanel />;
    }
    case EMenuItems.DOCUMENTS: {
      return <DocumentsPanel />;
    }
    case EMenuItems.COUNTRIES: {
      return <CountriesPanel />;
    }
    case EMenuItems.TOOLS: {
      return <ToolsPanel />;
    }
    case EMenuItems.INDUSTRY_NEWS: {
      return <IndustryNewsPanel />;
    }
    case EMenuItems.VPS_PORTS: {
      return <VpsPortsPanel />;
    }
    case EMenuItems.FIRST_CALL_PORTS: {
      return <FirstCallPanel />;
    }
    case EMenuItems.CORRESPONDENTS: {
      return <CorrespondentsPanel />;
    }
    case EMenuItems.NOTHING_SELECTED: {
      return <div />;
    }
    default: {
      return <ErrorPanel message="Component not found." />;
    }
  }
}

function MainMenu() {
  const secondaryMenuOpen = useAppSelector(
    (state) => state.menu.secondaryMenuOpen
  );
  const { vesselHistoryData } = useAppSelector((state) => state.historyPanel);
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);

  const dispatch = useAppDispatch();

  const componentToDisplay = useAppSelector(
    (state) => state.menu.selectedOption
  );
  const { canAccessTimeline } = useAccessControl();

  const component = DeriveComponent(componentToDisplay);

  useEffect(() => {
    // If the menu item is not regions, we close the panel and clear the map
    // of added drawings
    if (componentToDisplay !== EMenuItems.DRAWINGS) {
      dispatch(setCreateDrawingPanelVisible(false));
      if (mapInitialised) {
        const draw = themedDraw;
        draw.changeMode('simple_select');
        // manually fire the mode change event as the draw.changeMode() does not
        // seem to be firing it.
        MapHelpers.fire('draw.modechange', { mode: 'simple_select' });
        draw.deleteAll();
        removeTemporaryCircleFeatures();
      }
    }
  }, [componentToDisplay]);

  useEffect(() => {
    if (componentToDisplay === EMenuItems.NOTHING_SELECTED) {
      dispatch(closeMenus());
    }
  }, [componentToDisplay]);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        height: '100%',
      }}
      data-testid="main-menu"
    >
      <SideMenu />

      {secondaryMenuOpen && <SecondaryPane component={component} />}
      <ModalsContainer />
      {canAccessTimeline && vesselHistoryData && (
        <Timeline vesselHistoryData={vesselHistoryData} />
      )}
    </Box>
  );
}

export default MainMenu;

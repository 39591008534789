import { useAppSelector, useMobile } from '../hooks';
import './map-details-indicator.scss';

function MapDetailsIndicator() {
  const tickerOpen = useAppSelector(
    (state) => state.userPreferences.userPreferences.showRiNewsBanner
  );
  const isMobile = useMobile();
  // 'mousemove' events never fire on mobile devices, so the indicator is never populated, so not useful
  // It was also falling off the screen
  if (isMobile) return null;
  return (
    <div
      id="map-details-indicator-container"
      className={`${tickerOpen ? 'tickerOpen' : ''}`}
    />
  );
}

export default MapDetailsIndicator;

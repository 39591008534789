/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  VesselHistoryData,
  getLocationDataDAAS,
  responseToLocationArray,
} from '../../../api';
import RIAccordion from '../../../common-components/accordion/ri-accordion';
import HeatmapKey from '../../../common-components/heatmap-key/heatmap-key';
import { setCentreDate } from '../../../common-components/timeline/timeline.slice';
import { useAppDispatch, useAppSelector, useMobile } from '../../../hooks';
import useAccessControl from '../../../hooks/access-control/useAccessControl';
import MapLayerPaint from '../../../map/map-layer-manager/map-layer-paint';
import { TimeSeriesLineGraph } from '../../../reporting/charts';
import DateTimeHelpers from '../../../utils/date-time-helpers.utils';
import { removeWhiteSpace } from '../../../utils/text-formatting.utils';
import {
  buildIdentifiers,
  clearVesselHistory,
} from '../../../utils/vessels.utils';
import { HistoricVesselPoint } from '../historic-vessel-point.model';
import HistoryAISDataGaps from '../history-ais-data-gaps/history-ais-data-gaps';
import HistoryAISMergedPoints from '../history-ais-merged-points/history-ais-merged-points';
import { HistoryFormValues } from '../history-form/history-form-validators';
import HistoryPanelSampleRate from '../history-panel-sample-rate';
import VesselHistoryResultsExportModal from './history-results-export/history-results-export-modal';
import HistoryResultsList from './history-results-list';
import HistoryResultsVisualisationOptions from './history-results-visualisation-options';

const SAMPLE_RATE_LABELS = {
  [HistoryPanelSampleRate.ONE_DAY]: 'Daily',
  [HistoryPanelSampleRate.ONE_HOUR]: 'Hourly',
  [HistoryPanelSampleRate.TEN_MINUTES]: 'Every 10 minutes',
};

interface InfoTabProps {
  formValues?: HistoryFormValues;
  formattedIdentifiers: any;
}

function InfoTab({ formValues, formattedIdentifiers }: InfoTabProps) {
  return (
    <Grid
      container
      rowSpacing={1}
      sx={{
        padding: '20px 10px',
      }}
    >
      <Grid item xs={2}>
        <Typography variant="subtitle2" align="left">
          MMSI
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body2" align="right">
          {formattedIdentifiers.mmsis.length
            ? formattedIdentifiers.mmsis.join(', ')
            : 'None'}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="subtitle2" align="left">
          IMO
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="body2" align="right">
          {formattedIdentifiers.imos.length
            ? formattedIdentifiers.imos.join(', ')
            : 'None'}
        </Typography>
      </Grid>
      {formValues?.fromDate && formValues?.toDate && (
        <>
          <Grid item xs={2}>
            <Typography variant="subtitle2" align="left">
              Date
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2" align="right">
              {DateTimeHelpers.dateToMonthDayYear(formValues.fromDate)} -{' '}
              {DateTimeHelpers.dateToMonthDayYear(formValues.toDate)}
            </Typography>
          </Grid>
        </>
      )}
      {formValues?.sampleRate && (
        <>
          <Grid item xs={2}>
            <Typography variant="subtitle2" align="left">
              Rate
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2" align="right">
              {SAMPLE_RATE_LABELS[formValues.sampleRate]}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}

InfoTab.defaultProps = {
  formValues: undefined,
};

function RenderGraphs({
  vesselHistoryData,
  formattedIdentifiers,
}: {
  vesselHistoryData: VesselHistoryData;
  formattedIdentifiers: {
    mmsis: string[];
    imos: string[];
  };
}) {
  const { fromDate, toDate } = vesselHistoryData.formValues;
  const [vesselPoints, setVesselPoints] = useState<HistoricVesselPoint[][]>([]);
  const { centreDate } = useAppSelector((state) => state.timeline);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getLocationDataDAAS(
      new Date(fromDate),
      new Date(toDate),
      formattedIdentifiers.mmsis,
      formattedIdentifiers.imos,
      '1d'
    ).then((response) => {
      setVesselPoints(responseToLocationArray(response));
    });
  }, []);

  const requestDetailedDataFunction = async (min: Date, max: Date) =>
    getLocationDataDAAS(
      min,
      max,
      formattedIdentifiers.mmsis,
      formattedIdentifiers.imos,
      '1h'
    ).then((response) => responseToLocationArray(response));

  const onDateChange = (date: number) => {
    dispatch(
      setCentreDate({
        alreadyOffset: false,
        date,
      })
    );
  };
  const colours = vesselPoints.map(
    (_, index) => MapLayerPaint.VESSEL_HISTORY_JOURNEY(index)['line-color']
  );

  return vesselPoints.length > 0 ? (
    <TimeSeriesLineGraph
      title="Speed"
      data={vesselPoints}
      yAxisKeys={[{ key: 'speed' }]}
      xAxisKey="timestamp"
      yLabel="Speed (Knots)"
      labelKeys={vesselPoints.map((vessel) => vessel[0].name)}
      requestDetailedDataFunction={requestDetailedDataFunction}
      onDateChange={onDateChange}
      selectedDate={centreDate.date}
      colours={colours}
    />
  ) : (
    <CircularProgress />
  );
}

function HistoryResultsViewPopup() {
  const { vesselHistoryData } = useAppSelector((state) => state.historyPanel);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const isMobile = useMobile();

  const [formattedIdentifiers, setFormattedIdentifiers] = useState<{
    mmsis: string[];
    imos: string[];
  }>({
    mmsis: [],
    imos: [],
  });

  useEffect(() => {
    if (vesselHistoryData?.formValues.identifiers) {
      setFormattedIdentifiers(
        buildIdentifiers(
          removeWhiteSpace(vesselHistoryData.formValues.identifiers)
        )
      );
    } else {
      // eslint-disable-next-line no-console
      console.debug('No identifiers found in form values');
    }
  }, [vesselHistoryData]);

  const { canAccessGraphs } = useAccessControl();

  return (
    <Box
      sx={{
        overflow: 'hidden',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '1rem',
      }}
      data-testid="history-results-view-popup"
    >
      <VesselHistoryResultsExportModal
        showModal={showExportModal}
        closeModal={() => setShowExportModal(false)}
      />
      <Typography
        variant="body1"
        textAlign="left"
        sx={{
          fontWeight: 500,
          padding: '10px',
        }}
      >
        {isMobile && (
          <IconButton
            size="small"
            onClick={() => {
              clearVesselHistory();
            }}
          >
            <ArrowBack />
          </IconButton>
        )}
        Vessel History
      </Typography>
      {canAccessGraphs ? (
        <Tabs>
          <TabList
            style={{
              marginBottom: '0.5rem',
              paddingBottom: '1px',
            }}
          >
            <Tab data-testid="info-tab-header">Info</Tab>
            <Tab data-testid="graphs-tab-header">Graphs</Tab>
          </TabList>
          <TabPanel data-testid="info-tab-body">
            {vesselHistoryData ? (
              <InfoTab
                formValues={vesselHistoryData.formValues}
                formattedIdentifiers={formattedIdentifiers}
              />
            ) : null}
          </TabPanel>
          <TabPanel data-testid="graphs-tab-body">
            {vesselHistoryData ? (
              <RenderGraphs
                vesselHistoryData={vesselHistoryData}
                formattedIdentifiers={formattedIdentifiers}
              />
            ) : (
              <CircularProgress />
            )}
          </TabPanel>
        </Tabs>
      ) : vesselHistoryData ? (
        <InfoTab
          formValues={vesselHistoryData.formValues}
          formattedIdentifiers={formattedIdentifiers}
        />
      ) : null}

      <Box
        sx={{
          padding: '10px',
        }}
      >
        <Button
          sx={{
            borderRadius: '100px',
            borderColor: 'primary.dark',
            borderWidth: '1px',
            borderStyle: 'solid',
            height: '38px',
            '& a': {
              color: 'secondary.main',
            },
            marginBottom: '20px',
          }}
          fullWidth
          onClick={() => setShowExportModal(true)}
        >
          Download history
        </Button>
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          maxHeight: 'fit-content',
          scrollbarWidth: 'thin',
        }}
      >
        <RIAccordion
          title="Visualization Options"
          content={<HistoryResultsVisualisationOptions />}
        />
        <RIAccordion title="Results" content={<HistoryResultsList />} />
        <RIAccordion title="AIS data gaps" content={<HistoryAISDataGaps />} />
        <RIAccordion
          title="Merged/stationary points"
          content={<HistoryAISMergedPoints />}
        />
        <RIAccordion
          title="Heatmap Key"
          content={<HeatmapKey label="Increasing Density of AIS Broadcasts" />}
        />
      </Box>
    </Box>
  );
}

export default HistoryResultsViewPopup;

import { Units } from '@turf/helpers';
import { circle as turfCircle } from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import GeoHelper from '../../utils/geo-helpers.utils';
import MapLayerManager from '../map-layer-manager/map-layer-manager.utils';
import { MapGroupLayer } from '../map-layer-manager/map-layer.enum';
import MapHelpers from '../map.utils';

export const drawCircle = (
  layer: string,
  centre: number[],
  radius: number,
  paint: mapboxgl.FillPaint,
  groupLayer?: MapGroupLayer
) => {
  const options = {
    steps: 64,
    units: 'nauticalmiles' as Units,
  };

  const circle = turfCircle(centre, radius, options);

  const addNewLayer = !MapHelpers.getLayer(layer);
  GeoHelper.setMapboxGeoJSONSourceData(layer, circle);
  if (addNewLayer) {
    if (groupLayer) {
      MapLayerManager.AddLayerinGroups(
        {
          id: layer,
          type: 'fill',
          source: layer,
          paint,
        },
        [groupLayer]
      );
    } else {
      MapHelpers.addLayer({
        id: layer,
        type: 'fill',
        source: layer,
        paint,
      });
    }
  }
};

export const drawCircleLine = (
  layer: string,
  centre: number[],
  radius: number,
  paint: mapboxgl.LinePaint
) => {
  const options = {
    steps: 64,
    units: 'nauticalmiles' as Units,
  };

  const circle = turfCircle(centre, radius, options);

  const addNewLayer = !MapHelpers.getLayer(layer);
  GeoHelper.setMapboxGeoJSONSourceData(layer, circle);
  if (addNewLayer) {
    MapHelpers.addLayer({
      id: layer,
      type: 'line',
      source: layer,
      paint,
    });
  }
};

export const deleteDrawing = (layer: string) => {
  const emptyFeatureCollection: GeoJSON.FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  GeoHelper.setMapboxGeoJSONSourceData(layer, emptyFeatureCollection);
};

import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { EDossiers, setSelectedDossier } from '../../../main-menu/menu.slice';
import { CorrespondentFeature } from '../../../models/correspondents.model';
import { startNewHistory } from '../../../nav-history.slice';
import {
  setSelectedCorrespondent,
  setSelectedCorrespondentId,
} from '../../../state/correspondents/correspondents.slice';
import store from '../../../store';
import { assertIsPointFeature } from '../../../utils/typescript-helpers';
import MapHelpers from '../../map.utils';

function onCorrespondentClick(
  feature: MapboxGeoJSONFeature & CorrespondentFeature
) {
  const { selectedCorrespondentId, correspondents } =
    store.getState().correspondents;

  if (assertIsPointFeature(feature)) {
    MapHelpers.zoomToPoint(feature.geometry.coordinates, 8.5);
  }

  if (selectedCorrespondentId === feature.properties.uid.toString()) {
    return;
  }

  store.dispatch(startNewHistory({ type: 'map' }));
  store.dispatch(setSelectedCorrespondentId(feature.properties.uid.toString()));
  const newSelectedCorrespondent = correspondents?.find(
    (correspondent) => correspondent.uid === feature.properties.uid
  );

  if (newSelectedCorrespondent) {
    window.history.pushState(
      {},
      '',
      `/correspondents/${feature.properties.uid}`
    );
  }

  store.dispatch(setSelectedDossier(EDossiers.CORRESPONDENTS));
  store.dispatch(setSelectedCorrespondent(newSelectedCorrespondent));
}

export default onCorrespondentClick;

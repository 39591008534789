import { Image, StyleSheet, View } from '@react-pdf/renderer';
import RILOGO from '../assets/ri-logo-blue.png';

const styles = StyleSheet.create({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    marginBottom: 35,
    // <Image/> alone seems to be able to be bullied by other components, shrinking it.
    // This 5px of leeway is a hack to make sure it's always the same size.
    // The margin has been shrunk by 5px to compensate.
    height: 35,
  },
  headerImage: {
    height: 30,
    maxHeight: '100%',
    maxWidth: '100%',
  },
});

export default function RIHeader() {
  return (
    <View style={styles.headerContainer}>
      <Image src={RILOGO} style={styles.headerImage} />;
    </View>
  );
}

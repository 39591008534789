import { Alert, CircularProgress } from '@mui/material';

interface FormLoadingMessageProps {
  message: string;
}

function FormLoadingMessage(props: FormLoadingMessageProps) {
  const { message } = props;

  return (
    <Alert
      icon={<CircularProgress size="1.5rem" color="primary" />}
      severity="info"
    >
      {message}
    </Alert>
  );
}

export default FormLoadingMessage;

import { Button, Collapse, Stack } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import { GeoJSONSource } from 'mapbox-gl';
import { useState } from 'react';
import * as yup from 'yup';
import DateRangeModal, {
  EDateRangeModalVariants,
} from '../../common-components/date-range-modal/date-range-modal';
import LongLatInputFields from '../../common-components/form-fields/long-lat-input-fields';
import LoadingButton from '../../common-components/loading-button/loading-button';
import { DMSToDecimal, decimalToDMS } from '../../map/map-controls.utils';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../map/map.utils';
import DateTimeHelpers from '../../utils/date-time-helpers.utils';
import GeoHelper from '../../utils/geo-helpers.utils';
import {
  validateLatitudeDms,
  validateLongitudeDms,
} from '../../utils/validation-helpers.utils';
import { WeatherFormValues } from './weather.model';

const validationSchema = yup.object().shape({
  location: yup.object().shape({
    longitude: validateLongitudeDms(),
    latitude: validateLatitudeDms(),
  }),
  startDate: yup
    .date()
    .required('Required')
    .max(new Date(), 'Start date cannot be in future'),
  endDate: yup
    .date()
    .required('Required')
    .min(yup.ref('startDate'), 'End date cannot be before start date'),
});

export interface WeatherFormProps {
  onSubmit: (values: WeatherFormValues) => void;
  onClear: () => void;
  initialFormValues: WeatherFormValues;
  loading?: boolean;
  success?: boolean;
}

export default function WeatherForm({
  onSubmit,
  onClear,
  initialFormValues,
  loading,
  success,
}: WeatherFormProps) {
  const [showModal, setShowModal] = useState(false);
  const formik = useFormik<WeatherFormValues>({
    enableReinitialize: true,
    initialValues: initialFormValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
    validationSchema,
    validateOnMount: true,
  });
  const onLngLatSelect = (lngLat: mapboxgl.LngLat[]) => {
    formik.setValues({
      ...formik.values,
      location: {
        longitude: decimalToDMS(lngLat[0].lng, 'longitude'),
        latitude: decimalToDMS(lngLat[0].lat, 'latitude'),
      },
    });
    (
      MapHelpers.getSource(MapLayer.WEATHER_PIN) as GeoJSONSource | undefined
    )?.setData(
      GeoHelper.createGeoJSON([
        GeoHelper.createFeaturePoint([lngLat[0].lng, lngLat[0].lat], {
          type: 'dropped-pin',
        }),
      ])
    );
  };

  const formFilled =
    DMSToDecimal(formik.values.location.latitude!) !== 0 ||
    DMSToDecimal(formik.values.location.longitude!) !== 0 ||
    !!formik.values.startDate ||
    !!formik.values.endDate;

  return (
    <FormikProvider value={formik}>
      <Stack spacing={1} useFlexGap>
        <LongLatInputFields
          longitudeInputName="location.longitude"
          latitudeInputName="location.latitude"
          onLngLatSelect={onLngLatSelect}
        />
        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            setShowModal(true);
          }}
        >
          {!formik.values.startDate || !formik.values.endDate
            ? 'Select Date'
            : `${DateTimeHelpers.dateToIsoDate(
                formik.values.startDate
              )} - ${DateTimeHelpers.dateToIsoDate(formik.values.endDate)}`}
        </Button>

        <DateRangeModal
          title="Date Range"
          visible={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          onSelect={(values) => {
            setShowModal(false);
            formik.setValues({
              ...formik.values,
              startDate: values.dateFrom.toDate().toISOString(),
              endDate: values.dateTo.toDate().toISOString(),
            });
          }}
          loading={false}
          success={false}
          error={false}
          initialStart={new Date().toISOString()}
          initialEnd={new Date().toISOString()}
          variant={EDateRangeModalVariants.FULL_RANGE}
        />
        <LoadingButton
          disabled={!formik.isValid || !formFilled}
          type="submit"
          onClick={() => onSubmit(formik.values)}
          variant="contained"
          loading={!!loading}
          success={!!success}
        >
          Submit
        </LoadingButton>
        <Collapse in={formFilled}>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              formik.resetForm();
              setShowModal(false);
              onClear();
            }}
          >
            Clear
          </Button>
        </Collapse>
      </Stack>
    </FormikProvider>
  );
}

WeatherForm.defaultProps = {
  loading: false,
  success: false,
};

/* eslint-disable react/jsx-props-no-spreading */
import {
  arrow,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Slider,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import { PropsWithChildren, useRef, useState } from 'react';
import { useMobile } from '../../hooks';

const marks = [
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
];

const CustomSlider = styled(Slider)(() => ({
  color: 'primary',
  height: 2,
  padding: '1rem 0',
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    top: '1.45rem',
    backgroundColor: 'unset',
  },
}));

interface ListSliderItemProps<T> {
  item: T;
  hasValue: number;
  onClick: (title: string, value: number) => void;
}

function ListSliderItem<T extends { title: string }>({
  item,
  hasValue,
  onClick,
}: ListSliderItemProps<T>) {
  const { title } = item;
  return (
    <ListItem
      sx={{ py: '0.3rem', display: 'flex' }}
      disablePadding
      disableGutters
    >
      <Typography sx={{ flex: 2 }}>{title}</Typography>
      <CustomSlider
        sx={{ p: '0.5rem', flex: 3 }}
        step={1}
        marks={marks}
        min={0}
        max={5}
        value={hasValue}
        aria-label="Threat Intensity"
        // @ts-ignore
        onChange={(e) => onClick(title, e?.target?.value)}
        valueLabelDisplay="on"
      />
    </ListItem>
  );
}

interface ListSliderProps<T> {
  items: T[];
  onSubmit: (items: number[]) => void;
  selectedItems: any[];
  setSelectedItems: (items: any[]) => void;
  buttonText: string | JSX.Element;
}

function ListSlider<T extends { id: number; title: string }>({
  items,
  onSubmit,
  selectedItems,
  setSelectedItems,
  buttonText,
}: PropsWithChildren<ListSliderProps<T>>) {
  const [open, setOpen] = useState(false);

  const isMobile = useMobile();
  const theme = useTheme();

  // Popover setup
  const arrowRef = useRef(null);
  const { x, y, strategy, refs, context } = useFloating({
    open,
    onOpenChange: setOpen,
    placement: 'bottom',
    middleware: [
      offset({ crossAxis: isMobile ? 0 : 90, mainAxis: 10 }),
      size({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth + 60}px`,
            maxHeight: `${availableHeight - 40}px`,
          });
        },
      }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const onClick = (title: string, newValue: number) => {
    const updatedItems = selectedItems.map((item) => {
      if (item.title === title) {
        return { ...item, value: newValue };
      }
      return item;
    });

    setSelectedItems(updatedItems);
  };

  return (
    <>
      <IconButton
        className={classNames('filter-button', 'filterItem', {
          mobile: isMobile,
        })}
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'background.paper',
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '40px',
          padding: '6px 24px 6px 16px',
          fontSize: '16px',
          fontWeight: '400',
          color: theme.palette.text.primary,
          bgcolor: open ? 'primary.light' : '',
          borderRadius: '0',
          width: '100%',
        }}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {buttonText}
        {open ? <ArrowDropDown /> : <ArrowRight />}
      </IconButton>
      {open && (
        <Box
          className="popoverContainer"
          bgcolor={theme.palette.primary.light}
          ref={refs.setFloating}
          sx={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            borderRadius: '28px',
            alignItems: 'center',
          }}
          {...getFloatingProps()}
        >
          <Alert severity="info">
            Less than or equal to requested value. 0 indicates no filter.
          </Alert>
          <List
            sx={{ p: '0.8rem 1rem 0.8rem 0.5rem', width: '95%' }}
            className="listSliderList"
          >
            {items.map((item) => (
              <ListSliderItem
                key={item.id}
                item={item}
                onClick={onClick}
                hasValue={
                  selectedItems.find((i) => i.title === item.title).value
                }
              />
            ))}
          </List>
          <Button
            className="listSliderButton listSliderSubmit"
            sx={{
              borderRadius: '0 0 28px 28px',
              borderTop: '1px solid',
              borderTopColor: 'background.paper',
              inlineSize: 'stretch',
            }}
            onClick={() => {
              onSubmit(selectedItems);
              setOpen(false);
            }}
          >
            Submit
          </Button>
        </Box>
      )}
    </>
  );
}

export default ListSlider;

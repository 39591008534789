import { Box, Button, ListItem, Typography } from '@mui/material';
import { useState } from 'react';
import { GeollectSanction, NorthStandardSanction } from '../sanction.model';
import './sanction-item.scss';

interface Props {
  sanction: GeollectSanction | NorthStandardSanction;
}

function decodeHtmlEntities(htmlString: string) {
  const tempElement = document.createElement('textarea');
  tempElement.innerHTML = htmlString;
  return tempElement.value.replace(/<[^>]*>/g, '');
}

function convertDate(date: string) {
  let parsedDate;
  // check if date is in format of dd/mm/yyyy
  if (date.includes('/')) {
    const [day, month, year] = date.split('/');
    parsedDate = new Date(`${year}-${month}-${day}`);
  } else {
    parsedDate = new Date(date);
  }

  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };

  return parsedDate.toLocaleDateString('en-GB', options);
}

function SanctionItem({ sanction }: Props) {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const showMoreInfoClick = () => {
    setShowMoreInfo(!showMoreInfo);
  };
  const openLinkClick = () => {
    if ('description' in sanction) {
      if (sanction.link) window.open(sanction.link);
    } else if (sanction.url) window.open(sanction.url);
  };

  const expandLinkButtonStyle = {
    backgroundColor: 'primary.main',
    color: 'secondary.light',
    textDecoration: 'underline',
  };

  return (
    // role is not redundant since list-style: none removes the 'semanticity' of a list
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <ListItem
      data-testid="sanction-item"
      sx={{ display: 'flex', width: '100%' }}
    >
      <div style={{ width: '100%' }}>
        {!showMoreInfo ? (
          <div style={{ width: '100%' }}>
            {'description' in sanction ? (
              <Typography variant="h4" sx={{ fontSize: '1rem' }}>
                {sanction.title} - {convertDate(sanction.date)}
              </Typography>
            ) : (
              <Typography variant="h4" sx={{ fontSize: '1rem' }}>
                {sanction.title} - {convertDate(sanction.date)}
              </Typography>
            )}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                sx={expandLinkButtonStyle}
                onClick={() => openLinkClick()}
              >
                Open Link
              </Button>
              <Button sx={expandLinkButtonStyle} onClick={showMoreInfoClick}>
                Expand
              </Button>
            </div>
          </div>
        ) : (
          <Box
            sx={{
              width: '100%',
              overflow: 'auto',
              paddingRight: '1em',
            }}
          >
            <Typography variant="h4" sx={{ fontSize: '1rem' }}>
              {sanction.title}
            </Typography>
            {'description' in sanction ? (
              <>
                <Typography variant="body2">
                  {convertDate(sanction.date)}
                </Typography>
                <Typography sx={{ maxWidth: '90%' }} variant="body2">
                  {sanction.description}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="body2">
                  {convertDate(sanction.date)}
                </Typography>
                <Typography sx={{ maxWidth: '90%' }} variant="body2">
                  {decodeHtmlEntities(sanction.text)}
                </Typography>
              </>
            )}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Button
                sx={expandLinkButtonStyle}
                onClick={() => openLinkClick()}
              >
                Open Link
              </Button>
              <Button sx={expandLinkButtonStyle} onClick={showMoreInfoClick}>
                Collapse
              </Button>
            </div>
          </Box>
        )}
      </div>
    </ListItem>
  );
}

export default SanctionItem;

import { Box, Typography } from '@mui/material';
import cn from 'classnames';
import { useEffect } from 'react';
import { useAppSelector, useMobile } from '../../hooks';
import { DataItem } from '../../hooks/menu-data/menu-data';
import useMenuData from '../../hooks/menu-data/useMenuData';
import { EMenuItems } from '../menu.slice';
import useMainMenuAccessConfig from '../use-main-menu-access-config';
import useMainMenuLayerConfig from '../use-main-menu-layer-config';
import SideMenuItem from './side-menu-item/side-menu-item';
import './side-menu.scss';

type Props = {
  section: {
    title: string;
    items: DataItem[];
  };
  sectionNumber: number;
  itemSelected: (el: DataItem) => void;
};

function SideMenuSection({ section, sectionNumber, itemSelected }: Props) {
  const menuData = useMenuData();

  const menuOpen = useAppSelector((state) => state.menu.menuOpen);

  const selectedOption = useAppSelector((state) => state.menu.selectedOption);
  const mainMenuLayerConfig = useMainMenuLayerConfig();
  const mainMenuAccessConfig = useMainMenuAccessConfig();

  const isMobile = useMobile();

  const showMenuItem = (el: DataItem) => {
    const { hasAccess } = mainMenuAccessConfig[el.selected];

    if (!hasAccess && el.selected === EMenuItems.HISTORY) {
      return null;
    }

    return (
      <SideMenuItem
        key={el.selected}
        selected={selectedOption === el.selected}
        iconName={el.icon}
        itemName={el.selected}
        menuOpen={menuOpen}
        layerToggleProps={mainMenuLayerConfig[el.selected]}
        itemDisabled={!hasAccess}
        onClick={() => itemSelected(el)}
      />
    );
  };

  useEffect(() => {
    const sideMenu = document.getElementById('side-menu');
    if (!isMobile) {
      sideMenu?.classList.remove('side-menu-closed');
    }
  }, []);

  return (
    <Box key={section.title}>
      <Typography
        align="left"
        variant="body2"
        fontWeight="500"
        className={cn({ hidden: !menuOpen })}
        sx={{
          m: menuOpen ? '0.6rem 1rem' : '',
          whiteSpace: 'nowrap',
        }}
      >
        {section.title}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
        {section.items.map((item) => showMenuItem(item))}
      </Box>

      <Box
        sx={{
          borderBottom:
            sectionNumber + 1 === menuData.length ? '' : '1px solid',
          borderColor: menuOpen ? 'background.paper' : 'inherit',
          m: menuOpen ? '0.1rem 1rem' : '0.1rem 1.75rem',
        }}
      />
    </Box>
  );
}

export default SideMenuSection;

/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import ListPicker from '../../../../common-components/list-picker/list-picker';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { ExpandedId } from '../../../../models/risk_intelligence.model';
import {
  calcDisplayedText,
  incidentRegionCache,
} from '../../../../utils/incidents-helpers';
import { setIncidentFilters } from '../../incident-panel.slice';

function RegionsFilter() {
  const { incidentRegions } = useAppSelector((store) => store.incidents);
  const { filters } = useAppSelector((store) => store.incidentsPanel);
  const dispatch = useAppDispatch();

  const [selectedRegions, setSelectedRegions] = useState<number[]>([]);

  useEffect(() => {
    setSelectedRegions(filters.regions);
  }, [filters.regions]);

  const onSubmit = () => {
    dispatch(
      setIncidentFilters({
        regions: selectedRegions,
      })
    );
  };

  return (
    <ListPicker
      onSubmit={onSubmit}
      items={(incidentRegions as ExpandedId[]) || []}
      selectedItems={selectedRegions}
      setSelectedItems={setSelectedRegions}
      buttonText={calcDisplayedText(
        filters.regions,
        'Regions',
        incidentRegionCache
      )}
    />
  );
}
export default RegionsFilter;

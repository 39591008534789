import { Expression } from 'mapbox-gl';
import { GENERIC_VESSEL } from '../../../utils/vessel-constants.utils';
import prepareVesselStylingData from '../../../utils/vessel-styling.utils';
import MapHelpers from '../../map.utils';
import MapLayerVisibility from '../map-layer-visibility.enum';

function addAnyVesselLayer(
  layer: string,
  metadata: object = {},
  visibility: MapLayerVisibility = MapLayerVisibility.NOT_VISIBLE,
  iconImage: string | Expression = '',
  iconColour: string | Expression = ''
) {
  const { size } = prepareVesselStylingData();

  MapHelpers.addLayer({
    ...GENERIC_VESSEL,
    id: layer,
    source: layer,
    metadata,
    layout: {
      ...GENERIC_VESSEL.layout,
      visibility,
      'icon-image': iconImage,
      'icon-size': size,
    },
    paint: {
      ...GENERIC_VESSEL.paint,
      'icon-color': iconColour,
    },
  });
}

export default addAnyVesselLayer;

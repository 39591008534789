import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import MapLayer from '../map-layer.enum';

function onIncidentClusterClick(
  map: mapboxgl.Map,
  incidentCluster: GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
) {
  const clusterId = incidentCluster.properties.cluster_id;
  (map.getSource(MapLayer.INCIDENTS) as GeoJSONSource).getClusterExpansionZoom(
    clusterId,
    (err, zoom) => {
      if (err) return;
      map.easeTo({
        center: incidentCluster.geometry.coordinates as [number, number],
        zoom: zoom + 0.5,
      });
    }
  );
}

export default onIncidentClusterClick;

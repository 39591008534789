import { Box } from '@mui/material';
import SimpleSearchBar from '../../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import { setSearchFilterValue } from '../routes-panel.slice';

function RoutesPanelFilterBlock() {
  const { searchFilterValue } = useAppSelector((state) => state.routesPanel);

  const dispatch = useAppDispatch();

  return (
    <div className="routes-panel-filter-block">
      <Box sx={{ display: 'flex', padding: '12px 20px' }}>
        <SimpleSearchBar
          handleChange={(value: string) =>
            dispatch(setSearchFilterValue(value))
          }
          filterValue={searchFilterValue}
          placeholder="Search Routes"
          classNames="routes-panel-search-bar"
        />
      </Box>
    </div>
  );
}

export default RoutesPanelFilterBlock;

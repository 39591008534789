import { Port } from '../../../maritime-menu-options/world-ports-panel/world-ports.model';

function portInfoPopUpContent(
  feature: mapboxgl.MapboxGeoJSONFeature
): { label: string; value: string }[] | null {
  if (feature) {
    const properties = feature.properties as Port;
    const { NAME, WPI, UNLOCODE } = properties;

    const fields = [
      {
        label: 'Port Name',
        value: NAME,
      },
      {
        label: 'World Port Index No.',
        value: WPI,
      },
      {
        label: 'Country',
        value: properties.COUNTRY,
      },
    ];

    if (UNLOCODE) {
      fields.push({
        label: 'UN/LOCODE',
        value: UNLOCODE,
      });
    }
    return fields;
  }
  return null;
}

export default portInfoPopUpContent;

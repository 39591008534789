import { Circle, Svg } from '@react-pdf/renderer';
import { translateThreatLevelToColour } from '../dossiers_and_panels/threat-assessments/threat-assessment-helpers';
import { Threat } from '../dossiers_and_panels/threat-assessments/threat-assessment.model';

function TALevelChip({ level }: { level: Threat['level'] }) {
  return (
    <Svg width={10} height={10} style={{ marginRight: 5 }}>
      <Circle cx={5} cy={5} r={3} fill={translateThreatLevelToColour(level)} />
    </Svg>
  );
}

export default TALevelChip;

/* eslint-disable no-underscore-dangle */
/**
 * Adpated from solution provided in this github issue
 * https://github.com/mapbox/mapbox-gl-draw/issues/874
 */
import { IconLookup, icon } from '@fortawesome/fontawesome-svg-core';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import MapHelpers from '../map/map.utils';

interface ExtendDrawBarOptions {
  draw: MapboxDraw;
  buttons: {
    action(): void;
    title: string;
    faIcon: IconLookup;
    classes?: string[];
    iconWidth?: number;
    iconHeight?: number;
    iconStrokeWidth?: number;
    _elButton?: HTMLElement; // private, used when removeButton is called
  }[];
}

export default class ExtendDrawBar {
  private draw: MapboxDraw;

  private map?: mapboxgl.Map;

  private buttons;

  private onAddOrig;

  private onRemoveOrig;

  // public for test reasons
  public elContainer?: HTMLElement;

  constructor(opt: ExtendDrawBarOptions) {
    this.draw = opt.draw;
    this.buttons = opt.buttons || [];
    this.onAddOrig = opt.draw.onAdd;
    this.onRemoveOrig = opt.draw.onRemove;
  }

  onAdd(map: mapboxgl.Map) {
    this.map = map;
    // Since mapbox-gl-draw returns a HTML Element instead of anything react-y, we are forced to continue in document.createElement etc.
    this.elContainer = this.onAddOrig(map);
    this.buttons.forEach((b) => {
      this.addButton(b);
    });
    return this.elContainer;
  }

  onRemove() {
    const map = MapHelpers.getMapInstance();
    this.buttons.forEach((b) => {
      this.removeButton(b);
    });
    this.onRemoveOrig(map);
  }

  addButton(opt: ExtendDrawBarOptions['buttons'][0]) {
    const elButton = document.createElement('button');
    elButton.title = opt.title;
    elButton.classList.add(`mapbox-gl-draw_ctrl-draw-btn`);
    if (opt.classes instanceof Array) {
      opt.classes.forEach((c) => {
        elButton.classList.add(c);
      });
    }
    // Since we cannot use <FontAwesomeIcon> we need to use the svg library that lives underneath
    const currTheme = document
      .querySelector('html')
      ?.getAttribute('data-theme');
    const fillColour = currTheme === 'RISK_INTELLIGENCE' ? '#fff' : '#000';
    const x = icon(opt.faIcon);
    // set width and height to match the roughly 20x20 of the mapbox icons
    let addWidthHeight = x.html[0]
      .replace(
        /<svg/,
        `<svg width="${opt.iconWidth || 20}" height="${opt.iconHeight || 20}"`
      )
      .replace(/fill="currentColor"/, `fill="${fillColour}"`);

    if (opt.iconStrokeWidth) {
      addWidthHeight = addWidthHeight.replace(
        /<path/,
        `<path stroke="${fillColour}" stroke-width="${opt.iconStrokeWidth}"`
      );
    }

    // use this format (which matches mapbox-gl-draw) instead of document.appendChild so the classNames are used correctly
    elButton.style.backgroundImage = `url(data:image/svg+xml;utf8,${encodeURIComponent(
      addWidthHeight
    )})`;
    elButton.addEventListener('click', opt.action);
    this.elContainer?.appendChild(elButton);
    // eslint-disable-next-line no-param-reassign
    opt._elButton = elButton;
  }

  // eslint-disable-next-line class-methods-use-this
  removeButton(opt: ExtendDrawBarOptions['buttons'][0]) {
    opt._elButton?.removeEventListener('click', opt.action);
    opt._elButton?.remove();
  }
}

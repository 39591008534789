import favIcon from './assets/NS-Favicon.png';
import logo from './assets/globeview.png';
import MapStyle from './map/map-style';

export interface ColourScheme {
  sarTint: string;
  areaTint: string;
  drawActive: string;
  drawInactive: string;
  drawStatic: string;
}

// TODO: Add NS colours
const northStandardColours: ColourScheme = {
  sarTint: '#007BB8',
  areaTint: '#ffac1c',
  drawActive: '#fbb03b',
  drawInactive: '#3bb2d0',
  drawStatic: '#404040',
};

interface ThemeItem {
  image: string;
  title: string;
  icon: string;
  mapboxStyle: string;
  colourScheme: ColourScheme;
}

export const nsTheme: ThemeItem = {
  image: logo,
  title: 'GlobeView',
  icon: favIcon,
  mapboxStyle: MapStyle.DEFAULT,
  colourScheme: northStandardColours,
};

export const setFavicon = () => {
  let favicon: HTMLLinkElement | null =
    document.querySelector("link[rel~='icon']");
  if (!favicon) {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(favicon);
  }
  favicon.href = favIcon;
};

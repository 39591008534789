import { wrapRequest } from './base';

export const getDidYouKnow = async (): Promise<any> =>
  wrapRequest('get', 'geonius', '/users/did-you-know', {});

export const updateDidYouKnow = async (id: string): Promise<any> =>
  wrapRequest('patch', 'geonius', `/users/did-you-know`, {
    body: {
      last_seen_dyk: id,
    },
  });

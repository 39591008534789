import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackOneButton } from '../../../common-components/breadcrumb/breadcrumb';
import TabWithTooltip from '../../../common-components/tabs/TabWithTooltip';
import DocumentsTab from '../../../dossiers_and_panels/documents/documents-tab';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import useAccessControl from '../../../hooks/access-control/useAccessControl';
import {
  EDossiers,
  closeMenus,
  setSelectedDossier,
} from '../../../main-menu/menu.slice';
import { EntityType } from '../../../models/document';
import { addHistoryItem } from '../../../nav-history.slice';
import { setSelectedCountryId } from '../../../state/countries/countries.slice';
import { CONTACTS, Details, EMERGENCY } from '../world-ports-panel.slice';
import { ExpandedRIPort, Port } from '../world-ports.model';
import RIDetails from './ri-details/ri-details';
import RITab from './ri-tab/ri-tab';
import './specific-port.scss';
import WPITab from './wpi-tab/wpi-tab';

interface SpecificPortProps {
  port: Port;
}

function SpecificPort({ port }: SpecificPortProps) {
  const [selectedTab, setSelectedTab] = useState('1');
  const [contacts, setContacts] = useState(false);
  const [emergency, setEmergency] = useState(false);
  const [foreignFlags, setForeignFlags] = useState(false);
  const navigate = useNavigate();
  const { secondaryMenuOpen } = useAppSelector((state) => state.menu);
  const { canAccessDocuments } = useAccessControl();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (selectedTab === '2' && !port.ri) {
      setSelectedTab('1');
    }
    setContacts(false);
    setEmergency(false);
    setForeignFlags(false);
  }, [port]);

  const setTab = (tab: typeof EMERGENCY | typeof CONTACTS) => {
    if (tab === EMERGENCY) {
      setEmergency(!emergency);
    } else if (tab === CONTACTS) {
      setContacts(!contacts);
    }
  };

  const goToCountry = (
    countryId: number,
    dispatch: ReturnType<typeof useAppDispatch>
  ) => {
    dispatch(setSelectedDossier(EDossiers.COUNTRY));
    dispatch(setSelectedCountryId(countryId));
    dispatch(closeMenus());
  };

  const portContacts: Details = {
    title: CONTACTS,
    name: port.NAME,
    items: port && port.ri && port.ri.contacts ? port.ri?.contacts : null,
  };

  const portEmergency: Details = {
    title: EMERGENCY,
    name: port.NAME,
    items: port?.ri?.securityEmergencyUnits
      ? port.ri?.securityEmergencyUnits
      : null,
  };

  const dispatch = useAppDispatch();

  const detachedStyling = {
    marginTop: '0.45em',
  };

  const nonDetachedStyling = {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1.2em',
    alignItems: 'center',
    marginBottom: '0.5em',
  };

  return (
    <div data-testid="specific-port-container">
      {contacts && (
        <RIDetails details={portContacts} setTab={setTab} type={CONTACTS} />
      )}
      {emergency && (
        <RIDetails details={portEmergency} setTab={setTab} type={EMERGENCY} />
      )}
      {!contacts && !emergency && !foreignFlags && (
        <>
          <div className="specific-port-header">
            <div className="specific-port-title">
              <Box
                sx={secondaryMenuOpen ? detachedStyling : nonDetachedStyling}
              >
                {!secondaryMenuOpen && <BackOneButton />}
                <Typography fontWeight={500} data-testid="dossier-title">
                  {port.NAME}
                </Typography>
              </Box>
              {port.ri && (
                <Button
                  sx={{ color: 'background.button' }}
                  data-testid="dossier-title"
                  className="specific-port-country"
                  variant="text"
                  onClick={() => {
                    dispatch(
                      addHistoryItem({ type: 'port', properties: port })
                    );
                    goToCountry(
                      (port as Port & { ri: ExpandedRIPort }).ri.country.id,
                      dispatch
                    );
                    navigate(
                      `/countries/${
                        (port as Port & { ri: ExpandedRIPort }).ri.country.id
                      }`
                    );
                  }}
                >
                  {(port as Port & { ri: ExpandedRIPort }).ri.country.title}
                </Button>
              )}
            </div>
          </div>
          <TabContext value={selectedTab}>
            <TabList onChange={handleChange}>
              <TabWithTooltip
                value="1"
                label="World Port Index"
                sx={{
                  flex: '1',
                }}
              />
              {port.ri && (
                <TabWithTooltip
                  value="2"
                  label="Risk Intelligence"
                  sx={{
                    flex: '1',
                  }}
                />
              )}
              <TabWithTooltip
                value="3"
                label="Documents"
                disabled={!canAccessDocuments}
                sx={{
                  flex: '1',
                }}
              />
            </TabList>

            <TabPanel value="1" sx={{ padding: '0px' }}>
              <WPITab port={port} />
            </TabPanel>
            {port.ri && (
              <TabPanel value="2">
                <RITab port={port} setTab={setTab} />
              </TabPanel>
            )}
            {canAccessDocuments && (
              <TabPanel value="3">
                <DocumentsTab
                  entityId={String(port.WPI)}
                  entityType={EntityType.port}
                  entityName={port.NAME}
                />
              </TabPanel>
            )}
          </TabContext>
        </>
      )}
    </div>
  );
}

export default SpecificPort;

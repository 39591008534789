/* eslint-disable import/prefer-default-export */
import { eachDayOfInterval, endOfDay } from 'date-fns';
import chunk from 'lodash/chunk';
import { DMSToDecimal } from '../map/map-controls.utils';
import {
  WeatherFormValues,
  WeatherResponse,
} from '../maritime-menu-options/weather-panel/weather.model';
import { wrapRequest } from './base';

/**
 * response promises should retain their order, so should be fine to merge in order
 * @param responses
 */
const mergeWeatherResponses = (responses: WeatherResponse[]) => {
  if (responses.length === 0) {
    throw Error('No responses to merge');
  }
  if (responses.length === 1) {
    return responses[0];
  }
  const mergedResponse = responses.reduce((acc, curr, index) => {
    if (index === responses.length - 1) {
      // start dates will be obtained from responses[0], need to set end dates from responses[-1]
      acc.tidal_extremes.meta.end = curr.tidal_extremes.meta.end;
      acc.weather.meta.end = curr.weather.meta.end;
      // elevation is static
      // astronomy is only has start date
    }
    acc.astronomy.data.push(...curr.astronomy.data);
    // elevation is static
    acc.weather.hours.push(...curr.weather.hours);
    acc.tidal_extremes.data.push(...curr.tidal_extremes.data);
    if (curr.astronomy.errors) {
      acc.astronomy.errors = curr.astronomy.errors;
    }
    if (curr.weather.errors) {
      acc.weather.errors = curr.weather.errors;
    }
    if (curr.tidal_extremes.errors) {
      acc.tidal_extremes.errors = curr.tidal_extremes.errors;
    }
    if (curr.elevation.errors) {
      acc.elevation.errors = curr.elevation.errors;
    }
    return acc;
  });
  return mergedResponse;
};

/**
 * Call backend to get weather data.
 * Stormglass API only allows 10 days at a time, so we need to chunk the date range, then merge the results back together.
 * These requests are done in parallel, so should only be slightly slower than a single request.
 * @param formValues
 * @returns
 */
export const fetchWeather = async (
  formValues: WeatherFormValues
): Promise<WeatherResponse> => {
  if (!formValues.location.latitude || !formValues.location.longitude) {
    throw new Error('Missing location');
  }
  if (!formValues.startDate || !formValues.endDate) {
    throw new Error('Missing date range');
  }

  // Stormglass API only does dateRange of 10 days
  const days = eachDayOfInterval({
    start: new Date(formValues.startDate),
    end: new Date(formValues.endDate),
  });
  const dateRanges = chunk(days, 9); // 9 days just to be safe

  const requestPromises = dateRanges.map((dateRange) => {
    const result = wrapRequest<WeatherResponse>(
      'get',
      'geonius',
      '/weather-data',
      {
        queryStringParameters: {
          longitude: DMSToDecimal(formValues.location.longitude!), // typescript being odd, we know it's not undefined
          latitude: DMSToDecimal(formValues.location.latitude!),
          timestamp_after: dateRange[0].toISOString(),
          timestamp_before: endOfDay(
            dateRange[dateRange.length - 1]
          ).toISOString(),
        },
      }
    );
    return result;
  });

  const results = await Promise.all(requestPromises);

  const mergedResult = mergeWeatherResponses(results);
  // API errors from Stormglass should be checked
  Object.values(mergedResult).some((value) => {
    if (value.errors) {
      throw new Error(value.errors.key);
    }
    return false;
  });
  return mergedResult;
};

import { GeoJSONSource } from 'mapbox-gl';
import { Vessel } from '../../../models/vessel.model';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

function setVesselFocusRing(layerId: MapLayer | string, vessels: Vessel[]) {
  const focusRingSource = MapHelpers.getSource(layerId) as
    | GeoJSONSource
    | undefined;

  const vesselPositionFeatures: GeoJSON.Feature[] = vessels.map((vessel) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [vessel.longitude, vessel.latitude],
    },
    properties: {},
  }));

  if (!focusRingSource) {
    MapHelpers.addSource(layerId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: vesselPositionFeatures,
      },
    });
  } else {
    focusRingSource.setData({
      type: 'FeatureCollection',
      features: vesselPositionFeatures,
    });
  }

  if (!MapHelpers.getLayer(layerId)) {
    MapHelpers.addLayer({
      id: layerId,
      type: 'symbol',
      source: layerId,
      layout: {
        'icon-image': layerId, // reference the image
        'icon-allow-overlap': true,
        'icon-size': [
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#interpolate
          'interpolate',
          ['linear'],
          ['zoom'],
          // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#camera-expressions
          1,
          0.1,
          10,
          0.1,
          20,
          0.05,
        ],
      },
    });
  }
}

export default setVesselFocusRing;

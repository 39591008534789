/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ThreatAssessmentInfo = {
  id: number | null;
  title: string;
};

type ThreatAssessmentStateType = {
  selectedEntityThreatAssessmentInfo: ThreatAssessmentInfo;
  loading: boolean;
  error: boolean;
};

export const INITAL_THREAT_ASSESSMENT_STATE: ThreatAssessmentStateType = {
  selectedEntityThreatAssessmentInfo: { id: null, title: '' },
  loading: false,
  error: false,
};

const ThreatAssessmentSlice = createSlice({
  name: 'threat-assessment',
  initialState: INITAL_THREAT_ASSESSMENT_STATE,
  reducers: {
    setSelectedEntityThreatAssessmentInfo: (
      state,
      action: PayloadAction<ThreatAssessmentInfo>
    ) => {
      state.selectedEntityThreatAssessmentInfo.id = action.payload.id;
      state.selectedEntityThreatAssessmentInfo.title = action.payload.title;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const { setSelectedEntityThreatAssessmentInfo, setLoading, setError } =
  ThreatAssessmentSlice.actions;

export default ThreatAssessmentSlice.reducer;

import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import MapLayer from '../map-layer.enum';

function onRoutePortClusterClick(
  map: mapboxgl.Map,
  portCluster: GeoJSON.Feature<GeoJSON.Point, Record<string, any>>
) {
  const clusterId = portCluster.properties?.cluster_id;
  (
    map.getSource(MapLayer.ROUTES_PORTS) as GeoJSONSource
  ).getClusterExpansionZoom(clusterId, (err, zoom) => {
    if (err) return;

    map.easeTo({
      center: portCluster.geometry.coordinates as [number, number],
      zoom,
    });
  });
}

export default onRoutePortClusterClick;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useAppDispatch } from '../../../hooks';
import {
  MergedHistoricVesselPoint,
  setSelectedMergedPointIndex,
} from '../history-panel.slice';
import AISMergedPointsController from './history-ais-merged-points-controller.utils';
import './history-ais-merged-points.scss';
import { dateToUtcTimeString } from '../../../utils/date-time-helpers.utils';

/* eslint-disable jsx-a11y/click-events-have-key-events */
interface HistoryAISMergedPointResultProps {
  point: MergedHistoricVesselPoint;
  selected: boolean;
}

function HistoryAISMergedPointResult(props: HistoryAISMergedPointResultProps) {
  const { point, selected } = props;
  const { mmsi, features, startTimestamp, endTimestamp, index } = point;

  const dispatch = useAppDispatch();

  const onResultClick = () => {
    const selectedPoint =
      AISMergedPointsController.getMergedPointByIndex(index);

    if (selectedPoint) {
      AISMergedPointsController.onFeatureSelect(selectedPoint);
      dispatch(setSelectedMergedPointIndex(index));
    }
  };

  return (
    <ListItem disableGutters disablePadding>
      <ListItemButton
        onClick={onResultClick}
        selected={selected}
        autoFocus={selected}
      >
        <ListItemText
          primary={
            <>
              <Typography variant="body2">
                <b>MMSI</b> {mmsi}
              </Typography>
              <Typography variant="body2">
                <b>Points</b> {features.length}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography variant="body2">
                <br />
                <b>From</b> {dateToUtcTimeString(new Date(startTimestamp))}{' '}
                &nbsp;
              </Typography>
              <Typography variant="body2">
                <b>To</b> {dateToUtcTimeString(new Date(endTimestamp))}
              </Typography>
            </>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export default HistoryAISMergedPointResult;

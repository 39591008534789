import { useMemo } from 'react';
import useAccessControl from '../access-control/useAccessControl';
import { createMenuItems } from './menu-data';

const useMenuData = () => {
  const privileges = useAccessControl();
  const menuData = useMemo(() => createMenuItems(privileges), [privileges]);

  return menuData;
};

export default useMenuData;

import { useAppSelector } from '../../hooks';
import VesselHistoryController from './vessel-history-controller.utils';

function useVesselHistoryLayerList() {
  const { historicVesselPoints } = useAppSelector(
    (state) => state.historyPanel
  );

  return VesselHistoryController.getLayerListSources(historicVesselPoints);
}

export default useVesselHistoryLayerList;

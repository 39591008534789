import React, { useCallback, useEffect } from 'react';
import { getUserProperties } from '../../api';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { UserPreferencesBaseMap } from '../../utils/user.enum';
import { applyUserPreferences } from '../../utils/user.utils';
import ApplySetupBar from '../apply-setup-bar/apply-setup-bar';
import {
  setApplySetupBarError,
  setApplySetupBarNumber,
} from '../apply-setup-bar/apply-setup-bar.slice';
import {
  DEFAULT_USER_PREFERENCES,
  setUserPreferences,
  setUserPreferencesLoading,
} from './user-preferences.slice';

type UserPreferencesProps = {
  children: React.ReactNode;
};

function UserPreferences({ children }: UserPreferencesProps) {
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);
  const userPreferences = useAppSelector(
    (state) => state.userPreferences.userPreferences
  );
  const userPreferencesLoading = useAppSelector(
    (state) => state.userPreferences.userPreferencesLoading
  );

  const cognitoId = useAppSelector((state) => state.user.idToken?.sub);
  const dispatch = useAppDispatch();

  const loadUserPreferences = useCallback(async () => {
    try {
      if (cognitoId) {
        const { user_preferences: preferences } = await getUserProperties(
          cognitoId
        );
        if (!preferences) {
          throw new Error('No preferences found for user.');
        }

        dispatch(
          setUserPreferences({
            ...preferences,
            zoomToIncident:
              preferences?.zoomToIncident !== undefined
                ? preferences.zoomToIncident
                : true,
            basemap: preferences?.basemap ?? UserPreferencesBaseMap.DEFAULT,
          })
        );
      } else {
        dispatch(setUserPreferencesLoading(false));
      }
    } catch (error) {
      dispatch(setApplySetupBarError(true));
      dispatch(setApplySetupBarNumber(0));
      dispatch(setUserPreferences(DEFAULT_USER_PREFERENCES));
      dispatch(setUserPreferencesLoading(false));
    }
  }, [cognitoId]);

  useEffect(() => {
    dispatch(setUserPreferencesLoading(true));
    loadUserPreferences();
  }, [loadUserPreferences]);

  useEffect(() => {
    if (userPreferencesLoading && mapInitialised) {
      applyUserPreferences(userPreferences).then(() => {
        dispatch(setUserPreferencesLoading(false));
      });
    }
  }, [userPreferencesLoading, mapInitialised, userPreferences]);

  return (
    <>
      {userPreferencesLoading && <ApplySetupBar />}
      {children}
    </>
  );
}
export default UserPreferences;

import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setSearchFilterValue } from './boundaries-panel.slice';

function BoundariesPanelFilterBlock() {
  const { searchFilterValue } = useAppSelector(
    (state) => state.boundariesPanel
  );

  const dispatch = useAppDispatch();

  return (
    <SimpleSearchBar
      handleChange={(value: string) => dispatch(setSearchFilterValue(value))}
      filterValue={searchFilterValue}
      placeholder="Search Boundaries"
    />
  );
}

export default BoundariesPanelFilterBlock;

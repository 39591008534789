import { S3ProviderListOutputItem } from '@aws-amplify/storage';

export const serializeS3ProviderListOutputItem = (
  item: S3ProviderListOutputItem
) => ({ ...item, lastModified: item.lastModified?.valueOf()! });

export interface EntityDocument {
  document_id?: string;
  filename: string;
  // ISO 8601 date format
  date_uploaded: string;
  // ISO 8601 date format
  file_date: string;
  description: string;
  s3_location: string;
  entity_id: string;
  entity_type: string;
  cognito_id: string;
}

export enum EntityType {
  port = 'port',
  country = 'country',
  drawing = 'drawing',
  riArea = 'ri-area',
}

// Wrapper for Amplify Document Type - allow us to store documents in redux
export interface IDocument
  extends Omit<S3ProviderListOutputItem, 'lastModified'> {
  lastModified: Date | number;
  metadata?: EntityDocument;
}

export interface DocumentWithMeta extends IDocument {
  metadata: EntityDocument;
}

export enum StorageLevel {
  PRIVATE = 'private',
}

export const StorageDirectory = {
  [StorageLevel.PRIVATE]: 'documents/private/',
};

export enum FileOwner {
  PRIVATE = 'user',
}

export const StorageLevelToFileOwner: Record<StorageLevel, FileOwner> = {
  [StorageLevel.PRIVATE]: FileOwner.PRIVATE,
};

export const FileOwnerToStorageLevel: Record<FileOwner, StorageLevel> = {
  [FileOwner.PRIVATE]: StorageLevel.PRIVATE,
};

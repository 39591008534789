import onCorrespondentsClusterClick from './correspondents-cluster-onClick';
import onCorrespondentsClick from './correspondents-onClick';

namespace CorrespondentsController {
  export const layerEvents = {
    onClick: onCorrespondentsClick,
    onClusterClick: onCorrespondentsClusterClick,
  };
}

export default CorrespondentsController;

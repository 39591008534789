import { Box, Stack } from '@mui/material';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SaveSetupModal from '../../common-components/save-setup/save-setup';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import { DataItem } from '../../hooks/menu-data/menu-data';
import useMenuData from '../../hooks/menu-data/useMenuData';
import MapHelpers from '../../map/map.utils';
import { emptyHistory } from '../../nav-history.slice';
import SearchBar from '../../search-bar/search-bar';
import { setPanelStatus } from '../../search-bar/search-panel.slice';
import { setTickerOpen } from '../../state/incidents/incidents.slice';
import openInNewTab from '../../utils/open-link';
import { kebabify } from '../../utils/text-formatting.utils';
import {
  EMenuItems,
  setSecondaryMenu,
  setSelectedOption,
  toggleMainMenu,
} from '../menu.slice';
import useMainMenuLayerConfig from '../use-main-menu-layer-config';
import ExpandMenuButton from './expand-menu-button';
import SideMenuSection from './side-menu-section';
import './side-menu.scss';

function SideMenu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const menuData = useMenuData();

  const menuOpen = useAppSelector((state) => state.menu.menuOpen);
  const secondaryMenuOpen = useAppSelector(
    (state) => state.menu.secondaryMenuOpen
  );
  const selectedOption = useAppSelector((state) => state.menu.selectedOption);
  const tickerOpen = useAppSelector(
    (state) => state.userPreferences.userPreferences.showRiNewsBanner
  );

  const mainMenuLayerConfig = useMainMenuLayerConfig();
  const isMobile = useMobile();

  const [showSaveSetupModal, setShowSaveSetupModal] = useState<boolean>(false);

  useEffect(() => {
    const sideMenu = document.getElementById('side-menu');
    if (!isMobile) {
      sideMenu?.classList.remove('side-menu-closed');
    }
  }, []);

  const onLayerMenuItemSelected = (
    itemName: EMenuItems,
    layersToToggle: string[] = []
  ) => {
    dispatch(setSelectedOption(itemName));
    if (itemName === EMenuItems.INCIDENTS) {
      dispatch(setTickerOpen(true));
    }
    if (itemName !== EMenuItems.MAPS && itemName !== EMenuItems.HISTORY) {
      layersToToggle.forEach((layer) => {
        // set layer to visible if it exists on map
        if (MapHelpers.getLayer(layer)) {
          MapHelpers.setLayerVisibility(layer, true);
        }
      });
    }

    // If is mobile then close the menu when a side option is selected.
    if (isMobile) {
      dispatch(setPanelStatus({ panelOpen: false }));
      dispatch(toggleMainMenu());
    }
  };

  const menuItemSelected = (el: DataItem) => {
    dispatch(emptyHistory()); // empty nav / breadcrumb history
    if (el.selected === EMenuItems.SAVE_SETUP) {
      setShowSaveSetupModal(true);
    } else if (el.selected === EMenuItems.PRIVACY_POLICY) {
      openInNewTab('https://north-standard.com/privacy-policy/');
    } else {
      onLayerMenuItemSelected(
        el.selected,
        mainMenuLayerConfig[el.selected].disabled
          ? []
          : mainMenuLayerConfig[el.selected].layers
      );
    }
    if (selectedOption === el.selected && secondaryMenuOpen === true) {
      dispatch(setSecondaryMenu());
      navigate(`/`);
    } else {
      setSelectedOption(el.selected);
      navigate(`/${kebabify(el.selected)}`);
    }
  };

  return (
    <Box
      data-testid="side-menu"
      id="side-menu"
      className={cn({
        'side-menu-open': menuOpen,
        'side-menu-closed': !menuOpen,
        mobile: isMobile,
      })}
      sx={{
        bgcolor: 'primary.main',
        color: 'secondary.main',
        borderRight: '1px solid',
        borderColor: 'background.paper',
        height: tickerOpen ? 'calc(100% - 2rem)' : '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ExpandMenuButton />

      {isMobile && menuOpen && <SearchBar inline />}

      <Stack
        className={cn({ mobile: isMobile })}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          '&.mobile': {
            overflowY: 'scroll',
          },
        }}
      >
        {menuData.map((section, i) => (
          <SideMenuSection
            key={section.title}
            section={section}
            sectionNumber={i}
            itemSelected={(item) => menuItemSelected(item)}
          />
        ))}
      </Stack>
      {showSaveSetupModal && (
        <SaveSetupModal
          visible={showSaveSetupModal}
          onClose={() => setShowSaveSetupModal(false)}
        />
      )}
    </Box>
  );
}

export default SideMenu;

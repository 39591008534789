/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

type ApplySetupBarInitialState = {
  applySetupBarError: boolean;
  applySetupBarNumber: number;
};

export const INITIAL_APPLY_SETUP_BAR_STATE: ApplySetupBarInitialState = {
  applySetupBarError: false,
  applySetupBarNumber: 99,
};

const applySetupBarSlice = createSlice({
  name: 'applySetupBar',
  initialState: INITIAL_APPLY_SETUP_BAR_STATE,
  reducers: {
    setApplySetupBarError: (state, action) => {
      state.applySetupBarError = action.payload;
    },
    setApplySetupBarNumber: (state, action) => {
      state.applySetupBarNumber = action.payload;
    },
    decreaseApplySetupBarNumber: (state) => {
      state.applySetupBarNumber -= 1;
    },
  },
});

export const {
  setApplySetupBarError,
  setApplySetupBarNumber,
  decreaseApplySetupBarNumber,
} = applySetupBarSlice.actions;

export default applySetupBarSlice.reducer;

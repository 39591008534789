import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import {
  addMeasurementDrawLayers,
  addMeasurementLayers,
} from '../../../map/map-layer-manager/measurement-utils/add-measurement-layer';
import setMeasurementLayerFeatures from '../../../map/map-layer-manager/measurement-utils/set-measurement-layer-features';
import MapHelpers from '../../../map/map.utils';
import { Measurement } from './measurement.model';

const onMeasurementsUpdate = (measurements: Measurement[]) => {
  if (!MapHelpers.getSource(MapLayer.MEASUREMENT_LINES)) {
    addMeasurementLayers();
  }

  if (!MapHelpers.getSource(MapLayer.MEASUREMENT_LINES_TEMP)) {
    addMeasurementDrawLayers();
  }

  setMeasurementLayerFeatures({
    measurements,
    lineLayerId: MapLayer.MEASUREMENT_LINES,
    pointLayerId: MapLayer.MEASUREMENT_POINTS,
  });
};

export default onMeasurementsUpdate;

import { gpx } from '@tmcw/togeojson';

export const gpxToGeoJSON = (xmlText: string) => {
  const gpxFile = new DOMParser().parseFromString(xmlText, 'text/xml');
  const geoJSON = gpx(gpxFile) as GeoJSON.FeatureCollection<GeoJSON.LineString>;
  // gpx does not throw an error if it's not a valid gpx file, just returns an empty feature collection
  // we need all parsers to throw if the file is not valid
  if (!geoJSON || !geoJSON.features || !geoJSON.features.length) {
    throw new Error('Invalid GPX file');
  }
  return geoJSON;
};

export default gpxToGeoJSON;

import DownloadHelpers from '../../../../utils/download.utils';

export interface HistoryResultsExportFormValues {
  fileName: string;
  fileFormat: typeof DownloadHelpers.FileFormat[keyof typeof DownloadHelpers.FileFormat];
}

export function validateHistoryResultsExportForm(
  values: HistoryResultsExportFormValues
) {
  const errors: { [key: string]: any } = {
    ...(values.fileName.trim().length < 1 && {
      fileName: 'Please provide a valid filename.',
    }),
    ...(!Object.values(DownloadHelpers.FileFormat).includes(
      values.fileFormat
    ) && { fileFormat: 'Please select a valid File Format.' }),
  };

  return errors;
}

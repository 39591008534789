/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FirstCallFeature, FirstCallProperties } from '../../models/first-call';

interface FirstCallState {
  firstCallPorts: FirstCallFeature[] | null;
  loading: boolean;
  error: boolean;
  selectedFirstCall: FirstCallFeature | null;
  selectedFirstCallId: number | string | null;
}

export const INITIAL_FIRST_CALL_STATE: FirstCallState = {
  firstCallPorts: null,
  loading: false,
  error: false,
  selectedFirstCall: null,
  selectedFirstCallId: null,
};

const FirstCallSlice = createSlice({
  name: 'firstCall',
  initialState: INITIAL_FIRST_CALL_STATE,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean | null>) => {
      state.loading = action.payload ?? true;
    },
    setError: (state, action: PayloadAction<boolean | null>) => {
      state.error = action.payload ?? true;
      state.loading = false;
    },
    setFirstCallPorts: (
      state,
      action: PayloadAction<FirstCallFeature[] | null>
    ) => {
      state.firstCallPorts = action.payload;
      state.loading = false;
      state.error = false;
    },
    setSelectedFirstCallPortId: (
      state,
      action: PayloadAction<number | string | null>
    ) => {
      state.selectedFirstCallId = action.payload;
    },
    setSelectedFirstCallPort: (
      state,
      action: PayloadAction<FirstCallFeature | undefined | null>
    ) => {
      const prevSelectedFirstCallId = state.selectedFirstCall
        ? String(state.selectedFirstCall.id)
        : undefined;
      const newSelectedFirstCallId = action.payload
        ? String(action.payload.id)
        : undefined;

      state.selectedFirstCall = action.payload
        ? {
            ...action.payload,
            properties: {
              ...(action.payload.properties as FirstCallProperties),
              selected: true,
            },
          }
        : null;

      if (state.firstCallPorts) {
        if (newSelectedFirstCallId) {
          // set the selected property to true for the new selected port
          state.firstCallPorts = state.firstCallPorts.map((port) => {
            if (String(port.id) === newSelectedFirstCallId) {
              return {
                ...port,
                properties: {
                  ...(port.properties as FirstCallProperties),
                  selected: true,
                },
              };
            }
            return port;
          });
        }

        // if no new selected port, deselect the previous port
        if (
          prevSelectedFirstCallId &&
          prevSelectedFirstCallId !== newSelectedFirstCallId
        ) {
          state.firstCallPorts = state.firstCallPorts.map((port) => {
            if (String(port.id) === prevSelectedFirstCallId) {
              return {
                ...port,
                properties: {
                  ...(port.properties as FirstCallProperties),
                  selected: false,
                },
              };
            }
            return port;
          });
        }
      }
    },
  },
});

export const {
  setLoading,
  setError,
  setFirstCallPorts,
  setSelectedFirstCallPortId,
  setSelectedFirstCallPort,
} = FirstCallSlice.actions;

export default FirstCallSlice.reducer;

/* eslint-disable import/prefer-default-export */
import {
  Drawing,
  DrawingDelete,
  DrawingSubmit,
  DrawingUpdate,
} from '../models/drawings.model';
import { wrapRequest } from './base';

export const getDrawings = async (): Promise<Drawing[]> =>
  wrapRequest('get', 'north_standard', '/drawings');

export const deleteDrawings = async (
  drawingDelete: DrawingDelete
): Promise<void> =>
  wrapRequest('del', 'north_standard', '/drawings', {
    body: {
      ...drawingDelete,
    },
  });

export const postDrawing = async (
  drawingSubmit: DrawingSubmit
): Promise<Drawing> =>
  wrapRequest('post', 'north_standard', '/drawings', {
    body: drawingSubmit,
  });

export const postUpdateDrawing = async (
  drawingUpdate: DrawingUpdate
): Promise<Drawing> =>
  wrapRequest('post', 'north_standard', '/drawings', {
    body: drawingUpdate,
    queryStringParameters: {
      region_id: drawingUpdate.region_id,
    },
  });

/* eslint-disable @typescript-eslint/no-unused-vars */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import LayerToggle from '../../common-components/layer-toggle/layer-toggle';
import zIndexes from '../../z-indexes.scss';
import './drawings-panel.scss';

interface CardProps {
  titleIcon?: ReactNode;
  title: string;
  subtitle: string;
  onClick?: () => void;
  onEditClick?: () => void;
  entityId?: string;
  drawingId: string;
  deleteDrawing: (drawingId: string) => void;
  selected: boolean;
}

function AreaInfoCard(props: CardProps) {
  const {
    title,
    titleIcon,
    subtitle,
    onClick,
    onEditClick,
    entityId,
    drawingId,
    deleteDrawing,
    selected,
  } = props;

  const anchorEl = useRef(null);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);

  return (
    <>
      <ListItem
        data-testid="area-info-card-container"
        disablePadding
        divider
        secondaryAction={
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <IconButton
              ref={anchorEl}
              data-testid="context-menu-button"
              id="context-menu-button"
              edge="end"
              aria-label="more"
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setContextMenuOpen(true);
              }}
            >
              <MoreVertIcon />
            </IconButton>

            <LayerToggle layers={[entityId as string]} layerGroups={[]} />
          </Box>
        }
      >
        <ListItemButton
          onClick={onClick}
          disableGutters
          sx={{
            padding: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          autoFocus={selected}
          selected={selected}
        >
          <ListItemAvatar>{titleIcon}</ListItemAvatar>
          <ListItemText
            sx={{ paddingInlineEnd: '48px' }} // This is needed to make the text not overlap with the secondary action buttons.
            primary={<Typography noWrap>{title}</Typography>}
            secondary={
              <Typography variant="body2">
                {subtitle || 'No Description'}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
      <Menu
        id="drawing-edit-menu"
        anchorEl={anchorEl.current}
        open={contextMenuOpen}
        onClose={() => {
          setContextMenuOpen(false);
        }}
        sx={{ zIndex: zIndexes['$--feature-panel-contents-layer'] }} // This is needed to display the menu above the panel.
        MenuListProps={{
          'aria-labelledby': `context-menu-button`,
          role: 'listbox',
        }}
        slotProps={{
          paper: {
            sx: {
              bgcolor: 'primary.main',
            },
          },
        }}
      >
        <MenuItem
          sx={{ display: 'flex', gap: '8px' }}
          onClick={() => {
            onEditClick?.();
          }}
        >
          <ModeEditOutlineOutlinedIcon />
          Edit Drawing
        </MenuItem>
        <MenuItem
          sx={{ display: 'flex', gap: '8px' }}
          onClick={() => {
            deleteDrawing(drawingId);
          }}
        >
          <DeleteOutlineIcon />
          Delete
        </MenuItem>
      </Menu>
    </>
  );
}

AreaInfoCard.defaultProps = {
  titleIcon: undefined,
  onClick: undefined,
  onEditClick: undefined,
  entityId: undefined,
};

export default AreaInfoCard;

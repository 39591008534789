import { HistoricVesselPoint } from '../../../maritime-menu-options/history-panel/historic-vessel-point.model';

export function getUniqueHistoricVessels(points: HistoricVesselPoint[]) {
  // https://stackoverflow.com/questions/33464504/using-spread-syntax-and-new-set-with-typescript
  return Array.from(new Set(points.map((point) => point.vessel_id)));
}

export function getUniqueHistoricMmsis(points: HistoricVesselPoint[]) {
  return Array.from(new Set(points.map((point) => point.mmsi)));
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { BackOneButton } from '../../common-components/breadcrumb/breadcrumb';
import PanelTitle from '../../common-components/panel/panel-title';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { themedDraw } from '../../map/map-controls.utils';
import MapHelpers from '../../map/map.utils';
import { Drawing } from '../../models/drawings.model';
import {
  setCreateDrawingPanelVisible,
  setLoading,
  setSelectedDrawing,
} from '../../state/drawings/drawings.slice';
import CreateDrawingPanel from './create-drawing-panel';
import DrawingsPanelMain from './drawings-panel-main';
import './drawings-panel.scss';
import { updateDrawings } from './drawings-panel.utils';

function DrawingsPanel() {
  const dispatch = useAppDispatch();
  const [drawingToEdit, setDrawingToEdit] = useState<Drawing | null>();
  const mapInitialised = useAppSelector((state) => state.map.mapInitialised);

  useEffect(() => {
    if (!mapInitialised) {
      return;
    }
    dispatch(setLoading());
    updateDrawings();
  }, [mapInitialised]);

  const createDrawingPanelVisible = useAppSelector(
    (state) => state.drawings.createDrawingPanelVisible
  );
  const initialFormValues = useAppSelector(
    (state) => state.drawingsPanel.initialFormValues
  );
  const drawings = useAppSelector((state) => state.drawings.drawings);

  useEffect(() => {
    const drawingIdToEdit = initialFormValues?.common?.drawingId;
    if (drawingIdToEdit) {
      const drawingFound = drawings?.find(
        (drawing) => drawing.region_id === drawingIdToEdit
      );
      if (drawingFound) {
        setDrawingToEdit(drawingFound);
      }
    }
  }, [initialFormValues]);

  return (
    <Box
      className="drawings-panel-container-content"
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        scrollbarWidth: 'thin',
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'start' }}>
        {createDrawingPanelVisible && (
          <Box sx={{ maxHeight: '50%', marginTop: '2.2rem' }}>
            <BackOneButton
              onClick={() => {
                setDrawingToEdit(null);
                MapHelpers.setLayerVisibility('circle-edit-temp', false);
                const draw = themedDraw;
                draw.deleteAll();
              }}
            />
          </Box>
        )}
        <PanelTitle>Drawings</PanelTitle>
      </Box>

      {createDrawingPanelVisible ? (
        <CreateDrawingPanel
          onCreate={(drawingInfo, drawingId, resetForm) => {
            updateDrawings().then(() => {
              MapHelpers.setLayerVisibility(drawingId, true);
              dispatch(setCreateDrawingPanelVisible(false));
              setDrawingToEdit(null);
            });
            resetForm();
            dispatch(setSelectedDrawing(null));
          }}
          initialValues={initialFormValues}
          drawingToEdit={drawingToEdit}
        />
      ) : (
        <DrawingsPanelMain />
      )}
    </Box>
  );
}
export default DrawingsPanel;

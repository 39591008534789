import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import DrawCompletionOverlay from '../../common-components/drawing-overlay/drawing-overlay';
import { useAppDispatch, useAppSelector, useMobile } from '../../hooks';
import {
  addShippingLaneOutlineLayer,
  deleteShippingLaneOutlineById,
  deleteShippingLaneTileLayers,
} from '../../map/map-layer-manager/shipping-lane-utils/add-shipping-lane-layers';
import { setDrawType } from '../../map/map.slice';
import ShippingLanesFormContainer from './shipping-lanes-form-container';

export default function ShippingLanesPanel() {
  const drawType = useAppSelector((state) => state.map.drawType);
  const dispatch = useAppDispatch();
  const isMobile = useMobile();
  const [shippingLaneFormIds, setShippingLaneFormIds] = useState<string[]>([
    '',
  ]);

  const createDefaultForm = () => {
    const formId = Date.now().toString(36);
    setShippingLaneFormIds([formId]);
    addShippingLaneOutlineLayer(formId);
  };

  useEffect(() => {
    createDefaultForm();
  }, []);

  const handleAddShippingLaneForm = () => {
    const formId = Date.now().toString(36);
    addShippingLaneOutlineLayer(formId);

    const updatedIds = [...shippingLaneFormIds, formId];
    setShippingLaneFormIds(updatedIds);
  };

  const handleDeleteFormById = (id: string) => {
    const updatedIds = shippingLaneFormIds.filter((formId) => formId !== id);
    setShippingLaneFormIds(updatedIds);

    deleteShippingLaneTileLayers(id);
    deleteShippingLaneOutlineById(id);
  };

  const handleDeleteFormByIds = (ids: string[]) => {
    const updatedIds = shippingLaneFormIds.filter(
      (formId) => !ids.includes(formId)
    );
    setShippingLaneFormIds(updatedIds);

    ids.forEach((id) => {
      deleteShippingLaneTileLayers(id);
      deleteShippingLaneOutlineById(id);
    });

    createDefaultForm();
  };

  const onClear = () => {
    handleDeleteFormByIds(shippingLaneFormIds);
  };

  return (
    <Box sx={{ height: '100%', maxHeight: '90vh' }}>
      {drawType === 'box' && !isMobile && (
        <DrawCompletionOverlay
          handleClick={() => {
            dispatch(setDrawType(null));
          }}
          text="Click two points on the map to draw an area. Click this panel to cancel."
        />
      )}
      <Divider />
      <Stack
        sx={{
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        divider={<Divider />}
        spacing={1}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          {shippingLaneFormIds.map((formId, index) => (
            <Stack key={formId}>
              <Stack direction="row" spacing={2} sx={{ px: 2, pt: 2 }}>
                <TextField
                  id={`query_text_field ${index}`}
                  label="Query name"
                  defaultValue={`Query ${index + 1}`}
                  sx={{ width: '100%' }}
                />

                <IconButton
                  aria-label="delete"
                  data-testid="delete"
                  onClick={() => handleDeleteFormById(formId)}
                  disabled={shippingLaneFormIds.length <= 1}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>
              <ShippingLanesFormContainer key={formId} formId={formId} />
              <Divider />
            </Stack>
          ))}
        </Box>

        <Stack>
          <Button variant="outlined" onClick={handleAddShippingLaneForm}>
            Add additional shipping lane query
          </Button>
          <Button
            sx={{ marginY: 0.5 }}
            onClick={onClear}
            disabled={shippingLaneFormIds.length <= 1}
          >
            Clear all queries
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

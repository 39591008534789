import * as yup from 'yup';
import {
  validateLatitudeDms,
  validateLongitudeDms,
} from '../../../utils/validation-helpers.utils';

export const rangeRingFormCentreValidationSchema = yup.object().shape({
  centre: yup.object().shape({
    longitude: validateLongitudeDms(),
    latitude: validateLatitudeDms(),
  }),
});

export const rangeRingPropertiesValidationSchema = yup.object().shape({
  speed: yup
    .number()
    .typeError('Speed must be a number')
    .min(0, 'Must be greater than or equal to 0')
    .required('Required'),
  colour: yup.string(),
  duration: yup.object().shape({
    hours: yup
      .number()
      .typeError('Hours must be a number')
      .min(0, 'Must be greater than or equal to 0')
      .required('Required'),
    minutes: yup
      .number()
      .typeError('Minutes must be a number')
      .max(59, 'Minutes must be 59 or less')
      .min(0, 'Must be greater than or equal to 0')
      .required('Required'),
  }),
  distanceNauticalMiles: yup
    .number()
    .typeError('Distance must be a number')
    .min(0, 'Must be greater than or equal to 0'),
});

import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, SxProps } from '@mui/material';
import { InputHTMLAttributes, MutableRefObject, useRef } from 'react';

interface UploadFileProps {
  label: string;
  acceptedFileTypes: InputHTMLAttributes<HTMLInputElement>['accept'];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps;
}

function UploadFileButton(props: UploadFileProps) {
  const { onChange, acceptedFileTypes, label, sx } = props;

  const hiddenFileInput = useRef() as MutableRefObject<HTMLInputElement>;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files != null) {
      onChange(e);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<UploadFileIcon />}
        onClick={() => hiddenFileInput.current?.click()}
        sx={sx}
      >
        {label}
      </Button>
      <input
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        type="file"
        multiple
        accept={acceptedFileTypes}
        onChange={handleFileChange}
        data-testid={label}
      />
    </>
  );
}

export default UploadFileButton;

UploadFileButton.defaultProps = {
  sx: {},
};

/* eslint-disable import/prefer-default-export */
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';

import {
  ErrorMessage,
  FieldArray,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';

import { putRoute } from '../../../api/routes';
import MuiFormikTextField from '../../../common-components/form-fields/mui-formik-text-field';
import FormErrorMessage from '../../../common-components/form-messages/form-error-message';
import FormLoadingMessage from '../../../common-components/form-messages/form-loading-message';
import FormSuccessMessage from '../../../common-components/form-messages/form-success-message';
import MUISlider from '../../../common-components/slider/SliderMUI';
import { useAppDispatch } from '../../../hooks';
import useAccessControl from '../../../hooks/access-control/useAccessControl';
import {
  DMSToDecimal,
  decimalToDMS,
  themedDraw,
} from '../../../map/map-controls.utils';
import {
  addDrawingPointLabels,
  addRadiusAura,
  removeDrawingPointLabels,
  removeRadiusAura,
  updateRadiusAura,
} from '../../../map/map-layer-manager/route-utils/route-layer-handler';
import { setDrawType } from '../../../map/map.slice';
import MapHelpers from '../../../map/map.utils';
import { Route } from '../../../models/routes.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import {
  convertKmToNauticalMiles,
  convertNauticalMilesToKm,
} from '../../../utils/measurement-helpers';
import { gpxToGeoJSON } from '../../../utils/parsers/gpx-to-geojson';
import { rtzToGeoJson } from '../../../utils/parsers/rtz-to-geojson';
import {
  validateLatitudeDms,
  validateLongitudeDms,
} from '../../../utils/validation-helpers.utils';
import updateRoutes from '../routes-panel.utils';
import './route-form.scss';

interface LongLatDMS {
  // rawLong: number;
  // rawLat: number;
  long: string;
  lat: string;
}

interface RouteFormValues {
  routeId: string;
  routeName: string;
  waypoints: LongLatDMS[];
  radiusNm: number; // in nautical miles
  description: string;
}

interface RenderLatLongInputsProps {
  index: number;
  remove: (index: number) => void;
  formik: FormikProps<RouteFormValues>;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function renderLatLongInputs({
  index,
  remove,
  formik,
}: RenderLatLongInputsProps) {
  const result = [
    <MuiFormikTextField
      ariaLabel="Latitude"
      name={`waypoints[${index}].lat`}
      key={`latitude-${index}-input`}
      dataTestId={`latitude-${index}-input`}
      className="latitude"
      hiddenLabel
    />,
    <MuiFormikTextField
      ariaLabel="Longitude"
      key={`longitude-${index}-input`}
      name={`waypoints[${index}].long`}
      dataTestId={`longitude-${index}-input`}
      className="longitude"
      hiddenLabel
    />,
    <IconButton
      aria-label={`delete waypoint ${index}`}
      onClick={() => {
        remove(index);
      }}
      data-testid={`delete-${index}-button`}
      key={`${index}-button`}
      className="remove"
      disabled={formik.values.waypoints.length <= 2}
    >
      <CancelIcon />
    </IconButton>,
  ];

  return result;
}

const routeValidationSchema = yup.object().shape({
  routeName: yup.string().required('Route name is required'),
  waypoints: yup
    .array()
    .of(
      yup.object().shape({
        long: validateLongitudeDms(),
        lat: validateLatitudeDms(),
      })
    )
    .min(2, 'Must have at least 2 waypoints'),
});

const convertGeoJsonToWaypoints = (
  geoJSON: GeoJSON.Feature<GeoJSON.LineString>
) => {
  const waypoints: LongLatDMS[] = [];
  geoJSON.geometry.coordinates.forEach((coordinate) => {
    waypoints.push({
      // rawLong: coordinate[0],
      // rawLat: coordinate[1],
      long: decimalToDMS(coordinate[0], 'longitude', true),
      lat: decimalToDMS(coordinate[1], 'latitude', true),
    });
  });
  return waypoints;
};

/**
 *
 * @param waypoints
 * @param radius // in kilometers
 * @returns
 */
const convertWaypointsToGeoJSON = (
  waypoints: LongLatDMS[],
  radius: number
): GeoJSON.Feature<GeoJSON.LineString> =>
  GeoHelper.handleAntiMeridian({
    type: 'Feature',
    properties: {
      radius,
    },
    geometry: {
      type: 'LineString',
      coordinates: waypoints.map((waypoint) => [
        DMSToDecimal(waypoint.long)!,
        DMSToDecimal(waypoint.lat)!,
      ]),
    },
  });

export function RouteForm({
  routeToEdit,
  clearEditRoute,
}: {
  routeToEdit?: Route | null;
  clearEditRoute?: () => void;
}) {
  const dispatch = useAppDispatch();
  const { canUploadRTZ } = useAccessControl();

  const draw = themedDraw;
  const [currentDrawing, setCurrentDrawing] =
    useState<GeoJSON.Feature<GeoJSON.LineString> | null>(null);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const supportedFileTypes = ['GeoJSON', 'GPX'];

  if (canUploadRTZ) {
    supportedFileTypes.push('RTZ');
  }

  const formRef = useRef<FormikProps<RouteFormValues>>(null);

  const [uploadErrorMessage, setUploadErrorMessage] = useState('');

  const initialValues: RouteFormValues = routeToEdit
    ? {
        routeId: routeToEdit.route_id,
        routeName: routeToEdit.route_name,
        waypoints: convertGeoJsonToWaypoints(routeToEdit.route_data),
        radiusNm: routeToEdit.radius,
        description: routeToEdit.description || '',
      }
    : {
        routeId: '',
        routeName: '',
        waypoints: [],
        radiusNm: 25, // in nautical miles
        description: '',
      };

  const onDrawUpdateListener = (event: any) => {
    const drawnFeature = event.features[0];
    drawnFeature.properties = {
      ...drawnFeature.properties,
      radius: convertNauticalMilesToKm(formRef.current?.values.radiusNm ?? 0),
    };

    setCurrentDrawing(drawnFeature);
    formRef.current?.setFieldValue(
      'waypoints',
      convertGeoJsonToWaypoints(drawnFeature)
    );
  };

  useEffect(() => {
    if (routeToEdit && !currentDrawing) {
      // if there is a route to edit we need to push it into mapbox-draw
      draw.deleteAll();
      const [mapboxDrawFeatureId] = draw.add(routeToEdit.route_data);

      setCurrentDrawing(
        draw.get(mapboxDrawFeatureId)! as GeoJSON.Feature<GeoJSON.LineString>
      );
      draw.changeMode('direct_select', { featureId: mapboxDrawFeatureId });

      MapHelpers.addMapEventListener('draw.update', null, onDrawUpdateListener);
    }
  }, [routeToEdit, draw]);

  const handleUploadedFile = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<RouteFormValues>['setFieldValue']
  ) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        try {
          const result = reader.result as string;
          let geoJSON:
            | GeoJSON.Feature<GeoJSON.LineString>
            | GeoJSON.FeatureCollection<GeoJSON.LineString>
            | undefined;

          if (file.type === 'application/json') {
            geoJSON = JSON.parse(result);
          } else if (file.type === 'text/xml') {
            // attempt all parsers until one works. Ensure all parser functions throw if the file is not valid,
            // so that we don't get a false positive from a previous parser
            const xmlParsers: ((
              string: string
            ) =>
              | GeoJSON.FeatureCollection<GeoJSON.LineString>
              | GeoJSON.Feature<GeoJSON.LineString>)[] = [gpxToGeoJSON];

            // RTZ upload feature-flagged for now
            if (canUploadRTZ) {
              xmlParsers.push(rtzToGeoJson);
            }
            xmlParsers.forEach((parser) => {
              try {
                geoJSON = parser(result);
              } catch (err) {
                // do nothing until all parsers have been tried
              }
            });
          }

          if (!geoJSON) {
            throw new Error(
              `Uploaded file is not a supported filetype (${supportedFileTypes.join(
                ', '
              )}), or could not be parsed.`
            );
          }

          let feature: GeoJSON.Feature<GeoJSON.LineString>;
          if (geoJSON.type === 'FeatureCollection') {
            if (geoJSON.features.length !== 1) {
              throw new Error('Uploaded data must contain exactly one Feature');
            }
            feature = geoJSON
              .features[0] as GeoJSON.Feature<GeoJSON.LineString>;
          } else if (geoJSON.type === 'Feature') {
            feature = geoJSON;
          } else {
            throw new Error('Uploaded file does not contain a route');
          }

          if (!feature.geometry) {
            throw new Error('Data does not contain geometry information');
          }

          if (feature.geometry.type !== 'LineString') {
            throw new Error('Data Geometry is not a LineString');
          }

          if (!feature.geometry.coordinates) {
            throw new Error('Data does not contain coordinates');
          }

          // RTZ and GPX routes can also have a name property, add it to the form if it exists
          if (feature.properties?.name) {
            setFieldValue('routeName', feature.properties.name);
          }

          // add the uploaded route to mapbox-draw
          const [mapboxDrawFeatureId] = draw.add(feature);
          setCurrentDrawing(
            draw.get(
              mapboxDrawFeatureId
            )! as GeoJSON.Feature<GeoJSON.LineString>
          );

          const waypoints = convertGeoJsonToWaypoints(feature);
          // initialValues.waypoints = waypoints;
          const radius = feature.properties?.radius;

          // Formik checks whether the values have changed before updating.
          // If a user uploads a file twice, the form will not update.
          // Kick the Formik values to a blank object to force an update.
          setFieldValue('waypoints', waypoints);
          if (radius) {
            // Radius always stored in geoJson in kilometers.
            setFieldValue('radiusNm', convertKmToNauticalMiles(radius));
          }

          setUploadErrorMessage('');
        } catch (err) {
          if (err instanceof SyntaxError) {
            setUploadErrorMessage(
              'Error reading file: Invalid JSON or XML format'
            );
          } else {
            setUploadErrorMessage((err as Error).message);
          }
        }
      };
      reader.readAsText(file);
    }
  };

  const startRouteDraw = () => {
    dispatch(setDrawType('route'));
    setUploadErrorMessage('');
    if (draw) {
      draw.deleteAll();
      setCurrentDrawing(null);
      formRef.current?.setFieldValue('waypoints', []);

      draw.changeMode('draw_line_string');

      MapHelpers.once('draw.create', (e) => {
        onDrawUpdateListener(e);
      });

      MapHelpers.addMapEventListener('draw.update', null, onDrawUpdateListener);
    }
  };

  const onChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    if (currentDrawing) {
      if (name === 'radiusNm') {
        currentDrawing.properties = {
          radius: convertNauticalMilesToKm(Number(value)),
        };
        setCurrentDrawing(currentDrawing);
        updateRadiusAura(currentDrawing);
        formRef.current?.setFieldValue('radiusNm', Number(value));
      }
      // when waypoints are changed, the name field is in the format of "waypoints[0].long"
      if (name.startsWith('waypoints')) {
        if (name === 'waypoints.new') {
          const newCoords = [...currentDrawing.geometry.coordinates];
          newCoords.push([0, 0]);

          const newDrawing = {
            ...currentDrawing,
            geometry: {
              ...currentDrawing.geometry,
              coordinates: newCoords,
            },
          };
          draw?.set({
            type: 'FeatureCollection',
            features: [newDrawing],
          });
          setCurrentDrawing(newDrawing);
          updateRadiusAura(newDrawing);
        } else if (name === 'waypoints.delete') {
          const newCoords = [...currentDrawing.geometry.coordinates];
          newCoords.splice(Number(value), 1);
          const newDrawing = {
            ...currentDrawing,
            geometry: {
              ...currentDrawing.geometry,
              coordinates: newCoords,
            },
          };
          draw?.set({
            type: 'FeatureCollection',
            features: [newDrawing],
          });
          setCurrentDrawing(newDrawing);
        } else {
          const index = name.match(/\[(.*?)\]/)?.[1];
          // Will need addressing once user can enter lat longs in degrees/minutes/decimal minutes
          if (Number.isNaN(Number(value))) {
            return;
          }
          if (index) {
            const waypointIndex = Number(index);
            const waypointType = name.split('.')[1];
            const newCoords = [...currentDrawing.geometry.coordinates];
            if (waypointIndex > newCoords.length - 1) {
              newCoords.push([0, 0]);
            }

            if (waypointType === 'long') {
              newCoords[waypointIndex][0] = Number(value);
            }
            if (waypointType === 'lat') {
              newCoords[waypointIndex][1] = Number(value);
            }
            const newDrawing = {
              ...currentDrawing,
              geometry: {
                ...currentDrawing.geometry,
                coordinates: newCoords,
              },
            };

            draw?.set({
              type: 'FeatureCollection',
              features: [newDrawing],
            });
            setCurrentDrawing(newDrawing);
          }
        }
      }
    } else if (name === 'waypoints.newRoute') {
      const newCoords = [
        { long: `0° 0' 0" E`, lat: `0° 0' 0" N` },
        { long: `0° 0' 0" E`, lat: `0° 0' 0" N` },
      ];
      const newDrawing = convertWaypointsToGeoJSON(
        newCoords,
        initialValues.radiusNm
      );
      setCurrentDrawing(newDrawing);
      addRadiusAura(newDrawing);
    }
  };

  useEffect(() => {
    if (!currentDrawing) {
      // no need to establish cleanup when there is no current drawing
      return () => {};
    }
    addRadiusAura(currentDrawing);
    addDrawingPointLabels(currentDrawing);
    return () => {
      removeRadiusAura(currentDrawing);
      removeDrawingPointLabels(currentDrawing);
    };
  }, [currentDrawing]);

  useEffect(() => {
    if (draw && !routeToEdit) {
      draw.deleteAll();
    }
    return () => {
      if (draw) {
        draw.deleteAll();
      }
    };
  }, []);

  const onSubmit = (
    values: RouteFormValues,
    { resetForm }: FormikHelpers<RouteFormValues>
  ) => {
    setLoading(true);
    setSuccess(false);
    setError(false);
    const geoJSON = convertWaypointsToGeoJSON(
      values.waypoints,
      convertNauticalMilesToKm(values.radiusNm)
    );
    putRoute({
      route_id: values.routeId,
      route_name: values.routeName,
      description: values.description,
      radius: convertNauticalMilesToKm(values.radiusNm),
      route_data: geoJSON,
    })
      .then(() => {
        if (routeToEdit && clearEditRoute) {
          clearEditRoute();
        }
        setLoading(false);
        setSuccess(true);
        resetForm();
        draw?.deleteAll();
        setCurrentDrawing(null);
      })
      .then(() => updateRoutes())
      .then((routes) => {
        // Make the just created route visible
        if (routes) {
          const thisRoute = routes.find(
            (route) => route.route_name === values.routeName
          );
          if (thisRoute) {
            MapHelpers.setLayoutProperty(
              thisRoute.route_id,
              'visibility',
              'visible'
            );
            MapHelpers.setLayoutProperty(
              `${thisRoute.route_id}_radius`,
              'visibility',
              'visible'
            );

            MapHelpers.setLayoutProperty(
              `${thisRoute.route_id}_labels`,
              'visibility',
              'visible'
            );
          }
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      {loading && <FormLoadingMessage message="Creating route..." />}
      {!loading && error && <FormErrorMessage message="Error creating route" />}
      {!loading && !error && success && (
        <FormSuccessMessage message="Successfully created route" />
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={routeValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        innerRef={formRef}
      >
        {/* {({ values, errors, touched, setFieldValue } ) => ( */}
        {(formikProps: FormikProps<RouteFormValues>) => (
          <Form onChange={onChange}>
            <div className="route-form">
              <Stack sx={{ width: '100%' }} spacing={3}>
                <Stack sx={{ width: '100%' }} spacing={1}>
                  <Box>
                    <Button fullWidth variant="outlined" component="label">
                      Upload Route
                      <VisuallyHiddenInput
                        type="file"
                        id="route-upload"
                        name="routeUpload"
                        onChange={(event) =>
                          handleUploadedFile(event, formikProps.setFieldValue)
                        }
                      />
                    </Button>
                    <Typography
                      variant="subtitle2"
                      sx={{ textAlign: 'left', marginTop: '0.4em' }}
                    >
                      Supported File Types:{' '}
                      {supportedFileTypes.map((type, index) => (
                        <>
                          <strong>{type}</strong>
                          {index !== supportedFileTypes.length - 1
                            ? ', '
                            : null}
                        </>
                      ))}
                    </Typography>
                    {uploadErrorMessage && (
                      <Alert severity="error">{uploadErrorMessage}</Alert>
                    )}
                  </Box>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => startRouteDraw()}
                  >
                    Draw Route
                  </Button>
                </Stack>

                <Stack sx={{ width: '100%' }} spacing={2}>
                  <MuiFormikTextField
                    name="routeName"
                    label="Route name"
                    placeholder="Enter route name"
                  />
                  <MuiFormikTextField
                    name="description"
                    label="Route description"
                    placeholder="Enter route description"
                  />
                </Stack>
              </Stack>

              <Divider />
              <div className="lat-long-container">
                <h3 className="title">Waypoints</h3>
                <span className="latitude">Latitude</span>
                <span className="longitude">Longitude</span>
                <FieldArray name="waypoints">
                  {(arrayHelpers) => (
                    <div className="lat-long-container scroll" key="x">
                      {formikProps.values.waypoints?.length > 0 &&
                        formikProps.values.waypoints.map((_, index) =>
                          renderLatLongInputs({
                            formik: formikProps,
                            index,
                            remove: (i: number) => {
                              // having less than 2 waypoints will crash geojson as it's not a valid linestring
                              if (formikProps.values.waypoints.length > 2) {
                                onChange({
                                  target: {
                                    name: 'waypoints.delete',
                                    value: i,
                                  },
                                } as React.ChangeEvent<any>);
                                arrayHelpers.remove(i);
                              }
                            },
                          })
                        )}
                      <Button
                        key="add-button"
                        fullWidth
                        className="add"
                        variant="outlined"
                        onClick={() => {
                          if (formikProps.values.waypoints?.length === 0) {
                            onChange({
                              target: { name: 'waypoints.newRoute' },
                            } as React.ChangeEvent<any>);
                            arrayHelpers.push({
                              lat: `0° 0' 0" N`,
                              long: `0° 0' 0" E`,
                            });
                            arrayHelpers.push({
                              lat: `0° 0' 0" N`,
                              long: `0° 0' 0" E`,
                            });
                          } else {
                            onChange({
                              target: { name: 'waypoints.new' },
                            } as React.ChangeEvent<any>);

                            arrayHelpers.push({
                              lat: `0° 0' 0" N`,
                              long: `0° 0' 0" E`,
                            });
                          }
                        }}
                      >
                        Add a waypoint
                      </Button>

                      {formikProps.errors.waypoints &&
                        typeof formikProps.errors.waypoints === 'string' && (
                          <ErrorMessage
                            component="span"
                            key="y"
                            className="error-message waypoints-error-message"
                            name="waypoints"
                          />
                        )}
                    </div>
                  )}
                </FieldArray>
              </div>
              <Divider />
              <div className="route-radius-container">
                <label htmlFor="route-radius">
                  Route Intelligence Radius (NM): {formikProps.values.radiusNm}
                </label>
                <MUISlider
                  name="radiusNm"
                  id="route-radius"
                  value={formikProps.values.radiusNm}
                  onChange={(event, value) => {
                    onChange({
                      target: {
                        name: 'radiusNm',
                        value,
                      },
                    } as React.ChangeEvent<any>);
                  }}
                  aria-label="Route Intelligence Radius"
                  valueLabelDisplay="auto"
                  min={1}
                  max={200}
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={
                  !formikProps.values.routeName ||
                  !(formikProps.values.waypoints?.length !== 0)
                }
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

RouteForm.defaultProps = {
  routeToEdit: null,
  clearEditRoute: null,
};

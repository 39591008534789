import { PostRoute, Route } from '../models/routes.model';
import { wrapRequest } from './base';

export interface RouteAlertResponse {
  route_id: string;
  radius: number;
  route_name: string;
  route_data: GeoJSON.Feature<GeoJSON.LineString>;
  description: string;
  company_id: string;
  cognito_id: string;
  shared: boolean;
  alert_id: string;
  result_hash: string;
  vessel_id: null;
  alert_modifiers: AlertModifiers;
  alert_time: string;
  expiry_time: string;
  additional_details: RouteAdditionalDetails;
  alert_config_id: string;
}

interface RouteAdditionalDetails {
  radius: number | string;
  incidents: number[];
}

interface AlertModifiers {
  hours: number;
  route_id: string;
}

// this is a guess of the call signatures. update once api endpoint exists
export const getRoutes = async (): Promise<Route[]> =>
  wrapRequest('get', 'north_standard', '/routes');

export const putRoute = (route: PostRoute) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { route_id, ...routeWithoutId } = route;
  if (route_id) {
    return wrapRequest('put', 'north_standard', '/routes', {
      body: routeWithoutId,
      queryStringParameters: { route_id },
    });
  }
  return wrapRequest('put', 'north_standard', '/routes', {
    body: routeWithoutId,
  });
};

export const deleteRoute = async (routeIds: string[]): Promise<void> =>
  wrapRequest('del', 'north_standard', '/routes', {
    body: {
      route_ids: routeIds,
    },
  });
